var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.onlyBreakMessage)?_c('div',{staticClass:"d-flex flex-column",class:{
    'align-items-end': _vm.isCustomer,
    'align-items-start': !_vm.isCustomer,
  }},[_c('div',{staticClass:"d-flex align-items-center",class:_vm.isCustomer ? 'pr-2' : 'pl-2'},[(_vm.isCustomer && _vm.isVoicebot && !_vm.simpleView)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive",modifiers:{"noninteractive":true}}],staticClass:"border-0 bg-transparent shadow-none p-0 mr-1 meta",attrs:{"size":"sm","title":"Fast forward the recording to this message"},on:{"click":function($event){return _vm.playbackSkipTo({
        chatLogId: _vm.chatLogId,
        timestamp: _vm.chatMessage.timestamp - _vm.estimatedMessageLength,
      })}}},[_c('font-awesome-icon',{attrs:{"icon":"fast-forward"}})],1):_vm._e(),_c('span',{staticClass:"meta"},[_vm._v(_vm._s(_vm.isCustomer ? 'Customer' : 'Bot'))]),(!_vm.isCustomer && _vm.isVoicebot)?_c('voicebot-audio',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive",modifiers:{"noninteractive":true}}],staticClass:"border-0 bg-transparent shadow-none ml-1 meta",attrs:{"text":_vm.chatMessage.data.query,"small":"","title":"Play this message"}}):_vm._e()],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"bubble p-2",class:_vm.isCustomer ? 'customer ml-5' : 'chatbot mr-5',domProps:{"innerHTML":_vm._s(_vm.formatChatlogText(_vm.chatMessage.data.query, _vm.wasVoicebot, _vm.showSsml))}}),(_vm.isCustomer && !_vm.simpleView)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive",modifiers:{"noninteractive":true}}],staticClass:"border-0 bg-transparent shadow-none pl-1 mr-1 meta",attrs:{"title":"Copy text to demo panel"},on:{"click":function($event){return _vm.setCurrentMessage(_vm.chatMessage.data.query)}}},[_c('font-awesome-icon',{attrs:{"icon":"share"}})],1):_vm._e()],1),_c('span',{staticClass:"meta",class:_vm.isCustomer ? 'pr-2' : 'pl-2'},[_vm._v(_vm._s(_vm.dateFormatter(_vm.chatMessage.timestamp)))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }