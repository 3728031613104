<template>
  <div>
    <b-form-input
      v-model="localName"
      :state="$v.localName.$invalid ? false : null"
      :placeholder="placeholder"
      type="text"
      class="mb-2 mr-sm-2 mb-sm-0 text-monospace"
      autofocus
      :disabled="disabled"
      @input="conditionalInput"
    />
    <b-form-invalid-feedback
      v-if="!$v.localName.isNotEmpty"
      id="emptyname"
    >
      Name your variable.
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="!$v.localName.doesNotClashWithReservedKeyword"
      id="reservedkeyword"
    >
      Variable name is a reserved BotScript keyword.
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="!$v.localName.isValidPythonIdentifier"
      id="badpythonname"
    >
      Variable name must be a valid Python variable name.
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="!$v.localName.doesNotClashWithNER"
      id="ner_name"
    >
      Variable name is used for a NER's name already.
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="!$v.localName.isNotAlreadyUsed"
      id="already_use"
    >
      Variable name is already used.
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  isValidPythonIdentifier,
  isReservedKeyword,
} from 'supwiz/botscript/parser';

export default {
  name: 'VariableName',
  mixins: [validationMixin],
  props: {
    value: {
      validator: (v) => typeof v === 'string' || v === null,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Variable name',
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    nerindex: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alreadyUsedVariableNames: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      localName: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['getNERs']),
  },
  watch: {
    value(newValue) {
      if (this.localName !== newValue) {
        this.localName = newValue;
      }
    },
  },
  mounted() {
    this.localName = this.value;
  },
  methods: {
    conditionalInput(value) {
      if (!this.$v.localName.$invalid) {
        this.$emit('input', value);
      }
    },
    variableNameClashesWithNER(newName) {
      const ners = this.getNERs;
      if (!ners) {
        return false;
      }
      const index = ners.findIndex((ner) => ner.name === newName);
      if (index < 0) {
        return false;
      }
      return index !== this.nerindex;
    },
  },
  validations: {
    localName: {
      isNotEmpty(value) {
        return this.allowEmpty || !!value;
      },
      doesNotClashWithReservedKeyword(value) {
        return !isReservedKeyword(value);
      },
      isValidPythonIdentifier(value) {
        return (this.allowEmpty && !value) || isValidPythonIdentifier(value);
      },
      doesNotClashWithNER(value) {
        return !this.variableNameClashesWithNER(value);
      },
      isNotAlreadyUsed(value) {
        return !this.alreadyUsedVariableNames.includes(value);
      },
    },
  },
};
</script>
