<template>
  <!--
    Add your metric-specific results here, by including it in the gigantic (if, else-if, ...)
    If your metric-specific results requires any logic to be presented, _don't_ include that logic
    here, but instead do create a separate component, and include that component here.
    The ClassifierFreshnessResult is an example of a component that needs custom logic.
  -->
  <div>
    <template
      v-if="metricIdentifier === 'NUMBER_OF_DRAFT_REPLIES'"
    >
      There are currently {{ insightDetails.number_of_draft_replies }} draft responses in bot.
    </template>
    <enough-examples-per-node
      v-else-if="metricIdentifier === 'HAS_ENOUGH_EXAMPLES_PER_NODE'"
      :insight-details="insightDetails"
    />
    <children-missing-examples
      v-else-if="metricIdentifier === 'HAS_SMARTNODE_CHILDREN_WO_MATCHING'"
      :insight-details="insightDetails"
    />
    <classifier-freshness-result
      v-else-if="metricIdentifier === 'USES_FRESH_CLASSIFIERS'"
      :insight-details="insightDetails"
    />
    <has-loose-ends
      v-else-if="metricIdentifier === 'HAS_LOOSE_ENDS'"
      :insight-details="insightDetails"
    />
    <link-checking
      v-else-if="metricIdentifier === 'LINK_CHECKING'"
      :insight-details="insightDetails"
    />
    <button-text-length
      v-else-if="metricIdentifier === 'BUTTON_TEXT_LENGTH'"
      :insight-details="insightDetails"
    />
    <ensure-classfier-compatibility
      v-else-if="metricIdentifier === 'ENSURE_CLASSIFIER_COMPATIBILITY'"
      :insight-details="insightDetails"
    />
    <excludable-node-list
      v-else-if="metricIdentifier === 'CHECKUP_ON_YOUNG_NODE_RESPONSES'"
      :insight-details="insightDetails"
      :metric-identifier="'CHECKUP_ON_YOUNG_NODE_RESPONSES'"
    />
    <template
      v-else
    >
      No check-specific presentation has been designed for this check yet.
    </template>
  </div>
</template>

<script>

import ClassifierFreshnessResult from '@/pages/Health/Results/ClassifierFreshnessResult.vue';
import EnoughExamplesPerNode from '@/pages/Health/Results/EnoughExamplesPerNode.vue';
import ChildrenMissingExamples from '@/pages/Health/Results/ChildrenMissingExamples.vue';
import HasLooseEnds from '@/pages/Health/Results/HasLooseEnds.vue';
import LinkChecking from '@/pages/Health/Results/LinkChecking.vue';
import ButtonTextLength from '@/pages/Health/Results/ButtonTextLength.vue';
import EnsureClassfierCompatibility from '@/pages/Health/Results/EnsureClassfierCompatibility.vue';
import ExcludableNodeList from '@/pages/Health/Results/ExcludableNodeList.vue';

export default {
  components: {
    ClassifierFreshnessResult,
    EnoughExamplesPerNode,
    HasLooseEnds,
    LinkChecking,
    ButtonTextLength,
    EnsureClassfierCompatibility,
    ChildrenMissingExamples,
    ExcludableNodeList,
  },
  props: {
    metricIdentifier: {
      type: String,
      required: true,
    },
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  // Fellow developers: Why are you putting logic here?
  // Please consider creating a separate component instead. If in doubt, ask me (Morten)
};
</script>
