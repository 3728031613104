<template>
  <b-popover
    :target="target"
    custom-class="custom-popover"
    triggers="hover"
    delay="100"
    placement="top"
    boundary="viewport"
  >
    <template #title>
      {{ title }}
    </template>
    <div class="popover-content">
      <div class="d-flex">
        <div
          v-for="(value, index) in values"
          :key="index"
          style="border: 1px solid #ebebeb;"
          class="p-2 text-center flex-grow-1"
        >
          {{ nodeDetailsLabels[index] ? nodeDetailsLabels[index] : 'index' }}<br>
          {{ formatValue(value) }}
        </div>
      </div>
    </div>
  </b-popover>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NodeDetailsPopover',
  props: {
    target: {
      type: String,
      required: true,
    },
    values: {
      type: [Array, Number, null],
      default: (() => []),
    },
  },
  computed: {
    ...mapGetters('statistics', [
      'getNodeDetailsData',
      'getRunningNodeTaskIds',
      'nodeDetailsLabels',
    ]),
    ...mapGetters('statistics/filters', [
      'statsFilter',
      'nodesFilter',
      'activeSorting',
    ]),
    title() {
      return this.statsFilter.selectedGroupBy === 'months' ? 'Monthly distribution' : 'Weekly distribution';
    },
  },
  methods: {
    formatValue(value) {
      if (typeof value !== 'number') {
        return '-';
      }
      if (this.target.includes('count')) {
        return value;
      }
      if (this.target.includes('rating')) {
        return value.toFixed(2);
      }
      return `${value.toFixed(2)}%`;
    },
  },
};
</script>
<style scoped>
.popover{
  max-width: 500px !important;
}
::v-deep .popover-body{
 white-space: nowrap;
 padding: 0px;
}
.popover-content{
  max-width: 500px;
  overflow-x: auto;
}
</style>
