<template>
  <b-col>
    <b-row no-gutters>
      <b-col class="r-25 border py-2 px-2 mt-2">
        <b-row>
          <b-col
            v-if="showAutomation"
            cols="auto"
            class="pr-1"
          >
            <b-button
              v-b-tooltip.hover.top.noninteractive
              size="sm"
              variant="primary"
              :title="`${showExpanded ? 'Hide' : 'Show'} details`"
              class="py-0"
              :class="showExpanded ? 'd-block' : 'float-left'"
              onmouseup="this.blur()"
              @click="expanded = !expanded"
            >
              <font-awesome-icon
                :icon="showExpanded ? 'angle-up' : 'angle-down'"
              />
            </b-button>
          </b-col>
          <b-col :class="showAutomation ? 'pl-1' : ''">
            <div class="r-25 text-white mb-2" :style="`background-color:${labelColor}`">
              {{ name }}
            </div>
          </b-col>
        </b-row>

        <span v-if="!showExpanded" style="margin-left: -28px">
          Automation rate
          <span
            :class="expanded ? '' : 'h1 mb-0 mb-1 d-block'"
            class="font-weight-bold text-primary"
          >
            {{ expanded ? "" : stats.automationRate }}
          </span>
        </span>
        <template v-else>
          <b-overlay
            variant="white bar-overlay"
            :show="!hasCoverage"
            opacity="0.8"
          >
            <template #overlay>
              <span />
            </template>
            <b-row no-gutters>
              <b-col :cols="value2Grid(stats.selfService)">
                <bar-piece
                  label="Self-serviced"
                  :value="stats.selfService"
                  :bg-color="colors.selfService"
                />
              </b-col>
              <b-col
                :cols="12 - value2Grid(stats.selfService)"
              >
                <bar-piece
                  label="Partially automated conversations"
                  :value="stats.other"
                  :bg-color="colors.other"
                />
              </b-col>
            </b-row>
          </b-overlay>
          <b-overlay
            v-if="showAutomation"
            variant="white bar-overlay"
            :show="!hasPartial"
            opacity="0.8"
          >
            <template #overlay>
              <span />
            </template>
            <b-row no-gutters>
              <b-col
                :cols="
                  value2Grid(stats.otherPartial)
                "
              >
                <bar-piece
                  label="Partial resolution rate"
                  :value="stats.otherPartial"
                  :bg-color="colors.partialResolution"
                />
              </b-col>
              <b-col
                :cols="12 - value2Grid(stats.otherPartial)"
              >
                <bar-piece
                  :value="stats.otherOther"
                  :bg-color="colors.other"
                />
              </b-col>
            </b-row>
          </b-overlay>
        </template>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import BarPiece from '@/pages/Statistics/BarPiece.vue';

export default {
  name: 'CoverageDetails',
  components: {
    BarPiece,
  },
  props: {
    name: {
      required: true,
      type: String,
    },
    stats: {
      required: true,
      type: Object,
    },
    hasCoverage: {
      required: true,
      type: Boolean,
    },
    hasPartial: {
      required: true,
      type: Boolean,
    },
    labelColor: {
      required: true,
      type: String,
    },
    showAutomation: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      expanded: false,
      colors: {
        selfService: '#43a047',
        other: '#b0bec5',
        partialResolution: '#66bb6a',
      },
    };
  },
  computed: {
    showExpanded() {
      return this.showAutomation ? this.expanded : true;
    },
  },
  methods: {
    value2Grid(value) {
      if (value === null) {
        return 6;
      }
      if (value >= 82) return 10;
      if (value >= 72) return 9;
      if (value >= 63) return 8;
      if (value >= 53) return 7;
      if (value >= 47) return 6;
      if (value >= 37) return 5;
      if (value >= 28) return 4;
      if (value >= 18) return 3;
      return 2;
    },
  },
};
</script>
