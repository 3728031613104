<template>
  <div>
    <div
      :id="id"
      v-b-tooltip.hover.nointeractive.viewport
      v-observe-visibility="{ callback: visibilityChanged }"
      :title="isVisible && Boolean(responseText) ? responseText : ''"
      style="height: 100%; white-space: nowrap;"
      :class="['entry', 'black-text', {
        topLevel,
        'bg-info': primaryHighlighted,
        'grey-out': disabled,
        'bg-light': disabled,
        'bg-related-highlighted': highlighted && !primaryHighlighted,
        'highlight-edit': highlightFromEdit,
      }]"
      @mouseenter="setActive"
      @mouseleave="setInactive"
      @click.self="edit"
    >
      <b-dropdown
        variant="primary"
        size="sm"
        lazy
        no-caret
        class="child-drop"
        @show="showDropdown"
        @hide="hideDropdown"
      >
        <template
          #button-content
        >
          <div style="width: 15px;">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </template>
        <b-dropdown-item-button @click.stop="showAddNodeModal">
          New child node
        </b-dropdown-item-button>
        <b-dropdown-item-button @click.stop="showLinkNodeModal">
          New child reference
        </b-dropdown-item-button>
        <b-dropdown-item-button @click.stop="toggleFlowNode(botNodeId)">
          {{ isFlowNode(botNodeId) ? 'Remove node from flow nodes' : 'Add node to flow nodes' }}
        </b-dropdown-item-button>
        <template v-if="parentId !== null">
          <b-dropdown-item-button @click.stop="removeClick">
            Remove reference
          </b-dropdown-item-button>
          <b-dropdown-item-button v-if="hideDelete" @click.stop="deleteClick">
            Delete node
          </b-dropdown-item-button>
          <b-dropdown-item-button @click.stop="toggleDisability">
            <template v-if="disabled">
              Enable
            </template>
            <template v-else>
              Disable
            </template>
          </b-dropdown-item-button>
        </template>
        <span v-if="id !== rootTreeNode">
          <b-dropdown-divider />
          <b-dropdown-item-button
            @click.stop="toggleZoom"
          >
            <template v-if="topLevel">
              Unzoom
            </template>
            <template v-else>
              Zoom
            </template>
          </b-dropdown-item-button>
        </span>
      </b-dropdown>

      <div
        :style="{
          'padding-left': `${depth * 1.7 - .5}em`,
          display: 'inline-block',
        }"
        @click.self="edit"
      >
        <b-btn
          v-if="botNode.children.length"
          size="sm"
          variant="light"
          style="background-color: transparent"
          class="arrow-button"
          :class="{
            'text-white': primaryHighlighted,
          }"
          @click.left.exact="toggleArrow"
          @click.ctrl.left.exact="expandTree"
        >
          <font-awesome-icon :icon="toggleIcon" />
        </b-btn>
        <div class="d-inline" :style="botNode.children.length ? '' : 'margin-left: 1.85rem;'">
          <font-awesome-icon
            :icon="typeIcon"
            style="vertical-align: -0.25em;"
            :class="{
              'text-white': primaryHighlighted,
            }"
            @dblclick="changeResponseMode"
            @contextmenu.prevent="changeResponseMode"
          />
        </div>
        <font-awesome-icon
          v-if="isOutgoingNode"
          class="ml-1"
          :class="{
            'text-white': primaryHighlighted,
          }"
          style="vertical-align: -0.25em; text-align: right"
          icon="sign-out-alt"
        />
      </div>

      <span
        class="node-title"
        :style="`max-width: calc(100% - ${maxNameWidth}em) !important`"
        :class="{
          'text-primary': specialNode && !primaryHighlighted,
          'text-white': primaryHighlighted,
          'text-muted': disabled && primaryHighlighted,
        }"
        @click.self="edit"
      >
        {{ nodeName }}
      </span>

      <div class="float-right mr-2 mt-1">
        <span
          v-if="!wrapIsGreetNode && !isSubflowNode && !wrapHasMatchingSet"
          v-b-tooltip.hover
          class="mx-1"
          title="Node has no matching configured"
        >
          <font-awesome-icon
            :icon="['far', 'circle']"
          />
        </span>
        <span
          v-if="actionWarning"
          v-b-tooltip.hover
          class="mx-1"
          title="Node has actions with warnings"
        >
          <font-awesome-icon
            icon="exclamation-circle"
          />
        </span>
        <span
          v-if="diagnosticWarning"
          v-b-tooltip.hover
          class="mx-1"
          title="Node has errors"
          @click="$router.push({ name: 'health', hash: '#errors' })"
        >
          <font-awesome-icon
            icon="exclamation-circle"
          />
        </span>
        <font-awesome-icon
          v-if="requiresAuth"
          class="mx-1"
          style="text-align: right"
          icon="lock"
        />
        <font-awesome-icon
          v-if="isFlowNode(botNodeId)"
          class="mx-1"
          style="text-align: right"
          icon="sitemap"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import { truncateString, mapPhrases, phrase2Id } from '@/js/utils';
import { applyThisArgs } from '@/js/storeHelpers';
import { nodeTypes, ROOT_TREE_NODE } from '@/js/constants';

export default {
  name: 'AppNodeUnit',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
    depth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dropdownActive: false,
      active: false,
      isVisible: true,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'getActionsModified',
      'isGreetNode',
      'isSpecialNode',
      'hasMatchingConfigured',
      'nodeById',
      'phraseText',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      fallbackUsage: 'getFallbackUsageOfNode',
      authFallbackUsage: 'getAuthFallbackUsageOfNode',
      isDisabled: 'isDisabled',
    }), 'botNodeId'),
    ...mapGetters('diagnostics', [
      'getWarningsForNode',
    ]),
    ...mapState('treeView', [
      'tree',
      'activeBotNodeIdDict',
      'highlightEditNodeDict',
    ]),
    ...mapGetters('nodeInterpretations', ['isFlowNode']),
    hideDelete() {
      if (this.isGreetNode(this.botNode.id) || this.isSpecialNode(this.botNode)) {
        return false;
      }
      return true;
    },
    maxNameWidth() {
      return 7 + (this.depth * 1.7);
    },
    botNodeId() {
      return this.tree[this.id].botNodeId;
    },
    parentBotNodeId() {
      const parentNode = this.tree[this.parentId];
      return parentNode ? parentNode.botNodeId : null;
    },
    specialNode() {
      return this.botNode.preds.length > 1;
    },
    highlighted() {
      return this.isVisible && this.activeBotNodeIdDict[this.botNodeId];
    },
    highlightFromEdit() {
      return this.isVisible && this.highlightEditNodeDict[this.botNodeId];
    },
    primaryHighlighted() {
      return this.isVisible && this.active && this.highlighted;
    },
    disabled() {
      return this.isVisible && this.isDisabled;
    },
    actionWarning() {
      const modifiedActions = this.getActionsModified(this.botNodeId);
      return modifiedActions.length > 0;
    },
    diagnosticWarning() {
      const otherWarningsForNode = this.getWarningsForNode(this.botNodeId);
      return otherWarningsForNode.length > 0;
    },
    requiresAuth() {
      return this.botNode.requiresAuth;
    },
    showChildren() {
      return this.$store.getters['treeView/children'](this.id) !== null;
    },
    botNode() {
      return this.$store.getters['treeView/botNode'](this.id);
    },
    typeIcon() {
      const type = this.botNode.options.nodeType;
      const mode = this.botNode.options.responseMode;
      if (type === nodeTypes.MULTIPLE_CHOICE) {
        if (this.botNode.options.isStrictMpc) {
          return 'stream';
        }
        return 'brain';
      }
      if (type === nodeTypes.SUBFLOW) {
        return 'bezier-curve';
      }

      return mode === 'require' ? ['fas', 'comments'] : ['far', 'comment'];
    },
    nodeName() {
      return this.botNode.name;
    },
    topLevel() {
      return this.depth === 0;
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    toggleIcon() {
      return this.showChildren ? 'angle-down' : 'angle-right';
    },
    linkNodeModalId() {
      return `${this.id}-addChildModal`;
    },
    addNodeModalId() {
      return `${this.id}-addNodeModal`;
    },
    wrapIsGreetNode() {
      return this.isGreetNode(this.botNode.id);
    },
    wrapHasMatchingSet() {
      return this.hasMatchingConfigured(this.botNode.id);
    },
    isSubflowNode() {
      return this.botNode.options.nodeType === nodeTypes.SUBFLOW;
    },
    responseText() {
      let text = '';
      for (const activityId of this.botNode.activityIds) {
        const activity = this.botNode.activities[activityId];
        if (activity.type === 'response') {
          if (text) {
            text += ' | ';
          }
          text += mapPhrases(activity.text).map((e) => (e.isPhrase ? this.phrase2Text(e.value) : e.value)).join('');
        }
      }
      if (this.botNode.options.nodeType === 'multipleChoice') {
        text += text ? ` | ${this.botNode.options.optionsQuery}` : this.botNode.options.optionsQuery;
      }
      return truncateString(text, 55);
    },

    /**
     * Can only return true for subflow 'exit' nodes
     */
    isOutgoingNode() {
      return this.botNode.options.outgoing || false;
    },
    rootTreeNode() {
      return ROOT_TREE_NODE;
    },
  },
  methods: {
    ...mapMutations('treeView', ['setActiveId', 'setActiveParentId']),
    ...mapActions('nodeInterpretations', [
      'handleDeletionOfNode',
    ]),
    ...mapActions('nodeInterpretations', ['toggleFlowNode']),
    showDropdown() {
      this.dropdownActive = true;
    },
    hideDropdown() {
      this.dropdownActive = false;
    },
    changeResponseMode() {
      const type = this.botNode.options.nodeType;
      const currentResponseMode = this.botNode.options.responseMode;
      // Only change the response mode where it is visible in the frontend.
      if (type === nodeTypes.MULTIPLE_CHOICE || type === nodeTypes.SUBFLOW) {
        return;
      }
      let newResponseMode = currentResponseMode;
      if (currentResponseMode === 'require') {
        newResponseMode = 'pass';
      } else if (currentResponseMode === 'pass') {
        newResponseMode = 'require';
      }
      this.$store.commit('botManipulation/activeBot/setResponseMode', {
        responseMode: newResponseMode,
        id: this.botNode.id,
      });
    },
    toggleZoom() {
      if (this.topLevel) {
        this.$store.commit('treeView/zoomOut');
      } else {
        this.$store.commit('treeView/addZoomNode', { treeNodeId: this.id });
      }
    },
    setActive() {
      this.active = true;
      this.$store.commit('treeView/setActiveBotNodeId', { botNodeId: this.botNodeId });
    },
    setInactive() {
      this.active = false;
    },
    toggleArrow() {
      this.$store.dispatch('treeView/toggle', { treeNodeId: this.id });
    },
    expandTree() {
      this.$store.dispatch('treeView/expandTree', { treeNodeId: this.id });
    },
    edit(event) {
      this.$store.commit('treeView/setActiveBotNodeId', { botNodeId: null });
      const { botId } = this.$route.params;
      this.openCtrlLink({ name: 'edit-node', params: { botId, nodeId: this.botNode.id } }, event);
    },
    toggleDisability() {
      this.$store.commit('botManipulation/activeBot/toggleIsDisabled', { id: this.botNodeId });
    },
    showAddNodeModal() {
      this.setActiveId(this.id);
      this.setActiveParentId(this.parentBotNodeId);
      this.$root.$emit('add-node-modal', this.addNodeModalId);
    },
    showLinkNodeModal() {
      this.setActiveId(this.id);
      this.setActiveParentId(this.parentBotNodeId);
      this.$root.$emit('link-node-modal', this.linkNodeModalId);
    },
    removeClick() {
      this.setActiveId(this.id);
      this.setActiveParentId(this.parentBotNodeId);
      this.$root.$emit('remove-node');
    },
    deleteClick() {
      this.setActiveId(this.id);
      this.$root.$emit('delete-node-modal');
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
    },
    phrase2Text(phrase) {
      return this.phraseText(phrase2Id(phrase));
    },
  },
};
</script>

<style scoped>
  .entry, .topLevel {
    border: solid rgba(0, 0, 0, .125);
    border-width: 0 1px 1px 1px;
    height: 40px;
  }
  .topLevel {
    border-top: 1px solid rgba(0, 0, 0, .125);
    height: 41px
  }

  .bg-related-highlighted {
    background-color: rgba(91, 192, 222, 0.24);
  }
  ::v-deep .btn{
    border-radius: 0 !important;
  }
  .child-drop {
    height: 100%;
    margin: -1px;
  }
  .node-title {
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 5px;
  }
  .grey-out {
    color: #B1B4B6;
  }
  .highlight-edit {
    transition: all 0.5s ease;
    background: #ade3f3;
  }
  .arrow-button {
    border: 0; height:100%;
    margin-right: 5px;
  }

</style>
