<template>
  <base-line-chart
    v-if="chartType === 'line'"
    :data="chartData"
    :options="chartOptions"
  />
  <bar-chart
    v-else-if="chartType === 'bar'"
    :data="chartData"
    :options="chartOptions"
  />
</template>
<script>
import { Bar as BarChart, Line as BaseLineChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Legend,
  Tooltip,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { metricDistributionType } from '@/js/constants';

ChartJS.register(
  Legend,
  Tooltip,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
);

export default {
  name: 'PlotCategoryMetric',
  components: { BaseLineChart, BarChart },
  props: {
    distribution: {
      // Supported enum values: metricDistributionType
      type: String,
      default: metricDistributionType.COUNT,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartType: {
      type: String,
      default: 'line',
    },
  },
  computed: {
    chartOptions() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            offset: this.chartType === 'bar',
            title: {
              display: true,
              text: 'Time',
            },
          },
          y: {
            title: {
              display: true,
              text: this.plotType2Text(this.distribution),
            },
            beginAtZero: true,
            min: 0,
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            mode: 'nearest',
            axis: 'x',
            intersect: false,
            callbacks: {
              label: (tooltipItems) => {
                const value = tooltipItems.formattedValue;
                return this.formatValue(this.distribution, value);
              },
            },
          },
          legend: {
            onClick: (e) => e.stopPropagation(),
            display: false,
          },
        },
        /*
        onHover: (event, chartElement) => {
          // eslint-disable-next-line no-param-reassign
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        */
      };
      if (this.distribution === metricDistributionType.COVERAGE) {
        options.scales.y.ticks = { stepSize: 10 };
        options.scales.y.max = 100;
      } else if (this.distribution === metricDistributionType.RATING) {
        options.scales.y.ticks = { stepSize: 1 };
        options.scales.y.min = 1;
        options.scales.y.max = 5;
      }

      return options;
    },
  },
  methods: {
    plotType2Text(plotType) {
      switch (plotType) {
        case metricDistributionType.COUNT:
          return 'Number of conversations';
        case metricDistributionType.COVERAGE:
          return 'Flow coverage percentage';
        case metricDistributionType.RATING:
          return 'Average chat rating';
        default:
          return 'Number of conversations';
      }
    },
    formatValue(plotType, value) {
      switch (plotType) {
        case metricDistributionType.COUNT:
          return `Number of conversations: ${value}`;
        case metricDistributionType.COVERAGE:
          return `Coverage: ${value}%`;
        case metricDistributionType.RATING:
          return `Average rating: ${value}`;
        default:
          return `Number of conversations: ${value}`;
      }
    },
  },
};
</script>
