<template>
  <base-bar-chart
    :data="theDataToRender"
    :options="barChartOptions"
  />
</template>
<script>
import { Bar as BaseBarChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { truncateString } from '@/js/utils';
import { chartColors } from '@/js/constants';

ChartJS.register(
  Tooltip,
  LinearScale,
  CategoryScale,
  Legend,
  BarElement,
);

export default {
  name: 'NodeProbabilitiesChart',
  components: { BaseBarChart },
  props: {
    probabilities: {
      type: Array,
      required: true,
    },
    nodeName: {
      type: String,
      required: true,
    },
    barChartOptions: {
      type: Object,
      required: false,
      default() {
        return {
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              title: {
                display: true,
                text: 'Score',
              },
              beginAtZero: true,
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (tooltipItem) => `${tooltipItem.formattedValue}%`,
              },
            },
          },
        };
      },
    },
  },
  computed: {
    theDataToRender() {
      // At toplevel, we could potantially have to evaluate 25 nodes; this is too much too show
      // in a single diagram, hence only show top-5 highest-ranked nodes.
      const probabilitiesCopy = Object.values(this.probabilities);
      const top5Probabilities = probabilitiesCopy.sort((a, b) => b.score - a.score).slice(0, 5);
      const nameSortedProbabilities = top5Probabilities
        .sort((a, b) => a.display_name.localeCompare(b.display_name));
      const labels = nameSortedProbabilities.map((element) => element.display_name);
      const scoresForLabels = nameSortedProbabilities
        .map((element) => Number(element.score * 100.0).toFixed(1));

      // Construct dataset for each constituent.
      // If you are to debug this, inspect the source-code here for inspiration:
      // https://www.chartjs.org/samples/latest/charts/bar/stacked-group.html
      const singleDataSet = {};
      singleDataSet.label = `Top 5 scores for childnodes of ${truncateString(this.nodeName, 50)}`;
      singleDataSet.stack = 'The stack';
      singleDataSet.data = scoresForLabels;
      singleDataSet.backgroundColor = chartColors.greenishBar;

      return {
        labels,
        datasets: [singleDataSet],
      };
    },
  },
};
</script>
