<template>
  <div>
    <h3>General Settings</h3>
    <b-card
      no-body
    >
      <p>
        Basic configuration of the bot.
      </p>
    </b-card>
    <hr>
    <b-form-group
      label-for="botName"
      :label="isMainBot ? 'Bot name' : 'SubFlow name'"
    >
      <b-form-input
        id="botName"
        v-model="botName"
        type="text"
        :state="$v.botName.$invalid ? false : null"
        aria-describedby="botNameFeedback"
        placeholder="Bot name"
        @blur="userLeftInputField"
      />
      <b-form-invalid-feedback
        id="botNameFeedback"
      >
        <div v-if="!$v.botName.nameIsNonEmptyAndOtherThanWhitespace">
          Your bot must have a name.
        </div>
        <div v-if="!$v.botName.nameIsUnique">
          A bot already exists with that name.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="!isMainBot"
      label-for="subflowDescription"
      label="SubFlow description"
    >
      <b-form-input
        id="subflowDescription"
        v-model="subflowDescription"
        type="text"
      />
    </b-form-group>

    <div v-if="isMainBot">
      <b-form-group
        label="Fallback threshold"
        label-for="botThreshold"
        description="The bot will go to the fallback node if there are no matches above
        the threshold. The thresholds for smart nodes will take precedence over this
        setting"
      >
        <b-form-input
          id="botThreshold"
          v-model="botThreshold"
          type="range"
          class="custom-range"
        />
        <div style="float:right">
          {{ botThreshold.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) }}%
        </div>
      </b-form-group>

      <b-form-group
        label="Platforms"
        label-for="platforms"
      >
        <b-form-checkbox-group
          id="platforms"
          :checked="getPlatforms"
          :options="platformOptions"
          @input="setPlatforms"
        />
      </b-form-group>
      <hr>
      <b-form-group>
        <b-form-checkbox
          :checked="isProactive"
          @input="proactive => setProactive({ proactive })"
        >
          Proactive bot
          <tooltipped-text
            value="If unchecked the bot will only write a message after the user has written
              their first message"
          />
        </b-form-checkbox>
      </b-form-group>
      <hr>
      <div>
        Language:
        <b-form-select
          v-model="language"
          :options="languages"
          placeholder="Which language does the bot speak"
        />
      </div>
      <div class="mt-1">
        Labels:
        <b-input
          v-model="newLabel"
          placeholder="Add New Label"
          @change="e => addLabel(e)"
        />
        <draggable
          v-model="labels"
          tag="span"
        >
          <b-button
            v-for="x in labels"
            :key="x"
            pill
            class="mr-1 mt-1"
          >
            {{ x }}
            <font-awesome-icon
              :icon="['far', 'times-circle']"
              size="lg"
              @click.stop="deleteLabel(x)"
            />
          </b-button>
        </draggable>
      </div>
      <hr>
      <label>
        Response delay mode
        <tooltipped-text
          value="The fixed mode is where there is a fixed delay between each response. The auto mode
          emulates a real person writing the message to make the conversation flow more natural."
        />
      </label>
      <b-select
        v-model="delayMode"
        class="mb-1"
        :options="[{ text: 'Fixed', value: 'fixed' }, { text: 'Auto', value: 'auto' }]"
      />
      <b-form-group v-if="getResponseDelayMode === 'fixed'">
        <label for="fixedDelay">
          Delay between responses (milliseconds)
          <tooltipped-text value="The fixed time the bot will wait between each response." />
        </label>
        <b-form-input
          id="fixedDelay"
          v-model="fixedDelay"
          type="number"
          :state="$v.fixedDelay.$invalid ? false : null"
          aria-describedby="fixedDelayFeedback"
        />
        <b-form-invalid-feedback id="fixedDelayFeedback">
          <div v-if="!$v.fixedDelay.required">
            You must write a delay (in milliseconds).
          </div>
          <div v-if="!$v.fixedDelay.integer">
            The delay must be an integer.
          </div>
          <div v-if="!$v.fixedDelay.nonNegative">
            The delay cannot be negative.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="delayMode === 'auto'">
        <label for="readWpm">
          Average word-per-minutes for auto-delay
          <tooltipped-text
            value="This value will be applied as delay for messages. 25% as 'bot typing'-delay and
              75% as 'user-reading'-delay."
          />
        </label>

        <b-form-input
          id="readWpm"
          v-model="readWpm"
          type="number"
          :state="$v.readWpm.$invalid ? false : null"
          aria-describedby="readWpmFeedback"
        />
        <b-form-invalid-feedback id="readWpmFeedback">
          <div v-if="!$v.readWpm.required">
            You must write a reading speed (words per minute).
          </div>
          <div v-if="!$v.readWpm.integer">
            The delay must be an integer.
          </div>
          <div v-if="!$v.readWpm.positive">
            The delay must be positive.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <hr>
      <label>
        Message Interrupt Handling
        <tooltipped-text
          value="What should happen if the user sends a message to the bot while
           it is still processing the previous response."
        />
      </label>
      <div>
        Allow users to interrupt while the bot is processing their previous message:
        <b-form-select
          v-model="interruptMode"
          :options="interruptModes"
          placeholder="Which interrupt mode should bot use"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import {
  required, integer, minValue,
} from 'vuelidate/lib/validators';
import {
  platforms, voicePlatforms, platformDisplayNames, LanguageOptions,
} from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'BasicConfig',
  components: { TooltippedText, Draggable },
  mixins: [validationMixin],
  data() {
    return {
      botName: null,
      newLabel: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getOtherBotNames',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
      'getBotName',
      'getPlatforms',
      'isProactive',
      'getDescription',
      'getInterruptMode',
      'getAutoSleepEnabled',
      'getRoutingLanguage',
      'getRoutingLabels',
      'getResponseDelayMode',
      'getFixedDelay',
      'getReadWpm',
    ]),
    ...mapGetters('userSettings', ['showVoicebotFeatures']),
    platformOptions() {
      let plats = { ...platforms };
      if (this.showVoicebotFeatures) {
        plats = { ...plats, ...voicePlatforms };
      }
      delete plats.USERDEFINED;
      const displayNames = Object.entries(plats).map((entry) => ({
        value: entry[1],
        text: platformDisplayNames[entry[0]] || entry[1],
      }));
      displayNames.sort((a, b) => a.text.localeCompare(b.text));
      return displayNames;
    },
    botThreshold: {
      get() {
        return this.$store.getters['botManipulation/activeBot/config/getBotThreshold'];
      },
      set(value) {
        this.$store.commit('botManipulation/activeBot/config/setBotThreshold', { threshold: value });
      },
    },
    subflowDescription: {
      get() {
        return this.getDescription;
      },
      set(value) {
        this.setDescription({ description: value });
      },
    },
    completions() {
      return [];
    },
    languages() {
      const languages = [...LanguageOptions]; // Shallow copy!!
      languages.unshift({ value: '', text: 'Select language' });
      return languages;
    },
    interruptModes() {
      return [
        { value: 'all', text: 'Always' },
        { value: 'smart', text: 'During smart and multiple choice nodes' },
        { value: 'none', text: 'Never' },
      ];
    },
    language: {
      get() {
        return this.getRoutingLanguage || '';
      },
      set(value) {
        this.setRoutingLanguage(value);
      },
    },
    labels: {
      get() {
        return this.getRoutingLabels || [];
      },
      set(value) {
        this.setRoutingLabels([...new Set(value)]);
      },
    },
    delayMode: {
      get() {
        return this.getResponseDelayMode;
      },
      set(mode) {
        this.setResponseDelayMode({ mode });
      },
    },
    fixedDelay: {
      get() {
        return this.getFixedDelay;
      },
      set(delay) {
        this.setFixedDelay({ delay });
      },
    },
    readWpm: {
      get() {
        return this.getReadWpm;
      },
      set(wpm) {
        this.setReadWpm({ wpm });
      },
    },
    interruptMode: {
      get() {
        return this.getInterruptMode;
      },
      set(mode) {
        this.setInterruptMode(mode);
      },
    },
  },
  watch: {
    botName(newValue) {
      if (!this.nameIsUnique(newValue)) {
        return;
      }
      if (!this.nameIsNonEmptyAndOtherThanWhitespace(newValue)) {
        return;
      }
      this.setBotName({ name: newValue });
    },
  },
  mounted() {
    this.botName = this.getBotName;
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setBotName',
      'setPlatforms',
      'setProactive',
      'setDescription',
      'setInterruptMode',
      'setRoutingLanguage',
      'setRoutingLabels',
      'setResponseDelayMode',
      'setFixedDelay',
      'setReadWpm',
    ]),
    nameIsUnique(name) {
      return !this.getOtherBotNames.includes(name);
    },
    nameIsNonEmptyAndOtherThanWhitespace(name) {
      if (name && name.trim()) {
        return true;
      }
      return false;
    },
    userLeftInputField() {
      /**
      * Calling this function on blur on inputfield ensures that when a user has input an invalid
      * botname and leaves the input field, then the field value is set back to last valid name.
      */
      this.botName = this.getBotName;
    },
    deleteLabel(value) {
      this.labels = this.labels.filter((e) => e !== value);
    },
    addLabel(newLabel) {
      if (newLabel) {
        this.labels = [...this.labels, newLabel];
        this.newLabel = '';
      }
    },
  },
  validations: {
    botName: {
      nameIsNonEmptyAndOtherThanWhitespace(newValue) {
        return this.nameIsNonEmptyAndOtherThanWhitespace(newValue);
      },
      nameIsUnique(newValue) {
        return this.nameIsUnique(newValue);
      },
    },
    fixedDelay: {
      required,
      integer,
      nonNegative: minValue(0),
    },
    readWpm: {
      required,
      integer,
      positive: minValue(1),
    },
  },
};
</script>
