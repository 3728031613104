<template>
  <b-modal
    id="delete-subflow-variable-modal"
    title="Confirm deletion"
    ok-variant="danger"
    ok-title="Delete"
    @ok="removeSubFlowVar()"
  >
    Do you want to delete the variable <code>{{ subflowVariableName }}</code>? <br>
    Doing so will make <code>{{ subflowVariableName }}</code> inaccessible in mainflow.
  </b-modal>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'SubflowVariables',
  props: {
    subflowVariableType: {
      required: true,
      type: String,
    },
    subflowVariableName: {
      required: true,
      type: String,
    },
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'deleteInputVariable',
      'removeOutputVariable',
    ]),
    removeSubFlowVar() {
      if (this.subflowVariableType === 'input') {
        this.deleteInputVariable(this.subflowVariableName);
      } else if (this.subflowVariableType === 'output') {
        this.removeOutputVariable(this.subflowVariableName);
      }
    },
  },
};
</script>
