<template>
  <b-form-group
    id="optionsQueryGroup"
    label-class="font-weight-bold d-flex"
    label-for="optionsQuery"
    description="Message displayed together with node options"
  >
    <template #label>
      Multiple choice message
      <voicebot-audio
        v-if="voicebotPlatformSelected"
        v-b-tooltip.noninteractive
        :text="optionsQuery || baseOptionsQuery"
        small
        title="Play this message"
        class="border-0 bg-transparent shadow-none ml-1"
      />
    </template>
    <b-form-textarea
      :value="optionsQuery"
      :placeholder="baseOptionsQuery"
      rows="2"
      max-rows="3"
      @focus="autoFill"
      @input="optionsQuery => setOptionsQuery({ optionsQuery })"
    />
  </b-form-group>
</template>

<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import { applyThisArgs, addThisArgs } from '@/js/storeHelpers';

import VoicebotAudio from '@/components/VoicebotAudio.vue';

export default {
  name: 'EditMultipleChoiceMessage',
  components: { VoicebotAudio },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      optionsQuery: 'getOptionsQuery',
    }), 'nodeId'),
    ...mapState('botManipulation/activeBot/config', ['multipleChoice']),
    ...mapGetters('botManipulation/activeBot/config', ['getPlatforms']),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    baseOptionsQuery() {
      return this.multipleChoice.optionsQuery;
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setOptionsQuery',
    ]), { id: 'nodeId' }),
    autoFill() {
      if (this.optionsQuery === null) {
        this.setOptionsQuery({ optionsQuery: this.baseOptionsQuery });
      }
    },
  },
};
</script>
