<template>
  <b-container
    fluid
    class="px-0"
  >
    <b-row no-gutters>
      <template v-if="isLoading">
        <b-col />
        <b-col cols="auto">
          <b-spinner
            class="m-4"
            style="width: 4rem; height: 4rem;"
          />
        </b-col>
        <b-col />
      </template>
      <template v-else-if="hasLoadError || hasSyncError">
        <b-col>
          <b-card
            title="Error occurred"
            class="r-75"
            body-class="p-3"
          >
            <template v-if="hasLoadError">
              Some error occurred while loading the datasets.
            </template>
            <template v-else>
              Some error occurred while synchronizing the datasets.
            </template>
            <b-link @click="fetchAllDatasets">
              Click here to refresh.
            </b-link>
          </b-card>
        </b-col>
      </template>
      <template v-if="isInitialized">
        <b-col
          v-show="isLoaded"
        >
          <router-view />
          <bot-studio-toaster />
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  DATASET_STATE_LOAD_FAILED,
  DATASET_STATE_LOADED,
  DATASET_STATE_LOADING,
  DATASET_STATE_SYNC_FAILED,
} from '@/js/constants';
import BotStudioToaster from '@/components/BotStudioToaster.vue';

export default {
  name: 'DataExploration',
  components: {
    BotStudioToaster,
  },
  beforeRouteEnter(to, from, next) {
    // This is called whenever this component is about to be rendered in the route view. At this
    // moment we do not have access to "this", and therefore we pass a function, which will have
    // access to next.
    next((vm) => {
      vm.updateBotList();
      vm.fetchAllDatasets(); // don't await to avoid delaying routing
    });
  },
  beforeRouteUpdate(to, from, next) {
    // This is called whenever a routing occurs, but this component survives. This means that we
    // navigated on to another page concerning datasets. Hence we do not have to refetch all
    // datasets.
    next();
  },
  beforeRouteLeave(to, from, next) {
    // This is called when we leave this to go to another component which does not
    // use data exploration. We therefore don't need to do anything.
    next();
  },
  computed: {
    ...mapState('dataExploration', ['datasetsLoadState', 'isInitialized']),
    ...mapState(['sidebarOpen']),
    isLoading() {
      return this.datasetsLoadState === DATASET_STATE_LOADING;
    },
    isLoaded() {
      return this.datasetsLoadState === DATASET_STATE_LOADED;
    },
    hasLoadError() {
      return this.datasetsLoadState === DATASET_STATE_LOAD_FAILED;
    },
    hasSyncError() {
      return this.datasetsLoadState === DATASET_STATE_SYNC_FAILED;
    },
  },
  methods: {
    ...mapActions('dataExploration', ['fetchAllDatasets']),
    ...mapActions('botManipulation', ['updateBotList']),
  },
};
</script>

<style scoped>

</style>
