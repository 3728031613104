<template>
  <font-awesome-icon
    v-b-popover.hover="'Unsaved changes'"
    icon="exclamation-circle"
  />
</template>

<script>
export default {
  name: 'DirtyIcon',
};
</script>
