<template>
  <b-list-group-item class="border">
    <h4>Q&A entry title</h4>
    <b-form-input
      v-model="nodeName"
      :placeholder="'Name for this Q&A entry'"
    />
    <hr class="separator">
    <b-container>
      <b-row
        align-v="center"
        align-h="between"
        :style="{ cursor: 'pointer' }"
        @click="toggleShowQuestions"
      >
        <b-col cols="*">
          <h4>Example questions</h4>
        </b-col>
        <b-col cols="*">
          <h5 class="mr-2">
            <font-awesome-icon :icon="arrowQuestions" />
          </h5>
        </b-col>
      </b-row>
    </b-container>
    <b-collapse
      id="collapse-question"
      v-model="showQuestions"
    >
      <string-list
        :strings="nodeExamples"
        :placeholder="'Add an example question for this Q&A entry'"
        @change="examples => setNodeExamples({ examples })"
      />
    </b-collapse>

    <hr class="separator">
    <node-edit-activities
      :node-id="id"
      :only-allow-new-responses="true"
      class="mt-2"
    />

    <hr class="separator">
    <b-row
      align-h="center"
      class="mb-3"
    >
      <b-btn
        v-b-tooltip.hover
        variant="danger"
        title="Delete this Q&A entry"
        @click="promptRemoveNode"
      >
        <font-awesome-icon icon="trash-alt" /> Delete entry
      </b-btn>
    </b-row>
  </b-list-group-item>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import StringList from 'supwiz/components/StringList.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import NodeEditActivities from '@/pages/EditNode/NodeEditActivities.vue';

export default {
  name: 'QANode',
  components: { NodeEditActivities, StringList },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      arrowAnswer: 'angle-down',
      arrowQuestions: 'angle-down',
      showQuestions: false,
    };
  },

  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      nodeExamples: 'getNodeExamples',
    }), 'id'),
    nodeName: {
      get() {
        return this.activeNode.name;
      },
      set(value) {
        this.$store.dispatch('botManipulation/activeBot/updateNames', { id: this.id, value });
      },
    },
  },

  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setNodeExamples: 'setNodeExamplesWithHelper',
    }), { id: 'id' }),
    ...addThisArgs(mapActions('botManipulation/activeBot', [
      'removeNode',
    ]), { id: 'id' }),
    toggleShowQuestions() {
      this.showQuestions = !this.showQuestions;
      this.arrowQuestions = this.showQuestions ? 'angle-up' : 'angle-down';
    },
    async promptRemoveNode() {
      const question = 'Are you sure you want to delete this question-answer entry?';
      if (await this.$bvModal.msgBoxConfirm(question)) {
        await this.removeNode();
      }
    },
  },
};
</script>

<style scoped>

</style>
