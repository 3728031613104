import { mapGetters } from 'vuex';

const botManipulationMixin = {
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeByName',
    ]),
  },
  methods: {
    setChildrenByIdArray(nodeIds) {
      this.$store.dispatch(
        'botManipulation/activeBot/updateActiveNode',
        { value: nodeIds, attribute: 'children', id: this.nodeId },
      );
    },
    nodeNames2Ids(nameArray) {
      const output = [];
      let valid = true;
      for (let i = 0; i < nameArray.length; i += 1) {
        const tempNode = this.nodeByName(nameArray[i]);
        if (!tempNode) {
          valid = false;
        } else {
          output.push(tempNode.id);
        }
      }
      // False will throw an error as it is expected to be an array? On Purpose?
      return valid ? output : false;
    },
  },
};

export default botManipulationMixin;
