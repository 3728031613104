<template>
  <div>
    <b-row
      v-if="isClassifierLoading"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <router-view v-else />
  </div>
</template>
<script>

import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'LabelSingle',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setActiveLabelId(to.params.labelId);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.labelId !== from.params.labelId) {
      this.setActiveLabelId(to.params.labelId);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.setActiveLabelId(null);
    next();
  },
  computed: {
    ...mapState('nlu/classifier', ['isClassifierLoading']),
    ...mapGetters('nlu/classifier', ['activeLabel']),
  },
  methods: {
    ...mapMutations('nlu/classifier', ['setActiveLabelId']),
  },
};
</script>
