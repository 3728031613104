<template>
  <b-row class="mb-3">
    <b-col>
      <b-overlay :show="isLoading(dataKey)">
        <b-card
          class="r-75 shadow text-center"
          body-class="p-3"
        >
          <b-card-title class="position-relative">
            Automation details
            <b-button-group class="position-absolute" style="right: 0">
              <b-button
                v-b-tooltip.hover.noninteractive
                variant="primary"
                class="chart-type-button"
                :pressed="dataTab === dataType.CONVERSATIONS"
                title="Conversation-based automation details"
                @click.stop="setDataTab(dataType.CONVERSATIONS)"
              >
                <font-awesome-icon icon="comment" />
              </b-button>
              <b-button
                v-b-tooltip.hover.noninteractive
                variant="primary"
                class="chart-type-button"
                :pressed="dataTab === dataType.INTENTS"
                title="Intent-based automation details"
                @click.stop="setDataTab(dataType.INTENTS)"
              >
                <font-awesome-icon icon="comments" />
              </b-button>
            </b-button-group>
          </b-card-title>
          <div>
            <!-- LEVEL 1 -->
            <b-row
              no-gutters
              class="pt-1"
            >
              <b-col
                :cols="coveredCols"
              >
                <bar-piece
                  label="Flow covered"
                  :value="key2Value('flow_coverage')"
                  :bg-color="colors.covered"
                />
              </b-col>
              <b-col
                v-if="showSupsearchCoverage"
                :cols="supsearchCoveredCols"
              >
                <bar-piece
                  label="SupSearch covered"
                  :value="key2Value('supsearch_coverage')"
                  :bg-color="colors.supsearchCovered"
                />
              </b-col>
              <b-col
                :cols="nonCoveredCols"
              >
                <bar-piece
                  label="Not covered"
                  :value="key2Value('not_covered')"
                  :bg-color="colors.notCovered"
                />
              </b-col>
            </b-row>

            <b-row no-gutters>
              <!-- LEFT SIDE -->
              <CoverageDetails
                name="Flow covered details"
                label="Covered"
                :show-automation="showAutomation"
                :stats="flowCoveredStats"
                :has-coverage="hasAutomationData('flow_coverage', 1)"
                :has-partial="hasAutomationData('flow_coverage', 2)"
                :label-color="colors.covered"
              />
              <!-- MIDDLE -->
              <CoverageDetails
                v-if="showSupsearchCoverage"
                class="pl-2"
                name="SupSearch covered details"
                :show-automation="showAutomation"
                :stats="supsearchCoveredStats"
                :has-coverage="hasAutomationData('supsearch_coverage', 1)"
                :has-partial="hasAutomationData('supsearch_coverage', 2)"
                :label-color="colors.supsearchCovered"
              />
              <!-- RIGHT SIDE -->
              <CoverageDetails
                name="Not covered details"
                class="pl-2"
                :show-automation="showAutomation"
                :stats="notCoveredStats"
                :has-coverage="hasAutomationData('not_covered', 1)"
                :has-partial="hasAutomationData('not_covered', 2)"
                :label-color="colors.notCovered"
              />
            </b-row>
          </div>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from 'vuex';
import BarPiece from '@/pages/Statistics/BarPiece.vue';
import CoverageDetails from '@/pages/Statistics/CoverageDetails.vue';
import { automationDetailsType } from '@/js/constants';

export default {
  name: 'AutomationDetails',
  components: {
    BarPiece,
    CoverageDetails,
  },
  data() {
    return {
      colors: {
        covered: '#546e7a',
        supsearchCovered: '#728994',
        notCovered: '#90a4ae',
      },
      dataType: automationDetailsType,
      coveredVisible: false,
      notCoveredVisible: false,
      dataTab: automationDetailsType.CONVERSATIONS,
    };
  },
  computed: {
    ...mapGetters('statistics', ['getKPIData', 'isLoading']),
    isEmpty() {
      return !this.getKPIData(this.dataKey);
    },
    flowCoveredStats() {
      return this.coverageStats('flow_coverage');
    },
    supsearchCoveredStats() {
      return this.coverageStats('supsearch_coverage');
    },
    notCoveredStats() {
      return this.coverageStats('not_covered');
    },
    showSupsearchCoverage() {
      return this.key2Value('supsearch_coverage') > 0;
    },
    flowCoverage() {
      return this.key2Value('flow_coverage');
    },
    supsearchCoverage() {
      return this.key2Value('supsearch_coverage');
    },
    rawFlowCols() {
      return this.value2Grid(this.flowCoverage);
    },
    rawSupSearchCols() {
      return this.value2Grid(this.supsearchCoverage);
    },
    coveredCols() {
      if (this.rawFlowCols + this.rawSupSearchCols < 12) {
        // At least one column is available for non-covered chats
        return this.rawFlowCols;
      }
      return this.rawFlowCols - (this.rawFlowCols > this.rawSupSearchCols ? 0 : 1);
    },
    supsearchCoveredCols() {
      if (!this.showSupsearchCoverage) {
        return 0;
      }
      if (this.rawFlowCols + this.rawSupSearchCols < 12) {
        // At least one column is available fore non-covered chats
        return this.rawSupSearchCols;
      }
      return this.rawSupSearchCols - (this.rawSupSearchCols > this.rawFlowCols ? 0 : 1);
    },
    nonCoveredCols() {
      return 12 - this.coveredCols - this.supsearchCoveredCols;
    },
    dataKey() {
      if (this.dataTab === this.dataType.CONVERSATIONS) {
        return 'conversation_automation_distributions';
      }
      if (this.dataTab === this.dataType.INTENTS) {
        return 'question_automation_distributions';
      }
      return '';
    },
    showAutomation() {
      return this.dataTab !== this.dataType.INTENTS;
    },
  },
  methods: {
    setDataTab(tab) {
      this.dataTab = tab;
    },
    coverageStats(kpiName) {
      return {
        automationRate: this.automationRate(kpiName),
        selfService: this.key2Value(`${kpiName}_self_service`),
        other: this.key2Value(`${kpiName}_other`),
        otherPartial: this.key2Value(`${kpiName}_other_partial_resolution`),
        otherOther: this.key2Value(`${kpiName}_other_other`),
      };
    },
    automationRate(kpiName) {
      const data = this.getKPIData(this.dataKey);
      if (!this.hasAutomationData(kpiName, 1)) {
        return 'N/A';
      }
      let rate = data.info[`${kpiName}_self_service`];
      if (this.hasAutomationData(kpiName, 2)) {
        // Add partial resolution
        const other = data.info[`${kpiName}_other`];
        const otherPartial = data.info[`${kpiName}_other_partial_resolution`];
        rate += other * otherPartial;
      }
      return `${(rate * 100).toFixed(1)}%`;
    },
    value2Grid(value) {
      if (value === null) {
        return 6;
      }
      if (value >= 82) return 10;
      if (value >= 72) return 9;
      if (value >= 63) return 8;
      if (value >= 53) return 7;
      if (value >= 47) return 6;
      if (value >= 37) return 5;
      if (value >= 28) return 4;
      if (value >= 18) return 3;
      return 2;
    },
    key2Value(key) {
      const value = this.getKPIData(this.dataKey);
      if (value?.info && value.info[key] !== null) {
        return Math.round(value.info[key] * 10000) / 100;
      }
      return null;
    },
    hasAutomationData(key, depth) {
      if (depth === 1) {
        return !!this.key2Value(`${key}_self_service`) || !!this.key2Value(`${key}_other`);
      }
      return this.hasAutomationData(key, 1) && (!!this.key2Value(`${key}_other_partial_resolution`) || !!this.key2Value(`${key}_other_other`));
    },
  },
};
</script>
<style scoped>
::v-deep .stat-overlay {
  border-radius: 0.5rem;
}
</style>
