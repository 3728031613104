<template>
  <div>
    <b-form-group
      label="Minimum number of examples per node"
      label-for="inputMinRequiredExamples"
    >
      <b-form-input
        id="inputMinRequiredExamples"
        v-model="newConfig.minRequiredExamples"
        aria-describedby="inputFeedback"
        :state="$v.newConfig.$invalid ? false : null"
        placeholder="Minimum number of examples per node"
        type="number"
        min="0"
      />
      <b-form-invalid-feedback id="inputFeedback">
        <div v-if="!$v.newConfig.minRequiredExamples.insideBounds">
          Specify an integer greater than zero
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button
      :disabled="$v.newConfig.$invalid ? true : false"
      @click="saveConfig()"
    >
      Save new config
    </b-button>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, integer, minValue } from 'vuelidate/lib/validators';

export default {
  name: 'ExamplesConfiguration',
  mixins: [validationMixin],
  data() {
    return {
      newConfig: {
        minRequiredExamples: null,
      },
      // Keeping metricIdentifier around as a constant for convenience.
      metricIdentifier: 'HAS_ENOUGH_EXAMPLES_PER_NODE',
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', [
      'getConfigForMetric',
    ]),
    currentConfig() {
      return this.getConfigForMetric(this.getBotId, this.metricIdentifier);
    },
  },
  mounted() {
    if (this.currentConfig === null) {
      // This will leave input field empty
      return;
    }
    this.newConfig.minRequiredExamples = this.currentConfig.min_required_examples;
  },
  methods: {
    ...mapActions('health', [
      'updateComputeConfig',
    ]),
    saveConfig() {
      this.updateComputeConfig({
        newConfig: {
        // Remapping name, since backend expects snake_cased name.
          min_required_examples: this.newConfig.minRequiredExamples,
        },
        metricIdentifier: this.metricIdentifier,
      });
    },
  },
  validations: {
    newConfig: {
      minRequiredExamples: {
        required,
        integer,
        insideBounds: minValue(1),
      },
    },
  },
};
</script>
