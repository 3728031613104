<template>
  <main
    role="main"
    class="h-100"
  >
    <template v-if="isLoading">
      <b-row class="h-100 align-items-center">
        <b-col class="text-center">
          <b-spinner
            style="width: 5rem; height: 5rem;"
          />
        </b-col>
      </b-row>
    </template>
    <router-view v-else />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminUsersGroups',
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState('administration', ['isFetchingUsers', 'isFetchingGroups']),
    isLoading() {
      return !this.ready || this.isFetchingUsers || this.isFetchingGroups;
    },
  },
  async created() {
    this.ready = false;
    await this.fetchUsers();
    await this.fetchGroups();
    this.ready = true;
  },
  methods: {
    ...mapActions('administration', ['fetchUsers']),
    ...mapActions('administration', ['fetchGroups']),
  },
};
</script>
