import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';

const categoriesState = () => ({
  // Contains the id of different visualizations.
  visualizationIds: [],
  // Contains the meta-data for all visualizations.
  visualizationMetas: {},
  // Contains data (necessary for showing the visualization) where it has been fetched.
  visualizationDatas: {},
  isFetchingData: false,
  isFetchingMeta: false,
});

export const categoriesGetters = {
  visualizationMetasFromDatasetId: (state) => (datasetId) => state.visualizationIds
    .map((id) => state.visualizationMetas[id]).filter((v) => v.datasetId === datasetId),
  getVisualizationNameFromId: (state) => (visualizationId) => state
    .visualizationMetas[visualizationId]?.name,
  getVisualization: (state) => (visualizationId) => {
    const visData = state.visualizationDatas[visualizationId];
    if (visData === undefined || visData === null) return {};
    return visData;
  },
};

const mutations = {
  setVisualizationMetas(state, { visualizations }) {
    const visualizationIds = visualizations.map(({ id }) => id);
    const visualizationDict = {};
    for (const visualization of visualizations) {
      visualizationDict[visualization.id] = {
        id: visualization.id,
        datasetId: visualization.dataset_id,
        numTopics: visualization.num_topics,
        language: visualization.language,
        ready: visualization.ready,
        computationFailed: visualization.computation_failed,
        inQueue: visualization.in_queue,
        name: visualization.name,
      };
    }
    state.visualizationIds = visualizationIds;
    Vue.set(state, 'visualizationMetas', visualizationDict);
  },
  setVisualizationData(state, { visualizationId, dataPointIds, encodedVisualization }) {
    Vue.set(state.visualizationDatas, visualizationId, { dataPointIds, encodedVisualization });
  },
  setIsFetchingData(state, value) {
    state.isFetchingData = value;
  },
  setIsFetchingMeta(state, value) {
    state.isFetchingMeta = value;
  },
};

const actions = {
  async fetchAllVisualizationMetas({ rootState, commit }, { datasetId, refreshing }) {
    if (!refreshing) {
      commit('setIsFetchingMeta', true);
    }
    const { data: { visualizations } } = await axios.get(endpoints.dataExplorationVisualization, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: { dataset_id: datasetId },
    });
    commit('setVisualizationMetas', { visualizations });
    if (!refreshing) {
      commit('setIsFetchingMeta', false);
    }
  },
  async createVisualizationFromDataset({ rootState, commit }, {
    datasetId, name, numTopics, language,
  }) {
    const url = endpoints.dataExplorationVisualization;
    const data = {
      from_dataset: true,
      from_flow_potential: false,
      from_transferred_covered: false,
      dataset_id: datasetId,
      name,
      num_topics: numTopics,
      language,
    };
    const config = { headers: { Authorization: `JWT ${rootState.auth.jwt}` } };
    const { data: { visualizations, id } } = await axios.post(url, data, config);
    commit('setVisualizationMetas', { visualizations });
    return id;
  },
  async createVisualizationFromFlowPotential(
    { rootState, commit },
    {
      botId, variantId, flowNodeId, daysDelta, selectedDataOrigins, name, numTopics, language,
    },
  ) {
    const url = endpoints.dataExplorationVisualization;
    const data = {
      from_dataset: false,
      from_flow_potential: true,
      from_transferred_covered: false,
      bot_id: botId,
      variant_id: variantId,
      flow_node_id: flowNodeId,
      days_delta: daysDelta,
      selected_data_origins: selectedDataOrigins,
      name,
      num_topics: numTopics,
      language,
    };
    const config = { headers: { Authorization: `JWT ${rootState.auth.jwt}` } };
    const { data: { visualizations, id } } = await axios.post(url, data, config);
    commit('setVisualizationMetas', { visualizations });
    return id;
  },
  async createVisualizationFromTransferredCovered(
    { rootState, commit },
    {
      botId, variantId, coverageFilter, daysDelta,
      selectedDataOrigins, name, numTopics, language,
    },
  ) {
    const url = endpoints.dataExplorationVisualization;
    const data = {
      from_dataset: false,
      from_flow_potential: false,
      from_transferred_covered: true,
      bot_id: botId,
      variant_id: variantId,
      coverage_filter: coverageFilter,
      days_delta: daysDelta,
      selected_data_origins: selectedDataOrigins,
      name,
      num_topics: numTopics,
      language,
    };
    const config = { headers: { Authorization: `JWT ${rootState.auth.jwt}` } };
    const { data: { visualizations, id } } = await axios.post(url, data, config);
    commit('setVisualizationMetas', { visualizations });
    return id;
  },
  async deleteVisualization({ rootState, commit }, { visualizationId }) {
    const { data: { visualizations } } = await axios.delete(endpoints.dataExplorationVisualization,
      {
        params: { visualization_id: visualizationId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
    commit('setVisualizationMetas', { visualizations });
  },
  async fetchVisualizationData({ dispatch }, { visualizationId }) {
    dispatch('updateVisualizationData', { visualizationId });
  },
  async updateVisualizationData({
    rootState, commit, dispatch,
  }, { visualizationId }) {
    commit('setIsFetchingData', true);
    const url = `${endpoints.dataExplorationVisualizationData + visualizationId}/`;
    const config = { headers: { Authorization: `JWT ${rootState.auth.jwt}` } };
    const { data } = await axios.get(url, config);
    if (data.ready === false) {
      setTimeout(() => dispatch('updateVisualizationData', { visualizationId }), 1000);
    } else if (data.failed) {
      dispatch('sidebar/showWarning', {
        title: 'An error occured',
        text: 'Failed to create visualization',
        variant: 'danger',
      }, { root: true });
      commit('setIsFetchingData', false);
    } else {
      commit('setVisualizationData', {
        visualizationId,
        dataPointIds: data.data_point_ids,
        encodedVisualization: data.encoded_visualization,
      });
      commit('setIsFetchingData', false);
    }
  },
};

export default {
  namespaced: true,
  state: categoriesState,
  getters: categoriesGetters,
  mutations,
  actions,
};
