import axios from 'axios';
import Vue from 'vue';
import { CHAT_ACTION, COMPOUND_RESPONSE } from '@/js/activity';
import {
  AUTH_SETTING_CONFLICT_WARNING, NODE_SAME_NAME,
  AUTH_MISSING_FALLBACK, MAKE_BOT_FAILURE, SYNTAX_ERROR, NO_CUSTOMFALLBACK_SPECIFIED_WARNING,
  TaskStatus, nodeTypes, ACTIONS_HAVE_WARNINGS, YESNO_MODEL_WARNING, INTERNAL_BAD_ACTIVITY_STATE,
  UNSUPPORTED_CHAT_ACTION, COMPOUND_RESPONSE_ERROR, compoundResponseTypes, CONTENT_ERROR,
} from '@/js/constants';
import endpoints from '@/js/urls';
import chatActions from '@/js/chatActions';

/*
All warning-objects (regardless of the warning-type specifics are expected to include)
warningType: <'SYNTAX_ERROR'|'YESNO_MODEL_WARNING'|etc...>
nodeId: <id>
*/

const initialState = {
  errorComputingLocalDiagnostics: false,
  loadedLocalComputedDiagnostics: false,
  loadingLocalComputedDiagnostics: false,
  warnings: [],
  // Only ever hold a reference to the current diagnostics task running in backend. The task may be
  // underway or completed already. Completed or underway, this is the task that the enduser is
  // presented with.
  diagnosticsTaskId: null,
  // The diagnosticsTaskResult is an object always containing a value for key 'status', and
  // optionally a value for key 'result' (if value for 'status' is 'FINISHED').
  diagnosticsTaskResult: null,
  /**
   * If the bot fails to build in backend, the errormessage should be stored here.
   * Currently, we only detect one error at a time.
   */
  backendDiagnosticsErrorMessage: null,
};

const getters = {
  errorComputingLocalDiagnostics(state) {
    return state.errorComputingLocalDiagnostics;
  },
  loadedLocalComputedDiagnostics(state) {
    return state.loadedLocalComputedDiagnostics;
  },
  loadingLocalComputedDiagnostics(state) {
    return state.loadingLocalComputedDiagnostics;
  },
  getWarnings(state) {
    return state.warnings;
  },
  getWarningsOfType: (state) => (warningType) => state.warnings.filter(
    (warning) => warning.warningType === warningType,
  ),
  getWarningsForNode: (state) => (nodeId) => state.warnings.filter(
    (warning) => warning.nodeId === nodeId,
  ),
  getInternalErrors: (state) => state.warnings.filter(
    (warning) => warning.warningType && warning.warningType.startsWith('INTERNAL_'),
  ),
  diagnosticsTaskId: (state) => state.diagnosticsTaskId,
  diagnosticsTaskResult: (state) => state.diagnosticsTaskResult,
  backendDiagnosticsErrorMessage: (state) => state.backendDiagnosticsErrorMessage,
  backendIsComputingDiagnostics(state) {
    return state.diagnosticsTaskResult !== null
    && state.diagnosticsTaskResult.status === TaskStatus.PENDING;
  },
};

const mutations = {
  resetDiagnostics(state) {
    state.errorComputingLocalDiagnostics = false;
    state.loadedLocalComputedDiagnostics = false;
    state.loadingLocalComputedDiagnostics = false;
    state.warnings = [];
    state.diagnosticsTaskId = null;
    state.diagnosticsTaskResult = null;
    state.backendDiagnosticsErrorMessage = null;
  },
  setErrorComputingLocalDiagnostics(state, value) {
    state.errorComputingLocalDiagnostics = value;
  },
  setLoadedLocalComputedDiagnostics(state, value) {
    state.loadedLocalComputedDiagnostics = value;
  },
  setLoadingLocalComputedDiagnostics(state, value) {
    state.loadingLocalComputedDiagnostics = value;
  },
  addWarning(state, warning) {
    const newWarnings = state.warnings;
    newWarnings.push(warning);
    Vue.set(state, 'warnings', newWarnings);
  },
  clearAllWarnings(state) {
    Vue.set(state, 'warnings', []);
  },
  clearDiagnosticsTaskResult(state) {
    Vue.set(state, 'diagnosticsTaskResult', null);
  },
  setDiagnosticsTaskId(state, taskId) {
    state.diagnosticsTaskId = taskId;
  },
  setDiagnosticsTaskResult(state, payload) {
    Vue.set(state, 'diagnosticsTaskResult', payload);
  },
  setBackendDiagnosticsErrorMessage(state, backendDiagnosticsErrorMessage) {
    Vue.set(state, 'backendDiagnosticsErrorMessage', backendDiagnosticsErrorMessage);
  },
};

const actions = {
  calculateBadActivityState({ commit, rootState }) {
    const nodes = rootState.botManipulation.activeBot.nodes;
    for (const node of Object.values(nodes)) {
      for (const activityId of Object.keys(node.activities)) {
        if (!node.activityIds.includes(activityId)) {
          commit('addWarning', {
            nodeId: node.id,
            warningType: INTERNAL_BAD_ACTIVITY_STATE,
            error: `Activity ${activityId} is not present in activity Ids`,
            activityId,
          });
        }
      }
    }
  },
  calculateSameNameWarnings({ commit, rootState }) {
    const nameToIds = {};
    const nodes = rootState.botManipulation.activeBot.nodes;
    for (const node of Object.values(nodes)) {
      if (nameToIds[node.name] === undefined) {
        nameToIds[node.name] = [];
      }
      nameToIds[node.name].push(node.id);
    }
    for (const [name, ids] of Object.entries(nameToIds)) {
      if (ids.length > 1) {
        for (const id of ids) {
          commit('addWarning', {
            nodeId: id,
            warningType: NODE_SAME_NAME,
            sharedName: name,
          });
        }
      }
    }
  },
  calculateAuthWarnings({ commit, rootState, rootGetters }) {
    const authIsEnabledForBot = rootGetters['botManipulation/activeBot/config/auth/authenticationSubflowIsEnabled'];
    const nodes = rootState.botManipulation.activeBot.nodes;
    for (const node of Object.values(nodes)) {
      const nodeRequiresAuth = node.requiresAuth;
      if (nodeRequiresAuth && !authIsEnabledForBot) {
        commit('addWarning', {
          nodeId: node.id,
          warningType: AUTH_SETTING_CONFLICT_WARNING,
        });
      }
      if (nodeRequiresAuth && authIsEnabledForBot && !node.fallbackAuth) {
        commit('addWarning', {
          nodeId: node.id,
          warningType: AUTH_MISSING_FALLBACK,
        });
      }
    }
  },
  calculateCustomFallbackSelectedNoNodeSupplied({ commit, rootState, rootGetters }) {
    const nodes = rootState.botManipulation.activeBot.nodes;
    for (const node of Object.values(nodes)) {
      const nodeType = node.options.nodeType;
      if (nodeType === nodeTypes.MULTIPLE_CHOICE) {
        if (rootGetters['botManipulation/activeBot/getUsesCustomFallbackNode'](node.id)) {
          if (node.options.customFallbackNodeId === null) {
            commit('addWarning', {
              nodeId: node.id,
              warningType: NO_CUSTOMFALLBACK_SPECIFIED_WARNING,
            });
          }
        }
      }
    }
  },
  calculateActionsWithWarnings({ commit, rootState, rootGetters }) {
    const nodes = rootState.botManipulation.activeBot.nodes;
    for (const node of Object.values(nodes)) {
      const activityIds = rootGetters['botManipulation/activeBot/getActionsModified'](node.id);
      if (activityIds.length > 0) {
        commit('addWarning', {
          nodeId: null,
          warningType: ACTIONS_HAVE_WARNINGS,
        });
        break; // We just need one to determine that the warning exists.
      }
    }
  },
  checkYesNoModelLabels({ rootState, commit }) {
    const multipleChoice = rootState.botManipulation.activeBot.config.multipleChoice;
    const missingLabel = !multipleChoice.yesLabel || !multipleChoice.noLabel;
    if (multipleChoice.yesNoModelName && missingLabel) {
      commit('addWarning', {
        warningType: YESNO_MODEL_WARNING,
      });
    }
  },
  async calculateBackendDiagnostics({ commit, rootState }) {
    commit('clearDiagnosticsTaskResult');
    const data = { bot_id: rootState.botManipulation.activeBot.id };
    const config = { headers: { Authorization: `JWT ${rootState.auth.jwt}` } };
    try {
      const response = await axios.post(endpoints.diagnostics, data, config);
      const taskId = response.data.taskId;
      // Store taskId
      commit('setDiagnosticsTaskId', taskId);
      // Signify task is in progress
      commit('setDiagnosticsTaskResult', { status: 'PENDING' });
    } catch {
      commit('setDiagnosticsTaskResult', {
        status: 'ERROR_ON_POST',
      });
    }
  },
  async fetchBackendDiagnosticsTaskStatus({ commit, state, rootState }) {
    try {
      const response = await axios.get(endpoints.diagnostics, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        params: { task_id: state.diagnosticsTaskId },
      });
      const { info, TaskException: taskException } = response.data;
      const taskState = response.data.state;
      if (taskException !== undefined) {
        commit('setDiagnosticsTaskResult', { ...info, status: TaskStatus.TASK_FAILED });
        const errorDescription = taskException.errorDescription;
        commit('setBackendDiagnosticsErrorMessage', errorDescription);
        return TaskStatus.TASK_FAILED;
      }
      if (taskState === TaskStatus.TASK_COMPLETED) {
        // Store result
        commit('setDiagnosticsTaskResult', { ...info, status: TaskStatus.TASK_COMPLETED });

        if (info.buildErrors.isError) {
          commit('addWarning', {
            msg: info.buildErrors.msg,
            nodeId: info.buildErrors.nodeId,
            subflowId: info.buildErrors.subflowId,
            warningType: MAKE_BOT_FAILURE,
          });
        }

        for (const error of info.syntaxErrors) {
          commit('addWarning', { ...error, warningType: SYNTAX_ERROR });
        }
        for (const error of info.contentErrors) {
          commit('addWarning', { ...error, warningType: CONTENT_ERROR });
        }

        return TaskStatus.TASK_COMPLETED;
      }
      if (taskState === TaskStatus.PENDING) {
        commit('setDiagnosticsTaskResult', { status: TaskStatus.PENDING });
        return TaskStatus.PENDING;
      }
      return TaskStatus.UNKNOWN_STATUS;
    } catch {
      return TaskStatus.TASK_FAILED;
    }
  },
  async checkForUnsupportedChatActions({ commit, rootState }) {
    const configPlatforms = rootState.botManipulation.activeBot.config.platforms;
    const nodes = rootState.botManipulation.activeBot.nodes;
    for (const node of Object.values(nodes)) {
      const foundBadActivities = new Set();
      for (const activity of Object.values(node.activities)) {
        if (activity.type === CHAT_ACTION) {
          const chatAction = chatActions.find((a) => a.id === activity.id);

          const missing = configPlatforms.filter((e) => !chatAction.supportedIn.includes(e));
          if (!configPlatforms.length) {
            foundBadActivities.add(chatAction.id);
            commit('addWarning', {
              nodeId: node.id,
              activityId: chatAction.id,
              warningType: UNSUPPORTED_CHAT_ACTION,
              msg: `Platform action "${chatAction.name}" not supported. Choose platforms in configuration.`,
            });
          } else if (missing.length && !foundBadActivities.has(chatAction.id)) {
            foundBadActivities.add(chatAction.id);
            commit('addWarning', {
              nodeId: node.id,
              activityId: chatAction.id,
              warningType: UNSUPPORTED_CHAT_ACTION,
              msg: `Platform action "${chatAction.name}" not supported on following platforms: ${missing.join(', ')}`,
            });
          }
        }
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  async validateCompoundResponses({ commit, rootState }) {
    const nodes = rootState.botManipulation.activeBot.nodes;

    function validUrl(value) {
      return value.startsWith('http://') || value.startsWith('https://');
    }

    for (const node of Object.values(nodes)) {
      for (const activity of Object.values(node.activities)) {
        if (activity.type === COMPOUND_RESPONSE) {
          const compoundType = compoundResponseTypes.find(
            (crt) => crt.id === activity.compoundType,
          );
          if (compoundType.requires.includes('message')
            && !activity.text.trim().length) {
            commit('addWarning', {
              nodeId: node.id,
              activityId: activity.id,
              warningType: COMPOUND_RESPONSE_ERROR,
              msg: `Rich response (${compoundType.name}) message cannot be empty.`,
            });
          }
          if (compoundType.requires.includes('buttons')
            && !Object.values(activity.buttons).length) {
            commit('addWarning', {
              nodeId: node.id,
              activityId: activity.id,
              warningType: COMPOUND_RESPONSE_ERROR,
              msg: `Rich response (${compoundType.name}) must have at least one button.`,
            });
          }
          if (compoundType.requires.includes('title')
            && !activity.title.trim().length) {
            commit('addWarning', {
              nodeId: node.id,
              activityId: activity.id,
              warningType: COMPOUND_RESPONSE_ERROR,
              msg: `Rich response (${compoundType.name}) title cannot be empty.`,
            });
          }
          if (compoundType.requires.includes('link')
            && !activity.link
            && !validUrl(activity.link)) {
            commit('addWarning', {
              nodeId: node.id,
              activityId: activity.id,
              warningType: COMPOUND_RESPONSE_ERROR,
              msg: `Rich response (${compoundType.name}) must have a valid link.`,
            });
          }
          if (compoundType.requires.includes('image')
            && !activity.imageURL
            && !validUrl(activity.imageURL)) {
            commit('addWarning', {
              nodeId: node.id,
              activityId: activity.id,
              warningType: COMPOUND_RESPONSE_ERROR,
              msg: `Rich response (${compoundType.name}) must have a valid image link.`,
            });
          }
          if (compoundType.id === 'ZendeskPanel') {
            if (activity.text.length > 150) {
              commit('addWarning', {
                nodeId: node.id,
                activityId: activity.id,
                warningType: COMPOUND_RESPONSE_ERROR,
                msg: 'Rich response content must not have more than 150 characters.',
              });
            }
          }
          if (compoundType.supports.includes('buttons')) {
            const problemButtons = [];
            Object.values(activity.buttons).forEach((button, index) => {
              let isValid = true;
              if (button.type === 'Link') {
                if (!validUrl(button.link)) {
                  isValid = false;
                }
              }
              if (!button.text.trim().length) {
                isValid = false;
              }
              if (!isValid) {
                problemButtons.push(index + 1); // users skip 0 when counting
              }
            });
            if (problemButtons.length > 0) {
              let msg = 'All rich response buttons must have a valid link and text, but the';
              msg = `${msg} following do not: ${problemButtons.toString()}`;
              commit('addWarning', {
                nodeId: node.id,
                activityId: activity.id,
                warningType: COMPOUND_RESPONSE_ERROR,
                msg,
              });
            }
          }
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
