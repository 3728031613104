<template>
  <b-modal
    id="searchModal"
    title="Search nodes"
    ok-variant="primary"
    ok-title="Search"
    scrollable
    size="lg"
    cancel-title="Close"
    @shown="focusInput"
    @ok.prevent="search"
    @hide="resetFilters"
  >
    <b-form-group
      id="searchLabel"
      class="mb-1"
      description="Press enter to search - searches in names, responses, actions, variables, etc."
      label="Type the text you would like to search for"
      label-for="searchInput"
    >
      <b-form-input
        id="searchInput"
        ref="inputText"
        v-model="query"
        type="text"
        placeholder="Enter text"
        @keyup.enter.native="search"
      />
    </b-form-group>
    <b-form-checkbox
      v-model="showFilters"
      name="check-button"
      switch
    >
      Filters
    </b-form-checkbox>
    <hr class="my-1">
    <div v-if="showFilters">
      <b-form-checkbox
        v-model="caseSensitive"
        class="mb-1"
      >
        Case Sensitive Search
      </b-form-checkbox>
      <b-form-group
        label="Where to search"
        class="mb-1"
      >
        <b-form-radio-group
          v-model="whereToSearch"
          :options="[{ value: 'all', text: 'All' }, { value: 'onlyResponse', text: 'Only Responses' }]"
        />
      </b-form-group>
      <span
        size="sm"
      >Node type</span>
      <b-form-checkbox-group
        v-model="nodeTypesToSearch"
        class="my-1"
        :options="nodeTypeOptions"
      />
      <span
        size="sm"
      >Graph node type</span>
      <b-form-checkbox-group
        v-model="nodeGraphTypes"
        class="my-1"
        :options="nodeGraphOptions"
      />
      <hr class="mt-1 mb-2">
    </div>

    <p>Nodes found: {{ results.length }}</p>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in results"
        :key="index"
        button
        variant="info"
        @click="event => nav(item.id, event)"
      >
        <p class="mb-0">
          Nodes Name: {{ item.name }}
        </p>
        <p class="mb-0">
          Response Content: {{ item.response }}
        </p>
      </b-list-group-item>
    </b-list-group>
    <template #modal-footer="{ cancel }">
      <div class="float-right">
        <b-button
          class="mr-2"
          @click="cancel"
        >
          Close
        </b-button>
        <b-button
          variant="primary"
          @click="search"
        >
          <b-spinner
            v-if="searching"
            small
          />
          <font-awesome-icon
            v-else
            icon="search"
          />
          <span class="ml-1">Search</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACTION, RESPONSE, SET_VARIABLE, CHAT_ACTION, CONTROL_FLOW, METRIC_SIGNAL,
  ENCRYPT, COMPOUND_RESPONSE,
} from '@/js/activity';
import { nodeTypes } from '@/js/constants';

export default {
  name: 'NodeSearch',
  data() {
    return {
      query: '',
      caseSensitive: false,
      whereToSearch: 'all',
      nodeTypeOptions: [
        { text: 'Standard', value: nodeTypes.SIMPLE },
        { text: 'Smart', value: nodeTypes.MULTIPLE_CHOICE },
        { text: 'Small talk', value: nodeTypes.SMALLTALK },
        { text: 'Q & A', value: nodeTypes.QA },
        { text: 'Subflow', value: nodeTypes.SUBFLOW },
      ],
      nodeTypesToSearch: [
        // We just include all types by default
        nodeTypes.SIMPLE,
        nodeTypes.MULTIPLE_CHOICE,
        nodeTypes.SMALLTALK,
        nodeTypes.QA,
        nodeTypes.SUBFLOW,
      ],
      results: [],
      searching: false,
      nodeGraphOptions: [
        'Connected',
        'Detached',
      ],
      nodeGraphTypes: [
        'Connected',
        'Detached',
      ],
      showFilters: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getSubFlows',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodesAsList',
      'specialNodes',
      'getChildrenNodes',
      'getCustomFallbackNodes',
      'getAuthFallbackNodes',
    ]),
    allNodes() {
      const specialNodes = this.specialNodes.filter((node) => node.id !== 'final');
      return this.nodesAsList.concat(specialNodes);
    },
  },
  methods: {
    resetFilters() {
      this.showFilters = false;
      this.caseSensitive = false;
      this.whereToSearch = 'all';
      this.nodeTypesToSearch = [
        nodeTypes.SIMPLE,
        nodeTypes.MULTIPLE_CHOICE,
        nodeTypes.SMALLTALK,
        nodeTypes.QA,
        nodeTypes.SUBFLOW,
      ];
      this.nodeGraphTypes = ['Connected', 'Detached'];
    },
    // Escaping regular expressions as suggested by
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
    escapeRegExp(str) {
      return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    async search() {
      this.results = [];

      this.searching = true;
      const flags = this.caseSensitive ? '' : 'i';
      const re = new RegExp(this.escapeRegExp(this.query), flags);
      for (const node of Object.values(this.allNodes)) {
        if (!this.nodeTypesToSearch.includes(node.options.nodeType)) {
          continue;
        }
        // Make a list of node name, response, actions, variables, etc. to search in.
        // only include name of node if we are searching for all fields
        const toSearch = (this.whereToSearch === 'all') ? [node.name] : [];
        // Search in activities.
        for (const activity of Object.values(node.activities)) {
          if (activity.type === RESPONSE) {
            toSearch.push(activity.text);
          } else if (activity.type === COMPOUND_RESPONSE) {
            toSearch.push(activity.text);
            toSearch.push(activity.title);
            toSearch.push(activity.link);
            for (const button of Object.values(activity.buttons)) {
              toSearch.push(button.text);
              toSearch.push(button.link);
            }
          } else if ((activity.type === ACTION || activity.type === CHAT_ACTION)
              && this.whereToSearch === 'all') {
            toSearch.push(activity.name);
            if (activity.target) {
              toSearch.push(activity.target);
            }
            // Also add parameters of actions:
            for (const param of activity.params) {
              toSearch.push(param.key);
              toSearch.push(param.value);
            }
            if (activity.type === ACTION) {
              // CHAT_ACTION activities don't have headers.
              for (const header of activity.headers) {
                toSearch.push(header.key);
                toSearch.push(header.value);
              }
            }
          } else if (activity.type === SET_VARIABLE && this.whereToSearch === 'all') {
            toSearch.push(activity.key);
            toSearch.push(activity.code);
          } else if (activity.type === METRIC_SIGNAL && this.whereToSearch === 'all') {
            toSearch.push(activity.label);
          } else if (activity.type === ENCRYPT && this.whereToSearch === 'all') {
            toSearch.push(activity.code);
            toSearch.push(activity.target);
          } else if (activity.type === CONTROL_FLOW && this.whereToSearch === 'all') {
            if (['if', 'else if'].includes(activity.name)) {
              toSearch.push(activity.statement);
            } else if (activity.name === 'for') {
              toSearch.push(activity.iterable);
              toSearch.push(activity.item);
            }
          }
        }
        if (this.whereToSearch === 'all') {
          // Search in requirements
          for (const requirement of Object.values(node.requirements)) {
            toSearch.push(requirement);
          }
          // Search in subflow nodes for e.g. the subflow name.
          if (Object.prototype.hasOwnProperty.call(node, 'subFlowMap')
              && node.subFlowMap.subFlowID) {
            // Search for the subflow name
            const subflowName = this.getSubFlows.find(
              (x) => x.id === node.subFlowMap.subFlowID,
            ).config.name;
            toSearch.push(subflowName);
            // Search for variable input and output
            for (const [name, code] of Object.entries(node.subFlowMap.input.vars)) {
              toSearch.push(name);
              toSearch.push(code);
            }
            for (const [name, code] of Object.entries(node.subFlowMap.output.vars)) {
              toSearch.push(name);
              toSearch.push(code);
            }
          }
        }
        // Search in multiple choice node texts
        if (node.options.nodeType === nodeTypes.MULTIPLE_CHOICE) {
          toSearch.push(node.options.optionsQuery);
          toSearch.push(node.options.confirmQuery);
          toSearch.push(node.options.otherText);
          for (const displayName of Object.values(node.options.displayNames)) {
            toSearch.push(displayName);
          }
        }

        // Actually search
        const didMatch = toSearch.some((text) => re.test(text));
        if (didMatch) {
          let response = '';
          for (const activity of Object.values(node.activities)) {
            if (activity.type === RESPONSE) {
              response += ` ${activity.text}`;
            }
            if (activity.type === COMPOUND_RESPONSE) {
              response += ` ${activity.text}`;
              response += ` ${activity.title}`;
              response += ` ${activity.link}`;
              for (const button of Object.values(activity.buttons)) {
                response += ` ${button.text}`;
                response += ` ${button.link}`;
              }
            }
          }
          if (this.filterGraphTypes(node.id)) {
            const temp = { id: node.id, name: node.name, response };
            this.results.push(temp);
          }
        }
      }
      if (this.results.length === 0) {
        await new Promise((r) => { setTimeout(r, 500); }); // enforce spinner showing
      }
      this.searching = false;
    },
    detached() {
      const activeBot = this.$store.state.botManipulation.activeBot;
      const connectedIds = new Set(this.getChildrenNodes(activeBot.root, []).concat(
        this.getCustomFallbackNodes,
        this.getAuthFallbackNodes,
      ));
      const detachedNodes = [];
      for (const {
        id, name, activities, options, preds,
      } of Object.values(activeBot.nodes)) {
        if (options.nodeType === nodeTypes.QA || options.global || preds.length !== 0) {
          continue;
        }
        if (!(connectedIds.has(id))) {
          let response = '';
          for (const activity of Object.values(activities)) {
            if (activity.type === RESPONSE) {
              response += ` ${activity.text}`;
            }
          }
          detachedNodes.push({ id, name, response });
        }
      }
      return detachedNodes;
    },

    // check if detached/connected filters are set, remove nodes that dont match those filters
    filterGraphTypes(id) {
      const detachedIds = this.detached().map((e) => e.id);
      if (this.nodeGraphTypes.includes('Detached')) {
        if (detachedIds.includes(id)) {
          return true;
        }
      }
      if (this.nodeGraphTypes.includes('Connected')) {
        if (!detachedIds.includes(id)) {
          return true;
        }
      }
      return false;
    },
    nav(id, event) {
      this.openCtrlLink(this.editNodeLink(id), event);
    },
    focusInput() {
      this.$refs.inputText.focus();
    },
  },
};
</script>
