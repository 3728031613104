export default {
  breadcrumbs: {
    home: 'Home',
  },
  notifications: {
    seeNotifications: 'Click to see current notifications',
    noNotifications: 'No notifications',
  },
  sidebar: {
    enableCompactSidebar: 'Enable Compact Sidebar',
    disableCompactSidebar: 'Disable Compact Sidebar',
  },
  userMenu: {
    buttonTitle: 'Click to perform user options like signing out',
    greeting: 'Hi',
    logOut: 'Log out',
    noName: 'Missing name',
  },
}
