<template>
  <div class="d-inline">
    <b-button
      v-b-modal="`voicebot-tag-modal-${uid}`"
      size="sm"
    >
      Insert SSML
    </b-button>
    <b-modal
      :id="`voicebot-tag-modal-${uid}`"
      :size="phonemeHelpActive ? 'xl' : ''"
      :ok-disabled="$v.tagAttributes.$error"
      ok-title="Insert Tag"
      title="Advanced Voice Response"
      return-focus="textArea"
      @hidden="reset"
      @ok="confirm"
    >
      <b-row>
        <b-col
          :cols="phonemeHelpActive ? 6 : 12"
        >
          <p>
            Here you can easily generate advanced voice responses using the SSML standard.
            Your result will be inserted into your response activity.
          </p>
          <b-form-group
            label="List of tags"
            :description="selectedTag ? selectedTagObject.description : ''"
          >
            <b-form-select
              v-model="selectedTag"
              :options="tagOptions"
              :required="true"
              @input="prepareSelections"
            >
              <template #first>
                <b-form-select-option
                  value=""
                  disabled
                >
                  Select a tag
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <template v-if="selectedTag !== ''">
            <b-form-group
              v-for="(attributeObj, attributeKey) in selectedTagAttributes"
              :key="`${selectedTag}-${attributeKey}`"
              :label="attributeObj.label || attributeKey"
              :description="attributeObj.description || ''"
              label-class="text-capitalize"
            >
              <b-form-row>
                <b-col
                  :cols="attributeKey === 'ph' ? 10 : 12"
                >
                  <b-form-select
                    v-if="attributeObj.type === 'select'"
                    :required="attributeObj.required"
                    :options="getAttributeOptions(attributeObj)"
                    value=""
                    @input="(val) => setAttribute(attributeKey, val)"
                  >
                    <template #first>
                      <b-form-select-option
                        value=""
                        disabled
                      >
                        Select an option
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-input
                    v-else
                    :required="attributeObj.required"
                    :type="attributeObj.type"
                    :placeholder="`Enter ${attributeObj.label || attributeKey}`"
                    @input="(val) => setAttribute(attributeKey, val)"
                  />
                </b-col>
                <b-col
                  v-if="attributeKey === 'ph'"
                >
                  <b-button
                    block
                    @click="togglePhonemeHelp"
                  >
                    <font-awesome-icon icon="circle-info" /> Help
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form-group>
          </template>
        </b-col>
        <b-col
          v-if="phonemeHelpActive"
          cols="6"
        >
          <div style="overflow:auto;max-height:500px;">
            <PhonemeTables :language="getRoutingLanguage" />
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { tagOptionList } from '@/js/voicebotTags';
import PhonemeTables from '@/components/PhonemeTables.vue';

export default {
  name: 'AdvancedVoiceResponse',
  components: {
    PhonemeTables,
  },
  mixins: [validationMixin],
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTag: '',
      tagAttributes: {},
      phonemeHelpActive: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config/voice', ['getText2speech']),
    ...mapGetters('botManipulation/activeBot/config', ['getRoutingLanguage']),
    provider() { return this.getText2Speech || 'azure'; },
    tagOptions() {
      return tagOptionList
        .filter(({ support }) => support.includes(this.provider))
        .map(({ name, id }) => ({
          text: name,
          value: id,
        }));
    },
    selectedTagObject() {
      if (this.selectedTag === '') return null;
      return tagOptionList.find(({ id }) => id === this.selectedTag);
    },
    selectedTagAttributes() {
      if (this.selectedTag === '') return [];
      return this.selectedTagObject.attributes;
    },
    finalOutput() {
      if (this.selectedTag === '') return '';
      return this.selectedTagObject.output(this.tagAttributes);
    },
  },
  mounted() {
    this.$v.$touch();
  },
  methods: {
    togglePhonemeHelp() {
      this.phonemeHelpActive = !this.phonemeHelpActive;
    },
    prepareSelections() {
      this.tagAttributes = {};
      Object.keys(this.selectedTagAttributes).forEach((attributeName) => {
        this.$set(this.tagAttributes, attributeName, '');
      });
    },
    getAttributeOptions(attributeObj) {
      return Object.entries(attributeObj.options)
        .map(([optionValue, optionText]) => ({ value: optionValue, text: optionText }));
    },
    setAttribute(attribute, value) {
      this.$set(this.tagAttributes, attribute, value);
      this.$v.tagAttributes.$touch();
    },
    reset() {
      this.selectedTag = '';
      this.tagAttributes = {};
      this.phonemeHelpActive = false;
    },
    confirm() {
      this.$emit('insert', this.finalOutput);
      this.$bvModal.hide(`voicebot-tag-modal-${this.uid}`);
    },
  },
  validations() {
    return {
      selectedTag: {
        required,
      },
      tagAttributes: {
        required,
        unit: {
          required: requiredIf(() => this.selectedTag === 'break'),
        },
        time: {
          required: requiredIf(() => this.selectedTag === 'break'
                                  || this.selectedTag === 'time'),
        },
        path: {
          required: requiredIf(() => this.selectedTag === 'audio'),
        },
        text: {
          required: requiredIf(() => this.selectedTag === 'spellout'
                                  || this.selectedTag === 'paragraph'
                                  || this.selectedTag === 'sentence'
                                  || this.selectedTag === 'fraction'
                                  || this.selectedTag === 'phonemeamazon'
                                  || this.selectedTag === 'phonemeazure'),
        },
        format: {
          required: requiredIf(() => this.selectedTag === 'date'
                                  || this.selectedTag === 'time'),
        },
        date: {
          required: requiredIf(() => this.selectedTag === 'date'),
        },
        phone: {
          required: requiredIf(() => this.selectedTag === 'telephone'
                                  || this.selectedTag === 'telephoneazure'),
        },
        code: {
          required: requiredIf(() => this.selectedTag === 'telephoneazure'),
        },
        alphabet: {
          required: requiredIf(() => this.selectedTag === 'phonemeamazon'
                                  || this.selectedTag === 'phonemeazure'),
        },
        ph: {
          required: requiredIf(() => this.selectedTag === 'phonemeamazon'
                                  || this.selectedTag === 'phonemeazure'),
        },
      },
    };
  },
};
</script>
