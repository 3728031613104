import classifier from './classifier';
import languageModel from './languageModel';
import labelExternal from './labelExternal';
/*
 * Disclaimer: NLU abbreviates "Natural Language Understanding"
 */

export default {
  namespaced: true,
  modules: {
    classifier, languageModel, labelExternal,
  },
};
