<template>
  <div>
    <h6>
      Usage ({{ usage.length }})
    </h6>
    <b-list-group style="max-height: 200px; overflow-y:auto;">
      <b-list-group-item
        v-for="(item, index) in usage"
        :key="index"
        v-b-tooltip.hover.noninteractive.viewport
        title="View node"
        :to="editNodeLink(item.nodeId)"
      >
        {{ nameOfId(item.nodeId) }} <font-awesome-icon icon="arrow-right" class="mx-2" /> {{ camelStrToText(item.type) }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { camelStrToText } from 'supwiz/util/data';

export default {
  name: 'PhraseUsage',
  props: {
    usage: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
  },
  methods: {
    camelStrToText,
  },
};
</script>
