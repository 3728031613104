<template>
  <div
    class="d-flex align-items-center"
  >
    <b-form-checkbox
      v-model="approvedValue"
      switch
    />
    <font-awesome-icon
      :icon="value ? 'check-circle' : 'exclamation-circle'"
      :class="`${value ? 'text-success' : 'text-warning'} mr-1`"
    />
    {{ approvedValue ? 'Approved' : 'Draft' }}
  </div>
</template>

<script>

export default {
  name: 'ApproveButton',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
      // What is the type of what this component approve
    },
  },
  computed: {
    approvedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style scoped>
</style>
