import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';

const lmState = {
  languageModelIds: [],
  languageModels: {},
};

export const lmGetters = {
  languageModels: (state) => state.languageModels,
};

const mutations = {
  setLanguageModels(state, { models }) {
    const modelIds = models.map(({ id }) => id);
    const modelDict = {};
    for (const model of models) {
      modelDict[model.id] = model;
    }
    Vue.set(state, 'languageModelIds', modelIds);
    Vue.set(state, 'languageModels', modelDict);
  },
};

const actions = {
  async fetchLanguageModels({ rootState, commit }) {
    const resp = await axios.get(endpoints.languageModels, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    if (resp.status === 200) {
      commit('setLanguageModels', resp.data);
    }
  },
};

export default {
  namespaced: true,
  state: lmState,
  getters: lmGetters,
  mutations,
  actions,
};
