import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';

const adminState = {
  users: {},
  groups: {},
  objects: {},
  isFetchingUsers: false,
  isFetchingGroups: false,
};

const getters = {
  getUsernameFromId: (state) => (userId) => {
    if (userId in state.users) {
      return state.users[userId].username;
    }
    return '(User not found)';
  },
  getGroupNameFromId: (state) => (groupId) => state.groups[groupId]?.name || '(Group not found)',
  getDisplayNameFromId: (state) => (userId) => {
    if (userId in state.users) {
      const user = state.users[userId];
      if (user.first_name && user.last_name) {
        return `${user.first_name} ${user.last_name}`;
      }
      if (user.first_name) {
        return user.first_name;
      }
      return user.username;
    }
    return 'System';
  },
  getObjectFromId: (state) => (id) => {
    if (Object.values(state.objects).length) {
      return state.objects.find((e) => e.id === id).name;
    } return null;
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  updateUser(state, { id, user }) {
    Vue.set(state.users, id, user);
  },
  setGroups(state, groups) {
    groups.forEach((e) => {
      Vue.set(state.groups, e.id, e);
    });
  },
  updateGroup(state, { id, group }) {
    Vue.set(state.groups, id, group);
  },
  deleteGroup(state, id) {
    Vue.delete(state.groups, id);
  },
  setIsFetchingUsers(state, value) {
    state.isFetchingUsers = value;
  },
  setIsFetchingGroups(state, value) {
    state.isFetchingGroups = value;
  },
};

const actions = {
  async fetchUsers({ rootState, commit }) {
    commit('setIsFetchingUsers', true);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    const resp = await axios.get(endpoints.userList, config);
    commit('setUsers', resp.data.result);
    commit('setIsFetchingUsers', false);
  },
  async fetchUser({ rootState }, userId) {
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    const resp = await axios.get(endpoints.user + userId, config);
    return resp.data;
  },
  async fetchGroups({ rootState, commit }) {
    commit('setIsFetchingGroups', true);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    const resp = await axios.get(endpoints.group, config);
    commit('setGroups', resp.data);
    commit('setIsFetchingGroups', false);
  },
  async updateGroup({ rootState, commit, dispatch }, group) {
    commit('setIsFetchingGroups', true);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    await axios.put(`${endpoints.group}${group.id}/`, group, config);
    dispatch('fetchGroups');
    commit('setIsFetchingGroups', false);
  },
};

export default {
  namespaced: true,
  state: adminState,
  getters,
  mutations,
  actions,
};
