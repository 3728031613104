<template>
  <div>
    <p>
      The objective of the health page is to help users identify and prevent potential errors
      in a bot and provide guidance on what should be done next to improve health and overall
      performance of a bot. The page is divided into four sections described below
    </p>

    <h5>Improvements</h5>
    <p>
      This section analyzes the most recent conversations and displays various suggested
      improvements that can refine the automation rate of a bot. You can configure analyzed
      period in <b-link v-b-modal.settings-modal>
        health settings.
      </b-link>
    </p>
    <ul>
      <li>
        <strong>Flows with many transfers:</strong> Provides a list of flows in the bot with a
        large fraction of transferred conversations. These are good candidates for improvements.
        You can click on each line to either <em>find examples of transferred conversations</em> or
        <em>explore topics of the transferred questions</em>.
      </li>
      <li>
        <strong>Nodes with many fallbacks:</strong> Provides a list of steps in the bot, where the
        bot often did not understand the user and therefore went to the fallback flow.
      </li>
      <li>
        <strong>Options without matching configured:</strong> Provides a list of nodes in the bot,
        that are used as multiple-choice options, but does not have matching configured. Such nodes
        will work well if the user clicks on the option buttons, but will fail if the user writes
        text manually.
      </li>
    </ul>

    <h5>Errors</h5>
    <p>
      This section contains an overview of potential errors and inconsistencies in a bot.
      <strong>Note that not all errors are detected by this page</strong>. Below you can find
      a description of all the things checked.
    </p>
    <h6>Backend: Does it build?</h6>
    <p>
      Diagnostics will check if the bot builds in the backend. Exceptions are caught and
      send to the frontend. It will show if the exception belongs to a certain node. Bot
      building errors will only show one error at a time, so you should rerun diagnostics
      after fixing one.
    </p>
    <h6>Do the activities have undefined fields?</h6>
    <p>
      Diagnostics will check if activities of a node have fields that are empty, e.g. if the key
      and value of a "Set variable" activity have been defined.
    </p>
    <h6>Is the control flow well defined?</h6>
    <p>
      Diagnostics will check if potential control flow in the nodes is well defined, e.g. that
      an if-else block has the correct order.
    </p>
    <h6>Has a label been chosen for the NLU model?</h6>
    <p>
      Diagnostics will check if a label has been specified for any NLU models chosen in the
      matching section of a node.
    </p>
    <h6>Errors caught in the frontend</h6>
    <p>
      The frontend may recognize and show multiple warnings together. The bot may still build
      in the backend despite these warnings, but could fail dynamically at some point during
      a flow if not handled. Currently, it makes the following checks:
    </p>
    <ul>
      <li>Are two nodes using the same name?</li>
      <li>
        Does a node require authentication even though it has been disabled globally?
      </li>
      <li>Is a node with authentication enabled missing a fallback node?</li>
      <li>
        Has an integration blueprint been modified without the corresponding activities being
        updated accordingly (main bot only).
      </li>
      <li>
        Are there any actions that are not supported on all chosen platforms?
      </li>
    </ul>

    <h5>Hygiene checks</h5>
    <p>
      This section contains a list of actions that can help improve your bot. Disabled/Enabled
      checks and other hygiene-related settings can be configured in
      <b-link v-b-modal.settings-modal>
        health settings.
      </b-link>
    </p>

    <h5>Conversation tests</h5>
    <p>
      Conversation tests can be used to automate quality assurance of your bot while
      adding new flows, questions, and more. This can ensure that old functionality
      does not break (conversation tests are always run using the Mock API of the bot).
    </p>
    <p>
      To create a conversation test, do the following:
    </p>
    <ol>
      <li>
        Run a conversation in the demo-panel on the right and ensure the conversation is as you
        expect.
      </li>
      <li>
        Click <font-awesome-icon icon="vial" /> button and provide a name for the test
        in the popup that appears.
      </li>
    </ol>
  </div>
</template>
<script>
export default { name: 'HealthDescription' };
</script>
