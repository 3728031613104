<template>
  <b-row>
    <b-col>
      <b-card
        title="Data"
        class="shadow-sm r-75"
      >
        <template v-if="status === 'finding'">
          <b-spinner large />
        </template>
        <template v-else-if="status === 'no_labels'">
          There are no more datapoints to sample.
          <font-awesome-icon
            icon="check"
            style="color: green"
          />
        </template>
        <template v-else-if="status === 'error'">
          An error occurred while finding a data point to label.
          <b-link @click="goToDataPoint">
            Click here
          </b-link>
          to try again.
        </template>
        <template v-else-if="dataset.data_count > 0">
          <b-link @click="goToDataPoint">
            Find a random data point to label.
          </b-link>
        </template>
        <template v-else>
          There is currently no data in the dataset.
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { applyThisArgs } from '@/js/storeHelpers';
import endpoints from '@/js/urls';

export default {
  name: 'DataExplorationLabelingEntryPage',
  data() {
    return {
      status: 'finding',
    };
  },
  computed: {
    ...applyThisArgs(
      mapGetters('dataExploration', {
        dataset: 'getDatasetById',
      }),
      'datasetId',
    ),
    ...mapGetters('dataExploration', [
      'forceRandomSample',
    ]),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
  },
  mounted() {
    return this.goToDataPoint();
  },
  methods: {
    async goToDataPoint() {
      this.status = 'finding';
      const url = `${endpoints.dataExplorationSampleDataId + this.datasetId}/`;
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { useRandom: this.forceRandomSample },
      };
      try {
        const { data } = await axios.get(url, config);
        const params = {
          datasetId: this.datasetId,
          dataId: data.id,
          dataType: data.type,
        };
        this.$router.push({ name: 'dataExplorationLabeling', params });
      } catch (e) {
        const { status } = e.response;
        if (status === 404) {
          this.status = 'no_labels';
        } else {
          this.status = 'error';
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
