<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    :advanced-icon="advancedIcon"
    bs-variant="primary"
    @shown="focusInput"
    @toggleExtraContents="toggleExtraContents"
  >
    <template #icon>
      <font-awesome-icon icon="link" />
    </template>
    <template #header>
      {{ cappedActionName }}
    </template>
    <SuggestArticles
      v-if="actionId === 'supsearch.suggestArticles'"
      :data="suggestArticlesData"
      @input="v => suggestArticlesInput(v)"
    />

    <template v-else-if="actionId === 'supsearch.suggestionFeedback'">
      <DescriptionBox>
        This activity will send user-feedback to SupSearch for a given article suggestion
        (originally provided by SupSearch). Provide the SuggestionID of the original article
        suggestion and information about whether the article was useful or not (boolean value).
      </DescriptionBox>
      <ActivityFormGroup label="Suggestion ID">
        <botscript-validation
          v-model="suggestionId"
          :expanded="false"
          :validations="['empty', 'typecheck-nonbool']"
        />
      </ActivityFormGroup>
      <ActivityFormGroup label="Was suggestion helpful">
        <botscript-validation
          v-model="wasHelpful"
          :expanded="false"
          :validations="['empty']"
        />
      </ActivityFormGroup>
    </template>
    <ActivityFormGroup
      v-if="showExtraContents && hasMockResponse"
      label="Mock response"
    >
      <botscript-validation
        v-model="mockResponse"
        :expanded="false"
        :validations="['empty']"
      />
    </ActivityFormGroup>
    <ActivityFormGroup
      v-if="target !== null"
      label="Response variable"
      class="mt-1"
      label-class="font-weight-bold"
    >
      <VariableName
        :value="target"
        @input="target => setTarget({ target })"
      />
    </ActivityFormGroup>
  </base-activity>
</template>
<script>
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import { truncateString, includeOrRemoveParams, changeOrAddParam } from '@/js/utils';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import supWizActions from '@/js/supWizActions';
import VariableName from '@/components/VariableName.vue';
import SuggestArticles from '@/pages/EditNode/activity/SupWizActivity/SuggestArticles.vue';
import DescriptionBox from './DescriptionBox.vue';
import ActivityFormGroup from './ActivityFormGroup.vue';

function mapParams(params) {
  const map = {};
  for (const param of params) {
    map[param] = {
      get() {
        return this.getParam(param);
      },
      set(value) {
        this.setParam(param, value);
      },
    };
  }
  return map;
}

export default {
  name: 'SupWizActivity',
  components: {
    BaseActivity,
    VariableName,
    BotscriptValidation,
    DescriptionBox,
    ActivityFormGroup,
    SuggestArticles,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showExtraContents: false,
      suggestArticlesFields: ['ranker', 'query', 'presentedArticlesNumber', 'presentAs',
        'articleThreshold', 'responseMessage', 'additionalMessage', 'useFeedback', 'textDescription'],
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      name: 'activityName',
      params: 'activityParams',
      target: 'activityTarget',
      actionId: 'activityId',
    }), 'nodeId', 'activityId'),
    ...mapState('supsearch', ['rankers', 'isFetchingRankers']),
    ...mapGetters('userSettings', ['getBotStudioSettings']),
    graphView() {
      return this.$route.name === 'graph';
    },
    suggestArticlesData() {
      const data = {};
      this.suggestArticlesFields.forEach((field) => {
        data[field] = this.getParam(field);
      });
      return data;
    },
    defaultAction() {
      const defaultAction = supWizActions.find((a) => a.id === this.actionId);
      if (!defaultAction) {
        return supWizActions.find((a) => a.id === 'MISSING_AI_ACTION');
      }
      return defaultAction;
    },
    shouldAllowExpansion() {
      return this.defaultAction.allowsExpansion;
    },
    cappedActionName() {
      if (!this.target || !this.getBotStudioSettings.show_variable_assignment) {
        return truncateString(this.name, 60);
      }
      return truncateString(`${this.target} = ${this.name}`, 60);
    },
    mockResponseParam() {
      return this.defaultAction.extraParams.find((p) => p.name === 'mockResponse');
    },
    hasMockResponse() {
      return this.mockResponseParam !== undefined;
    },
    advancedIcon() {
      if ((this.defaultAction.extraParams || []).length > 0) {
        return this.showExtraContents ? 'angle-up' : 'angle-down';
      }
      return '';
    },
    normalParams() {
      // Old bots may be missing added attributes
      if (!this.defaultAction) {
        return this.params;
      }
      const allParams = this.params.slice();
      for (const pDefault of this.defaultAction.params) {
        if (!allParams.some((p) => p.key === pDefault.name)) {
          allParams.push({ key: pDefault.name, value: pDefault.default });
        }
      }
      return allParams;
    },
    filteredParams() {
      return includeOrRemoveParams(this.normalParams,
        this.showExtraContents,
        this.defaultAction.extraParams);
    },
    ...mapParams(['ranker', 'query', 'presentedArticlesNumber', 'presentAs', 'articleThreshold',
      'responseMessage', 'additionalMessage', 'useFeedback', 'textDescription', 'suggestionId', 'wasHelpful',
      'mockResponse']),
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setTarget: 'setActivityTarget',
      setParams: 'setActivityParams',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    ...mapActions('supsearch', ['fetchRankers']),
    suggestArticlesInput(v) {
      this.setParam(v.param, v.value);
    },
    getParam(key) {
      return this.filteredParams.find((e) => e.key === key).value;
    },
    setParam(key, value) {
      const newParams = changeOrAddParam(this.params, key, value);
      this.setParams({ params: newParams });
    },
    focusInput() {
      if (!this.rankers.length) {
        this.fetchRankers();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
    toggleExtraContents() {
      this.showExtraContents = !this.showExtraContents;
    },
  },
};
</script>
