import { render, staticRenderFns } from "./TooltippedText.vue?vue&type=template&id=0cac1468&scoped=true"
import script from "./TooltippedText.vue?vue&type=script&lang=js"
export * from "./TooltippedText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cac1468",
  null
  
)

export default component.exports