<template>
  <div class="p-3">
    <h4>Re-mapped bot secrets</h4>
    In this section you can re-map/change secrets used in the main bot. When re-mapping a secret,
    all uses of the secret (in integrations and subflow integrations) will be replaced by the
    re-mapped secret.
    <br>Note: A secret can only be re-mapped to other secrets having the same type.

    <b-table
      show-empty
      empty-text="No secrets in bot"
      :items="items"
      :fields="fields"
      class="mt-3"
    >
      <template #cell(replacement)="data">
        <template v-if="data.item.replacement">
          {{ data.value }}
        </template>
        <span
          v-else
          class="text-muted"
        >
          Secret is not re-mapped in this variant
        </span>
      </template>
      <template #cell(actions)="data">
        <b-btn-group>
          <b-btn
            variant="primary"
            @click.stop="setEditSecret(data.item.id)"
          >
            Edit
          </b-btn>
          <b-btn
            variant="danger"
            :disabled="clearDisabled(data.item.id)"
            @click="clearSecret(data.item.id)"
          >
            Clear
          </b-btn>
        </b-btn-group>
      </template>
    </b-table>
    <b-modal
      id="secretModal"
      ref="secretModal"
      title="Select bot secret"
      ok-title="Save"
      :ok-disabled="$v.modalSecret.$invalid || isVariantsSaving"
      @ok="setSecret"
    >
      <b-form>
        <b-form-group
          label="Original secret"
        >
          <b-input
            :value="modalSecret.originalName"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Replacement secret"
        >
          <b-form-select
            v-model="modalSecret.replacement"
            :options="replacementOptions"
            :state="!$v.modalSecret.replacement.$invalid"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import {
  mapGetters,
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  name: 'VariantSecrets',
  mixins: [validationMixin],
  data() {
    return {
      fields: [
        'name',
        'type',
        'replacement',
        { key: 'actions', class: 'text-center' },
      ],
      modalSecret: {
        original: null,
        originalName: null,
        replacement: null,
      },
    };
  },
  computed: {
    ...mapGetters('botSecrets', [
      'getBotSecrets',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    ...mapGetters('variants', [
      'selectedVariantSecretMapping',
      'isVariantsSaving',
    ]),
    secrets() {
      return Object.entries(this.selectedVariantSecretMapping).map(
        (x) => ({ original: x[0], replacement: x[1] }),
      );
    },
    botSecretsList() {
      return this.getBotSecrets(this.activeBotId);
    },
    botSecrets() {
      return Object.fromEntries(this.botSecretsList.map((x) => [x.id, x]));
    },
    items() {
      return this.botSecretsList.map(
        (x) => ({
          id: x.id,
          name: x.name,
          type: x.secretType,
          replacement: this.botSecrets[this.selectedVariantSecretMapping[x.id]]?.name,
        }),
      );
    },
    replacementOptions() {
      if (this.modalSecret === null) {
        return [];
      }
      return this.botSecretsList.filter(
        (x) => x.id !== this.modalSecret.original
          && x.secretType === this.botSecrets[this.modalSecret.original]?.secretType,
      ).map((x) => ({ text: x.name, value: x.id }));
    },
  },
  methods: {
    ...mapMutations('variants', ['setSelectedVariantSecretMapping']),
    ...mapActions('variants', ['pushVariantSecrets']),
    setEditSecret(id) {
      this.modalSecret = {
        original: id,
        originalName: this.botSecrets[id].name,
        replacement: this.selectedVariantSecretMapping[id] || null,
      };
      this.$bvModal.show('secretModal');
    },
    clearDisabled(id) {
      return !(id in this.selectedVariantSecretMapping) || this.isVariantsSaving;
    },
    async clearSecret(id) {
      const mapping = cloneDeep(this.selectedVariantSecretMapping);
      if (!(id in mapping)) {
        return;
      }
      delete mapping[id];
      this.setSelectedVariantSecretMapping(mapping);
      await this.pushVariantSecrets();
    },
    async setSecret() {
      const mapping = cloneDeep(this.selectedVariantSecretMapping);
      mapping[this.modalSecret.original] = this.modalSecret.replacement;
      this.setSelectedVariantSecretMapping(mapping);
      await this.pushVariantSecrets();
      this.modalSecret = {
        original: null,
        originalName: null,
        replacement: null,
      };
    },
  },
  validations: {
    modalSecret: {
      replacement: {
        required,
      },
    },
  },
};
</script>
