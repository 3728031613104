<template>
  <span>
    {{ time.toLocaleString() }}
  </span>
</template>

<script>
export default {
  name: 'PrettyDateTime',
  props: {
    rawTime: {
      type: [Number, Date],
      required: true,
    },
  },
  computed: {
    time() {
      return new Date(this.rawTime);
    },
  },
};
</script>

<style scoped>

</style>
