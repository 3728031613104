<template>
  <div>
    <b-container fluid>
      <b-row
        v-if="!alwaysOpen"
        v-b-toggle.collapseOptions
        align-v="center"
        class="cursor-pointer"
        align-h="between"
        @click="toggleArrow"
      >
        <b-col cols="*">
          <h4>
            Options
            <tooltipped-text value="Miscellaneous options for this node" />
          </h4>
          <p v-if="!showCollapse" class="subheader">
            {{ subHeader }}
          </p>
        </b-col>
        <b-col
          v-if="!alwaysOpen"
          cols="*"
        >
          <h4 class="mr-2">
            <span>
              <font-awesome-icon :icon="arrow" size="sm" />
            </span>
          </h4>
        </b-col>
      </b-row>
    </b-container>
    <b-collapse
      id="collapseOptions"
      :visible="alwaysOpen"
    >
      <b-form class="mt-2">
        <b-form-group
          label-cols="4"
          content-cols="8"
          label="Node type"
          class="mb-2"
          label-class="font-weight-bold text-right"
          label-for="select-node-type"
        >
          <b-form-select
            id="select-node-type"
            v-model="nodeType"
            :options="nodeTypes"
            :disabled="isSubflowNode || isQANode"
            required
          />
        </b-form-group>

        <!-- We don't allow traceback for nodes in subflows as the bot framework handles
             subflows in a way that isn't really compatible. -->
        <template v-if="isMainBot && !is_global && !isSubflowNode">
          <b-form-group
            label-cols="4"
            content-cols="8"
            class="mb-2"
            label-class="font-weight-bold text-right"
            label-for="allow-traceback"
          >
            <template #label>
              Traceback
              <tooltipped-text
                value="Allow the bot to trace back the conversation to this node
                  if it would otherwise go to the fallback node.
                  Note: Traceback does not work in subflows. Also note that traceback does
                  not reset the state of the bot including variables and entity extractors."
              />
            </template>
            <b-form-checkbox
              id="allow-traceback"
              v-model="allowTraceback"
              class="pt-2"
              switch
              size="sm"
            >
              Allow traceback
            </b-form-checkbox>
          </b-form-group>
          <b-collapse v-if="allowTraceback && isMainBot" :visible="allowTraceback">
            <b-form-group
              label-cols="4"
              content-cols="8"
              class="mb-2"
              label-class="font-weight-bold text-right"
              label-for="traceback-threshold"
            >
              <template #label>
                Traceback threshold
                <tooltipped-text
                  value="matching must exceed this threshold to trace back the conversation."
                />
              </template>
              <b-form-input
                id="traceback-threshold"
                v-model="tracebackThreshold"
                type="range"
                class="custom-range pt-2"
              />
              <div style="float:right">
                {{ tracebackThreshold }}
              </div>
            </b-form-group>
          </b-collapse>
        </template>

        <b-form-group
          v-if="is_global && !isSmallTalkNode"
          label-cols="4"
          content-cols="8"
          label="Activation threshold"
          class="mb-2"
          label-class="font-weight-bold text-right"
          label-for="globalThreshold"
        >
          <b-form-input
            id="globalThreshold"
            v-model="globalThreshold"
            type="range"
            class="custom-range pt-2"
            max="1"
            min="0"
            step="0.01"
          />
          <div style="float:right">
            {{ Number(globalThreshold * 100).toFixed(0) }}%
          </div>
        </b-form-group>

        <b-form-group
          v-if="isSmallTalkNode"
          label-cols="4"
          content-cols="8"
          label="Activation threshold"
          class="mb-2"
          label-class="font-weight-bold text-right"
          label-for="smallTalkThreshold"
        >
          <b-form-input
            id="smallTalkThreshold"
            v-model="smallTalkThreshold"
            type="range"
            class="custom-range pt-2"
            max="1"
            min="0"
            step="0.01"
          />
          <div style="float:right">
            {{ smallTalkThreshold }}
          </div>
        </b-form-group>
        <b-form-group
          v-if="isSmartNode && isMainBot && !isStrictMpc"
          label-cols="4"
          content-cols="8"
          class="mb-2"
          label="Node classifier"
          label-class="font-weight-bold text-right"
        >
          <edit-node-classifier :node-id="nodeId" />
        </b-form-group>

        <template v-if="isSmartNode">
          <hr>
          <h4>
            <span v-if="!isStrictMpc">Smart node options</span>
            <span v-else>Multiple choice options</span>
          </h4>
          <b-tabs fill>
            <b-tab
              ref="smart-node-presentation-tab"
              class="my-2"
              title="Presentation"
            >
              <b-row v-if="isSmartNode && !isStrictMpc">
                <b-col cols="6">
                  <b-form-group class="mb-0">
                    <label
                      class="font-weight-bold"
                      for="nodeAutoThreshold"
                    >
                      Direct jump threshold
                      <tooltipped-text
                        value="When matching on input exceeds this threshold, conversation jumps
                         directly to option instead of presenting list of options to user.
                          This threshold is prioritized over the fallback threshold"
                      />
                    </label>
                    <input
                      id="nodeAutoThreshold"
                      v-model="nodeAutoThreshold"
                      type="range"
                      class="custom-range"
                    >
                    <div style="float:right">
                      <div v-if="Number(nodeAutoThreshold) === 100">
                        Never go directly to child
                      </div>
                      <div v-else>
                        {{ nodeAutoThreshold }}%
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="mb-0">
                    <label
                      class="font-weight-bold"
                      for="nodeShowThreshold"
                    >
                      Display option threshold
                      <tooltipped-text
                        value="Only show option if matching on option exceeds this threshold"
                      />
                    </label>
                    <input
                      id="nodeShowThreshold"
                      v-model="nodeShowThreshold"
                      type="range"
                      class="custom-range"
                    >
                    <div style="float:right">
                      <div v-if="Number(nodeShowThreshold) === 0">
                        Always show all options
                      </div>
                      <div v-else>
                        {{ nodeShowThreshold }}%
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <template v-if="!isStrictMpc">
                <edit-single-choice-message :node-id="nodeId" />
              </template>
              <edit-multiple-choice-message :node-id="nodeId" />

              <b-form-group
                v-if="!isStrictMpc"
                label-cols="auto"
                label="Limit number of shown options (0 = no limit) to"
                label-for="limit-options"
              >
                <b-form-input
                  id="limit-options"
                  ref="limitOptions"
                  :key="inputKey"
                  v-model="limitOptions"
                  :state="!$v.limitOptions.$invalid"
                  style="width:130px;"
                  size="sm"
                  type="number"
                  min="0"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.limitOptions.nonNegative">
                    Value must be a non-negative integer.
                  </div>
                  <div v-else-if="!$v.limitOptions.integer">
                    Value must be an integer.
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-checkbox
                id="sortChoicesCheckbox"
                v-model="sortChoices"
              >
                Present options in order based on best matches
                <tooltipped-text
                  value="If not ticked the order is kept in the order given below"
                />
              </b-form-checkbox>
              <b-form-checkbox
                id="blockInputCheckbox"
                v-model="blockInput"
              >
                Block user chat when showing options
                <tooltipped-text
                  value="If not ticked the user can write (any) text, and the bot will try to match it to an option"
                />
              </b-form-checkbox>
              <approve-button
                v-model="responseApproved"
                class="mt-3"
                type="above texts"
              />
              <div
                v-if="voicebotPlatformSelected"
                class="mt-3 d-flex"
              >
                <voicebot-audio
                  :text="multipleChoiceAudioText"
                  button-text="Play the full node"
                />
              </div>
            </b-tab>
            <b-tab
              ref="smart-node-items-tab"
              class="my-2"
              title="Items"
            >
              <template
                v-if="children.length > 0"
              >
                <edit-child-node-display-name
                  :node-id="nodeId"
                  :always-open="alwaysOpen"
                />
              </template>
              <edit-other-option :node-id="nodeId" />
            </b-tab>
          </b-tabs>
        </template>

        <!-- Subflow input variables -->
        <div
          v-if="isSubflowNode"
          class="card bg-light my-3"
        >
          <div class="card-body p-2">
            <h4>Subflow input</h4>

            <h6>Variables</h6>
            <b-row
              v-for="(name, index) in sfInputVars"
              :key="index"
              class="mt-1"
            >
              <b-col
                :cols="tempVariableCodeValues[index]
                  && tempVariableCodeValues[index].length > 25 ? 12 : 6"
                class="mt-1"
              >
                <label>
                  Variable name:
                </label>
                <VariableName
                  :value="name"
                  disabled
                />
              </b-col>
              <b-col
                :cols="tempVariableCodeValues[index]
                  && tempVariableCodeValues[index].length > 25 ? 12 : 6"
                class="mt-1"
              >
                <label>
                  Variable code:
                </label>
                <b-textarea
                  v-if="tempVariableCodeValues[index] && tempVariableCodeValues[index].length > 25"
                  :ref="`textarea${index}`"
                  :value="subFlowMap.input.vars[name]"
                  type="text"
                  :placeholder="lookupDefaultValueForSubflowInputVariable(name)"
                  class="text-monospace bg-white"
                  @blur="checkFocus(index, false)"
                  @input="value => inputVariableCodeProxy(name, value, index)"
                />
                <b-form-input
                  v-else
                  :ref="`input${index}`"
                  :value="subFlowMap.input.vars[name]"
                  type="text"
                  :placeholder="lookupDefaultValueForSubflowInputVariable(name)"
                  class="text-monospace bg-white"
                  @blur="checkFocus(index, true)"
                  @input="value => inputVariableCodeProxy(name, value, index)"
                />
              </b-col>
            </b-row>

            <hr><h6>Integrations</h6>
            <div
              v-for="name in sfInputActions"
              :key="`sfInputActions-${name}`"
            >
              {{ name }}
              <b-form-select
                :value="subFlowMap.input.actions[name]"
                :options="[null].concat(actionNames)"
                :state="actionParamsMatch(name)"
                class="bg-white"
                aria-describedby="selectFeedback"
                @change="value => updateSFMap(['input', 'actions', name], value)"
              />
              <b-form-invalid-feedback id="selectFeedback">
                The parameters of the two integrations dont match.
              </b-form-invalid-feedback>
            </div>

            <hr><h6>Entities</h6>
            <div
              v-for="name in sfInputNers"
              :key="`sfInputNers-${name}`"
            >
              {{ name }}
              <b-form-select
                class="bg-white"
                :value="subFlowMap.input.ners[name]"
                :options="[null].concat(nerNames)"
                @change="value => updateSFMap(['input', 'ners', name], value)"
              />
            </div>
          </div>
        </div>

        <!-- Subflow output variables -->
        <b-card v-if="isSubflowNode" no-body>
          <h4>
            Subflow output variables
          </h4>

          <template
            v-if="subflowOutputVariables.length !== 0"
          >
            <p class="mb-2">
              For each subflow output variable listed in subflow you must declare a
              corresponding variable in mainbot.
              The corresponding variable in mainbot  will hold the content of the subflow output
              variable.
            </p>
            <p class="mb-2">
              The corresponding variable in mainbot may exist already or you may
              declare it for the first time here.
            </p>
            <p>
              You are free to name the corresponding variable the same as the subflow output
              variable, or choose another name.
            </p>

            <b-row
              v-for="name in subflowOutputVariables"
              :key="name"
              class="mt-1"
            >
              <b-col
                cols="4"
                class="text-right my-auto"
              >
                <code>{{ name }}</code>
              </b-col>
              <b-col>
                <VariableName
                  allow-empty
                  :value="subFlowMap.output.vars[name] || ''"
                  placeholder="Corresponding variable in mainbot"
                  @input="value => updateSFMap(['output', 'vars', name], value)"
                />
              </b-col>
            </b-row>
          </template>
          <template
            v-else
          >
            Your subflow does not define any subflow output variables. <br>
          </template>
        </b-card>
      </b-form>
    </b-collapse>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import { validationMixin } from 'vuelidate';
import { integer, minValue } from 'vuelidate/lib/validators';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { nodeTypes } from '@/js/constants';
import { tagOptionList } from '@/js/voicebotTags';
import EditChildNodeDisplayName from '@/pages/EditNode/SmartNode/EditChildNodeDisplayName.vue';
import EditMultipleChoiceMessage from '@/pages/EditNode/SmartNode/EditMultipleChoiceMessage.vue';
import EditSingleChoiceMessage from '@/pages/EditNode/SmartNode/EditSingleChoiceMessage.vue';
import EditOtherOption from '@/pages/EditNode/SmartNode/EditOtherOption.vue';
import EditNodeClassifier from '@/pages/EditNode/SmartNode/EditNodeClassifier.vue';
import TooltippedText from '@/components/TooltippedText.vue';
import VariableName from '@/components/VariableName.vue';
import ApproveButton from '@/components/ApproveButton.vue';
import VoicebotAudio from '@/components/VoicebotAudio.vue';

// we introduce a local node type in this component to handle strict
// vs non-strict (smart) multiple choice nodes
const STRICT_MULTIPLE_CHOICE = 'strictMultipleChoice';

export default {
  name: 'NodeEditOptions',
  components: {
    ApproveButton,
    TooltippedText,
    EditChildNodeDisplayName,
    EditMultipleChoiceMessage,
    EditSingleChoiceMessage,
    EditOtherOption,
    EditNodeClassifier,
    VariableName,
    VoicebotAudio,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputKey: 0,
      arrow: 'angle-down',
      tempVariableCodeValues: {},
      showOptions: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
      'getPlatforms',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nameOfId',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      getAutoThreshold: 'getAutoThreshold',
      getLimitOptions: 'getLimitOptions',
      getShowThreshold: 'getShowThreshold',
      getSortChoices: 'getSortChoices',
      getBlockInput: 'getBlockInput',
      getResponseMode: 'getResponseMode',
      getNodeType: 'getNodeType',
      isStrictMpc: 'isStrictMpc',
      getSmallTalkThreshold: 'getSmallTalkThreshold',
      getGlobalThreshold: 'getGlobalThreshold',
      getResponseApproved: 'getResponseApproved',
      isSmartNode: 'isSmartNode',
      isSubflowNode: 'isSubflowNode',
      isQANode: 'isQANode',
      isSmallTalkNode: 'isSmallTalkNode',
    }), 'nodeId'),
    ...mapState('botManipulation/activeBot/config', ['multipleChoice']),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    showCollapse: {
      get() {
        return this.alwaysOpen ? this.alwaysOpen : this.showOptions;
      },
      set(v) {
        this.showOptions = v;
      },
    },
    nodeTypes() {
      let options = [];
      if (this.nodeType === nodeTypes.SUBFLOW) {
        options = [{
          value: nodeTypes.SUBFLOW,
          text: 'Subflow',
          disabled: true,
        }];
      } else if (this.nodeType === nodeTypes.QA) {
        options = [{
          value: nodeTypes.QA,
          text: 'Q and A',
          disabled: true,
        }];
      } else if (this.is_global) {
        options = [
          { value: nodeTypes.SIMPLE, text: 'Standard' },
          { value: STRICT_MULTIPLE_CHOICE, text: 'Multiple choice' },
          { value: nodeTypes.MULTIPLE_CHOICE, text: 'Smart' },
          {
            value: nodeTypes.SMALLTALK,
            text: 'Small talk',
          }];
      } else if (this.isMainBot) {
        options = [
          { value: nodeTypes.SIMPLE, text: 'Standard' },
          { value: STRICT_MULTIPLE_CHOICE, text: 'Multiple choice' },
          { value: nodeTypes.MULTIPLE_CHOICE, text: 'Smart' },
        ];
      } else { // Smart nodes and multiple-choice nodes will break the bot if used in subflow.
        options = [
          { value: nodeTypes.SIMPLE, text: 'Standard' },
        ];
      }
      return options;
    },
    is_global() { return this.activeNode.options.global; },
    usesCustomFallback() {
      return Boolean(this.activeNode.options.usesCustomFallbackNode);
    },
    subHeader() {
      let subHeader = '';
      if (this.nodeType === 'multipleChoice') {
        subHeader = 'smart';
      } else if (this.nodeType === STRICT_MULTIPLE_CHOICE) {
        subHeader = 'MultipleChoice';
      } else if (this.nodeType === 'simple') {
        subHeader = 'standard';
      } else {
        subHeader = this.nodeType;
      }
      subHeader = `Nodetype: ${subHeader.charAt(0).toUpperCase() + subHeader.slice(1)}`;
      if (this.nodeType === 'multipleChoice' && this.usesCustomFallback) {
        subHeader = 'Nodetype: Smart, uses custom fallback node';
      }
      return subHeader;
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    children() {
      return this.activeNode.children.map((id) => this.nodeById(id));
    },
    subFlow() {
      const subFlows = this.$store.state.botManipulation.subFlows;
      const subFlowID = this.activeNode?.subFlowMap?.subFlowID;
      return subFlowID ? subFlows.find((sf) => sf.id === subFlowID) : null;
    },
    subFlowMap: {
      get() {
        const resp = this.activeNode.subFlowMap;
        return resp || {};
      },
    },
    sfInputVars() {
      return this.subFlow ? this.subFlow.config.inputVariables.map((x) => x.name) : [];
    },
    sfInputNers() {
      return this.subFlow.ners.map((x) => x.name);
    },
    sfInputActions() {
      return this.subFlow.actions.map((x) => x.name);
    },
    subflowOutputVariables() {
      return this.subFlow.config.outputVariables;
    },
    nerNames() {
      return this.activeBot.ners.map((x) => x.name);
    },
    actionNames() {
      return this.activeBot.actions.map((x) => x.name);
    },
    nodeAutoThreshold: {
      get() {
        return this.getAutoThreshold;
      },
      set(autoThreshold) {
        this.setAutoThreshold({ autoThreshold });
      },
    },
    limitOptions: {
      get() {
        return this.getLimitOptions;
      },
      set(value) {
        this.setLimitOptions({ limitOptions: value || 0 });
        this.inputKey++;
        this.$nextTick(() => {
          this.$refs.limitOptions.focus();
        });
      },
    },
    nodeShowThreshold: {
      get() {
        return this.getShowThreshold;
      },
      set(showThreshold) {
        this.setShowThreshold({ showThreshold });
      },
    },
    sortChoices: {
      get() {
        return this.getSortChoices;
      },
      set(sortChoices) {
        this.setSortChoices({ sortChoices });
      },
    },
    blockInput: {
      get() {
        return this.getBlockInput;
      },
      set(blockInput) {
        this.setBlockInput({ blockInput });
      },
    },
    responseMode: {
      get() {
        return this.getResponseMode;
      },
      set(responseMode) {
        this.setResponseMode({ responseMode });
      },
    },
    nodeType: {
      get() {
        if (this.getNodeType === nodeTypes.MULTIPLE_CHOICE && this.isStrictMpc) {
          return STRICT_MULTIPLE_CHOICE;
        }
        return this.getNodeType;
      },
      set(nodeType) {
        if (nodeType === STRICT_MULTIPLE_CHOICE) {
          this.setNodeType({ nodeType: nodeTypes.MULTIPLE_CHOICE, isStrictMpc: true });
        } else {
          this.setNodeType({ nodeType, isStrictMpc: false });
        }
      },
    },
    smallTalkThreshold: {
      get() {
        return this.getSmallTalkThreshold;
      },
      set(smallTalkThreshold) {
        this.setSmallTalkThreshold({ smallTalkThreshold });
      },
    },
    globalThreshold: {
      get() {
        return this.getGlobalThreshold;
      },
      set(globalThreshold) {
        this.setGlobalThreshold({ globalThreshold });
      },
    },
    allowTraceback: {
      get() {
        const resp = this.activeNode.options.allowTraceback;
        return resp || false;
      },
      set(value) {
        this.setAllowTraceback({ allowTraceback: value });
      },
    },
    tracebackThreshold: {
      get() {
        const threshold = this.activeNode.options.tracebackThreshold;
        return threshold || 90;
      },
      set(value) {
        this.setTracebackThreshold({ tracebackThreshold: value });
      },
    },
    responseApproved: {
      get() {
        return this.getResponseApproved;
      },
      set(approved) {
        this.setResponseApproved({ approved });
      },
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    multipleChoiceAudioText() {
      // No need to generate the string if this isn't a voicebot or MC node
      if (!this.voicebotPlatformSelected || !this.isSmartNode) return '';

      // Find the "which of the following..." text
      const queryText = this.activeNode.options.optionsQuery
        || this.multipleChoice.optionsQuery;

      // Grab all display names
      const displayNames = this.activeNode.options.displayNames;

      // Grab all the children ids
      const children = this.activeNode.children;

      // Function to check if child is hidden
      const isHidden = (nodeId) => this.activeNode.options.directMatchOnly[nodeId] === true;

      const pause = tagOptionList.find((tag) => tag.id === 'break').output({ time: 500, unit: 'ms' });
      let audioString = queryText;

      let index = 1;
      /*
        Loop through the children and use either the display name
        or the node name.
      */
      children.forEach((childId) => {
        if (!isHidden(childId)) {
          const displayName = displayNames[childId]
            || this.children.find((c) => c.id === childId)?.name;
          audioString += `${pause}${index} ${pause} ${displayName}`;
          index++;
        }
      });

      // Finally, add "none of the above" if enabled
      if (this.activeNode.options.otherShow) {
        const displayName = this.activeNode.options.otherText
          || this.multipleChoice.otherText;
        audioString += `${pause}${index} ${pause} ${displayName}`;
      }
      return audioString;
    },
  },
  watch: {
    nodeType(newVal) {
      if (newVal === nodeTypes.SMALLTALK) {
        this.$store.dispatch('botManipulation/activeBot/updateActiveNode', {
          value: [], attribute: 'children', id: this.nodeId,
        });
        this.responseMode = 'require';
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.sfInputVars.length; i++) {
      this.tempVariableCodeValues[i] = this.subFlowMap.input.vars[this.sfInputVars[i]];
    }
  },
  methods: {
    ...mapActions('botManipulation/activeBot', [
      'updateSubFlowMap',
      'removeKeyIfNullOrUpdateSubflowMap',
    ]),
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setAllowTraceback',
      'setTracebackThreshold',
      'setAutoThreshold',
      'setLimitOptions',
      'setShowThreshold',
      'setSortChoices',
      'setBlockInput',
      'setResponseMode',
      'setNodeType',
      'setSmallTalkThreshold',
      'setGlobalThreshold',
      'setResponseApproved',
    ]), { id: 'nodeId' }),
    toggleArrow() {
      if (this.arrow === 'angle-down') {
        this.arrow = 'angle-up';
      } else {
        this.arrow = 'angle-down';
      }
    },
    actionParamsMatch(sfActionName) {
      const botActionName = this.subFlowMap.input.actions[sfActionName];
      if (botActionName == null) {
        return true;
      }
      const botAction = this.activeBot.actions.find((x) => x.name === botActionName);
      const botParams = new Set(botAction.params.map((x) => x.name));
      const botHeaders = new Set(botAction.headers.map((x) => x.name));
      const sfAction = this.subFlow.actions.find((x) => x.name === sfActionName);
      const sfParams = new Set(sfAction.params.map((x) => x.name));
      const sfHeaders = new Set(sfAction.headers.map((x) => x.name));
      const eqSize = botParams.size === sfParams.size && botHeaders.size === sfHeaders.size;
      const eqValues = [...botParams].every((x) => sfParams.has(x))
        && [...botHeaders].every((x) => sfHeaders.has(x));
      return eqSize && eqValues;
    },
    removeIfNullOrEmptyOrUpdateSFMap(keys, value) {
      this.removeKeyIfNullOrUpdateSubflowMap({ keys, value, id: this.nodeId });
    },
    updateSFMap(keys, value) {
      this.updateSubFlowMap({ keys, value, id: this.nodeId });
    },
    lookupDefaultValueForSubflowInputVariable(inputVariableName) {
      const entryForInputVariable = this.subFlow.config.inputVariables
        .find((x) => x.name === inputVariableName);
      if (entryForInputVariable.value === '') {
        return `<${inputVariableName}: no default value has been configured>`;
      }
      return entryForInputVariable.value;
    },
    checkFocus(index, isExpanding) {
      this.$nextTick(() => {
        if (isExpanding) {
          if (!this.$refs[`input${index}`].length) {
            this.$refs[`textarea${index}`][0].focus();
            this.$refs[`textarea${index}`][0].setSelectionRange(this.caretPosition, this.caretPosition);
          }
        } else if (!this.$refs[`textarea${index}`].length) {
          this.$refs[`input${index}`][0].focus();
          this.$refs[`input${index}`][0].setSelectionRange(this.caretPosition, this.caretPosition);
        }
      });
    },
    inputVariableCodeProxy(name, value, index) {
      if (!this.$refs[`input${index}`].length) {
        this.caretPosition = this.$refs[`textarea${index}`][0].selectionStart;
      } else {
        this.caretPosition = this.$refs[`input${index}`][0].selectionStart;
      }
      this.tempVariableCodeValues[index] = value;
      this.removeIfNullOrEmptyOrUpdateSFMap(['input', 'vars', name], value);
    },
  },
  validations: {
    limitOptions: {
      nonNegative: minValue(0),
      integer,
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: smaller;
  margin: 2px;
}
::v-deep .nav-tabs > li > .nav-link{
  border-radius: 0;
}
</style>
