<template>
  <b-overlay :show="isDatasetLoading || isFetchingLabelsExternal">
    <router-view />
  </b-overlay>
</template>
<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'DataExplorationDatasetPage',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadDataset(to.params.datasetId);
      vm.fetchLabelsExternal();
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.datasetId !== from.params.datasetId) {
      this.loadDataset(to.params.datasetId);
      this.setUpdateInterval();
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.clearState();
    next();
  },
  data() {
    return {
      setIntervalId: null,
    };
  },
  computed: {
    ...mapState('dataExploration', ['isDatasetLoading', 'currentDatasetId']),
    ...mapState({ isFetchingLabelsExternal: (state) => state.nlu.labelExternal.isFetching }),
  },
  mounted() {
    this.setUpdateInterval();
  },
  destroyed() {
    this.clearUpdateInterval();
  },
  methods: {
    ...mapActions('dataExploration', [
      'loadDataset',
      'clearState',
    ]),
    ...mapActions('nlu/labelExternal', ['fetchLabelsExternal']),
    ...mapActions('dataExploration/categories', ['fetchCategories']),
    setUpdateInterval() {
      if (this.setIntervalId) {
        this.clearUpdateInterval(this.setIntervalId);
      }
      this.setIntervalId = setInterval(
        () => this.fetchCategories(this.currentDatasetId), 3000,
      );
    },
    clearUpdateInterval() {
      clearInterval(this.setIntervalId);
      this.setIntervalId = null;
    },
  },
};
</script>
