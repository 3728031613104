<template>
  <div>
    <b-card
      title="Data points"
      class="r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          Below is an overview of all data-points included in the dataset.
        </b-col>
      </b-row>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row
        v-if="numberOfTableItems > 0"
      >
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="numPages"
            :per-page="1"
            class="mb-1"
          />
        </b-col>
      </b-row>
      <b-table
        ref="table"
        striped
        hover
        responsive
        small
        :current-page="currentPage"
        show-empty
        class="mb-1"
        empty-text="There are no datapoints in dataset yet. Add them by adding a datasource"
        :per-page="perPage"
        :items="getDataForTable"
        :fields="tableFields"
        :busy="isLoading"
      >
        <template #cell(options)="row">
          <b-btn
            v-b-tooltip.hover
            :title="row.item.editable ? 'Edit datapoint' : 'You cannot edit data from node'"
            variant="outline-primary"
            size="sm"
            :disabled="!row.item.editable"
            @click.stop="setEditDataPoint(row.item)"
          >
            <font-awesome-icon icon="edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :title="row.item.editable ? 'Delete datapoint' : 'You cannot delete data from node'"
            variant="outline-danger"
            size="sm"
            :disabled="!row.item.editable"
            @click.stop="setDeleteDataPoint(row.item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-btn>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner style="width: 3rem; height: 3rem;" />
          </div>
        </template>
      </b-table>
      <b-row
        v-if="numberOfTableItems > 0"
      >
        <b-col>
          <b-pagination
            v-model="currentPage"
            class="mb-0"
            :total-rows="numPages"
            :per-page="1"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="editDataPointModal"
      title="Edit datapoint"
      size="xl"
      ok-title="Save"
      @ok="proxyEditDataPoint"
      @shown="focusEditTextArea"
    >
      <b-form-textarea
        ref="editModalTextArea"
        v-model="editDataPointText"
        rows="4"
        max-rows="6"
      />
    </b-modal>
    <b-modal
      id="deleteDataPointModal"
      title="Delete datapoint"
      :auto-focus-button="'cancel'"
      ok-variant="danger"
      ok-title="Delete"
      @ok="proxyDeleteDataPoint"
    >
      Are you certain you would like to delete the datapoint?
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import {
  mapActions,
} from 'vuex';
import endpoints from '@/js/urls';

export default {
  name: 'DataExplorationDataPage',
  data() {
    return {
      currentPage: 1,
      numPages: 1,
      numberOfTableItems: 0,
      editDataPointId: -1, // init to nonvalid value
      editDataPointText: '',
      selectedDeleteDataPoint: {},
      isLoading: false,
    };
  },
  computed: {
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    tableFields() {
      return [{
        key: 'text',
        label: 'Text',
      },
      {
        key: 'options',
        label: 'Options',
        thClass: 'text-left',
        tdClass: 'text-left',
        thStyle: 'width: 80px',
      }];
    },
    perPage() {
      return 20;
    },
  },
  methods: {
    ...mapActions('dataExploration/dataSources', [
      'deleteDataPoint',
      'editDataPoint',
    ]),
    async getDataForTable(ctx) {
      this.isLoading = true;
      // sortDesc, sortBy, filter are also in ctx.
      const { perPage, currentPage } = ctx;
      const url = `${endpoints.dataExplorationAllData + this.datasetId}/`;
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { per_page: perPage, current_page: currentPage },
      };
      const { data: { data, num_pages: numPages } } = await axios.get(url, config);
      this.numPages = numPages;
      this.numberOfTableItems = data.length;
      this.isLoading = false;
      return data;
    },
    setEditDataPoint(row) {
      this.editDataPointId = row.id;
      this.editDataPointText = row.text;
      this.$bvModal.show('editDataPointModal');
    },
    async proxyEditDataPoint() {
      await this.editDataPoint({
        datasetId: this.datasetId,
        dataPointId: this.editDataPointId,
        dataPointText: this.editDataPointText,
      });
      this.$refs.table.refresh();
    },
    setDeleteDataPoint(datapoint) {
      this.selectedDeleteDataPoint = { ...datapoint };
      this.$bvModal.show('deleteDataPointModal');
    },
    async proxyDeleteDataPoint() {
      await this.deleteDataPoint({
        datasetId: this.datasetId,
        dataPoint: this.selectedDeleteDataPoint,
      });
      this.$refs.table.refresh();
    },
    focusEditTextArea() {
      this.$refs.editModalTextArea.focus();
    },
  },
};
</script>
