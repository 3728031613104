import store from '@/store/index';
import { LanguageOptions } from '@/js/constants';

export function getDisplayFromId(key, value, isSubflow) {
  let display;
  switch (key) {
    // Bot cases
    case 'botId':
      if (isSubflow) {
        const subflowId = parseInt(value.substring(36, value.length).replace('-', ''), 10);
        display = store.getters['botManipulation/getSubFlowNameFromId'](subflowId) ? store.getters['botManipulation/getSubFlowNameFromId'](subflowId) : null;
      } else {
        display = store.getters['botManipulation/getBotNameFromId'](value) ? store.getters['botManipulation/getBotNameFromId'](value) : null;
      }
      break;
    case 'nodeId':
      display = store.getters['botManipulation/activeBot/nameOfId'](value) ? store.getters['botManipulation/activeBot/nameOfId'](value) : null;
      break;
    case 'variantId':
      display = store.getters['variants/getNameFromId'](value);
      break;
    case 'chatId':
      display = 'Details';
      break;

    // NLU cases
    case 'modelId':
      display = store.getters['nlu/classifier/getModelFromId'](value) ? store.getters['nlu/classifier/getModelFromId'](value).name : null;
      break;
    case 'labelId':
      display = store.getters['nlu/classifier/activeLabel'] ? store.getters['nlu/classifier/activeLabel'].displayName : null;
      break;
    case 'sourceId':
      display = store.getters['nlu/classifier/activeSource'] ? store.getters['nlu/classifier/activeSource'].name : null;
      break;
    case 'versionId':
      display = 'Details';
      break;

    // Data Exploration cases
    case 'datasetId':
      display = store.getters['dataExploration/getDatasetById'](value) ? store.getters['dataExploration/getDatasetById'](value).name : null;
      break;
    case 'visualizationId':
      display = store.getters['dataExploration/visualizations/getVisualizationNameFromId'](value) ? store.getters['dataExploration/visualizations/getVisualizationNameFromId'](value) : null;
      break;
    case 'dataId':
      display = 'Labeling';
      break;

    // Administration cases
    case 'userId':
      display = store.getters['administration/getUsernameFromId'](value) ? store.getters['administration/getDisplayNameFromId'](value) : null;
      break;
    case 'groupId':
      display = store.getters['administration/getGroupNameFromId'](value) || `Missing group (id=${value})`;
      break;
    case 'id':
      display = store.getters['administration/getObjectFromId'](value) ? store.getters['administration/getObjectFromId'](value) : null;
      break;
    default: display = 'empty';
  }

  return display;
}

export function getBreadcrumb(match, params) {
  // if the path ends with / or is empty, it means it is some route that is accessed
  // from base route, so it already has a match
  if (match.path.charAt(match.path.length - 1) === '/') {
    return null;
  }
  if (!match.path.length) {
    return null;
  }

  let paramKey;
  if (match.path.includes('botId') && !match.path.includes('nodeId')
  && !match.path.includes('chatId') && !match.path.includes('variantId')) {
    paramKey = 'botId';
  } else if (match.path.includes('dataType')) {
    paramKey = 'dataId';
  } else {
    paramKey = match.path.split('/').pop().trim().substring(1);
  }
  const paramValue = params[paramKey];

  // create link and display name
  const link = { name: match.name, params: { paramKey: paramValue } };
  const isSubflow = paramKey === 'botId' && paramValue.substring(36, paramValue.length).includes('-');
  const display = getDisplayFromId(paramKey, paramValue, isSubflow);
  if (isSubflow) {
    link.name = 'flow';
    const botLink = {
      display: store.getters['botManipulation/getBotNameFromIdInSubflow'](paramValue.substring(0, 36)),
      link: {
        name: 'flow',
        params: { botId: paramValue.substring(0, 36) },
      },
    };
    return [
      { display: botLink.display, link: botLink.link },
      { display: 'Subflows', link: { name: 'subflows', params: { botId: paramValue.substring(0, 36) } } },
      { display, link },
    ];
  }
  const activeNLUModel = store.getters['nlu/classifier/activeModel'];
  if (paramKey === 'modelId' && activeNLUModel?.language) {
    return [{ display, link }, {
      display: LanguageOptions.find((e) => e.value === activeNLUModel.language).text,
      link,
    }];
  }
  if (paramKey === 'chatId') {
    return [{ display: 'Conversation Logs', link: { name: 'conversation-logs' } }, { display, link }];
  }
  if (paramKey === 'variantId') {
    return [{ display: 'Variants', link: { name: 'variants' } }, { display, link }];
  }
  return { display, link };
}
