<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="info"
    @shown="focusInput"
  >
    <template #icon>
      <font-awesome-icon icon="pencil-alt" />
    </template>
    <template #header>
      {{ cappedText }}
    </template>
    <response-activity-text-input
      ref="textInputField"
      :uid="nodeId + activityId"
      :activity-id="activityId"
      :node-id="nodeId"
    />
  </base-activity>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import ResponseActivityTextInput from '@/pages/EditNode/activity/ResponseActivityTextInput.vue';
import { truncateString, mapPhrases, phrase2Id } from '@/js/utils';

export default {
  name: 'ResponseActivity',
  components: {
    BaseActivity,
    ResponseActivityTextInput,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['phraseText']),
    ...mapGetters('botManipulation/activeBot', {
      getResponseText: 'activityText',
    }),
    text() {
      return this.getResponseText(this.nodeId, this.activityId);
    },
    cappedText() {
      const mapped = mapPhrases(this.text)
        .map((e) => (e.isPhrase ? this.phraseText(this.phrase2Id(e.value)) : e.value)).join('');
      return truncateString(mapped
        .replaceAll(/<[^>]*>?/gm, '')
        .replaceAll('&nbsp;', '')
        .replaceAll('&gt;', '')
        .replaceAll('&lt;', ''), 60);
    },
  },
  methods: {
    phrase2Id,
    focusInput() {
      if (this.$refs.textInputField) {
        this.$refs.textInputField.focusActivity();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
};
</script>
