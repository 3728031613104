import Vue from 'vue';
import { ROOT_TREE_NODE } from '@/js/constants';
import { showPaths } from '@/js/treeViewHelper';

const treeState = {
  tree: {},
  root: undefined,
  zoom: [],
  activeBotNodeId: null,
  highlightEditNodeId: null,
  // It is double as fast to do botNodeId === activeBotNode in a mutation,
  // than doing it in a computed property in the component (in production).
  // So we add following dict that really is redundant.
  activeBotNodeIdDict: {},
  highlightEditNodeDict: {},
  activeId: null,
  activeParentId: null,
};

// 64 bit should be enough to never repeat
// Dont put it in state as it will make it reactive
let nextTreeNodeId = 0;
export function nextUnusedId() {
  nextTreeNodeId += 1;
  return `tree_node_${nextTreeNodeId}`;
}

export const treeGetters = {
  botNodeId: (state) => (treeNodeId) => state.tree[treeNodeId].botNodeId,
  children: (state) => (treeNodeId) => state.tree[treeNodeId].children,
  botNode: (state, getters, rootState, rootGetters) => (treeNodeId) => {
    const { botNodeId } = state.tree[treeNodeId];
    return rootGetters['botManipulation/activeBot/nodeById'](botNodeId);
  },
  zoomRoot: (state) => state.zoom[state.zoom.length - 1],
  treeRoot: (state) => state.root,
  getTree: (state) => state.tree,
};

function deleteRecursively(state, id, deleteThis, depth = 0) {
  const node = state.tree[id];
  if (node.children !== null) {
    for (const childId of node.children) {
      deleteRecursively(state, childId, true, depth + 1);
    }
    Vue.set(state.tree[id], 'children', null);
  }

  if (deleteThis) {
    const parent = state.tree[node.parent];
    if (depth === 0 && parent && parent.children !== null) {
      Vue.set(parent, 'children', parent.children.filter((childId) => childId !== id));
    }
    Vue.delete(state.tree, id);
  }
}

function expandTreeFn({
  commit, rootGetters, getters, treeNodeId, isRoot, visited,
}) {
  let treeChildren = getters.children(treeNodeId);
  const node = getters.botNode(treeNodeId);
  const hasSingleParent = node.preds.length < 2;
  if (treeChildren === null && (hasSingleParent || isRoot) && !visited.has(node)) {
    visited.add(node);
    commit('showChildren', {
      treeNodeId, getters, commit,
    });
    treeChildren = getters.children(treeNodeId);
    if (treeChildren) {
      for (let ii = 0; ii < treeChildren.length; ii++) {
        expandTreeFn({
          commit, rootGetters, getters, treeNodeId: treeChildren[ii], isRoot: false, visited,
        });
      }
    }
  }
}

export const treeMutations = {
  initializeTree(state, { rootId }) {
    const treeRootId = ROOT_TREE_NODE;
    Vue.set(state, 'tree', {
      [treeRootId]: {
        botNodeId: rootId,
        children: null,
        parent: null,
      },
    });
    Vue.set(state, 'root', treeRootId);
    Vue.set(state, 'zoom', [treeRootId]);
  },
  setTree(state, tree) {
    state.tree = tree;
  },
  setActiveBotNodeId(state, { botNodeId }) {
    if (state.activeBotNodeId === botNodeId) {
      return;
    }

    const oldNodeId = state.activeBotNodeId;
    state.activeBotNodeId = botNodeId;

    for (const treeNode of Object.values(state.tree)) {
      const nodeId = treeNode.botNodeId;
      if (nodeId === oldNodeId || nodeId === botNodeId) {
        Vue.set(state.activeBotNodeIdDict, nodeId, nodeId === botNodeId);
      }
    }
  },
  setHighlightEditNode(state, botNodeId) {
    if (state.highlightEditNodeId === botNodeId) {
      return;
    }

    const oldNodeId = state.highlightEditNodeId;
    state.highlightEditNodeId = botNodeId;

    for (const treeNode of Object.values(state.tree)) {
      const nodeId = treeNode.botNodeId;
      if (nodeId === oldNodeId || nodeId === botNodeId) {
        Vue.set(state.highlightEditNodeDict, nodeId, nodeId === botNodeId);
      }
    }
  },
  addZoomNode(state, { treeNodeId }) {
    if (treeNodeId && treeNodeId !== state.zoom[state.zoom.length - 1]) {
      state.zoom.push(treeNodeId);
    }
  },
  addZoomBotNodeId(state, { botNodeId }) {
    for (const [treeId, treeNode] of Object.entries(state.tree)) {
      if (treeNode.botNodeId === botNodeId) {
        state.zoom.push(treeId);
        return;
      }
    }
  },
  removeZoomNode(state, { treeNodeId }) {
    const index = state.zoom.indexOf(treeNodeId);
    if (index >= 0) {
      state.zoom.splice(index);
    }
  },
  resetZoom(state) {
    const treeRootId = ROOT_TREE_NODE;
    state.zoom = [treeRootId];
  },
  zoomOut(state) {
    if (state.zoom.length > 1) {
      state.zoom.splice(-1);
    }
  },
  setChildrenList(state, { treeNodeId, childrenList }) {
    Vue.set(state.tree[treeNodeId], 'children', childrenList);
  },
  deleteNode(state, { treeNodeId }) {
    if (state.tree[treeNodeId] !== undefined) {
      deleteRecursively(state, treeNodeId, true);
    }
  },
  collapse(state, { treeNodeId }) {
    if (state.tree[treeNodeId] !== undefined) {
      deleteRecursively(state, treeNodeId, false);
    }
  },
  addNode(state, { treeNodeId, treeParentId, botNodeId }) {
    Vue.set(state.tree, treeNodeId, {
      botNodeId,
      children: null,
      parent: treeParentId,
    });
  },
  showChildren(state, {
    treeNodeId, getters, commit,
  }) {
    const treeNode = state.tree[treeNodeId];
    if (treeNode.children !== null) {
      throw new Error("showChildren should only be called on nodes that don't show children already");
    }
    const botNode = getters.botNode(treeNodeId);
    if (botNode === undefined || botNode === null) {
      throw new Error('showChildren was called on a node that is no longer in the bot');
    }
    const treeChildren = [];
    const addNodes = {};
    for (const botChildId of botNode.children) {
      const treeChildId = nextUnusedId();

      addNodes[treeChildId] = {
        botNodeId: botChildId,
        parent: treeNodeId,
        children: null,
      };
      treeChildren.push(treeChildId);
    }
    state.tree = { ...state.tree, ...addNodes };
    commit('setChildrenList', {
      treeNodeId,
      childrenList: treeChildren,
    });
  },
  setActiveId(state, id) {
    state.activeId = id;
  },
  setActiveParentId(state, id) {
    state.activeParentId = id;
  },
};

function arraysEqualShallow(a, b) {
  if (a === undefined || a === null || b === undefined || b === null) return a === b;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export const treeActions = {
  showChildren({
    commit, getters,
  }, { treeNodeId }) {
    commit('showChildren', {
      treeNodeId, getters, commit,
    });
  },
  toggle({
    commit, getters, dispatch,
  }, { treeNodeId }) {
    if (getters.children(treeNodeId) !== null) {
      commit('collapse', { treeNodeId });
    } else {
      dispatch('showChildren', { treeNodeId });
    }
  },
  expandTree({
    commit, rootGetters, getters,
  }, { treeNodeId }) {
    expandTreeFn({
      commit, rootGetters, getters, treeNodeId, isRoot: true, visited: new Set([]),
    });
  },
  init({ commit }, { rootId }) {
    commit('initializeTree', { rootId });
  },
  showPathsProxy({
    commit, state, rootGetters, getters,
  }, { paths, shortest, zoom }) {
    commit('initializeTree', { rootId: getters.botNodeId(getters.treeRoot) });
    const tree = showPaths({
      tree: JSON.parse(JSON.stringify(state.tree)), paths, shortest, root: getters.treeRoot, nextUnusedId, nodeById: rootGetters['botManipulation/activeBot/nodeById'],
    });
    commit('setTree', tree);
    if (zoom) {
      // Find dominator, and zoom to it
      const sets = paths.map((a) => new Set(a));
      const intersection = [];
      for (const element of paths[0]) {
        if (sets.every((set) => set.has(element))) {
          intersection.push(element);
        }
      }
      if (intersection.length > 2) {
        // It does not matter which treenodeid we zoom into
        commit('addZoomBotNodeId', { botNodeId: intersection[intersection.length - 2] });
      }
    }
    return new Promise((resolve) => {
      setTimeout(resolve, 0);
    });
  },
  /**
   * Normalizes the tree, whenever there has been an update in the bot.
   * That is, if a bot node has been deleted, then all of the corresponding tree
   * nodes are deleted. If the children list of a bot has changed, then the
   * children list of the corresponding tree node is changed as well.
   *
   * It is a standing assumption that whenever a node is added or deleted or
   * its parents or children are changed this function is called.
   */
  async normalize({
    state, rootGetters, commit, rootState,
  }) {
    // Special case: If the root has changed, then we completely collapse the tree and only
    // show the new root.
    const oldRootBotId = state.tree[state.root].botNodeId;
    const newRootBotId = rootState.botManipulation.activeBot.root;
    if (oldRootBotId !== newRootBotId) {
      commit('initializeTree', { rootId: rootState.botManipulation.activeBot.root });
      return;
    }

    const deletedTreeNodeIds = [];
    const changedChildrenTreeNodeIds = [];
    for (const treeNodeId of Object.keys(state.tree)) {
      const treeNode = state.tree[treeNodeId];
      const { botNodeId } = treeNode;
      const botNode = rootGetters['botManipulation/activeBot/nodeById'](botNodeId);
      if (botNode === undefined || botNode === null) {
        deletedTreeNodeIds.push(treeNodeId);
      } else if (treeNode.children !== null) {
        const treeBotChildren = treeNode.children.map((treeId) => state.tree[treeId].botNodeId);
        if (!arraysEqualShallow(treeBotChildren, botNode.children)) {
          changedChildrenTreeNodeIds.push(treeNodeId);
        }
      }
    }
    // All these nodes are deleted in the bot, or descendants of deleted bot-nodes.
    for (const treeNodeId of deletedTreeNodeIds) {
      commit('deleteNode', { treeNodeId });
      commit('removeZoomNode', { treeNodeId });
    }
    // Possible problem: We have just deleted a lot of nodes. Now we will insert some nodes. That
    // means that it is possible that when we look at the node with a given id, it is actually not
    // the one we wanted - it might be a new node. Therefore we mark all the new nodes we introduce
    // and then in the end remove that mark.
    const markedNodes = new Set();
    for (const treeNodeId of changedChildrenTreeNodeIds) {
      if (state.tree[treeNodeId] !== undefined && !(treeNodeId in markedNodes)) {
        const treeNode = state.tree[treeNodeId];
        const { botNodeId } = treeNode;
        const botNode = rootGetters['botManipulation/activeBot/nodeById'](botNodeId);
        const oldTreeChildren = state.tree[treeNodeId].children;
        const oldBotChildren = oldTreeChildren.map(
          (childTreeId) => state.tree[childTreeId].botNodeId,
        );
        const hasBeenReused = {};
        for (const childTreeId of oldTreeChildren) {
          hasBeenReused[childTreeId] = false;
        }
        const newBotChildren = botNode.children;
        const newTreeChildren = [];
        for (const newChildBotId of newBotChildren) {
          let reusedId = null;
          for (let i = 0; i < oldTreeChildren.length; i += 1) {
            if (reusedId === null) {
              const oldChildTreeId = oldTreeChildren[i];
              const oldChildBotId = oldBotChildren[i];
              if (oldChildBotId === newChildBotId && !hasBeenReused[oldChildTreeId]) {
              // Reusing a child
                hasBeenReused[oldChildTreeId] = true;
                reusedId = oldChildTreeId;
              }
            }
          }
          if (reusedId !== null) {
            newTreeChildren.push(reusedId);
          } else {
            // Making a new child
            const newChildTreeId = nextUnusedId();
            commit('addNode', {
              treeNodeId: newChildTreeId,
              botNodeId: newChildBotId,
              treeParentId: treeNodeId,
            });
            markedNodes.add(newChildTreeId);
            newTreeChildren.push(newChildTreeId);
          }
        }
        for (const childTreeId of oldTreeChildren) {
          if (!hasBeenReused[childTreeId]) {
            commit('deleteNode', { treeNodeId: childTreeId });
            commit('removeZoomNode', { treeNodeId: childTreeId });
          }
        }
        commit('setChildrenList', {
          treeNodeId,
          childrenList: newTreeChildren,
        });
      }
    }
    // Special case: If all the nodes are deleted we have to initialize the treeView again.
    if (Object.keys(state.tree).length === 0) {
      commit('initializeTree', { rootId: rootState.botManipulation.activeBot.root });
    }
  },
};

export default {
  namespaced: true,
  state: treeState,
  getters: treeGetters,
  mutations: treeMutations,
  actions: treeActions,
};
