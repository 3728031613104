<template>
  <b-modal
    :id="`phrase-modal-${modalId}`"
    :title="`${action} phrase`"
    :ok-title="action"
    :ok-disabled="name.length === 0"
    size="lg"
    body-class="p-0"
    :hide-footer="action === 'Delete' && usage.length > 0"
    @ok="handleOk"
    @hide="clearData"
    @show="onShow"
  >
    <h3 v-if="usage.length > 0 && action === 'Delete'" class="mb-0" style="line-height: 0.9;">
      <b-badge class="mb-0 w-100 h-100 r-0 mt-0" variant="warning">
        Action required
      </b-badge>
    </h3>
    <div class="p-3">
      <p v-if="action !== 'Add'">
        This phrase is used in {{ usage.length }} response{{ usage.length === 1 ? '' : 's' }}.
      </p>
      <div v-if="action === 'Delete' && usage.length > 0">
        <p class="mb-3">
          This phrase must be either removed from bot responses or replaced with its text content.
        </p>
        <b-form-group label="Phrase text" class="mb-0" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="border r-25 p-2 mb-3" style="background-color: #EEEEEE; min-height:37px;" v-html="filteredText" />
        <b-row>
          <b-col class="text-right">
            <b-button variant="primary" :disabled="usage.length === 0" @click="replacePhraseAndDelete(false)">
              Use phrase text as replacement and delete
            </b-button>
          </b-col>
          <b-col cols="auto">
            <b-button variant="primary" @click="replacePhraseAndDelete(true)">
              Remove from responses and delete
            </b-button>
          </b-col>
        </b-row>
      </div>

      <phrase-input v-else-if="action !== 'Delete'" :phrase="{ name, text }" @input="updatePhraseLocal" />
    </div>
  </b-modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import PhraseInput from '@/pages/Phrases/PhraseInput.vue';
import { guidGenerator, formatTextWithLinebreaks, phraseUsage } from '@/js/utils';
import { RESPONSE, COMPOUND_RESPONSE } from '@/js/activity';

export default {
  name: 'PhraseModal',
  components: {
    PhraseInput,
  },
  props: {
    modalId: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      text: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['activePhrase']),
    ...mapGetters('botManipulation/activeBot', ['phrasesDict']),
    ...mapGetters('botManipulation/activeBot', ['nodesAsList', 'nodesWithDraftResponses']),
    ...mapGetters('botManipulation/activeBot/config', ['wysiwygEnabled']),
    filteredText() {
      return this.wysiwygEnabled ? formatTextWithLinebreaks(this.activePhrase.text)
        : this.activePhrase.text;
    },
    phraseId2PhraseFormat() {
      if (this.activePhrase) {
        return `[phrase:${this.activePhrase.id}]`;
      }
      return null;
    },
    usage() {
      return this.activePhrase ? phraseUsage(this.activePhrase.id, this.nodesAsList) : [];
    },
  },
  methods: {
    ...mapMutations('botManipulation', ['setActivePhraseId']),
    ...mapMutations('botManipulation/activeBot', ['addPhrase', 'updatePhrase', 'deletePhrase']),
    ...mapMutations('botManipulation/activeBot', ['setActivityResponseText', 'setActivityResponseText']),
    onShow() {
      if (this.activePhrase) {
        this.name = this.activePhrase.name;
        this.text = this.activePhrase.text;
      } else {
        this.name = '';
        this.text = '';
      }
    },
    clearData() {
      this.name = '';
      this.text = '';
      this.setActivePhraseId(null);
    },
    updatePhraseLocal(v) {
      this.name = v.name;
      this.text = v.text;
    },
    handleOk() {
      if (this.action === 'Add') {
        const id = guidGenerator();
        this.addPhrase({ id, name: this.name, text: this.text });
        this.$emit('phraseAdded', id);
      } else if (this.action === 'Update') {
        this.updatePhrase({ id: this.activePhrase.id, name: this.name, text: this.text });
      } else {
        this.replacePhraseAndDelete(true);
      }
    },
    replacePhraseAndDelete(remove) {
      this.usage.forEach((activity) => {
        const updatedText = activity.text
          .replaceAll(this.phraseId2PhraseFormat, remove ? '' : this.activePhrase.text);
        if (activity.type === RESPONSE) {
          this.setActivityResponseText({
            nodeId: activity.nodeId,
            activityId: activity.activityId,
            text: updatedText,
          });
        } else if (activity.type === COMPOUND_RESPONSE) {
          this.setActivityResponseText({
            nodeId: activity.nodeId,
            activityId: activity.activityId,
            text: updatedText,
          });
        }
      });
      this.deletePhraseProxy();
    },
    deletePhraseProxy() {
      this.deletePhrase(this.activePhrase.id);
      this.$bvModal.hide(`phrase-modal-${this.modalId}`);
    },
  },
};
</script>
