<template>
  <div>
    <b-card
      class="r-75"
      title="Phrases"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          <p class="mb-3">
            Here you can manage bot phrases. With phrases you can save time and
            ensure that your bot consistently communicates in a specific way. Each phrase consists
            of a name, which allows you do identify and reference it, and the actual text content
            that will be displayed in bot's response.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-1">
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              placeholder="Filter on name, creation date or usage"
            />

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-table
        :items="items"
        :fields="fields"
        :filter-included-fields="['name', 'timestamp', 'usage']"
        class="mb-0"
        table-class="cursor-pointer"
        :sort-compare="mySortCompare"
        sort-icon-left
        show-empty
        empty-text="Click + to add a phrase."
        @row-clicked="toggleDetails"
      >
        <template #cell(timestamp)="row">
          {{ formatTimestamp(row.item.timestamp) }}
        </template>
        <template #cell(usage)="row">
          <div>
            {{ row.item.usage.length }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button
            v-b-tooltip.hover.nointeractive.viewport
            variant="danger"
            class="p-0"
            title="Delete phrase"
            @click.stop="deleteClicked(row.item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
        <template #row-details="row">
          <phrase-input
            :phrase="row.item"
            @input="v => updatePhrase({ id: row.item.id, name: v.name, text: v.text })"
          />
          <phrase-usage :usage="row.item.usage" />
        </template>
        <template #custom-foot>
          <b-tr style="text-align: center">
            <b-td
              colspan="4"
              class="pt-3"
            >
              <b-button
                v-b-tooltip.hover.noninteractive.viewport
                variant="primary"
                class="px-3"
                title="New phrase"
                @click="addClicked"
              >
                Add new phrase
              </b-button>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>
    <phrase-modal :action="action" :usage="activePhraseUsage" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import PhraseInput from '@/pages/Phrases/PhraseInput.vue';
import PhraseUsage from '@/pages/Phrases/PhraseUsage.vue';
import PhraseModal from '@/pages/Phrases/PhraseModal.vue';
import { phraseUsage } from '@/js/utils';

export default {
  name: 'PhrasesPage',
  components: {
    PhraseInput,
    PhraseModal,
    PhraseUsage,
  },
  data() {
    return {
      action: '',
      filter: '',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'timestamp', label: 'Created on', sortable: true },
        {
          key: 'usage', label: 'Usage', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      rowDetails: {},
      activePhraseUsage: [],
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['activePhrase']),
    ...mapGetters('botManipulation/activeBot', ['phrases']),
    ...mapGetters('botManipulation/activeBot', ['nodesAsList']),
    items() {
      return cloneDeep(this.phrases)
        .map((e) => ({
          ...e,
          usage: phraseUsage(e.id, this.nodesAsList),
          _showDetails: this.rowDetails[e.id] !== undefined ? this.rowDetails[e.id] : false,
        }))
        .filter((e) => e.name.toLowerCase().includes(this.filter.toLowerCase())
         || new Date(e.timestamp).toLocaleString().includes(this.filter)
         || `${e.usage.length}`.includes(this.filter));
    },
  },
  watch: {
    activePhrase(n) {
      if (n === null) {
        this.activePhraseUsage = [];
      }
    },
  },
  methods: {
    ...mapMutations('botManipulation', ['setActivePhraseId']),
    ...mapMutations('botManipulation/activeBot', ['updatePhrase', 'deletePhrase']),
    addClicked() {
      this.action = 'Add';
      this.$bvModal.show('phrase-modal-');
    },
    deleteClicked(item) {
      this.setActivePhraseId(item.id);
      this.activePhraseUsage = item.usage;
      this.action = 'Delete';
      this.$bvModal.show('phrase-modal-');
    },
    toggleDetails(item) {
      this.$set(this.rowDetails, item.id, !item._showDetails);
    },
    mySortCompare(a, b, key) {
      if (key === 'timestamp') {
        return new Date(a[key]).getTime() - new Date(b[key]).getTime();
      }
      return false;
    },
    formatTimestamp(timestamp) {
      return timestamp ? new Date(timestamp).toLocaleString() : timestamp;
    },
  },
};
</script>
