import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { ACTION } from '@/js/activity';
import chatActions from '@/js/chatActions';
import apiActionTemplates from '@/js/apiActionTemplates';
import { nodesGetters } from './nodes';

export const actionGetters = {
  getBotActions(state) {
    return state.activeBotSet ? state.activeBot.actions : null;
  },
  getBotActionsNames(state) {
    return state.activeBotSet ? state.activeBot.actions.map((x) => x.name) : null;
  },
  /**
     * Returns either action (found), undefined (no match) or null (activeBot is null)
     */
  getBotActionByName(state) {
    if (state.activeBotSet) {
      return (name) => state.activeBot.actions.find((action) => action.name === name);
    }
    return () => null;
  },
  getChatActions(state) {
    return state.activeBotSet ? chatActions : null;
  },
};

export const actionMutations = {
  addBotAction(state, payload) {
    if (state.activeBotSet) {
      // we clone here because the component has a watcher on the payload which otherwise
      // causes an error
      state.activeBot.actions.push(cloneDeep(payload.action));
    }
  },
  /**
   * TODO: Refactor this and document expected behaviour!
   * Saves an updated integration blueprint, but (!) with non-trivial sideeffects to all 'concrete'
   * integrations in nodes in bot based off of this blueprint.
   * As we disallow overriding the default secrets for headers or parameters in concrete
   * integrations in nodes in bot, we update all concrete integrations based off of this blueprint,
   * specifically in their secret-referring aspect.
   */
  editBotAction(state, { action }) {
    if (state.activeBotSet) {
      const index = state.activeBot.actions.findIndex((a) => a.name === action.name);
      const currentSavedAction = state.activeBot.actions[index];
      // Checking that name is unique. Returning if not.
      for (let i = 0; i < state.activeBot.actions.length; i += 1) {
        const { name } = state.activeBot.actions[i];
        if (i !== index && action.name === name) {
          return;
        }
      }

      const allowAppendChanged = action.orgAllowAppend !== action.allowAppend;
      const expectJsonChanged = action.orgExpectJson !== action.expectJson;
      const useFormEncoding = action.orgUseFormEncoding !== action.useFormEncoding;

      // Check if the headers have changed during the edit so we can update "instances"
      let headersChanged = false;
      const oldHeaderNamesToNew = {};
      for (const header of action.headers) {
        if (header.orgName !== undefined) { // orgName means 'original name'
          oldHeaderNamesToNew[header.orgName] = header.name;
        }
        if (header.orgName !== header.name || header.orgDefault !== header.default) {
          headersChanged = true;
        }

        // Check if the secret has been updated for header
        if (header.secretReference !== undefined) {
          const currentHeader = currentSavedAction.headers.find((x) => x.name === header.name);
          if (currentHeader === undefined) {
            // The header has been added or renamed
            headersChanged = true;
          } else if (currentHeader.secretReference === undefined) {
            // Header did not previously use a secret for value, but does now
            headersChanged = true;
          } else if (currentHeader.secretReference.secretId !== header.secretReference.secretId) {
            // Header references another secret now
            headersChanged = true;
          }
        }
      }
      // Check whether headers were deleted or added
      if (currentSavedAction.headers.length !== action.headers.length) {
        headersChanged = true;
      }

      // Check if the parameters have changed during the edit so we can update "instances"
      let paramsChanged = false;
      // Now we know that the name is unique. We go through all nodes, and for each node
      // check all their actions and see if any of them are changed.
      const oldParamsNamesToNew = {};
      for (const param of action.params) {
        if (param.orgName !== undefined) {
          oldParamsNamesToNew[param.orgName] = param.name;
        }
        if (param.orgName !== param.name || param.orgDefault !== param.default) {
          paramsChanged = true;
        }

        // Check if the secret has been updated for param
        if (param.secretReference !== undefined) {
          const currentParam = currentSavedAction.params.find((x) => x.name === param.name);
          if (currentParam === undefined) {
            // The param has been added or renamed
            paramsChanged = true;
          } else if (currentParam.secretReference === undefined) {
            // Parameter did not previously use a secret for value, but does now
            paramsChanged = true;
          } else if (currentParam.secretReference.secretId !== param.secretReference.secretId) {
            // Parameter references another secret now
            paramsChanged = true;
          }
        }
      }

      // Check whether parameters were deleted or added
      if (currentSavedAction.params.length !== action.params.length) {
        paramsChanged = true;
      }

      // Iterate through all nodes in (main)bot based on this integration
      for (const node of nodesGetters.allNodesAsList(state.activeBot)) {
        for (const activity of Object.values(node.activities)) {
          if (activity.type === ACTION && activity.name === action.name) {
            // A dictionary form of the headers for the modified action.
            const headerDict = {};
            // First we construct the headerDict by assigning the default values for each header
            for (const header of action.headers) {
              if (header.secretReference !== undefined) {
                headerDict[header.name] = {
                  secretReference: header.secretReference,
                  value: header.default,
                };
              } else {
                headerDict[header.name] = header.default;
              }
            }

            // Then, for headers that are not new or renamed, we overwrite the headerDict entry
            // with the concrete integration's current value.
            for (const header of activity.headers) {
              const newName = oldHeaderNamesToNew[header.key];
              if (newName !== undefined) {
                if (headerDict[newName].secretReference !== undefined) {
                  // Intentionally, do _not_ do anything here!
                  // For secret references, the behaviour is different; we override with the one
                  // set in blueprint, so that concrete integrations and integration blueprints
                  // are always in sync in regards to the secrets they reference
                } else {
                  headerDict[newName] = header.value;
                }
              }
            }

            // Save the headers in list format instead of in dict format
            activity.headers = action.headers
              .map(({ name }) => {
                if (headerDict[name].secretReference !== undefined) {
                  return {
                    key: name,
                    value: headerDict[name].value,
                    secretReference: headerDict[name].secretReference,
                  };
                }
                return {
                  key: name,
                  value: headerDict[name],
                };
              });

            // A dictionary form of the params for the modified action.
            const paramDict = {};
            // First we construct the paramDict by assigning the default values for each parameter
            for (const param of action.params) {
              if (param.secretReference !== undefined) {
                paramDict[param.name] = {
                  secretReference: param.secretReference,
                  value: param.default,
                };
              } else {
                paramDict[param.name] = param.default;
              }
            }
            // For parameters that are not new or renamed we overwrite with the old value.
            for (const param of activity.params) {
              const newName = oldParamsNamesToNew[param.key];
              if (newName !== undefined) {
                // This parameter is either (A) not new or (B) wasn't renamed
                if (paramDict[newName].secretReference !== undefined) {
                  // Intentionally, do _not_ do anything here!
                  // For secret references, the behaviour is different; we override with the one
                  // set in blueprint, so that concrete integrations and integration blueprints
                  // are always in sync in regards to the secrets they reference
                } else {
                  paramDict[newName] = param.value;
                }
              }
            }

            // Save the params in list format instead of in dict format
            activity.params = action.params
              .map(({ name }) => {
                if (paramDict[name].secretReference !== undefined) {
                  return {
                    key: name,
                    value: paramDict[name].value,
                    secretReference: paramDict[name].secretReference,
                  };
                }
                return {
                  key: name,
                  value: paramDict[name],
                };
              });

            // Mark that the action was changed
            if (paramsChanged
              || headersChanged
              || allowAppendChanged
              || useFormEncoding
              || expectJsonChanged) {
              activity.modified = true;
            }
          }
        }
      }
      Vue.set(state.activeBot.actions, index, cloneDeep(action));
    }
  },
  deleteBotAction(state, { id }) {
    if (state.activeBotSet) {
      const index = state.activeBot.actions.findIndex((a) => a.name === id);
      const action = state.activeBot.actions[index];
      for (const node of nodesGetters.allNodesAsList(state.activeBot)) {
        const idsToDelete = new Set([]);
        for (const [activityId, activity] of Object.entries(node.activities)) {
          if (activity.type === ACTION && activity.name === action.name) {
            idsToDelete.add(activityId);
          }
        }
        node.activityIds = node.activityIds.filter((aid) => !idsToDelete.has(aid));
        for (const activityId of idsToDelete) {
          Vue.delete(node.activities, activityId);
        }
      }

      // Do this last if the above fails
      state.activeBot.actions.splice(index, 1);
    }
  },
};

export const apiActionActions = {
  createAPIActionFromTemplate({ commit }, { serviceIdentifier, templateId, apiActionName }) {
    const templateDefinition = apiActionTemplates.templates[serviceIdentifier]
      .find((x) => x.id === templateId);
    const template = cloneDeep(templateDefinition.template);

    // Override the name
    template.name = apiActionName;
    commit('addBotAction', { action: template });
  },
};

export default {
  actionGetters,
  actionMutations,
  apiActionActions,
};
