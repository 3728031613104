<template>
  <div
    v-if="!isFetching"
  >
    <b-row>
      <b-col>
        <data-exploration-data-point-card
          :data-point="dataPoint"
          :dataset-id="datasetId"
          :dataset-type="dataType"
          @deleted-datapoint="handleDeletion"
          @edited-datapoint="handleEdit"
          @input="v => dataPoint.text = v"
        />
      </b-col>
    </b-row>
    <data-exploration-assign-labels
      :dataset-id="datasetId"
      :data-id="dataId"
      :data-type="dataType"
      :submit-should-update-existing-labels="false"
      :already-assigned-labels="categoriesAssignedToDatapoint"
      @submitted="goToEntry"
    />
  </div>
  <b-spinner
    v-else
    class="m-4"
    style="width: 4rem; height: 4rem;"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataExplorationAssignLabels from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationAssignLabels.vue';
import DataExplorationDataPointCard from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationDataPointCard.vue';

export default {
  name: 'DataExplorationLabelingPage',
  components: {
    DataExplorationAssignLabels,
    DataExplorationDataPointCard,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData(
        { dataId: to.params.dataId, dataType: to.params.dataType, datasetId: vm.datasetId },
      );
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.dataId !== from.params.dataId) {
      this.fetchData(
        { dataId: to.params.dataId, dataType: to.params.dataType, datasetId: this.datasetId },
      );
    }
    next();
  },
  computed: {
    ...mapGetters('dataExploration/label', ['text', 'categoriesAssignedToDatapoint', 'isFetching']),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    dataId() {
      return parseInt(this.$route.params.dataId, 10);
    },
    dataType() {
      return this.$route.params.dataType;
    },
    dataPoint() {
      return {
        id: this.dataId,
        text: this.text,
      };
    },
  },
  methods: {
    ...mapActions('dataExploration/label', ['fetchData']),
    goToEntry() {
      this.$router.push({ name: 'dataExplorationLabelingEntry', params: { datasetId: this.datasetId } });
    },
    handleDeletion() {
      this.goToEntry();
    },
    handleEdit() {
      this.fetchData({ dataId: this.dataId, dataType: this.dataType, datasetId: this.datasetId });
    },
  },
};
</script>
