<template>
  <div class="p-3">
    <b-row>
      <b-col><strong>  Language: </strong></b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="auto"
        class="my-auto pl-4"
      >
        Master:
      </b-col>
      <b-col>
        <b-form-select
          :value="getRoutingLanguage"
          :options="languages"
          :disabled="true"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="auto"
        class="my-auto pl-4"
      >
        Variant:
      </b-col>
      <b-col>
        <b-form-select
          :value="selectedVariantRoutingLanguage"
          :options="languages"
          @change="value => setAndPushVariantConfigField(
            { value, indexList: ['config', 'routing', 'language'] },
          )"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col><strong>  Labels: </strong></b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        class="mb-auto mt-2 pl-4"
      >
        <p class="mt-1">
          Master:
        </p>
      </b-col>
      <b-col>
        <b-button
          v-for="x in getRoutingLabels"
          :key="x"
          pill
          class="mr-1 mt-1"
          :disabled="true"
        >
          {{ x }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="auto"
        class="mb-auto mt-2 pl-4"
      >
        Variant:
      </b-col>
      <b-col>
        <b-input
          v-model="newLabel"
          class="mb-1"
          placeholder="Add label"
          @change="e => addSelectedVariantRoutingLabel(e)"
        />
        <b-button
          v-for="x in selectedVariantRoutingLabels"
          :key="x"
          pill
          class="mr-1 mt-1"
        >
          {{ x }}
          <font-awesome-icon
            :icon="['far', 'times-circle']"
            size="lg"
            @click.stop="deleteSelectedVariantRoutingLabel(x)"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LanguageOptions } from '@/js/constants';

export default {
  name: 'VariantRouting',
  data() {
    return {
      newLabel: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getRoutingLanguage',
      'getRoutingLabels',
    ]),
    ...mapGetters('variants', [
      'selectedVariantConfig',
    ]),
    languages() {
      const languages = [...LanguageOptions]; // Shallow copy!!
      languages.unshift({ value: null, text: 'Select language' });
      return languages;
    },
    selectedVariantRoutingLanguage() {
      if (!this.selectedVariantConfig.config || !this.selectedVariantConfig.config.routing) {
        return undefined;
      }
      return this.selectedVariantConfig.config.routing.language;
    },
    selectedVariantRoutingLabels() {
      if (!this.selectedVariantConfig.config || !this.selectedVariantConfig.config.routing) {
        return undefined;
      }
      return this.selectedVariantConfig.config.routing.labels;
    },
  },
  methods: {
    ...mapActions('variants', [
      'setAndPushVariantConfigField',
    ]),
    addSelectedVariantRoutingLabel(value) {
      if (value) {
        const currentLabels = this.selectedVariantRoutingLabels || [];
        this.setAndPushVariantConfigField({
          value: [...new Set([...currentLabels, value])],
          indexList: ['config', 'routing', 'labels'],
        });
        this.newLabel = '';
      }
    },
    deleteSelectedVariantRoutingLabel(value) {
      const currentLabels = this.selectedVariantRoutingLabels || [];
      this.setAndPushVariantConfigField({
        value: currentLabels.filter((x) => x !== value),
        indexList: ['config', 'routing', 'labels'],
      });
    },
  },
};
</script>

<style scoped>

</style>
