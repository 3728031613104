<template>
  <b-modal
    id="classifier-upload-modal"
    title="Upload classifier"
    hide-footer
  >
    <!-- Show user some useful info about the upload process. -->
    <b-alert
      variant="primary"
      :show="classifierUploading"
      class="shadow-sm"
    >
      <font-awesome-icon
        icon="spinner"
        size="lg"
        spin
      />
      Model is being uploaded. Do NOT leave this page.
    </b-alert>
    <b-alert
      variant="success"
      :show="!classifierUploading && classifierUploadSuccess === true"
      class="shadow-sm"
    >
      Upload succeeded!
    </b-alert>
    <b-alert
      variant="danger"
      :show="!classifierUploading && classifierUploadSuccess === false"
      class="shadow-sm"
    >
      Upload failed: {{ classifierUploadError }}
    </b-alert>

    <!-- Form for uploading a new model -->
    <div
      v-if="!classifierUploading"
      class="mt-3"
    >
      <b-form-group
        label="Name"
        label-for="classifierNameForm"
      >
        <b-form-input
          id="classifierNameForm"
          v-model="form.newClassifierName"
          type="text"
          placeholder="Choose a name for your model..."
          :state="$v.form.newClassifierName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.form.newClassifierName.required">
            You must provide a name for the model
          </div>
          <div v-if="!$v.form.newClassifierName.uniqueName">
            Your model name must be unique
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="classifierDescriptionForm"
      >
        <b-form-input
          id="classifierDescriptionForm"
          v-model="form.newClassifierDescription"
          type="text"
          placeholder="Give a description of your model..."
        />
      </b-form-group>
      <b-form-group
        label="File"
        label-for="classifierFileForm"
      >
        <b-form-file
          id="classifierFileForm"
          v-model="form.newClassifierFile"
          accept=".zip"
          placeholder="Choose a zipped model folder..."
          :state="$v.form.newClassifierFile.$invalid ? false : null"
        />
        <!-- Bug 3545: this invalid does not work so we added :state instead -->
        <b-form-invalid-feedback>
          <div v-if="$v.form.newClassifierFile.$invalid">
            Missing file
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button
        class="p-2"
        variant="primary"
        :disabled="$v.form.$invalid"
        @click="() => uploadClassifier()"
      >
        Upload model
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getConnectionErrorMsg } from '@/js/utils';

export default {
  name: 'ClassifierUpload',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        newClassifierName: '',
        newClassifierDescription: '',
        newClassifierFile: null,
      },
      classifierUploading: false,
      classifierUploadSuccess: null,
      classifierUploadError: '',
    };
  },
  computed: {
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
    ]),
  },
  methods: {
    ...mapActions('nlu/classifier', [
      'fetchGlobalNLUModels',
    ]),
    uploadClassifier() {
      this.classifierUploading = true;
      this.classifierUploadSuccess = false;
      this.classifierUploadError = '';
      const formData = new FormData();
      formData.append('name', this.form.newClassifierName);
      formData.append('description', this.form.newClassifierDescription);
      formData.append('version_description', 'first upload');
      formData.append('file', this.form.newClassifierFile);
      formData.append('newClf', true);
      formData.append('type', 'swml');
      axios.post('/api/classifier/', formData, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      }).then(() => {
        this.classifierUploadSuccess = true;
        this.classifierUploadError = '';
        this.classifierUploading = false;
        this.form.newClassifierName = '';
        this.form.newClassifierDescription = '';
        this.form.newClassifierFile = null;
        this.fetchGlobalNLUModels();
      }).catch((e) => {
        this.classifierUploadError = getConnectionErrorMsg(e);
        this.classifierUploadSuccess = false;
        this.classifierUploading = false;
      });
    },
  },
  validations: {
    form: {
      newClassifierName: {
        required,
        uniqueName(value) {
          const nluModels = this.getGlobalNLUModels;
          const nluModelNames = Object.values(nluModels).map((nluModel) => nluModel.name);
          return !nluModelNames.includes(value);
        },
      },
      newClassifierFile: {
        required,
        notNull(value) {
          return !!value;
        },
      },
    },
  },
};
</script>
