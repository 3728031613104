<template>
  <b-row>
    <b-col>
      <b-input-group :size="size">
        <b-input-group-prepend :style="minKeyWidth ? `width: ${minKeyWidth}px;` : ''">
          <b-input-group-text
            :size="size"
            class="bg-primary text-white w-100 justify-content-center"
            style="word-break:break-all; white-space:pre-line;"
          >
            {{ keyProp }}
          </b-input-group-text>
        </b-input-group-prepend>
        <vue-json-pretty
          v-if="isJsonObject(valueProp)"
          :data="valueProp"
          :style="`flex-grow:100; height: inherit; width: calc(100% - ${minKeyWidth}px;`"
          class="border value"
        />
        <b-input-group-text
          v-else
          :class="size === 'sm' ? 'small' : ''"
          class="value bg-white"
          style="flex-grow:100; height: inherit; overflow-x: auto; flex-basis: 0"
        >
          {{ valueProp }}
        </b-input-group-text>
      </b-input-group>
    </b-col>
  </b-row>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'DisplayKeyValue',
  components: {
    VueJsonPretty,
  },
  props: {
    size: {
      type: String,
      default: 'md',
    },
    keyProp: {
      type: String,
      required: true,
    },
    valueProp: {
      type: [String, Number, Boolean, Date, Array, Object],
      required: false,
      default: null,
    },
    minKeyWidth: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    isJsonObject(value) {
      return typeof value === 'object';
    },
  },
};
</script>
<style scoped>
.value{
  white-space: pre-line !important;
  border-radius: 0px 0.25rem 0.25rem 0px  !important;
}
.value {
  white-space:nowrap !important;
}

</style>
