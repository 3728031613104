<template>
  <div>
    <template v-if="isModelsLoading">
      <b-spinner small /> Loading classifiers...
    </template>
    <b-button
      v-else-if="nodeClassifier"
      size="sm"
      variant="primary"
      :to="{ name: 'nlu-model-page', params: { modelId: nodeClassifier } }"
    >
      Go to classifier
    </b-button>
    <b-dropdown
      v-else
      split
      text="Create node classifier"
      size="sm"
      variant="primary"
      @click="createClassifierProxy"
    >
      <b-dropdown-item v-b-modal.create-clf-modal>
        Create classifier with custom permissions
      </b-dropdown-item>
    </b-dropdown>
    <b-modal
      id="create-clf-modal"
      static
      title="Create node classifier"
      ok-title="Create"
      @ok="createClassifierProxy"
    >
      <PermissionsSelector ref="clfPermissions" :permissions="classifierPermissions" />
    </b-modal>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import PermissionsSelector from '@/components/PermissionsSelector.vue';

export default {
  name: 'EditNodeClassifier',
  components: {
    PermissionsSelector,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('botManipulation', ['activeBotId']),
    ...mapGetters('botManipulation', ['getBotId']),
    ...mapGetters('auth', ['isSuperUser']),
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
      'isModelsLoading',
    ]),
    classifierPermissions() {
      return {
        assignYourself: false,
        bots: [this.getBotId],
      };
    },
    nodeClassifier() {
      for (const clf of Object.values(this.getGlobalNLUModels)) {
        if (
          clf.botId === this.activeBotId
          && clf.nodeId === this.nodeId
          && clf.mainClassifier === null
        ) {
          return clf.id;
        }
      }
      return null;
    },
  },
  created() {
    this.fetchGlobalNLUModels();
  },
  methods: {
    ...mapActions('nlu/classifier', [
      'fetchGlobalNLUModels',
      'createClassifier',
    ]),
    createClassifierProxy() {
      const { assignYourself, botPermissions, groupPermissions } = this.$refs.clfPermissions;
      this.createClassifier({
        type: 'swml.bot',
        botId: this.activeBotId,
        nodeId: this.nodeId,
        permissions: {
          assign_user: assignYourself,
          groups: groupPermissions,
          bots: botPermissions,
        },
      });
    },
  },
};
</script>
