<template>
  <main>
    <b-card
      title="Variants"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold mb-2">
        On this page you can create and manage variants of your bots.
      </p>
      <p class="mb-2">
        This is useful if you use the same bot for different brands or languages as well as for
        creating a test-version of your bot.
      </p>
      <p class="mb-2">
        Select or create a variant below to get started.
      </p>
      <div
        v-if="showPhraseIntegrationComponents"
      >
        <b-button
          variant="primary"
          @click.stop="presentPhraseConfigModal"
        >
          Configure <em>Phrase</em> integration
        </b-button>
        <b-button
          v-if="phraseIntegrationConfig.isEnabled && !isPushingToPhrase"
          class="ml-2"
          variant="primary"
          :disabled="isPushingToPhrase"
          @click.stop="pushMasterToPhraseProxy"
        >
          <b-spinner
            v-if="isPushingToPhrase"
            small
          />
          Push master to <em>Phrase</em>
        </b-button>
        <b-button
          v-if="phraseIntegrationConfig.isEnabled"
          class="ml-2"
          variant="primary"
          :disabled="isFetchingFromPhrase"
          @click.stop="pullMasterFromPhrase"
        >
          <b-spinner
            v-if="isFetchingFromPhrase"
            small
          />
          Fetch master from <em>Phrase</em>
        </b-button>
        <b-button
          v-if="phraseIntegrationConfig.isEnabled"
          class="ml-2"
          variant="primary"
          :disabled="isFetchingFromPhrase"
          @click.stop="pullAllFromPhrase"
        >
          <b-spinner
            v-if="isFetchingFromPhrase"
            small
          />
          Fetch all variants from <em>Phrase</em>
        </b-button>
      </div>
    </b-card>

    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      title="Variant list"
    >
      <b-table
        show-empty
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :items="variantsList"
        sort-by="name"
        :fields="tableFields"
        :busy="isVariantsRefreshing"
        hover
        @row-clicked="onRowClicked"
      >
        <template #empty>
          <div class="text-center">
            There are currently no variants of this bot. Create one!
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <b-button
            variant="outline-danger"
            @click="deleteVariantLocal(item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
        <template #cell(status)="{ item }">
          <template v-if="item.id in getTranslationTasks">
            <b-spinner
              class="mr-3"
              small
            />
            Translating... {{ getTranslationTasks[item.id].progress }}
          </template>
        </template>
      </b-table>
      <b-row
        class="mt-3 ml-1"
      >
        <b-button
          v-b-modal.createVariantModal
          variant="primary"
        >
          <font-awesome-icon icon="plus" /> New variant
        </b-button>
      </b-row>
    </b-card>

    <b-modal
      id="createVariantModal"
      title="Create new variant"
      ok-title="Create"
      :ok-disabled="$v.createVariantModalData.$invalid"
      @ok="createVariantProxy"
      @shown="createVariantModalShown"
    >
      <b-form-group
        label="Name"
        label-for="createVariantName"
        description="Give this variant a descriptive name"
      >
        <b-form-input
          id="createVariantName"
          v-model="createVariantModalData.variantName"
          type="text"
          :state="!$v.createVariantModalData.variantName.$invalid"
          aria-describedby="variantNameFeedback"
          @keyup.enter.native="createVariantProxy"
        />
        <b-form-invalid-feedback id="variantNameFeedback">
          <div v-if="!$v.createVariantModalData.variantName.required">
            Variant must have a name
          </div>
          <div v-else-if="!$v.createVariantModalData.variantName.uniqueName">
            The name already exists.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Variant language"
        description="Language of the variant used for routing and translations"
      >
        <b-form-select
          id="createVariantTranslation"
          v-model="createVariantModalData.language"
          :state="!$v.createVariantModalData.language.$invalid"
          :options="languages"
        />
        <b-form-invalid-feedback>
          You must choose a language.
        </b-form-invalid-feedback>
      </b-form-group>
      <template v-if="showTranslationComponents">
        <template v-if="showTranslationOptions">
          <b-form-group
            description="Automatically generate translations for the variant."
          >
            <b-form-checkbox
              v-model="createVariantModalData.autoTranslate"
              switch
            >
              Automatic variant translation
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="createVariantModalData.autoTranslate"
            description="Create language variants for the classifiers of the main bot."
          >
            <b-form-checkbox
              v-model="createVariantModalData.createClassifiers"
              switch
            >
              Create classifier variants
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="createVariantModalData.autoTranslate
              && createVariantModalData.createClassifiers"
            description="This will translate data used by the classifiers of the main bot
              and add the data to the classifier variants."
            :state="$v.createVariantModalData.doClassifierTranslation.$invalid ? false : null"
            :invalid-feedback="`All classifiers used by the bot must have a language defined.
              Please contact SupWiz to define the language of the following classifiers:
              ${clfsMissingLanguage.join(', ')}.`"
          >
            <b-form-checkbox
              v-model="createVariantModalData.doClassifierTranslation"
              :state="$v.createVariantModalData.doClassifierTranslation.$invalid ? false : null"
              switch
            >
              Translate classifier data
            </b-form-checkbox>
          </b-form-group>
        </template>
        <b-alert
          v-if="!getRoutingLanguage"
          show
          variant="light"
        >
          Automatic translation will not be provided unless you configure the bot language under
          the
          <ConfigLink category="routing">
            routing configuration.
          </ConfigLink>
        </b-alert>
      </template>
    </b-modal>

    <b-modal
      id="editPhraseIntegrationConfigModal"
      ref="editPhraseIntegrationConfigModal"
      ok-title="Save"
      :ok-disabled="$v.phraseIntegrationConfigModalData.$invalid"
      @ok="savePhraseIntegrationConfigProxy"
    >
      <template #modal-title>
        Are you sure you want to import from <em>Phrase</em>?
      </template>
      <b-form>
        <b-form-group>
          <template #label>
            Edit <em>Phrase</em> integration configuration
          </template>
          <b-form-checkbox
            v-model="phraseIntegrationConfigModalData.isEnabled"
            switch
          >
            {{ phraseIntegrationConfigModalData.isEnabled ? 'Enabled' : 'Disabled' }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="phraseIntegrationConfigModalData.isEnabled">
          <template #label>
            <em>Phrase</em> authentication token
          </template>
          <b-form-input
            v-model="phraseIntegrationConfigModalData.authToken"
            :placeholder="phraseIntegrationConfigModalData.authTokenExists ? '<SECRET_KEY>' : ''"
            :state="$v.phraseIntegrationConfigModalData.authToken.$invalid ? false : null"
            aria-describedby="phraseAuthTokenFeedback"
          />
          <b-form-invalid-feedback id="phraseAuthTokenFeedback">
            <div v-if="!$v.phraseIntegrationConfigModalData.authToken.required">
              A <em>Phrase</em> authentication token must be provided.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="phraseIntegrationConfigModalData.isEnabled">
          <template #label>
            <em>Phrase</em> project id
          </template>
          <b-form-input
            v-model="phraseIntegrationConfigModalData.projectId"
            :state="$v.phraseIntegrationConfigModalData.projectId.$invalid ? false : null"
            aria-describedby="phraseProjectIdFeedback"
          />
          <b-form-invalid-feedback id="phraseProjectIdFeedback">
            <div v-if="!$v.phraseIntegrationConfigModalData.projectId.required">
              Project id must be specified.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="phraseIntegrationConfigModalData.isEnabled">
          <template #label>
            <em>Phrase</em> master locale id for pushing to <em>Phrase</em>
          </template>
          <b-form-input
            v-model="phraseIntegrationConfigModalData.masterLocaleIdPush"
            :state="$v.phraseIntegrationConfigModalData.masterLocaleIdPush.$invalid ? false : null"
            aria-describedby="phraseMasterLocaleIdFeedback"
          />
          <b-form-invalid-feedback id="phraseMasterLocaleIdFeedback">
            <div v-if="!$v.phraseIntegrationConfigModalData.masterLocaleIdPush.required">
              Master locale id for pushing must be specified.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="phraseIntegrationConfigModalData.isEnabled">
          <template #label>
            <em>Phrase</em> master locale id for pulling from <em>Phrase</em>
          </template>
          <b-form-input
            v-model="phraseIntegrationConfigModalData.masterLocaleIdPull"
            :state="$v.phraseIntegrationConfigModalData.masterLocaleIdPull.$invalid ? false : null"
            aria-describedby="phraseMasterLocaleIdFeedback"
          />
          <b-form-invalid-feedback id="phraseMasterLocaleIdFeedback">
            <div v-if="!$v.phraseIntegrationConfigModalData.masterLocaleIdPull.required">
              Master locale id for pulling must be specified.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="phrase-import-master-modal"
      ok-title="Import"
      scrollable
      @ok="pullMasterFromPhraseCommit"
    >
      <template #modal-title>
        Are you sure you want to import master bot from <em>Phrase</em>?
        <b-alert
          variant="warning"
          class="text-center py-1 mt-1"
        >
          It is strongly advised to stage a (temporary) version at the bot before importing
          data to overwrite the master bot to avoid any irreversible damage.
        </b-alert>
      </template>
      <div v-if="phraseMasterImportData">
        <b-card
          no-body
          class="mb-1 border"
        >
          <b-card-header
            header-tag="header"
            class="p-1"
            role="tab"
          >
            <b-button
              v-b-toggle="'accordion-master'"
              block
              variant="info"
            >
              {{ phraseMasterImportData.keys_new.length }} new,
              {{ phraseMasterImportData.keys_upd.length }} updated
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-master'"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <p>{{ phraseMasterImportData.keys_new.length }} keys will be created.</p>
              <b-list-group flush>
                <b-list-group-item
                  v-for="(key, index) in phraseMasterImportData.keys_new"
                  :key="index"
                  class="py-1 px-1 break-text"
                >
                  {{ key }}
                </b-list-group-item>
              </b-list-group>
              <p class="mt-2">
                {{ phraseMasterImportData.keys_upd.length }} keys will be updated.
              </p>
              <b-list-group>
                <b-list-group-item
                  v-for="(key, index) in phraseMasterImportData.keys_upd"
                  :key="index"
                  class="py-1 px-1 break-text"
                >
                  {{ key }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>
    <b-modal
      id="phrase-import-all-modal"
      ok-title="Import"
      scrollable
      @ok="pullAllFromPhraseCommit"
    >
      <template #modal-title>
        Are you sure you want to import from <em>Phrase</em>?
      </template>
      <div v-if="phraseImportData">
        <b-card
          v-for="[variantId, variantData] of Object.entries(phraseImportData)"
          :key="variantId"
          no-body
          class="mb-1 border"
        >
          <b-card-header
            header-tag="header"
            class="p-1"
            role="tab"
          >
            <b-button
              v-b-toggle="`accordion-${variantId}`"
              block
              variant="info"
            >
              {{ variantData.variant_name }} ({{ variantData.keys_new.length }} new,
              {{ variantData.keys_upd.length }} updated)
            </b-button>
          </b-card-header>
          <b-collapse
            :id="`accordion-${variantId}`"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <p>{{ variantData.keys_new.length }} keys will be created.</p>
              <b-list-group flush>
                <b-list-group-item
                  v-for="(key, index) in variantData.keys_new"
                  :key="index"
                  class="py-1 px-1 break-text"
                >
                  {{ key }}
                </b-list-group-item>
              </b-list-group>
              <p class="mt-2">
                {{ variantData.keys_upd.length }} keys will be updated.
              </p>
              <b-list-group>
                <b-list-group-item
                  v-for="(key, index) in variantData.keys_upd"
                  :key="index"
                  class="py-1 px-1 break-text"
                >
                  {{ key }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>
  </main>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { isPhraseIntegrationEnabled } from '@/js/featureFlags';
import { LanguageOptions } from '@/js/constants';
import ConfigLink from '@/pages/BotConfig/ConfigLink.vue';

export default {
  name: 'VariantsPage',
  components: { ConfigLink },
  mixins: [validationMixin],
  data() {
    return {
      createVariantModalData: {
        variantName: null,
        language: null,
        autoTranslate: false,
        createClassifiers: true,
        doClassifierTranslation: true,
      },
      tableFields: [
        {
          key: 'actions',
          label: '',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width:50px',
        },
        { key: 'name', tdClass: 'align-middle', sortable: true },
        {
          key: 'status',
          label: '',
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: 'width:250px',
        },
      ],
      phraseIntegrationConfigModalData: {
        isEnabled: false,
        authToken: null,
        projectId: null,
        masterLocaleIdPush: null,
        masterLocaleIdPull: null,
        authTokenExists: false,
      },
      isPushingToPhrase: false,
      isFetchingFromPhrase: false,
      phraseImportData: null,
      phraseMasterImportData: null,
    };
  },
  computed: {
    ...mapGetters('variants', [
      'isVariantsRefreshing',
      'variantsList',
      'phraseIntegrationConfig',
      'getTranslationTasks',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getRoutingLanguage',
      'getNLUModels',
    ]),
    ...mapGetters('nlu/classifier', [
      'getModelFromId',
    ]),
    ...mapGetters('userSettings', ['showTranslationFeatures']),
    showPhraseIntegrationComponents() {
      return isPhraseIntegrationEnabled;
    },
    showTranslationComponents() {
      return this.showTranslationFeatures;
    },
    showTranslationOptions() {
      return this.getRoutingLanguage
        && this.getRoutingLanguage !== this.createVariantModalData.language;
    },
    languages() {
      const languages = [...LanguageOptions]; // Shallow copy!!
      if (!this.getRoutingLanguage) {
        return languages;
      }
      const idx = languages.findIndex((x) => x.value === this.getRoutingLanguage);
      if (idx < 0) {
        return languages;
      }
      languages[idx] = { value: languages[idx].value, text: `${languages[idx].text} (main bot language)` };
      return languages;
    },
    clfsMissingLanguage() {
      const ret = [];
      for (const clf of this.getNLUModels) {
        const model = this.getModelFromId(clf.modelId);
        if (model.language === null) {
          ret.push(model.name);
        }
      }
      return ret;
    },
  },
  async mounted() {
    await this.fetchVariants();
  },
  methods: {
    ...mapActions('variants', [
      'createVariant',
      'fetchVariants',
      'savePhraseIntegrationConfig',
      'pushMasterToPhrase',
      'deleteVariant',
      'fetchMasterFromPhrase',
      'fetchMasterFromPhraseCommit',
      'fetchAllVariantsFromPhrase',
      'fetchBatchFromPhraseCommit',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    createData(field) {
      return this.showTranslationOptions ? this.createVariantModalData[field] : false;
    },
    createVariantModalShown() {
      this.createVariantModalData.variantName = null;
      this.createVariantModalData.language = null;
    },
    async createVariantProxy() {
      await this.createVariant({
        name: this.createVariantModalData.variantName,
        language: this.createVariantModalData.language,
        translateVariant: this.createData('autoTranslate'),
        createClassifiers: this.createData('createClassifiers'),
        doClassifierTranslation: this.createData('doClassifierTranslation'),
      });
      this.$bvModal.hide('createVariantModal');
    },
    onRowClicked(item, index, event) {
      if (item.id in this.getTranslationTasks) {
        this.$root.$bvToast.toast('You must wait for the variant to finish translating.', {
          title: 'Translation in progress',
        });
      } else {
        this.openCtrlLink({ name: 'edit-variant', params: { variantId: item.id } }, event);
      }
    },
    presentPhraseConfigModal() {
      this.phraseIntegrationConfigModalData = { ...this.phraseIntegrationConfig, authToken: null };
      this.$bvModal.show('editPhraseIntegrationConfigModal');
    },
    async pushMasterToPhraseProxy() {
      try {
        this.isPushingToPhrase = true;
        await this.pushMasterToPhrase();
        this.$root.$bvToast.toast('Successfully pushed master texts to Phrase', {
          title: 'Upload successful',
          appendToast: true,
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        this.$root.$bvToast.toast('An error occurred while uploading texts to Phrase.', {
          title: 'An error occured',
          appendToast: true,
          variant: 'danger',
          solid: true,
        });
      }
      this.isPushingToPhrase = false;
    },
    showPhraseError() {
      this.showWarning({
        title: 'An error occured',
        text: 'An error occurred while fetching texts from Phrase.',
        variant: 'danger',
      });
    },
    async pullMasterFromPhrase() {
      this.isFetchingFromPhrase = true;
      try {
        this.phraseMasterImportData = await this.fetchMasterFromPhrase();
        setTimeout(() => {
          this.isFetchingFromPhrase = false;
          this.$bvModal.show('phrase-import-master-modal');
        }, 1000);
      } catch (e) {
        this.showPhraseError();
      } finally {
        this.isFetchingFromPhrase = false;
      }
    },
    async pullMasterFromPhraseCommit() {
      this.isFetchingFromPhrase = true;
      try {
        await this.fetchMasterFromPhraseCommit(this.phraseMasterImportData);
      } catch (e) {
        this.showPhraseError();
      } finally {
        this.isFetchingFromPhrase = false;
      }
    },
    async pullAllFromPhrase() {
      this.isFetchingFromPhrase = true;
      try {
        this.phraseImportData = await this.fetchAllVariantsFromPhrase();
        setTimeout(() => {
          this.isFetchingFromPhrase = false;
          this.$bvModal.show('phrase-import-all-modal');
        }, 1000);
      } catch (e) {
        this.showPhraseError();
        this.isFetchingFromPhrase = false;
      }
    },
    async pullAllFromPhraseCommit() {
      this.isFetchingFromPhrase = true;
      try {
        await this.fetchBatchFromPhraseCommit(this.phraseImportData);
        this.isFetchingFromPhrase = false;
      } catch (e) {
        this.showPhraseError();
        this.isFetchingFromPhrase = false;
      }
    },
    async savePhraseIntegrationConfigProxy() {
      await this.savePhraseIntegrationConfig(this.phraseIntegrationConfigModalData);
    },
    async deleteVariantLocal(item) {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this variant?')) {
        this.deleteVariant(item);
      }
    },
  },
  validations() {
    return {
      createVariantModalData: {
        variantName: {
          required,
          uniqueName(value) {
            return !(this.variantsList.map((x) => x.name).includes(value));
          },
        },
        language: {
          required,
        },
        doClassifierTranslation: {
          validLanguage(value) {
            return !this.showTranslationOptions
              || !this.createVariantModalData.autoTranslate
              || !this.createVariantModalData.createClassifiers
              || !value
              || this.clfsMissingLanguage.length === 0;
          },
        },
      },
      phraseIntegrationConfigModalData: {
        projectId: {
          required,
        },
        masterLocaleIdPush: {
          required,
        },
        masterLocaleIdPull: {
          required,
        },
        authToken: {
          required: requiredIf(() => !this.phraseIntegrationConfigModalData.authTokenExists),
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .custom-control-label {
  font-size: 0.9375rem !important;
}
</style>
