<template>
  <b-modal
    id="addUnitTestModal"
    title="Add Conversation Test"
    ok-title="Save"
    :ok-disabled="$v.formGroup.$invalid"
    @ok="proxyAddUnitTest"
    @shown="unitTestModalShown"
  >
    <b-form-group
      label="Name"
      label-for="addUnitTestName"
      description="Give this conversation test a descriptive name"
    >
      <b-form-input
        id="addUnitTestName"
        ref="addUnitTestNameRef"
        v-model="tmpUnitTestName"
        type="text"
        :state="($v.messages.$invalid || $v.tmpUnitTestName.$invalid) ? false : null"
        class="form-control border p-1"
        aria-describedby="testNameFeedback"
        placeholder="TestName"
        @keyup.enter.native="proxyAddUnitTest"
      />
      <b-form-invalid-feedback id="testNameFeedback">
        <div v-if="!$v.messages.required">
          No conversation to save as test.
        </div>
        <div v-else-if="!$v.tmpUnitTestName.required">
          The test needs a name.
        </div>
        <div v-else-if="!$v.tmpUnitTestName.uniqueName">
          The name already exists.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="What to test"
      :state="!$v.whatToTest.$invalid"
      invalid-feedback="Something must be tested for."
    >
      <b-form-checkbox
        v-model="testPath"
        switch
      >
        Test path
      </b-form-checkbox>
      <small class="text-muted">
        Test that the bot keeps following the same node path given the same user input.
      </small>
      <b-form-checkbox
        v-model="testContent"
        class="mt-2"
        switch
      >
        Test content
      </b-form-checkbox>
      <small class="text-muted">
        Test that the bot keeps having the exact same conversation given the same user input.
      </small>
    </b-form-group>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AddConvTestModal',
  mixins: [validationMixin],
  props: {
    path: {
      required: true,
      type: Array,
    },
    messages: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      tmpUnitTestName: null,
      testPath: true,
      testContent: false,
    };
  },
  computed: {
    ...mapGetters('unitTest', ['getUnitTests', 'getAiTests']),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
  },
  methods: {
    unitTestModalShown() {
      this.tmpUnitTestName = null;
      this.$refs.addUnitTestNameRef.focus();
    },
    async proxyAddUnitTest() {
      if (this.$v.formGroup.$invalid) {
        return;
      }
      await this.$store.dispatch(
        'unitTest/newUnitTest',
        {
          title: this.tmpUnitTestName,
          path: this.path.map((x) => x.id),
          messages: this.messages,
          testPath: this.testPath,
          testContent: this.testContent,
          testGenAi: false,
          genAiEvents: [],
        },
      );

      this.$bvModal.hide('addUnitTestModal');
    },
  },
  validations: {
    messages: {
      required,
    },
    tmpUnitTestName: {
      required,
      uniqueName(value) {
        const testNames = this.getUnitTests.map((x) => x.title)
          .concat(this.getAiTests.map((x) => x.title));
        return !(testNames.includes(value));
      },
    },
    whatToTest: {
      isSet() {
        return this.testPath || this.testContent;
      },
    },
    formGroup: ['messages', 'tmpUnitTestName', 'whatToTest'],
  },
};
</script>
