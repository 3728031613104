<template>
  <div>
    Young nodes:
    <b-form
      inline
    >
      <b-form-input
        id="minAge"
        v-model="newConfig.minDay"
        class="mr-2"
        aria-describedby="minInputFeedback"
        :state="$v.newConfig.minDay.$invalid ? false : null"
        placeholder="Define a young node to be minimum this days old"
        type="number"
        min="0"
        number
      />
      -
      <b-form-input
        id="maxAge"
        v-model="newConfig.maxDay"
        class="ml-2 mr-2"
        aria-describedby="maxInputFeedback"
        :state="$v.newConfig.maxDay.$invalid ? false : null"
        placeholder="Define a young node to be maximum this days old"
        type="number"
        :min="newConfig.minDay"
        number
      />
      days
      <b-form-invalid-feedback id="minInputFeedback">
        <div v-if="!$v.newConfig.minDay.insideBounds">
          Specify a day number greater or equal to zero
        </div>
      </b-form-invalid-feedback>
      <b-form-invalid-feedback id="maxInputFeedback">
        <div v-if="!$v.newConfig.maxDay.insideBounds">
          Specify a day number greater or equal to current minimum days
        </div>
      </b-form-invalid-feedback>
    </b-form>
    <b-button
      variant="info"
      class="mt-2"
      :disabled="!allowSave()"
      @click="saveConfig()"
    >
      Save new config
    </b-button>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, integer, minValue } from 'vuelidate/lib/validators';
import { deepEqualsJson } from 'supwiz/util/data';

export default {
  name: 'YoungNodeConfiguration',
  mixins: [validationMixin],
  data() {
    return {
      newConfig: {
        minDay: 14,
        maxDay: 28,
      },
      // Keeping metricIdentifier around as a constant for convenience.
      metricIdentifier: 'CHECKUP_ON_YOUNG_NODE_RESPONSES',
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', [
      'getConfigForMetric',
    ]),
    currentConfig() {
      return this.getConfigForMetric(this.getBotId, this.metricIdentifier);
    },
  },
  mounted() {
    if (!this.currentConfig) {
      // This will keep default values
      return;
    }
    this.$set(this, 'newConfig', { ...this.newConfig, ...this.currentConfig });
  },
  methods: {
    ...mapActions('health', [
      'updateComputeConfig',
    ]),
    allowSave() {
      if (this.$v.newConfig.$invalid) return false;
      if (!this.currentConfig) return true;
      return !deepEqualsJson(this.newConfig, this.currentConfig);
    },
    saveConfig() {
      // Reset those nodes that have been excluded
      this.newConfig.excluded_ids = [];
      this.updateComputeConfig({
        metricIdentifier: this.metricIdentifier,
        newConfig: this.newConfig,
      });
    },
  },
  validations: {
    newConfig: {
      minDay: {
        required,
        integer,
        insideBounds: minValue(0),
      },
      maxDay: {
        required,
        integer,
        insideBounds(days) {
          return this.newConfig.minDay <= days;
        },
      },
    },
  },
};
</script>

<style scoped>

</style>
