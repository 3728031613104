<template>
  <b-modal
    id="editVarModal"
    ref="editVarModalref"
    :title="modalTitle"
    ok-title="Save"
    :ok-disabled="$v.modalVar.$invalid"
    @ok="editOK"
    @show="initState"
  >
    <form>
      <b-form-group
        for="inputVarName"
        label="Variable name"
      >
        <VariableName
          v-model="modalVar.name"
          :nerindex="editIndex"
          placeholder="NER variable name"
        />
      </b-form-group>

      <b-form-group
        for="inputVarDescription"
        title="Description"
      >
        <b-form-input
          id="inputVarDescription"
          v-model="modalVar.description"
          type="text"
          placeholder="Optional description of what this entity recognizer does"
        />
      </b-form-group>

      <b-form-group
        label="Type"
        for="identifierType"
      >
        <b-form-select
          id="identifierType"
          v-model="modalVar.type"
          :state="$v.modalVar.type.$invalid ? false : null"
          :options="typeOptions"
          aria-desccribedby="typeFeedback"
        />
        <b-form-invalid-feedback id="typeFeedback">
          <div v-if="!$v.modalVar.type.required">
            Please select a type
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <div
        v-if="modalVar.type === 'swml'"
        class="form-group"
      >
        <b-form-group
          label="Select built-in recognizer"
          label-for="predefinedRecognizerDropdown"
        >
          <b-form-select
            id="predefinedRecognizerDropdown"
            v-model="modalVar.chosenPredefinedRecognizer"
            :options="predefinedRecognizerOptions"
            :state="$v.modalVar.chosenPredefinedRecognizer.$invalid ? false : null"
            aria-describedby="SWMLFeedback"
          />
          <b-form-invalid-feedback id="SWMLFeedback">
            <div v-if="!$v.modalVar.chosenPredefinedRecognizer.required">
              Please select a built-in recognizer
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div
        v-else-if="modalVar.type === 'regex'"
        class="form-group"
      >
        <b-form-group
          label="Regular Expression"
        >
          <b-form-textarea
            v-model="modalVar.regex"
            class="text-monospace"
            rows="3"
            :state="$v.modalVar.regex.$invalid ? false : null"
            aria-desccribedby="RegexFeedback"
          />
          <b-form-invalid-feedback id="RegexFeedback">
            <div v-if="!$v.modalVar.regex.required">
              Please write a regex with 1 capture group.
            </div>
            <div v-else-if="!$v.modalVar.regex.validRegex">
              regex should be valid.
            </div>
            <div v-else-if="!$v.modalVar.regex.captureGroup">
              regex should contain exactly 1 capture group.<br>
              Hint: Start inner capture groups with "(?:" to make them non-capture groups.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div v-if="!isMainBot">
        <small>This is a stand-in Named Entitiy Extractor, which can be replaced
          by a real one when using the SubFlow in the bot.</small>
      </div>
    </form>
  </b-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import VariableName from '@/components/VariableName.vue';
import { NamedEntityRecognizerOptions, anonymizationTypes } from '@/js/constants';

const emptyModalVar = {
  name: '',
  description: '',
  type: '',
  regex: '', // Mutually exclusive to chosenPredefinedRecognizer
  chosenPredefinedRecognizer: '', // Mutually exclusive to regex
};

export default {
  name: 'EntityPage',
  components: { VariableName },
  mixins: [validationMixin],
  props: {
    // Supported values for mode: 'addNew', 'editExisting
    mode: {
      type: String,
      required: true,
    },
    editIndex: {
      type: Number,
      required: false, // Only needed when editing an existing recognizer
      default: -1,
    },
  },
  data() {
    return {
      modalVar: cloneDeep(emptyModalVar),
      typeOptions: anonymizationTypes,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'getNERs',
    ]),
    predefinedRecognizerOptions() {
      const mapped = Object.entries(NamedEntityRecognizerOptions)
        .map((x) => ({ value: x[0], text: x[1].displayText }));
      return mapped;
    },
    modalTitle() {
      if (this.mode === 'addNew') {
        return 'Add entity recognizer';
      }
      return 'Edit entity recognizer';
    },
  },
  methods: {
    ...mapActions('botManipulation/activeBot', [
      'addNewNER',
      'editNER',
    ]),
    initState() {
      this.modalVar = cloneDeep(emptyModalVar);
      if (this.mode === 'editExisting' && this.editIndex !== -1) {
        const row = this.getNERs[this.editIndex];
        this.modalVar.name = row.name;
        this.modalVar.description = row.description;
        this.modalVar.type = row.identifier.type;

        if (row.identifier.type === 'swml') {
          this.modalVar.chosenPredefinedRecognizer = row.identifier.data;
        } else if (row.identifier.type === 'regex') {
          this.modalVar.regex = row.identifier.data;
        }
      }
    },
    editOK() {
      const payload = {
        name: this.modalVar.name,
        description: this.modalVar.description,
        identifier: {
          type: '',
          data: '',
        },
      };

      if (this.modalVar.type === 'swml') {
        payload.identifier.type = 'swml';
        payload.identifier.data = this.modalVar.chosenPredefinedRecognizer;
      } else if (this.modalVar.type === 'regex') {
        payload.identifier.type = 'regex';
        payload.identifier.data = this.modalVar.regex;
      }

      if (this.mode === 'addNew') {
        this.addNewNER({ data: payload });
      } else {
        this.editNER({ index: this.editIndex, data: payload });
      }
    },
  },
  validations() {
    return {
      modalVar: {
        name: {
        /**
         * The VariableName component will show a validation error, but the validation state won't
         * be propagated to here, so we must check it too, in order to prevent the user from
         * clicking OK.
         */
          required,
        },
        type: {
          required,
        },
        chosenPredefinedRecognizer: {
        // chosenPredefinedRecognizer is only required when the type is set to swml
          required: requiredIf(() => this.modalVar.type === 'swml'),
        },
        regex: {
        // regex is only required when the type is set to 'regex'
          required: requiredIf(() => this.modalVar.type === 'regex'),
          validRegex(value) {
            if (this.modalVar.type === 'swml') {
              return true;
            }
            let isValid = true;
            try {
              new RegExp(value);
            } catch (e) {
              isValid = false;
            }
            return isValid;
          },
          captureGroup(value) {
            if (this.modalVar.type === 'swml') {
              return true;
            }
            // Ad hock way of testing if there is a capture group.
            if (!value || value.length < 2) {
              return false;
            }
            try {
            // Test by allowing matching the empty string
              const testRegExp = new RegExp(`${value}|`);
              // Actually test the empty string, which will succeed
              const matches = testRegExp.exec('');
              // Unused capture groups are null; so count them
              const numberOfGroups = matches.length - 1;
              // Maybe require exactly 1, as only 1 is kept?
              return numberOfGroups === 1;
            } catch (e) {
            // in case of bad regex
              return false;
            }
          },
        },
      },
    };
  },
};

</script>
