<template>
  <div>
    <p class="mb-2">
      It is now possible to export all bot responses in an easily human-readable excel format.
    </p>
    <div class="text-center">
      <b-img
        class="w-75"
        fluid
        :src="require(`@/assets/featureImages/excel_responses.png`)"
        alt="Example of the new Excel response export"
      />
    </div>
  </div>
</template>
<script>
export default { name: 'ExcelResponseExportDescription' };
</script>
