<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <div v-else>
    <b-card class="r-75">
      <b-row class="mb-3">
        <b-col class="my-auto">
          <b-card-title class="my-auto">
            Manage Images
          </b-card-title>
        </b-col>
        <b-col cols="auto">
          <b-button
            class="upload-btn"
            variant="primary"
            @click="uploadClicked"
          >
            Upload Image
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            class="upload-btn"
            variant="primary"
            @click="newFolder"
          >
            New folder
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="auto">
          <b-list-group style="min-width:300px;">
            <ImageFolder
              v-for="(folder, index) in foldersArray"
              :key="index"
              :folder="folder"
            />
          </b-list-group>
        </b-col>
        <b-col>
          <div class="border r-25 p-2">
            <span v-if="!openedFolderId">Select folder to see images.</span>
            <div>
              <b-table
                class="mb-0"
                show-empty
                :items="folderImages"
                small
                :fields="fields"
                empty-text="There are no images to show"
              >
                <template #cell(actions)="data">
                  <b-button-group size="sm">
                    <b-button
                      v-b-tooltip.hover.noninteractive.viewport
                      title="Copy image link"
                      @click="copyImageLink(data.item.path)"
                    >
                      <font-awesome-icon icon="copy" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.noninteractive.viewport
                      title="Open image in new tab"
                      @click="openImage(data.item.path)"
                    >
                      <font-awesome-icon icon="external-link-alt" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.noninteractive.viewport
                      title="Edit image"
                      @click="editImage(data.item)"
                    >
                      <font-awesome-icon icon="pencil" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.noninteractive.viewport
                      title="Delete image"
                      @click="deleteImage(data.item)"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </b-button>
                  </b-button-group>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="folder-modal"
      :title="`${activeItem.isEdit ? 'Edit' : 'Add new'} folder`"
      hide-footer
    >
      <b-form-group label="Folder name">
        <b-form-input
          v-model="activeItem.name"
          :state="!$v.activeItem.name.$invalid"
          placeholder="Enter folder name"
        />
        <b-form-invalid-feedback>
          The name cannot be empty.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Parent folder" :description="isRoot ? 'Root folder cannot have a parent.' : ''">
        <TreeSelector
          v-model="activeItem.parent"
          :tree="imageParentOptions"
          placeholder="Select folder"
          :disabled="isRoot"
        />
      </b-form-group>
      <b-form-group
        v-if="activeItem.parent"
        label="Permissions"
        description="This folder will inherit permissions from its parent."
      />
      <PermissionsSelector v-show="!activeItem.parent && !isRoot" ref="folderPermissions" :permissions="activeItem.permissions" />
      <b-row>
        <b-col>
          <b-button v-if="activeItem.isEdit" variant="danger" @click="deleteFolder">
            Delete folder
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button :disabled="$v.activeItem.name.$invalid" variant="primary" @click="okFolderClicked">
            {{ activeItem.isEdit ? 'Update' : 'Add' }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="image-modal"
      :title="`${activeItem.isEdit ? 'Edit' : 'Upload'} image`"
      :ok-disabled="isOkDisabled"
      :ok-title="activeItem.isEdit ? 'Update' : 'Upload'"
      ok-only
      @ok="okClicked"
    >
      <b-form-group v-if="!activeItem.isEdit" label="Select image">
        <b-form-file
          v-model="activeItem.image"
          class="img-input"
          accept=".jpg, .png, .gif"
          :state="Boolean(activeItem.image)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        />
      </b-form-group>
      <b-form-group label="Image name">
        <b-form-input
          v-model="activeItem.name"
          :state="!$v.activeItem.name.$invalid"
          placeholder="Enter image name"
        />
        <b-form-invalid-feedback>
          The name cannot be empty.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Parent folder">
        <TreeSelector
          v-model="activeItem.parent"
          :tree="folderParentOptions"
          placeholder="Select folder"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import endpoints from '@/js/urls';
import ImageFolder from '@/pages/Images/ImageFolder.vue';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import PermissionsSelector from '@/components/PermissionsSelector.vue';
import TreeSelector from '@/components/TreeSelector.vue';
import copy from 'clipboard-copy';
import { formatSize } from 'supwiz/util/formatters';

export default {
  name: 'ImagesPage',
  components: { PermissionsSelector, ImageFolder, TreeSelector },
  mixins: [validationMixin],
  provide() {
    return {
      $folders: () => this.folders,
      $images: () => this.images,
      $openedFolderId: () => this.openedFolderId,
    };
  },
  data: () => ({
    ready: false,
    isFetchingFolders: false,
    isFetchingImages: false,
    images: [],
    folders: [],
    newFolderName: '',
    newFolderParent: null,
    activeItem: {},
    openedFolderId: 'default',
    fields: ['name',
      { key: 'size', label: 'Size', formatter: formatSize }, 'type',
      {
        key: 'last_modified_time',
        label: 'Last Modified',
        formatter: (date) => new Date(date).toLocaleString('en-GB'),
      },
      {
        key: 'actions', label: 'Actions', tdClass: 'actions', thClass: 'actions',
      },
    ],
  }),
  computed: {
    isFetching() {
      return !this.ready || this.isFetchingFolders || this.isFetchingImages;
    },
    foldersArray() {
      return [{ name: 'Default', id: 'default' }].concat(this.folders.filter((e) => e.parent === null));
    },
    folderOptions() {
      // Make parent map object
      const parentMap = {};
      for (const folder of this.folders) {
        if (!(folder.parent in parentMap)) {
          parentMap[folder.parent] = [];
        }
        parentMap[folder.parent].push(folder);
      }
      return this.parentTree({ name: 'Default', id: null }, parentMap);
    },
    folderParentOptions() {
      return [{ text: 'Select folder', value: null }].concat(this.folderOptions);
    },
    imageParentOptions() {
      return [{ text: 'Default', value: null }].concat(this.folderOptions);
    },
    isRoot() {
      return this.activeItem.isEdit && this.activeItem.parent === null;
    },
    defaultFolder() {
      return this.openedFolderId === 'default';
    },
    folderImages() {
      if (this.defaultFolder) {
        return this.images.filter((image) => image.parent === null);
      }
      return this.images.filter((image) => image.parent === this.openedFolderId);
    },
    isOkDisabled() {
      if (this.activeItem.isEdit) {
        return this.$v.activeItem.name.$invalid;
      }
      return !this.activeItem.image || this.$v.activeItem.name.$invalid;
    },
  },
  beforeDestroy() {
    this.$root.$off('edit-folder');
    this.$root.$off('image-deleted');
    this.$root.$off('open-folder');
  },
  async created() {
    await this.fetchFolders();
    await this.fetchImages();
    this.ready = true;
    this.$root.$on('edit-folder', (folder) => {
      this.activeItem = { isEdit: true, ...folder };
      this.$bvModal.show('folder-modal');
    });
    this.$root.$on('open-folder', (id) => {
      this.openedFolderId = id;
    });
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    openImage(path) {
      window.open(path, '_blank');
    },
    copyImageLink(path) {
      copy(path);
    },
    uploadClicked() {
      this.activeItem = { isEdit: false };
      this.$bvModal.show('image-modal');
    },
    okClicked() {
      if (this.activeItem.isEdit) {
        this.updateImage();
      } else {
        this.uploadImage();
      }
    },
    editImage(image) {
      this.activeItem = { isEdit: true, ...image };
      this.$bvModal.show('image-modal');
    },
    parentTree(parent, parentMap) {
      if (!(parent.id in parentMap)) {
        return [];
      }
      const elements = [];
      for (const element of parentMap[parent.id]) {
        const children = this.parentTree(element, parentMap);
        elements.push({ value: element.id, text: element.name, children });
      }
      return elements;
    },
    newFolder() {
      this.activeItem = {
        isEdit: false, name: '', parent: null, permissions: {},
      };
      this.$bvModal.show('folder-modal');
    },
    okFolderClicked() {
      if (this.activeItem.isEdit) {
        this.editFolder();
      } else {
        this.createFolder();
      }
      this.resetActiveItem();
    },
    async fetchFolders() {
      this.isFetchingFolders = true;
      this.folders = [];
      try {
        const resp = await axios.get(endpoints.mediaFolder, { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.folders = resp.data;
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch image folders',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        this.isFetchingFolders = false;
      }
    },
    async createFolder() {
      try {
        const { assignYourself, botPermissions, groupPermissions } = this.$refs.folderPermissions;
        await axios.post(endpoints.mediaFolder,
          {
            name: this.activeItem.name,
            parent: this.activeItem.parent,
            permissions: {
              assign_user: assignYourself,
              groups: groupPermissions,
              bots: botPermissions,
            },
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.fetchFolders();
      } catch (error) {
        this.showWarning({
          title: 'Failed to add image folder',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      }
    },
    async editFolder() {
      try {
        await axios.put(`${endpoints.mediaFolder + this.activeItem.id}/`,
          {
            ...this.activeItem,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.fetchFolders();
      } catch (error) {
        this.showWarning({
          title: 'Failed to update image folder',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      }
    },
    async deleteFolder() {
      try {
        await axios.delete(`${endpoints.mediaFolder + this.activeItem.id}/`,
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.fetchFolders();
      } catch (error) {
        this.showWarning({
          title: 'Failed to delete image folder',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        this.resetActiveItem();
      }
    },
    async fetchImages() {
      this.isFetchingImages = true;
      this.openedFolderId = 'default';
      this.images = [];
      try {
        const resp = await axios.get(endpoints.image, { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.images = resp.data;
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch images',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        this.isFetchingImages = false;
      }
    },
    async uploadImage() {
      const formData = new FormData();
      formData.append('image_file', this.activeItem.image);
      formData.append('name', this.activeItem.name);
      if (this.activeItem.parent) {
        formData.append('parent', this.activeItem.parent);
      }
      try {
        await axios.post(
          endpoints.image,
          formData,
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.fetchImages();
      } catch (error) {
        const feedback = error?.response?.data?.error || error.message;
        this.showWarning({
          title: 'Failed to upload image',
          text: feedback,
          variant: 'danger',
        });
        throw error;
      }
    },
    async updateImage() {
      try {
        await axios.put(`${endpoints.image}?id=${this.activeItem.id}`,
          { changes: { name: this.activeItem.name, parent: this.activeItem.parent } },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.fetchImages();
      } catch (error) {
        this.showWarning({
          title: 'Failed to update image folder',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        this.resetActiveItem();
      }
    },
    async deleteImage(item) {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete the image?', {
        title: 'Delete image',
        okTitle: 'Delete',
        okVariant: 'danger',
      })) {
        try {
          await axios.delete(endpoints.image, {
            params: { id: item.id },
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
          this.fetchImages();
        } catch (error) {
          this.showWarning({
            title: 'Failed to delete image',
            text: error.message,
            variant: 'danger',
          });
          throw error;
        }
      }
    },
    resetActiveItem() {
      this.$bvModal.hide('folder-modal');
      this.$bvModal.hide('image-modal');
      this.activeItem = {};
      this.$v.$reset();
    },
  },
  validations() {
    return {
      activeItem: {
        name: {
          required,
        },
      },
    };
  },
};
</script>
<style>
/* this is needed to make the button same height as the upload input */
.upload-btn{
  padding-top: 5px;
  padding-bottom: 4px;
}
.img-input{
  z-index: 1 !important;
}
</style>
<style scoped>
::v-deep .actions{
    width: 140px;
}</style>
