const botPages = [
  {
    display: 'Build',
    section: true,
    pages: [
      {
        display: 'Dashboard',
        linkName: 'dashboard',
        icon: 'home',
        tooltip: 'Bot dashboard',
        hideInSubflow: true,
        limited: true,
      },
      {
        display: 'Conversation Flow',
        linkName: 'flow',
        icon: 'project-diagram',
        matchOn: ['flow', 'edit-node'],
        tooltip: 'Edit flow and nodes',
        limited: true,
      },
      {
        display: 'Q & A',
        linkName: 'Q&A',
        icon: 'stream',
        tooltip: 'One-line questions that the bot can answer at any given time in flow',
        hideInSubflow: true,
        limited: true,
        filterKey: 'show_qa_page',
      },
      {
        display: 'Responses',
        linkName: 'responses',
        icon: 'comment',
        tooltip: 'Search responses',
        limited: true,
      },
      {
        display: 'Configuration',
        linkName: 'config',
        icon: 'cog',
        tooltip: 'General settings and global nodes',
        limited: true,
      },
      {
        display: 'Advanced',
        tooltip: 'Advanced bot features',
        icon: 'cubes',
        matchOn: ['global-nodes', 'integrations', 'edit-variant', 'classifiers',
          'entities', 'subflows', 'variants', 'edit-variant', 'phrases'],
        children: [
          {
            display: 'Global Nodes',
            linkName: 'global-nodes',
            icon: 'globe-europe',
            tooltip: 'Nodes that match throughout the conversation',
            hideInSubflow: true,
            limited: true,
          },
          {
            display: 'Integrations',
            linkName: 'integrations',
            icon: 'cloud',
            tooltip: 'Edit integrations used by this bot',
            limited: true,
          },
          {
            display: 'Classifiers',
            linkName: 'classifiers',
            icon: 'atom',
            tooltip: 'Classifiers used for node matching and more.',
            limited: true,
          },
          {
            display: 'Entities',
            linkName: 'entities',
            icon: 'highlighter',
            tooltip: 'Use entity extractors with this bot (e.g. email recognizer)',
            limited: true,
          },
          {
            display: 'Subflows',
            linkName: 'subflows',
            icon: 'bezier-curve',
            tooltip: 'Add or edit subflows that can be used by the bot',
            limited: true,
            hideInSubflow: true,
          },
          {
            display: 'Variables',
            linkName: 'variables',
            icon: 'code',
            tooltip: 'Search variables',
            limited: true,
            hideInSubflow: true,
          },
          {
            display: 'Variants',
            linkName: 'variants',
            icon: 'language',
            tooltip: 'Configure variants (e.g. languages) of the bot',
            limited: true,
            hideInSubflow: true,
            filterKey: 'show_variants_page',
            matchOn: ['edit-variant'],
          },
          {
            display: 'Phrases',
            linkName: 'phrases',
            icon: 'bars-staggered',
            tooltip: 'Manage bot phrases',
            limited: true,
            hideInSubflow: true,
          },
        ],
      },
    ],
  },
  {
    display: 'Analyze & Debug',
    section: true,
    pages: [
      {
        display: 'Conversation Logs',
        linkName: 'conversation-logs',
        matchOn: ['conversation-logs', 'conversation-log-single'],
        icon: 'comments',
        limited: true,
        hideInSubflow: true,
        tooltip: 'Inspect conversations',
      },
      {
        display: 'Statistics',
        linkName: 'statistics',
        icon: 'chart-column',
        limited: true,
        hideInSubflow: true,
        tooltip: 'View statistics and KPIs for the bot',
      },
      {
        display: 'Insights',
        linkName: 'insights',
        icon: 'microscope',
        tooltip: 'Get insights into user-journeys',
        hideInSubflow: true,
        limited: true,
      },
      {
        display: 'Training',
        linkName: 'training',
        icon: 'graduation-cap',
        limited: false,
        hideInSubflow: true,
        tooltip: 'Evaluate the predictions of the bot to help it improve.',
      },
      {
        display: 'Health',
        linkName: 'health',
        matchOn: ['health', 'conversation-tests'],
        icon: 'heartbeat',
        limited: true,
        hideInSubflow: true,
        tooltip: 'Inspect bot health and diagnose issues',
      },
    ],
  },
  {
    display: 'Deploy',
    section: true,
    pages: [
      {
        display: 'Bot Staging',
        linkName: 'staging',
        icon: 'layer-group',
        limited: true,
        hideInSubflow: true,
        tooltip: 'Stage different versions of bots for deployment',
      },
    ],
  },
];

const nluPages = [
  {
    display: 'NLU',
    section: true,
    pages: [
      {
        display: 'Configuration',
        linkName: 'nlu-model-config',
        icon: 'cog',
        tooltip: 'Configuration of the classifier',
        limited: false,
        hideWhenUploaded: false,
      },
      {
        display: 'Labels',
        linkName: 'nlu-labels-overview',
        matchOn: ['nlu-labels-overview', 'nlu-label-single-overview', 'nlu-label-source'],
        icon: 'tags',
        tooltip: 'The labels and the data sources associated to the model',
        limited: false,
        hideWhenUploaded: true,
      },
      {
        display: 'Versions',
        linkName: 'nlu-versions-overview',
        matchOn: ['nlu-versions-overview', 'nlu-version-details'],
        icon: 'history',
        tooltip: 'Versions of the classifier',
        limited: false,
        hideWhenUploaded: false,
      }],
  },
];

const dataExplorationPages = [
  {
    display: 'Data Exploration',
    section: true,
    pages: [
      {
        display: 'Data Sources',
        linkName: 'dataExplorationDataSource',
        icon: 'database',
        tooltip: 'Get an overview and modify the data sources in this dataset',
        limited: false,
      },
      {
        display: 'Data Points',
        linkName: 'dataExplorationData',
        icon: 'layer-group',
        tooltip: 'Review datapoints in dataset',
        limited: false,
      },
      {
        display: 'Topic Exploration',
        linkName: 'dataExplorationTopicExplorationEntry',
        matchOn: ['dataExplorationTopicExplorationEntry', 'dataExplorationTopicExploration'],
        icon: 'vial',
        tooltip: 'Explore different topics',
        limited: false,
      },
    ],
  },
  {
    display: 'Data Labeling',
    section: true,
    pages: [
      {
        display: 'Label',
        linkName: 'dataExplorationLabelingEntry',
        matchOn: ['dataExplorationLabeling'],
        icon: 'highlighter',
        tooltip: 'Label different examples',
        limited: false,
      },
      {
        display: 'Log',
        linkName: 'dataExplorationLabelingLog',
        icon: 'list',
        tooltip: 'View labels and perform relabeling',
        limited: false,
      },
      {
        display: 'Statistics',
        linkName: 'dataExplorationStats',
        icon: 'chart-column',
        tooltip: 'See different statistics',
        limited: false,
      },
    ],
  },
];

const adminPages = [
  {
    display: 'Administration',
    section: true,
    pages: [
      {
        display: 'Users',
        linkName: 'admin-users-overview',
        matchOn: ['admin-users-overview', 'admin-users-single'],
        icon: 'user',
        tooltip: 'Manage users',
      },
      {
        display: 'Bots',
        linkName: 'admin-bots',
        matchOn: ['admin-bots-overview', 'admin-bots-single'],
        icon: 'robot',
        tooltip: 'Manage bot permissions',
      },
      {
        display: 'Classifiers',
        linkName: 'admin-classifiers',
        matchOn: ['admin-classifiers-overview', 'admin-classifiers-single'],
        icon: 'atom',
        tooltip: 'Manage classifiers permissions',
      },
      {
        display: 'Datasets',
        linkName: 'admin-datasets',
        matchOn: ['admin-datasets-overview', 'admin-datasets-single'],
        icon: 'tags',
        tooltip: 'Manage dataset permissions',
      }],
  },
];

const sidebarState = {
  sidebarItems: [],
  showSidebar: false,
  pageLoading: false,
};

const sidebarGetters = {
  pages: (state) => state.sidebarItems,
  loggedIn: (state, getters, rootState) => rootState.auth.loggedIn,
};

const mutations = {
  setShowSidebar(state, payload) {
    state.showSidebar = payload;
  },
  setSidebarItems(state, to) {
    if (to === null) {
      state.sidebarItems = [];
    } else if (to.fullPath.startsWith('/bot')) {
      state.sidebarItems = botPages;
    } else if (to.fullPath.startsWith('/nlu')) {
      state.sidebarItems = nluPages;
    } else if (to.fullPath.startsWith('/data-exploration')) {
      state.sidebarItems = dataExplorationPages;
    } else if (to.fullPath.startsWith('/administration')) {
      state.sidebarItems = adminPages;
    }
  },
  setLoading(state, payload) {
    state.pageLoading = payload;
  },
};

const actions = {
  showWarning({ dispatch }, { title, text, variant }) {
    dispatch('templateStore/templateSendNotification', {
      title,
      text,
      variant,
      toast: true,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state: sidebarState,
  getters: sidebarGetters,
  mutations,
  actions,
};
