<template>
  <main role="main">
    <b-list-group>
      <b-list-group-item
        v-if="loading"
        variant="info"
        class="r-25 health-alert"
      >
        <b-row class="mx-0 py-2 h-100">
          <b-col class="my-auto">
            <h4 class="my-auto">
              Running diagnostics...
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item
        v-if="errorComputingLocalDiagnostics"
        variant="danger"
        class="r-25"
      >
        <b-row
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Recompute diagnostics"
              variant="link"
              @click="calculateAllDiagnostics"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="pl-0">
            <h4 class="my-1">
              Diagnostics failed to compute!
            </h4>
            <p>
              If this problem persists please report this error to your BotStudio administrator
            </p>
          </b-col>
          <b-col
            cols="*"
            class="my-auto text-right"
          >
            <span
              v-b-tooltip.hover
              title="You should look into this"
            >
              <font-awesome-icon

                :icon="['far', 'times-circle']"
                size="lg"
                class="mr-1"
              />
            </span>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item
        v-else-if="backendComputedDiagnosticsFailed"
        variant="danger"
      >
        <b-row
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Recompute diagnostics"
              variant="link"
              @click="calculateAllDiagnostics"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="pl-0">
            <h4 class="my-1">
              Diagnostics failed to compute!
            </h4>
            <p
              v-if="hasClassifierError"
              class="error-content"
            >
              An associated classifier could not be used in bot. <br>
              Did you recently upload this bot from another environment (i.e. PROD or TEST)?
              <br>
              In that case you must replace the classifier with one present in this environment.
              <br>
              Go to the
              <router-link
                :to="{ name: 'classifiers' }"
              >
                Classifiers page
              </router-link>,
              and verify that associated classifiers have expected sizes.
            </p>
          </b-col>
          <b-col
            cols="*"
            class="my-auto text-right"
          >
            <span
              v-b-tooltip.hover
              title="You should look into this"
            >
              <font-awesome-icon
                :icon="['far', 'times-circle']"
                class="mr-1"
                size="lg"
              />
            </span>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-if="diagnosticsReady"
        :variant="numWarnings ? 'danger' : 'success'"
      >
        <b-row
          v-if="!numWarnings"
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Recompute diagnostics"
              variant="link"
              @click="calculateAllDiagnostics"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="py-2">
            <h4 class="my-1">
              No errors detected.
            </h4>
          </b-col>
        </b-row>
        <b-row
          v-else
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Recompute diagnostics"
              variant="link"
              @click="calculateAllDiagnostics"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col>
            <h4>
              Errors!
            </h4>
            <p class="error-content">
              While checking the bot, {{ numWarnings }}
              {{ numWarnings === 1 ? 'error was' : 'errors were' }} found. See a summary below.
            </p>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <div
      v-if="diagnosticsReady"
      class="accordion"
      :class="numWarnings ? 'mt-3' : ''"
      role="tablist"
    >
      <error-wrapper
        v-for="(ids, name) in nameErrors"
        :key="name"
        :name="`Duplicate name '${name}'`"
        @click.native="setDiagnosticsData('nameErrors', `Duplicate name '${name}'`, { ids, name })"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              {{ activeInfo.ids.length }} nodes use the same node name "{{ activeInfo.name }}"
              <br>
              Change the node name(s), such that all nodes have unique names. <br>
            </p>
            <b-list-group>
              <b-list-group-item
                v-for="id in activeInfo.ids"
                :key="id"
                button
                @click="e => goToNode(id, null, e)"
              >
                Edit node {{ nameOfId(id) }}
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="{ nodeId, nodeName } in authConflictWarnings"
        :key="`${nodeId}authConflictWarning`"
        name="Authentication settings conflict"
        @click.native="setDiagnosticsData('authConflictWarnings',
                                          'Authentication settings conflict',
                                          { nodeId, nodeName })"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              Authentication is required in "{{ activeInfo.nodeName }}", but disallowed
              globally in bot.
              <ul>
                <li>
                  If you <em>do</em> need authentication in the node, you should re-enable
                  authentication globally in bot
                </li>
                <li>
                  If you don't need authentication in the node, disable it in node.
                </li>
              </ul>
            </p>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="{ nodeId, nodeName } in authFallbackWarnings"
        :key="`${nodeId}authFallbackWarning`"
        name="Auth fallback missing"
        @click.native="setDiagnosticsData('authFallbackWarnings', 'Auth fallback missing',
                                          { nodeId, nodeName })"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              Authentication is required in "{{ activeInfo.nodeName }}", but no fallback
              node is specified
            </p>
            <b-button
              block
              variant="primary"
              @click="e => goToNodeEditAnchor(activeInfo.nodeId, 'advanced', e)"
            >
              Edit {{ activeInfo.nodeName }}
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="(error, i) in makeBotErrors"
        :key="`${i}MakeBotError`"
        :name="`${error.nodeId ? 'Node' : 'Bot'} creation error
          ${error.subflowId ? ' (subflow)' : ''}`"
        @click.native="setDiagnosticsData('makeBotErrors', `${error.nodeId ? 'Node' : 'Bot'
        } creation error${error.subflowId ? ' (subflow)' : ''}`, error)"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              {{ activeInfo.msg }}
            </p>
            <b-button
              v-if="activeInfo.nodeId"
              block
              variant="primary"
              @click="e => goToNode(activeInfo.nodeId, activeInfo.subflowId, e)"
            >
              Edit node: {{ nameOfId(activeInfo.nodeId, activeInfo.subflowId) }}
            </b-button>
            <b-button
              v-else-if="findNodeId(activeInfo.msg)"
              block
              variant="primary"
              @click="e => goToNode(findNodeId(activeInfo.msg), null, e)"
            >
              Edit node: {{ nameOfId(findNodeId(activeInfo.msg)) }}
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="(error, i) in customFallbackSelectedNoNodeSuppliedErrors"
        :key="`${i}missingFallbackNodeSupplied`"
        name="No custom fallback node supplied"
        @click.native="setDiagnosticsData('customFallbackSelectedNoNodeSuppliedErrors',
                                          'No custom fallback node supplied', error)"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              The node is configured as a smartnode and configured to use a custom fallback node.
              <br> However, no custom fallback node has been configured. You should either
              <ul>
                <li>disable the smartnode from using a custom fallback node, or</li>
                <li>supply the intended custom fallback node</li>
              </ul>
            </p>
            <b-button
              v-if="activeInfo.nodeId"
              block
              variant="primary"
              @click="goToNodeOptions(activeInfo.nodeId)"
            >
              Edit node options
            </b-button>
            <b-button
              v-else-if="findNodeId(activeInfo.msg)"
              block
              variant="primary"
              @click="e => goToNode(findNodeId(activeInfo.msg), null, e)"
            >
              Edit node: {{ nameOfId(findNodeId(activeInfo.msg)) }}
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-if="actionsHaveWarningsError"
        name="Integrations have warnings"
        @click.native="setDiagnosticsData('actionsHaveWarningsError',
                                          'Integrations have warnings', {})"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              The bot contains integrations that have been changed without the according
              activities have been checked and/or updated.
              <br>Please refer to the integrations page, where a list of all such warnings can be
              displayed.
            </p>
            <b-button
              block
              variant="primary"
              @click="goToActionWarnings"
            >
              Go to integrations page
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-if="yesNoModelError"
        name="Smart node setup error"
        @click.native="setDiagnosticsData('yesNoModelError', 'Smart node setup error')"
      >
        <template
          #collapsecontent
        >
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              The labels of the confirmation classifier in the smart node config has not
              been set.
            </p>
            <b-button
              block
              variant="primary"
              @click="e => goToConfig('smart-nodes', e)"
            >
              Go to configuration
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="(error, i) in syntaxErrors"
        :key="`${i}SyntaxError`"
        name="Syntax error"
        @click.native="setDiagnosticsData('syntaxErrors', 'Syntax error', error)"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <div v-if="activeInfo.syntaxErrorType === 'node' && activeInfo.subflowId !== null">
              <p>
                The following syntax error exists in the SubFlow:
                {{ getSubFlowName(activeInfo.subflowId) }}
              </p>
              <p><code>{{ activeInfo.error }}</code></p>
              <p>Inside the following expression:</p>
              <p class="mb-3">
                <code>{{ activeInfo.code || activeInfo.string }}</code>
              </p>
              <b-link :to="editNodeLink(activeInfo.nodeId, activeInfo.subflowId)">
                <b-button
                  block
                  variant="primary"
                >
                  Edit the node
                </b-button>
              </b-link>
            </div>
            <div v-else-if="activeInfo.syntaxErrorType === 'node'">
              <p>
                The following syntax error exists in
                <b-link :to="linkToNode(activeInfo.nodeId)">
                  {{ nameOfId(activeInfo.nodeId) }}
                </b-link>:
              </p>
              <p><code>{{ activeInfo.error }}</code></p>
              <p>Inside the following expression:</p>
              <p class="mb-3">
                <code>{{ activeInfo.code || activeInfo.string }}</code>
              </p>
              <b-link :to="linkToNode(activeInfo.nodeId)">
                <b-button
                  block
                  variant="primary"
                >
                  Edit {{ nameOfId(activeInfo.nodeId) }}
                </b-button>
              </b-link>
            </div>
            <div v-else-if="activeInfo.syntaxErrorType === 'action'">
              <p>
                The following syntax error exists in the integration {{ activeInfo.actionName }}
                <template v-if="activeInfo.subflowId">
                  in the SubFlow {{ getSubFlowName(activeInfo.subflowId) }}
                </template>
              </p>
              <p><code>{{ activeInfo.error }}</code></p>
              <p>Inside the following expression:</p>
              <p class="mb-3">
                <code>{{ activeInfo.code || activeInfo.string }}</code>
              </p>
              <b-link :to="linkToActionModal(activeInfo.subflowId, activeInfo.actionName)">
                <b-button
                  block
                  variant="primary"
                >
                  Go to integrations page
                </b-button>
              </b-link>
            </div>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="(error, i) in contentErrors"
        :key="`${i}ContentError`"
        name="Content error"
        @click.native="setDiagnosticsData('contentErrors', 'Content error', error)"
      >
        <template
          #collapsecontent
        >
          <b-card-body v-if="activeInfo">
            <div v-if="activeInfo.contentErrorType === 'node' && activeInfo.subflowId !== null">
              <p>
                The following content error exists in the SubFlow:
                {{ getSubFlowName(activeInfo.subflowId) }}
              </p>
              <p class="mb-3">
                <code>{{ activeInfo.error }}</code>
              </p>
              <b-link :to="editNodeLink(activeInfo.nodeId, activeInfo.subflowId)">
                <b-button
                  block
                  variant="primary"
                >
                  Edit the node
                </b-button>
              </b-link>
            </div>
            <div v-else-if="activeInfo.contentErrorType === 'node'">
              <p>
                The following content error exists in
                <b-link :to="linkToNode(activeInfo.nodeId)">
                  {{ nameOfId(activeInfo.nodeId) }}
                </b-link>:
              </p>
              <p class="mb-3">
                <code>{{ activeInfo.error }}</code>
              </p>
              <b-link :to="linkToNode(activeInfo.nodeId)">
                <b-button
                  block
                  variant="primary"
                >
                  Edit {{ nameOfId(activeInfo.nodeId) }}
                </b-button>
              </b-link>
            </div>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="(error, i) in internalErrors"
        :key="`${i}InternalErrors`"
        name="Internal error"
        @click.native="setDiagnosticsData('internalErrors', 'Internal error', error)"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              The following internal error exists in
              <b-link :to="linkToNode(activeInfo.nodeId)">
                {{ nameOfId(activeInfo.nodeId) }}
              </b-link>:
            </p>
            <p><code>{{ activeInfo.error }}</code></p>
          </b-card-body>
        </template>
      </error-wrapper>

      <error-wrapper
        v-for="(warning, i) in unsupportedChatActionWarnings"
        :key="`${i}unsupportedChatActionWarnings`"
        name="Unsupported platform action"
        @click.native="setDiagnosticsData('unsupportedChatActionWarnings',
                                          'Unsupported platform action', warning)"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              {{ activeInfo.msg }}
            </p>
            <b-button
              block
              variant="primary"
              @click="e => goToNode(activeInfo.nodeId, null, e)"
            >
              Edit node: {{ activeInfo.nodeName }}
            </b-button>
            <b-button
              block
              variant="primary"
              :to="{ name: 'config', hash: 'general' }"
            >
              Configure platforms
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
      <error-wrapper
        v-for="(warning, i) in compoundResponseErrors"
        :key="`${i}compoundResponseErrors`"
        name="Rich reponse error"
        @click.native="setDiagnosticsData('compoundResponseError',
                                          'Rich response error', warning)"
      >
        <template #collapsecontent>
          <b-card-body v-if="activeInfo">
            <p class="mb-3">
              {{ activeInfo.msg }}
            </p>

            <b-button
              block
              variant="primary"
              @click="e => goToNode(activeInfo.nodeId, null, e)"
            >
              Edit node: {{ activeInfo.nodeName }}
            </b-button>
          </b-card-body>
        </template>
      </error-wrapper>
    </div>
  </main>
</template>

<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import {
  AUTH_SETTING_CONFLICT_WARNING,
  NODE_SAME_NAME,
  AUTH_MISSING_FALLBACK,
  MAKE_BOT_FAILURE,
  SYNTAX_ERROR,
  CONTENT_ERROR,
  NO_CUSTOMFALLBACK_SPECIFIED_WARNING,
  ACTIONS_HAVE_WARNINGS,
  YESNO_MODEL_WARNING,
  UNSUPPORTED_CHAT_ACTION,
  COMPOUND_RESPONSE_ERROR,
  TaskStatus,
  BackendDiagnosticsErrors,
} from '@/js/constants';
import { UUID_REGEX } from '@/js/utils';
import { isProduction } from '@/js/featureFlags';
import ErrorWrapper from '@/pages/Health/Diagnostics/ErrorWrapper.vue';

export default {
  name: 'Diagnostics',
  components: {
    ErrorWrapper,
  },
  data() {
    return {
      activeName: '',
      activeTitle: '',
      activeInfo: null,
    };
  },
  computed: {
    ...mapState('botManipulation/activeBot', {
      nodes: 'nodes',
      activeBotId: 'id',
    }),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nameOfId',
    ]),
    ...mapGetters('botManipulation/activeBot/config/auth', [
      'authenticationSubflowIsEnabled',
    ]),
    ...mapGetters('diagnostics', [
      'errorComputingLocalDiagnostics',
      'loadedLocalComputedDiagnostics',
      'loadingLocalComputedDiagnostics',
      'getWarningsOfType',
      'getWarnings',
      'getInternalErrors',
      'diagnosticsTaskResult',
      'backendDiagnosticsErrorMessage',
    ]),
    ...mapGetters('botManipulation', ['getSubFlows']),
    getSubFlowName() {
      return (subflowId) => this.getSubFlows.filter((x) => x.id === subflowId)[0].config.name;
    },
    loading() {
      return this.loadingLocalComputedDiagnostics || this.backendIsComputingDiagnostics;
    },
    backendIsComputingDiagnostics() {
      return this.diagnosticsTaskResult !== null
      && this.diagnosticsTaskResult.status === TaskStatus.PENDING;
    },
    backendComputedDiagnosticsReady() {
      return this.diagnosticsTaskResult !== null
      && this.diagnosticsTaskResult.status === TaskStatus.TASK_COMPLETED;
    },
    backendComputedDiagnosticsFailed() {
      return this.diagnosticsTaskResult !== null
      && this.diagnosticsTaskResult.status === TaskStatus.TASK_FAILED;
    },
    diagnosticsReady() {
      return this.loadedLocalComputedDiagnostics && this.backendComputedDiagnosticsReady
      && !this.errorComputingLocalDiagnostics && !this.backendComputedDiagnosticsFailed;
    },
    hasClassifierError() {
      return this.backendDiagnosticsErrorMessage === BackendDiagnosticsErrors.INVALID_CLASSIFIER;
    },
    nameErrors() {
      // Produce desired shape of data from store
      const sameNameWarnings = this.getWarningsOfType(NODE_SAME_NAME);
      const output = {}; // Dictionary: name -> list of node-ids sharing name
      sameNameWarnings.forEach((element) => {
        if (output[element.sharedName] === undefined) {
          output[element.sharedName] = [];
        }
        output[element.sharedName].push(element.nodeId);
      });
      return output;
    },
    authConflictWarnings() {
      return this.getWarningsOfType(AUTH_SETTING_CONFLICT_WARNING).map(({ nodeId }) => ({
        nodeId,
        nodeName: this.nameOfId(nodeId),
      }));
    },
    authFallbackWarnings() {
      return this.getWarningsOfType(AUTH_MISSING_FALLBACK).map(({ nodeId }) => ({
        nodeId,
        nodeName: this.nameOfId(nodeId),
      }));
    },
    customFallbackSelectedNoNodeSuppliedErrors() {
      return this.getWarningsOfType(NO_CUSTOMFALLBACK_SPECIFIED_WARNING).map(({ nodeId }) => ({
        nodeId,
        nodeName: this.nameOfId(nodeId),
      }));
    },
    actionsHaveWarningsError() {
      return this.getWarningsOfType(ACTIONS_HAVE_WARNINGS).length > 0;
    },
    yesNoModelError() {
      return this.getWarningsOfType(YESNO_MODEL_WARNING).length > 0;
    },
    makeBotErrors() {
      return this.getWarningsOfType(MAKE_BOT_FAILURE);
    },
    syntaxErrors() {
      return this.getWarningsOfType(SYNTAX_ERROR);
    },
    contentErrors() {
      return this.getWarningsOfType(CONTENT_ERROR);
    },
    internalErrors() {
      return this.getInternalErrors;
    },
    unsupportedChatActionWarnings() {
      return this.getWarningsOfType(UNSUPPORTED_CHAT_ACTION).map(({ nodeId, activityId, msg }) => ({
        nodeId,
        activityId,
        nodeName: this.nameOfId(nodeId),
        msg,
      }));
    },
    compoundResponseErrors() {
      return this.getWarningsOfType(COMPOUND_RESPONSE_ERROR).map(({ nodeId, activityId, msg }) => ({
        nodeId,
        activityId,
        nodeName: this.nameOfId(nodeId),
        msg,
      }));
    },
    numWarnings() {
      return this.getWarnings.length;
    },
  },
  mounted() {
    if (!this.errorComputingLocalDiagnostics && !this.loadedLocalComputedDiagnostics) {
      this.calculateAllDiagnostics();
    }
  },
  methods: {
    ...mapMutations('botManipulation/activeBot', [
      'deleteActivity',
    ]),
    ...mapMutations('diagnostics', [
      'clearAllWarnings',
      'setErrorComputingLocalDiagnostics',
      'setLoadedLocalComputedDiagnostics',
      'setLoadingLocalComputedDiagnostics',
    ]),
    ...mapActions('diagnostics', [
      'calculateBadActivityState',
      'calculateCustomFallbackSelectedNoNodeSupplied',
      'calculateSameNameWarnings',
      'calculateAuthWarnings',
      'calculateBackendDiagnostics',
      'calculateActionsWithWarnings',
      'checkYesNoModelLabels',
      'fetchBackendDiagnosticsTaskStatus',
      'checkForUnsupportedChatActions',
      'validateCompoundResponses',
    ]),
    setDiagnosticsData(name, title, info) {
      this.activeName = name;
      this.activeTitle = title;
      this.activeInfo = info;
    },
    async calculateAllDiagnostics() {
      this.computeLocalDiagnostics();
      await this.calculateBackendDiagnostics();
      await this.pollForBackendDiagnosticsTask([0.2, 0.8, 1.6, 2.0].reverse());
    },
    computeLocalDiagnostics() {
      try {
        this.setErrorComputingLocalDiagnostics(false);
        this.setLoadedLocalComputedDiagnostics(false);
        this.setLoadingLocalComputedDiagnostics(true);
        this.clearAllWarnings();

        this.calculateCustomFallbackSelectedNoNodeSupplied();
        this.calculateSameNameWarnings();
        this.calculateAuthWarnings();
        this.calculateActionsWithWarnings();
        this.checkYesNoModelLabels();
        this.checkForUnsupportedChatActions();
        this.validateCompoundResponses();
        if (!isProduction()) {
          // Following are faults that user cannot do anything about,
          // and is more indication of intern faults somewhere.
          this.calculateBadActivityState();
        }
        this.setLoadedLocalComputedDiagnostics(true);
        this.setLoadingLocalComputedDiagnostics(false);
      } catch {
        this.setErrorComputingLocalDiagnostics(true);
        this.setLoadedLocalComputedDiagnostics(false);
        this.setLoadingLocalComputedDiagnostics(false);
      }
    },
    async pollForBackendDiagnosticsTask(thresholds) {
      const result = await this.fetchBackendDiagnosticsTaskStatus();
      if (result === TaskStatus.PENDING) {
        // Keep polling
        const self = this;
        const timeout = thresholds.length === 1 ? thresholds[0] : thresholds.pop();
        setTimeout(self.pollForBackendDiagnosticsTask, timeout * 1000, thresholds);
      }
    },
    linkToNode(id) {
      if (id === 'final') {
        return { name: 'config', hash: '#final-activities' };
      }
      return this.editNodeLink(id);
    },
    goToActionWarnings(event) {
      const botId = this.$route.params.botId;
      this.openCtrlLink({ name: 'integrations', params: { botId }, hash: '#showWarnings' }, event);
    },
    linkToActionModal(subflowId, actionName) {
      let botId;
      if (subflowId) {
        botId = `${this.$route.params.botId}-${subflowId}`;
      } else {
        botId = this.$route.params.botId;
      }
      return { name: 'integrations', params: { botId }, hash: `#${actionName}` };
    },
    goToNode(nodeId, subflowId, event) {
      if (subflowId) {
        const params = { botId: `${this.activeBotId}-${subflowId}`, nodeId };
        this.openCtrlLink({ name: 'edit-node', params }, event);
      } else {
        this.openCtrlLink(this.linkToNode(nodeId), event);
      }
    },
    goToNodeEditAnchor(nodeId, anchorString, event) {
      const routePayload = this.linkToNode(nodeId);
      if (anchorString !== '') {
        routePayload.hash = anchorString;
      }
      this.openCtrlLink(routePayload, event);
    },
    goToConfig(configSection, event) {
      const routePayload = {
        name: 'config',
      };
      if (configSection !== '') {
        routePayload.hash = configSection;
      }
      this.openCtrlLink(routePayload, event);
    },
    goToNodeOptions(nodeId) {
      const routePayload = this.linkToNode(nodeId);
      routePayload.hash = 'options';
      this.$router.push(routePayload);
    },
    findNodeId(msg) {
      if (msg) {
        const nodeIdRegex = new RegExp(`node (${UUID_REGEX.source})`, 'i');
        const found = msg.match(nodeIdRegex);
        if (found) {
          return found[1];
        }
      }
      return null;
    },
  },
};
</script>
<style scoped>
.error-content{
    font-size: 0.9375rem;
    font-weight: 400;
}
</style>
