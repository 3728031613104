<template>
  <div>
    <p>
      {{ badLinks.length }} bad link(s) found in the bot:
    </p>
    <excludable-value-list
      metric-identifier="LINK_CHECKING"
      :ids="badLinks"
    />
  </div>
</template>
<script>
import ExcludableValueList from '@/pages/Health/Results/ExcludableIdsList.vue';

export default {
  name: 'LinkChecking',
  components: { ExcludableValueList },
  props: {
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badLinks() {
      return Object.entries(this.insightDetails).filter((e) => !e[1]).map((e) => e[0]);
    },
  },
};
</script>
