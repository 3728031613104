<template>
  <b-dropdown
    block
    :text="valueText"
    :disabled="disabled"
    class="tree-selector"
  >
    <b-dropdown-form style="min-width: 200px; max-width: 400px;">
      <ul class="fa-ul">
        <TreeSelectorElement
          v-for="(subtree, index) in tree"
          :key="`${index}:${subtree.value}`"
          :element="subtree"
          :value="value"
          @input="(value) => $emit('input', value)"
        />
      </ul>
    </b-dropdown-form>
  </b-dropdown>
</template>
<script>
import TreeSelectorElement from '@/components/TreeSelectorElement.vue';

function flattenTree(tree, key) {
  const elements = [];
  for (const element of tree) {
    const elementCopy = { ...element };
    let subElements = [];
    if (key in elementCopy) {
      subElements = flattenTree(element[key], key);
      delete elementCopy[key];
    }
    elements.push(elementCopy, ...subElements);
  }
  return elements;
}

export default {
  name: 'TreeSelector',
  components: {
    TreeSelectorElement,
  },
  props: {
    tree: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, Array, String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    flatValues() {
      const flat = flattenTree(this.tree, 'children');
      return Object.assign({}, ...flat.map((e) => ({ [e.value]: e })));
    },
    valueText() {
      if (!(this.value in this.flatValues)) {
        return this.placeholder;
      }
      return this.flatValues[this.value].text;
    },
  },
};
</script>

<style scoped>
.fa-ul, .fa-li {
  --fa-li-margin: 1em;
}

.fa-ul >>> li > span.fa-li:hover, .fa-ul >>> .selectable-element:hover {
  font-weight: bold;
  cursor: pointer;
  background-color: var(--light);
}

.fa-ul >>> .li-content:hover {
  font-weight: bold;
  cursor: pointer;
  background-color: var(--light);
  border-radius: 0.25em;
}

.fa-ul >>> li.selected > .li-content {
  font-weight: bold;
  background-color: var(--light);
  border-radius: 0.25em;
}
</style>
