<template>
  <b-modal
    id="createUpdateSecretModal"
    :title="modalTitle"
    :ok-title="okTitle"
    size="lg"
    :ok-disabled="!okActionIsOKToInvoke"
    @ok="createOrUpdateSecret"
    @show="prepModalContent"
  >
    <b-form>
      <b-form-group
        label="Name"
        label-for="secretName"
      >
        <b-form-input
          id="secretName"
          v-model="modalContent.secretName"
          autocomplete="off"
          placeholder="Display name for secret (can be changed)"
          type="text"
          :state="$v.modalContent.secretName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretName.required">
            You must provide a name for the secret.
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretName.uniqueSecretName">
            The name is already in use for a secret for this bot.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Secret"
        label-for="secretValueField"
      >
        <b-form-input
          id="secretValueField"
          v-model="modalContent.secretValue"
          type="password"
          autocomplete="off"
          :placeholder="secretInputFieldPlaceholder"
          :state="$v.modalContent.secretValue.$invalid ? false : null"
          @input="() => userUpdatedSecretValue = true"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretValue.required">
            You need to provide a secret
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>

    <template
      v-if="mode === 'update'"
    >
      <b-button
        variant="outline"
        @click="() => ui.revealSecretCollapseIsOpen = !ui.revealSecretCollapseIsOpen"
      >
        <font-awesome-icon
          v-if="ui.revealSecretCollapseIsOpen"
          icon="angle-up"
        />
        <font-awesome-icon
          v-else
          icon="angle-right"
        />
        Inspect current secret
      </b-button>
      <b-collapse
        v-model="ui.revealSecretCollapseIsOpen"
      >
        <code>
          {{ secretReveal.firstCharacter }}*****{{ secretReveal.lastCharacter }}
        </code>
      </b-collapse>
    </template>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'SimpleSecretModal',
  mixins: [validationMixin],
  props: {
    // Mode is either: 'update' or 'create' and denotes whether to create a new secret or update an
    // existing secret. If mode is 'update', prop secretToUpdate must also be set.
    mode: {
      type: String,
      required: false,
      default: 'create',
    },
    secretToUpdate: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      ui: {
        revealSecretCollapseIsOpen: false,
      },
      modalContent: {
        secretName: null,
        secretValue: null,
      },
      secretReveal: {
        firstCharacter: null,
        lastCharacter: null,
      },
      // Keep hold of the the initial name. Should only be set when modal is opened / prepared.
      secretNameUponShow: null,
      userUpdatedSecretValue: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    okActionIsOKToInvoke() {
      if (this.mode === 'update') {
        // Verify that we're actually about to update anything
        if (this.$v.modalContent.$invalid) {
          // The validations take into account if user has updated the secret value, so something
          // in modalContent _is not_ valid
          return false;
        }
        if (this.userUpdatedSecretValue
            || this.modalContent.secretName !== this.secretNameUponShow) {
          // User _did_ update the secret name
          return true;
        }
        // User did neither update secret value nor update the secret name -> do not allow user to
        // fire this update-request.
        return false;
      }
      return !this.$v.modalContent.$invalid;
    },
    modalTitle() {
      if (this.mode === 'update') {
        return `Update secret: ${this.secretToUpdate.name}`;
      } if (this.mode === 'create') {
        return 'Create secret';
      }
      return '';
    },
    secretInputFieldPlaceholder() {
      if (this.mode === 'update' && !this.userUpdatedSecretValue) {
        return 'New secret value (leave as is to keep the secret value unchanged)';
      }
      if (this.mode === 'update') {
        return 'Supply new secret value';
      }
      return 'Secret value';
    },
    okTitle() {
      if (this.mode === 'update') {
        return 'Update';
      }
      if (this.mode === 'create') {
        return 'Create';
      }
      return '';
    },
  },
  async mounted() {
    await this.fetchSecrets();
  },
  methods: {
    ...mapActions('botSecrets', [
      'fetchSecrets',
      'createSimpleSecret',
      'updateSimpleSecret',
      'fetchPartiallyRevealedSecret',
    ]),
    async createOrUpdateSecret() {
      if (this.mode === 'update') {
        await this.updateSimpleSecret({
          botId: this.getBotId,
          secretId: this.secretToUpdate.id,
          newSecretName: this.modalContent.secretName,
          newSecretValue: this.modalContent.secretValue,
        });
      } else if (this.mode === 'create') {
        await this.createSimpleSecret({
          botId: this.getBotId,
          secretName: this.modalContent.secretName,
          secretValue: this.modalContent.secretValue,
        });
      }
    },
    async prepModalContent() {
      await this.fetchSecrets();
      this.userUpdatedSecretValue = false;

      if (this.mode === 'update') {
        this.modalContent.secretName = this.secretToUpdate.name;
        this.modalContent.secretValue = null;
        this.secretNameUponShow = this.secretToUpdate.name;

        // Fetch info for partial reveal
        const response = await this.fetchPartiallyRevealedSecret({
          botId: this.getBotId,
          secretId: this.secretToUpdate.id,
        });

        this.secretReveal.firstCharacter = response.data.first_character;
        this.secretReveal.lastCharacter = response.data.last_character;
      } else {
        this.modalContent.secretName = null;
        this.modalContent.secretValue = null;

        this.secretReveal.firstCharacter = null;
        this.secretReveal.lastCharacter = null;
      }
    },
  },
  validations: {
    modalContent: {
      secretName: {
        required,
        uniqueSecretName(suggestedValue) {
          const botSecrets = this.getBotSecrets(this.activeBotId);
          if (botSecrets === undefined) {
            return true;
          }
          if (suggestedValue === this.secretNameUponShow) {
            // Name hasn't changed from its original value
            return true;
          }
          const secretNames = botSecrets.map((x) => x.name);
          return !secretNames.includes(suggestedValue);
        },
      },
      secretValue: {
        required(newValue) {
          if (this.mode === 'update' && !this.userUpdatedSecretValue) {
            // User is updating an existing secret but she/he has not changed the secret value
            // (yet), so we're still in a valid state.
            return true;
          }
          return required(newValue);
        },
      },
    },
  },
};
</script>
