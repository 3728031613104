<template>
  <div class="d-flex flex-column align-items-center my-3">
    <span class="meta">{{ eventName }}</span>
    <div
      :class="`bubble p-2 errorMessage ${bgClass}`"
      @dblclick="toggleDetails"
    >
      {{ errorText }}
      <pre v-if="showDetails">{{ details }}</pre>
    </div>
    <span
      class="meta"
    >{{ dateFormatter(error.timestamp) }}</span>
  </div>
</template>

<script>
export default {
  name: 'ChatError',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
      sPressed: false, // whether the key 'S' is pressed
      tPressed: false, // whether the key 'T' is pressed
    };
  },
  computed: {
    details() {
      const details = this.error.data?.traceback;
      if (details === undefined) {
        return `Activity ID: ${this.error.data.activity}`;
      }
      return details.join('');
    },
    bgClass() {
      return this.error.type === 'error' ? 'bg-danger' : 'bg-warning';
    },
    eventName() {
      return this.error.type === 'error' ? 'Error' : 'Soft (fallback) error';
    },
    errorText() {
      return this.error.data?.error || this.error.data.reason;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyDownListener);
    window.addEventListener('keyup', this.keyUpListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownListener);
    window.removeEventListener('keyup', this.keyUpListener);
  },
  methods: {
    dateFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleTimeString();
    },
    toggleDetails() {
      this.showDetails = this.sPressed && this.tPressed && !this.showDetails;
    },
    keyDownListener(e) {
      if (e.code === 'KeyS') {
        this.sPressed = true;
      }
      if (e.code === 'KeyT') {
        this.tPressed = true;
      }
    },
    keyUpListener(e) {
      if (e.code === 'KeyS') {
        this.sPressed = false;
      }
      if (e.code === 'KeyT') {
        this.tPressed = false;
      }
    },
  },
};
</script>

<style scoped>
.errorMessage {
  border-radius: 5px;
  color: white;
}
</style>
