<template>
  <b-card
    no-body
    class="border"
  >
    <b-card-header
      header-tag="header"
      class="cursor-pointer p-2 health-header"
      role="tab"
      @click="visible = !visible"
    >
      <b-row
        role="tablist"
        align-h="between"
        class="mx-0"
      >
        <b-col
          cols="*"
        >
          <h6 class="my-2">
            <font-awesome-icon
              class="fa-lg mx-2"
              icon="exclamation-circle"
            />
            {{ name }}
          </h6>
        </b-col>
        <b-col
          cols="*"
          class="text-right my-auto pr-1"
        >
          <span
            v-b-tooltip.hover
            title="You should look into this"
          >
            <font-awesome-icon
              :icon="['far', 'times-circle']"
              color="red"
              size="lg"
            />
          </span>
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse
      v-model="visible"
      accordion="my-accordion"
      role="tabpanel"
    >
      <slot name="collapsecontent" />
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  name: 'ErrorWrapper',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {},
};
</script>
