<template>
  <b-card
    class="r-75"
    body-class="p-3"
  >
    <b-list-group
      horizontal
      class="w-100 text-center mb-3"
      style="font-size:1rem;"
    >
      <b-list-group-item
        button
        class="py-0 px-1"
        active-class="active-tab"
        :active="chosenSection === '#improvements'"
        @click="chooseSection('improvements')"
      >
        <div class="d-block">
          <h6>
            Improvements
          </h6>
          <h2
            :class="improvementsCount === 0 ? 'text-success' : 'text-info'"
            class="mb-0 mt-1 font-weight-bold"
          >
            {{ improvementsCount }}
          </h2>
        </div>
        <b-overlay
          :show="loadingHealthSuggestions"
          no-wrap
        />
      </b-list-group-item>
      <b-list-group-item
        v-if="!isUserObserver"
        button
        :active="chosenSection === '#errors'"
        class="py-0 px-1"
        @click="chooseSection('errors')"
      >
        <div class="d-block">
          <h6>
            Errors
          </h6>
          <h2
            class="mb-0 mt-1 font-weight-bold"
            :class="getWarnings.length === 0
              && !backendComputedDiagnosticsFailed ? 'text-success' : 'text-danger'"
          >
            {{ getWarnings.length }}
          </h2>
        </div>
        <b-overlay
          :show="loadingDiagnostics"
          no-wrap
        />
      </b-list-group-item>
      <b-list-group-item
        button
        :active="chosenSection === '#metrics'"
        class="py-0 px-1"
        @click="chooseSection('metrics')"
      >
        <div class="d-block">
          <h6>
            Hygiene checks
          </h6>
          <h2
            class="mb-0 mt-1 font-weight-bold"
            :class="inProgressFailedOrNotSatisfiedCards(getBotId).length ? 'text-warning' : 'text-green'"
          >
            {{ inProgressFailedOrNotSatisfiedCards(getBotId).length }}
          </h2>
        </div>
        <b-overlay
          :show="metricsInProgress(getBotId) !== 0"
          no-wrap
        />
      </b-list-group-item>
      <b-list-group-item
        v-if="!isUserObserver"
        button
        class="p-1"
        :active="chosenSection === '#tests'"
        @click="chooseSection('tests')"
      >
        <div class="d-block">
          <h6 style="line-height: 1;">
            Conversation Tests
          </h6>
          <h2
            class="mb-0 mt-1 font-weight-bold"
            :class="totalFailedTests !== 0 ? 'text-danger' : 'text-success'"
          >
            {{ totalFailedTests ? totalFailedTests : filteredTests.length }}
          </h2>
        </div>
        <b-overlay
          :show="areTestsRunning && totalFinishedTests !== totalRunningTests"
          no-wrap
        />
      </b-list-group-item>
      <b-list-group-item
        v-if="!isUserObserver"
        button
        class="p-1"
        :active="chosenSection === '#AIEvaluation'"
        @click="chooseSection('AIEvaluation')"
      >
        <div class="d-block">
          <h6 style="line-height: 1;">
            GenAI Evaluation
          </h6>
          <h2
            class="mb-0 mt-1 font-weight-bold"
            :class="totalFailedAiTests !== 0 ? 'text-danger' : 'text-success'"
          >
            {{ totalFailedAiTests ? totalFailedAiTests : filteredAiTests.length }}
          </h2>
        </div>
        <b-overlay
          :show="areAiTestsRunning && totalFinishedAiTests !== totalRunningAiTests"
          no-wrap
        />
      </b-list-group-item>
      <b-list-group-item
        button
        class="p-0"
        style="width:40px;"
      >
        <b-list-group
          flushed
          class="no-border h-100"
        >
          <b-list-group-item
            v-b-tooltip.hover.noninteractive.viewport.bottom
            class="btn-icon"
            button
            title="Recompute all"
            @click="recomputeAll()"
          >
            <font-awesome-icon
              icon="redo"
            />
          </b-list-group-item>
          <b-list-group-item
            v-if="healthTaskConfigLoaded(getBotId)"
            v-b-modal.settings-modal
            v-b-tooltip.hover.noninteractive.viewport.bottom
            title="Health settings"
            button
            class="btn-icon"
          >
            <font-awesome-icon
              icon="cog"
            />
          </b-list-group-item>
          <b-list-group-item
            v-b-toggle.health-description-sidebar
            v-b-tooltip.hover.noninteractive.viewport.bottom
            title="Health description"
            button
            class="btn-icon"
          >
            <font-awesome-icon
              icon="info-circle"
            />
          </b-list-group-item>
        </b-list-group>
      </b-list-group-item>
    </b-list-group>
    <div v-show="chosenSection === '#improvements'">
      <health-suggestions ref="healthSuggestions" />
    </div>
    <div
      v-if="!isUserObserver"
      v-show="chosenSection === '#errors'"
    >
      <diagnostics ref="diagnostics" />
    </div>
    <bot-hygiene v-show="chosenSection === '#metrics'" />
    <AITests v-show="chosenSection === '#AIEvaluation'" />
    <div
      v-if="!isUserObserver"
      v-show="chosenSection === '#tests'"
    >
      <conversation-tests />
    </div>

    <health-settings-modal
      v-if="healthTaskConfigLoaded(getBotId)"
      @configForMetricUpdated="fetchHealthConfig"
    />

    <sidebar-description
      id="health-description-sidebar"
      title="Health description"
    >
      <template #content>
        <health-description />
      </template>
    </sidebar-description>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import SidebarDescription from 'supwiz/components/SidebarDescription.vue';
import { TaskStatus } from '@/js/constants';
import HealthSettingsModal from '@/pages/Health/Configurations/HealthSettingsModal.vue';
import Diagnostics from '@/pages//Health/Diagnostics/Diagnostics.vue';
import ConversationTests from '@/pages/Health/ConversationTests/ConversationTests.vue';
import HealthSuggestions from '@/pages/Health/HealthSuggestions.vue';
import HealthDescription from '@/components/descriptions/HealthDescription.vue';
import BotHygiene from '@/components/BotHygiene.vue';
import AITests from '@/pages/Health//AiTests/AITests.vue';

export default {
  name: 'HealthPage',
  components: {
    BotHygiene,
    HealthSettingsModal,
    Diagnostics,
    HealthSuggestions,
    ConversationTests,
    SidebarDescription,
    HealthDescription,
    AITests,
  },
  data() {
    return {
      latestVisualizationId: null,
      nodeWithChatsId: null,
      areChatsComputed: false,
      chosenSection: null,
      selectedOrigins: [],
      flowPotentialsLoading: false,
      simpleNodeFallbackLoading: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
      'activeBotId',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'allNormalNodes',
    ]),
    ...mapGetters('auth', ['isUserObserver']),
    ...mapState('health', ['showCompleted']),
    ...mapGetters('health', {
      healthTaskConfigLoaded: 'getHealthTaskConfigLoaded',
      healthTaskConfig: 'getHealthConfigForBot',
      metricIsCurrentlyDisabled: 'metricIsCurrentlyDisabled',
      taskProgress: 'getTaskProgress',
      getMetricResult: 'getMetricResult',
      metricsInProgress: 'metricsInProgress',
      inProgressFailedOrNotSatisfiedCards: 'inProgressFailedOrNotSatisfiedCards',
    }),
    ...mapGetters('healthSuggestions', [
      'isFetchingFlowPotentials',
      'isFetchingSimpleNodeFallback',
      'getFlowPotentials',
      'getSimpleNodeFallbacks',
    ]),
    ...mapGetters('unitTest', [
      // unit tests
      'getUnitTests',
      'totalRunningTests',
      'getRunningUnitTests',
      'getRunningUnitTestStatus',
      'totalRunningTests',
      'totalFinishedTests',
      'totalFailedTests',
      'areTestsRunning',
      // ai tests
      'getAiTests',
      'totalRunningAiTests',
      'getRunningAiTests',
      'getRunningAiTestStatus',
      'totalRunningAiTests',
      'totalFinishedAiTests',
      'totalFailedAiTests',
      'areAiTestsRunning',
    ]),
    ...mapGetters('dataExploration/visualizations', [
      'getVisualization',
    ]),
    ...mapGetters('chatlogs', [
      'availableDataOrigins',
    ]),
    ...mapState('dataExploration/visualizations', [
      'visualizationDatas',
    ]),
    ...mapGetters('diagnostics', [
      'getWarnings',
      'loadingLocalComputedDiagnostics',
      'diagnosticsTaskResult',
      'backendIsComputingDiagnostics',
    ]),
    filteredTests() {
      return this.getUnitTests.filter((e) => e.enabled);
    },
    filteredAiTests() {
      return this.getAiTests.filter((e) => e.enabled);
    },
    routeHash() {
      return this.$route.hash;
    },
    loadingDiagnostics() {
      return this.loadingLocalComputedDiagnostics || this.backendIsComputingDiagnostics;
    },
    loadingHealthSuggestions() {
      return this.isFetchingFlowPotentials
      || this.isFetchingSimpleNodeFallback;
    },
    improvementsCount() {
      return this.getFlowPotentials(5).length + this.getSimpleNodeFallbacks(5).length;
    },
    isLinkCheckingDone() {
      return this.linksChecked === this.totalNumberOfLinks;
    },
    backendComputedDiagnosticsFailed() {
      return this.diagnosticsTaskResult !== null
      && this.diagnosticsTaskResult.status === TaskStatus.TASK_FAILED;
    },
    getNumberOfEnabledTests() {
      if (this.getRunningUnitTests) {
        const enabledTest = this.getUnitTests.filter((test) => test.enabled);
        return enabledTest.length;
      }
      return 0;
    },
    getNumberOfEnabledAiTests() {
      if (this.getRunningAiTests) {
        const enabledTest = this.getAiTests.filter((test) => test.enabled);
        return enabledTest.length;
      }
      return 0;
    },
  },
  watch: {
    routeHash(n) {
      if (n) {
        this.chosenSection = n;
      } else {
        this.$router.replace({ hash: this.chosenSection });
      }
    },
  },
  async mounted() {
    if (!this.routeHash) {
      this.$router.replace({ hash: '#improvements' });
    } else {
      this.chosenSection = this.routeHash;
    }
    if (!this.isUserObserver) {
      if (this.getUnitTests.length && !this.getRunningUnitTestStatus) {
        this.runUnitTests();
      }
      if (this.getNumberOfEnabledTests !== this.totalRunningTests && this.getUnitTests.length) {
        this.runUnitTests();
      }
    }
    await this.fetchHealthConfig();
    this.recomputeAllMetrics();
  },
  methods: {
    ...mapActions('health', [
      'fetchHealthConfig',
      'fetchHealthSuggestions',
      'recomputeAllMetrics',
    ]),
    ...mapActions('nodeInterpretations', ['fetchNodeInterpretations']),
    ...mapActions('healthSuggestions', ['fetchHealthSuggestions']),
    chooseSection(hash) {
      if (`#${hash}` !== this.routeHash) {
        this.$router.replace({ hash });
      }
    },
    cardSortFunction(a, b) {
      return a.displayOrder - b.displayOrder;
    },
    recomputeAll() {
      if (!this.isUserObserver) {
        if (this.getUnitTests.length) {
          this.runUnitTests();
        }
        if (this.getAiTests.length) {
          this.runAiTests();
        }
        this.$refs.diagnostics.calculateAllDiagnostics();
      }
      this.recomputeAllMetrics();
      this.fetchHealthSuggestions();
    },
    async runUnitTests() {
      this.$store.dispatch('unitTest/clearRunningTests');
      await this.$store.dispatch('unitTest/runUnitTests');
      this.$store.dispatch('unitTest/updateUnitTestStatus');
    },
    async runAiTests() {
      this.$store.dispatch('unitTest/clearRunningAiTests');
      await this.$store.dispatch('unitTest/runAiTests');
      this.$store.dispatch('unitTest/updateAiTestStatus');
    },
  },
};
</script>
<style scoped>
.btn-icon{
  border:0px !important;
  height:36px;
  padding-top:8px;
  padding-bottom: auto;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid #DFDFDF !important
}
.btn-icon:last-of-type{
  border-bottom: 0px solid #DFDFDF !important
}
.active{
  background-color: #EFEFEF !important;
  color: #49505C;
}

</style>
