<template>
  <b-form-input
    v-model="comment"
    placeholder="Type node-comments here (visible only for bot-builders)"
    size="sm"
    :style="{ border: 'none' }"
  />
</template>

<script>
export default {
  name: 'NodeEditComments',
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    comment: {
      get() {
        return this.$store.getters['botManipulation/activeBot/nodeById'](this.nodeId).comment;
      },
      set(value) {
        this.$store.dispatch(
          'botManipulation/activeBot/updateActiveNode',
          { id: this.nodeId, value, attribute: 'comment' },
        );
      },
    },
  },
};
</script>
