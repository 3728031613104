import Vue from 'vue';

const nerGetters = {
  getNERs(state, getters, rootState) {
    return rootState.botManipulation.activeBotSet ? rootState.botManipulation.activeBot.ners : null;
  },
};

const nerMutations = {
  addNewNER(state, payload) {
    state.ners.push(payload.data);
  },
  editNER(state, payload) {
    Vue.set(state.ners, payload.index, payload.data);
  },
  swapNER(state, {
    idx1, idx2, ner1, ner2,
  }) {
    Vue.set(state.ners, idx1, ner2);
    Vue.set(state.ners, idx2, ner1);
  },
  deleteNER(state, payload) {
    state.ners.splice(payload.index, 1);
  },
};

const nerActions = {
  addNewNER({ rootState, commit }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      commit('addNewNER', payload);
    }
  },
  editNER({ rootState, commit }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      commit('editNER', payload);
    }
  },
  swapNER({ rootState, commit }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      const idx1 = payload.i;
      const idx2 = payload.j;
      const ner1 = rootState.botManipulation.activeBot.ners[idx1];
      const ner2 = rootState.botManipulation.activeBot.ners[idx2];
      commit('swapNER', {
        idx1, idx2, ner1, ner2,
      });
    }
  },
  deleteNER({ rootState, commit }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      commit('deleteNER', payload);
    }
  },
};

export { nerGetters, nerMutations, nerActions };
