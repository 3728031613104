import { render, staticRenderFns } from "./VoicebotAudio.vue?vue&type=template&id=27c117e1&scoped=true"
import script from "./VoicebotAudio.vue?vue&type=script&lang=js"
export * from "./VoicebotAudio.vue?vue&type=script&lang=js"
import style0 from "./VoicebotAudio.vue?vue&type=style&index=0&id=27c117e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c117e1",
  null
  
)

export default component.exports