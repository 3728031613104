import Cookies from 'js-cookie';
import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import isEqual from 'lodash';

import HealthPage from '@/pages/Health/HealthPage.vue';

import store from '@/store/index';
import { userPermission } from '@/js/constants';
import endpoints from '@/js/urls';
import { getBreadcrumb } from '@/js/breadcrumbs';
import HomePage from '@/pages/HomePage.vue';
import Dashboard from '@/pages/Dashboard/Dashboard.vue';
import TreeViewPage from '@/pages/TreeView/TreeViewPage.vue';
import GraphViewPage from '@/pages/GraphView/GraphViewPage.vue';
import EditNode from '@/pages/EditNode/EditNodePage.vue';
import BotConfigPage from '@/pages/BotConfig/BotConfigPage.vue';
import BotDiffPage from '@/pages/BotDiffPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import LogoutPage from '@/pages/LogoutPage.vue';
import APIActionsPage from '@/pages/APIActions/APIActionsPage.vue';
import EntityPage from '@/pages/EntityPage.vue';
import EditNLUs from '@/pages/EditNLUs.vue';
import SubflowPage from '@/pages/SubflowPage.vue';
import StagingPage from '@/pages/StagingPage.vue';
import TrainingPage from '@/pages/Training/TrainingPage.vue';
import StatisticsPage from '@/pages/Statistics/StatisticsPage.vue';
import QAPage from '@/pages/QA/QAPage.vue';
import ResponsesPage from '@/pages/Responses/ResponsesPage.vue';
import ChatlogsPage from '@/pages/ChatLogs/ChatlogsPage.vue';
import ChatlogDetailsPage from '@/pages/ChatLogs/ChatlogDetailsPage.vue';
import DataExploration from '@/components/layout/DataExploration.vue';
import DataExplorationOverview from '@/pages/DataExploration/DataExplorationOverview.vue';
import DataExplorationDatasetPage from '@/pages/DataExploration/DataExplorationDatasetPage.vue';
import DataExplorationLabelingPage from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationLabelingPage.vue';
import DataExplorationLabelingEntryPage from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationLabelingEntryPage.vue';
import DataExplorationStatisticsPage from '@/pages/DataExploration/DataExplorationStatisticsPage.vue';
import DataExplorationDataPage from '@/pages/DataExploration/DataExplorationDataPage.vue';
import TopicExplorationPage from '@/pages/DataExploration/TopicExploration/TopicExplorationPage.vue';
import VisualizationBody from '@/pages/DataExploration/TopicExploration/VisualizationBody.vue';
import DataExplorationDataSourcePage from '@/pages/DataExploration/DataExplorationDataSourcePage.vue';
import DataExplorationLabelingLog from '@/pages/DataExploration/DataExplorationLabelingLog.vue';
import GlobalNodesPage from '@/pages/GlobalNodes.vue';
import PlainNoSidebar from '@/components/layout/PlainNoSidebar.vue';
import Bots from '@/components/layout/Bots.vue';
import BotsOverview from '@/pages/BotsOverview/BotsOverview.vue';
import BotHistory from '@/pages/BotHistory.vue';
import SingleBot from '@/components/layout/SingleBot.vue';
import PageNotFoundPage from '@/pages/PageNotFoundPage.vue';
import ScriptHelpPage from '@/pages/ScriptHelp/ScriptHelpPage.vue';
import NotAllowedToViewPage from '@/pages/NotAllowedToViewPage.vue';
import NLU from '@/components/layout/NLU.vue';
import NLUModelPage from '@/pages/NLU/NLUModelPage.vue';
import NLUModelConfig from '@/pages/NLU/NLUModelConfig.vue';
import NLUModelLabelsBase from '@/pages/NLU/NLUModelLabelsBase.vue';
import NLUModelLabelsOverview from '@/pages/NLU/Labels/NLUModelLabelsOverview.vue';
import NLUModelLabelSingle from '@/pages/NLU/Labels/NLUModelLabelSingle.vue';
import NLUModelLabelSingleOverview from '@/pages/NLU/Labels/NLUModelLabelSingleOverview.vue';
import NLUModelLabelSource from '@/pages/NLU/Labels/NLUModelLabelSource.vue';
import NLUModelsOverview from '@/pages/NLU/NLUModelsOverview.vue';
import NLUModelVersionsBase from '@/pages/NLU/NLUModelVersionsBase.vue';
import NLUModelVersionsOverview from '@/pages/NLU/Versions/NLUModelVersionsOverview.vue';
import NLUModelVersionDetails from '@/pages/NLU/Versions/NLUModelVersionDetails.vue';
import Administration from '@/components/layout/Administration.vue';
import AdminUsersGroups from '@/pages/Admin/AdminUsersGroups.vue';
import AdminUsersGroupsOverview from '@/pages/Admin/AdminUsersGroupsOverview.vue';
import AdminUsersGroupsSingle from '@/pages/Admin/AdminUsersGroupsSingle.vue';
import SingleSignOnWrapper from '@/pages/Admin/SingleSignOnWrapper.vue';
import InsightsPage from '@/pages/Insights/InsightsPage.vue';
import VariablesPage from '@/pages/Variables/VariablesPage.vue';
import VariantsPage from '@/pages/Variants/VariantsPage.vue';
import VariantsEditPage from '@/pages/Variants/VariantsEditPage.vue';
import PhrasesPage from '@/pages/Phrases/PhrasesPage.vue';
import ImagesPage from '@/pages/Images/ImagesPage.vue';
// import ChatlogSimplePage from '@/pages/ChatLogs/ChatlogSimplePage.vue';

Vue.use(VueRouter);

export function userIsAllowedToViewRoute(route) {
  const loggedUserPermission = store.state.auth.userPermission;
  // All matched routes must satisfy any of the following:
  // 1) Have no requiredRolesForEntry defined
  // 2) The requiredRolesForEntry are satisfied by the user's permission
  const matchedRoutes = route.matched !== undefined ? route.matched : [route];
  return matchedRoutes.every((matchedRoute) => {
    if (matchedRoute.meta === undefined || matchedRoute.meta.requiredRolesForEntry === undefined) {
      return true;
    }
    const requiredRolesForEntry = matchedRoute.meta.requiredRolesForEntry;
    return requiredRolesForEntry.includes(loggedUserPermission);
  });
}

async function checkAuth(to, from, next) {
  try {
    const authStatus = await store.dispatch('authentication');
    if (authStatus === 'success') {
      if (!userIsAllowedToViewRoute(to)) {
        next({ name: 'not-allowed-to-view', query: { requestedPath: to.path } });
      } else {
        next();
      }
    } else if (authStatus === 'no token') {
      next({ name: 'login', query: { originalPath: to.path } });
    } else {
      console.log(`unexpected status ${authStatus}`);
      next({ name: 'login', query: { originalPath: to.path } });
    }
  } catch (error) {
    console.log(error);
    next({ name: 'login', query: { originalPath: to.path } });
  }
}

async function chatIdToBotId(to, from, next) {
  const chatId = to.params.chatId;
  const config = {
    headers: { Authorization: `JWT ${store.state.auth.jwt}` },
    params: {
      chat_id: chatId,
    },
  };
  try {
    const response = await axios.get(endpoints.chatlogsBotId, config);
    const botId = response.data.bot_id;
    next({ name: 'conversation-log-single', params: { botId, chatId } });
  } catch {
    // Couldn't find the conversation log, so just redirect to home.
    next({ name: 'home' });
  }
}

const routes = [
  {
    path: '/',
    component: PlainNoSidebar,
    beforeEnter: checkAuth,
    meta: {
      breadcrumb: getBreadcrumb,
    },
    children: [
      {
        path: '',
        component: HomePage,
        name: 'home',
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
      { path: 'not-allowed-to-view', component: NotAllowedToViewPage, name: 'not-allowed-to-view' },
      {
        path: 'conversation-logs/:chatId',
        beforeEnter: chatIdToBotId,
        component: HomePage,
        name: 'conversation-log-single-no-bot-id',
      },
      {
        path: 'conversation-logs/simple/:chatId',
        beforeEnter: checkAuth,
        component: ChatlogDetailsPage,
        name: 'conversation-log-single-simple',
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
            userPermission.LIMITED,
            userPermission.TRANSCRIPT,
          ],
          breadcrumb: { display: 'Conversation log', link: { name: 'conversation-log-single-simple' } },
        },
      },
      {
        path: 'conversation-logs/simple/external/:externalId',
        beforeEnter: checkAuth,
        component: ChatlogDetailsPage,
        name: 'conversation-log-single-simple-external',
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
            userPermission.LIMITED,
            userPermission.TRANSCRIPT,
          ],
          breadcrumb: { display: 'Conversation log', link: { name: 'conversation-log-single-simple-external' } },
        },
      },
      {
        path: 'scripthelp',
        beforeEnter: checkAuth,
        name: 'scripthelp',
        component: ScriptHelpPage,
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
          ],
          breadcrumb: { display: 'Script Help', link: { name: 'scripthelp' } },
        },
      },
      {
        path: 'manage-images',
        beforeEnter: checkAuth,
        name: 'manage-images',
        component: ImagesPage,
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
          ],
          breadcrumb: { display: 'Manage Images', link: { name: 'manage-images' } },
        },
      },
    ],
  },

  {
    path: '/',
    component: PlainNoSidebar,
    meta: {
      breadcrumb: getBreadcrumb,
    },
    children: [
      {
        path: 'login',
        component: LoginPage,
        name: 'login',
        meta: {
          breadcrumb: { display: ' ', link: null },
        },
      },
      {
        path: 'login-supwiz',
        name: 'login-supwiz',
        beforeEnter: () => window.location.replace(endpoints.ssoLoginSupwiz),
      },
      {
        path: 'logout',
        beforeEnter: checkAuth,
        component: LogoutPage,
        name: 'logout',
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
            userPermission.LIMITED,
            userPermission.OBSERVER,
            userPermission.TRANSCRIPT,
            userPermission.NO_PERMISSIONS,
          ],
          breadcrumb: { display: ' ', link: null },
        },
      },
    ],
  },

  {
    path: '/bot',
    component: Bots,
    beforeEnter: checkAuth,
    meta: {
      requiredRolesForEntry: [
        userPermission.SUPERUSER,
        userPermission.NORMAL,
        userPermission.LIMITED,
        userPermission.OBSERVER,
      ],
      breadcrumb: { display: 'Bots', link: { name: 'bot' } },
    },
    children: [
      {
        path: '',
        name: 'bot',
        component: BotsOverview,
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
            userPermission.LIMITED,
            userPermission.OBSERVER,
          ],
          breadcrumb: getBreadcrumb,
        },
      },
      {
        // Paths can either be on the form <uuid> or <uuid>-<subflow-num>.
        path: ':botId([0-9a-f-]{36}|[0-9a-f-]{36}-\\d+)',
        component: SingleBot,
        name: 'single-bot',
        redirect: (r) => {
          const loggedUserPermission = store.state.auth.userPermission;
          if (r.params.botId.match('[0-9a-f-]{36}-\\d+')
              || loggedUserPermission === userPermission.LIMITED) {
            return { name: 'flow' };
          }
          return { name: 'dashboard' };
        },
        meta: {
          breadcrumb: getBreadcrumb,
        },
        children: [
          {
            path: 'dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.OBSERVER,
              ],
              breadcrumb: {
                display: 'Dashboard', link: { name: 'dashboard' },
              },
            },
          },
          {
            path: 'flow',
            name: 'flow',
            component: TreeViewPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.LIMITED,
                userPermission.OBSERVER,
              ],
              breadcrumb: {
                display: 'Flow', link: { name: 'flow' },
              },
            },
          },
          {
            path: 'graph',
            name: 'graph',
            component: GraphViewPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.LIMITED,
                userPermission.OBSERVER,
              ],
              breadcrumb: {
                display: 'Graph', link: { name: 'graph' },
              },
            },
          },
          {
            path: 'edit-node/:nodeId',
            beforeEnter: checkAuth,
            name: 'edit-node',
            component: EditNode,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.LIMITED,
              ],
              breadcrumb: getBreadcrumb,
            },
          },
          {
            path: 'qa',
            name: 'Q&A',
            component: QAPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Q&A', link: { name: 'Q&A' },
              },
            },
          },
          {
            path: 'responses',
            name: 'responses',
            component: ResponsesPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Responses', link: { name: 'responses' },
              },
            },
          },
          {
            path: 'variables',
            name: 'variables',
            component: VariablesPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Variables', link: { name: 'variables' },
              },
            },
          },
          {
            path: 'config',
            name: 'config',
            component: BotConfigPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Configuration', link: { name: 'config' },
              },
            },
          },
          {
            path: 'global-nodes',
            name: 'global-nodes',
            component: GlobalNodesPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Global Nodes', link: { name: 'global-nodes' },
              },
            },
          },
          {
            path: 'integrations',
            name: 'integrations',
            component: APIActionsPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Integrations', link: { name: 'integrations' },
              },
            },
          },
          {
            path: 'classifiers',
            name: 'classifiers',
            component: EditNLUs,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Classifiers', link: { name: 'classifiers' },
              },
            },
          },
          {
            path: 'entities',
            name: 'entities',
            component: EntityPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Entities', link: { name: 'entities' },
              },
            },
          },
          {
            path: 'subflows',
            name: 'subflows',
            component: SubflowPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Subflows', link: { name: 'subflows' },
              },
            },
          },
          {
            path: 'variants',
            name: 'variants',
            component: VariantsPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Variants', link: { name: 'variants' },
              },
            },
          },
          {
            path: 'variants/:variantId',
            name: 'edit-variant',
            component: VariantsEditPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: getBreadcrumb,
            },
          },
          {
            path: 'history',
            name: 'history',
            component: BotHistory,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'History', link: { name: 'history' },
              },
            },
          },
          {
            path: 'conversation-logs',
            name: 'conversation-logs',
            component: ChatlogsPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.LIMITED,
                userPermission.OBSERVER,
              ],
              breadcrumb: {
                display: 'Conversation Logs', link: { name: 'conversation-logs' },
              },
            },
          },
          {
            path: 'conversation-logs/:chatId',
            name: 'conversation-log-single',
            component: ChatlogDetailsPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.LIMITED,
                userPermission.OBSERVER,
              ],
              breadcrumb: getBreadcrumb,
            },
          },
          {
            path: 'stats',
            name: 'statistics',
            component: StatisticsPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.OBSERVER,
              ],
              breadcrumb: {
                display: 'Statistics', link: { name: 'statistics' },
              },
            },
          },
          {
            path: 'insights',
            name: 'insights',
            component: InsightsPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Insights', link: { name: 'insights' },
              },
            },
          },
          {
            path: 'training',
            name: 'training',
            component: TrainingPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.LIMITED,
              ],
              breadcrumb: {
                display: 'Training', link: { name: 'training' },
              },
            },
          },
          {
            path: 'health',
            name: 'health',
            component: HealthPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
                userPermission.OBSERVER,
              ],
              breadcrumb: {
                display: 'Health', link: { name: 'health' },
              },
            },
          },
          {
            path: 'staging',
            name: 'staging',
            component: StagingPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Staging', link: { name: 'staging' },
              },
            },
          },
          {
            path: 'diff',
            name: 'diff',
            component: BotDiffPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Differences', link: { name: 'diff' },
              },
            },
          },
          {
            path: 'phrases',
            name: 'phrases',
            component: PhrasesPage,
            beforeEnter: checkAuth,
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
                userPermission.NORMAL,
              ],
              breadcrumb: {
                display: 'Phrases', link: { name: 'phrases' },
              },
            },
          },
        ],
      },
    ],
  },

  {
    path: '/nlu',
    component: NLU,
    beforeEnter: checkAuth,
    meta: {
      requiredRolesForEntry: [
        userPermission.SUPERUSER,
        userPermission.NORMAL,
        userPermission.LIMITED,
      ],
      breadcrumb: { display: 'NLU', link: { name: 'nlu' } },
    },
    children: [
      {
        path: '',
        component: NLUModelsOverview,
        name: 'nlu',
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
      {
        path: ':modelId',
        component: NLUModelPage,
        name: 'nlu-model-page',
        redirect: { name: 'nlu-model-config' },
        meta: { breadcrumb: getBreadcrumb },
        children: [
          {
            path: 'config',
            component: NLUModelConfig,
            name: 'nlu-model-config',
            meta: {
              breadcrumb: { display: 'Configuration', link: { name: 'nlu-model-config' } },
            },
          },
          {
            path: 'labels',
            component: NLUModelLabelsBase,
            meta: {
              breadcrumb: { display: 'Labels', link: { name: 'nlu-labels-overview' } },
            },
            children: [
              {
                path: '',
                component: NLUModelLabelsOverview,
                name: 'nlu-labels-overview',
                meta: {
                  breadcrumb: getBreadcrumb,
                },
              },
              {
                path: ':labelId',
                component: NLUModelLabelSingle,
                name: 'nlu-label-single',
                redirect: { name: 'nlu-label-single-overview' },
                meta: {
                  breadcrumb: getBreadcrumb,
                },
                children: [
                  {
                    path: '',
                    component: NLUModelLabelSingleOverview,
                    name: 'nlu-label-single-overview',
                    meta: { breadcrumb: getBreadcrumb },
                  },
                  {
                    path: ':sourceId',
                    component: NLUModelLabelSource,
                    name: 'nlu-label-source',
                    meta: {
                      breadcrumb: getBreadcrumb,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'versions',
            component: NLUModelVersionsBase,
            meta: {
              breadcrumb: { display: 'Versions', link: { name: 'nlu-versions-overview' } },
            },
            children: [
              {
                path: '',
                component: NLUModelVersionsOverview,
                name: 'nlu-versions-overview',
                meta: {
                  breadcrumb: getBreadcrumb,
                },
              },
              {
                path: ':versionId',
                component: NLUModelVersionDetails,
                name: 'nlu-version-details',
                meta: {
                  breadcrumb: getBreadcrumb,
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/data-exploration',
    component: DataExploration,
    beforeEnter: checkAuth,
    meta: {
      requiredRolesForEntry: [
        userPermission.SUPERUSER,
        userPermission.NORMAL,
        userPermission.LIMITED,
      ],
      breadcrumb: { display: 'Data Exploration', link: { name: 'dataExploration' } },
    },
    children: [
      {
        path: '',
        component: DataExplorationOverview,
        name: 'dataExploration',
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
            userPermission.NORMAL,
            userPermission.LIMITED,
          ],
          breadcrumb: getBreadcrumb,
        },
      },
      {
        path: ':datasetId',
        component: DataExplorationDatasetPage,
        name: 'dataExplorationDataset',
        meta: { breadcrumb: getBreadcrumb },
        redirect: { name: 'dataExplorationDataSource' },
        children: [
          {
            path: 'labeling',
            component: DataExplorationLabelingEntryPage,
            name: 'dataExplorationLabelingEntry',
            meta: {
              breadcrumb: { display: 'Labeling', link: { name: 'dataExplorationLabelingEntry' } },
            },
          },
          {
            path: 'labeling/:dataType(file|node)/:dataId(\\d+)',
            component: DataExplorationLabelingPage,
            name: 'dataExplorationLabeling',
            meta: { breadcrumb: getBreadcrumb },
          },
          {
            path: 'labeling-log',
            component: DataExplorationLabelingLog,
            name: 'dataExplorationLabelingLog',
            meta: {
              breadcrumb: { display: 'Labeling Log', link: { name: 'dataExplorationLabelingLog' } },
            },
          },
          {
            path: 'stats',
            component: DataExplorationStatisticsPage,
            name: 'dataExplorationStats',
            meta: {
              breadcrumb: { display: 'Statistics', link: { name: 'dataExplorationStats' } },
            },
          },
          {
            path: 'data',
            component: DataExplorationDataPage,
            name: 'dataExplorationData',
            meta: {
              breadcrumb: { display: 'Data Points', link: { name: 'dataExplorationData' } },
            },
          },
          {
            path: 'data-source',
            component: DataExplorationDataSourcePage,
            name: 'dataExplorationDataSource',
            meta: {
              breadcrumb: { display: 'Data Source', link: { name: 'dataExplorationDataSource' } },
            },
          },
          {
            path: 'topic-exploration/',
            component: TopicExplorationPage,
            name: 'dataExplorationTopicExplorationEntry',
            meta: {
              breadcrumb: { display: 'Topic Exploration', link: { name: 'dataExplorationTopicExplorationEntry' } },
            },
            children: [
              // We wish to enable deep-links to a specific visualization
              {
                path: ':visualizationId',
                component: VisualizationBody,
                name: 'dataExplorationTopicExploration',
                meta: { breadcrumb: getBreadcrumb },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/administration',
    name: 'administration',
    component: Administration,
    beforeEnter: checkAuth,
    redirect: { name: 'admin-users-groups-overview' },
    meta: {
      requiredRolesForEntry: [
        userPermission.SUPERUSER,
      ],
      breadcrumb: { display: 'Administration', link: { name: 'admin-users-groups-overview' } },
    },
    children: [
      {
        path: 'users-groups',
        component: AdminUsersGroups,
        name: 'admin-users-groups',
        redirect: { name: 'admin-users-groups-overview' },
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
          ],
          breadcrumb: { display: 'Users & Groups', link: { name: 'admin-users-groups-overview' } },
        },
        children: [
          {
            path: '',
            component: AdminUsersGroupsOverview,
            name: 'admin-users-groups-overview',
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
              ],
              breadcrumb: getBreadcrumb,
            },
          },
          {
            path: 'user/:userId',
            component: AdminUsersGroupsSingle,
            name: 'admin-users-single',
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
              ],
              breadcrumb: getBreadcrumb,
            },
          },
          {
            path: 'group/:groupId',
            component: AdminUsersGroupsSingle,
            name: 'admin-groups-single',
            meta: {
              requiredRolesForEntry: [
                userPermission.SUPERUSER,
              ],
              breadcrumb: getBreadcrumb,
            },
          },
        ],
      },
      {
        path: 'single-sign-on',
        component: SingleSignOnWrapper,
        name: 'admin-single-sign-on',
        meta: {
          requiredRolesForEntry: [
            userPermission.SUPERUSER,
          ],
          breadcrumb: { display: 'Single Sign On', link: { name: 'admin-single-sign-on' } },
        },
      },
    ],
  },

  {
    path: '/aad-auth',
    name: 'aad-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateAccessToken', accessToken);
          store.commit('auth/updateRefreshToken', refreshToken);
          next({ name: 'home' });
        } else {
          next({ name: 'login', query: { aad_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { aad_error: to.query.aad_error, originalPath } });
      }
    },
  },
  {
    path: '/sso-auth',
    name: 'sso-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateAccessToken', accessToken);
          store.commit('auth/updateRefreshToken', refreshToken);
          next({ name: 'home' });
        } else {
          next({ name: 'login', query: { aad_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { aad_error: to.query.aad_error, originalPath } });
      }
    },
  },

  // At the end we have a catch all. Since routes are matched in order this will only match if
  // no other route matches.
  {
    path: '/',
    component: PlainNoSidebar,
    children: [
      { path: '*', component: PageNotFoundPage, name: '404' },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!isEqual(to.params, from.params) || (to.params.botId === undefined
    && to.params.modelId === undefined && to.params.datasetId === undefined)) {
    store.commit('sidebar/setShowSidebar', false);
    store.commit('sidebar/setSidebarItems', null);
  } else {
    store.commit('sidebar/setSidebarItems', to);
    store.commit('sidebar/setShowSidebar', true);
  }
  await store.dispatch('auth/inspectToken', { shouldRefresh: true });
  next();
});

router.afterEach(async (to, from) => {
  if (!isEqual(to.params, from.params) && (to.params.botId !== undefined
    || to.params.modelId !== undefined || to.params.datasetId !== undefined)) {
    store.commit('sidebar/setSidebarItems', to);
    store.commit('sidebar/setShowSidebar', true);
  }
});

export default router;
