<template>
  <main>
    <b-card
      title="Natural Language Understanding"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        On this page you can train and manage the language understanding of your bots.
      </p>
      <p>
        Select or create a classifier below to get started.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      title="Classifiers"
    >
      <classifiers />
    </b-card>
  </main>
</template>

<script>

import Classifiers from '@/pages/NLU/ModelsOverview/Classifiers.vue';

export default {
  name: 'NLUModelsOverview',
  components: {
    Classifiers,
  },
};
</script>
