<template>
  <div>
    <b-input-group class="mt-1">
      <template #prepend>
        <b-button
          @click="deleteButton(buttonId)"
        >
          <font-awesome-icon icon="trash-alt" />
        </b-button>
      </template>
      <b-form-input
        v-model="text"
        required
        @input="$emit('update-button-text')"
      />
      <template #append>
        <b-form-select
          v-model="buttonType"
          :options="['Link', 'Reply']"
          class="input-group-append h-100"
        />
      </template>
    </b-input-group>

    <b-form-input
      v-if="buttonType === 'Link'"
      v-model="buttonLink"
      required
      :state="isLinkValid"
      class="mt-1 ml-auto button-link"
      placeholder="Button Link URL"
    />
    <b-form-invalid-feedback style="margin-left:31px;">
      Link must start with 'http://'' or 'https://'
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';

export default {
  name: 'CompoundResponseActivityButtonInput',
  props: {
    prefix: {
      type: String,
      default: 'Button',
    },
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    buttonId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        buttonIds: 'activityCompoundResponseButtonIds',
        buttons: 'activityCompoundResponseButtons',
      }),
      'nodeId',
      'activityId',
    ),
    text: {
      get() {
        return this.buttons[this.buttonId].text;
      },
      set(text) {
        this.setCompoundResponseButtonText({ buttonId: this.buttonId, text });
      },
    },
    buttonType: {
      get() {
        return this.buttons[this.buttonId].type;
      },
      set(type) {
        this.setCompoundResponseButtonType({ buttonId: this.buttonId, type });
      },
    },
    buttonLink: {
      get() {
        return this.buttons[this.buttonId].link;
      },
      set(link) {
        this.setCompoundResponseButtonLink({ buttonId: this.buttonId, link });
      },
    },
    isLinkValid() {
      return this.buttonLink.startsWith('http://') || this.buttonLink.startsWith('https://');
    },
  },
  methods: {
    ...addThisArgs(
      mapMutations('botManipulation/activeBot', {
        deleteCompoundResponseButton: 'deleteActivityCompoundResponseButton',
        setCompoundResponseButtonText: 'setActivityCompoundResponseButtonText',
        setCompoundResponseButtonLink: 'setActivityCompoundResponseButtonLink',
        setCompoundResponseButtonType: 'setActivityCompoundResponseButtonType',
      }),
      { nodeId: 'nodeId', activityId: 'activityId' },
    ),
    deleteButton() {
      this.deleteCompoundResponseButton({ buttonId: this.buttonId });
    },
  },
};
</script>

<style scoped>
.button-link{
  width: calc(100% - 31px) !important;
}
</style>
