import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';

const taskState = {
  runningTasks: {},
};

const taskGetters = {
  celeryIds: (state) => Object.keys(state.runningTasks),
  taskById: (state) => (celeryId) => state.runningTasks[celeryId],
};

const mutations = {
  addTask(state, {
    celeryId, callbackDone, callbackFailed, callbackUpdate, callbackShouldAbort,
  }) {
    Vue.set(state.runningTasks, celeryId, {
      celeryId, callbackDone, callbackFailed, callbackUpdate, callbackShouldAbort,
    });
  },
  removeTask(state, celeryId) {
    Vue.delete(state.runningTasks, celeryId);
  },
  clearTasks(state) {
    state.runningTasks = {};
  },
};

const actions = {
  async fetchTasks({ rootGetters }, celeryIds) {
    const request = {
      params: { celery_id__in: celeryIds.join(',') },
      ...rootGetters['auth/headerAuthorization'],
    };
    const { data } = await axios.get(endpoints.task, request);
    return data;
  },
  async abortTask({ rootGetters }, taskId) {
    const endpoint = `${endpoints.task}${taskId}/abort/`;
    const resp = await axios.post(endpoint, {}, rootGetters['auth/headerAuthorization']);
    return resp.data.success;
  },
};

export default {
  namespaced: true,
  state: taskState,
  getters: taskGetters,
  mutations,
  actions,
};
