<template>
  <b-alert
    :show="fallbackUsage.length > 0"
    class="mt-3"
    variant="warning"
  >
    <h4>Warning</h4>
    <div class="mb-2">
      The node is used as {{ fallbackNodeType }} node in the following nodes
    </div>
    <b-list-group>
      <b-list-group-item
        v-for="{ name, id } in fallbackUsage"
        :key="id"
        :to="editNodeLink(id)"
      >
        {{ name }}
      </b-list-group-item>
    </b-list-group>
  </b-alert>
</template>

<script>

export default {
  name: 'FallbackNodeDeleteWarning',
  props: {
    fallbackUsage: {
      required: true,
      type: Array,
    },
    warningType: {
      type: String,
      default: 'custom',
    },
  },
  computed: {
    fallbackNodeType() {
      return this.warningType === 'auth' ? 'authentication fallback' : 'custom fallback';
    },
  },
};

</script>
