<template>
  <div>
    <p>
      It is now possible to handle non-200 HTTP codes from integrations.
    </p>
    <p class="my-2">
      To do this, simply set a checkmark in <em>continue on error</em> when configuring an
      integration.
      <br>
      When running an integration activity you can then check for a <code>None</code> response
      and look up the HTTP status code, reason, and full response in the reserved
      <code>debug_mostRecentIntegrationResult</code> variable.
    </p>
    <!-- TODO: Add an appropriate image -->
  </div>
</template>
<script>
export default { name: 'IntegrationErrorContinueDescription' };
</script>
