<template>
  <b-list-group-item
    class="border-0 p-0 hover r-25"
  >
    <b-row
      no-gutters
      align-v="center"
      :class="folder.id === openedFolderId ? 'bg-primary text-white' : ''"
      class="cursor-pointer p-1 r-25"
      @click="rowClicked"
    >
      <b-col cols="auto">
        <font-awesome-icon
          class="mx-2"
          :icon="collapseOpen ? 'angle-down' : 'angle-right'"
        />
      </b-col>

      <b-col>
        {{ folder.name }}
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-tooltip.hover.noninteractive.viewport
          size="sm"
          class="ml-2"
          variant="text"
          :style="`visibility: ${defaultFolder ? 'hidden' : 'visible'} `"
          title="Edit folder"
          @click.stop="editFolder"
        >
          <font-awesome-icon :color="folder.id === openedFolderId ? 'white' : ''" icon="pencil" />
        </b-button>
      </b-col>
    </b-row>

    <b-collapse v-model="collapseOpen">
      <b-list-group>
        <ImageFolder
          v-for="(nestedFolder, index) in filteredFolders"
          :key="index + nestedFolder.name"
          class="pl-3"
          :folder="nestedFolder"
        />
      </b-list-group>
    </b-collapse>
  </b-list-group-item>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ImageFolder',
  inject: ['$folders', '$images', '$openedFolderId'],
  props: {
    folder: {
      type: Object, required: true,
    },
  },
  data() {
    return {
      collapseOpen: false,
    };
  },
  computed: {
    folders() {
      return this.$folders();
    },
    images() {
      return this.$images();
    },
    openedFolderId() {
      return this.$openedFolderId();
    },
    defaultFolder() {
      return this.folder.id === 'default';
    },
    filteredFolders() {
      return this.folders.filter((e) => e.parent === this.folder.id);
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    editFolder() {
      this.$root.$emit('edit-folder', this.folder);
    },
    rowClicked() {
      if (this.folder.id !== this.openedFolderId) {
        this.collapseOpen = true;
        this.$root.$emit('open-folder', this.folder.id);
      } else {
        this.collapseOpen = !this.collapseOpen;
      }
    },
  },
};
</script>
