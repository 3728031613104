<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="dark"
    @shown="focusInput"
  >
    <template #icon>
      <font-awesome-icon icon="broadcast-tower" />
    </template>
    <template #header>
      {{ cappedAssignment }}
    </template>
    <b-row>
      <b-col>
        <label>
          Label:
        </label>
        <b-form-input
          ref="labelName"
          v-model="value"
          :state="$v.value.$invalid ? false : null"
          placeholder="Label name"
          class="mb-2 mr-sm-2 mb-sm-0"
          @input="setLabel"
        />
        <b-form-invalid-feedback
          v-if="!$v.value.notEmpty"
          id="emptyname"
        >
          Write a label.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-else-if="!$v.value.noWhitespace"
          id="whitespace"
        >
          Label cannot have whitespaces.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-else-if="!$v.value.noSpecialChars"
          id="whitespace"
        >
          Label cannot have special characters.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
  </base-activity>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  truncateString,
} from '@/js/utils';

export default {
  name: 'MetricSignalActivity',
  components: {
    BaseActivity,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      label: 'activityMetricSignalLabel',
    }), 'nodeId', 'activityId'),
    cappedAssignment() {
      return truncateString(`${this.label}`, 60);
    },
  },
  watch: {
    /**
     * It is necessary to watch store as the value for key might be changed by
     * another, concurrent user. In this case we need to update keyName.
     */
    label(newValue) {
      this.value = newValue;
    },
  },
  mounted() {
    this.value = this.label;
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setActivityMetricSignalLabel: 'setActivityMetricSignalLabel',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    setLabel(value) {
      if (!this.$v.value.$invalid) {
        this.setActivityMetricSignalLabel({ label: value });
      }
    },
    focusInput() {
      if (this.$refs.labelName) {
        this.$refs.labelName.focus();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
  validations: {
    value: {
      notEmpty(value) {
        return !!value;
      },
      noWhitespace(value) {
        return !/\s/.test(value);
      },
      noSpecialChars(value) {
        return /^[A-Za-z0-9_\-.]+$/.test(value);
      },
    },
  },

};
</script>
