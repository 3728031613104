import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';
import {
  ACTION,
} from '@/js/activity';

const initialState = {
  botSecrets: {},
  isFetchingSecrets: {},
};

const moduleGetters = {
  getBotSecrets: (state) => (botId) => state.botSecrets[botId],
  getActiveBotSecrets: (state, _, rootState) => {
    const botId = rootState.botManipulation.activeBotId;
    return state.botSecrets[botId];
  },
  isFetchingActiveBotSecrets: (state, _, rootState) => {
    const botId = rootState.botManipulation.activeBotId;
    return !!state.isFetchingSecrets[botId];
  },
  /*
  * Returns a mapping of [node-id]:[node-name] of nodes that reference the secret to be deleted
  */
  getMainflowNodesReferencingSecret: (state, _, rootState) => (secretId) => {
    const bot = rootState.botManipulation.activeBot;

    const nodesReferencingSecrets = {};
    const nodes = bot.nodes;
    for (const node of Object.values(nodes)) {
      for (const activity of Object.values(node.activities)) {
        if (activity.type === ACTION) {
          // Check whether the integration references a secret in header or parameter
          const headersReferencingSecrets = activity.headers
            .filter((header) => {
              const secretReference = header.secretReference;
              return secretReference !== undefined
                  && secretReference.secretId === secretId;
            });
          const parametersReferencingSecrets = activity.params
            .filter((parameter) => {
              const secretReference = parameter.secretReference;
              return secretReference !== undefined
                  && secretReference.secretId === secretId;
            });
          if (headersReferencingSecrets.length > 0 || parametersReferencingSecrets.length > 0) {
            nodesReferencingSecrets[node.id] = node.name;
          }
        }
      }
    }
    return nodesReferencingSecrets;
  },
  /**
  * Returns a mapping of subflow nodes that reference a secret.
  */
  subflowNodesReferencingSecrets: (state, _, rootState, rootGetters) => (secretId) => {
    const subflowNodesReferencingSecrets = {};

    const subflows = rootGetters['botManipulation/getSubFlows'];
    for (const subflow of subflows) {
      for (const node of Object.values(subflow.nodes)) {
        for (const activity of Object.values(node.activities)) {
          if (activity.type === ACTION) {
            // Check whether the integration references a secret in header or parameter
            const headersReferencingSecrets = activity.headers
              .filter((header) => {
                const secretReference = header.secretReference;
                return secretReference !== undefined
                    && secretReference.secretId === secretId;
              });
            const parametersReferencingSecrets = activity.params
              .filter((parameter) => {
                const secretReference = parameter.secretReference;
                return secretReference !== undefined
                    && secretReference.secretId === secretId;
              });
            if (headersReferencingSecrets.length > 0 || parametersReferencingSecrets.length > 0) {
              subflowNodesReferencingSecrets[node.id] = {
                name: node.name,
                subflowId: subflow.id,
              };
            }
          }
        }
      }
    }
    return subflowNodesReferencingSecrets;
  },
};

const actions = {
  async fetchSecrets({ rootState, commit, getters }) {
    if (getters.isFetchingActiveBotSecrets) {
      return;
    }
    const botId = rootState.botManipulation.activeBotId;
    commit('setIsFetchingSecrets', { botId, value: true });
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
      },
    };
    const response = await axios.get(endpoints.botSecrets, config);

    const tableItems = [];
    for (const [key, value] of Object.entries(response.data)) {
      tableItems.push({
        id: key,
        name: value.name,
        secretType: value.secret_type,
        prefix: value.prefix,
      });
    }
    commit('setBotSecrets', { botId, botSecrets: tableItems });
    commit('setIsFetchingSecrets', { botId, value: false });
  },
  async createSimpleSecret({ rootState, dispatch }, {
    botId, secretName, secretValue,
  }) {
    const data = {
      bot_id: botId,
      secret_name: secretName,
      secret: secretValue,
      secret_type: 'simple',
    };
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    await axios.post(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async updateSimpleSecret({ rootState, dispatch }, {
    botId, secretId, newSecretName, newSecretValue,
  }) {
    const data = {
      secret_type: 'simple',
    };
    if (newSecretName !== null) {
      data.secret_name = newSecretName;
    }
    if (newSecretValue !== null) {
      data.secret = newSecretValue;
    }

    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
        secret_id: secretId,
      },
    };
    await axios.put(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async createBasicAuthorizationSecret({ rootState, dispatch }, {
    botId, secretName, username, password,
  }) {
    const data = {
      bot_id: botId,
      secret_name: secretName,
      secret_type: 'basicAuth',
      username,
      password,
    };
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    await axios.post(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async updateBasicAuthorizationSecret({ rootState, dispatch }, {
    botId, secretId, newSecretName, newSecretBasicUsername, newSecretBasicPassword,
  }) {
    const data = {
      secret_type: 'basicauth',
    };
    if (newSecretName !== null) {
      data.secret_name = newSecretName;
    }
    if (newSecretBasicUsername !== null) {
      data.username = newSecretBasicUsername;
    }
    if (newSecretBasicPassword !== null) {
      data.password = newSecretBasicPassword;
    }

    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
        secret_id: secretId,
      },
    };
    await axios.put(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async createPrefixedSecret({ rootState, dispatch }, {
    botId, secretName, secretPrefix, secretValue,
  }) {
    const data = {
      bot_id: botId,
      secret_name: secretName,
      secret_type: 'customPrefix',
      prefix: secretPrefix,
      secret: secretValue,
    };
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    await axios.post(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async updatePrefixedBotSecret({ rootState, dispatch }, {
    botId, secretId, newSecretName, newSecretPrefix, newSecretValue,
  }) {
    const data = {
      secret_type: 'customprefix',
    };
    if (newSecretName !== null) {
      data.secret_name = newSecretName;
    }
    if (newSecretPrefix !== null) {
      data.prefix = newSecretPrefix;
    }
    if (newSecretValue !== null) {
      data.secret = newSecretValue;
    }

    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
        secret_id: secretId,
      },
    };
    await axios.put(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async createEncryptionKeySecret({ rootState, dispatch }, {
    botId, secretName, encryptionType, secretValue,
  }) {
    const data = {
      bot_id: botId,
      secret_name: secretName,
      secret_type: `encryption-${encryptionType}`,
      secret: secretValue,
    };
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    await axios.post(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async updateEncryptionKeySecret({ rootState, dispatch }, {
    botId, secretId, newSecretName, newEncryptionType, newSecretValue,
  }) {
    const data = {
      secret_type: `encryption-${newEncryptionType}`,
    };
    if (newSecretName !== null) {
      data.secret_name = newSecretName;
    }
    if (newSecretValue !== null) {
      data.secret = newSecretValue;
    }

    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
        secret_id: secretId,
      },
    };
    await axios.put(endpoints.botSecrets, data, config);
    dispatch('fetchSecrets');
  },
  async deleteSecret({ dispatch, rootState }, { secretId, botId }) {
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
        secret_id: secretId,
      },
    };
    await axios.delete(endpoints.botSecrets, config);
    dispatch('fetchSecrets');
  },
  async fetchPartiallyRevealedSecret({ rootState }, { botId, secretId }) {
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: botId,
        secret_id: secretId,
      },
    };
    const response = await axios.get(endpoints.revealBotSecret, config);
    return response;
  },
};

const mutations = {
  setBotSecrets(state, { botId, botSecrets }) {
    // Using Vue.set since we're potentially adding a key to object here
    Vue.set(state.botSecrets, botId, botSecrets);
  },
  setIsFetchingSecrets(state, { botId, value }) {
    Vue.set(state.isFetchingSecrets, botId, value);
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters: moduleGetters,
  mutations,
};
