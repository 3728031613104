// Helper function for applyThisArgs.
function applyThisArg(originalFunction, ...argsToApply) {
  // It should not be an arrow function because then it doesn't work when Vue tries to bind it.
  return function newFunction(...args) {
    const mappedArgs = [];
    for (const arg of argsToApply) {
      mappedArgs.push(this[arg]);
    }
    // We need to call `.bind(this)` because only the outer function `newFunction` is bound to
    // the vue instance.
    return originalFunction.bind(this)(...args)(...mappedArgs);
  };
}

/**
 * Takes a dictionary of functions that returns functions and returns a new dictionary where each
 * function is transformed.
 * The transformation: If the function, f, is defined by X -> (Y -> Z) then a function, g: X -> Z
 * is returned where g(x) = f(x)(y) where y is extracted from the argsToApply array.
 * Example:
 * applyThisArgs({ f: () => (id) => element[id].var }, 'myId')
 * returns a dictionary containing:
 * { f: () => element[this.myId].var }
 *
 * @param originalFunctions A dictionary of functions where each function returns a function.
 * @param argsToApply A list of strings: Names of arguments in the `this` scope to apply.
 */
export function applyThisArgs(originalFunctions, ...argsToApply) {
  const mappedFunctions = {};
  for (const [name, fn] of Object.entries(originalFunctions)) {
    mappedFunctions[name] = applyThisArg(fn, ...argsToApply);
  }
  return mappedFunctions;
}

// Helper function for addThisArgs
function addThisArg(originalFunction, newArgs) {
  // It should not be an arrow function because then it doesn't work when Vue tries to bind it.
  return function newFunction(payload) {
    const newPayload = { ...payload };
    for (const [name, value] of Object.entries(newArgs)) {
      newPayload[name] = this[value];
    }
    // We need to call `.bind(this)` because only the outer function `newFunction` is bound to
    // the vue instance.
    return originalFunction.bind(this)(newPayload);
  };
}

/**
 * Takes a dictionary containing a number of functions and a dictionary with arguments to add to
 * this function. It returns a dictionary with new functions where the arguments are populated with
 * the values defined in newArgs, but taken from the "this" scope.
 * Example:
 * addThisArgs({ f: ({ x, id }) => { element[id].var = x; } }, { id: 'myId' });
 * returns
 * { f: ({ x }) => { element[this.myId].var = x; } }
 *
 * @param originalFunctions A dictionary of function.
 * @param newArgs A dictionary where keys refer to names and payl
 */
export function addThisArgs(originalFunctions, newArgs) {
  const mappedFunctions = {};
  for (const [name, fn] of Object.entries(originalFunctions)) {
    mappedFunctions[name] = addThisArg(fn, newArgs);
  }
  return mappedFunctions;
}
