<template>
  <b-modal
    :visible="showModal"
    hide-header
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    @ok="logout"
  >
    The current session is timed out. Please login again.
  </b-modal>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'AppSessionTime',
  computed: {
    ...mapState('auth', ['expired', 'ongoingSession']),
    showModal() {
      return this.expired && this.ongoingSession;
    },
  },
  methods: {
    logout() {
      this.$store.commit('auth/updateExpired', false);
      this.$router.push({ name: 'logout' });
    },
  },
};
</script>
