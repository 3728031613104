<template>
  <div
    ref="draggableContainer"
    class="outer"
    @mouseenter="setHovered(true)"
    @mouseleave="setHovered(false)"
  >
    <div
      class="node-inner d-block p-2 bg-white"
      :class="[{
        'inner-compact': compactView,
        'inner-standard': !compactView,
        'inner-highlight shadow-lg': isHighlighted,
        'inner-hovered shadow-lg': hoveredNode === config.id || activeNodeId === config.id,
        'inner-disabled': isDisabled,
      }]"
      @click.stop="nodeClicked"
    >
      <h6
        class="node-name text-center"
        :class="compactView ? 'mb-0' : 'mb-2'"
        :style="compactView ? 'flex: 1; align-self: center;' : ''"
      >
        {{ nameOfId(config.id) }}
      </h6>
      <div v-if="!compactView">
        <p
          v-for="(response, index) in responses.slice(0, 3)"
          :key="index"
          class="node-response px-1"
        >
          {{ response }}
        </p>
      </div>
      <b-row v-if="!compactView" class="node-info" no-gutters>
        <b-col
          v-if="isDisabled"
          cols="auto"
          class="pl-2"
        >
          <b-badge
            v-b-tooltip.hover.noninteractive.viewport
            pill
            title="This node is disabled"
          >
            <font-awesome-icon icon="link-slash" />
          </b-badge>
        </b-col>
        <b-col class="text-right">
          <b-badge
            v-b-tooltip.hover.noninteractive.viewport
            :title="`Node has ${responsesCount} ${responsesCount === 1 ? 'response' : 'responses'}`"
            pill
            class="mr-1 bg-primary-100 text-dark"
          >
            {{ responsesCount }}
            <font-awesome-icon
              icon="pencil"
            />
          </b-badge>
          <b-badge
            v-b-tooltip.hover.noninteractive.viewport
            pill
            class="bg-light text-dark"
            :title="`Node type: ${getNodeType} `"
          >
            <font-awesome-icon
              :icon="typeIcon"
            />
          </b-badge>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="localHover"
      style="position:absolute; width:210px"
      :style="compactView ? 'bottom: 28px;' : 'bottom: -15px;'"
    >
      <b-button-group class="w-100">
        <b-button
          v-b-tooltip.hover.bottom
          style="border-top-left-radius: 0.75rem; border-bottom-left-radius: 0.75rem;"
          title="Add child"
          size="sm"
          variant="primary"
          @click="$emit('linkNode', config)"
        >
          <font-awesome-icon icon="plus" />
        </b-button>
        <b-button
          v-b-tooltip.hover.bottom
          size="sm"
          :title="config.expanded ? 'Collapse' : 'Expand'"
          variant="primary"
          :disabled="nodeChildren.length === 0"
          @click="toggleExpand"
        >
          <font-awesome-icon :icon="config.expanded ? 'angle-up' : 'angle-down'" />
        </b-button>
        <b-button
          v-b-tooltip.hover.bottom
          size="sm"
          style="border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem;"
          :disabled="isRoot || hasNoParents"
          :title="isTopNode ? 'Zoom out 1 step' : 'Zoom in on node'"
          variant="primary"
          @click="isTopNode ? $emit('zoomOut', config) : $emit('zoomOnNode', config)"
        >
          <font-awesome-icon :icon="isTopNode ? 'magnifying-glass-minus' : 'magnifying-glass-plus'" />
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { applyThisArgs } from '@/js/storeHelpers';
import { nodeTypes } from '@/js/constants';
import { RESPONSE, COMPOUND_RESPONSE } from '@/js/activity';
import { mapPhrases, phrase2Id } from '@/js/utils';

export default {
  name: 'NodeBox',
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    compactView: {
      type: Boolean,
      default: false,
    },
    hoveredNode: {
      type: String,
      default: '',
    },
    activeNodeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localHover: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
      'nodeById',
    ]),
    ...mapState('graphView', ['graph', 'showSidebar', 'topNodeId', 'highlightEditNodeId']),
    ...mapState('botManipulation/activeBot', ['nodes']),
    ...mapGetters('auth', ['isUserObserver']),
    ...mapGetters('botManipulation/activeBot', ['phrasesDict']),
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        activeNode: 'nodeById',
        hasUniqueNodeName: 'isNodeNameUnique',
        isDisabled: 'isDisabled',
        isRoot: 'isRoot',
        fallbackUsage: 'getFallbackUsageOfNode',
        authFallbackUsage: 'getAuthFallbackUsageOfNode',
      }),
      'nodeId',
    ),
    node() {
      return this.nodeById(this.config.id) || {};
    },
    nodeId() {
      return this.config.id;
    },
    isTopNode() {
      return this.topNodeId === this.config.id;
    },
    isHighlighted() {
      return this.config.id === this.highlightEditNodeId;
    },
    hasNoParents() {
      return this.activeNode?.preds?.length === 0;
    },
    getNodeType() {
      const type = this.node.options?.nodeType;
      if (type === nodeTypes.MULTIPLE_CHOICE) {
        if (this.node.options?.isStrictMpc) {
          return 'Multiple choice';
        }
        return 'Smart node';
      }
      return 'Simple';
    },
    typeIcon() {
      const type = this.node.options?.nodeType;
      const mode = this.node.options?.responseMode;
      if (type === nodeTypes.MULTIPLE_CHOICE) {
        if (this.node.options?.isStrictMpc) {
          return 'stream';
        }
        return 'brain';
      }
      if (type === nodeTypes.SUBFLOW) {
        return 'bezier-curve';
      }

      return mode === 'require' ? ['fas', 'comments'] : ['far', 'comment'];
    },
    responses() {
      let responses = [];
      if (this.node.options?.nodeType === nodeTypes.MULTIPLE_CHOICE) {
        responses = Object.values(this.node.options.displayNames);
      }
      return responses.concat(Object.values(this.node?.activities || [])
        .filter((e) => [RESPONSE, COMPOUND_RESPONSE].includes(e.type))
        .map((e) => mapPhrases(e.text)
          .map((block) => (block.isPhrase
            ? this.phrasesDict[phrase2Id(block.value)].text
            : block.value)).join('').replaceAll(/<[^>]*>?/gm, '')));
    },
    responsesCount() {
      return Object.values(this.node?.activities || [])
        .filter((e) => [RESPONSE, COMPOUND_RESPONSE].includes(e.type)).length;
    },
    nodeChildren() {
      return this.nodeById(this.config.id)?.children || [];
    },
  },
  watch: {
    nodeChildren(n, o) {
      if (JSON.stringify(n) !== JSON.stringify(o)) {
        this.$emit('nodeChildrenChange', this.config.id);
      }
    },
  },

  methods: {
    ...mapActions('sidebar', ['showWarning']),
    nodeClicked() {
      if (this.isUserObserver) {
        this.showWarning({
          title: 'Insufficient permission',
          text: 'You do not have permissions to perform this action',
          variant: 'warning',
        });
        return;
      }
      if (this.nodeById(this.config.id)) {
        this.$emit('nodeClicked', this.config);
      }
    },
    toggleExpand() {
      this.$emit('toggleExpand', this.config.uid);
    },
    setHovered(hovering) {
      this.localHover = hovering;
      if (hovering) {
        this.$emit('hoverNode', this.config.id);
      } else {
        this.$emit('hoverNode', null);
      }
    },
  },
};
</script>
<style scoped>
.outer{
  position: absolute;
  height: 130px;
  width: 210px;
  margin-left: 2px;
}
.node-inner{
  text-overflow: ellipsis;
  width: 210px;
  position: absolute;
  border: 3px solid #A1A1A1;
}
.inner-hovered{
  border: 3px solid var(--primary) !important;
}
.inner-highlight{
  border: 3px solid var(--primary) !important;
}
.inner-compact{
  margin-top: 5px;
  border-radius: 0.75rem;
  height: 64px;
  display: flex !important;
  flex-direction: row !important;
  flex: 1 !important;
}
.inner-standard{
  border-radius: 0.75rem;
  height: 111px;
}
.inner-disabled{
  background-color: #EEEEEE !important;
}
.node-name{
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
  font-weight: 600;
  width: 100%;
}
.node-response{
  font-size: 0.7rem;
  text-align: left;
  width: 188px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
  border-radius: 0.2rem;
  border-left: solid 1px var(--secondary);
  margin: 1px 0px;
}
.node-info{
  width: 100%;
  position: absolute;
  bottom: 2px;
  right: 4px;
}
</style>
