<template>
  <div>
    <p>
      The following {{ pluralize(items, ['classifier', 'is']) }} missing labels that
      are used by the version in use by the bot:
    </p>
    <b-table
      :items="items"
      :fields="fields"
    >
      <template #cell(nodes)="data">
        {{ data.value }}
        <b-button
          v-b-tooltip.hover.bottomright
          class="px-2"
          :title="data.detailsShowing ? 'Hide nodes' : 'Show nodes'"
          @click="showNodes(data)"
        >
          <font-awesome-icon :icon="data.detailsShowing ? 'angle-up' : 'angle-down'" />
        </b-button>
      </template>
      <template #row-details="data">
        <node-list :nodes="nodesList[data.item.model]" />
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import NodeList from '@/components/NodeList.vue';

export default {
  name: 'EnsureClassifierCompatibility',
  components: {
    NodeList,
  },
  props: {
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: ['name', 'model', 'missingLabels', 'nodes'],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    items() {
      return cloneDeep(this.insightDetails.missing_labels);
    },
    nodesList() {
      const nodesList = {};
      for (const item of this.insightDetails.missing_labels) {
        nodesList[item.model] = item.failingNodes.map(
          (nodeId) => this.nodeById(nodeId),
        );
      }
      return nodesList;
    },
  },
  methods: {
    showNodes(data) {
      if (!data.detailsShowing) {
        data.toggleDetails();
      } else {
        data.toggleDetails();
      }
    },
    pluralize(items, strings) {
      if (items !== undefined && items.length > 1) {
        return strings.map((string) => {
          if (string === 'is') {
            return 'are';
          }
          return `${string}s`;
        }).join(' ');
      }
      return strings.join(' ');
    },
  },
};
</script>
