<template>
  <b-row
    v-if="loading"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <div v-else-if="userOrGroup.username !== 'superuser' && userOrGroup.username !== 'chatbot-service-account'">
    <b-card
      v-if="isUserOrGroup === 'user'"
      class="r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col
          cols="auto"
          class="my-auto"
        >
          <h4 class="card-title">
            User type
            <tooltipped-text
              value="Super users have full access to all actions in BotStudio, including
              administration. Normal and limited users need to get manual permision to
              alter different parts. Observers only have access to compute and view statistics,
              view the health page, and read conversation logs. Transcript users only have
              access to read conversation logs."
            />
          </h4>
        </b-col>
        <b-col class="text-right">
          <b-button
            variant="primary"
            class="mr-1"
            @click="openTokenModal"
          >
            <font-awesome-icon icon="sync" />
            Manage API token
          </b-button>
          <b-button
            variant="primary"
            @click="openEditUserModal"
          >
            <font-awesome-icon
              icon="pencil-alt"
            />
            <font-awesome-icon
              icon="user"
            />
            Update user info
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-select
            v-if="userOrGroup !== null && !isADUser"
            :value="userOrGroup.usertype"
            :options="usertypes"
            @input="setUserType"
          />
          <template
            v-else
          >
            <p>
              This user is logged in using Active Directory.<br>
              Roles for users logged in using Active Directory cannot be managed here, but
              are instead managed by assigning/removing the relevant Active Directory group
              to or from the user
              in question. <br>
              To change the roles assigned to this user, contact your organization's user
              administration.
            </p>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-else-if="isUserOrGroup === 'group'"
      class="r-75"
      title="User group"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          <b-form-input v-model="userOrGroup.name" />
        </b-col>
        <b-col cols="auto">
          <b-button variant="success" @click="updateGroupProxy">
            Update name
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="isUserOrGroup === 'user'"
      class="r-75 mt-3"
      body-class="p-3"
      title="User groups"
    >
      <left-right-select
        v-if="userOrGroup !== null && !isADUser"
        :left-options="userOrGroup.groups.notMember"
        :right-options="userOrGroup.groups.member"
        left-header="Unassigned groups"
        right-header="Assigned groups"
        identifier="group"
        @move-to-left="onMoveToLeft"
        @move-to-right="onMoveToRight"
      />
      <template v-else>
        <p>
          This user is logged in using Active Directory.<br>
          Groups for users logged in using Active Directory cannot be managed here, but
          are instead managed by assigning/removing the relevant Active Directory group
          to or from the user
          in question. <br>
          To change the roles assigned to this user, contact your organization's user
          administration.
        </p>
      </template>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      title="Permissions"
    >
      <template v-if="isSuperuser">
        <h6>This user is a superuser and therefore has access to all objects.</h6>
      </template>
      <template v-else>
        <div
          v-for="model in models"
          :key="model"
        >
          <h4 class="mt-4">
            {{ model.replace(/^./, model[0].toUpperCase()) }}
          </h4>
          <left-right-select
            v-if="userOrGroup !== null"
            :left-options="userOrGroup[model].noperm"
            :right-options="userOrGroup[model].perm"
            left-header="Not Allowed"
            right-header="Allowed"
            :identifier="model"
            @move-to-left="onMoveToLeft"
            @move-to-right="onMoveToRight"
          />
        </div>
      </template>
      <add-update-user-modal
        mode="editExisting"
        :existing-user-id="userOrGroup.id"
      />
      <admin-token-modal
        :user-id="parseInt(userId, 10)"
      />
    </b-card>
  </div>
  <div v-else>
    <b-card>
      <h4 class="card-title">
        Superuser is not editable.
      </h4>
    </b-card>
  </div>
</template>

<script>

import axios from 'axios';
import { mapActions } from 'vuex';
import endpoints from '@/js/urls';
import LeftRightSelect from '@/components/LeftRightSelect.vue';
import AddUpdateUserModal from '@/pages/Admin/AddUpdateUserModal.vue';
import AdminTokenModal from '@/pages/Admin/AdminTokenModal.vue';
import { UUID_REGEX } from '@/js/utils';
import TooltippedText from '@/components/TooltippedText.vue';
import { usertypes } from '@/js/constants';

export default {
  name: 'AdminUsersSingle',
  components: {
    LeftRightSelect,
    AddUpdateUserModal,
    TooltippedText,
    AdminTokenModal,
  },
  data() {
    return {
      userOrGroup: null,
      models: ['bot', 'classifier', 'dataset'],
      loading: true,
    };
  },
  computed: {
    isSuperuser() {
      return this.userOrGroup !== null && this.userOrGroup.group === 'Super user';
    },
    /**
     * Disclaimer: isADUser relies on a crude assumption: If the users username is a UUID, then the
     * user is an AD-logged-in user.
     */
    isADUser() {
      if (this.userOrGroup === null || this.userOrGroup.username === undefined) {
        return false;
      }
      return this.userOrGroup.username.match(UUID_REGEX);
    },
    isUserOrGroup() {
      if (this.userId) {
        return 'user';
      }
      return 'group';
    },
    usertypes() {
      return usertypes;
    },
    userId() {
      return this.$route.params.userId;
    },
    groupId() {
      return this.$route.params.groupId;
    },
  },
  watch: {
    userId(n, o) {
      if (n && n !== o) {
        this.fetchUserProxy(n);
      }
    },
    groupId(n, o) {
      if (n && n !== o) {
        this.fetchGroup(n);
      }
    },
  },
  mounted() {
    // Set the user id
    /**
     * Short version: When modal AddEditUserModal saves/updates this component is re-rendered,
     * (meaning data is reset)
     * Elaborate explanation as to why below this.fetchUserProxy(this.userId) is needed:
     * The AddEditUserModal will (when saving/updating) trigger a fetch from backend. Before and
     * after this fetch, a variable objectsLoading is set to true/false in store. The AdminUsers
     * page renders a route-view (that this page is included in) when objectsLoading is set to false
     * The toggling of objectsLoading before and after the fetch triggers the router-view
     * (including this page) to disappear and then reapear, trigging this component to be mounted
     * again.
     */
    if (this.userId) {
      this.fetchUserProxy(this.userId);
    } else {
      this.fetchGroup(this.groupId);
      this.models.push('mediafolder');
    }
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('administration', ['fetchUser', 'updateGroup']),
    async fetchUserProxy(userId) {
      this.loading = true;
      this.userOrGroup = await this.fetchUser(userId);
      this.loading = false;
    },
    async fetchGroup(groupId) {
      this.loading = true;
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      };
      const resp = await axios.get(`${endpoints.group}${groupId}/`, config);
      this.userOrGroup = resp.data;
      this.loading = false;
    },
    openEditUserModal() {
      this.$bvModal.show('new-user-modal');
    },
    openTokenModal() {
      this.$bvModal.show('admin-token-modal');
    },
    async editUser(model, objIds, add, usertype) {
      const userId = this.$route.params.userId;
      try {
        await axios.put(
          endpoints.user + userId,
          {
            model,
            obj_ids: objIds,
            add,
            usertype,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.fetchUserProxy(userId);
      } catch (err) {
        if (err.response.status === 400) {
          this.showWarning({
            title: 'An error occured',
            text: 'You cannot edit your own user group.',
            variant: 'danger',
          });
        }
      }
    },
    async editUserGroups(groupIds, add) {
      const userId = this.$route.params.userId;
      try {
        await axios.put(
          endpoints.user + userId,
          {
            group_ids: groupIds,
            add,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.fetchUserProxy(userId);
      } catch (err) {
        if (err.response.status === 400) {
          this.showWarning({
            title: 'An error occured',
            text: 'You cannot edit your own user group.',
            variant: 'danger',
          });
        }
      }
    },
    async editGroup(model, objIds, add) {
      const groupId = this.$route.params.groupId;
      try {
        await axios.put(
          `${endpoints.group}${groupId}/update_permissions/`,
          {
            model,
            obj_ids: objIds,
            add,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.fetchGroup(groupId);
      } catch (err) {
        if (err.response.status === 400) {
          this.showWarning({
            title: 'An error occured',
            text: 'You cannot edit your own group.',
            variant: 'danger',
          });
        }
      }
    },
    async editPermissions(model, objIds, add, usertype) {
      if (this.isUserOrGroup === 'user') {
        this.editUser(model, objIds, add, usertype);
      } else {
        this.editGroup(model, objIds, add);
      }
    },
    onMoveToLeft(val) {
      if (val.identifier === 'group') {
        this.editUserGroups(val.items, false);
      } else {
        this.editPermissions(val.identifier, val.items, false);
      }
    },
    onMoveToRight(val) {
      if (val.identifier === 'group') {
        this.editUserGroups(val.items, true);
      } else {
        this.editPermissions(val.identifier, val.items, true);
      }
    },
    setUserType(usertype) {
      this.editPermissions(null, null, null, usertype);
    },
    updateGroupProxy() {
      this.updateGroup({ id: this.userOrGroup.id, name: this.userOrGroup.name });
    },
  },
};

</script>
