<template>
  <main
    role="main"
  >
    <b-card
      title="Bot differences"
      class="r-75"
      body-class="p-3"
    >
      <div>
        On this page you can check the difference between two versions of {{ getBotName }} <br>
      </div>
      <div>
        Click-and-hold <strong>Ctrl</strong> (or <strong>Cmd</strong> on Mac)
        to select two items
      </div>
      <div class="mt-3">
        <b-form-select
          v-model="selectedBots"
          :options="botOptions"
          multiple
          :state="$v.selectedBots.$invalid ? false : null"
          :select-size="Math.min(6, botOptions.length)"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.selectedBots.notTooManySelections">
            Please select only two bots to compare
          </div>
        </b-form-invalid-feedback>
        <b-btn
          :disabled="!comparisonCanStart"
          class="mt-3"
          @click="computeDiff"
        >
          Compare bots
        </b-btn>
      </div>
    </b-card>
    <b-card
      no-body
      class="r-75 mt-3"
    >
      <div
        v-if="computingDiff"
        class="text-center"
      >
        <b-spinner
          class="m-3"
          style="width: 3rem; height: 3rem;"
          variant="primary"
        />
      </div>
      <template v-else>
        <h4
          v-if="bothchosen() && computed_items.length === 0"
          class="m-3"
        >
          No difference
        </h4>
        <div
          v-for="item in computed_items"
          :key="item.id"
        >
          <b-button
            v-b-toggle="item.id"
            block
            :variant="item.type"
            class="btn-sm"
          >
            <font-awesome-icon icon="angle-down" />  {{ item.msg }}
            <b-button
              v-if="item.link"
              class="ml-2"
              :variant="item.type"
              pill
              size="sm"
              @click.stop="$router.push(item.link)"
            >
              Link
            </b-button>
          </b-button>

          <b-collapse
            :id="item.id"
            accordion="diff-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <template v-if="item.format === 'colored'">
                <div
                  v-for="(change, idx) in item.v"
                  :key="change.key"
                >
                  <b-list-group
                    :class="{ 'mt-3': idx > 0 }"
                  >
                    <b-list-group-item
                      class="font-weight-bold"
                      variant="dark"
                    >
                      <b>{{ change.key }}</b>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="'before' in change"
                      variant="danger"
                    >
                      <vue-json-pretty
                        v-if="validJson(change.before)"
                        :data="change.before"
                      />

                      <pre
                        v-else
                        class="mb-0"
                        style="white-space: pre-wrap"
                      >{{ change.before }}</pre>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="'after' in change"
                      variant="success"
                    >
                      <vue-json-pretty
                        v-if="validJson(change.after)"
                        :data="change.after"
                      />
                      <pre
                        v-else
                        class="mb-0"
                        style="white-space: pre-wrap"
                      >{{ change.after }}</pre>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </template>
              <pre
                v-else
                class="mb-0"
                style="white-space: pre-wrap;"
              >{{ item.v }}</pre>
            </b-card-body>
          </b-collapse>
        </div>
      </template>
    </b-card>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

function sleep(t) {
  return new Promise((resolve) => {
    setTimeout(resolve, t);
  });
}
function strfy(dataToStringify, separator = '\n') {
  let returnValue = '';
  const nodes = Object.keys(dataToStringify);
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (returnValue.length > 0) {
      returnValue = `${returnValue}${separator}${dataToStringify[node]}`;
    } else {
      returnValue = dataToStringify[node];
    }
  }
  return returnValue;
}

export default {
  name: 'BotDiffPage',
  components: { VueJsonPretty },
  mixins: [validationMixin],
  data() {
    return {
      computingDiff: false,
      computed_items: [],
      selectedBots: [],
      botid1: null,
      botid2: null,
      idToBotMapping: {},
      objectTypes: [
        ['node', 'NODE'],
        ['subflow', 'SUBFLOW'],
        ['action', 'INTEGRATION'],
        ['nlu_model', 'CLASSIFIER'],
      ],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getBotName',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
    ...mapGetters('botManipulation', [
      'getStagedBots',
      'getDiffTwoBots',
      'lastStagedBot',
    ]),
    activeBotId() { return this.$route.params.botId; },
    botids() {
      const botOptions = [];
      // Shallow copy (slice) before sorting to avoid mutating vuex array
      const sortedBots = this.getStagedBots.slice().sort((x, y) => {
        const xDate = new Date(x.timestamp);
        const yDate = new Date(y.timestamp);
        if (xDate < yDate) {
          return -1;
        } if (xDate > yDate) {
          return 1;
        }
        return 0;
      });
      for (const b of sortedBots) {
        const timeString = new Date(b.timestamp).toLocaleString();
        botOptions.push({
          value: b.id,
          text: `${b.name} -- (${timeString})`,
        });
      }
      botOptions.push({
        value: this.activeBotId,
        text: 'Current Bot',
      });
      botOptions.reverse();
      return botOptions;
    },
    botOptions() {
      return this.botids;
    },
    diffTwoBots() {
      return this.getDiffTwoBots;
    },
    /**
     * Returns a Boolean, indicating whether comparison can start.
     */
    comparisonCanStart() {
      return this.selectedBots.length === 2;
    },
  },
  mounted() {
    // Add activeBot
    this.selectedBots.push(this.activeBotId);
    this.botid2 = this.activeBotId;
    // Adding this fake entry for "Current Bot", we only really need a timestamp that will sort'
    // highest
    this.idToBotMapping[this.activeBotId] = {
      timestamp: new Date().toISOString(),
      id: this.activeBotId,
    };

    if (!this.lastStagedBot) {
      this.botid1 = this.activeBotId;
      return;
    }

    this.botid1 = this.lastStagedBot.id;
    this.selectedBots.push(this.lastStagedBot.id);
    for (const stagedBot of Object.values(this.getStagedBots)) {
      this.idToBotMapping[stagedBot.id] = stagedBot;
    }
    this.computeDiff();
  },
  methods: {
    ...mapActions('botManipulation', [
      'doDiffTwoBots',
    ]),
    selectNameForBotId(botId) {
      if (botId === this.activeBotId) {
        return 'Current Bot';
      }
      const bot = this.idToBotMapping[botId];
      return bot.name;
    },
    async computeDiff() {
      // Determine which of the two is the oldest
      const orderedBots = this.selectedBots
        .map((botId) => this.idToBotMapping[botId])
        .sort((a, b) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          if (dateA > dateB) {
            return 1;
          } if (dateA < dateB) {
            return -1;
          }
          return 0;
        });
      this.botid1 = orderedBots[0].id;
      this.botid2 = orderedBots[1].id;

      if (this.computingDiff) {
        return;
      }

      const botid1 = this.botid1;
      const botid2 = this.botid2;

      if (botid1 != null && botid2 != null) {
        this.computingDiff = true;
        await this.doDiffTwoBots({ botid1, botid2 });
        await sleep(200);
        this.computingDiff = false;
        const d = this.diffTwoBots[0];
        this.computed_items = [];
        if (d.config_changes.length > 0) {
          this.computed_items.push({
            id: 'ConfigChanges',
            msg: 'Configuration changes',
            v: d.config_changes,
            type: 'primary',
            format: 'colored',
            link: { name: 'config' },
          });
        }
        for (const [name, displayName] of this.objectTypes) {
          if (d[`${name}_diff`].added.length > 0) {
            this.computed_items.push({
              id: `added-${name}s`,
              msg: `Added ${displayName}s`,
              v: strfy(d[`${name}_diff`].added),
              type: 'success',
            });
          }
          if (d[`${name}_diff`].removed.length > 0) {
            this.computed_items.push({
              id: `removed-${name}s`,
              msg: `Removed ${displayName}s`,
              v: strfy(d[`${name}_diff`].removed),
              type: 'danger',
            });
          }
          if (d[`${name}_diff`].renamed.length > 0) {
            this.computed_items.push({
              id: `renamed-${name}s`,
              msg: `Renamed ${displayName}s`,
              v: strfy(d[`${name}_diff`].renamed),
              type: 'warning',
            });
          }
          for (const [id, val] of Object.entries(d[`${name}_diff`].diff)) {
            let link;
            if (name === 'node') {
              link = { name: 'edit-node', params: { nodeId: id } };
            } else if (name === 'subflow') {
              link = { name: 'flow', params: { botId: `${this.activeBotId}-${id}` } };
            } else if (name === 'action') {
              link = { name: 'integrations' };
            } else if (name === 'nlu_model') {
              link = { name: 'classifiers' };
            }
            this.computed_items.push({
              id: `${name}-id:${encodeURI(id)}`,
              msg: `Changes in ${displayName} "${val.name}"`,
              v: val.diff,
              type: 'info',
              format: 'colored',
              link,
            });
          }
        }
        if (d.other_diff.length > 0) {
          this.computed_items.push({
            id: 'OtherChanges',
            msg: 'Other bot changes',
            v: d.other_diff,
            type: 'secondary',
            format: 'colored',
          });
        }
      }
    },
    chosen(b, name) {
      if (this.botid1 === b) {
        this.name1 = name;
        return true;
      }
      if (this.botid2 === b) {
        this.name2 = name;
        return true;
      }
      return false;
    },
    bothchosen() {
      return (this.botid1 != null & this.botid2 != null);
    },
    validJson(value) {
      return value instanceof Object;
    },
  },
  validations: {
    selectedBots: {
      notTooManySelections(selectedBots) {
        return selectedBots.length < 3;
      },
    },
  },
};
</script>
