<template>
  <div class="h-100 d-flex flex-column">
    <b-row class="flex-grow-1 mb-2">
      <b-col>
        <div v-if="!chartData.labels.length" class="py-5 text-center">
          There is no data to show.
        </div>
        <typed-chart
          v-else
          :chart-data="chartData"
          :options="preparedChartOptions"
          chart-type="bar"
          class="h-100"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="isDataExploration">
        <b-button
          v-b-modal.add-topic-examples
          variant="primary"
          block
          size="sm"
        >
          Add topic examples to category
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-b-modal.see-examples-modal
          variant="primary"
          block
          size="sm"
        >
          See topic examples
        </b-button>
      </b-col>
    </b-row>
    <AddTopicExamplesModal :topic-details="topicDetails" />
    <b-modal
      id="see-examples-modal"
      size="lg"
      ok-only
      scrollable
      title="Topic examples"
      @show="fetchExamples"
      @hide="resetModal"
    >
      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="pagination.totalRows"
        class="mb-1"
        size="sm"
        :per-page="pagination.perPage"
      />
      <b-table
        :per-page="pagination.itemsPerPage"
        :current-page="pagination.currentPage"
        :fields="fields"
        :busy="isFetchingExamples"
        :items="topicExamplesItems"
        show-empty
      />
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep, sortBy } from 'lodash';
import TypedChart from 'supwiz/components/TypedChart.vue';
import AddTopicExamplesModal from '@/components/StatisticsTopics/AddTopicExamplesModal.vue';
import axios from 'axios';
import endpoints from '@/js/urls';
import { mapActions } from 'vuex';

export default {
  name: 'DetailsSection',
  components: {
    TypedChart, AddTopicExamplesModal,
  },
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    topicsObj: {
      type: Object,
      required: true,
    },
    topicIndex: {
      type: Number,
      required: true,
    },
    specificityValue: {
      type: [Number, String],
      required: true,
    },
    visualizationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      selectedWord: '',
      localStopWords: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
      },
      topicExamplesItems: [],
      fields: [{ key: 'id', label: 'ID', tdClass: 'table-nobreak' }, 'text'],
      isFetchingExamples: false,
    };
  },
  computed: {
    isDataExploration() {
      return this.$route.fullPath.includes('data-exploration');
    },
    allWordsMap() {
      return this.topicsObj.word_names.map((name, index) => {
        const result = {
          name,
          index,
          count: this.topicsObj.word_counts[index],
          countInTopic: this.topicDetails.word_counts[index],
          frequency: this.topicDetails.word_frequency[index],
          relativeFrequency: this.topicDetails.word_relative_frequency[index],
        };
        const R = 1 - this.specificityValue;

        // see https://nlp.stanford.edu/events/illvi2014/papers/sievert-illvi2014.pdf
        result.wordRelevance = (R * result.frequency) + ((1 - R) * result.relativeFrequency);
        return result;
      });
    },
    chartData() {
      const datasets = [];
      // Data is sorted by wordRelevance, maybe we want to show that value instead?
      ['countInTopic', 'count'].forEach((count, index) => {
        const dataset = {
          data: this.wordMapFiltered.map((obj) => obj[count]),
        };
        if (!index) {
          dataset.backgroundColor = this.wordMapFiltered
            .map((x, i) => (i === this.selectedWordIndex ? '#00354C' : '#005F89'));
        } else {
          dataset.backgroundColor = '#ccc';
        }
        datasets.push(dataset);
      });
      return {
        datasets,
        labels: this.words,
      };
    },
    preparedChartOptions() {
      const options = cloneDeep(this.chartOptions);
      options.scales = {
        y: {
          stacked: true,
        },
      };
      const wordCount = this.wordMapFiltered.length;
      options.plugins.title.text = [`Top ${wordCount} most relevant keywords in topic`];
      options.plugins.tooltip = {
        displayColors: false,
        callbacks: {
          label: (tooltipItems) => {
            const word = tooltipItems.label;
            const wordIndex = this.getWordIndex(word);
            const wordObj = this.wordMapFiltered[wordIndex];
            return [
              // `Word relevance: ${Number(wordObj.wordRelevance).toFixed(2)}%`,
              `Count in topic: ${wordObj.countInTopic}`,
              `Total count: ${wordObj.count}`,
              // `Frequency: ${Number(wordObj.frequency).toFixed(2)}%`,
              // `Relative frequency: ${Number(wordObj.relativeFrequency).toFixed(2)}%`,
            ];
          },
        },
      };
      delete options.onClick;
      return options;
    },
    selectedWordIndex() {
      return this.getWordIndex(this.selectedWord);
    },
    topicDetails() {
      return this.topicsObj.topics?.[this.topicIndex] || null;
    },
    wordMapFiltered() {
      const countOfAtLeastOne = this.allWordsMap.filter(({ countInTopic }) => countInTopic >= 1);
      return sortBy(countOfAtLeastOne, ['wordRelevance']).reverse().slice(0, 15);
    },
    words() {
      return this.wordMapFiltered.map(({ name }) => name);
    },
    currentPage() {
      return this.pagination.currentPage;
    },
  },
  watch: {
    currentPage() {
      this.fetchExamples();
    },
    words: {
      immediate: true,
      handler(words) {
        if (words?.length) this.selectedWord = words[0];
        else this.selectedWord = '';
      },
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    getWordIndex(word) {
      return this.wordMapFiltered.findIndex(({ name }) => word === name);
    },
    async fetchExamples() {
      this.isFetchingExamples = true;
      try {
        const id = this.isDataExploration
          ? this.$route.params.visualizationId : this.visualizationId;
        const resp = await axios.get(
          endpoints.dataExplorationVisualizationExamples + id,
          {
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
            params: {
              vis_topic: this.topicDetails.id,
              current_page: this.pagination.currentPage,
              per_page: this.pagination.perPage,
            },
          });
        this.topicExamplesItems = resp.data.result;
        this.pagination.totalRows = resp.data.num_pages * 10;
      } catch (e) {
        this.showWarning({
          title: 'Failed to fetch examples',
          text: e.message,
          variant: 'danger',
        });
      } finally {
        this.isFetchingExamples = false;
      }
    },
    resetModal() {
      this.pagination.currentPage = 1;
      this.pagination.totalRows = 0;
      this.topicExamplesItems = [];
    },
  },
};
</script>
