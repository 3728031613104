/* README
 This file contains functions that manage interactions with
 the graph view (expand/collapse, updates from other pages, sorting,
 switching between tree view and graph view).

 The actual state of the graph is saved/managed in the store module.
 Both GraphVuePage and the store module utilize below functions, therefore
 they have been extracted here for better readability and maintenance.
*/

/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import { guidGenerator } from '@/js/utils';

function sortChildren(currentChildren, nodeChildren) {
  currentChildren.sort((a, b) => {
    if (a.type === 'collapse-btn') {
      return 1;
    }
    if (b.type === 'collapse-btn') {
      return -1;
    }
    return nodeChildren.indexOf(a.id) < nodeChildren.indexOf(b.id) ? -1 : 1;
  });
}
export function findNode(node, uid) {
  if (node.uid === uid) {
    return node;
  }
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].type !== 'collapse-btn') {
      const result = findNode(node.children[i], uid);
      if (result) {
        return result;
      }
    }
  }
  return null;
}
export function findNodes(node, id) {
  let nodes = [];
  if (node.id === id) {
    nodes.push(node);
  }
  if (node.type !== 'collapse-btn') {
    for (let i = 0; i < node.children.length; i++) {
      nodes = nodes.concat(findNodes(node.children[i], id));
    }
  }
  return nodes;
}
export function nodeExpand(node, nodeById, nameOfId) {
  const children = nodeById(node.id).children;
  node.expanded = true;
  for (let i = 0; i < 3; i++) {
    if (i < children.length) {
      node.children.push({
        uid: guidGenerator(),
        id: children[i],
        name: nameOfId(children[i]),
        children: [],
        parentId: node.id,
        expanded: false,
        type: 'node',
      });
    }
  }
  if (children.length > 3) {
    node.children.push({
      uid: guidGenerator(),
      selected: node.children.filter((e) => e.type !== 'collapse-btn').map((e) => e.id).join(','),
      parentId: node.id,
      parentUid: node.uid,
      type: 'collapse-btn',
    });
  }
  return node;
}
export function nodeCollapse(node) {
  node.expanded = false;
  node.children = [];
}
export function reinitializeChildren(node, nodeById, nameOfId) {
  const newChildrenIds = nodeById(node.id).children;
  const childrenCopy = cloneDeep(node.children).filter((e) => newChildrenIds.includes(e.id));
  node.children = childrenCopy;
  if (newChildrenIds.length > 3) {
    node.children.push(
      {
        uid: guidGenerator(),
        selected: node.children.filter((e) => e.type !== 'collapse-btn').map((e) => e.id).join(','),
        parentId: node.id,
        parentUid: node.uid,
        type: 'collapse-btn',
      },
    );
  } else {
    newChildrenIds.forEach((id) => {
      if (!node.children.find((e) => e.id === id)) {
        node.children.push({
          uid: guidGenerator(),
          id,
          name: nameOfId(id),
          children: [],
          parentId: node.id,
          expanded: false,
          type: 'node',
        });
      }
    });
  }
  sortChildren(node.children, newChildrenIds);
}
export function showAllNodes(node, nodeById, nameOfId) {
  const visibleChildren = node.children.map((e) => e.id);
  const nodeChildren = nodeById(node.id).children;
  nodeChildren.forEach((child) => {
    if (!visibleChildren.includes(child)) {
      node.children.push({
        uid: guidGenerator(),
        id: child,
        name: nameOfId(child),
        children: [],
        parentId: node.id,
        expanded: false,
        type: 'node',
      });
    }
  });
  sortChildren(node.children, nodeChildren);
  node.children[node.children.length - 1].selected = nodeChildren.join(',');
}
export function updateVisibleNodes(node, newVisibleNodes, nodeById, nameOfId) {
  const visibleChildren = node.children.map((e) => e.id);
  const nodeChildren = nodeById(node.id).children;

  nodeChildren.forEach((id) => {
    if (!newVisibleNodes.includes(id)) {
      if (visibleChildren.includes(id)) {
        const index = node.children.indexOf(node.children.find((e) => e.id === id));
        node.children.splice(index, 1);
      }
    } else if (!visibleChildren.includes(id)) {
      node.children.push({
        uid: guidGenerator(),
        id,
        name: nameOfId(id),
        parentId: node.id,
        children: [],
        expanded: false,
        type: 'node',
      });
    }
  });
  sortChildren(node.children, nodeById(node.id).children);
  node.children[node.children.length - 1].selected = node.children.map((e) => e.id).splice(0, node.children.length).join(',');
}
export function initializePath(node, path, index, nodeById, nameOfId) {
  let child = node.children.find((e) => e.id === path[index + 1]);
  if (!child) {
    node.children.push({
      uid: guidGenerator(),
      id: path[index + 1],
      name: nameOfId(path[index + 1]),
      children: [],
      parentId: node.id,
      expanded: path.length - 1 > index + 1,
      type: 'node',
    });
  }
  reinitializeChildren(node, nodeById, nameOfId);
  if (path.length - 1 > index + 1) {
    child = node.children.find((e) => e.id === path[index + 1]);
    initializePath(child, path, index + 1, nodeById, nameOfId);
  }
}

export function reinitializeNode(node, nodeById, nameOfId) {
  if (node.children.length) {
    reinitializeChildren(node, nodeById, nameOfId);
    node.children.forEach((child) => {
      if (child.type === 'node') {
        reinitializeNode(child, nodeById, nameOfId);
      }
    });
  }
}
