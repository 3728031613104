import Vue from 'vue';
import { setState } from '@/js/utils';
import config from './config/index';
import {
  makeInitialNodeState, nodesGetters, nodeActions, nodeMutations,
} from './nodes';
import { activityActions, activityGetters, activityMutations } from './activities';
import { phraseGetters, phraseMutations, phraseActions } from './phrases';
import { nerGetters, nerMutations, nerActions } from './ner';

const makeInitialBotState = () => ({
  ...makeInitialNodeState(),
});

const initialState = makeInitialBotState();

const activeBotGetters = {
  ...nodesGetters,
  ...activityGetters,
  ...nerGetters,
  ...phraseGetters,
  hasNeutralExamples(state) {
    if (state.neutralData) {
      if ((state.neutralData.files && state.neutralData.files.length > 0)
        || (state.neutralData.editable && state.neutralData.editable > 0)) {
        return true;
      }
    }
    return false;
  },
  neutralData(state) {
    return state.neutralData;
  },
};

const activeBotMutations = {
  ...nodeMutations,
  ...activityMutations,
  ...nerMutations,
  ...phraseMutations,
  setBot(state, bot) {
    for (const [key, value] of Object.entries(bot)) {
      if (key !== 'config' && key !== 'nodes' && key !== 'nodeIds') {
        Vue.set(state, key, value);
      }
    }
    // TODO: Should move setBot to be an action and then dispatch a commit instead. (?)
    config.mutations.setConfig(state.config, bot.config || {});
    nodeMutations.setNodes(state, {
      nodes: bot.nodes || {},
      nodeIds: bot.nodeIds || [],
    });
    state.phrases = bot.phrases || {};
  },
  resetBotState(state) {
    setState(state, makeInitialBotState(), ['config']);
  },
  removeNeutralsFile(state, idx) {
    state.neutralData.files.splice(idx, 1);
  },
};

const activeBotActions = {
  ...nodeActions,
  ...activityActions,
  ...nerActions,
  ...phraseActions,
  resetBotState({ commit }) {
    commit('resetBotState');
    commit('config/resetConfigState');
  },
  removeNeutralsFile({ commit }, idx) {
    commit('removeNeutralsFile', idx);
  },
};

export default {
  namespaced: true,
  modules: {
    config,
  },
  state: () => initialState,
  getters: activeBotGetters,
  mutations: activeBotMutations,
  actions: activeBotActions,
};
