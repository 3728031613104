<template>
  <b-modal id="new-feature-modal" size="lg" :title="title" body-class="feature-description" @hide="$emit('hide', null)">
    <slot name="description" class="w-100" />
    <template #modal-footer>
      <div class="w-100">
        <b-button class="mr-3" @click="$bvModal.hide('new-feature-modal')">
          Close
        </b-button>
        <b-button variant="warning" @click="dismissClicked">
          Do not show again
        </b-button>
        <div
          class="float-right align-middle text-muted"
        >
          Included in version {{ featureVersion }}
          <b-button
            class="ml-2"
            :to="{ name: activeFeature.link, params: { botId: getBotId } }"
            variant="primary"
            :disabled="visitPageDisabled"
          >
            Visit page
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { discoverableFeatures } from '@/js/discoverableFeatures';

export default {
  name: 'NewFeatureModal',
  props: {
    activeFeature: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('botManipulation', ['getBotId']),
    ...mapGetters('auth', ['isSuperUser']),
    title() {
      return this.activeFeature?.title;
    },
    featureVersion() {
      return this.activeFeature?.version;
    },
    visitPageDisabled() {
      if (this.activeFeature.key === discoverableFeatures.BOT_HISTORY.key && !this.isSuperUser) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('userSettings', ['dismissFeature']),
    dismissClicked() {
      this.dismissFeature(this.activeFeature.key);
      this.$bvModal.hide('new-feature-modal');
    },

  },
};
</script>
<style>
.feature-description img{
  border-radius: 0.5rem;
  border: 1px solid #EEEEEE;
}
</style>
