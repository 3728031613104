<template>
  <div>
    <p class="mb-2">
      A new Health feature has been added for automatically assessing GenAI responses in a bot.
      This includes performance evaluation based on thresholds and manual reviews.
      The GenAI Evaluation feature enables saving specific conversations for
      review and allows for ongoing re-evaluation.
    </p>
    <div class="text-center">
      <b-img
        class="w-100"
        fluid
        :src="require(`@/assets/featureImages/gen_ai_evaluation.png`)"
        alt="Example of the new Excel response export"
      />
    </div>
    <p class="mt-2">
      Additionally, several new features and improvements have been made for working with
      GenAI in chatbots:
    </p>
    <ul class="mb-0">
      <li>
        Enhanced context-based search: SupSearch and GenAI now automatically
        adjust search queries based on chat context, improving follow-up question handling etc.
      </li>
      <li>
        It is now possible to configure replacement phrases in the BotStudio UI
        to avoid generating wrong words or phrases.
      </li>
      <li>
        There is a new option for using Mock API with real GenAI
        for custom integrations in BotStudio.
      </li>
      <li>
        GenAI now utilizes structured data like HTML from scraped web pages
        for better context understanding.
      </li>
      <li>
        There are 4 new configurable guardrails to reduce hallucinations
        and unwanted responses by evaluating replies against user's question.
      </li>
      <li> Performance of SupGPT for non-English languages has been improved. </li>
    </ul>
  </div>
</template>
<script>
export default { name: 'GenerativeAIEvaluation' };
</script>
