<template>
  <div>
    <draggable
      :value="activityIds"
      role="tablist"
      filter=".card-body"
      :prevent-on-filter="false"
      @input="activityIds => setActivitiesOrder({ activityIds })"
    >
      <component
        :is="activityComponents[activityId]"
        v-for="(activityId, idx) in activityIds"
        :ref="activityId"
        :key="activityId"
        :node-id="nodeId"
        :activity-id="activityId"
        :indent="activityIndentation[idx]"
      />
    </draggable>
    <div v-if="!onlyAllowNewResponses">
      <b-btn-group style="display: flex">
        <b-btn
          v-if="allowedActivities.has(RESPONSE)"
          v-b-tooltip.hover
          title="Response"
          variant="info"
          @click="addActivity(RESPONSE)"
        >
          <font-awesome-icon icon="pencil-alt" />
        </b-btn>
        <b-btn
          v-if="allowedActivities.has(SET_VARIABLE)"
          v-b-tooltip.hover
          title="Set variable"
          variant="secondary"
          @click="addActivity(SET_VARIABLE)"
        >
          <font-awesome-icon icon="code" />
        </b-btn>
        <b-btn
          v-if="allowedActivities.has(ACTION)"
          v-b-tooltip.hover
          title="Integration"
          variant="dark"
          @click="addActivity(ACTION)"
        >
          <font-awesome-icon icon="cloud" />
        </b-btn>
        <b-btn
          v-if="allowedActivities.has(CHAT_ACTION)"
          v-b-tooltip.hover
          title="Platform action"
          variant="success"
          @click="addActivity(CHAT_ACTION)"
        >
          <font-awesome-icon icon="cloud" />
        </b-btn>
        <b-btn
          v-if="allowedActivities.has(CONTROL_FLOW)"
          v-b-tooltip.hover
          title="Control flow"
          variant="danger"
          @click="addActivity(CONTROL_FLOW)"
        >
          <font-awesome-icon icon="directions" />
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          title="Select from all activities"
          variant="primary"
          @click="$bvModal.show('new-activity-modal')"
        >
          All activities
        </b-btn>
      </b-btn-group>

      <new-activity-modal
        ref="newActivityModal"
        :node-id="nodeId"
        :allowed-activities="allowedActivities"
        @activity-created="focusActivity"
      />
    </div>
    <div v-else>
      <b-btn
        block
        variant="primary"
        @click="addActivity(RESPONSE)"
      >
        <font-awesome-icon icon="plus" />
        Add response
      </b-btn>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  ACTION, CHAT_ACTION, RESPONSE, SET_VARIABLE, CONTROL_FLOW, CALL_MODEL,
  ALL_ACTIONS, METRIC_SIGNAL, ENCRYPT, COMPOUND_RESPONSE, GENERATIVE_AI, SUP_WIZ_INTEGRATION,
} from '@/js/activity';
import ResponseActivity from '@/pages/EditNode/activity/ResponseActivity.vue';
import ActionActivity from '@/pages/EditNode/activity/ActionActivity.vue';
import ChatActionActivity from '@/pages/EditNode/activity/ChatActionActivity.vue';
import GenerativeAIActivity from '@/pages/EditNode/activity/GenerativeAIActivity.vue';
import SetVariableActivity from '@/pages/EditNode/activity/SetVariableActivity.vue';
import NewActivityModal from '@/pages/EditNode/activity/NewActivityModal.vue';
import ControlFlowActivity from '@/pages/EditNode/activity/ControlFlowActivity.vue';
import CallModelActivity from '@/pages/EditNode/activity/CallModelActivity.vue';
import MetricSignalActivity from '@/pages/EditNode/activity/MetricSignalActivity.vue';
import EncryptActivity from '@/pages/EditNode/activity/EncryptActivity.vue';
import CompoundResponseActivity from '@/pages/EditNode/activity/CompoundResponseActivity.vue';
import SupWizActivity from '@/pages/EditNode/activity/SupWizActivity.vue';

function activityTypeToComponent(activityType) {
  if (activityType === RESPONSE) {
    return ResponseActivity;
  }
  if (activityType === ACTION) {
    return ActionActivity;
  }
  if (activityType === CHAT_ACTION) {
    return ChatActionActivity;
  }
  if (activityType === GENERATIVE_AI) {
    return GenerativeAIActivity;
  }
  if (activityType === SUP_WIZ_INTEGRATION) {
    return SupWizActivity;
  }
  if (activityType === SET_VARIABLE) {
    return SetVariableActivity;
  }
  if (activityType === CONTROL_FLOW) {
    return ControlFlowActivity;
  }
  if (activityType === CALL_MODEL) {
    return CallModelActivity;
  }
  if (activityType === METRIC_SIGNAL) {
    return MetricSignalActivity;
  }
  if (activityType === COMPOUND_RESPONSE) {
    return CompoundResponseActivity;
  }
  if (activityType === ENCRYPT) {
    return EncryptActivity;
  }
  throw new Error(`Unknown activity type: ${activityType}`);
}

export default {
  name: 'NodeEditActivities',
  components: { NewActivityModal, Draggable },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    onlyAllowNewResponses: {
      type: Boolean,
      default: false,
    },
    allowedActivities: {
      type: Set,
      default: () => new Set(ALL_ACTIONS),
    },
  },
  data() {
    return {
      arrow: 'angle-down',
      showCreateModal: false,
      indentStep: 1,
      ACTION,
      CHAT_ACTION,
      RESPONSE,
      SET_VARIABLE,
      CONTROL_FLOW,
      METRIC_SIGNAL,
      COMPOUND_RESPONSE,
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      node: 'nodeById',
    }), 'nodeId'),
    activityIds() {
      return this.node.activityIds;
    },
    newActivityButtonText() {
      if (!this.onlyAllowNewResponses) {
        return 'Add activity';
      }
      return 'Add response';
    },
    activityComponents() {
      const components = {};
      for (const [id, activity] of Object.entries(this.node.activities)) {
        components[id] = activityTypeToComponent(activity.type);
      }
      return components;
    },
    activityIndentation() {
      let indent = 0;
      const indents = [];
      for (const activityId of this.node.activityIds) {
        const activity = this.node.activities[activityId];
        if (activity.type === CONTROL_FLOW) {
          if (['if', 'for'].includes(activity.name)) {
            indents.push(indent);
            indent += this.indentStep;
          } else if (['end if', 'end for'].includes(activity.name)) {
            indent -= this.indentStep;
            indents.push(indent);
          } else {
            indents.push(indent - this.indentStep);
          }
        } else {
          indents.push(indent);
        }
      }
      return indents;
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setActivitiesOrder',
    ]), { nodeId: 'nodeId' }),
    ...addThisArgs(mapActions('botManipulation/activeBot', [
      'addResponseActivity',
      'addSetVariableActivity',
      'addActionActivity',
      'addMetricSignalActivity',
    ]), { nodeId: 'nodeId' }),
    async addActivity(type) {
      if (type === RESPONSE) {
        const activityId = await this.addResponseActivity();
        this.focusActivity(activityId);
      } else if (type === SET_VARIABLE) {
        const activityId = await this.addSetVariableActivity({});
        this.focusActivity(activityId);
      } else if (type === METRIC_SIGNAL) {
        const activityId = await this.addMetricSignalActivity({});
        this.focusActivity(activityId);
      } else {
        this.$refs.newActivityModal.setNewActivityType(type);
        this.$bvModal.show('new-activity-modal');
      }
    },
    focusActivity(activityId) {
      this.$refs[activityId][0]?.focusActivity();
    },
  },
};
</script>

<style scoped>

</style>
