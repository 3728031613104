<template>
  <div>
    <p>The following nodes have loose ends:</p>
    <node-list :nodes="nodeList" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NodeList from '@/components/NodeList.vue';

export default {
  name: 'HasLooseEnds',
  components: {
    NodeList,
  },
  props: {
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    nodeList() {
      return this.insightDetails.failing_nodes.map(
        (nodeId) => this.nodeById(nodeId),
      ).filter((x) => x !== undefined);
    },
  },
};
</script>
