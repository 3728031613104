<template>
  <main role="main">
    <b-card
      title="Variants"
      class="r-75"
      body-class="p-3"
    >
      <p>Select a tab below to update the relevant part of the variant.</p>
      <b-button
        class="mt-1"
        :disabled="isVariantsRefreshing"
        @click="refreshAllSelectedVariantData()"
      >
        <b-spinner
          v-if="isVariantsRefreshing"
          small
        />
        Refresh
      </b-button>
    </b-card>

    <div
      v-if="isVariantsRefreshing"
      class="text-center mt-3"
    >
      <b-spinner style="width: 3rem; height: 3rem;" />
    </div>
    <div
      v-else-if="!selectedVariant"
      class="text-center mt-3"
    >
      Please select a variant to start editing.
    </div>
    <b-card
      v-else
      class="r-75 mt-3"
      no-body
    >
      <b-tabs
        v-model="sectionIndex"
        fill
      >
        <b-tab
          @click="chooseSection('responses')"
        >
          <template #title>
            Responses
            <dirty-icon v-if="dirtySections.responses" />
          </template>
          <variant-phrases @dirty="(v) => dirtySections.responses = v" />
        </b-tab>
        <b-tab
          @click="chooseSection('otherActivities')"
        >
          <template #title>
            Other activities
            <dirty-icon v-if="dirtySections.otherActivities" />
          </template>
          <VariantOtherActivities @dirty="(v) => dirtySections.otherActivities = v" />
        </b-tab>
        <b-tab
          title="Classifiers"
          @click="chooseSection('classifiers')"
        >
          <template #title>
            Classifiers
          </template>
          <variant-classifiers />
        </b-tab>
        <b-tab
          title="Secrets"
          @click="chooseSection('secrets')"
        >
          <template #title>
            Bot secrets
          </template>
          <variant-secrets />
        </b-tab>
        <b-tab
          @click="chooseSection('routing')"
        >
          <template #title>
            Routing
          </template>
          <variant-routing />
        </b-tab>
        <b-tab
          title="Configuration"
          @click="chooseSection('configuration')"
        >
          <template #title>
            Configuration
            <dirty-icon v-if="dirtySections.configuration" />
          </template>
          <variant-config @dirty="(v) => dirtySections.configuration = v" />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import VariantPhrases from '@/pages/Variants/VariantPhrases.vue';
import VariantRouting from '@/pages/Variants/VariantRouting.vue';
import VariantClassifiers from '@/pages/Variants/VariantClassifiers.vue';
import VariantSecrets from '@/pages/Variants/VariantSecrets.vue';
import VariantConfig from '@/pages/Variants/VariantConfig.vue';
import DirtyIcon from '@/components/DirtyIcon.vue';
import VariantOtherActivities from '@/pages/Variants/VariantOtherActivities.vue';

export default {
  name: 'VariantsEditPage',
  components: {
    VariantPhrases,
    VariantRouting,
    VariantClassifiers,
    VariantOtherActivities,
    VariantSecrets,
    VariantConfig,
    DirtyIcon,
  },
  beforeRouteEnter(to, from, next) {
    const hash = to.hash.replace('#', '');
    next((vm) => {
      vm.chooseSection(hash);
      vm.initComponentFromRoute();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const hash = to.hash.replace('#', '');
    if (this.sections.indexOf(hash) === -1) {
      this.sectionIndex = 0;
    } else {
      this.chooseSection(hash);
    }
    if (to.params.variantId !== from.params.variantId) {
      if (!await this.dirtyGuard()) {
        return false;
      }
      this.cleanUpVariantSpecificState();
      this.initComponentFromRoute();
    }
    next();
    return true;
  },
  // eslint-disable-next-line no-unused-vars
  async beforeRouteLeave(to, from, next) {
    if (!await this.dirtyGuard()) {
      return false;
    }
    this.cleanUpVariantSpecificState();
    next();
    return true;
  },
  data() {
    return {
      sections: ['responses', 'otherActivities', 'classifiers', 'secrets', 'routing', 'configuration'],
      sectionIndex: null,
      dirtySections: {
        responses: false,
        configuration: false,
      },
    };
  },
  computed: {
    ...mapGetters('variants', [
      'selectedVariant',
      'isVariantsRefreshing',
    ]),
    isDirty() {
      return Object.values(this.dirtySections).some((x) => x);
    },
  },
  watch: {
    sectionIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        const tabHash = `#${this.sections[newValue]}`;
        if (this.$route.hash !== tabHash) {
          this.$router.replace({ hash: tabHash });
        }
      }
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNavigation);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNavigation);
    });
  },
  methods: {
    ...mapMutations('variants', [
      'setSelectedVariant',
      'cleanUpVariantSpecificState',
    ]),
    ...mapActions('variants', [
      'refreshAllSelectedVariantData',
    ]),
    async initComponentFromRoute() {
      this.setSelectedVariant(this.$route.params.variantId);
      await this.refreshAllSelectedVariantData();
    },
    chooseSection(section) {
      this.sectionIndex = this.sections.indexOf(section);
    },
    async dirtyGuard() {
      if (this.isDirty) {
        return this.$bvModal.msgBoxConfirm(
          'You have unsaved changes which will be discarded if you leave the page. Are you sure you'
          + ' wish to continue?');
      }
      return true;
    },
    preventNavigation(event) {
      if (this.isDirty) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    },
  },
};
</script>

<style scoped>
::v-deep .nav-tabs > li > .nav-link{
  border:none;
  border-radius: 0;
}
::v-deep .nav-tabs > li > .nav-link.active, ::v-deep .nav-tabs > li > .nav-link:hover{
  background-color: var(--primary);
  color: var(--white);
}
::v-deep .nav-tabs{
  margin: 1px 1px 0 1px;
}
::v-deep .nav-tabs > li:first-child > .nav-link{
  border-top-left-radius: .75rem;
}
::v-deep .nav-tabs > li:last-child > .nav-link{
  border-top-right-radius: .75rem;
}
</style>
