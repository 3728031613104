<template>
  <b-container
    fluid
    class="px-0"
  >
    <b-row no-gutters>
      <template v-if="isModelsLoading">
        <b-col />
        <b-col cols="auto">
          <b-spinner
            class="m-4"
            style="width: 4rem; height: 4rem;"
          />
        </b-col>
        <b-col />
      </template>
      <b-col v-else>
        <router-view />
        <bot-studio-toaster />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BotStudioToaster from '@/components/BotStudioToaster.vue';

export default {
  name: 'NLU',
  components: {
    BotStudioToaster,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.updateBotList();
      vm.fetchGlobalNLUModels();
      vm.fetchLanguageModels();
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'nlu') {
      this.updateBotList();
      this.fetchGlobalNLUModels();
      this.fetchLanguageModels();
    }
    next();
  },
  computed: {
    ...mapState('nlu/classifier', ['isModelsLoading']),
    ...mapState(['sidebarOpen']),
  },
  methods: {
    ...mapActions('nlu/classifier', ['fetchGlobalNLUModels']),
    ...mapActions('nlu/languageModel', ['fetchLanguageModels']),
    ...mapActions('botManipulation', ['updateBotList']),
  },
};
</script>

<style scoped>

</style>
