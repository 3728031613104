import VoiceStore from './botManipulation/activeBot/config/voice';

export default function configureModerator(store) {
  // Listen to actions
  // Note that we don't wait for promises to return before proceeding
  store.subscribeAction(({ type, payload }) => {
    if (type === 'botManipulation/activeBot/removeNode') {
      if (store.hasModule('botManipulation/activeBot/config/voice')) {
        store.commit('botManipulation/activeBot/config/voice/ensureValidInterruptNode', payload);
      }
    }
  });
  store.subscribe(async ({ type }) => {
    if (type === 'botManipulation/activeBot/config/setRoutingLanguage') {
      if (store.hasModule('botManipulation/activeBot/config/voice')) {
        await store.dispatch('botManipulation/activeBot/config/voice/setDefaultVoice');
      }
      return;
    }

    if (type === 'userSettings/setShowVoicebotFeatures') {
      const hasVoicebot = store.hasModule('botManipulation/activeBot/config/voice');
      if (!hasVoicebot) {
        store.registerModule(
          ['botManipulation', 'activeBot', 'config', 'voice'],
          VoiceStore,
          { preserveState: true },
        );
      }
    }
  });
}
