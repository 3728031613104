const phoneticAlphabets = {
  da: {
    vowels: [
      {
        ipa: 'a',
        ex1: '*a*bitur',
        ex2: 'kortf*a*ttet',
        ex3: 'marin*a*',
      },
      {
        ipa: 'ɑ',
        ex1: '*a*bnorme',
        ex2: 'pic*a*resk',
        ex3: 'var*e*r',
      },
      {
        ipa: 'ɑː',
        ex1: '*a*ra',
        ex2: 'pir*a*t',
        ex3: 'vandk*ar*',
      },
      {
        ipa: 'ɛ',
        ex1: '*e*dderkop',
        ex2: 'and*æ*gtig',
        ex3: 'tomatpur*é*',
      },
      {
        ipa: 'ɛː',
        ex1: '*e*ventyr',
        ex2: 'dyrl*æ*ge',
        ex3: '',
      },
      {
        ipa: 'ɔ',
        ex1: '*o*nde',
        ex2: 'dysf*u*nktion',
        ex3: 'Sus*å*',
      },
      {
        ipa: 'ɒ',
        ex1: '*å*nd',
        ex2: 'abeh*å*nd',
        ex3: 'akkumulat*or*',
      },
      {
        ipa: 'ɒː',
        ex1: '*å*rbog',
        ex2: 'fyrre*å*rig',
        ex3: 'ricksh*aw*',
      },
      {
        ipa: 'ɔː',
        ex1: '*å*bne',
        ex2: 'modst*å*ende',
        ex3: 'husarbl*å*',
      },
      {
        ipa: 'ɐ',
        ex1: '',
        ex2: 'buntmag*er*e',
        ex3: 'bortgift*er*',
      },
      {
        ipa: 'æː',
        ex1: '*a*begilde',
        ex2: 'flagd*a*ge',
        ex3: 'hovedf*ag*',
      },
      {
        ipa: 'e',
        ex1: '*e*ditere',
        ex2: 'klarh*e*den',
        ex3: 'kanap*é*',
      },
      {
        ipa: 'ø',
        ex1: '*ø*dem',
        ex2: 'mælkeb*ø*tte',
        ex3: 'milj*ø*',
      },
      {
        ipa: 'øː',
        ex1: '*ø*de',
        ex2: 'bortf*ø*rere',
        ex3: 'lind*ø*',
      },
      {
        ipa: 'ə',
        ex1: '*E*yolf',
        ex2: 'men*e*ders',
        ex3: 'æd*e*',
      },
      {
        ipa: 'eː',
        ex1: '*e*delweiss',
        ex2: 'plac*e*bo',
        ex3: 'Culms*ee*',
      },
      {
        ipa: 'i',
        ex1: '*i*aften',
        ex2: 'l*i*p*i*zzaner',
        ex3: 'bankkont*i*',
      },
      {
        ipa: 'iː',
        ex1: '*i*linger',
        ex2: 'industr*i*en',
        ex3: 'bag*i*',
      },
      {
        ipa: 'o',
        ex1: '*o*ase',
        ex2: 'udel*o*d',
        ex3: 'brav*o*',
      },
      {
        ipa: 'œ',
        ex1: '*ø*m',
        ex2: 'varmer*ø*r',
        ex3: 'b*øh*',
      },
      {
        ipa: 'œː',
        ex1: '*Ear*l',
        ex2: 'arbejdspr*ø*ves',
        ex3: 'Edinb*urgh*',
      },
      {
        ipa: 'oː',
        ex1: '*o*berst',
        ex2: 'bevatr*o*ns',
        ex3: 'cong*o*',
      },
      {
        ipa: 'u',
        ex1: '*u*døver',
        ex2: 'spektak*u*lær',
        ex3: 'endn*u*',
      },
      {
        ipa: 'uː',
        ex1: '*u*ge',
        ex2: 'spidsb*u*e',
        ex3: 'eis*uu*',
      },
      {
        ipa: 'y',
        ex1: '*y*bsalon',
        ex2: 'underkr*y*dder',
        ex3: 'men*u*',
      },
      {
        ipa: 'yː',
        ex1: '*y*de',
        ex2: 'vids*y*n',
        ex3: 'dødss*yg*',
      },
    ],
    consonants: [
      {
        ipa: 'b',
        ex1: '*b*landt',
        ex2: 'ga*b*ende',
        ex3: 'då*b*',
      },
      {
        ipa: 'd',
        ex1: '*d*å',
        ex2: 'hæv*d*er',
        ex3: 'græ*dt*',
      },
      {
        ipa: 'ð',
        ex1: '*th*ousand',
        ex2: 'baa*d*en',
        ex3: 'gra*d*',
      },
      {
        ipa: 'f',
        ex1: '*f*abrik',
        ex2: 'spøge*f*ugle',
        ex3: 'boligsto*f*',
      },
      {
        ipa: 'g',
        ex1: '*g*aader',
        ex2: 'fåbor*g*enser',
        ex3: 'fi*k*',
      },
      {
        ipa: 'h',
        ex1: '*h*åb',
        ex2: 'træg*h*eden',
        ex3: '',
      },
      {
        ipa: 'j',
        ex1: 'h*j*orte',
        ex2: 'mil*j*ø',
        ex3: 'bagtø*j*',
      },
      {
        ipa: 'kʰ',
        ex1: '*k*abale',
        ex2: 'bort*k*omme',
        ex3: 'Cu*c*',
      },
      {
        ipa: 'l',
        ex1: '*l*å',
        ex2: 'romanb*l*ad',
        ex3: 'fata*l*',
      },
      {
        ipa: 'm',
        ex1: '*m*aya',
        ex2: 'taxa*m*etret',
        ex3: 'calciu*m*',
      },
      {
        ipa: 'n',
        ex1: '*n*å',
        ex2: 'toge*n*e',
        ex3: 'bredde*n*',
      },
      {
        ipa: 'ŋ',
        ex1: '',
        ex2: 'fu*n*ktion',
        ex3: 'klareri*ng*',
      },
      {
        ipa: 'pʰ',
        ex1: '*p*a',
        ex2: 'cul*p*a',
        ex3: '',
      },
      {
        ipa: 'ʔ',
        ex1: '',
        ex2: 'aff*y*re',
        ex3: 'bortg*å*',
      },
      {
        ipa: 'ʁ',
        ex1: '*r*å',
        ex2: 'a*r*eal',
        ex3: '',
      },
      {
        ipa: 'ɐ̯',
        ex1: '',
        ex2: 'jonglø*r*kunst',
        ex3: 'bjørnebæ*r*',
      },
      {
        ipa: 's',
        ex1: '*s*å',
        ex2: 'per*s*on',
        ex3: 'belønne*s*',
      },
      {
        ipa: 'ɕ',
        ex1: '*Sj*ælland',
        ex2: 'benzinsta*ti*on',
        ex3: 'affi*che*',
      },
      {
        ipa: 't',
        ex1: '*t*oge',
        ex2: 'side*t*al',
        ex3: 'administrationsappara*t*',
      },
      {
        ipa: 'v',
        ex1: '*v*aagner',
        ex2: 'e*v*aluere',
        ex3: 'dåkal*v*',
      },
      {
        ipa: 'w',
        ex1: '*w*alkman',
        ex2: 'prø*v*e',
        ex3: 'meterlo*v*',
      },
    ],
  },
  en: {
    vowels: [
      {
        ipa: 'i',
        ex1: '*ea*t',
        ex2: 'f*ee*l',
        ex3: 'vall*ey*',
      },
      {
        ipa: 'ɪ',
        ex1: '*i*f',
        ex2: 'f*i*ll',
        ex3: '',
      },
      {
        ipa: 'eɪ',
        ex1: '*a*te',
        ex2: 'g*a*te',
        ex3: 'd*ay*',
      },
      {
        ipa: 'ɛ',
        ex1: '*e*very',
        ex2: 'p*e*t',
        ex3: 'm*eh* (rare word-final)',
      },
      {
        ipa: 'æ',
        ex1: '*a*ctive',
        ex2: 'c*a*t',
        ex3: 'n*ah* (rare word-final)',
      },
      {
        ipa: 'ɑ',
        ex1: '*o*bstinate',
        ex2: 'p*o*ppy',
        ex3: 'r*ah* (rare word-final)',
      },
      {
        ipa: 'ɔ',
        ex1: '*o*range',
        ex2: 'c*au*se',
        ex3: 'Ut*ah*',
      },
      {
        ipa: 'ʊ',
        ex1: 'b*oo*k',
        ex2: '',
        ex3: '',
      },
      {
        ipa: 'oʊ',
        ex1: '*o*ld',
        ex2: 'cl*o*ne',
        ex3: 'g*o*',
      },
      {
        ipa: 'u',
        ex1: '*U*ber',
        ex2: 'b*oo*st',
        ex3: 't*oo*',
      },
      {
        ipa: 'ʌ',
        ex1: '*u*ncle',
        ex2: 'c*u*t',
        ex3: '',
      },
      {
        ipa: 'aɪ',
        ex1: '*i*ce',
        ex2: 'b*i*te',
        ex3: 'fl*y*',
      },
      {
        ipa: 'aʊ',
        ex1: '*ou*t',
        ex2: 's*ou*th',
        ex3: 'c*ow*',
      },
      {
        ipa: 'ɔɪ',
        ex1: '*oi*l',
        ex2: 'j*oi*n',
        ex3: 't*oy*',
      },
      {
        ipa: 'ju',
        ex1: '*Yu*ma',
        ex2: 'h*u*man',
        ex3: 'f*ew*',
      },
      {
        ipa: 'ə',
        ex1: '*a*go',
        ex2: 'wom*a*n',
        ex3: 'are*a*',
      },
    ],
    'r-colored vowels': [
      {
        ipa: 'ɪɹ',
        ex1: '*ear*s',
        ex2: 't*ir*amisu',
        ex3: 'n*ear*',
      },
      {
        ipa: 'ɛɹ',
        ex1: '*air*plane',
        ex2: 'app*ar*ently',
        ex3: 'sc*ar*e',
      },
      {
        ipa: 'ʊɹ',
        ex1: '',
        ex2: '',
        ex3: 'c*ur*e',
      },
      {
        ipa: 'aɪɹ',
        ex1: '*Ire*land',
        ex2: 'f*ir*eplace',
        ex3: 'ch*oir*',
      },
      {
        ipa: 'aʊɹ',
        ex1: '*hour*s',
        ex2: 'p*ower*ful',
        ex3: 's*our*',
      },
      {
        ipa: 'ɔɹ',
        ex1: '*or*ange',
        ex2: 'm*or*al',
        ex3: 's*oar*',
      },
      {
        ipa: 'ɑɹ',
        ex1: '*ar*tist',
        ex2: 'st*ar*t',
        ex3: 'c*ar*',
      },
      {
        ipa: 'ɝ',
        ex1: '*ear*th',
        ex2: 'b*ir*d',
        ex3: 'f*ur*',
      },
      {
        ipa: 'ɚ',
        ex1: '',
        ex2: 'all*er*gy',
        ex3: 'supp*er*',
      },
    ],
    semivowels: [
      {
        ipa: 'w',
        ex1: '*w*ith, s*ue*de',
        ex2: 'al*w*ays',
        ex3: '',
      },
      {
        ipa: 'j',
        ex1: '*y*ard, f*e*w',
        ex2: 'on*i*on',
        ex3: '',
      },
    ],
    'aspirated oral stops': [
      {
        ipa: 'p',
        ex1: '*p*ut',
        ex2: 'ha*pp*en',
        ex3: 'fla*p*',
      },
      {
        ipa: 'b',
        ex1: '*b*ig',
        ex2: 'num*b*er',
        ex3: 'cra*b*',
      },
      {
        ipa: 't',
        ex1: '*t*alk',
        ex2: 'capi*t*al',
        ex3: 'sough*t*',
      },
      {
        ipa: 'd',
        ex1: '*d*ig',
        ex2: 'ran*d*om',
        ex3: 'ro*d*',
      },
      {
        ipa: 'k',
        ex1: '*c*ut',
        ex2: 'sla*ck*er',
        ex3: 'Ira*q*',
      },
      {
        ipa: 'g',
        ex1: '*g*o',
        ex2: 'a*g*o',
        ex3: 'dra*g*',
      },
    ],
    'nasal stops': [
      {
        ipa: 'm',
        ex1: '*m*at, s*m*ash',
        ex2: 'ca*m*era',
        ex3: 'roo*m*',
      },
      {
        ipa: 'n',
        ex1: '*n*o, s*n*ow',
        ex2: 'te*n*t',
        ex3: 'chicke*n*',
      },
      {
        ipa: 'ŋ',
        ex1: '',
        ex2: 'li*n*k',
        ex3: 's*ing*',
      },
    ],
    fricatives: [
      {
        ipa: 'f',
        ex1: '*f*ork',
        ex2: 'le*f*t',
        ex3: 'hal*f*',
      },
      {
        ipa: 'v',
        ex1: '*v*alue',
        ex2: 'e*v*ent',
        ex3: 'lo*v*e',
      },
      {
        ipa: 'θ',
        ex1: '*th*in',
        ex2: 'empa*th*y',
        ex3: 'mon*th*',
      },
      {
        ipa: 'ð',
        ex1: '*th*en',
        ex2: 'mo*th*er',
        ex3: 'smoo*th*',
      },
      {
        ipa: 's',
        ex1: '*s*it',
        ex2: 'ri*s*k',
        ex3: 'fact*s*',
      },
      {
        ipa: 'z',
        ex1: '*z*ap',
        ex2: 'bu*s*y',
        ex3: 'kid*s*',
      },
      {
        ipa: 'ʃ',
        ex1: '*sh* e',
        ex2: 'abbrevia*ti*on',
        ex3: 'ru*sh*',
      },
      {
        ipa: 'ʒ',
        ex1: '*J*acques',
        ex2: 'plea*s*ure',
        ex3: 'gara*g*e',
      },
      {
        ipa: 'h',
        ex1: '*h*elp',
        ex2: 'en*h*ance',
        ex3: 'a-*h*a!',
      },
    ],
    affricatives: [
      {
        ipa: 'tʃ',
        ex1: '*ch*in',
        ex2: 'fu*t*ure',
        ex3: 'atta*ch*',
      },
      {
        ipa: 'dʒ',
        ex1: '*j*oy',
        ex2: 'ori*g*inal',
        ex3: 'oran*g*e',
      },
    ],
    approximants: [
      {
        ipa: 'l',
        ex1: '*l*id, g*l*ad',
        ex2: 'pa*l*ace',
        ex3: 'chi*ll*',
      },
      {
        ipa: 'ɹ',
        ex1: '*r*ed, b*r*ing',
        ex2: 'bo*rr*ow',
        ex3: 'ta*r*',
      },
    ],
  },
};

export default phoneticAlphabets;
