<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    :advanced-icon="advancedIcon"
    bs-variant="dark"
    @shown="focusInput"
    @toggleExtraContents="toggleExtraContents"
  >
    <template #icon>
      <font-awesome-icon icon="cloud" />
    </template>
    <template #header>
      {{ cappedActionName }}
      <font-awesome-icon
        v-if="actionIsModified"
        icon="exclamation-circle"
        class="float-right mr-2"
      />
    </template>
    <b-card>
      <template v-if="actionIsModified">
        <b-row
          class="mt-1 mb-3"
          align-v="center"
        >
          <b-col class="text-right text-danger">
            <font-awesome-icon
              icon="exclamation-circle"
              class="mr-2"
            />
            Integration was modified
          </b-col>
          <b-col cols="7">
            <b-button
              size="sm"
              class="mr-2"
              @click="dismissModifiedWarning()"
            >
              Dismiss warning
            </b-button>
            <b-button
              size="sm"
              @click="replaceDefaultValues()"
            >
              Replace default values
            </b-button>
          </b-col>
        </b-row>
        <hr class="separator">
      </template>
      <b-row
        class="mt-1 mb-3"
        align-v="center"
      >
        <b-col class="text-right font-weight-bold">
          Integration name
        </b-col>
        <b-col cols="7">
          <router-link :to="{ name: 'integrations', hash: `#${name}` }">
            {{ name }}
          </router-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="5"
          class="text-right"
        >
          <b-button
            variant="outline"
            @click="() => ui.headersExpanded = !ui.headersExpanded"
          >
            <font-awesome-icon
              :icon="ui.headersExpanded ? 'angle-up' : 'angle-right'"
            />

            Headers
          </b-button>
        </b-col>
      </b-row>
      <b-collapse
        v-model="ui.headersExpanded"
      >
        <b-row
          v-for="(header, index) in headers"
          :key="header.key"
          show
          variant="secondary"
          class="mt-1"
          align-v="center"
        >
          <b-col class="text-right font-weight-bold">
            {{ header.key }}
          </b-col>
          <b-col cols="7">
            <b-form-select
              v-if="header.secretReference !== undefined"
              :value="header.secretReference.secretId"
              :options="botSecretsForSelector"
              disabled
            />
            <botscript-validation
              v-else
              :validations="[]"
              :value="header.value"
              @onChange="code => setHeaderText(code, header.key, index)"
            />
          </b-col>
        </b-row>
      </b-collapse>

      <b-row>
        <b-col
          cols="5"
          class="text-right"
        >
          <b-button
            variant="outline"
            @click="() => ui.paramsExpanded = !ui.paramsExpanded"
          >
            <font-awesome-icon
              :icon="ui.paramsExpanded ? 'angle-up' : 'angle-right'"
            />

            Parameters
          </b-button>
        </b-col>
      </b-row>

      <b-collapse
        v-model="ui.paramsExpanded"
      >
        <b-row
          v-for="(param, index) in filteredParams"
          :key="param.key"
          show
          variant="secondary"
          class="mt-1"
          align-v="center"
        >
          <b-col class="text-right font-weight-bold">
            {{ param.key }}
          </b-col>
          <b-col cols="7">
            <b-form-select
              v-if="param.secretReference !== undefined"
              :value="param.secretReference.secretId"
              :options="botSecretsForSelector"
              disabled
            />
            <botscript-validation
              v-else
              :validations="[]"
              :value="param.value"
              @onChange="code => setParamText(code, param.key, index)"
            />
          </b-col>
        </b-row>
      </b-collapse>

      <!-- Mock response -->
      <b-row
        v-if="showExtraContents"
        class="mt-1"
        align-v="center"
      >
        <b-col
          class="text-right font-weight-bold"
        >
          Mock response
        </b-col>

        <b-col
          cols="7"
        >
          <botscript-validation
            :validations="[]"
            :value="mockResponse"
            @onChange="code => setMockResponse(code)"
          />
        </b-col>
      </b-row>

      <hr
        v-if="allowAppend"
        class="separator"
      >
      <b-row
        v-if="allowAppend"
        show
        variant="secondary"
        class="mt-1"
        align-v="center"
      >
        <b-col class="text-right font-weight-bold">
          Append value to endpoint:
        </b-col>
        <b-col cols="7">
          <botscript-validation
            :validations="[]"
            :value="appendValue"
            @onChange="code => setAppendValue({ value: code })"
          />
        </b-col>
      </b-row>
      <hr class="separator">
      <b-row
        class="mt-1"
        align-v="center"
      >
        <b-col
          cols="5"
          class="text-right font-weight-bold"
        >
          Variable to save response in
        </b-col>
        <b-col>
          <VariableName
            allow-empty
            :value="target"
            @input="target => setTarget({ target })"
          />
          <b-col />
        </b-col>
      </b-row>
    </b-card>
  </base-activity>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import VariableName from '@/components/VariableName.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  truncateString, changeOrAddParam,
} from '@/js/utils';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'ActionActivity',
  components: { BaseActivity, VariableName, BotscriptValidation },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ui: {
        headersExpanded: false,
        paramsExpanded: false,
      },
      showExtraContents: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotActionByName',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    ...mapGetters('userSettings', ['getBotStudioSettings']),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      target: 'activityTarget',
      name: 'activityName',
      headers: 'activityActionHeaders',
      params: 'activityParams',
      activityActionModified: 'activityActionModified',
      appendValue: 'activityActionAppendValue',
    }), 'nodeId', 'activityId'),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
    ]),
    botSecretsForSelector() {
      const botSecrets = this.getBotSecrets(this.activeBotId);
      if (botSecrets === undefined) {
        return [];
      }
      const secretsMapped = botSecrets.map((botSecret) => ({
        value: botSecret.id,
        text: `🔑${botSecret.name}`,
      }));
      secretsMapped.unshift({ value: null, text: 'Choose a secret' });
      return secretsMapped;
    },
    mockResponse() {
      const mockResponseEntry = this.params.find((x) => x.key === 'mockResponse');
      if (mockResponseEntry === undefined) {
        return '';
      }
      return mockResponseEntry.value;
    },
    cappedActionName() {
      if (!this.target || !this.getBotStudioSettings.show_variable_assignment) {
        return truncateString(this.name, 60);
      }
      return truncateString(`${this.target} = ${this.name}`, 60);
    },
    actionIsModified() {
      // Returns true if this specific activity references an action that is marked as modified
      return this.activityActionModified;
    },
    filteredParams() {
      return this.params.filter((param) => param.key !== 'mockResponse');
    },
    allowAppend() {
      const action = this.getBotActionByName(this.name);
      return action && action.allowAppend;
    },
    advancedIcon() {
      return this.showExtraContents ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setTarget: 'setActivityTarget',
      setParams: 'setActivityParams',
      setHeaders: 'setActivityActionHeaders',
      setActivityActionModified: 'setActivityActionModified',
      setAppendValue: 'setActivityActionAppendValue',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    ...mapActions('sidebar', ['showWarning']),
    setHeaderText(value, headerKey, indexHint) {
      // just use the changeOrAddParam function as headers have the same format.
      const newHeaders = changeOrAddParam(this.headers, headerKey, value, indexHint);
      this.setHeaders({ headers: newHeaders });
    },
    setParamText(value, paramKey, indexHint) {
      const newParams = changeOrAddParam(this.params, paramKey, value, indexHint);
      this.setParams({ params: newParams });
    },
    setMockResponse(newMockResponse) {
      let newParams;
      if (newMockResponse.trim() === '') {
        // User has effectively deleted the mock-response
        // Action: Revert to using default/blueprints mock-response, by deleting the mockResponse
        // 'param'
        newParams = this.params.filter((entry) => entry.key !== 'mockResponse');
      } else {
        newParams = changeOrAddParam(this.params, 'mockResponse', newMockResponse);
      }
      this.setParams({ params: newParams });
    },
    focusInput() {
      if (this.$refs.baseActivity?.$children[4]?.$children[3]?.$children[0]) {
        this.$refs.baseActivity.$children[4].$children[3].$children[0].focus();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
    dismissModifiedWarning() {
      this.setActivityActionModified({ modified: false });
    },
    replaceDefaultValues() {
      this.dismissModifiedWarning();
      const defaultAction = this.getBotActionByName(this.name);
      if (!defaultAction) {
        this.showWarning({
          title: 'Activities copied',
          text: 'Activities copied to pasteboard.',
          variant: 'primary',
        });
        return;
      }
      const newHeaders = defaultAction.headers.map((header) => {
        if (header.secretReference !== undefined) {
          return {
            key: header.name,
            value: header.default,
            secretReference: header.secretReference,
          };
        }
        return {
          key: header.name,
          value: header.default,
        };
      });
      this.setHeaders({ headers: newHeaders });

      const newParams = defaultAction.params.map((param) => {
        if (param.secretReference !== undefined) {
          return {
            key: param.name,
            value: param.default,
            secretReference: param.secretReference,
          };
        }
        return {
          key: param.name,
          value: param.default,
        };
      });
      this.setParams({ params: newParams });
    },
    toggleExtraContents() {
      this.showExtraContents = !this.showExtraContents;
    },
  },
};
</script>
