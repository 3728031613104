<template>
  <div>
    <b-modal
      id="admin-token-modal"
      title="Manage API tokens"
      size="lg"
      ok-title="Done"
      ok-variant="primary"
      ok-only
      @shown="fetchTokens"
      @hidden="clearAllTokens"
    >
      <b-form-group
        class="mb-0"
        description="Create and revoke tokens. You can create up to 2 tokens per user."
      >
        <div
          v-b-popover.hover.top="'Table showing active API tokens which can be used to'
            + ' authenticate against the API.'"
          class="d-block bg-primary text-center text-white table-label py-1"
          :style="`width: ${keyWidth}px;`"
        >
          API Tokens
        </div>
        <div class="border bg-light px-2">
          <div
            class="bg-light w-100"
          >
            <table-data
              borderless
              show-empty
              thead-tr-class="d-none"
              tbody-tr-class="bg-white shadow-sm p-2 my-2 text-break"
              table-class="spaced-table"
              :fields="fields"
              :items="items"
              :busy="fetching"
              @add="addModal"
              @delete="revokeToken"
            >
              <template #cell(created)="data">
                <div class="text-center">
                  <small class="text-muted">
                    Created
                  </small>
                  <br>
                  {{ data.value }}
                </div>
              </template>
              <template #cell(expiry)="data">
                <div class="text-center">
                  <small class="text-muted">
                    Expires
                  </small>
                  <br>
                  {{ data.value }}
                </div>
              </template>
            </table-data>
          </div>
        </div>
      </b-form-group>
    </b-modal>
    <b-modal
      id="admin-token-limit-modal"
    >
      You cannot create more than 2 tokens per user. You must revoke at least 1 token to continue.
    </b-modal>
    <b-modal
      id="admin-token-add-modal"
      title="Create New API Token"
      :ok-title="okTitle"
      :busy="fetching"
      :ok-only="hasNewToken"
      @ok.prevent="handleOk"
      @hide="handleHide"
    >
      <edit-key-value
        class="my-3"
        key-prop="Expiry date"
        description="Choose the date the token should expire."
        :value-prop="expiryDate"
        :min-key-width="120"
        :disabled="hasNewToken"
        type="date"
        @input="setExpiryDate"
      />
      <edit-key-value
        class="my-3"
        key-prop="Expiry time"
        description="Choose the time of day the token should expire."
        :value-prop="expiryTime"
        :min-key-width="120"
        :disabled="hasNewToken"
        type="time"
        @input="(x) => expiryTime = x"
      />
      <edit-key-value
        v-if="hasNewToken"
        class="my-3"
        key-prop="Created"
        description="Datetime of creation."
        type="input"
        disabled
        :min-key-width="120"
        :value-prop="formatDate(newToken.created)"
      />
      <display-key-value
        v-if="hasNewToken"
        class="my-3"
        key-prop="Token"
        description="This token can be used until the expiry date is reached."
        :value-prop="newToken.token"
        :min-key-width="120"
        @copy="copyToken"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';
import endpoints from '@/js/urls';

export default {
  name: 'AdminTokenModal',
  components: {
    TableData,
    EditKeyValue,
    DisplayKeyValue,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'created',
          formatter: this.formatDate,
        },
        {
          key: 'expiry',
          formatter: this.formatDate,
        },
        'delete',
      ],
      keyWidth: 230,
      expiryDate: null,
      expiryTime: null,
      fetching: false,
      newToken: null,
    };
  },
  computed: {
    okTitle() {
      if (!this.hasNewToken) {
        return 'Generate';
      }
      return 'Close';
    },
    hasNewToken() {
      return this.newToken !== null;
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    copyToken() {
      navigator.clipboard.writeText(this.newToken.token);
    },
    preZero(number) {
      return number.toString().padStart(2, '0');
    },
    addModal() {
      if (this.items.length === 2) {
        this.$bvModal.msgBoxOk('You cannot create more than 2 tokens per user. You must revoke at least 1 token to continue.');
      } else {
        this.expiryDate = new Date();
        this.expiryDate.setDate(this.expiryDate.getDate() + 30);
        this.expiryTime = `${this.preZero(this.expiryDate.getHours())}`
          + `:${this.preZero(this.expiryDate.getMinutes())}`
          + `:${this.preZero(this.expiryDate.getSeconds())}`;
        this.$bvModal.show('admin-token-add-modal');
      }
    },
    setExpiryDate(date) {
      this.expiryDate = date;
    },
    setExpiryTime(time) {
      this.expiryTime = time;
    },
    async handleOk() {
      if (this.hasNewToken) {
        return;
      }
      await this.generateToken();
    },
    async handleHide(bvModalEvent) {
      if (this.hasNewToken) {
        bvModalEvent.preventDefault();
        const value = await this.$bvModal.msgBoxConfirm(
          'Did you copy the token? When the window is closed, the token can no longer be viewed or copied.',
          {
            cancelTitle: 'Back',
            okTitle: 'Close',
            okVariant: 'danger',
            hideHeaderClose: true,
            autoFocusButton: 'cancel',
            centered: true,
          },
        );
        if (value) {
          this.newToken = null;
          this.$bvModal.hide('admin-token-add-modal');
        }
      }
    },
    async generateToken() {
      if (this.expiryDate === null || this.expiryTime === null) {
        this.showWarning({
          title: 'Expiry date and time required',
          text: "It's required to choose a date and time to generate a token.",
          variant: 'danger',
        });
        return;
      }
      try {
        this.generating = true;
        const expiry = new Date(this.expiryDate);
        const times = this.expiryTime.split(':');
        expiry.setHours(
          parseInt(times[0], 10),
          parseInt(times[1], 10),
          parseFloat(times[2]),
        );
        const response = await axios.post(
          endpoints.externalAuthToken,
          {
            selected_user: this.userId,
            token_expiry: Math.trunc(expiry.getTime() / 1000),
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        if (response.status === 200) {
          this.newToken = response.data;
          await this.fetchTokens();
          this.showWarning({
            title: 'Token generated',
            text: 'New API Token generated succesfully',
            variant: 'success',
          });
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to generate token',
          text: error.response.data.error,
          variant: 'danger',
        });
      } finally {
        this.generating = false;
      }
    },
    async fetchTokens() {
      try {
        this.fetching = true;
        const response = await axios.get(
          endpoints.externalAuthToken,
          {
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
            params: { selected_user: this.userId },
          },
        );
        if (response.status === 200) {
          this.items = response.data;
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch token',
          text: error.response.data.error,
          variant: 'danger',
        });
      } finally {
        this.fetching = false;
      }
    },
    async revokeToken(item) {
      if ((await this.$bvModal.msgBoxConfirm('Are you sure you want to revoke the token? This cannot be undone!', {
        title: 'Revoke token',
        okTitle: 'Revoke',
        okVariant: 'danger',
      }))) {
        try {
          this.fetching = true;
          const response = await axios.delete(
            endpoints.externalAuthToken,
            {
              headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
              data: { digest: item.digest },
            },
          );
          if (response.status === 204) {
            await this.fetchTokens();
          }
        } catch (error) {
          this.showWarning({
            title: 'Failed to revoke token',
            text: error.message,
            variant: 'danger',
          });
        } finally {
          this.fetching = false;
        }
      }
    },
    formatDate(timestamp) {
      const date = typeof timestamp === 'number' ? timestamp * 1000 : timestamp;
      return new Date(date).toLocaleString();
    },
    clearAllTokens() {
      this.items = [];
    },
  },
};
</script>
<style scoped>
::v-deep .spaced-table {
  font-size: 0.9375rem;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
</style>
