<template>
  <div>
    <b-card
      v-if="activeSource !== null"
      class="r-75"
      body-class="p-3"
    >
      <b-row
        no-gutters
        class="mb-2"
      >
        <b-col class="my-auto">
          <h4 class="mb-0 card-title">
            {{ title }}
          </h4>
        </b-col>
        <b-col cols="auto">
          <b-link :to="{ name: 'nlu-label-single-overview' }">
            <b-button
              variant="primary"
            >
              Back
            </b-button>
          </b-link>
        </b-col>
      </b-row>
      <template v-if="activeSource.type === 'examples' && activeLabel">
        <string-list
          :strings="activeLabel.examples"
          fixed-input
          @change="exampleChange"
        />
        <b-btn
          class="mt-1"
          variant="primary"
          @click="localSaveExamples"
        >
          <b-spinner
            v-if="savingExamples"
            small
          />
          Save examples
        </b-btn>
      </template>
      <template v-else>
        <h4 class="mt-2">
          Source details
        </h4>
        <b-card no-body>
          <b-table
            sort-by="name"
            :fields="fields"
            :items="items"
            borderles
          />
        </b-card>
        <h4 class="mt-4">
          Source data (showing at most 100 examples)
        </h4>
        <div
          v-if="loading"
          class="text-center"
        >
          <b-spinner />
        </div>
        <b-list-group
          v-else
          class="mt-0"
        >
          <b-list-group-item
            v-for="(example, idx) in data"
            :key="idx"
          >
            {{ example }}
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-card>
  </div>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import StringList from 'supwiz/components/StringList.vue';
import endpoints from '@/js/urls';

const keyMap = {
  name: 'Name',
  rows: 'Rows',
  type: 'Type',
  includeAutolabels: 'Include autolabels',
  language: 'Language',
  botName: 'Bot name',
  includeFilter: 'Include filter',
  excludeFilter: 'Exclude filter',
};

export default {
  name: 'LabelSource',
  components: {
    StringList,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setAndFetch(to.params.sourceId);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.sourceId !== from.params.sourceId) {
      this.setAndFetch(to.params.sourceId);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.setActiveSourceId(null);
    next();
  },
  data() {
    return {
      loading: false,
      savingExamples: false,
      data: [],
      fields: ['name', 'value'],
    };
  },
  computed: {
    ...mapGetters('nlu/classifier', ['activeModel', 'activeLabel', 'activeSource']),
    title() {
      return `Labels / ${this.activeLabel?.displayName} / Sources / ${this.activeSource?.name}`;
    },
    items() {
      const items = [];
      for (const [name, value] of Object.entries(this.activeSource)) {
        if ((['id', 'parentId', 'childId'].includes(name))) {
          continue;
        }
        let itemName = name;
        if (name in keyMap) {
          itemName = keyMap[name];
        }
        items.push({ name: itemName, value });
      }
      return items;
    },
  },
  watch: {
    activeSource(n, o) {
      if (n && n !== o) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapMutations('nlu/classifier', ['updateExamplesWithHelper', 'setActiveSourceId']),
    ...mapActions('nlu/classifier', ['saveExamples']),
    ...mapActions('sidebar', ['showWarning']),
    setAndFetch(sourceId) {
      this.setActiveSourceId(sourceId);
    },
    exampleChange(payload) {
      this.updateExamplesWithHelper({ id: this.activeLabel.id, payload });
    },
    async localSaveExamples() {
      this.savingExamples = true;
      await this.saveExamples(this.activeLabel.id);
      this.savingExamples = false;
    },
    async loadData() {
      this.loading = true;
      if (this.activeSource.type === 'file') {
        const resp = await axios.get(endpoints.labelFile, {
          params: { id: this.activeSource.id },
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        this.data = resp.data.result;
      } else if (this.activeSource.type === 'node') {
        try {
          const resp = await axios.get(endpoints.labelNode, {
            params: {
              botId: this.activeSource.botId,
              parentId: this.activeSource.parentId,
              childId: this.activeSource.childId,
              language: this.activeSource.language,
              includeAutolabels: this.activeSource.includeAutolabels,
              autolabelsStart: this.activeModel.config.autolabels_start,
              autolabelsAgeInDays: this.activeModel.config.autolabels_age_in_days,
              manualLabelsStart: this.activeModel.config.manual_labels_start,
              manualLabelsAgeInDays: this.activeModel.config.autolabels_age_in_days,
            },
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
          this.data = resp.data.result;
        } catch (err) {
          if (err.response.status === 403) {
            this.showWarning({
              title: 'Permission denied',
              text: 'You do not have permissions to this bot. You must ask a superuser to grant you access.',
              variant: 'warning',
            });
          }
          this.loading = false;
          throw err;
        }
      } else if (this.activeSource.type === 'external') {
        try {
          const resp = await axios.get(endpoints.labelExternal, {
            params: { id: this.activeSource.id },
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
          this.data = resp.data.result;
        } catch (err) {
          if (err.response.status === 403) {
            this.showWarning({
              title: 'Permission denied',
              text: 'You do not have permissions to this dataset. You must ask a superuser to grant you access.',
              variant: 'warning',
            });
          }
          this.loading = false;
          throw err;
        }
      } else {
        this.data = [];
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
