<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Page not found</h1>
        <p>Sorry, the requested page could not be found.</p>
        <p>
          <b-link :to="{ name: 'home' }">
            Click here to go to the front page
          </b-link>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PageNotFoundPage',
};
</script>

<style scoped>

</style>
