<template>
  <div>
    <b-dropdown
      v-b-tooltip.noninteractive.hover
      v-bind="$attrs"
      boundary="viewport"
      dropleft
      menu-class="bg-white"
      no-caret
      title="Enhance or generate a response using AI"
      @show="resetData"
      @shown="setSelected"
    >
      <template #button-content>
        <font-awesome-icon icon="wand-magic-sparkles" />
        <span class="sr-only">
          Enhance or generate a response using AI
        </span>
      </template>
      <b-dropdown-item-button
        v-for="({
          text, desc, type, icon, color,
        }) in improvementOptions"
        :key="type"
        button-class="px-2"
        @click="openModal(type)"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            class="r-25 border d-flex bg-white align-items-center text-center position-relative"
            size="2.5rem"
            :class="`border-${color} text-${color}`"
          >
            <div
              :class="`bg-${color} position-absolute icon-bg w-100 h-100`"
            />
            <font-awesome-icon
              :icon="icon"
              class="icon"
            />
          </b-avatar>
          <div class="d-flex flex-column pl-2 justify-content-center">
            <span class="font-weight-bold">{{ text }}</span>
            <span>{{ desc }}</span>
          </div>
        </div>
      </b-dropdown-item-button>
    </b-dropdown>
    <b-modal
      ref="modal"
      v-bind="modalInfo"
    >
      <b-overlay
        :show="busy"
        spinner-variant="primary"
        variant="white"
      >
        <b-form-group
          v-if="selectedMode === 'prompt'"
          label="Prompt"
        >
          <b-form-textarea
            v-model="promptText"
            placeholder="E.g. Ask the user if they need help with anything else."
          />
        </b-form-group>
        <b-form-group
          v-else
          label="Source"
        >
          <b-form-textarea
            class="bg-white"
            readonly
            :value="selectedText || textElValue"
          />
        </b-form-group>
        <b-form-group
          v-if="generatedText"
          label="Result"
        >
          <b-form-textarea
            class="bg-white"
            readonly
            :value="generatedText"
            @focus="$event.target.select()"
          />
        </b-form-group>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          :disabled="busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <template v-if="generatedText">
          <b-button
            class="mr-1"
            :disabled="busy || (isPrompt && !promptText)"
            @click="generateText"
          >
            Try again
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy"
            @click="acceptResult"
          >
            Accept
          </b-button>
        </template>
        <b-button
          v-else
          variant="primary"
          :disabled="busy || !promptText"
          @click="generateText"
        >
          Generate
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import endpoints from '@/js/urls';

export default {
  name: 'ResponseActivityEnhance',
  inheritAttrs: false,
  props: {
    textElement: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      generatedText: '',
      promptText: '',
      selectedMode: '',
      textElSelectionEnd: 0,
      textElSelectionStart: 0,
      textElValue: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['getRoutingLanguage']),
    improvementOptions() {
      const t = this.responseOrSelectionHeader;
      const options = [
        {
          color: 'success',
          desc: `Rewrite the ${t} to have a friendlier tone`,
          icon: 'star',
          text: `Make ${t} friendly`,
          type: 'friendly',
        },
        {
          color: 'danger',
          desc: `Rewrite the ${t} to be more clear and concise`,
          icon: 'minimize',
          text: `Make ${t} concise`,
          type: 'concise',
        },
        {
          color: 'info',
          desc: `Rewrite the ${t} to have a more formal tone`,
          icon: 'user-tie',
          text: `Make ${t} formal`,
          type: 'formal',
        },
        {
          color: 'primary',
          desc: 'Input a prompt and generate a full response',
          icon: 'align-left',
          text: 'Generate a response',
          type: 'prompt',
        },
      ];
      return options.filter((o) => (this.hasNoText ? o.type === 'prompt' : true));
    },
    hasNoText() {
      return !this.textElValue;
    },
    taskText() {
      if (!this.selectedMode) return '';
      const option = this.improvementOptions.find((o) => o.type === this.selectedMode);
      return option.text;
    },
    isPrompt() { return this.selectedMode === 'prompt'; },
    modalInfo() {
      return {
        title: this.taskText,
        scrollable: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
      };
    },
    responseOrSelectionHeader() {
      // determine if we are working with a whole response or a selection
      if (!this.hasNoText) return 'response';
      return 'selection';
    },
    selectedText() {
      return this.textElValue.slice(this.textElSelectionStart, this.textElSelectionEnd);
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    acceptResult() {
      this.$emit('result', this.generatedText);
      this.$refs.modal.hide();
    },
    async fetchAiResponse(mode, text) {
      try {
        const resp = await axios.post(endpoints.aiResponses,
          {
            mode,
            text,
            language: this.getRoutingLanguage,
          },
          {
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
        if (resp.data.error) {
          throw Error(resp.data.error);
        }
        return resp.data.text;
      } catch (error) {
        this.showWarning({
          title: 'Failed to create AI response',
          text: error.message,
          variant: 'danger',
        });
      }
      return null;
    },
    async generateText() {
      try {
        this.busy = true;
        const workingText = this.selectedMode === 'prompt'
          ? this.promptText
          : this.selectedText || this.textElValue;
        const result = await this.fetchAiResponse(this.selectedMode, workingText);
        if (!result || result.error) {
          this.generatedText = workingText;
        } else {
          this.generatedText = result;
        }
      } catch (error) {
        this.generatedText = error;
      } finally {
        this.busy = false;
      }
    },
    openModal(mode) {
      this.selectedMode = mode;
      this.$refs.modal.show();
      if (mode !== 'prompt') this.generateText();
    },
    resetData() {
      // reset as the dropdown opens
      this.busy = false;
      this.generatedText = '';
      this.promptText = '';
      this.selectedMode = '';
      this.textElSelectionStart = 0;
      this.textElSelectionEnd = 0;
      this.textElValue = '';
    },
    setSelected() {
      // run textElement prop function to get element with text
      const textElement = this.textElement();
      if (!textElement) return;

      // get selection and value from textelement
      const { selectionStart, selectionEnd, value } = textElement;
      this.textElSelectionStart = selectionStart;
      this.textElSelectionEnd = selectionEnd;
      this.textElValue = value;
    },
  },
};
</script>

<style scoped>
.icon-bg {
  opacity: 0.1;
}
.icon {
  opacity: 0.5;
}
</style>
