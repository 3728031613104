<template>
  <div>
    <b-form-group label="Name">
      <b-input v-model="name" />
    </b-form-group>

    <b-form-group label="Text">
      <quill-wrapper v-if="wysiwygEnabled" :text.sync="text" />
      <b-textarea v-else v-model="text" />
    </b-form-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import QuillWrapper from '@/components/QuillWrapper.vue';

export default {
  name: 'PhraseInput',
  components: {
    QuillWrapper,
  },
  props: {
    // This object contains only name and text
    phrase: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['wysiwygEnabled']),
    name: {
      get() {
        return this.phrase.name;
      },
      set(v) {
        this.$emit('input', { name: v, text: this.phrase.text });
      },
    },
    text: {
      get() {
        return this.phrase.text;
      },
      set(v) {
        this.$emit('input', { name: this.phrase.name, text: v });
      },
    },
  },
};
</script>
