<template>
  <b-modal
    id="new-user-modal"
    :title="modalTitle"
    :ok-title="okTitle"
    :ok-disabled="$v.modalContent.$invalid"
    @ok="createOrUpdateUser"
    @show="prepareUserModal"
  >
    <b-form>
      <b-form-group
        label="Username"
        label-for="usernameForm"
      >
        <b-form-input
          id="usernameForm"
          v-model="modalContent.username"
          type="text"
          autocomplete="username"
          :state="$v.modalContent.username.$invalid ? false : null"
          :disabled="mode === 'editExisting'"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.username.required">
            You must provide a username.
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.username.uniqueName">
            The username is already in use.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="First name"
        label-for="firstNameForm"
      >
        <b-form-input
          id="firstNameForm"
          v-model="modalContent.firstName"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="Last name"
        label-for="lastNameForm"
      >
        <b-form-input
          id="lastNameForm"
          v-model="modalContent.lastName"
          type="text"
        />
      </b-form-group>

      <b-form-group
        label="Email"
        label-for="emailForm"
        description="Optional: Supply an email-adress (used for password-resets)"
      >
        <b-form-input
          id="emailForm"
          v-model="modalContent.email"
          type="text"
          autocomplete="email"
          :state="$v.modalContent.email.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.email.looksLikeAnEmail">
            This does not look like an email to us.
          </div>
          <div v-if="!$v.modalContent.email.emailIsUnique">
            This email is already used by another (potentially locked) user.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password">
        <b-form-group>
          <b-form-input
            id="passwordForm"
            v-model="modalContent.password"
            type="password"
            autocomplete="new-password"
            placeholder="Type in a password"
            :disabled="mode === 'editExisting'"
            :state="$v.modalContent.password.$invalid ? false : null"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.modalContent.password.nonEmpty">
              Your must provide a password.
            </div>
            <div v-if="!$v.modalContent.password.minLength">
              Your password must be at least 8 characters long.
            </div>
            <div v-if="!$v.modalContent.password.notEntirelyNumeric">
              Your password cannot only contain numbers.
            </div>
          </b-form-invalid-feedback>

          <b-form-input
            id="confirmPasswordForm"
            v-model="modalContent.repeatPassword"
            type="password"
            class="mt-1"
            autocomplete="new-password"
            placeholder="Confirm password"
            :disabled="mode === 'editExisting'"
            :state="$v.modalContent.repeatPassword.$invalid ? false : null"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.modalContent.repeatPassword.sameAs">
              The two passwords must match
            </div>
          </b-form-invalid-feedback>
          <p v-if="mode === 'editExisting'">
            You are not allowed to set the user's new password.<br>
            However, you can refer the user to the
            <b-link
              target="_blank"
              rel="noopener noreferrer"
              :href="passwordResetURL"
            >
              BotStudio password reset page
            </b-link>
            where the user can request a password reset.
          </p>
        </b-form-group>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import {
  required, sameAs, minLength, email,
} from 'vuelidate/lib/validators';
import endpoints from '@/js/urls';

export default {
  name: 'AddUpdateUserModal',
  mixins: [validationMixin],
  props: {
    // Supported modes are 'editExisting', 'createNew'
    mode: {
      type: String,
      required: true,
    },
    // existingUserId needs only be set when editing an existing user
    existingUserId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      modalContent: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
      },
      // userEmailOnModalOpen should not change while the modal is opened.
      userEmailOnModalOpen: null,
    };
  },
  computed: {
    ...mapState('administration', ['users']),
    passwordResetURL() {
      return `${process.env.BASE_URL}accounts/password_reset/`;
    },
    modalTitle() {
      if (this.mode === 'editExisting') {
        return 'Update user';
      }
      return 'Create new user';
    },
    okTitle() {
      if (this.mode === 'editExisting') {
        return 'Update';
      }
      return 'Create';
    },
  },
  methods: {
    ...mapActions('administration', ['fetchUsers']),
    ...mapActions('sidebar', ['showWarning']),
    ...mapMutations('administration', { adminUpdateUser: 'updateUser' }),
    async prepareUserModal() {
      if (this.mode === 'createNew') {
        return;
      }
      // Fetch user by id
      const user = await this.fetchUser(this.existingUserId);
      this.modalContent.username = user.username;
      this.modalContent.firstName = user.first_name;
      this.modalContent.lastName = user.last_name;
      this.modalContent.email = user.email;
      this.userEmailOnModalOpen = user.email;
    },
    async fetchUser(userId) {
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      };
      const resp = await axios.get(endpoints.user + userId, config);
      return resp.data;
    },
    async createOrUpdateUser() {
      if (this.mode === 'editExisting') {
        this.updateUser();
      } else {
        this.createUser();
      }
    },
    /**
     * Currently we only support updating the user's email.
     * If the user needs a new password, he/she should use the password reset flow.
     */
    async updateUser() {
      try {
        const payload = {
          newEmail: this.modalContent.email,
          first_name: this.modalContent.firstName,
          last_name: this.modalContent.lastName,
        };
        const existingUserId = this.existingUserId;
        const userEndpoint = endpoints.user + existingUserId;
        const resp = await axios.put(userEndpoint, payload, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        if (resp.data.success) {
          this.adminUpdateUser({ id: resp.data.id, user: resp.data.result });
        }
      } catch (err) {
        this.showWarning({
          title: 'An error occured',
          text: 'Failed to update user.',
          variant: 'danger',
        });
        throw err;
      }
    },
    async createUser() {
      try {
        const payload = {
          username: this.modalContent.username,
          first_name: this.modalContent.firstName,
          last_name: this.modalContent.lastName,
          password: this.modalContent.password,
          email: this.modalContent.email,
        };
        const resp = await axios.post(endpoints.user, payload, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        if (resp.data.success) {
          // Reset modal state
          this.modalContent.username = '';
          this.modalContent.firstName = '';
          this.modalContent.lastName = '';
          this.modalContent.password = '';
          this.modalContent.repeatPassword = '';
          this.modalContent.email = '';

          this.adminUpdateUser({ id: resp.data.id, user: resp.data.result });
          this.$router.push({ name: 'admin-users-single', params: { userId: resp.data.id } });
        } else {
          this.showWarning({
            title: 'Password not accepted',
            text: resp.data.help_texts.join('  '),
            variant: 'danger',
          });
        }
      } catch (err) {
        this.showWarning({
          title: 'An error occured',
          text: 'Failed to create the user.',
          variant: 'danger',
        });
        throw err;
      }
    },
  },
  /**
   * The validations here are a best-effort at mimicking the validations carried out in all cases
   * in backend. They aid the user in "failing early", when they are about to enter a password that
   * will be rejected anyways in backend.
   * Most validations are "disabled" (by the validation-logic returning true) when the modal is
   * editing an existing user; this is the case on fields that are disabled for updating.
   */
  validations: {
    modalContent: {
      username: {
        required,
        uniqueName(username) {
          if (this.mode === 'editExisting') {
            return true;
          }
          return !Object.values(this.users).some(
            (x) => x.username.toLowerCase() === username.toLowerCase(),
          );
        },
      },
      password: {
        nonEmpty(input) {
          if (this.mode === 'editExisting') {
            return true;
          }
          return required(input);
        },
        minLength: minLength(8),
        notEntirelyNumeric: (input) => {
          const numericRegex = new RegExp('^\\d+$');
          return !input.match(numericRegex);
        },
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
      email: {
        looksLikeAnEmail(input) {
          return email(input);
        },
        emailIsUnique(input) {
          if (input === this.userEmailOnModalOpen) {
            // The input is allowed to be its initial value
            return true;
          }
          if (input === null || input.trim() === '') {
            // Providing an email is not required
            return true;
          }
          return !Object.values(this.users).some(
            (x) => x.email.toLowerCase() === input.toLowerCase(),
          );
        },
      },
    },
  },
};
</script>
