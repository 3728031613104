<template>
  <li :class="elementClass">
    <div class="li-content px-2">
      <span v-if="hasChildren" class="fa-li" @click="toggle">
        <font-awesome-icon :icon="toggleIcon" />
      </span>
      <div class="inline-block" @click="selectElement">
        <font-awesome-icon :icon="['far', 'folder']" />
        {{ element.text }}
      </div>
    </div>
    <template v-if="hasChildren">
      <ul v-if="open" class="fa-ul">
        <TreeSelectorElement
          v-for="(subtree, index) in element.children"
          :key="index"
          :element="subtree"
          :value="value"
          @input="emitValue"
        />
      </ul>
    </template>
  </li>
</template>
<script>

export default {
  name: 'TreeSelectorElement',
  props: {
    element: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array, String, Number],
      default: null,
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    hasChildren() {
      return this.element.children !== undefined && this.element.children.length > 0;
    },
    elementClass() {
      if (this.value === this.element.value) {
        return ['selected'];
      }
      return [];
    },
    toggleIcon() {
      return this.open ? 'angle-down' : 'angle-right';
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    emitValue(value) {
      this.$emit('input', value);
    },
    selectElement() {
      this.emitValue(this.element.value);
    },
  },
};
</script>
