<template>
  <div>
    <b-input-group>
      <template #append>
        <b-button
          v-b-tooltip.noninteractive.hover
          title="Refresh list of secrets"
          style="width: 36.75px"
          :disabled="isFetchingActiveBotSecrets"
          @click="fetchSecrets"
        >
          <font-awesome-icon
            v-if="!isFetchingActiveBotSecrets"
            icon="rotate-right"
          />
          <b-spinner
            v-else
            small
            style="border-radius: 50%; border-color: gray; border-right-color: rgba(0,0,0,0); "
          />
        </b-button>
        <slot name="append" />
      </template>
      <b-form-select
        v-model="localSecret"
        :options="existingKeys"
        :state="$v.localSecret.$invalid ? false : null"
        :disabled="isFetchingActiveBotSecrets"
        @input="secret => conditionalInput(secret)"
      />
    </b-input-group>
    <b-form-invalid-feedback
      v-if="existingKeys.length <= 1"
      id="nosecrets"
    >
      No valid secrets exist. You need to create one first.
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="!$v.localSecret.isNotNull"
      id="isnull"
    >
      Secret cannot be empty
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="!$v.localSecret.isDefined"
      id="isdefined"
    >
      Secret must exists
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';

export default {
  name: 'SecretSelector',
  mixins: [validationMixin],
  props: {
    value: {
      validator: (v) => typeof v === 'string' || v === null,
      required: true,
    },
    secretType: {
      type: String,
      default: 'encryption-jwe',
    },
    allowNull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSecret: null,
    };
  },
  computed: {
    ...mapGetters('botSecrets', [
      'getActiveBotSecrets',
      'isFetchingActiveBotSecrets',
    ]),
    existingKeys() {
      if (this.getActiveBotSecrets === undefined) {
        return [{ value: null, text: 'Loading secrets...' }];
      }
      const secretFiltered = this.getActiveBotSecrets.filter((s) => (
        s.secretType === this.secretType
      ));
      const secretsMapped = secretFiltered.map((botSecret) => ({
        value: botSecret.id,
        text: `🔑${botSecret.name}`,
      }));
      secretsMapped.unshift({ value: null, text: 'Choose a secret' });
      return secretsMapped;
    },
    cryptName() {
      if (this.getActiveBotSecrets === undefined || this.localSecret === null) {
        return 'None';
      }
      const secret = this.getActiveBotSecrets.find((e) => e.id === this.localSecret);
      if (!secret) {
        return 'Unknown';
      }
      return secret.name;
    },
  },
  async mounted() {
    this.localSecret = this.value;
    await this.fetchSecrets();
  },
  methods: {
    ...mapActions('botSecrets', [
      'fetchSecrets',
    ]),
    conditionalInput(value) {
      if (!this.$v.localSecret.$invalid) {
        this.$emit('input', value);
      }
    },
  },
  validations: {
    localSecret: {
      isNotNull(value) {
        return this.allowNull || value !== null;
      },
      isDefined(value) {
        return !!this.getActiveBotSecrets.find((e) => e.id === value);
      },
    },
  },
};
</script>
