<template>
  <div>
    <b-button
      v-b-tooltip.noninteractive.hover
      v-bind="$attrs"
      title="Generate short display name using AI"
      @click="openModal"
    >
      <font-awesome-icon icon="wand-magic-sparkles" />
      <span class="sr-only">
        Enhance or generate a response using AI
      </span>
    </b-button>
    <b-modal
      ref="modal"
      title="Generate short display name using AI"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-overlay
        :show="busy"
        spinner-variant="primary"
        variant="white"
      >
        <b-form-group
          label="Source"
        >
          <b-form-textarea
            class="bg-white"
            readonly
            :value="textElValue"
          />
        </b-form-group>
        <b-form-group
          v-if="generatedText"
          label="Result"
        >
          <b-form-textarea
            class="bg-white"
            readonly
            :value="generatedText"
            @focus="$event.target.select()"
          />
        </b-form-group>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          :disabled="busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <template v-if="generatedText">
          <b-button
            class="mr-1"
            :disabled="busy"
            @click="generateText"
          >
            Try again
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy"
            @click="acceptResult"
          >
            Accept
          </b-button>
        </template>
        <b-button
          v-else
          variant="primary"
          :disabled="busy || !promptText"
          @click="generateText"
        >
          Generate
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import endpoints from '@/js/urls';

export default {
  name: 'DisplayNameEnhance',
  inheritAttrs: false,
  props: {
    textElement: {
      type: Function,
      required: true,
    },
    defaultName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      generatedText: '',
      promptText: '',
      selectedMode: '',
      textElValue: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['getRoutingLanguage']),
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    acceptResult() {
      this.$emit('result', this.generatedText);
      this.$refs.modal.hide();
    },
    async fetchAiResponse(mode, text) {
      try {
        const resp = await axios.post(endpoints.aiResponses,
          {
            mode,
            text,
            language: this.getRoutingLanguage,
          },
          {
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
        if (resp.data.error) {
          throw Error(resp.data.error);
        }
        return resp.data.text;
      } catch (error) {
        this.showWarning({
          title: 'Failed to create AI response',
          text: error.message,
          variant: 'danger',
        });
      }
      return null;
    },
    async generateText() {
      try {
        this.busy = true;
        const result = await this.fetchAiResponse('concise (less than 5 words)', this.textElValue);
        if (!result || result.error) {
          this.generatedText = this.textElValue;
        } else {
          this.generatedText = result;
        }
      } catch (error) {
        this.generatedText = error;
      } finally {
        this.busy = false;
      }
    },
    openModal(mode) {
      this.resetData();
      this.setSelected();
      this.selectedMode = mode;
      this.$refs.modal.show();
      this.generateText();
    },
    resetData() {
      // reset as the dropdown opens
      this.busy = false;
      this.generatedText = '';
      this.promptText = '';
      this.textElSelectionStart = 0;
      this.textElSelectionEnd = 0;
      this.textElValue = '';
    },
    setSelected() {
      // run textElement prop function to get element with text
      const textElement = this.textElement();
      if (!textElement) return;

      this.textElValue = textElement.value ? textElement.value : this.defaultName;
    },
  },
};
</script>

<style scoped>
.icon-bg {
  opacity: 0.1;
}
.icon {
  opacity: 0.5;
}
</style>
