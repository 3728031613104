import { platforms } from '@/js/constants';

const suggestArticleMockResponse = {
  articles: [{
    external_id: 2,
    extract: 'In order to reset your password, you can go to http://app.com/login',
    id: 2,
    link: 'http://articles.com/login-issue',
    link_with_feedback: 'https://supsearch.mock/redirect/dummy-ID',
    pinned: false,
    score: 0.99,
    source: '',
    summary: null,
    title: 'Login issues',
  }],
  suggestionID: 'dummy-ID',
  useFeedback: false,
  textDescription: 'extract',
};

export default [
  {
    name: 'Suggest articles',
    description: 'Get article suggestions from SupSearch.',
    id: 'supsearch.suggestArticles',
    params: [
      { name: 'ranker', default: -1 },
      { name: 'query', default: 'thisMessage' },
      { name: 'presentedArticlesNumber', default: 1 },
      { name: 'articleThreshold', default: 80 },
      { name: 'useFeedback', default: true },
      { name: 'textDescription', default: 'extract' },
      { name: 'presentAs', default: 'message' },
      { name: 'responseMessage', default: 'I found the following article which could be helpful:' },
      { name: 'additionalMessage', default: 'I also found these relevant articles:' },
    ],
    extraParams: [{
      name: 'mockResponse',
      default: JSON.stringify(suggestArticleMockResponse),
    }],
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: 'suggestion_target',
    allowsExpansion: true,
  },
  {
    name: 'Suggestion feedback',
    description: 'Collect feedback on suggested articles.',
    id: 'supsearch.suggestionFeedback',
    params: [
      { name: 'suggestionId', default: 'suggestion_target["suggestionID"]' },
      { name: 'wasHelpful', default: '' },
    ],
    extraParams: [],
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },

  // This is a special Action for when an action is removed or in another branch
  {
    name: 'MISSING SUPWIZ ACTION',
    description: 'This SupWiz action is not supported in current version or no longer supported.',
    id: 'MISSING_SUPWIZ_ACTION',
    params: [],
    extraParams: [],
    supportedIn: [],
    excludeInNodes: [],
    target: null,
    allowsExpansion: false,
  },
];
