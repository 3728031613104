<template>
  <main>
    <b-button
      v-b-tooltip.hover
      variant="primary"
      :disabled="saveDisabled"
      :title="hasUnsavedChanges ? 'You have unsaved changes' : ''"
      @click="save"
    >
      <font-awesome-icon
        v-if="hasUnsavedChanges"
        icon="asterisk"
        transform="shrink-3"
      />
      {{ showConfirmation ? 'Changes saved!' : 'Save' }}
    </b-button>
  </main>
</template>

<script>
export default {
  name: 'SaveButton',
  props: {
    /**
     * Disable the button in these situations (maybe others too)
     * (A) If the configuration is invalidly configured, the user should not be able to save
     * (B) If the configuration has not changed
     */
    saveDisabled: {
      type: Boolean,
      required: true,
    },
    hasUnsavedChanges: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showConfirmation: false,
    };
  },
  methods: {
    save() {
      this.showConfirmation = true;
      setTimeout(() => { this.showConfirmation = false; }, 2000);
      this.$emit('save');
    },
  },
};
</script>
