<template>
  <main>
    <h5 class="font-weight-bold">
      Master nodes
    </h5>
    <p class="mb-2">
      Here you should specify the nodes which distribute questions to different intents. This is
      typically the parent of your first-level flow nodes.
    </p>
    <p>
      Each time an end-user visits a master node it is counted as one intent/question.
    </p>
    <p>
      Single-language bots typically have one master node whereas multi-language bots might
      have one for each language.
    </p>
    <h5 class="font-weight-bold">
      Opt-out nodes
    </h5>
    <p class="mb-2">
      Here you must specify the nodes that handle when a user actively chooses to stop
      the conversation.
    </p><p>
      For instance, nodes that handle when a user asks the bot to be transferred to a human
      agent, such nodes should go into the "Opt-out nodes" section.
    </p>

    <h5 class="font-weight-bold mt-3">
      Flow nodes
    </h5>
    <p class="mb-2">
      Here you should specify the top level nodes that are entry points to your specific flows, that
      can actually help/solve a users problem/request.
    </p>
    <p class="mb-2">
      Nodes that should <em>not</em> go in here are nodes that handle "None of the above" or nodes
      that have a non-specific "catch-all" or fallback nature.
    </p>
    <p>
      Putting some node A in "Flow nodes" section means that conversations going through
      node A will be counted as 'covered conversation' in the Statistics page.
    </p>

    <h5 class="font-weight-bold mt-3">
      Transfer nodes
    </h5>
    <p>
      Here you should specify the nodes that are visited when subsequent handling of the user's
      issue/request is handed over to another mechanism, such as:
      <ul>
        <li>
          the conversation is transferred to another queue, typically a queue operated by human
          agents,
        </li>
        <li>
          a ticket is created on behalf of the user for later processing by staff,
        </li>
        <li>
          the user is presented with additional means of contact (for instance email or phone),
          which the user can use to report or check up on his/her issue.
        </li>
      </ul>
      Depending on your setup you may have several or only a single node that handles handover.
      You should specify all such nodes.
    </p>
  </main>
</template>

<script>
export default {
  name: 'NodeInterpretationsDescription',
};
</script>
