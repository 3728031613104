import { render, staticRenderFns } from "./TrainingPage.vue?vue&type=template&id=7fb9c985&scoped=true"
import script from "./TrainingPage.vue?vue&type=script&lang=js"
export * from "./TrainingPage.vue?vue&type=script&lang=js"
import style0 from "./TrainingPage.vue?vue&type=style&index=0&id=7fb9c985&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb9c985",
  null
  
)

export default component.exports