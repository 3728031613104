<template>
  <main>
    <b-card
      title="Labeling log"
      class="r-75"
      body-class="p-3"
    >
      <div>
        Here you can view and relabel previously labeled datapoints.
      </div>
      <b-row class="mt-3">
        <b-col cols="6">
          <b-input-group
            prepend="Category"
          >
            <b-form-select
              v-model="selectedMainCategory"
              :options="mainCategories"
              @change="refreshTable"
            />
          </b-input-group>
        </b-col>
        <b-col
          v-if="subcategoriesForSelectedMainCategory.length > 0"
          cols="6"
        >
          <b-input-group
            prepend="Subcategory"
          >
            <b-form-select
              v-model="selectedSubCategory"
              :options="subcategoriesForSelectedMainCategory"
              @change="refreshTable"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-pagination
        v-if="pagination.totalRows > pagination.itemsPerPage"
        v-model="pagination.currentPage"
        :total-rows="pagination.totalRows"
        class="mb-1"
        :per-page="pagination.itemsPerPage"
      />
      <b-table
        ref="recentMostLabelsTable"
        :items="tableProvider"
        hover
        class="mb-1"
        striped
        small
        :fields="assignedLabelsTableFields"
        :per-page="pagination.itemsPerPage"
        :current-page="pagination.currentPage"
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        @row-clicked="rowClicked"
      >
        <template #cell(assigned_categories)="row">
          <assigned-labels :assigned-categories="row.item.assigned_categories" />
        </template>
      </b-table>
      <b-pagination
        v-if="pagination.totalRows > pagination.itemsPerPage"
        v-model="pagination.currentPage"
        class="mb-0"
        :total-rows="pagination.totalRows"
        :per-page="pagination.itemsPerPage"
      />
    </b-card>
    <b-modal
      id="assign-labels-modal"
      size="xl"
      hide-footer
    >
      <template v-if="modalLabel">
        <data-exploration-data-point-card
          :data-point="modalLabel.datapoint"
          :dataset-id="datasetId"
          :dataset-type="modalLabel.datapoint.type"
          @deleted-datapoint="handleDeletion"
          @edited-datapoint="handleEdit"
          @input="v => data.text = v"
        />
        <data-exploration-assign-labels
          :dataset-id="datasetId"
          :data-id="modalLabel.datapoint.id"
          :data-type="modalLabel.datapoint.type"
          :submit-should-update-existing-labels="true"
          :already-assigned-labels="modalLabel.assigned_categories"
          @submitted="onSubmission"
        />
      </template>
    </b-modal>
  </main>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import endpoints from '@/js/urls';
import DataExplorationAssignLabels from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationAssignLabels.vue';
import DataExplorationDataPointCard from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationDataPointCard.vue';
import AssignedLabels from '@/pages/DataExploration/DataExplorationLabeling/AssignedLabels.vue';

export default {
  name: 'DataExplorationLabelingLog',
  components: {
    DataExplorationAssignLabels,
    AssignedLabels,
    DataExplorationDataPointCard,
  },
  data() {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        totalRows: 0,
      },
      assignedLabelsTableFields: [
        {
          key: 'datapoint',
          label: 'Text',
          formatter: (value) => value.text,
        },
        {
          key: 'assigned_categories',
          label: 'Current label(s)',
        },
      ],
      selectedMainCategory: null,
      selectedSubCategory: null,
      fetchedMainCategoryId: null,
      fetchedSubCategoryId: null,
      modalLabel: null,
    };
  },
  computed: {
    ...mapGetters('dataExploration/categories', [
      'getMainCategories',
      'getSubcategoriesFromParents',
      'getSubcategoriesForParent',
    ]),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    mainCategories() {
      const options = this.getMainCategories.map((mainCategory) => (
        {
          value: mainCategory,
          text: mainCategory.name,
        }
      ));
      options.unshift({
        value: null,
        text: 'All categories',
      });
      return options;
    },
    subcategoriesForSelectedMainCategory() {
      if (this.selectedMainCategory === null) {
        return [];
      }
      const options = this.getSubcategoriesForParent(this.selectedMainCategory.id)
        .map((subcategory) => ({
          value: subcategory,
          text: subcategory.name,
        }));
      options.unshift(
        {
          // The null-valued option is the one the dropdown will choose before another one is
          // actively chosen by user.
          value: null,
          text: 'All subcategories',
        },
        {
          value: 'NO_SUBCATEGORY_ON_LABEL',
          text: 'No subcategory assigned',
        },
      );
      return options;
    },
  },
  methods: {
    async tableProvider(context) {
      let desiredPage;
      if ((this.selectedMainCategory && this.selectedMainCategory.id !== this.fetchedMainCategoryId)
        || (this.selectedSubCategory && this.selectedSubCategory.id !== this.fetchedSubCategoryId)
      ) {
        this.pagination.currentPage = 1;
        desiredPage = 1;
      } else {
        desiredPage = context.currentPage;
      }
      const itemsPerPage = context.perPage;
      const params = {
        dataset_id: this.datasetId,
        page: desiredPage,
        items_per_page: itemsPerPage,
      };
      if (this.selectedMainCategory !== null) {
        params.parent_category_id = this.selectedMainCategory.id;
      }
      if (this.selectedMainCategory !== null && this.selectedSubCategory === null) {
        // We map the default-option for dropdown ("All categories") to an actual
        // value ("_ALL") for backend
        params.sub_category_id = '__ALL';
      } else if (this.selectedMainCategory !== null
          && this.selectedSubCategory === 'NO_SUBCATEGORY_ON_LABEL') {
        params.sub_category_id = 'NO_SUBCATEGORY_ON_LABEL';
      } else if (this.selectedSubCategory !== null) {
        params.sub_category_id = this.selectedSubCategory.id;
      }
      const config = {
        headers: {
          Authorization: `JWT ${this.$store.state.auth.jwt}`,
        },
        params,
      };
      const response = await axios.get(endpoints.dataExplorationLabel, config);
      this.pagination.totalRows = response.data.total_number_of_entries;
      this.fetchedMainCategoryId = this.selectedMainCategory ? this.selectedMainCategory.id : null;
      this.fetchedSubCategoryId = this.selectedSubCategory ? this.selectedSubCategory.id : null;
      return response.data.entries;
    },
    refreshTable() {
      this.$refs.recentMostLabelsTable.refresh();
    },
    rowClicked(item) {
      this.modalLabel = item;
      this.$bvModal.show('assign-labels-modal');
    },
    onSubmission() {
      this.refreshTable();
      this.$bvModal.hide('assign-labels-modal');
    },
    handleDeletion() {
      this.$bvModal.hide('assign-labels-modal');
      this.refreshTable();
    },
    handleEdit() {
      this.refreshTable();
    },
  },
};
</script>
