<template>
  <div>
    <p class="mb-2">
      <strong>Phrase building blocks</strong> provides a way to define and manage re-useable phrases
      in a single, simple view.
    </p>
    <p class="mb-2">
      Phrases are particularly useful for texts you use many times throughout the bot such as
      greetings, opening hours, and more.
      <br>
      By using phrases you can easily update all such texts at one time.
    </p>
    <p>
      The screenshots below illustrate how one can create a phrase (left) and use it to build a
      response in the bot (right).
    </p>
    <b-row>
      <b-col cols="6">
        <b-img
          fluid
          :src="require(`@/assets/featureImages/phrase_create.png`)"
          alt="Example of creating a phrase and the overview UI"
        />
      </b-col>
      <b-col cols="6">
        <b-img
          fluid
          :src="require(`@/assets/featureImages/phrase_usage.png`)"
          alt="Example of using a phrase in a response activity"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default { name: 'PhraseBlocksDescription' };
</script>
