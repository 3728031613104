<template>
  <b-form-group
    :label="label"
    :label-for="`${label}-form`"
    :description="description"
  >
    <b-dropdown
      :id="`${label}-form`"
      class="w-100"
      lazy
      :disabled="disabled"
      right
      no-caret
      :toggle-class="`${labelInvalid ? 'border-danger' : ''} options-dropdown w-100 text-truncate text-left`"
      menu-class="select-menu bg-white text-dark pb-0"
      @hide="searchKeyword = ''"
    >
      <template #button-content>
        <b-row>
          <b-col>
            {{ nameOfAnyId(selectedItem) }}
          </b-col>
          <b-col cols="auto">
            <font-awesome-icon icon="caret-down" />
          </b-col>
        </b-row>
      </template>
      <b-dropdown-form>
        <b-input
          v-model="searchKeyword"
          autofocus
          debounce="250"
          placeholder="Type to search"
        />
      </b-dropdown-form>
      <b-dropdown-divider />
      <div class="select-options w-100">
        <div
          v-for="(item, itemIndex) in filteredItems"
          :key="itemIndex"
        >
          <template v-if="item.label">
            <b-dropdown-header>
              {{ item.label }}
            </b-dropdown-header>
            <b-dropdown-item
              v-for="(option, optionIndex) in item.options"
              :key="optionIndex"
              @click="$emit('click', option.value)"
            >
              {{ option.text }}
            </b-dropdown-item>
          </template>
          <b-dropdown-item
            v-else
            @click="$emit('click', item.value)"
          >
            {{ item.text }}
          </b-dropdown-item>
        </div>
      </div>
    </b-dropdown>
  </b-form-group>
</template>
<script>
export default {
  name: 'BotNodeSelectorSearch',
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelInvalid: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      default: null,
      type: String,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchKeyword: '',
    };
  },
  computed: {
    filteredItems() {
      return this.items.map((item) => {
        if (item.options) {
          const filteredOptions = item.options.filter((o) => o.text.toLowerCase()
            .includes(this.searchKeyword.toLowerCase()));
          if (filteredOptions.length > 0) {
            return { ...item, options: filteredOptions };
          }
        } else if (item.text.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
          return item;
        }
        return null;
      }).filter(Boolean);
    },
  },
  methods: {
    nameOfAnyId(id) {
      const optionsDict = {};
      this.items.forEach((item) => {
        if (item.value !== undefined) {
          optionsDict[item.value] = item.text;
        } else {
          item.options.forEach((option) => {
            optionsDict[option.value] = option.text;
          });
        }
      });
      return optionsDict[id];
    },
  },
};

</script>
<style scoped>
::v-deep .select-menu{
  max-height: 400px;
  width: 100%;
  overflow-y:auto;
  overflow-x: hidden;
}
:deep(.options-dropdown) {
  background-color: white !important;
  color: #111f2d !important;
}
:deep(.options-dropdown:hover), :deep(.options-dropdown:focus), :deep(.options-dropdown:active) {
  box-shadow: inset 0 0 2px 2px #70d3ff;
}
:deep(.select-options) {
  max-height: 180px;
  max-width: 600px;
  min-width: 500px;
  overflow-y: auto;
}
</style>
