<template>
  <b-modal
    id="new-group-modal"
    title="Create new group"
    ok-title="Create"
    :ok-disabled="$v.groupName.$invalid"
    @ok="createGroup"
  >
    <b-form>
      <b-form-group
        label="Group name"
        label-for="groupNameForm"
      >
        <b-form-input
          id="groupNameForm"
          v-model="groupName"
          type="text"
          :state="$v.groupName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.groupName.required">
            You must provide a group name
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.groupName.uniqueName">
            The group name is already in use
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.groupName.reservedName">
            The group name is reserved
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import endpoints from '@/js/urls';
import { usertypes } from '@/js/constants';

export default {
  name: 'AddGroupModal',
  mixins: [validationMixin],
  data() {
    return {
      groupName: '',
    };
  },
  computed: {
    ...mapState('administration', ['groups']),
    usertypes() {
      return usertypes;
    },
  },
  methods: {
    ...mapActions('administration', ['fetchGroups']),
    ...mapActions('sidebar', ['showWarning']),
    ...mapMutations('administration', ['updateGroup']),
    async createGroup() {
      try {
        const payload = {
          name: this.groupName,
        };
        const resp = await axios.post(endpoints.group, payload, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        // Reset modal state
        this.groupName = '';
        this.updateGroup({ id: resp.data.id, group: resp.data });
        this.$router.push({ name: 'admin-groups-single', params: { groupId: resp.data.id } });
      } catch (err) {
        this.showWarning({
          title: 'An error occured',
          text: 'Failed to create the group.',
          variant: 'danger',
        });
        throw err;
      }
    },
  },
  validations: {
    groupName: {
      required,
      uniqueName(groupName) {
        return !Object.values(this.groups).some(
          (x) => x.name.toLowerCase() === groupName.toLowerCase(),
        );
      },
      reservedName(groupName) {
        return !Object.values(this.usertypes).some(
          (x) => x.toLowerCase() === groupName.toLowerCase(),
        );
      },
    },
  },
};
</script>
