<template>
  <b-modal
    id="deleteSecretsWarningModal"
    :title="modalTitle"
    ok-title="Delete secret"
    :ok-disabled="deleteButtonShouldBeDisabled"
    ok-variant="danger"
    @ok="deleteSecretWrapper"
  >
    <!-- Show nodes containing integrations that reference the secret -->
    <template
      v-if="Object.values(getMainflowNodesReferencingSecret(toDeleteId)).length > 0"
    >
      <p
        class="mb-3"
      >
        {{ secretName }} is currently referenced in one or more integrations in the nodes below.
      </p>
      <b-btn
        v-for="(nodeName, nodeId) in getMainflowNodesReferencingSecret(toDeleteId)"
        :key="nodeId"
        block
        variant="primary"
        :to="editNodeLink(nodeId)"
      >
        {{ nodeName }}
      </b-btn>
      <p>
        In order to delete the secret, you must first
        <ol>
          <li>
            update the integration blueprint
          </li>
          <li>
            and then update the integrations based on that integration blueprint.
          </li>
        </ol>
      </p>
    </template>

    <template
      v-if="Object.values(subflowNodesReferencingSecrets(toDeleteId)).length > 0"
    >
      <p
        class="mb-3"
      >
        {{ secretName }} is currently referenced in one or more integrations in the subflows below.
      </p>
      <b-btn
        v-for="(entry, subflowNodeId) in subflowNodesReferencingSecrets(toDeleteId)"
        :key="subflowNodeId"
        block
        variant="primary"
        :to="editNodeLink(subflowNodeId, entry.subflowId)"
      >
        {{ entry.name }}
      </b-btn>
      <p>
        In order to delete the secret, you must first
        <ol>
          <li>
            update the integration blueprint in the subflow
          </li>
          <li>
            and then update the integrations based on that integration blueprint, in the subflow.
          </li>
        </ol>
      </p>
    </template>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'DeleteSecretsWarningModal',
  mixins: [validationMixin],
  props: {
    toDeleteId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState('botManipulation/activeBot', [
      'nodes',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
      'getMainflowNodesReferencingSecret',
      'subflowNodesReferencingSecrets',
    ]),
    deleteButtonShouldBeDisabled() {
      return Object.keys(this.getMainflowNodesReferencingSecret(this.toDeleteId)).length > 0
        || Object.keys(this.subflowNodesReferencingSecrets(this.toDeleteId)).length > 0;
    },
    secretName() {
      const secretToDelete = this.secretToDelete;
      if (secretToDelete === null) {
        return '';
      }
      return secretToDelete.name;
    },
    secretToDelete() {
      if (this.toDeleteId === null) {
        return null;
      }
      const botSecrets = this.getBotSecrets(this.activeBotId);
      const secretToDelete = botSecrets.find((x) => x.id === this.toDeleteId);
      return secretToDelete;
    },
    modalTitle() {
      const botSecrets = this.getBotSecrets(this.activeBotId);
      if (botSecrets === undefined || this.toDeleteId === null) {
        return '';
      }
      const secretToDelete = botSecrets.find((x) => x.id === this.toDeleteId);
      if (secretToDelete === undefined) {
        return '';
      }
      return `Confirm deletion of secret: ${secretToDelete.name}`;
    },
  },
  methods: {
    ...mapActions('botSecrets', [
      'deleteSecret',
      'fetchSecrets',
    ]),
    async deleteSecretWrapper() {
      await this.deleteSecret({
        botId: this.activeBotId,
        secretId: this.toDeleteId,
      });
      await this.fetchSecrets();
    },
  },
  validations: {
    toDeleteId: {
      required,
    },
  },
};
</script>
