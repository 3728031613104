<template>
  <div>
    <p class="mb-2">
      <strong>Automatic labels</strong> for your bots' NLU has been <em>significantly</em> improved.
    </p>
    <p>
      We advice you to turn on automatic labels for all of your classifiers to automatically improve
      the performance of your bot(s).
    </p>
  </div>
</template>
<script>
export default { name: 'IntegrationErrorContinueDescription' };
</script>
