<template>
  <div
    class="customer mr-2"
    :class="selectedForReplay ? 'bg-info' : ''"
    @click="selectForReplay"
  >
    <div
      v-if="showName"
      class="messageContent name text-right"
    >
      <b-btn
        v-if="hasAudio"
        v-b-tooltip.noninteractive
        variant="muted"
        class="p-0"
        size="sm"
        title="Play this message"
        @click.stop="audioObjectIsPlaying
          ? pauseUserMessage()
          : playUserMessageClicked()"
      >
        <font-awesome-icon
          :icon="`${audioObjectIsPlaying ? 'pause' : 'play'}-circle`"
        />
      </b-btn>
      You
    </div>
    <p
      class="messageContent mb-0"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'UserMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      required: true,
    },
    selectedForReplay: {
      type: Boolean,
      required: true,
    },
    audioUrl: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      audioObjectIsPlaying: false,
      audioObject: null,
    };
  },
  computed: {
    ...mapGetters('audio', [
      'isItMyTurnToPlayAudio',
    ]),
    hasAudio() {
      return this.audioUrl !== null;
    },
    shouldPlayAudio() {
      return this.isItMyTurnToPlayAudio(this._uid) && this.audioLoaded;
    },
  },
  destroyed() {
    if (this.audioObjectIsPlaying) this.pauseUserMessage();
    this.unregisterComponent({ componentId: this._uid });
  },
  methods: {
    ...mapActions('audio', [
      'reserveSpotInPlaybackQueue',
      'componentEndedPlayback',
      'unregisterComponent',
    ]),
    ...mapMutations('audio', [
      'clearQueue',
    ]),
    selectForReplay() {
      this.$emit('pickMeForReplay');
    },
    updateAudioObjectIsPlaying(newValue) {
      this.audioObjectIsPlaying = newValue;
    },
    pauseUserMessage() {
      this.audioObject.pause();
    },
    playUserMessage() {
      if (this.audioObject === null) {
        this.audioObject = new Audio(this.audioUrl);
        this.audioObject.addEventListener('ended', () => this.playbackEnded());
        this.audioObject.addEventListener('ended', () => this.updateAudioObjectIsPlaying(false));
        this.audioObject.addEventListener('pause', () => this.updateAudioObjectIsPlaying(false));
        this.audioObject.addEventListener('play', () => this.updateAudioObjectIsPlaying(true));
      }
      this.audioObject.play();
    },
    playUserMessageClicked() {
      if (this.audioObject !== null && this.audioObject.paused) {
        // The audio was paused, the user now wishes the audio to continue playing
        this.audioObject.play();
        return;
      }

      // Put this message in queue front (future will tell if below approach is too crude)
      this.clearQueue();
      this.reserveSpotInPlaybackQueue({
        componentId: this._uid,
      });

      this.playUserMessage();
    },
    playbackEnded() {
      this.componentEndedPlayback({ componentId: this._uid });
    },
  },
};
</script>

<style scoped>
  .customer {
    background: rgba(244, 244, 244, 0.65);
    cursor: pointer;
    border-radius: 15px;
    width: max-content;
    margin-right: 4px;
    max-width: 90%;
    margin-left: auto;
  }

</style>
