<template>
  <quill-editor
    ref="editor"
    v-model="localValue"
    :options="quillOptions"
    @ready="onEditorReady($event)"
    @blur="onBlur"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import 'quill-paste-smart';
import { wysiwygConf } from '@/js/constants';

export default {
  name: 'QuillWrapper',
  components: {
    quillEditor,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'wysiwygEnabled', 'getPlatforms',
    ]),
    quillOptions() {
      // eslint-disable-next-line no-unused-vars
      const { modules, formats } = wysiwygConf(this.getPlatforms);
      return {
        modules,
      };
    },
    localValue: {
      get() {
        return this.text;
      },
      set(v) {
        this.$emit('update:text', v);
      },
    },
  },
  methods: {
    onEditorReady(e) {
      this.$refs.editor.quill.root.innerHTML = this.text;
      // eslint-disable-next-line no-param-reassign
      e.history.stack.undo = [];
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
  },
};
</script>
<style>
.quill-editor strong{
  font-weight: bold !important;
}
.quill-editor p{
  margin-bottom: 1em !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}
</style>
