<template>
  <div>
    <h3>Initialization</h3>
    <b-card
      no-body
    >
      <p class="mb-2">
        Here you can initialize variables before the conversation starts.
        You can use that for easily changing REST end points by using variables in the hostname.
      </p>
      <p>
        Note it is very limited what is allowed.
      </p>
    </b-card>
    <hr>
    <h4
      class="mt-3"
    >
      Initialization Activities
    </h4>

    <node-edit-activities-content
      node-id="init"
      :allowed-activities="allowedActivities"
    />
  </div>
</template>

<script>
import NodeEditActivitiesContent from '@/pages/EditNode/NodeEditActivitiesContent.vue';

import {
  SET_VARIABLE, CONTROL_FLOW, METRIC_SIGNAL,
} from '@/js/activity';

export default {
  name: 'EditInitialization',
  components: {
    NodeEditActivitiesContent,
  },
  data() {
    return {
      allowedActivities: new Set([SET_VARIABLE, CONTROL_FLOW, METRIC_SIGNAL]),
    };
  },
};
</script>

<style scoped>

</style>
