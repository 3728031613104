<template>
  <b-card
    class="r-75"
    body-class="p-3"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <SingleSignOn
      :id="ssoConfigIds.CUSTOMER"
      :django-groups="djangoGroups"
      :methods="ssoMethods"
      @unsavedChanges="v => unsavedChanges = v"
    />
  </b-card>
</template>
<script>
import SingleSignOn from 'supwiz/components/SingleSignOn.vue';
import endpoints from '@/js/urls';
import { snakeCaseToText } from 'supwiz/util/data';
import { ssoConfigIds, createSsoMethods } from 'supwiz/util/sso';
import axios from 'axios';

export default {
  name: 'SingleSignOnWrapper',
  components: {
    SingleSignOn,
  },
  data() {
    return {
      unsavedChanges: false,
      groups: [],
      ssoConfigIds,
    };
  },
  computed: {
    djangoGroups() {
      return Object.values(this.groups).map(
        (e) => ({ value: e.id, text: snakeCaseToText(e.name) }),
      );
    },
    ssoMethods() {
      return createSsoMethods(axios, endpoints, this.$store.state.auth.jwt);
    },
  },
  async created() {
    await this.fetchAllGroups();
  },
  methods: {
    async fetchAllGroups() {
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { exclude_usertypes: false },
      };
      const resp = await axios.get(endpoints.group, config);
      this.groups = resp.data;
    },
  },
};

</script>
