<template>
  <b-list-group-item>
    <b-row
      align-v="center"
      align-h="between"
      :style="{ cursor: 'pointer' }"
      @click="showDetails = !showDetails"
    >
      <b-col cols="11">
        <h5 class="break-text mb-0">
          <b-link :to="link">
            Configuration of default Smart nodes Responses
          </b-link>
        </h5>
      </b-col>
      <b-col
        cols="1"
        class="text-right"
      >
        <h5 class="mr-1 mb-0">
          <font-awesome-icon :icon="showDetails ? 'angle-up' : 'angle-down'" />
        </h5>
      </b-col>
    </b-row>
    <b-collapse
      v-model="showDetails"
      class="mt-3"
    >
      <edit-smart-node
        responses-only
      />
    </b-collapse>
  </b-list-group-item>
</template>

<script>
import EditSmartNode from '@/pages/BotConfig/EditSmartNode.vue';

export default {
  name: 'ResponsesForConfigSmartNodes',
  components: {
    EditSmartNode,
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    link() {
      const botId = this.$route.params.botId;
      return { name: 'config', params: { botId }, hash: '#smart-nodes' };
    },
  },
};

</script>
