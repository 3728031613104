<template>
  <div>
    <p class="mb-2">
      Below is a list of nodes that are used as options in smart nodes or
      multiple choice nodes, but which do not have matching configured.
    </p>
    <node-list
      :nodes="nodeList"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NodeList from '@/components/NodeList.vue';

export default {
  name: 'ChildrenMissingExamples',
  components: {
    NodeList,
  },
  props: {
    insightDetails: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    nodeList() {
      return this.insightDetails.failing_nodes.map((nodeId) => this.nodeById(nodeId));
    },
  },
};
</script>
