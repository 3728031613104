<template>
  <div>
    <div
      v-for="(item, index) in chatData"
      :key="item.node.node + index"
    >
      <div class="d-block d-flex">
        <div
          class="border-right flowcolumn justify-content-start align-items-start d-flex flex-column"
          :style="showLog ? '' : 'margin-left: auto !important; min-width: 50% !important; max-width: 50% !important;'"
        >
          <node-with-labels
            v-if="!item.hide && showInTable(index)"
            :current-node="item"
            :show-arrow="index !== chatData.length - 1"
            :show-label="showOldLabel(index)"
            :show-top-prediction="showTopPrediction(index)"
          />
        </div>

        <div v-if="showLog" class="logcolumn ml-1 p-1">
          <div v-if="item.disabled && item.events.length" class="chat-overlay" />
          <div
            v-for="(event, eIndex) in item.events"
            :key="eIndex"
            class="d-flex flex-column"
            :class="isCustomer(event.data.author) ? 'align-items-end' : 'align-items-start'"
          >
            <div class="d-flex">
              <div
                class="bubble p-2 mb-1"
                :class="getMessageClass(event, index, item.disabled)"
              >
                <div class="name pt-0">
                  {{ event.data.author === 'agent' ? 'Bot' : 'Customer' }}
                </div>
                <!-- eslint-disable-next-line vue/no-v-html-->
                <div v-html="formatChatlogText(event.data.query)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(label, labelIndex) in getLabels(index, item.labels)"
        :key="labelIndex + label.id"
        class="w-100 d-block d-flex border-right flowcolumn justify-content-start align-items-start d-flex flex-column"
        :style="showLog ? '' : 'margin-left: auto !important; min-width: 50% !important; max-width: 50% !important;'"
      >
        <b-row v-if="label.id === 'select'" class="w-100">
          <b-col
            cols="12"
            class="px-auto text-center"
            style="margin-right: 36px;"
          >
            <b-select
              :options="getSelectOptions(item.labels[labelIndex - 1].label)"
              class="w-50"
              :value="null"
              style="min-width: 150px; max-width: 350px;"
              @input="v => $emit('nextLabel', { chatId, index, nodeId: v })"
            />
          </b-col>
        </b-row>
        <b-row v-else-if="showInTable(index)" class="w-100 text-center" no-gutters>
          <b-col style="width: calc(100% - 36px)">
            <div
              class="cursor-pointer node-name mx-auto bg-primary text-white text-truncate"
              style="max-width:90%"
              pill
              @click="goToNode(label.label)"
            >
              {{ getName(label.label) }}
            </div>
          </b-col>

          <b-col cols="auto" style="width: 36px;">
            <b-dropdown
              v-b-tooltip.hover.viewport="'Click to edit'"
              class="mr-2"
              variant="primary"
              menu-class="labels-dropdown"
              no-caret
              no-flip
              :right="!showLog"
              @hide="labelFilter = ''"
            >
              <template #button-content>
                <font-awesome-icon icon="pencil-alt" />
              </template>
              <b-dropdown-form v-if="getDropdownOptions(index, labelIndex).length > 10" form-class="px-3 py-2">
                <b-form-input v-model="labelFilter" placeholder="Type to search" />
              </b-dropdown-form>

              <template v-if="showResetButton(index, label)">
                <b-dropdown-item @click="resetLabel(chatId, index)">
                  Reset label
                </b-dropdown-item>
                <b-dropdown-divider />
              </template>
              <div style="max-height:200px; min-height:50px; overflow-y:auto;">
                <b-dropdown-item v-if="!getFilteredDropdownOptions(index, labelIndex).length">
                  There are no items to show
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(child, childIndex) in getFilteredDropdownOptions(index, labelIndex)"
                  v-else
                  :key="childIndex"
                  @click="newLabel(chatId, index, labelIndex, child.value)"
                >
                  {{ child.text }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="w-100" no-gutters>
          <b-col
            v-if="showArrow(index, item, labelIndex)"
            style="margin-right: 36px;"
          >
            <font-awesome-icon
              color="grey"
              class="d-block w-100 my-2"
              icon="arrow-down"
            />
            <div v-if="showLabelPrediction(index, labelIndex)" class="prediction-badge r-50 text-white">
              {{ getTopLabelPrediction(index, labelIndex) }}
            </div>
          </b-col>
          <b-col
            v-else-if="label.id !== 'select'"
            class="text-center"
            style="margin-right: 36px;"
          >
            <b-badge class="py-1 mt-1" pill>
              End of labeling
            </b-badge>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import NodeWithLabels from '@/pages/Training/NodeWithLabels.vue';
import { formatChatlogText, getTopPrediction } from '@/js/utils';

export default {
  name: 'LabelContent',
  components: { NodeWithLabels },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    queryLabel: {
      type: Object,
      required: true,
    },
    chatId: {
      type: String,
      required: true,
    },
    showLog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      labelFilter: '',
      localHighlightedQuery: null,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nameOfId',
      'nodesAsList',
    ]),
    ...mapState('nodeLabels', [
      'focusNode',
    ]),
    focusNodeIndex() {
      return this.chatData.findIndex((e) => e.node.node === this.focusNode);
    },
    activeQuery() {
      const nodeId = this.chatData[this.focusNodeIndex].node.node;
      return this.queryLabel.nodelabelitems.find((e) => e.node_id === nodeId).query;
    },
    highlightedQuery() {
      if (this.localHighlightedQuery) {
        return this.localHighlightedQuery;
      }
      const labeledDataIndex = this.chatData.findIndex((e) => (e?.labels || [])
        .some((l) => l.is_automatic !== undefined && !l.is_automatic),
      );
      const labeledDataElement = this.chatData[labeledDataIndex];
      const nextElementDisabled = this.chatData[labeledDataIndex + 1]?.disabled;
      const isLabeled = !!labeledDataElement;
      if (isLabeled && nextElementDisabled) {
        return labeledDataElement?.query;
      }
      return null;
    },
  },
  methods: {
    formatChatlogText,
    showOldLabel(index) {
      const item = this.chatData[index];
      const nextItem = this.chatData[index + 1];
      return !!(nextItem && item.labels?.length
      && item.labels[0].label !== nextItem.node.node);
    },
    showTopPrediction(index) {
      return !!this.chatData[index]?.labels?.length;
    },
    getLabels(index, labels) {
      const alreadyLabeled = this.chatData.slice(0, index).map((e) => e.labels?.length)
        .filter((l) => l > 1);
      return alreadyLabeled.length ? [] : labels;
    },
    getSelectOptions(nodeId) {
      return [{ value: null, text: 'Select next node', disabled: true }].concat(this.getNodeChildren(nodeId));
    },
    getName(nodeId) {
      const foundItem = this.chatData.find((e) => e.node.node === nodeId);
      if (this.nodeById(nodeId) || !foundItem) {
        return this.nameOfId(nodeId);
      }
      return `${foundItem.node.display_name} (deleted)`;
    },
    getFilteredDropdownOptions(index, labelIndex) {
      if (labelIndex === 0) {
        return this.getNodeChildren(this.chatData[index].node.node)
          .filter((e) => e.text.toLowerCase().includes(this.labelFilter.toLowerCase()));
      }
      return this.getNodeChildren(this.chatData[index].labels[labelIndex - 1].label);
    },
    getDropdownOptions(index, labelIndex) {
      if (labelIndex === 0) {
        return this.getNodeChildren(this.chatData[index].node.node);
      }
      return this.getNodeChildren(this.chatData[index].labels[labelIndex - 1].label);
    },
    getNodeChildren(id) {
      const options = id ? this.nodeById(id).children
        .map((e) => ({ text: this.nameOfId(e), value: e })) : [];
      return options.concat([{ value: 'fallback', text: 'None of the above' }]);
    },
    showResetButton(index, label) {
      return label.parent_label_id === null && label.label !== this.chatData[index + 1]?.node?.node;
    },
    showArrow(index, item, labelIndex) {
      if (item.labels.length - 1 !== labelIndex) {
        return true;
      }
      if (!this.showLog && !this.showInTable(index)) {
        return false;
      }
      const prev = this.chatData.slice(0, index + 1).map((e) => e.labels).filter((e) => e?.length);
      if (prev.some((l) => l.some((i) => i.id === 'select'))) {
        return false;
      }
      if (this.chatData[index + 1]) {
        if (!this.chatData[index + 1].hide || this.chatData[index + 1].labels?.length) {
          return true;
        }
      }
      const shown = this.chatData.slice(index + 1)
        .filter((e) => (!e.hide || e.labels?.length)).length > 0;
      return shown;
    },
    showLabelPrediction(index, labelIndex) {
      return labelIndex === 0 && this.chatData[index + 1]?.labels?.length
      && this.chatData[index + 1].probabilities?.some((e) => typeof e.score === 'number');
    },
    getTopLabelPrediction(index) {
      return getTopPrediction(
        this.chatData[index + 1].labels[0]?.label !== this.chatData[index + 2]?.node?.node,
        this.chatData[index + 1].probabilities);
    },
    getMessageClass(event, index, disabled) {
      let msgClass = this.isCustomer(event.data.author) ? 'ml-5' : 'mr-5';
      if (event.data.author === 'customer' && event.data.query === this.highlightedQuery) {
        msgClass += ' highlighted-message';
      }
      if (disabled) {
        msgClass += ' text-muted';
      }
      return msgClass;
    },
    isCustomer(author) {
      return author === 'customer';
    },
    goToNode(nodeId) {
      const botId = this.$route.params.botId;
      const newPage = this.$router.resolve({ name: 'edit-node', params: { botId, nodeId } });
      window.open(newPage.href, '_blank');
    },
    showInTable(index) {
      // single chat view, always show
      if (this.showLog) {
        return true;
      }
      const lastValidIndex = this.getLastValidQueryIndex(this.focusNodeIndex, this.activeQuery);
      return index <= lastValidIndex;
    },
    getLastValidQueryIndex(index, activeQuery) {
      if (index + 1 < this.chatData.length) {
        const nodeId = this.chatData[index + 1].node.node;
        const nextItem = this.queryLabel.nodelabelitems.find((e) => e.node_id === nodeId);
        if ((nextItem.query === activeQuery)
        && (nextItem.labels.length || this.chatData[index + 1].hide)) {
          return this.getLastValidQueryIndex(index + 1, activeQuery);
        }
      }
      return index;
    },
    newLabel(chatId, index, labelIndex, nodeId) {
      if (this.chatData[index].labels[labelIndex].label !== nodeId) {
        if (this.chatData[index].query) {
          this.localHighlightedQuery = this.chatData[index].query;
        } else {
          this.localHighlightedQuery = this.chatData[index].events.find((e) => e.data.author === 'customer').data.query;
        }
        this.$emit('newLabel', {
          chatId, index, labelIndex, nodeId,
        });
      }
    },
    resetLabel(chatId, index) {
      this.localHighlightedQuery = null;
      this.$emit('resetLabel', { chatId, index });
    },
  },
};
</script>
<style scoped>
.flowcolumn {
  min-width: 35%;
  max-width: 35%;
  border-width: 0.25rem !important;
}
.logcolumn {
  position: relative;
  min-width: 65%;
  max-width: 65%;
}
::v-deep .bubble {
  min-width: 75px;
  word-break: break-word;
  border-radius: 15px;
  color: #2D3946;
  background-color: #F5F5F5;
}
.node-name{
  width: fit-content;
  border-radius: 50rem;
  font-size: 15px;
  padding: 6px 8px;
  font-weight: 300;
}
.chat-overlay{
  position: absolute;
  background-color: #888888aa;
  opacity: 0.4;
  left: 0;
  right: 0;
  top: 0;
  bottom:0;
  width: auto;
  height: auto;
  z-index: 10;
}
.highlighted-message{
  border: 1px solid #00608981;
  box-shadow: 0 0 4px rgba(0, 95, 137, 0.5);
}
::v-deep .labels-dropdown{
  min-width: 200px;
}
.prediction-badge, ::v-deep .prediction-badge{
  position: absolute;
  padding: 1px 5px;
  left: 54%;
  top: 5px;
  background-color:#808080 !important;
  font-size: 0.8rem;
  white-space: nowrap;
}
.name{
  color:#888;
}
</style>
