import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';
import {
  DATASET_STATE_LOAD_FAILED,
  DATASET_STATE_LOADED,
  DATASET_STATE_LOADING,
  DATASET_STATE_SYNC_FAILED,
  DATASET_STATE_UNINITIALIZED,
  DATASET_DELETED_SUCCESSFULLY,
  DATASET_DELETION_ERROR,
  DATASET_DELETION_IN_PROGRESS,
} from '@/js/constants';
import dataSources from './dataSources';
import categories from './categories';
import visualizations from './visualizations';
import label from './label';

const dataExplorationState = () => ({
  datasetIds: [],
  datasets: {},
  datasetsLoadState: DATASET_STATE_UNINITIALIZED,
  /*  Maps a datasetId to a deletion-state (DELETING, ERROR): Dictionary will only contain entries
      for datasets for which deletion was requested */
  datasetsDeletingState: {},
  datasetsDeletionTasks: {}, // Maps a datasetId to a taskId
  isInitialized: false,
  isDatasetLoading: false,
  currentDatasetId: null,
  forceRandomSample: false,
});

export const dataExplorationGetters = {
  isFetchingDatasets: (state) => state.datasetsLoadState === DATASET_STATE_LOADING,
  getDatasetByName: (state) => (name) => Object.values(state.datasets).find((x) => x.name === name),
  getDatasetById: (state) => (datasetId) => state.datasets[datasetId],
  isDeleting: (state) => (datasetId) => {
    const result = DATASET_DELETION_IN_PROGRESS === state.datasetsDeletingState[datasetId];
    return result;
  },
  datasetOptions(state) {
    let datasets = Object.values(state.datasets);
    datasets = datasets.map((x) => ({ value: x.id, text: x.name }));
    datasets.unshift({ value: null, text: 'No dataset chosen' });
    return datasets;
  },
  forceRandomSample(state) {
    return state.forceRandomSample;
  },
};

/*
Conventions:
- The payload to any mutation or action must be a dict.
- The id of a dataset is always the key "datasetId". E.g. never just "id".
 */

const mutations = {
  setForceRandomSample(state, value) {
    state.forceRandomSample = value;
  },
  setDatasets(state, { datasets }) {
    state.datasetIds = datasets.map(({ id }) => id);
    const datasetDict = {};
    for (const dataset of datasets) {
      datasetDict[dataset.id] = dataset;
    }
    // This is necessary, since the datasets consists of objects, where the keys have not been
    // specified in the original state (e.g. the ids are not specified).
    Vue.set(state, 'datasets', datasetDict);
  },
  setDataset(state, { datasetId, dataset }) {
    // We need to use Vue.set for the same reason as in setDatasets
    if (!Object.prototype.hasOwnProperty.call(state.datasets, datasetId)) {
      Vue.set(state.datasets, datasetId, {});
    }
    for (const [key, value] of Object.entries((dataset))) {
      Vue.set(state.datasets[datasetId], key, value);
    }
  },
  setDatasetsLoadState(state, { loadState }) {
    state.datasetsLoadState = loadState;
  },
  updateDeletionStateForDatasets(state, { datasetId, currentDeletionState }) {
    Vue.set(state.datasetsDeletingState, datasetId, currentDeletionState);
  },
  addDeletionTask(state, { datasetId, taskId }) {
    Vue.set(state.datasetsDeletionTasks, datasetId, taskId);
  },
  deleteDeletionTask(state, { datasetId }) {
    Vue.delete(state.datasetsDeletionTasks, datasetId);
  },
  deleteDataset(state, { datasetId }) {
    state.datasetIds = state.datasetIds.filter((x) => x !== datasetId);
    Vue.delete(state.datasets, datasetId);
  },
  setIsInitialized(state, { isInitialized }) {
    state.isInitialized = isInitialized;
  },
  setIsDatasetLoading(state, value) {
    state.isDatasetLoading = value;
  },
  setCurrentDatasetId(state, value) {
    state.currentDatasetId = value;
  },
};

const actions = {
  async fetchDataset({ rootState, commit }, { id, count = false }) {
    commit('setDatasetsLoadState', { loadState: DATASET_STATE_LOADING });
    try {
      const { data } = await axios.get(
        `${endpoints.dataExplorationDatasets}${id}/`,
        {
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
          params: { count_total: count },
        },
      );
      if (!data.result || data.result.length !== 1) {
        throw new Error('Unexpected result of loading specific dataset');
      }
      commit('setDataset', { datasetId: id, dataset: data.result[0] });
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_LOADED });
    } catch (e) {
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_LOAD_FAILED });
    }
  },
  async fetchAllDatasets({ rootState, commit }) {
    commit('setDatasetsLoadState', { loadState: DATASET_STATE_LOADING });
    try {
      const { data } = await axios.get(endpoints.dataExplorationDatasets, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setDatasets', { datasets: data.result });
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_LOADED });
      commit('setIsInitialized', { isInitialized: true });
    } catch (e) {
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_LOAD_FAILED });
    }
  },
  async loadDataset({ commit, dispatch }, datasetId) {
    commit('setIsDatasetLoading', true);
    commit('setCurrentDatasetId', datasetId);
    await dispatch('categories/fetchCategories', datasetId);
    commit('setIsDatasetLoading', false);
  },
  async loadCurrentDataset({ state, dispatch }) {
    await dispatch('loadDataset', state.currentDatasetId);
  },
  clearState({ commit }) {
    commit('setIsDatasetLoading', false);
    commit('setCurrentDatasetId', null);
    commit('categories/setCategories', []);
  },
  async deleteDataset({ rootState, dispatch, commit }, { datasetId }) {
    try {
      const response = await axios.delete(endpoints.dataExplorationDatasets, {
        data: { id: datasetId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      const taskId = response.data.task_id;

      // Small hack to start showing spinner in UI immediately.
      commit('updateDeletionStateForDatasets', {
        datasetId,
        currentDeletionState: DATASET_DELETION_IN_PROGRESS,
      });

      commit('addDeletionTask', { datasetId, taskId });
      dispatch('startPollingForDeletionUpdates');
      // Start polling for taskupdates
    } catch (err) {
      if (err.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permissions to this dataset. You must ask a superuser to grant you access.',
          variant: 'warning',
        }, { root: true });
      }
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_SYNC_FAILED });
      throw err;
    }
  },
  async startPollingForDeletionUpdates({
    rootState, state, dispatch, commit,
  }) {
    const intervalMs = 1000; // Poll every second
    setTimeout(async () => {
      Object.entries(state.datasetsDeletionTasks).forEach(async ([datasetId, taskId]) => {
        if (DATASET_DELETED_SUCCESSFULLY === state.datasetsDeletingState[datasetId]
          || DATASET_DELETION_ERROR === state.datasetsDeletingState) {
          return; // No need to do more.
        }
        try {
          const response = await axios.get(endpoints.dataExplorationDatasetDeletionTaskStatus, {
            params: { task_id: taskId },
            headers: { Authorization: `JWT ${rootState.auth.jwt}` },
          });
          if (DATASET_DELETED_SUCCESSFULLY === response.data.task_status) {
            // Update state: Stop polling for this task by removing it from list of ongoing tasks
            commit('deleteDeletionTask', { datasetId });
            commit('updateDeletionStateForDatasets', {
              datasetId,
              currentDeletionState: DATASET_DELETED_SUCCESSFULLY,
            });
            // Remove dataset from list
            commit('deleteDataset', { datasetId });
          } else if (DATASET_DELETION_IN_PROGRESS === response.data.task_status) {
            // Update state
            commit('updateDeletionStateForDatasets', {
              datasetId,
              currentDeletionState: DATASET_DELETION_IN_PROGRESS,
            });

            // Schedule new poll
            dispatch('startPollingForDeletionUpdates');
          } else if (DATASET_DELETION_ERROR === response.data.task_status) {
            // Update state accordingly
            commit('updateDeletionStateForDatasets', {
              datasetId,
              currentDeletionState: DATASET_DELETION_ERROR,
            });
            // Currently frontend does not handle a deletion error, so we just piggyback the error-
            // handling already in place.
            throw new Error(`Unhandled status from backend ${response.data}`);
          } else {
            // Update state accordingly
            commit('updateDeletionStateForDatasets', {
              datasetId,
              currentDeletionState: DATASET_DELETION_ERROR,
            });

            throw new Error(`Unhandled status from backend ${response.data}`);
          }
        } catch (e) {
          commit('setDatasetsLoadState', { loadState: DATASET_STATE_SYNC_FAILED });
        }
      });
    }, intervalMs);
  },
  async createDataset({ rootState, dispatch, commit }, { dataset }) {
    try {
      const resp = await axios.post(endpoints.dataExplorationDatasets, dataset, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      await dispatch('fetchAllDatasets');
      if (resp.status === 200) {
        dispatch('sidebar/showWarning', {
          title: 'Dataset created',
          text: 'Dataset created successfully.',
          variant: 'info',
        }, { root: true });
        return resp.data.id;
      }
    } catch (e) {
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_SYNC_FAILED });
      dispatch('sidebar/showWarning', {
        title: 'Failed to create dataset',
        text: e.message,
        variant: 'danger',
      }, { root: true });
    }
    return undefined;
  },
  async editDataset({ rootState, commit, dispatch }, { datasetId, dataset }) {
    try {
      const data = { ...dataset, id: datasetId };
      await axios.put(endpoints.dataExplorationDatasets, data, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setDataset', { datasetId, dataset });
    } catch (err) {
      if (err.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permissions to this dataset. You must ask a superuser to grant you access.',
          variant: 'warning',
        }, { root: true });
      }
      commit('setDatasetsLoadState', { loadState: DATASET_STATE_SYNC_FAILED });
      throw err;
    }
  },
};

export default {
  namespaced: true,
  modules: {
    categories, visualizations, dataSources, label,
  },
  state: dataExplorationState,
  getters: dataExplorationGetters,
  mutations,
  actions,
};
