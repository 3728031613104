<template>
  <div class="d-flex flex-column align-items-center my-3">
    <span class="meta">Traceback</span>
    <div class="bubble p-2 bg-info tracebackMessage">
      <b-row no-gutters>
        <b-col
          class="text-right font-weight-bold"
          cols="5"
        >
          Traceback
        </b-col>
        <b-col cols="7" />
      </b-row>
      <b-row no-gutters>
        <b-col
          class="text-right font-weight-bold"
          cols="5"
        >
          Parent node:
        </b-col>
        <b-col
          cols="7"
          class="pl-2 mt-auto"
        >
          {{ traceback.data.parent_display_name }}
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col
          class="text-right font-weight-bold"
          cols="5"
        >
          Selected node:
        </b-col>
        <b-col
          cols="7"
          class="pl-2 pt-auto mt-auto"
        >
          {{ traceback.data.display_name }}
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col
          class="text-right font-weight-bold"
          cols="5"
        >
          Score:
        </b-col>
        <b-col
          cols="7"
          class="pl-2 mt-auto"
        >
          {{ traceback.data.score }}
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col
          class="text-right font-weight-bold"
          cols="5"
        >
          Applied threshold:
        </b-col>
        <b-col
          cols="7"
          class="pl-2 mt-auto"
        >
          {{ traceback.data.threshold }}
        </b-col>
      </b-row>
    </div>
    <span
      class="meta"
    >{{ dateFormatter(traceback.timestamp) }}</span>
  </div>
</template>

<script>
export default {
  name: 'ChatTraceback',
  props: {
    traceback: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleTimeString();
    },
  },
};
</script>

<style scoped>
.tracebackMessage {
  border-radius: 5px;
  word-break: keep-all;
  min-width: 40% !important;
}

</style>
