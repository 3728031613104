<template>
  <b-list-group-item>
    <b-row
      align-v="center"
      align-h="between"
      :style="{ cursor: 'pointer' }"
      @click="showDetails = !showDetails"
    >
      <b-col cols="11">
        <h5 class="break-text  mb-0">
          <b-link :to="link">
            Inactivity Prompt at {{ inactivity.time }} seconds
          </b-link>
        </h5>
      </b-col>
      <b-col
        cols="1"
        class="text-right"
      >
        <h5 class="mr-1 mb-0">
          <font-awesome-icon :icon="showDetails ? 'angle-up' : 'angle-down'" />
        </h5>
      </b-col>
    </b-row>
    <b-collapse
      v-model="showDetails"
      class="mt-3"
    >
      <edit-inactivity
        :value="tmpInactivity"
        response-only
        @input="updateInactive"
      />
    </b-collapse>
  </b-list-group-item>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import isEqual from 'lodash/isEqual';
import EditInactivity from '@/pages/BotConfig/EditInactivity.vue';

export default {
  name: 'ResponsesForInactivities',
  components: {
    EditInactivity,
  },
  props: {
    inactivityIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
      tmpInactivity: {
        time: '',
        msg: '',
        responseApproved: false,
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getInactivity',
    ]),
    inactivity() {
      return this.getInactivity[this.inactivityIndex];
    },
    link() {
      const botId = this.$route.params.botId;
      return { name: 'config', params: { botId }, hash: '#Inactivity' };
    },
  },
  watch: {
    tmpInactivity: {
      handler(value) {
        // We need to break watch-loop.
        if (!isEqual(value, this.inactivity)) {
          this.setSingleInactivity({
            index: this.inactivityIndex,
            inactivity: { ...value },
          });
        }
      },
      deep: true,
    },
    inactivity: {
      handler(value) {
        this.tmpInactivity = { ...value };
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setSingleInactivity',
    ]),
    updateInactive(payload) {
      this.tmpInactive[payload.type] = payload.value;
      if (payload.type === 'msg') {
        this.tmpInactive.responseApproved = false;
      }
    },
  },
};

</script>
