<template>
  <b-modal
    ref="modal"
    :title="title"
    :ok-disabled="$v.$invalid"
    :ok-title="title"
    @hide="handleHide"
    @ok="handleOk"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-form-group
        v-if="chooseParent"
        label="Category"
        label-for="parentId"
      >
        <b-form-select
          id="parentId"
          v-model="parentId"
          :options="parentOptions"
          :state="$v.parentId.$invalid ? false : null"
          aria-describedby="parentIdFeedback"
        />
        <b-form-invalid-feedback id="parentIdFeedback">
          <div v-if="!$v.parentId.isRequired">
            Category is required.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        :label="categoryNameLabel"
        label-for="categoryName"
      >
        <b-form-input
          id="categoryName"
          v-model.trim="categoryName"
          placeholder="Name"
          :state="$v.categoryName.$invalid ? false : null"
          aria-describedby="categoryNameFeedback"
        />
        <b-form-text
          v-if="$v.categoryName.isUnique && similarCategoryWarning"
          text-variant="warning"
        >
          Similar name already exists: {{ prettySimilarNames }}
        </b-form-text>
        <b-form-invalid-feedback id="categoryNameFeedback">
          <div v-if="!$v.categoryName.required">
            <template v-if="!chooseParent">
              Category name is required.
            </template>
            <template v-else>
              Subcategory name is required.
            </template>
          </div>
          <div v-if="!$v.categoryName.isUnique">
            <template v-if="!chooseParent">
              Category name already exists.
            </template>
            <template v-else>
              Subcategory name already exists.
            </template>
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'DataExplorationNewCategory',
  mixins: [validationMixin],
  data() {
    return {
      categoryName: '',
      parentId: null,
      chooseParent: null,
      datasetId: null,
      resolve: null,
      reject: null,
    };
  },
  computed: {
    ...mapGetters('dataExploration/categories', [
      'getSimilarNames',
      'isNameUnique',
      'getMainCategories',
    ]),
    similarNames() {
      if (this.categoryName) {
        return this.getSimilarNames(this.datasetId, this.parentId, this.categoryName)
          .map(({ name }) => name);
      }
      return [];
    },
    similarCategoryWarning() {
      return this.similarNames.length > 0;
    },
    prettySimilarNames() {
      if (this.similarNames.length === 0) {
        return '';
      }
      if (this.similarNames.length === 1) {
        return this.similarNames[0];
      }
      const firstPart = this.similarNames.slice(0, this.similarNames.length - 1).join(', ');
      return `${firstPart} and ${this.similarNames[this.similarNames.length - 1]}`;
    },
    title() {
      return !this.chooseParent ? 'Add category' : 'Add subcategory';
    },
    categoryNameLabel() {
      return !this.chooseParent ? 'Category name' : 'Subcategory name';
    },
    parentOptions() {
      return [{ value: null, text: '' }].concat(this.getMainCategories.map(({ id, name }) => ({
        value: id,
        text: name,
      })));
    },
  },
  methods: {
    handleSubmit() {
      if (this.$v.$invalid) {
        return;
      }
      this.resolve({ parentId: this.parentId, name: this.categoryName });
      this.$refs.modal.hide();
    },
    handleOk(e) {
      if (this.$v.$invalid) {
        e.preventDefault();
        return;
      }
      this.resolve({ parentId: this.parentId, name: this.categoryName });
    },
    handleHide() {
      this.resolve(null);
    },
    getNewCategory(datasetId, chooseParent, parentId = null) {
      this.datasetId = datasetId;
      this.chooseParent = chooseParent;
      this.parentId = parentId;
      this.categoryName = '';
      this.$refs.modal.show();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
  },
  validations: {
    categoryName: {
      required,
      isUnique(name) {
        return this.isNameUnique(this.datasetId, this.parentId, name);
      },
    },
    parentId: {
      isRequired(name) {
        return !this.chooseParent || required(name);
      },
    },
  },
};
</script>

<style scoped>

</style>
