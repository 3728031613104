<template>
  <main
    role="main"
  >
    <b-card
      class="r-75"
      title="Responses"
      body-class="p-3"
    >
      <p>
        Here you handle the responses of the bot.
      </p>
      <h5 class="mt-2 mb-0">
        <b-badge
          v-if="missingApprovals.length === 0"
          variant="success"
          class="p-2"
        >
          All responses approved
          <font-awesome-icon
            icon="check"
          />
        </b-badge>
        <template v-else>
          <b-button
            variant="warning"
            size="sm"
            @click="showDrafts"
          >
            Attention:
            {{ missingApprovals.length === 1 ? '1 place' : `${missingApprovals.length} places` }}
            containing draft response(s)
          </b-button>
          <b-button
            v-b-modal.approve-all-drafts
            size="sm"
            class="ml-2"
            variant="primary"
          >
            Approve all drafts
          </b-button>
        </template>
        <b-button
          v-if="isMainBot"
          class="ml-2 mb-0"
          variant="primary"
          size="sm"
          @click="exportResponses('excel')"
        >
          <font-awesome-icon
            icon="download"
          />
          Export all responses
        </b-button>
      </h5>
    </b-card>

    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row v-if="routeQuery">
        <b-col>
          <b-form-group
            class="mb-2"
            label="Search bad links"
            description="You are viewing bad links found in bot.
             Click 'close' button to return to standard Responses page."
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-btn
                  class="same-width"
                  @click="closeQuerySearch"
                >
                  close
                </b-btn>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                disabled
                placeholder="Response"
                type="text"
              />
              <template #append>
                <b-button
                  class="same-width"
                  variant="primary"
                  :disabled="routeQueryIndex === 0"
                  @click="previousClicked"
                >
                  previous
                </b-button>
                <b-button
                  variant="primary"
                  class="same-width"
                  :disabled="routeQueryIndex === routeQuery.length - 1"
                  @click="nextClicked"
                >
                  next
                </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-form-group
            class="mb-2"
            label="Search Text"
            description="Leave empty to get a list of all nodes with responses."
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox v-model="draftsOnly" />
                Only drafts
              </b-input-group-prepend>
              <b-form-textarea
                id="searchResponses"
                v-model="searchQuery"
                rows="2"
                placeholder="Response"
                type="text"
              />
              <template #append>
                <b-btn
                  variant="primary"
                  class="same-width"
                  @click="doSearch"
                >
                  Search
                </b-btn>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox
            v-model="replaceCollapse"
            name="check-button"
            switch
          >
            <span style="font-size:14px;">Advanced</span>
          </b-form-checkbox>

          <b-collapse v-model="replaceCollapse">
            <b-form-group
              label="Filter node names"
              description="Add a keyword for node names to narrow down results."
              class="mt-2"
            >
              <b-form-input
                v-model="nodeName"
                placeholder="Keyword"
              />
            </b-form-group>

            <b-form-group
              class="mb-0 mt-2"
              label="Replace Text"
            >
              <b-input-group>
                <b-form-textarea
                  id="replaceResponses"
                  v-model="replaceText"
                  rows="2"
                  type="text"
                />
                <template #append>
                  <b-btn
                    class="same-width"
                    variant="warning"
                    :disabled="!searchQuery || !searchQuery.trim()"
                    @click="doReplaceAsk"
                  >
                    Replace All
                  </b-btn>
                </template>
              </b-input-group>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>

      <div
        class="mt-2 text-muted small"
      >
        <div
          v-if="searchResults && !replaceTest && replaceCount >= 1"
        >
          Found {{ countResults }} nodes, and replaced {{ replaceCount }} occurrences.
        </div>
        <div
          v-else-if="searchResults"
        >
          Found {{ countResults }} nodes.
        </div>
      </div>
    </b-card>
    <b-card
      v-if="searchResults && (searchResults.nodes.length
        || searchResults.inactivities.length || searchResults.configSmartNodes)"
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-list-group
        v-if="searchResults && searchResults.nodes.length > 0"
      >
        <responses-for-node
          v-for="item in searchResults.nodes"
          :key="`node_${item.id}`"
          :node-id="item.id"
        />
      </b-list-group>

      <b-list-group
        v-if="searchResults && searchResults.inactivities.length > 0"
        :class="searchResults && searchResults.nodes.length ? 'mt-3' : ''"
      >
        <responses-for-inactivities
          v-for="inactiveIndex in searchResults.inactivities"
          :key="`inactivity_${inactiveIndex}`"
          :inactivity-index="inactiveIndex"
        />
      </b-list-group>

      <b-list-group
        v-if="searchResults && searchResults.configSmartNodes"
        :class="searchResults && (searchResults.nodes.length
          || searchResults.inactivities.length) ? 'mt-3' : ''"
      >
        <responses-for-config-smart-nodes />
      </b-list-group>
    </b-card>

    <b-modal
      id="import-modal"
      size="lg"
    >
      <b-form-group
        description="The import file should always be created by adjusting the data in an export
         file to preserve the correct format."
        label="Select file to import:"
        label-for="importFileForm"
      >
        <b-form-file
          id="importFileForm"
          v-model="importForm.file"
          placeholder="Choose a json phrases file..."
          :state="$v.importForm.file.$invalid ? false : null"
        />
        <!-- Bug 3545: this invalid does not work so we added :state instead -->
        <b-form-invalid-feedback>
          <div v-if="$v.importForm.file.$invalid">
            Missing file
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Select import settings:"
        label-for="importOptionsForm"
      >
        <b-form-checkbox-group
          id="importOptionsForm"
          v-model="importForm.options"
          :options="importOptions"
          name="flavour-1a"
          class="my-2"
        />
        <p>
          <b-link v-b-modal.import-options-explain>
            Click here for explanation of the options.
          </b-link>
        </p>
        <b-button
          :disabled="$v.importForm.$invalid || importProcessing"
          @click="importResponses()"
        >
          Import
          <b-spinner
            v-if="importProcessing"
            small
          />
        </b-button>
      </b-form-group>
      <b-list-group
        v-if="importResult.errors.length > 0 || importResult.warnings.length > 0
          || importResult.infos.length > 0"
      >
        <responses-for-import
          id="tableOfImportErrors"
          title="Errors"
          :table="importResult.errors"
          sort-key="msg"
          variant="danger"
        />
        <responses-for-import
          id="tableOfImportWarnings"
          title="Warnings"
          :table="importResult.warnings"
          sort-key="msg"
          variant="warning"
        />
        <responses-for-import
          id="tableOfImportInfo"
          title="Info"
          :table="importResult.infos"
          sort-key="msg"
          variant="info"
        />
      </b-list-group>
    </b-modal>

    <b-modal
      id="replace-all-confirmation"
      title="Confirm Replacing All Occurrences"
      size="lg"
      @ok="doReplaceReal"
      @cancel="replaceCount = 0"
    >
      <p>
        This will change {{ replaceCount }} responses and there may be no way to re-create them.
      </p>
      <p v-if="!replaceText">
        Replacing with the empty string!
      </p>
      <p>
        Are you sure?
      </p>
    </b-modal>

    <b-modal
      id="import-options-explain"
      title="Explanation of import options"
      size="lg"
      ok-only
    >
      <p>
        Brief description of the different import options:
      </p>
      <h5 class="font-weight-bold mt-4">
        Test only
      </h5>
      <p>
        Run a test-import of the file. This will not change the bot in anyway, but will show you
        the results of the import, so you can adjust accordingly.
      </p>
      <h5 class="font-weight-bold mt-4">
        Ignore missing imports
      </h5>
      <p>
        Allow import of a file that does not contain all the responses present in the bot. This is
        useful if your import is partial or if new phrases have been added to the bot after the
        import was created.
      </p>
      <h5 class="font-weight-bold mt-4">
        Ignore unused imports
      </h5>
      <p>
        Allow import of a file that contains phrases that are not present in the bot. This is
        useful if you have e.g. deleted a response in the bot since the import was created.
      </p>
      <h5 class="font-weight-bold mt-4">
        Ignore existing text
      </h5>
      <p>
        Allows import of text even if it has been changed since the import was created.
      </p>
      <h5 class="font-weight-bold mt-4">
        Keep empty text
      </h5>
      <p>
        Allows import of phrases to overwrite if the phrase in the bot has been changed to be
        empty.
      </p>
      <h5 class="font-weight-bold mt-4">
        (Advanced) Use text hash search
      </h5>
      <p>
        Allows the importer to use phrases that don't match the exact node to update text if an
        phrase in the bot is not present in the import file.
      </p>
    </b-modal>
    <b-modal
      id="approve-all-drafts"
      title="Approve all drafts"
      @ok="approveAllDrafts"
    >
      Are you sure you want to approve all draft responses?
    </b-modal>
  </main>
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { RESPONSE, COMPOUND_RESPONSE, nodeHasResponse } from '@/js/activity';
import ResponsesForImport from '@/pages/Responses/ResponsesForImport.vue';
import ResponsesForNode from '@/pages/Responses/ResponsesForNode.vue';
import ResponsesForInactivities from '@/pages/Responses/ResponsesForInactivities.vue';
import ResponsesForConfigSmartNodes from '@/pages/Responses/ResponsesForConfigSmartNodes.vue';
import endpoints from '@/js/urls';
import { truncateString } from '@/js/utils';
import { nodeTypes } from '@/js/constants';

export default {
  name: 'BotSearch',
  components: {
    ResponsesForImport,
    ResponsesForNode,
    ResponsesForInactivities,
    ResponsesForConfigSmartNodes,
  },
  mixins: [validationMixin],
  data() {
    return {
      replaceCollapse: false,
      searchResults: null,
      importForm: {
        file: null,
        options: ['test_only'],
      },
      allImportOptions: [
        { text: 'Test only', value: 'test_only' },
        { text: 'Ignore missing imports', value: 'ignore_missing' },
        { text: 'Ignore unused imports', value: 'ignore_unused' },
        { text: 'Ignore existing text', value: 'ignore_new_text' },
        { text: 'Keep empty text', value: 'keep_empty_text' },
        { text: 'Show advanced options', value: 'advanced' },
        { text: 'Use text hash search', value: 'allow_hash_search', req: 'advanced' },
      ],
      importProcessing: false,
      importResult: {
        errors: [],
        warnings: [],
        infos: [],
      },
      searchQuery: '',
      replaceText: '',
      replaceTest: true,
      nodeName: '',
      replaceCount: 0,
      draftsOnly: false,
      routeQuery: null,
      routeQueryIndex: 0,
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['activeBotId']),
    ...mapGetters('botManipulation/activeBot', ['allNodesAsList', 'nodesWithDraftResponses']),
    ...mapGetters('botManipulation/activeBot/config', [
      'getInactivity',
      'getMultipleChoiceApproved',
    ]),
    ...mapGetters('botManipulation/activeBot/config', ['isMainBot']),
    ...mapState('botManipulation/activeBot/config', ['multipleChoice']),
    ...mapGetters('botManipulation/activeBot', ['phrases']),
    missingApprovals() {
      if (this.filterConfigSmartNodes(true)) {
        return this.nodesWithDraftResponses.concat(this.filterInactivities(true)).concat('smart');
      }

      return this.nodesWithDraftResponses.concat(this.filterInactivities(true));
    },
    botName() {
      if (this.$store.state.botManipulation.activeBotSet) {
        return truncateString(this.$store.state.botManipulation.activeBot.config.name, 40);
      }
      return 'Nobody';
    },
    importOptions() {
      const options = [];
      for (const option of this.allImportOptions) {
        if (!option.req) {
          options.push(option);
        } else if (this.importForm.options.includes(option.req)) {
          options.push(option);
        } else {
          // for debug
        }
      }
      return options;
    },
    baseOptionsQuery() {
      return this.multipleChoice.optionsQuery;
    },
    baseConfirmQuery() {
      return this.multipleChoice.confirmQuery;
    },
    baseOtherText() {
      return this.multipleChoice.otherText;
    },
    countResults() {
      if (!this.searchResults) {
        return 0;
      }
      return this.searchResults.nodes.length
        + this.searchResults.inactivities.length
        + (this.searchResults.configSmartNodes ? 1 : 0);
    },
    doCaseSearch() {
      if (!this.searchQuery) {
        return false;
      }
      return this.searchQuery !== this.searchQuery.toLowerCase();
    },
    searchRegExp() {
      let flags = 'g';
      if (!this.doCaseSearch) {
        flags += 'i';
      }
      return new RegExp(this.escapeRegExp(this.searchQuery), flags);
    },
  },
  mounted() {
    // route query search accepts only array of strings
    if (this.$route.query?.length) {
      this.routeQuery = this.$route.query;
      this.searchQuery = this.routeQuery[this.routeQueryIndex];
      this.doSearch();
    } else if (Object.values(this.$route.query)?.length) {
      this.routeQuery = Object.values(this.$route.query);
      this.searchQuery = this.routeQuery[this.routeQueryIndex];
      this.doSearch();
    }
  },
  methods: {
    ...mapMutations('botManipulation/activeBot', [
      'setActivityResponseText',
    ]),
    ...mapMutations('botManipulation/activeBot/config', [
      'setMultipleChoiceOptionsQuery',
      'setMultipleChoiceConfirmQuery',
      'setMultipleChoiceOtherText',
      'setSingleInactivity',
      'setMultipleChoiceApproved',
    ]),
    ...mapActions('botManipulation', [
      'updateInactivity',
      'getAllResponses',
    ]),
    ...mapMutations('botManipulation/activeBot', ['setResponseApproved', 'setApproved']),
    // Escaping regular expressions as suggested by
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
    escapeRegExp(str) {
      return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    previousClicked() {
      this.routeQueryIndex -= 1;
      this.searchQuery = this.routeQuery[this.routeQueryIndex];
      this.doSearch();
    },
    nextClicked() {
      this.routeQueryIndex += 1;
      this.searchQuery = this.routeQuery[this.routeQueryIndex];
      this.doSearch();
    },
    closeQuerySearch() {
      this.routeQuery = null;
      this.routeQueryIndex = 0;
    },
    searchAmong(responses, searchPhrases) {
      const foundPhrases = [];
      if (searchPhrases) {
        for (const phrase of this.phrases) {
          const found = phrase.text.match(this.searchRegExp) !== null;
          if (found) {
            foundPhrases.push(phrase);
          }
        }
      }
      for (const response of responses) {
        const searchText = this.doCaseSearch ? response : response.toLowerCase();
        const found = searchText.match(this.searchRegExp) !== null;
        const phraseFound = foundPhrases.filter((e) => searchText.includes(e.id)).length > 0;

        if (found) {
          return true;
        }
        if (phraseFound) {
          return true;
        }
      }
      return false;
    },
    filterNodes() {
      let resultsTemp;
      if (this.draftsOnly) {
        resultsTemp = this.nodesWithDraftResponses;
      } else {
        resultsTemp = this.allNodesAsList.filter(nodeHasResponse);
      }
      resultsTemp = resultsTemp.filter((node) => {
        const responses = [];
        for (const activity of Object.values(node.activities)) {
          if (activity.type === RESPONSE) {
            responses.push(activity.text);
          }
          if (activity.type === COMPOUND_RESPONSE) {
            responses.push(activity.text);
            responses.push(activity.link);
            responses.push(activity.title);
          }
        }

        // For multiple-choice nodes we also want to search among
        // 1. Custom display-texts for nodes
        // 2. Multiple-choice message (also default)
        // 3. Single choice message (also default)
        // 4. Custom text for "None of the above option" (when enabled)
        if (node.options.nodeType === 'multipleChoice') {
          // 1. Custom display-texts for nodes
          for (const displayText of Object.values(node.options.displayNames)) {
            if (displayText !== '') {
              responses.push(displayText);
            }
          }

          // 2. Multiple-choice message (also default)
          if (node.options.confirmQuery === '') {
            // Use default
            responses.push(this.baseConfirmQuery);
          } else {
            // Use custom confirm query
            responses.push(node.options.confirmQuery);
          }

          // 3. Single choice message (also default)
          if (node.options.optionsQuery === '') {
            // Use default
            responses.push(this.baseOptionsQuery);
          } else {
            // Use custom options query
            responses.push(node.options.optionsQuery);
          }

          // 4. Text for "None of the above option" (when enabled) (include default message)
          if (node.options.otherShow) {
            if (node.options.othertext === '') {
              // When user has not specified a custom message, then include the default message
              responses.push(this.baseOtherText);
            } else {
              responses.push(node.options.otherText);
            }
          }
        }
        return this.searchAmong(responses, true);
      });
      if (this.nodeName.trim().length) {
        resultsTemp = resultsTemp
          .filter((node) => node.name.toLowerCase().includes(this.nodeName.toLowerCase()));
      }
      resultsTemp.sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
      this.searchResults.nodes = resultsTemp;
    },
    filterInactivities(draftsOnly) {
      const tempInactivities = [];
      this.getInactivity.forEach((inactive, index) => {
        if ((this.draftsOnly || draftsOnly) && inactive.responseApproved) {
          return;
        }
        const found = this.searchAmong([inactive.msg || '']);
        if (found) {
          tempInactivities.push(index);
        }
      });
      return tempInactivities;
    },
    filterConfigSmartNodes(draftsOnly) {
      if (!this.isMainBot) {
        return false;
      }
      if ((this.draftsOnly || draftsOnly) && this.getMultipleChoiceApproved) {
        return false;
      }
      const responses = [];
      responses.push(this.multipleChoice.optionsQuery);
      responses.push(this.multipleChoice.confirmQuery);
      responses.push(this.multipleChoice.otherText);
      return this.searchAmong(responses);
    },
    doSearch() {
      this.searchResults = {};
      this.replaceCount = 0;
      this.filterNodes();
      if (this.nodeName.trim().length) {
        this.searchResults.inactivities = [];
        this.searchResults.configSmartNodes = false;
      } else {
        this.searchResults.inactivities = this.filterInactivities();
        this.searchResults.configSmartNodes = this.filterConfigSmartNodes();
      }
    },
    doReplaceAsk() {
      this.replaceTest = true;
      this.doReplaceAll();
      this.$root.$emit('bv::show::modal', 'replace-all-confirmation');
    },
    doReplaceReal() {
      this.replaceTest = false;
      this.doReplaceAll();
    },
    doReplaceAll() {
      if (!this.searchQuery || !this.searchQuery.trim()) {
        this.searchResults = null;
        this.replaceCount = 0;
        return;
      }

      // Only replace in those we will find with search
      this.doSearch();

      for (const node of this.searchResults.nodes) {
        for (const [activityId, activity] of Object.entries(node.activities)) {
          if (activity.type === RESPONSE || activity.type === COMPOUND_RESPONSE) {
            const text = activity.text;
            const replacedText = text.replace(this.searchRegExp, this.replaceText);
            if (text !== replacedText) {
              this.replaceCount++;
              if (!this.replaceTest) {
                this.setActivityResponseText({
                  nodeId: node.id,
                  activityId,
                  text: replacedText,
                });
              }
            }
          }
        }
      }

      for (const index of this.searchResults.inactivities) {
        const inactivity = this.getInactivity[index];
        const text = inactivity.msg;
        const replacedText = text.replace(this.searchRegExp, this.replaceText);
        if (text !== replacedText) {
          this.replaceCount++;
          if (!this.replaceTest) {
            this.setSingleInactivity({
              index,
              inactivity: {
                ...inactivity,
                msg: replacedText,
              },
            });
          }
        }
      }

      if (this.searchResults.configSmartNodes) {
        const optionsText = this.multipleChoice.optionsQuery;
        const replacedOptions = optionsText.replace(this.searchRegExp, this.replaceText);
        if (optionsText !== replacedOptions) {
          this.replaceCount++;
          if (!this.replaceTest) {
            this.setMultipleChoiceOptionsQuery({ query: replacedOptions });
          }
        }

        const confirmText = this.multipleChoice.confirmQuery;
        const replacedConfirm = confirmText.replace(this.searchRegExp, this.replaceText);
        if (confirmText !== replacedConfirm) {
          this.replaceCount++;
          if (!this.replaceTest) {
            this.setMultipleChoiceConfirmQuery({ query: replacedConfirm });
          }
        }

        const otherText = this.multipleChoice.otherText;
        const replacedOther = otherText.replace(this.searchRegExp, this.replaceText);
        if (otherText !== replacedOther) {
          this.replaceCount++;
          if (!this.replaceTest) {
            this.setMultipleChoiceOtherText({ otherText: replacedOther });
          }
        }
      }
    },
    async exportResponses(exportFormat) {
      const responses = await this.getAllResponses({ botId: this.activeBotId, exportFormat });

      const fileData = URL.createObjectURL(responses);
      // enforce download of the file
      const link = document.createElement('a');
      link.setAttribute('href', fileData);
      const extension = exportFormat === 'excel' ? 'xlsx' : 'json';
      link.setAttribute('download', `${this.botName}_responses.${extension}`);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    },
    importResponses() {
      const botId = this.$store.state.botManipulation.activeBot.id;
      const formData = new FormData();
      formData.append('bot_id', botId);
      formData.append('file', this.importForm.file);
      formData.append('options', this.importForm.options);
      this.importProcessing = true;
      axios.patch(endpoints.phrases, formData, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      }).then((result) => {
        this.importProcessing = false;
        const importStatus = result.data.status;
        this.importResult = importStatus;
      }).catch((e) => {
        this.importProcessing = false;
        if (e && e.response && e.response.data && e.response.data.error) {
          this.importResult = {
            errors: [{ msg: e.response.data.error }],
            warnings: [],
            infos: [],
          };
          return;
        }
        this.importResult = {
          errors: [{ msg: e }],
          warnings: [],
          infos: [],
        };
      });
    },
    showDrafts() {
      this.draftsOnly = true;
      this.searchQuery = '';
      this.doSearch();
    },
    approveAllDrafts() {
      this.showDrafts();
      if (this.searchResults.configSmartNodes) {
        this.setMultipleChoiceApproved({ approved: true });
      }
      if (this.searchResults.inactivities.length) {
        this.searchResults.inactivities.forEach((index) => {
          const inactivityCopy = this.getInactivity;
          const newInactivity = {
            ...this.getInactivity[index],
            responseApproved: true,
          };
          inactivityCopy[index] = newInactivity;
          this.updateInactivity({ inactivity: inactivityCopy });
        });
      }
      if (this.searchResults.nodes.length) {
        this.searchResults.nodes.forEach((node) => {
          if (node.options.nodeType === nodeTypes.MULTIPLE_CHOICE) {
            const activityIds = this.responseIds(node);
            activityIds.forEach((element) => {
              this.setApproved({
                approved: true,
                nodeId: node.id,
                activityId: element,
              });
            });
            this.setResponseApproved({ approved: true, id: node.id });
          } else {
            const activityIds = this.responseIds(node);
            activityIds.forEach((element) => {
              this.activityId = element;
              this.setApproved({
                approved: true,
                nodeId: node.id,
                activityId: element,
              });
            });
          }
        });
      }
      this.draftsOnly = false;
      this.searchQuery = '';
      this.searchResults = null;
    },
    responseIds(node) {
      const ids = [];
      for (const id of node.activityIds) {
        const activity = node.activities[id];
        if (activity.type === RESPONSE && !activity.responseApproved) {
          ids.push(id);
        }
      }
      return ids;
    },
  },
  validations: {
    importForm: {
      options: {
      },
      file: {
        required,
        notNull(value) {
          return !!value;
        },
      },
    },
  },
};
</script>
<style scoped>
.same-width{
  width:92px;
}
</style>
