import axios from 'axios';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import endpoints from '@/js/urls';
import { filterDateBuilder } from '@/js/utils';

const defaultFilters = {
  selectedVariant: '',
  selectedLanguage: 'any',
  freeText: '',
  selectedRatings: [],
  ignoreNodeIds: [],
  passThroughNodeIds: [],
  startDate: new Date(Date.now() - (7 * 60 * 60 * 24 * 1000)),
  endDate: new Date(),
  selfServedFilter: 'all',
};

export const initialState = {
  filters: cloneDeep(defaultFilters),
  selectedDataOrigins: [],
  isFetching: false,
  isFetchingDatasets: false,
  activeTab: 0,
  datasets: [],
  selectedDataset: null,
  tableView: false,
  focusNode: null,
};

export const nodeLabelGetters = {
  selectedDataOrigins: (state) => state.selectedDataOrigins,
  activeDataset(state) {
    const found = state.datasets.find((e) => e.id === state.selectedDataset);
    return found || null;
  },
  getTableView: (state) => state.tableView,
  getFocusNode: (state) => state.focusNode,
};
export const nodeLabelMutations = {
  setSelectedDataOrigins(state, value) {
    state.selectedDataOrigins = value;
  },
  setIsFetching(state, value) {
    state.isFetching = value;
  },
  setIsFetchingDatasets(state, value) {
    state.isFetchingDatasets = value;
  },
  setDatasets(state, value) { state.datasets = value; },
  resetTrainingFilter(state) {
    state.filters = cloneDeep(defaultFilters);
  },
  updateTrainingFilter(state, { key, newValue }) {
    Vue.set(state.filters, key, newValue);
  },
  setActiveTab(state, value) {
    state.activeTab = value;
  },
  setSelectedDataset(state, value) {
    state.selectedDataset = value;
  },
  setTableView(state, value) {
    state.tableView = value;
  },
  setFocusNode(state, value) {
    state.focusNode = value;
  },
};
export const nodeLabelActions = {
  async fetchDatasets({ rootState, dispatch, commit }) {
    commit('setIsFetchingDatasets', true);
    try {
      const resp = await axios.get(endpoints.labelDataset, {
        params: { bot_id: rootState.botManipulation.activeBotId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setDatasets', resp.data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch datasets',
        text: error.message,
        variant: 'danger',
      }, { root: true });
      throw error;
    } finally {
      commit('setIsFetchingDatasets', false);
    }
  },
  async createDataset({ rootState, dispatch }, {
    name, origin, visualizationId, includeTopics,
  }) {
    try {
      let resp;
      if (origin === 'conversation logs') {
        const logsFilter = rootState.chatlogs.logsFilter;
        const selectedDataOrigins = logsFilter.selectedDataOrigins.map((x) => x.rawValue);
        const filters = {
          variant_id: logsFilter.selectedVariant,
          start_date: filterDateBuilder(logsFilter.startDate, logsFilter.startTime).toISOString(),
          end_date: filterDateBuilder(logsFilter.endDate, logsFilter.endTime).toISOString(),
          language: logsFilter.selectedLanguage,
          free_text: logsFilter.freeText,
          selected_data_origins: selectedDataOrigins,
          requireNodes: logsFilter.passThroughNodeIds,
          ignoreNodes: logsFilter.ignoreNodeIds,
          require_immediate_order: logsFilter.requireImmediateOrder,
          require_only_one: logsFilter.requireOnlyOne,
          coverage_filter: logsFilter.coverageFilter,
          self_served_filter: logsFilter.selfServedFilter,
          must_have_traceback: logsFilter.mustHaveTraceback,
          must_have_error: logsFilter.mustHaveError,
          selected_ratings: logsFilter.selectedRatings,
          is_labelable: true,
          new_label_data_set: {
            name,
            bot: rootState.botManipulation.activeBotId,
            origin,
          },
        };

        resp = await axios.post(`${endpoints.labelDataset}from_filters/`,
          { bot_id: rootState.botManipulation.activeBotId, ...filters },
          {
            params: { bot_id: rootState.botManipulation.activeBotId },
            headers: { Authorization: `JWT ${rootState.auth.jwt}` },
          },
        );
      } else {
        resp = await axios.post(`${endpoints.labelDataset}from_visualization/`,
          {
            bot: rootState.botManipulation.activeBotId,
            visualization_id: visualizationId,
            include_topics: includeTopics,
            new_label_data_set: {
              name,
              bot: rootState.botManipulation.activeBotId,
              origin,
            },
          },
          {
            params: { bot_id: rootState.botManipulation.activeBotId },
            headers: { Authorization: `JWT ${rootState.auth.jwt}` },
          });
      }
      if (resp.status === 201) {
        dispatch('sidebar/showWarning', {
          title: 'Dataset creation complete',
          text: 'You can access newly created dataset on the Training page.',
          variant: 'success',
        }, { root: true });
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to create dataset',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async deleteDataset({ rootState, dispatch }, id) {
    try {
      await axios.delete(`${endpoints.labelDataset}${id}`, {
        params: { bot_id: rootState.botManipulation.activeBotId, dataset_id: id },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      dispatch('fetchDatasets');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to delete dataset',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters: nodeLabelGetters,
  mutations: nodeLabelMutations,
  actions: nodeLabelActions,
};
