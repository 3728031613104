<template>
  <b-row>
    <b-col>
      <div v-if="isFetching || !isReady" class="text-center">
        <b-spinner class="text-center my-4" />
        <br>
        The visualization is being computed. This might take a few minutes.
      </div>
      <template v-else-if="isReady">
        <DataDisplayTopics :data="visualizationData" :table-fields="['name', 'count']" />
      </template>
      <template v-else>
        The visualization failed to produce.
      </template>
    </b-col>
  </b-row>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import DataDisplayTopics from '@/components/StatisticsTopics/DataDisplayTopics.vue';

export default {
  name: 'VisualizationBody',
  components: {
    DataDisplayTopics,
  },
  computed: {
    ...mapState('dataExploration/visualizations', ['isFetchingData', 'visualizationMetas', 'visualizationDatas']),
    isFetching() {
      return this.isFetchingData;
    },
    isReady() {
      return this.visualization.ready;
    },
    visualizationFailed() {
      return this.visualization.computationFailed;
    },
    visualization() {
      return this.visualizationMetas[this.visualizationId] || {};
    },
    visualizationData() {
      return this.visualizationDatas[this.visualizationId]?.encodedVisualization || {};
    },
    visualizationId() {
      return this.$route.params.visualizationId;
    },
  },
  watch: {
    isReady(newValue) {
      if (newValue) {
        this.fetchDataIfReadyAndNeeded(this.visualizationId);
      }
    },
    visualizationId(newVisualizationId, oldVisualizationId) {
      if (newVisualizationId !== oldVisualizationId) {
        this.fetchDataIfReadyAndNeeded(this.visualizationId);
      }
    },
  },
  async created() {
    await this.fetchDataIfReadyAndNeeded(this.visualizationId);
  },
  methods: {
    ...mapActions('dataExploration/visualizations', [
      'fetchVisualizationData',
    ]),
    async fetchDataIfReadyAndNeeded(visualizationId) {
      const visualizationMeta = this.visualizationMetas[visualizationId] || {};
      if (!visualizationMeta.ready) {
        return;
      }
      const visualizationData = this.visualizationDatas[visualizationId] || {};
      if (visualizationData && visualizationData.encodedVisualization) {
        return;
      }
      await this.fetchVisualizationData({ visualizationId });
    },
  },
};
</script>
