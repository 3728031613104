<template>
  <b-modal
    id="api-action-template-picker-modal"
    title="Pick an integration template"
    :ok-disabled="$v.modalContent.$invalid"
    ok-title="Create"
    @ok="createIntegration"
    @show="prepModal"
  >
    <b-breadcrumb>
      <b-breadcrumb-item
        v-for="item in breadcrumbItems"
        :key="item.id"
        :text="item.displayName"
        :disabled="item.disabled"
        @click="item.clickHandler"
      />
    </b-breadcrumb>

    <div
      v-if="modalContent.chosenService === null || modalContent.chosenTemplate === null"
    >
      <b-btn
        v-for="templateForPath in buttonsForCurrentProgression"
        :key="templateForPath.id"
        block
        variant="info"
        @click="templateForPath.clickHandler"
      >
        {{ templateForPath.displayName }}
      </b-btn>
    </div>
    <div
      v-else
    >
      <b-form @submit.prevent>
        <b-form-group>
          <b-form-input
            id="templateName"
            v-model="modalContent.chosenAPIActionName"
            placeholder="Name for integration (cannot be changed)"
            type="text"
            :state="$v.modalContent.chosenAPIActionName.$invalid ? false : null"
            @keyup.enter="$v.modalContent.$invalid ? null : createIntegration()"
          />
          <b-form-invalid-feedback
            id="input-group-feedback"
          >
            <div v-if="!$v.modalContent.chosenAPIActionName.required">
              Specify a name for the integration
            </div>
            <div v-if="!$v.modalContent.chosenAPIActionName.nameIsUnique">
              This name is already in use for another integration
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
  </b-modal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import apiActionTemplates from '@/js/apiActionTemplates';

export default {
  name: 'APIActionTemplatePickerModal',
  mixins: [validationMixin],
  data() {
    return {
      modalContent: {
        chosenService: null,
        chosenTemplate: null,
        chosenAPIActionName: null,
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotActions',
      'getBotActionByName',
    ]),
    /**
     * The modal has three "progressions":
     * 1. User has not chosen a service yet
     * 2. User has chosen a service, but not yet a template from service
     * 3. User has chosen a service and a template from service, and now needs to specify a name
     * for that corresponding integration to-be-created
     */
    buttonsForCurrentProgression() {
      const self = this;
      if (this.modalContent.chosenService === null) {
        // User has not chosen a service yet
        // Enrich the serviceDefinitions with a clickHandler
        const serviceDefinitions = apiActionTemplates.services;
        return serviceDefinitions.map((Definition) => {
          const copy = { ...Definition };
          copy.clickHandler = () => self.chooseService(copy.id);
          return copy;
        });
      }
      if (this.modalContent.chosenService !== null) {
        return apiActionTemplates.templates[this.modalContent.chosenService]
          .map((templateDefinition) => {
            const copy = { ...templateDefinition };
            copy.clickHandler = () => self.pickTemplate(copy.id);
            return copy;
          });
      }
      return [];
    },
    breadcrumbItems() {
      const breadcrumbs = [];
      const allServicesHome = {
        displayName: 'All services',
        id: 'allServices',
        disabled: false,
        clickHandler: () => this.goToStart(),
      };
      breadcrumbs.push(allServicesHome);

      if (this.modalContent.chosenService === null) {
        // We're at "home" / start
        return breadcrumbs;
      }

      if (this.modalContent.chosenService !== null && this.modalContent.chosenTemplate === null) {
        // User has chosen service, but has not yet picked a template
        const serviceDefinition = apiActionTemplates.services
          .find((x) => x.id === this.modalContent.chosenService);
        breadcrumbs.push({
          displayName: serviceDefinition.displayName,
          id: serviceDefinition.id,
          disabled: true,
          clickHandler: () => this.goToStart(), // Ignore the clickHandler here, click is disabled
        });
        return breadcrumbs;
      } if (this.modalContent.chosenService !== null
          && this.modalContent.chosenTemplate !== null) {
        // User has chosen a service and a template
        const serviceDefinition = apiActionTemplates.services
          .find((x) => x.id === this.modalContent.chosenService);
        breadcrumbs.push({
          displayName: serviceDefinition.displayName,
          id: serviceDefinition.id,
          disabled: false,
          clickHandler: () => this.goBackToServiceTemplates(),
        });
      }

      breadcrumbs.push({
        displayName: 'Specify name',
        id: 'specifyName',
        disabled: true,
        clickHandler: () => this.goToStart(), // Ignore the clickHandler here, click is disabled
      });

      return breadcrumbs;
    },
  },
  methods: {
    ...mapActions('botManipulation', [
      'createAPIActionFromTemplate',
    ]),
    prepModal() {
      this.modalContent = {
        chosenService: null,
        chosenTemplate: null,
        chosenAPIActionName: null,
      };
    },
    pickTemplate(templateName) {
      this.modalContent.chosenTemplate = templateName;
      // Pre-populate the template name
      const serviceTemplates = apiActionTemplates.templates[this.modalContent.chosenService];
      const chosenTemplate = serviceTemplates
        .find((x) => x.id === this.modalContent.chosenTemplate);

      // Below is a nicety for endusers, so that they get a suggested value
      this.modalContent.chosenAPIActionName = chosenTemplate.template.name;
    },
    chooseService(serviceIdentifier) {
      this.modalContent.chosenService = serviceIdentifier;
    },
    goToStart() {
      this.modalContent.chosenService = null;
    },
    goBackToServiceTemplates() {
      this.modalContent.chosenTemplate = null;
    },
    createIntegration() {
      // Create integration aptly named
      this.createAPIActionFromTemplate({
        serviceIdentifier: this.modalContent.chosenService,
        templateId: this.modalContent.chosenTemplate,
        apiActionName: this.modalContent.chosenAPIActionName,
      });
      // Raise event so that parent can choose to open the EditAction modal
      this.$emit('integrationCreatedId', this.modalContent.chosenAPIActionName);
      this.$bvModal.hide('api-action-template-picker-modal');
    },
  },
  validations: {
    modalContent: {
      chosenService: {
        required,
      },
      chosenTemplate: {
        required,
      },
      chosenAPIActionName: {
        required,
        nameIsUnique(suggestedName) {
          const names = this.getBotActions.map((x) => x.name);
          return !names.includes(suggestedName);
        },
      },
    },
  },
};
</script>
