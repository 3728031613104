<template>
  <div>
    <p class="mb-2">
      The <strong>Graph view</strong> provides a visual way to navigate and edit the conversation
      flow of your bot.
    </p>
    <p>
      The graph view consists of three key components (see screenshot below).
      <ol>
        <li>A menubar with buttons to easy navigation, bookmark nodes, and more.</li>
        <li>The visual diagram of the flow of the bot.</li>
        <li>
          The compact edit-sidebar with the most commonly used features to edit
          each node of the bot.
        </li>
      </ol>
    </p>
    <div class="text-center">
      <b-img
        class="w-75"
        fluid
        :src="require(`@/assets/featureImages/graph_view.png`)"
        alt="Overview of the BotStudio graph view"
      />
    </div>
  </div>
</template>
<script>
export default { name: 'GraphViewDescription' };
</script>
