<template>
  <b-form-group
    class="mb-1"
    :label-class="graphView ? labelClass : `text-right ${labelClass}`"
    :label-cols="graphView ? '12' : '3'"
    :content-cols="graphView ? '12' : '9'"
    :state="state"
  >
    <template #label>
      <TooltippedText
        v-if="tooltip !== undefined"
        :value="tooltip"
      />
      {{ label }}
    </template>
    <slot />
    <template slot="invalid-feedback">
      <slot name="invalid-feedback" />
    </template>
  </b-form-group>
</template>
<script>
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'ActivityFormGroup',
  components: {
    TooltippedText,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    graphView() {
      return this.$route.name === 'graph';
    },
  },
};
</script>
