<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="primary"
    @shown="focusInput"
  >
    <template #icon>
      <font-awesome-icon icon="address-card" />
    </template>
    <template #header>
      {{ cappedText }}
    </template>
    <compound-response-activity-inputs
      :ref="activityId"
      :key="activityId"
      :node-id="nodeId"
      :activity-id="activityId"
    />
  </base-activity>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { truncateString } from '@/js/utils';
import { PHRASE_REGEX } from '@/js/constants';
import { applyThisArgs } from '@/js/storeHelpers';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import CompoundResponseActivityInputs from './CompoundResponseActivityInputs.vue';

export default {
  name: 'CompoundResponseActivity',
  components: {
    BaseActivity,
    CompoundResponseActivityInputs,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        getResponseText: 'activityText',
      }),
      'nodeId',
      'activityId',
    ),
    ...mapGetters('botManipulation/activeBot', ['phrasesDict']),
    cappedText() {
      const str = this.getResponseText;
      const matches = str.match(PHRASE_REGEX);
      const substrings = str.split(PHRASE_REGEX);
      const result = substrings.length < 2 ? substrings
        : substrings.reduce((acc, val, idx) => acc.concat(val, matches[idx]), [])
          .filter((e) => e && e.length > 0);
      const formattedResult = result.map((e) => {
        if (e.match(PHRASE_REGEX)) {
          return this.phrasesDict[e.substring(8, 44)]?.text;
        }
        return e;
      });
      return truncateString(formattedResult.join(' ')
        .replaceAll(/<[^>]*>?/gm, '')
        .replaceAll('&nbsp;', '')
        .replaceAll('&gt;', '')
        .replaceAll('&lt;', ''), 60);
    },
  },
  methods: {
    focusInput() {
      this.$refs[this.activityId]?.focusInput();
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
};
</script>
<style>
.image {
  border-radius: 0px 0.25rem 0.25rem 0px  !important;
}
.prepend{
  width:100px;
}
</style>
