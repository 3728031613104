<template>
  <b-list-group-item class="r-25 mb-2 bg-light">
    <p
      v-b-tooltip.noninteractive.hover.viewport="shown ? 'Click to hide' : 'Click to show'"
      class="cursor-pointer font-weight-bold"
      @click="shown = !shown"
    >
      <font-awesome-icon :icon="shown ? 'angle-down' : 'angle-right'" />
      Description
    </p>
    <b-collapse :visible="shown">
      <slot />
    </b-collapse>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'DescriptionBox',
  data() {
    return {
      shown: false,
    };
  },
};
</script>
