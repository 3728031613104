<template>
  <b-modal
    :id="modalId"
    title="Link child"
    hide-footer
    @show="focusOnInput"
  >
    <div>
      <completion-input
        ref="autocomplete"
        :placeholder="'Link Child'"
        :completions="potentialChildrenNames"
        value=""
        @input="linkNode($event)"
      />
    </div>
  </b-modal>
</template>

<script>
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import { mapGetters } from 'vuex';
import botManipulationMixin from '@/mixins/BotManipulationMixin';

export default {
  name: 'LinkNodeModal',
  components: {
    CompletionInput,
  },
  mixins: [botManipulationMixin],
  props: {
    nodeId: {
      required: false,
      type: String,
      default: null,
    },
    modalId: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nodeByName',
      'nodeNames',
    ]),
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    activeNode() {
      return this.nodeById(this.nodeId);
    },
    children() {
      return this.activeNode.children.map((id) => this.nodeById(id));
    },
    childNames() {
      return this.children.map((n) => n.name);
    },
    potentialChildrenNames() {
      const tmpSet = new Set(this.childNames);
      return this.nodeNames.filter((n) => !tmpSet.has(n));
    },
  },
  methods: {
    focusOnInput() {
      // the only way I could make the autofocus work on a modal with autocomplete
      setTimeout(() => {
        document.getElementsByClassName('autocomplete-input')[0].focus();
      }, 10);
    },
    linkNode(nameArray) {
      if (!nameArray) {
        return;
      }
      const nodeIds = this.nodeNames2Ids(this.childNames.concat(nameArray));
      if (!nodeIds) return;
      this.setChildrenByIdArray(nodeIds);
      this.$bvModal.hide(this.modalId);
      this.$emit('nodeAdded');
    },
  },
};
</script>
