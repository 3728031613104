export const tagOptionList = [
  {
    name: 'Pause',
    id: 'break',
    description: 'Inserts a brief pause.',
    support: ['azure', 'azure-on-premise', 'amazon', 'google'],
    output: ({ time, unit }) => `<break time="${time}${unit}"/>`,
    attributes: {
      unit: {
        type: 'select',
        options: {
          s: 'seconds',
          ms: 'milliseconds',
        },
        required: true,
        description: 'Select a unit for the pause time.',
      },
      time: {
        type: 'number',
        required: true,
        description: 'Enter an amount of seconds or milliseconds.',
      },
    },
  },
  {
    name: 'Play Audio',
    id: 'audio',
    description: 'Plays an MP3 file.',
    support: ['azure', 'azure-on-premise', 'google'],
    output: ({ path }) => `<audio src="${path}"/>`,
    attributes: {
      path: {
        type: 'text',
        required: true,
        description: 'Enter the URL path to the MP3 file.',
      },
    },
  },
  {
    name: 'Spell Out Word',
    id: 'spellout',
    description: 'Spell out a word letter by letter.',
    support: ['azure', 'azure-on-premise', 'google', 'amazon'],
    output: ({ text }) => `<say-as interpret-as="characters">${text}</say-as>`,
    attributes: {
      text: {
        type: 'text',
        required: true,
        description: 'Enter the text that you want to spell out.',
      },
    },
  },
  {
    name: 'Paragraph',
    id: 'paragraph',
    description: 'A paragraph is similar to sentences but adds stronger pauses after each element. It is possible to combine paragraphs and sentences by nesting sentences inside paragraphs.',
    support: ['azure', 'azure-on-premise', 'google', 'amazon'],
    output: ({ text }) => `<p>${text}</p>`,
    attributes: {
      text: {
        type: 'text',
        required: true,
        description: 'Enter your paragraph text.',
      },
    },
  },
  {
    name: 'Sentence',
    id: 'sentence',
    description: 'A sentences but adds small pauses after each element. It is possible to nest sentences inside paragraphs.',
    support: ['azure', 'azure-on-premise', 'google', 'amazon'],
    output: ({ text }) => `<s>${text}</s>`,
    attributes: {
      text: {
        type: 'text',
        required: true,
        description: 'Enter your sentence text.',
      },
    },
  },
  {
    name: 'Fraction',
    id: 'fraction',
    description: 'Fraction is a way to pronounce fractions. For instance, 5+1/2 will be pronounced as five and a half.',
    support: ['azure', 'azure-on-premise', 'google', 'amazon'],
    output: ({ text }) => `<say-as interpret-as="fraction">${text}</say-as>`,
    attributes: {
      text: {
        type: 'text',
        required: true,
        description: 'Enter your fraction.',
      },
    },
  },
  {
    name: 'Date',
    id: 'date',
    description: 'Date is a way to pronounce dates. It is possible to select between multiple formats and pick your preference. You must separate day, month, and year with dashes (-).',
    support: ['azure', 'azure-on-premise', 'google', 'amazon'],
    output: ({ format, date }) => `<say-as interpret-as="date" format="${format}">${date}</say-as>`,
    attributes: {
      format: {
        type: 'select',
        options: {
          mdy: 'mdy (month-day-year)',
          dmy: 'dmy (day-month-year)',
          ymd: 'ymd (year-month-day)',
          md: 'md (month-day)',
          dm: 'dm (day-month)',
          ym: 'ym (year-month)',
          my: 'my (month-year)',
          d: 'd (day)',
          m: 'm (month)',
          y: 'y (year)',
        },
        required: true,
        description: 'Select the format of the date.',
      },
      date: {
        type: 'text',
        required: true,
        description: 'Enter the date in the selected format.',
      },
    },
  },
  {
    name: 'Time',
    id: 'time',
    description: 'Time is a way to pronounce time or clocks. It is possible to select between multiple formats and pick your preference.',
    support: ['azure', 'azure-on-premise', 'google', 'amazon'],
    output: ({ format, time }) => `<say-as interpret-as="time" format="${format}">${time}</say-as>`,
    attributes: {
      format: {
        type: 'select',
        options: {
          hms12: 'hour:minute:second AM/PM',
          hms24: 'hour:minute:second',
        },
        required: true,
        description: 'Select the format of the time.',
      },
      time: {
        type: 'text',
        required: true,
        description: 'Enter the time in the selected format.',
      },
    },
  },
  {
    name: 'Telephone Number',
    id: 'telephone',
    description: 'Pronounce telephone numbers in a way that is easier to remember and understand.',
    support: ['google', 'amazon'],
    output: ({ phone }) => `<say-as interpret-as="telephone">${phone}</say-as>`,
    attributes: {
      phone: {
        type: 'text',
        required: true,
        description: 'Enter a phone number.',
      },
    },
  },
  {
    name: 'Telephone Number',
    id: 'telephoneazure',
    description: 'Pronounce telephone numbers in a way that is easier to remember and understand. Code is the country code for the telephone number.',
    support: ['azure', 'azure-on-premise'],
    output: ({ code, phone }) => `<say-as interpret-as="telephone" format="${code}">${phone}</say-as>`,
    attributes: {
      code: {
        type: 'number',
        required: true,
        description: 'Enter the country code for the phone number.',
      },
      phone: {
        type: 'text',
        required: true,
        description: 'Enter a phone number.',
      },
    },
  },
  {
    name: 'Phonetic Pronunciation',
    id: 'phonemeamazon',
    description: 'Define phonetic pronunciation for specific text.',
    support: ['amazon'],
    output: ({ alphabet, ph, text }) => `<phoneme alphabet="${alphabet}" ph="${ph}">${text}</phoneme>`,
    attributes: {
      alphabet: {
        type: 'select',
        options: {
          ipa: 'International Phonetic Alphabet (IPA)',
          'x-sampa': 'Extended Speech Assessment Methods Phonetic Alphabet (X-SAMPA)',
        },
        required: true,
        description: 'Select which phonetic alphabet to use.',
      },
      ph: {
        type: 'text',
        required: true,
        description: 'Enter the pronounciation using the selected phonetic alphabet.',
      },
      text: {
        type: 'text',
        required: true,
        description: 'Enter the pronounciated text.',
      },
    },
  },
  {
    name: 'Phonetic Pronunciation',
    id: 'phonemeazure',
    description: 'Define phonetic pronunciation for specific text.',
    support: ['azure', 'azure-on-premise'],
    output: ({ alphabet, ph, text }) => `<phoneme alphabet="${alphabet}" ph="${ph}">${text}</phoneme>`,
    attributes: {
      alphabet: {
        type: 'select',
        options: {
          ipa: 'International Phonetic Alphabet (IPA)',
          ups: 'Universal Phone Set (UPS)',
        },
        required: true,
        description: 'Select which phonetic alphabet to use.',
      },
      ph: {
        label: 'Phonetic Spelling (ph)',
        type: 'text',
        required: true,
        description: 'Enter the pronounciation using the selected phonetic alphabet.',
      },
      text: {
        type: 'text',
        required: true,
        description: 'Enter the text that will be pronounced using the phonetic spelling above.',
      },
    },
  },
];

const validSSMLTags = ['break', 'audio', 'say-as', 'phoneme'];

export const ssmlRegex = new RegExp(`(&lt;|<)/?(${validSSMLTags.join('|')}).*?(&gt;|>)`, 'g');
