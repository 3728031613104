<template>
  <b-card
    v-if="isFetching"
    class="text-center text-muted r-75 mt-3"
  >
    <b-spinner style="width: 3rem; height: 3rem;" />
  </b-card>
  <b-card
    v-else-if="!chatsData.length"
    class="text-center text-muted r-75 mt-3"
  >
    {{ needsRefresh ? 'Click "Refresh" to fetch training data.' : 'There is no data to show' }}
  </b-card>
  <b-card
    v-else
    body-class="pb-0 "
    footer-class="bg-white r-50"
    class="r-75 mt-3 h-100 flex-fill"
  >
    <b-row>
      <b-col>
        <b-card-title class="my-auto">
          Label conversation
        </b-card-title>
      </b-col>
      <b-col v-if="focusNode" cols="auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          size="sm"
        />
      </b-col>
    </b-row>

    <template v-if="!focusNode">
      <hr class="mb-1">
      <b-list-group>
        <b-list-group-item v-if="showUnlabeledCount" class="py-1 border-0 d-flex justify-content-start align-items-center">
          <font-awesome-icon color="grey" icon="comments" class="mr-3" />
          Found {{ unlabeledChatCount }} unlabeled conversation{{ unlabeledChatCount > 1 ? 's' : '' }}
        </b-list-group-item>
        <template v-if="!focusNode">
          <b-list-group-item class="py-1 border-0 d-flex justify-content-start align-items-center">
            <font-awesome-icon :color="isLabeledSingle ? 'green' : 'grey'" icon="tags" class="mr-3 pr-1" />
            {{ isLabeledSingle ? 'This conversation is labeled' : "This conversation is not labeled" }}
          </b-list-group-item>
          <b-list-group-item class="py-1 border-0 d-flex justify-content-start align-items-center">
            <font-awesome-icon color="grey" icon="clock" class="mr-3 pr-1" />
            {{ timestamp }}
          </b-list-group-item>
        </template>
      </b-list-group>
      <hr class="mt-1">
    </template>
    <div v-if="focusNode">
      <div v-if="showUnlabeledCount" class="list-group mb-3 d-flex justify-content-start align-items-center" style="flex-direction: row">
        <font-awesome-icon color="grey" icon="comments" class="mr-3" />
        Found {{ unlabeledChatCount }} unlabeled conversation{{ unlabeledChatCount > 1 ? 's' : '' }}
      </div>
      <b-table
        :fields="fields"
        :items="chatsData"
        show-empty
      >
        <template #cell(label)="row">
          <b-form-select
            :options="getNodeChildren(focusNode)"
            :value="getCurrentLabelValue(row.item)"
            @input="v => updateCurrentLabelValue(row.item, v)"
          />
        </template>
        <template #cell(show_details)="row">
          <b-button
            v-b-tooltip.hover.viewport.noninteractive="'Details'"
            size="sm"
            variant="primary"
            @click="toggleDetails(row)"
          >
            <font-awesome-icon :icon="row.detailsShowing ? 'angle-up' : 'angle-down'" />
          </b-button>
        </template>
        <template #row-details="row">
          <label-content
            :chat-data="row.item.data"
            :chat-id="row.item.chatId"
            :query-label="queryLabels[row.item.chatId]"
            :show-log="false"
            @newLabel="v => newLabel(v)"
            @nextLabel="v => nextLabel(v)"
            @resetLabel="v => resetLabel(v)"
          />
        </template>
        <template #cell(submitCol)="row">
          <b-button
            v-b-tooltip.hover.viewport.noninteractive="row.item.isLabeled && !row.item.dirty ? '' : 'Submit'"
            :disabled="row.item.isLabeled && !row.item.dirty"
            :variant="row.item.isLabeled && !row.item.dirty ? 'success' : 'primary'"
            size="sm"
            @click="submit(row.item.chatId, true, true)"
          >
            <font-awesome-icon icon="check" />
          </b-button>
          <span :id="`popover${row.item.chatId}`">
            <font-awesome-icon
              v-if="row.item.includeSubLabels && !hasManualLabels(row.item)"
              icon="exclamation-circle"
              class="ml-2 text-warning h5 mb-0 mt-1"
            />
          </span>
          <b-popover :target="`popover${row.item.chatId}`" triggers="hover" placement="top">
            Second level labels will be submitted
            <b-button
              variant="primary"
              class="mt-1"
              block
              size="sm"
              @click="submit(row.item.chatId, true, false)"
            >
              submit only "{{ nameOfId(getCurrentLabelValue(row.item)) }}"
            </b-button>
          </b-popover>
        </template>
        <template #cell(linkCol)="row">
          <b-btn
            v-b-tooltip.hover
            title="View additional details and get direct link"
            size="sm"
            variant="primary"
            @click="goToChatlogDetailsPage(row.item.chatId)"
          >
            <font-awesome-icon icon="external-link-alt" />
          </b-btn>
        </template>
      </b-table>
    </div>
    <div v-else class="mt-3 mb-1 pt-1">
      <label-content
        :chat-data="chatsData[0].data"
        :chat-id="chatsData[0].chatId"
        :query-label="queryLabels[chatsData[0].chatId]"
        :show-log="true"
        @newLabel="v => newLabel(v)"
        @nextLabel="v => nextLabel(v)"
        @resetLabel="v => resetLabel(v)"
      />
    </div>
    <template v-if="!focusNode" #footer>
      <b-row>
        <b-col class="my-auto text-right">
          <b-button class="mr-4" @click="excludeFromTraining(chatsData[0].chatId)">
            Exclude from training
            <tooltipped-text
              class="ml-2"
              value="Exclude this conversation from training. Classifiers will not be trained
                     using this conversation and you will not see it on this page again.
                     Note that this cannot currently be undone."
            />
          </b-button>
          <b-button class="mr-4" @click="fetchChatlog()">
            Skip & label later
            <tooltipped-text class="ml-2" value="Skip this conversation for now and label it at a later time" />
          </b-button>
          <b-button variant="primary" class="px-3" @click="submit(chatsData[0].chatId, false, false)">
            Submit
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>
<script>
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { chatlogTransformer, eventsByNode, getTopPrediction } from '@/js/utils';
import LabelContent from '@/pages/Training/LabelContent.vue';
import TooltippedText from '@/components/TooltippedText.vue';
import endpoints from '@/js/urls';
import { nodeTypes } from '@/js/constants';

export default {
  name: 'LabelConversation',
  components: {
    LabelContent,
    TooltippedText,
  },
  props: {
    nodeLabelFilter: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    return {
      chatsData: [],
      queryLabels: {},
      timestamp: null,
      unlabeledChatCount: null,
      showUnlabeledCount: true,
      fields: [
        'userInput',
        'livePrediction',
        'label',
        { key: 'show_details', label: '', tdClass: 'action-col' },
        { key: 'submitCol', label: '', tdClass: 'submit-col' },
        { key: 'linkCol', label: '', tdClass: 'action-col' },
      ],
      needsRefresh: false,
      perPage: 10,
      currentPage: 1,
      totalItems: null,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nameOfId',
      'nodesAsList',
    ]),
    ...mapState('nodeLabels', [
      'filters',
      'isFetching',
      'selectedDataset',
      'tableView',
      'focusNode',
    ]),
    ...mapGetters('nodeLabels', [
      'activeDataset',
      'selectedDataOrigins',
    ]),
    ...mapGetters('botManipulation', [
      'activeBotId',
    ]),
    isLabeledSingle() {
      return this.chatsData.length && this.chatsData[0].isLabeled;
    },
  },
  watch: {
    selectedDataset(n) {
      if (n) {
        this.fetchChatlog();
      }
    },
    focusNode() {
      this.needsRefresh = true;
      this.chatsData = [];
      this.currentPage = 1;
    },
    currentPage() {
      this.fetchChatlog();
    },
  },
  mounted() {
    this.$root.$on('refreshTraining', this.fetchChatlog);
  },
  beforeDestroy() {
    this.$root.$off('refreshTraining');
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('chatlogs', [
      'fetchSingleLog',
      'searchLogs',
    ]),
    ...mapMutations('nodeLabels', ['setIsFetching']),
    ...mapActions('nodeLabels', ['fetchDatasets']),
    async fetchChatlog(chatId) {
      this.setIsFetching(true);
      this.needsRefresh = false;
      this.chatsData = [];
      this.timestamp = null;
      this.unlabeledChatCount = null;
      let currentChat;
      let data = { chats: [] };
      try {
        if (chatId) {
          data.chats = [await this.fetchSingleLog({
            botId: this.activeBotId,
            chatId,
            externalId: this.$route.params.externalId,
          })];
          currentChat = data.chats[0];
        } else {
          const requireNodes = this.focusNode ? [...new Set([...this.filters.passThroughNodeIds,
            this.focusNode])] : this.filters.passThroughNodeIds;

          const request = {
            botId: this.activeBotId,
            selectedLanguage: this.filters.selectedLanguage,
            variantId: this.filters.selectedVariant,
            freeText: this.filters.freeText,
            ignoreNodes: this.filters.ignoreNodeIds,
            page: this.currentPage,
            hasQueryLabel: this.nodeLabelFilter,
            requireNodes,
            resultsPerPage: this.focusNode ? 10 : 1,
            ignoreNodeChanges: this.focusNode ? [`${this.focusNode};final`] : null,
            selectedRatings: this.filters.selectedRatings,
            isLabelable: true,
            selectedDataOrigins: this.selectedDataOrigins,
            selfServedFilter: this.filters.selfServedFilter,
            trainingPage: true,
          };
          if (!this.focusNode) {
            // Only do random sampling when we don't have table-view.
            request.orderBy = '?';
          }
          if (this.selectedDataset) {
            request.labelDataSet = this.selectedDataset;
          } else {
            request.startDate = this.filters.startDate;
            request.endDate = this.filters.endDate;
          }
          data = await this.searchLogs(request);
          this.unlabeledChatCount = this.activeDataset ? this.activeDataset.labelable_count
          - this.activeDataset.label_count : data.unlabeled_items;
          this.totalItems = data.total_items;

          // dont go further if there is no data
          if (!data?.chats.length || this.focusNode) {
            if (this.$route.hash !== undefined && this.$route.hash.length) {
              this.$router.replace({ hash: '' });
            }
            if (!this.focusNode) {
              return;
            }
          }
          currentChat = data.chats[0];
          if (this.$route.hash.substring(1) !== currentChat?.chat_id && !this.focusNode) {
            this.$router.replace({ hash: currentChat.chat_id });
          }
        }

        this.showUnlabeledCount = this.unlabeledChatCount !== null
        && this.nodeLabelFilter === false;
        this.timestamp = currentChat ? new Date(currentChat.chat_started).toLocaleString('en-GB') : null;

        data.chats.forEach((chat) => {
          const events = eventsByNode(chatlogTransformer(chat).logEvents);
          const formattedChat = this.formatChatData(events);
          const formatted = {
            chatId: chat.chat_id,
            data: formattedChat,
          };
          if (this.focusNode) {
            formatted.livePrediction = this.getLivePrediction(formattedChat);
          }
          this.chatsData.push(formatted);
        });
        const chatIds = this.chatsData.map((e) => e.chatId);
        if (chatIds.length) {
          await this.fetchQueryLabels(chatIds);
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch conversation',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        this.$nextTick(() => {
          this.setIsFetching(false);
        });
      }
    },
    async fetchQueryLabels(ids) {
      // Function is only called inside fetchChatlog which handles updating of isFetching
      this.queryLabels = {};
      try {
        const resp = await axios.get(endpoints.queryLabel,
          {
            params: { botId: this.activeBotId, chat_id__in: ids.join(',') },
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
        resp.data.forEach((queryLabel) => {
          this.queryLabels[queryLabel.chat_id] = queryLabel;
        });
        this.populateLabels();
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch labels',
          text: error.message,
          variant: 'danger',
        });
      }
    },
    formatChatData(events) {
      /*
        structure of events data:
        List of visited nodes. Each visited node has the following properties:
        - events: List of events during this node
        - key
        - node: Details about the node. The property "node" contains the node ID.
      */
      let prevNodeId = null;
      let currentAcc = null;
      const reducedData = events.reduce((acc, node) => {
        const probabilities = node.probabilities;
        const nodeId = node.node.node;
        if (prevNodeId !== nodeId) {
          // If this node has a different nodeId than the previous one, start a new element
          currentAcc = {
            node: node.node,
            events: this.filterEvents(node.events),
            probabilities: probabilities?.length > -1 ? probabilities : [],
          };
          acc.push(currentAcc);
        } else {
          // If this node has the same nodeId as the previous one, merge it with the current element
          currentAcc.events = currentAcc.events.concat(this.filterEvents(node.events));
          currentAcc.probabilities = currentAcc.probabilities
            .concat(probabilities?.length > -1 ? probabilities : []);
        }
        prevNodeId = nodeId;
        return acc;
      }, []);
      return reducedData;
    },
    populateLabels() {
      const populatedChats = [];
      this.chatsData.forEach((chat) => {
        let shouldDisable = false;
        let firstLabel = false;
        const chatCopy = cloneDeep(chat.data);
        const queryLabel = cloneDeep(this.queryLabels[chat.chatId]);
        const isLabeled = queryLabel.nodelabelitems.some((e) => (e?.labels || [])
          .filter((l) => !l.is_automatic).length > 0);
        for (let i = 0; i < chatCopy.length; i++) {
          const nodeLabelItem = queryLabel.nodelabelitems
            .find((nodeLabel) => nodeLabel.node_id === chatCopy[i].node.node);
          if (!queryLabel || !nodeLabelItem) {
            continue;
          }
          nodeLabelItem.labels = this.sortLabels(nodeLabelItem.labels);
          if (this.hasChildWithMatching(chatCopy[i].node.node)) {
            chatCopy[i].query = nodeLabelItem.query;
            if (!isLabeled) {
              if (chatCopy[i + 1]) {
                nodeLabelItem.labels.push({
                  id: uuidv4(),
                  label: chatCopy[i + 1]?.node?.node,
                  parent_label_id: null,
                });
                chatCopy[i + 1].hide = true;
                chatCopy[i].labels = this.sortLabels(cloneDeep(nodeLabelItem.labels));
              }
              // some old chats might not have labels
              // but we still want to be able to label nodes with matching
            } else if (!nodeLabelItem.labels?.length) {
              chatCopy[i + 1].hide = true;
              chatCopy[i].labels = [{
                id: uuidv4(),
                label: chatCopy[i + 1]?.node?.node,
                parent_label_id: null,
              }];
            } else if (chatCopy[i + 1] && !firstLabel) {
              if (nodeLabelItem.labels[0]?.label === chatCopy[i + 1].node.node) {
                // labeled - original path
                chatCopy[i].labels = this.sortLabels(cloneDeep(nodeLabelItem.labels));
                chatCopy[i + 1].hide = true;
              } else {
                // labeled - new path
                firstLabel = true;
                shouldDisable = true;
                const lastLabel = nodeLabelItem.labels[nodeLabelItem.labels.length - 1].label;
                chatCopy[i].labels = cloneDeep(nodeLabelItem.labels);
                if (this.hasChildWithMatching(lastLabel)) {
                  chatCopy[i].labels = nodeLabelItem.labels.concat([{ id: 'select' }]);
                }
              }
            }
          }
          // disabled used for covering chat messages with overlay
          // hide used for hiding nodes that shouldnt be shown
          if (shouldDisable) {
            if (chatCopy[i + 1]) {
              chatCopy[i + 1].hide = true;
            }
            chatCopy[i].disabled = true;
          }
        }
        populatedChats.push({
          ...chat,
          data: chatCopy,
          chatId: chat.chatId,
          isLabeled,
          userInput: this.getUserInput(queryLabel),
        });
      });
      this.chatsData = populatedChats;
    },
    sortLabels(array) {
      const sortedArray = [];
      let currentObject = array.find((obj) => obj.parent_label_id === null);

      const findObject = (parentId) => array.find((obj) => obj.parent_label_id === parentId);
      while (currentObject !== undefined) {
        sortedArray.push(currentObject);
        currentObject = findObject(currentObject.id);
      }
      return sortedArray;
    },
    newLabel({
      chatId, index, labelIndex, nodeId,
    }) {
      const chatIndex = this.chatsData.findIndex((e) => e.chatId === chatId);
      const copy = cloneDeep(this.chatsData[chatIndex]);
      copy.dirty = true;
      if (nodeId === copy.data[index + 1].node.node) {
        this.resetLabel({ chatId, index });
      } else {
        copy.data[index].labels[labelIndex].label = nodeId;
        copy.data[index].labels = copy.data[index].labels.slice(0, labelIndex + 1);
        if (this.hasChildWithMatching(nodeId)) {
          copy.data[index].labels.push({ id: 'select' });
        }
        for (let i = index; i < copy.data.length; i++) {
          if (copy.data[i + 1]) {
            copy.data[i + 1].disabled = true;
            copy.data[i + 1].hide = true;
            copy.data[i + 1].labels = [];
          }
        }
        this.$set(this.chatsData, chatIndex, copy);
      }
    },
    nextLabel({ chatId, index, nodeId }) {
      const chatIndex = this.chatsData.findIndex((e) => e.chatId === chatId);
      const copy = cloneDeep(this.chatsData[chatIndex].data);
      copy[index].labels[copy[index].labels.length - 1] = {
        id: uuidv4(),
        label: nodeId,
        parent_label_id: copy[index].labels[copy[index].labels.length - 2].id,
      };
      if (this.hasChildWithMatching(nodeId)) {
        copy[index].labels.push({ id: 'select' });
      }
      this.chatsData[chatIndex].data = copy;
    },
    resetLabel({ chatId, index }) {
      const chatIndex = this.chatsData.findIndex((e) => e.chatId === chatId);
      const copy = cloneDeep(this.chatsData[chatIndex].data);
      copy[index].labels = [{
        id: copy[index].labels[0].id,
        label: copy[index + 1].node.node,
        parent_label_id: null,
      }];
      copy[index].disabled = false;
      for (let i = index + 1; i < copy.length; i++) {
        copy[i].disabled = false;
        if (this.hasChildWithMatching(copy[i]?.node?.node)) {
          copy[i].labels = [{
            id: uuidv4(),
            label: copy[i + 1]?.node?.node,
            parent_label_id: null,
          }];
          copy[i + 1].hide = true;
        } else if (copy[i + 1]) {
          copy[i + 1].hide = false;
        }
      }
      this.chatsData[chatIndex].data = copy;
    },
    getNodeChildren(id) {
      const options = id ? this.nodeById(id).children
        .map((e) => ({ text: this.nameOfId(e), value: e })) : [];
      return options.concat([{ value: 'fallback', text: 'None of the above' }]);
    },
    filterEvents(events) {
      return events ? events.filter((e) => e.type === 'msg') : [];
    },
    hasChildWithMatching(nodeId) {
      const node = this.nodeById(nodeId);
      if (node === undefined) {
        // Node not found. Most likely deleted node.
        return false;
      }
      if (nodeTypes.MULTIPLE_CHOICE === node.options.nodeType) {
        return true;
      }
      return node ? node.children.filter((e) => this.hasMatching(e)).length > 0 : false;
    },
    hasMatching(nodeId) {
      const node = this.nodeById(nodeId);
      return node ? node.match.examples.length || node.match.useClfToMatch : false;
    },
    async submit(chatId, isTable, subLabels) {
      try {
        this.setIsFetching(!this.focusNode && true);
        const payload = cloneDeep(this.queryLabels[chatId]);
        const currentChat = this.chatsData.find((e) => e.chatId === payload.chat_id);
        const includeSubLabels = !!(currentChat.includeSubLabels && subLabels);

        const focusNodeIndex = this.focusNodeIndex(currentChat.chatId);
        const activeQuery = this.getUserInput(payload);
        const lastVisibleNodeIndex = this.getLastValidQueryIndex(
          currentChat, payload, focusNodeIndex, activeQuery);

        const focusNodeLabelIndex = payload.nodelabelitems
          .findIndex((e) => e.node_id === this.focusNode);
        for (let i = 0; i < currentChat.data.length; i++) {
          const item = currentChat.data[i];
          const matchingNodeLabel = payload.nodelabelitems
            .find((x) => x.node_id === item.node.node);
          if (item.labels?.length) {
            let addLabels = false;
            // single view or manually labeled - add
            if (!isTable || item.disabled) {
              addLabels = true;
            }
            // table expanded - add all visible labels
            if (includeSubLabels && i <= lastVisibleNodeIndex) {
              addLabels = true;
            }
            // table collapsed - only add visible (until focus node)
            if (!includeSubLabels && (focusNodeLabelIndex === -1 || focusNodeLabelIndex >= i)) {
              addLabels = true;
            }

            if (addLabels) {
              matchingNodeLabel.labels = item.labels.filter((e) => e.id !== 'select')
                .map((x) => ({ ...x, is_automatic: false }));
            } else {
              matchingNodeLabel.labels = [];
            }
          }
        }
        await axios.put(`${endpoints.queryLabel + chatId}/`,
          payload,
          {
            params: { botId: this.activeBotId },
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
        if (!this.focusNode) {
          await this.fetchChatlog();
          if (this.selectedDataset) {
            await this.fetchDatasets();
          }
        } else if (this.nodeLabelFilter === false) {
          this.chatsData.splice(this.chatsData.indexOf(currentChat), 1);
        } else {
          currentChat.isLabeled = true;
          currentChat.dirty = false;
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to save labels',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        this.setIsFetching(false);
      }
    },
    focusNodeIndex(chatId) {
      return this.chatsData.find((e) => e.chatId === chatId).data
        .findIndex((e) => e.node.node === this.focusNode);
    },
    hasValidSubLabels(currentChat, currentQueryLabel) {
      const focusNodeIndex = this.focusNodeIndex(currentChat.chatId);
      const activeQuery = this.getUserInput(currentQueryLabel);

      const lastValidIndex = this.getLastValidQueryIndex(
        currentChat, currentQueryLabel, focusNodeIndex, activeQuery);
      let hasSubLabels = false;
      if (focusNodeIndex < lastValidIndex) {
        for (let i = focusNodeIndex; i <= lastValidIndex; i++) {
          if (currentChat.data[i]?.labels?.length) {
            hasSubLabels = true;
          }
        }
      }
      return hasSubLabels;
    },
    getLastValidQueryIndex(currentChat, currentQueryLabel, index, activeQuery) {
      if (index + 1 < currentChat.data.length) {
        const nodeId = currentChat.data[index + 1].node.node;
        const nextItem = currentQueryLabel.nodelabelitems.find((e) => e.node_id === nodeId);
        if ((nextItem.query === activeQuery)
        && (currentChat.data[index + 1]?.labels?.length)) {
          return this.getLastValidQueryIndex(
            currentChat, currentQueryLabel, index + 1, activeQuery);
        }
      }
      return index;
    },
    hasManualLabels(item) {
      return item.data.some((node) => node.disabled);
    },
    getUserInput(queryLabel) {
      const matchedLabelItem = queryLabel.nodelabelitems.find((e) => e.node_id === this.focusNode);
      return matchedLabelItem?.query;
    },
    getLivePrediction(chat) {
      const index = chat.indexOf(chat.find((e) => e.node.node === this.focusNode));
      return getTopPrediction(true, chat[index].probabilities);
    },
    getCurrentLabelValue(chat) {
      const currentItem = chat.data.find((e) => e.node.node === this.focusNode);
      return currentItem?.labels?.length ? currentItem.labels[0].label : null;
    },
    updateCurrentLabelValue(chat, nodeId) {
      const chatIndex = this.chatsData.findIndex((e) => e.chatId === chat.chatId);
      const copy = cloneDeep(chat);
      copy.dirty = true;
      this.$set(this.chatsData, chatIndex, copy);
      const itemIndex = chat.data.findIndex((e) => e.node.node === this.focusNode);
      if (copy.data[itemIndex].labels.length) {
        this.newLabel({
          chatId: chat.chatId, index: itemIndex, labelIndex: 0, nodeId,
        });
      }
    },
    goToChatlogDetailsPage(chatId) {
      const botId = this.$route.params.botId;
      const newPage = this.$router.resolve({ name: 'conversation-log-single', params: { botId, chatId } });
      window.open(newPage.href, '_blank');
    },
    toggleDetails(row) {
      row.toggleDetails();
      const currentChat = this.chatsData.find((e) => e.chatId === row.item.chatId);
      const currentQueryLabel = this.queryLabels[row.item.chatId];

      if (row.item._showDetails && this.hasValidSubLabels(currentChat, currentQueryLabel)) {
        currentChat.includeSubLabels = true;
      }
    },
    async excludeFromTraining(chatId) {
      try {
        const payload = {
          chat_id: chatId,
        };
        await axios.post(endpoints.chatlogsExcludeFromTraining,
          payload,
          {
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          },
        );
      } catch (error) {
        this.showWarning({
          title: 'Failed to exclude chat from training',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      } finally {
        await this.fetchChatlog();
      }
    },
  },
};
</script>
<style scoped>
::v-deep .action-col{
  width: 55px;
}
::v-deep .submit-col{
  width: 85px;
}
</style>
