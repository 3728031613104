<template>
  <div>
    <p class="mb-2">
      <strong>Generative AI</strong> is an extremely powerful tool that can both
      <strong>improve UX and automation</strong> of your bot while
      <strong>reducing the time</strong> you need to spend building and maintaining the bot.
    </p>
    <p>
      Some of the key Generative AI functionality in SupWiz' products include:
      <ul>
        <li>
          Automatically generate responses to user questions based on knowledge articles and
          and other documents
        </li>
        <li>
          Generate summary of conversation - e.g. in connection with hand-over or ticket creation
        </li>
        <li>Adjust tone-of-voice of bot responses</li>
      </ul>
    </p>
    <div class="text-center my-2">
      <b-button
        href="https://www.supwiz.com/generative-ai-supwiz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to read more
      </b-button>
    </div>
    <hr>
    <div class="text-center my-2">
      <b-img
        fluid
        class="w-75"
        :src="require(`@/assets/featureImages/gen_ai_tonality.png`)"
        alt="Example of using Generative AI to adjust tone of voice in replies."
      />
    </div>
    <b-alert
      show
      class="mt-3"
      variant="info"
    >
      Generative AI is an add-on feature that must be enabled for your SupWiz products before you
      can use it. Reach out to your SupWiz' rep to learn more.
    </b-alert>
  </div>
</template>
<script>
export default { name: 'GenerativeAiDescription' };
</script>
