<template>
  <div>
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <b-card-title>
        Data point
        <div class="float-right">
          <b-btn
            v-b-tooltip.hover
            :title="datasetType === 'file' ? 'Edit datapoint text' : 'You cannot edit data from node'"
            size="sm"
            variant="outline-primary"
            :disabled="datasetType !== 'file'"
            @click.stop="setEditDataPoint()"
          >
            <font-awesome-icon icon="edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :title="datasetType === 'file' ? 'Delete datapoint' : 'You cannot delete data from node'"
            size="sm"
            variant="outline-danger"
            :disabled="datasetType !== 'file'"
            @click.stop="setDeleteDataPoint()"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-btn>
        </div>
      </b-card-title>
      <template
        v-if="dataPoint"
      >
        <em id="datapoint-text">
          {{ dataPoint.text }}</em>
      </template>
      <template v-else>
        <b-spinner large />
      </template>
    </b-card>
    <b-modal
      id="editDataPointModal"
      title="Edit datapoint"
      size="xl"
      ok-title="Save"
      @ok="proxyEditDataPoint"
      @shown="focusEditTextArea"
    >
      <b-form-textarea
        ref="editModalTextArea"
        v-model="dataPointText"
        rows="4"
        max-rows="6"
      />
    </b-modal>
    <b-modal
      id="deleteDataPointModal"
      title="Delete datapoint"
      :auto-focus-button="'cancel'"
      ok-title="Delete"
      ok-variant="danger"
      @ok="proxyDeleteDataPoint"
    >
      Are you certain you would like to delete the datapoint?
    </b-modal>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'DataExplorationDataPointCard',
  props: {
    dataPoint: {
      type: Object,
      required: true,
    },
    datasetId: {
      type: Number,
      required: true,
    },
    datasetType: {
      type: String,
      default: 'file',
    },
  },
  computed: {
    dataPointText: {
      get() {
        return this.dataPoint.text;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    ...mapActions('dataExploration/dataSources', [
      'deleteDataPoint',
      'editDataPoint',
    ]),
    async proxyEditDataPoint() {
      await this.editDataPoint({
        datasetId: this.datasetId,
        dataPointId: this.dataPoint.id,
        dataPointText: this.dataPoint.text,
      });
      this.$emit('edited-datapoint');
    },
    setEditDataPoint() {
      this.$bvModal.show('editDataPointModal');
    },
    async proxyDeleteDataPoint() {
      await this.deleteDataPoint({ datasetId: this.datasetId, dataPoint: this.dataPoint });
      this.$emit('deleted-datapoint');
    },
    setDeleteDataPoint() {
      this.$bvModal.show('deleteDataPointModal');
    },
    focusEditTextArea() {
      this.$refs.editModalTextArea.focus();
    },
  },
};
</script>
