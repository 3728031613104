import { mapGetters } from 'vuex';
import axios from 'axios';
import dateFormat from 'dateformat';
import endpoints from '@/js/urls';

const downloadBotMixin = {
  data() {
    return {
      downloadingCurrentBot: false,
    };
  },
  methods: {
    async downloadCurrentBot(botId, botName = undefined) {
      const fileName = botName || this.getBotNameFromId(botId);
      this.showWarning({
        title: 'Downloading a bot',
        text: 'Your download is being prepared. This may take a few seconds.',
        variant: 'primary',
      });
      this.downloadingCurrentBot = true;
      try {
        const { data } = await axios({
          responseType: 'arraybuffer',
          url: endpoints.downloadBot,
          params: {
            bot_id: botId,
          },
          headers: {
            Authorization: `JWT ${this.$store.state.auth.jwt}`,
          },
          method: 'GET',
        });
        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${fileName}_${dateFormat(Date.now(), 'yyyymmddHHMM')}.zip`);
        document.body.appendChild(fileLink);

        fileLink.click();

        document.body.removeChild(fileLink);
      } catch (e) {
        await this.$bvModal.msgBoxOk('An error occurred while downloading the bot. '
          + 'Please contact your BotStudio administrator if the problem persists.');
      } finally {
        this.downloadingCurrentBot = false;
      }
    },
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotNameFromId',
    ]),

  },
};

export default downloadBotMixin;
