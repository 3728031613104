import axios from 'axios';
import { cloneDeep } from 'lodash';
import endpoints from '@/js/urls';
import { objToCamel } from '@/js/utils';
import { reinitializeNode } from '@/js/graphComputations';

const graphState = {
  graph: null,
  topNodeId: undefined,
  showSidebar: false,
  isFetchingBookmarks: false,
  highlightEditNodeId: null,
  bookmarks: [],
  activeBookmark: null,
  zoom: [],
  paths: [],
};
const getters = {

};
const mutations = {
  saveGraph(state, graph) {
    state.graph = graph;
  },
  setShowSidebar(state, value) {
    state.showSidebar = value;
  },
  setIsFetchingBookmarks(state, value) {
    state.isFetchingBookmarks = value;
  },
  setBookmarks(state, payload) {
    state.bookmarks = [];
    payload.forEach((element) => {
      state.bookmarks.push(objToCamel(element));
    });
  },
  setTopNodeId(state, id) {
    state.topNodeId = id;
  },
  setHighlightEditNodeId(state, id) {
    state.highlightEditNodeId = id;
  },
  clearGraphData(state) {
    state.graph = null;
    state.highlightEditNodeId = null;
    state.showSidebar = false;
    state.topNodeId = undefined;
    state.bookmarks = [];
  },
  setActiveBookmark(state, bookmark) {
    state.activeBookmark = bookmark;
  },
  zoomOnNode(state, uid) {
    state.zoom.push(uid);
  },
  setZoom(state, zoom) {
    state.zoom = zoom;
  },
  setPaths(state, paths) {
    state.paths = paths;
  },
};
const actions = {
  async fetchBookmarks({ rootState, dispatch, commit }) {
    commit('setIsFetchingBookmarks', true);
    try {
      const resp = await axios.get(endpoints.bookmarks, {
        params: {
          bot_id: rootState.botManipulation.activeBotId,
          subflow_id: rootState.botManipulation.activeSubFlowId,
        },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setBookmarks', resp.data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch bookmarks',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setIsFetchingBookmarks', false);
    }
  },
  async addBookmark({ rootState, dispatch }, { name = '', node = null, parent = null }) {
    try {
      const data = {
        name,
        bot: rootState.botManipulation.activeBotId,
        parent,
        node,
      };
      if (rootState.botManipulation.activeSubFlowId) {
        data.subflow = rootState.botManipulation.activeSubFlowId;
      }

      await axios.post(endpoints.bookmarks,
        data,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      dispatch('fetchBookmarks');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to add bookmark',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async updateBookmark({ rootState, dispatch }, newBookmark) {
    try {
      await axios.put(`${endpoints.bookmarks}${newBookmark.id}/`,
        newBookmark,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      dispatch('fetchBookmarks');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to update bookmark',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async deleteBookmark({ rootState, dispatch }, id) {
    try {
      await axios.delete(`${endpoints.bookmarks}${id}/`,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      dispatch('fetchBookmarks');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to delete bookmark',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  normalize({ state, rootGetters, commit }) {
    if (state.graph) {
      const copy = cloneDeep(state.graph);
      reinitializeNode(copy, rootGetters['botManipulation/activeBot/nodeById'], rootGetters['botManipulation/activeBot/nameOfId']);
      commit('saveGraph', copy);
    }
  },
};

export default {
  namespaced: true,
  state: graphState,
  getters,
  mutations,
  actions,
};
