<template>
  <div>
    <p>
      Excluded Urls:
    </p>
    <b-button
      v-for="(x, i) in newConfig.excluded_ids"
      :key="i"
      v-b-tooltip.nonineractive.hover
      pill
      size="sm"
      style="position:relative;"
      class="mr-1 mt-1"
    >
      {{ x }}
      <font-awesome-icon
        icon="ban"
        size="sm"
        @click.stop="deleteValue(i)"
      />
    </b-button>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { deepCopyJson } from 'supwiz/util/data';

export default {
  name: 'LinkCheckConfiguration',
  data() {
    return {
      newConfig: {
        excluded_ids: [],
      },
      // Keeping metricIdentifier around as a constant for convenience.
      metricIdentifier: 'LINK_CHECKING',
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', [
      'getConfigForMetric',
    ]),
    currentConfig() {
      return this.getConfigForMetric(this.getBotId, this.metricIdentifier);
    },
  },
  watch: {
    currentConfig() {
      this.$set(this, 'newConfig', deepCopyJson(this.currentConfig));
    },
  },
  mounted() {
    if (!this.currentConfig) {
      // This will keep default values
      return;
    }
    this.$set(this, 'newConfig', { ...this.newConfig, ...deepCopyJson(this.currentConfig) });
  },
  methods: {
    ...mapActions('health', [
      'updateComputeConfig',
    ]),
    deleteValue(index) {
      // Reset those nodes that have been excluded
      this.newConfig.excluded_ids.splice(index, 1);
      this.updateComputeConfig({
        metricIdentifier: this.metricIdentifier,
        newConfig: this.newConfig,
      });
    },
  },
};
</script>

<style scoped>

</style>
