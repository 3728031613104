<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="primary"
  >
    <template #icon>
      <font-awesome-icon icon="atom" />
    </template>
    <template #header>
      {{ cappedTargetName }}
      <font-awesome-icon
        v-if="cappedTargetName"
        icon="arrow-left"
      />
      {{ cappedModelName }}( {{ cappedInputName }} )
    </template>
    <span
      v-if="!botHasAnyModelsAssociated"
      class="text-danger"
    >
      To continue, you need to associate a NLU-model with the bot first.
      Go to
      <router-link
        :to="{ name: 'classifiers' }"
      >
        Classifiers page
      </router-link>.
    </span>
    <template v-else>
      <label>Model:</label>
      <b-form-select
        :value="modelName"
        :options="nluModelNames"
        class="mb-2 mr-sm-2 mb-sm-0"
        placeholder="Select NLU Model"
        :state="$v.modelName.$invalid ? false : null"
        :aria-describedby="modelNameFeedbackId"
        @input="modelName => setModelName({ modelName })"
      />
      <b-form-invalid-feedback :id="modelNameFeedbackId">
        <div v-if="!$v.modelName.required">
          Choose a model to call.
        </div>
      </b-form-invalid-feedback>
      <label class="mt-3">Model input:</label>
      <botscript-validation
        :validations="['empty']"
        :value="modelInput"
        class="mb-2 mr-sm-2 mb-sm-0"
        @onChange="code => setModelInput({ modelInput: code })"
      />
      <label class="mt-3">Variable to save response in</label>
      <VariableName
        :value="targetName"
        class="mb-2 sm-2 mb-sm-0"
        @input="targetName => setTargetName({ targetName })"
      />
      <b-form-checkbox
        :checked="returnProbs"
        class="mt-4"
        @input="returnProbs => setReturnProbs({ returnProbs })"
      >
        Return probabilities instead of prediction.
      </b-form-checkbox>
    </template>
  </base-activity>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import VariableName from '@/components/VariableName.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { truncateString } from '@/js/utils';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'CallModelActivity',
  components: { BaseActivity, VariableName, BotscriptValidation },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      modelName: 'activityCallModelName',
      modelInput: 'activityCallModelInput',
      targetName: 'activityCallModelTarget',
      returnProbs: 'activityCallModelReturnProbs',
    }), 'nodeId', 'activityId'),
    ...mapGetters('botManipulation/activeBot/config', [
      'getNLUModels',
    ]),
    nluModelNames() {
      return this.getNLUModels.map((model) => model.name);
    },
    botHasAnyModelsAssociated() {
      return this.getNLUModels.length > 0;
    },
    cappedModelName() {
      return truncateString(`${this.modelName}`, 40);
    },
    cappedInputName() {
      return truncateString(`${this.modelInput}`, 20);
    },
    cappedTargetName() {
      return truncateString(`${this.targetName}`, 20);
    },
    modelNameFeedbackId() {
      return `modelNameFeedback${this.nodeId}-${this.activityId}`;
    },
    inputFeedbackId() {
      return `inputFeedback${this.nodeId}-${this.activityId}`;
    },
    targetFeedbackId() {
      return `targetFeedback${this.nodeId}-${this.activityId}`;
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setModelName: 'setActivityCallModelName',
      setModelInput: 'setActivityCallModelInput',
      setTargetName: 'setActivityCallModelTarget',
      setReturnProbs: 'setActivityCallModelReturnProbs',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
  validations: {
    modelName: { required },
  },
};
</script>

<style scoped>

</style>
