/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/prefer-default-export
export function showPaths({
  tree, paths, shortest, root, nextUnusedId, nodeById,
}) {
  function showChildren({ treeNodeId }) {
    const treeNode = tree[treeNodeId];
    if (treeNode.children !== null) {
      throw new Error("showChildren should only be called on nodes that don't show children already");
    }
    const { botNodeId } = tree[treeNodeId];
    const botNode = nodeById(botNodeId);
    if (botNode === undefined || botNode === null) {
      throw new Error('showChildren was called on a node that is no longer in the bot');
    }
    const treeChildren = [];
    for (const botChildId of botNode.children) {
      const treeChildId = nextUnusedId();
      tree[treeChildId] = {
        botNodeId: botChildId,
        parent: treeNodeId,
        children: null,
      };
      treeChildren.push(treeChildId);
    }
    // set children list
    tree[treeNodeId].children = treeChildren;
  }
  function expandPath(path) {
    if (!path || path.length <= 1) {
      return;
    }
    let parentTreeNodeId = root;
    // We need to traverse down the path, as a node can be represented multiple places.
    // Root does not have an parent to expand, so skip it
    for (const nodeIdLocal of path.slice(1)) {
      if (!tree[parentTreeNodeId].children) {
        showChildren({ treeNodeId: parentTreeNodeId });
      }
      // Find the node among its siblings
      const siblingIds = tree[parentTreeNodeId].children;
      // eslint-disable-next-line no-loop-func
      parentTreeNodeId = siblingIds.find((sId) => tree[sId].botNodeId === nodeIdLocal);

      if (!parentTreeNodeId) {
        throw new Error(`Path must be valid. Did not find ${nodeIdLocal}`);
      }
    }
  }

  if (!paths || paths.length <= 0) {
    return {};
  }
  let pathsProxy = paths;
  if (shortest) {
    // Pick shortest path
    pathsProxy = [paths.reduce((prev, next) => (prev.length <= next.length ? prev : next))];
  }
  for (const path of pathsProxy) {
    expandPath(path, root, tree);
  }
  return tree;
}
