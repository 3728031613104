import Vue from 'vue';

const initialState = {
  authenticationSubflowEnabled: false,
  config: {
    id: null,
    successNode: null,
    failureNode: null,
  },
};

const authGetters = {
  subflowId(state) {
    if (!state.authenticationSubflowEnabled) {
      return null;
    }
    return state.config.id;
  },
  successNode(state) {
    if (!state.authenticationSubflowEnabled) {
      return null;
    }
    return state.config.successNode;
  },
  failureNode(state) {
    if (!state.authenticationSubflowEnabled) {
      return null;
    }
    return state.config.failureNode;
  },
  authenticationSubflowIsEnabled(state) {
    return state.authenticationSubflowEnabled;
  },
};

const mutations = {
  setValueForAuthenticationEnabled(state, newValue) {
    if (newValue !== false && newValue !== true) {
      // Check that we are only assigned either a true or a false
      return;
    }
    state.authenticationSubflowEnabled = newValue;
    if (newValue && state.config === null) {
      // If it has just been enabled we set the default values in the config.
      Vue.set(state, 'config', {
        id: null,
        successNode: null,
        failureNode: null,
      });
    }
  },
  setSubflowId(state, subflowId) {
    if (!state.authenticationSubflowEnabled) {
      // Programmatic error: When auth is disabled, user should not be able to set a subflow
      return;
    }
    if (subflowId !== state.config.id) {
      state.config.id = subflowId;
      // If we change the subflow id then the success and failure nodes doesn't make sense anymore
      state.config.successNode = null;
      state.config.failureNode = null;
    }
  },
  setSuccessNode(state, node) {
    if (!state.authenticationSubflowEnabled) {
      // Programmatic error: When auth is disabled, user should not be able to set a success node
      return;
    }
    state.config.successNode = node;
  },
  setFailureNode(state, node) {
    if (!state.authenticationSubflowEnabled) {
      // Programmatic error: When auth is disabled, user should not be able to set a failure node
      return;
    }
    state.config.failureNode = node;
  },
};

const actions = {};

export default {
  namespaced: true,
  state: () => initialState,
  getters: authGetters,
  mutations,
  actions,
};
