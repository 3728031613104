<template>
  <b-container>
    <b-row
      align-h="between"
      align-v="center"
    >
      <b-col cols="*">
        <div v-if="model.subflowId">
          <span class="font-weight-bold">Subflow: </span>{{ model.subflowName }}
        </div>
        <div>
          <span class="font-weight-bold">Name: </span>{{ toNameFilter(model) }}
        </div>
        <div>
          <span class="font-weight-bold">Model: </span>
          {{ toNameFilter(model.originalModel) }}
        </div>
      </b-col>
      <b-col cols="*">
        <div v-if="!model.variantSelected">
          Classifier is not overwritten by variant.
        </div>
        <div v-else>
          <span class="font-weight-bold">Model: </span>
          {{ toNameFilter(model.variantModel) }}
          <br>
          <span class="font-weight-bold">Version: </span>
          {{ model.variantVersion }}
        </div>
      </b-col>
      <b-col cols="*">
        <b-btn-group>
          <b-btn
            variant="primary"
            @click.stop="$emit('edit')"
          >
            Edit
          </b-btn>
          <b-btn
            variant="danger"
            @click="$emit('clear')"
          >
            Clear
          </b-btn>
        </b-btn-group>
      </b-col>
    </b-row>
    <b-alert
      v-if="model.variantSelected && model.missingLabels.length > 0"
      show
      variant="warning"
      class="mt-1 mb-0"
    >
      The bot uses the following label{{ model.missingLabels.length > 1 ? 's' : '' }}
      not found in the selected variant classifier:
      {{ model.missingLabels.join(', ') }}
    </b-alert>
  </b-container>
</template>
<script>
import { toNameFilter } from '@/js/vuefilters';

export default {
  name: 'VariantClassifier',
  props: {
    model: {
      required: true,
      type: Object,
    },
  },
  methods: {
    toNameFilter,
  },
};
</script>
