const audioState = {
  // A queue of component ids. The component themselves keep track of _what_ audio they are
  // supposed to play
  audioQueue: [],
};

const getters = {
  isItMyTurnToPlayAudio: (state) => (componentId) => {
    // It is a specific component's turn to play when that component's ID appears first in queue
    const index = state.audioQueue.findIndex((x) => x === componentId);
    return index === 0;
  },
};

const mutations = {
  clearQueue(state) {
    state.audioQueue = [];
  },
  addMessageToQueue(state, componentId) {
    state.audioQueue.push(componentId);
  },
  removeFromQueue(state, indexToRemove) {
    state.audioQueue.splice(indexToRemove, 1);
  },
};

const actions = {
  async reserveSpotInPlaybackQueue({ commit }, { componentId }) {
    commit('addMessageToQueue', componentId);
  },
  componentEndedPlayback({ state, commit }, { componentId }) {
    // Note that ending playback is different from pausing playback; ending playback implies another
    // queued playback can now start playing
    const index = state.audioQueue.findIndex((x) => x === componentId);
    if (index === -1) {
      // Component is not even registered in audioqueue, this is weird; just ignore for now
      return;
    }
    commit('removeFromQueue', index);
  },
  /**
   * The implementation of unregisterComponent is currently identical to that of
   * componentEndedPlayback; however they handle two different intents.
   */
  unregisterComponent({ state, commit }, { componentId }) {
    const index = state.audioQueue.findIndex((x) => x === componentId);
    if (index === -1) {
      // Component is not even registered in audioqueue, this is weird; just ignore for now
      return;
    }
    commit('removeFromQueue', index);
  },
};

export default {
  namespaced: true,
  state: audioState,
  getters,
  mutations,
  actions,
};
