<template>
  <b-card class="r-75 shadow" body-class="dashboard-card-content">
    <div class="dashboard-card-title font-weight-bold text-truncate mb-2">
      {{ title }}
    </div>
    <b-row align-v="end">
      <b-col class="value mt-auto d-color-dark">
        {{ formattedValue(value) }}
      </b-col>
      <b-col v-if="change !== null" cols="auto" class="mt-auto d-color-dark">
        <font-awesome-icon
          icon="up-long"
          :color="getArrowColor"
          :style="{ transform: rotateArrow ? 'rotate(180deg)' : '' }"
        />
        {{ absoluteChange }}%
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { secondsToHHMMSS } from 'supwiz/util/formatters';

export default {
  name: 'DashboardKPICard',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    change: {
      type: [Number, String],
      default: () => null,
    },
    formatType: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    revertColors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    absoluteChange() {
      return Math.abs(this.change);
    },
    getArrowColor() {
      if (this.revertColors) {
        return this.change > 0 ? 'orange' : 'green';
      }
      return this.change < 0 ? 'orange' : 'green';
    },
    rotateArrow() {
      return this.revertColors ? this.change <= 0 : this.change < 0;
    },
  },
  methods: {
    secondsToHHMMSS,
    formattedValue(value) {
      if (value === null) return 'N/A';
      const cleanValue = value;
      switch (this.formatType) {
        case 'percent': return `${cleanValue.toFixed(1)}%`;
        case 'rounded-number': return this.truncate ? this.nFormatter(cleanValue.toFixed(1)) : cleanValue.toFixed(1);
        case 'number': return this.truncate ? this.nFormatter(cleanValue) : cleanValue;
        case 'currency': return this.truncate
          ? `${this.nFormatter(Math.round(cleanValue))} ${this.currency}`
          : `${new Intl.NumberFormat('fr-FR').format(Math.round(cleanValue))} ${this.currency}`;
        case 'time': return this.secondsToHHMMSS(cleanValue);
        default: return cleanValue;
      }
    },
    nFormatter(num) {
      if (num >= 1000000000) {
        return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
      }
      if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
      }
      if (num >= 1000) {
        return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
      }
      return num;
    },
  },
};
</script>
<style scoped>

.value{
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1;
}
</style>
