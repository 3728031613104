<template>
  <div class="d-flex flex-column align-items-center my-3">
    <span class="meta">Interaction</span>
    <div
      class="bubble p-2 actionMessage bg-info"
    >
      <b-row>
        <b-col class="text-right font-weight-bold">
          Type:
        </b-col>
        <b-col cols="8">
          {{ interaction.data.type }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Value:
        </b-col>
        <b-col cols="8">
          {{ interaction.data.value }}
        </b-col>
      </b-row>
    </div>
    <span
      class="meta"
    >{{ dateFormatter(interaction.timestamp) }}</span>
  </div>
</template>

<script>

export default {
  name: 'ChatInteraction',
  props: {
    interaction: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleTimeString();
    },
  },
};
</script>

<style scoped>
.bubble.interactionMessage {
  min-width: 80%;
  max-width: 80%;
}
</style>
