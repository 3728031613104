<template>
  <b-list-group>
    <b-list-group-item
      v-for="{ name, id } in nodes"
      :key="id"
      :to="editNodeLink(id)"
    >
      <slot v-bind="{ name, id }">
        {{ name }}
      </slot>
    </b-list-group-item>
  </b-list-group>
</template>

<script>

export default {
  name: 'NodeList',
  props: {
    nodes: {
      required: true,
      type: Array,
    },
  },
};
</script>
