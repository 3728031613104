<template>
  <div>
    <p>
      It is now possible to access detailed history of changes in a bot,
      including both overall bot changes and specific changes to individual nodes.
    </p>
    <div class="text-center my-2">
      <b-img
        fluid
        class="w-75"
        :src="require(`@/assets/featureImages/bot_history.png`)"
        alt="Image of bot history page."
      />
    </div>
    <b-alert
      :show="!isSuperUser"
      class="mt-3"
      variant="info"
    >
      Bot history page can only be accessed by users with superuser privillages.
    </b-alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BotHistory',
  computed: {
    ...mapGetters('auth', ['isSuperUser']),
  },
};
</script>
