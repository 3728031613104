<template>
  <b-row>
    <b-col cols="5">
      <b>{{ leftHeader }}</b>
      <b-form-select
        v-model="leftSelected"
        :options="leftOptionsAlphabeticallySorted"
        multiple
        :select-size="7"
      />
    </b-col>
    <b-col cols="2">
      <div class="text-center">
        <font-awesome-icon
          class="mr-1 mt-5"
          :class="rightHasValue ? 'cursor-pointer' : ' text-muted'"
          icon="arrow-left"
          @click="moveToLeft"
        />
        <font-awesome-icon
          class="ml-1 mt-5"
          :class="leftHasValue ? 'cursor-pointer' : 'text-muted'"
          icon="arrow-right"
          @click="moveToRight"
        />
      </div>
    </b-col>
    <b-col cols="5">
      <b>{{ rightHeader }}</b>
      <b-form-select
        v-model="rightSelected"
        multiple
        :select-size="7"
      >
        <option
          v-for="option in rightOptionsAlphabeticallySorted"
          :key="option.value + option.text"
          :value="option.value"
          :disabled="option.fromGroup"
        >
          {{ option.text }}
          <template v-if="option.fromGroup">
            &lt;inherited from {{ option.fromGroup }}&gt;
          </template>
        </option>
      </b-form-select>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'LeftRightSelect',
  props: {
    leftOptions: {
      required: true,
      type: Array,
    },
    rightOptions: {
      required: true,
      type: Array,
    },
    leftHeader: {
      required: true,
      type: String,
    },
    rightHeader: {
      required: true,
      type: String,
    },
    identifier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      leftSelected: [],
      rightSelected: [],
    };
  },
  computed: {
    leftOptionsAlphabeticallySorted() {
      return this.leftOptions.slice().sort((a, b) => a.text.localeCompare(b.text));
    },
    rightOptionsAlphabeticallySorted() {
      return this.rightOptions.slice().sort((a, b) => a.text.localeCompare(b.text));
    },
    leftHasValue() {
      return !!this.leftSelected.length;
    },
    rightHasValue() {
      return !!this.rightSelected.length;
    },
  },
  methods: {
    moveToLeft() {
      if (this.rightHasValue) {
        this.$emit('move-to-left', {
          items: this.rightSelected,
          identifier: this.identifier,
        });
      }
    },
    moveToRight() {
      if (this.leftHasValue) {
        this.$emit('move-to-right', {
          items: this.leftSelected,
          identifier: this.identifier,
        });
      }
    },
  },
};
</script>
