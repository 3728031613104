<template>
  <div>
    <b-form-input
      v-model="tmpValue"
      :placeholder="placeholder"
      :state="$v.$invalid ? false : null"
      :aria-describedby="'labelName'"
      @input="onInput"
      @change="onChange"
    />
    <b-form-invalid-feedback
      :id="'labelName'"
    >
      <div v-if="!$v.tmpValue.notEmpty">
        Label must have a name.
      </div>
      <div v-else-if="!$v.tmpValue.inQuotes">
        Label should not be in quotes.
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

export default {
  name: 'LabelInput',
  mixins: [validationMixin],
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Write a label name without quotes',
    },
  },
  data() {
    return {
      tmpValue: this.value,
    };
  },
  methods: {
    onInput(newValue) {
      if (!this.$v.tmpValue.$invalid) {
        this.$emit('input', newValue);
      }
    },
    onChange(newValue) {
      if (!this.$v.tmpValue.$invalid) {
        this.$emit('change', newValue);
      }
    },
  },
  validations: {
    tmpValue: {
      notEmpty(value) {
        return !!value;
      },
      inQuotes(value) {
        if (!value) return false;
        return !value.match(/^".*"$|^'.*'$/);
      },
    },
  },
};

</script>

<style scoped>
</style>
