<template>
  <b-nav>
    <!-- buttons for user logged in -->
    <template v-if="loggedIn">
      <b-nav-item
        v-if="wrapUserIsAllowedToViewRouteNamed(botRouteName) && $route.name !== 'home'"
        v-b-tooltip.hover.noninteractive
        title="Bots"
        :to="{ name: botRouteName }"
        :active="botActive"
        :class="[{ active: botActive }, $options.headerBtnClass]"
      >
        <font-awesome-icon
          size="lg"
          icon="robot"
        />
      </b-nav-item>
      <b-nav-item
        v-if="wrapUserIsAllowedToViewRouteNamed(nluRouteName) && $route.name !== 'home'"
        v-b-tooltip.hover.noninteractive
        title="NLU"
        :to="{ name: nluRouteName }"
        :active="nluActive"
        :class="[{ active: nluActive }, $options.headerBtnClass]"
      >
        <font-awesome-icon
          size="lg"
          icon="atom"
        />
      </b-nav-item>
      <b-nav-item
        v-if="wrapUserIsAllowedToViewRouteNamed(dataExplorationRouteName) && $route.name !== 'home'"
        v-b-tooltip.hover.noninteractive
        title="Data Exploration"
        :to="{ name: dataExplorationRouteName }"
        :active="dataExplorationActive"
        :class="[{ active: dataExplorationActive }, $options.headerBtnClass]"
      >
        <font-awesome-icon
          icon="tags"
          size="lg"
        />
      </b-nav-item>

      <b-nav-item
        v-if="wrapUserIsAllowedToViewRouteNamed(adminRouteName) && $route.name !== 'home'"
        v-b-tooltip.hover.noninteractive
        title="Administration"
        :to="{ name: adminRouteName }"
        :active="admininistrationActive"
        :class="[{ active: admininistrationActive }, $options.headerBtnClass]"
      >
        <font-awesome-icon
          size="lg"
          icon="user-lock"
        />
      </b-nav-item>

      <b-nav-item-dropdown
        v-b-tooltip.hover.noninteractive
        title="Help"
        :class="$options.headerBtnClass"
        right
        no-caret
      >
        <template #button-content>
          <font-awesome-icon
            size="lg"
            icon="question-circle"
          />
        </template>
        <b-dropdown-item
          v-if="wrapUserIsAllowedToViewRouteNamed(botScriptRouteName)"
          :to="{ name: botScriptRouteName }"
        >
          BotScript documentation
        </b-dropdown-item>
        <b-dropdown-item @click="toggleShowTooltips">
          <span v-if="tooltipsOn">Toggle tooltips off</span>
          <span v-else>Toggle tooltips on</span>
        </b-dropdown-item>
        <b-dropdown-item @click="toggleShowQA">
          <span v-if="getShowQA">Toggle QA page off</span>
          <span v-else>Toggle QA page on</span>
        </b-dropdown-item>
        <b-dropdown-item @click="toggleShowVariants">
          <span v-if="getShowVariants">Toggle variants page off</span>
          <span v-else>Toggle variants page on</span>
        </b-dropdown-item>
        <b-dropdown-item @click="toggleShowDemoPanel">
          <span v-if="getShowDemoPanel">Hide demo panel by default</span>
          <span v-else>Show demo panel by default</span>
        </b-dropdown-item>
        <b-dropdown-item @click="toggleActivityVariableAssignment">
          <span v-if="getShowActivityVariableAssignment">Hide activity variable assignments</span>
          <span v-else>Show activity variable assignments</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isGenerativeAiEnabled()"
          @click="toggleDemoPanelDefaultApiMode"
        >
          <span v-if="getDemoPanelDefaultApi === apiModes.MOCK">
            Use Mock API with Gen AI actions by default
          </span>
          <span v-else>Use Mock API by default</span>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="isProduction()"
          @click="toggleVoicebot"
        >
          <span v-if="showVoicebotFeatures">Toggle voicebot features off</span>
          <span v-else>Toggle voicebot features on</span>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="isProduction()"
          @click="toggleTranslation"
        >
          <span v-if="showTranslationFeatures">Toggle translation features off</span>
          <span v-else>Toggle translation features on</span>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="isProduction()"
          @click="toggleAI"
        >
          <span v-if="showAIFeatures">Toggle AI features off</span>
          <span v-else>Toggle AI features on</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isSuperUser || isUserNormal"
          :to="{ name: 'manage-images' }"
        >
          <span>Manage images</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isSuperUser"
          :to="{ name: 'admin-single-sign-on' }"
        >
          <span>Configure SSO</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item v-b-modal.version-info-modal>
          Show software version
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </template>
    <b-modal
      id="version-info-modal"
      title="Version Information"
      hide-footer
    >
      <b-table
        borderless
        striped
        small
        :items="versionInfoArray"
      />
    </b-modal>
  </b-nav>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import { userIsAllowedToViewRoute } from '@/router';
import { isProduction, isGenerativeAiEnabled } from '@/js/featureFlags';
import { versionInfoArray } from '@/js/utils';
import { apiModes } from '@/js/constants';

export default {
  name: 'BaseHeader',
  headerBtnClass: 'menu-item text-center mb-0 mx-1 ease-in-out p-0 d-flex '
    + 'justify-content-center align-items-center',
  data() {
    return {
      apiModes,
      dataExplorationRouteName: 'dataExploration',
      nluRouteName: 'nlu',
      adminRouteName: 'administration',
      botRouteName: 'bot',
      botScriptRouteName: 'scripthelp',
    };
  },
  computed: {
    ...mapState('templateStore', ['sidebarVisible']),
    ...mapState('auth', ['loggedIn']),
    ...mapGetters('auth', ['isUserLimited', 'isUserNormal', 'isSuperUser']),
    ...mapGetters('userSettings', [
      'getBotStudioSettings',
      'tooltipsOn',
      'showVoicebotFeatures',
      'showTranslationFeatures',
      'showAIFeatures',
    ]),
    getShowQA() {
      return this.getBotStudioSettings.show_qa_page;
    },
    getShowVariants() {
      return this.getBotStudioSettings.show_variants_page;
    },
    getShowDemoPanel() {
      return this.getBotStudioSettings.show_demo_panel_default;
    },
    getShowActivityVariableAssignment() {
      return this.getBotStudioSettings.show_variable_assignment;
    },
    getDemoPanelDefaultApi() {
      return this.getBotStudioSettings.demo_panel_default_api;
    },
    dataExplorationActive() {
      return this.isRouteActive('data-exploration');
    },
    nluActive() {
      return this.isRouteActive(this.nluRouteName);
    },
    admininistrationActive() {
      return this.isRouteActive(this.adminRouteName);
    },
    botActive() {
      return this.isRouteActive(this.botRouteName);
    },
    currentPageIsLoginPage() {
      return this.$route.name === 'login';
    },
  },
  methods: {
    ...mapActions('userSettings', ['toggleShowQA', 'toggleShowVariants', 'toggleShowTooltips',
      'toggleShowDemoPanel', 'toggleDemoPanelDefaultApiMode', 'toggleActivityVariableAssignment',
    ]),
    ...mapMutations('userSettings', [
      'setShowVoicebotFeatures',
      'setShowTranslationFeatures',
      'setShowAIFeatures',
    ]),
    versionInfoArray,
    isProduction,
    isGenerativeAiEnabled,
    isRouteActive(name) {
      return (
        this.$route.matched.findIndex((x) => x.path.substr(1, name.length + 1) === name) !== -1
      );
    },
    wrapUserIsAllowedToViewRouteNamed(routeName) {
      const routeForRouteName = this.$router.resolve({ name: routeName }).route;
      return userIsAllowedToViewRoute(routeForRouteName);
    },
    toggleVoicebot() {
      this.setShowVoicebotFeatures(!this.showVoicebotFeatures);
    },
    toggleTranslation() {
      this.setShowTranslationFeatures(!this.showTranslationFeatures);
    },
    toggleAI() {
      this.setShowAIFeatures(!this.showAIFeatures);
    },
  },
};
</script>

<style scoped>
.menu-item {
  width: 50px !important;
  height: 36px !important;
}
.menu-item svg * {
  fill: #fff!important;
}
::v-deep .help-menu{
  position: relative !important;
  z-index: 10 !important;
}
</style>
