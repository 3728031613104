<template>
  <div>
    IPA Phonetic Alphabet ({{ languageMap[language] }}) <br>
    The voicebot supports the IPA suprasegmentals that are listed here.
    Most cannot be typed with a normal keyboard, so you can copy them from here.
    Note that certain symbols look like symbols on the keyboard.
    <b-table
      :items="symbols"
      :fields="symbolFields"
    />
    <template
      v-for="(item, key) in getTables"
    >
      <div
        :key="item.id"
      >
        <b>{{ toTitleCase(key) }}</b>
        <b-table
          :items="item"
          :fields="fields"
        >
          <template
            v-for="field in formattedCols"
            #[`cell(${field})`]="data"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              :key="field.id"
              v-html="formatter(data.value)"
            />
            <!-- eslint-enable -->
          </template>
        </b-table>
      </div>
    </template>
  </div>
</template>

<script>
import phoneticAlphabets from './phoneticAlphabets';

export default {
  name: 'PhonemeHelp',
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formattedCols: ['ex1', 'ex2', 'ex3'],
      fields: [
        {
          key: 'ipa',
          label: 'IPA Letter',
        },
        {
          key: 'ex1',
          label: 'Example 1',
        },
        {
          key: 'ex2',
          label: 'Example 2',
        },
        {
          key: 'ex3',
          label: 'Example 3',
        },
      ],
      languageMap: {
        da: 'Danish',
        en: 'English',
      },
      symbols: [
        {
          ipa: 'ˈ',
          symbol: 'Primary stress',
          note: 'Don\'t use single quote ( ‘ or \' ) though it looks similar.',
        },
        {
          ipa: 'ˌ',
          symbol: 'Secondary stress',
          note: 'Don\'t use comma ( , ) though it looks similar.',
        },
        {
          ipa: 'ː',
          symbol: 'Long',
          note: 'Don\'t use colon (: or ：) though it looks similar.',
        },
      ],
      symbolFields: [
        {
          key: 'ipa',
          label: 'IPA',
        },
        {
          key: 'symbol',
          label: 'Symbol',
        },
        {
          key: 'note',
          label: 'Note',
        },
      ],
    };
  },
  computed: {
    getTables() {
      return phoneticAlphabets[this.language];
    },
  },
  methods: {
    formatter(phoneme) {
      return phoneme.replace(/\*(.*?)\*/g, '<b>$1</b>');
    },
    toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    },
  },
};
</script>
