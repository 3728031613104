import axios from 'axios';
import endpoints from '@/js/urls';

const initialState = {
  speech2textMultiLanguage: false,
  text2speechVoice: null,
  interruptMode: 'none',
  speech2textContext: null,
  interruptNode: null,
  delayBeforeRepeat: 0,
  inbandDTMF: false,
  chainTimeout: 500,
};

const defaultText2speech = 'azure';
const defaultSpeech2ext = 'azure';

const voiceGetters = {
  getSpeech2textMultiLanguage(state) {
    return state.speech2textMultiLanguage;
  },
  getText2speechVoice(state) {
    return state.text2speechVoice;
  },
  getInterruptMode(state) {
    return state.interruptMode;
  },
  getSpeech2textContext(state) {
    return state.speech2textContext;
  },
  getDefaultText2speech() {
    return defaultText2speech;
  },
  getInterruptNode(state) {
    return state.interruptNode;
  },
  getDelayBeforeRepeat(state) {
    return state.delayBeforeRepeat;
  },
  getInbandDTMF(state) {
    return state.inbandDTMF;
  },
  getChainTimeout(state) {
    return state.chainTimeout;
  },
};

const mutations = {
  setSpeech2textMultiLanguage(state, { value }) {
    state.speech2textMultiLanguage = value;
  },
  setText2speechVoice(state, { voice }) {
    state.text2speechVoice = voice;
  },
  setInterruptMode(state, { mode }) {
    state.interruptMode = mode;
  },
  setSpeech2textContext(state, { context }) {
    state.speech2textContext = context;
  },
  setInterruptNode(state, { node }) {
    state.interruptNode = node;
  },
  setDelayBeforeRepeat(state, { newDelayBeforeRepeat }) {
    state.delayBeforeRepeat = newDelayBeforeRepeat;
  },
  setInbandDTMF(state, { inbandDTMF }) {
    state.inbandDTMF = inbandDTMF;
  },
  setChainTimeout(state, { chainTimeout }) {
    state.chainTimeout = chainTimeout;
  },
  ensureValidInterruptNode(state, { id }) {
    if (state.interruptNode === id) {
      this.commit('botManipulation/activeBot/config/voice/setInterruptNode', '');
    }
  },
};

const actions = {
  async setDefaultVoice({ dispatch, commit }) {
    const voices = await dispatch('fetchSpeech2textVoices');
    commit('setText2speechVoice', { voice: voices[0].name });
  },
  async fetchSpeech2textVoices({ rootState }) {
    const activeBot = rootState.botManipulation.activeBot;
    const locale = activeBot.config.routing.language;
    const supplier = defaultSpeech2ext;
    const { data } = await axios.get(endpoints.voices, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: { locale, supplier },
    });
    return data.voices;
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters: voiceGetters,
  mutations,
  actions,
};
