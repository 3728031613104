import axios from 'axios';
import endpoints from '@/js/urls';

const labelState = {
  text: null,
  categoriesAssignedToDatapoint: [],
  isFetching: false,
};

const getters = {
  text(state) {
    return state.text;
  },
  isFetching(state) {
    return state.isFetching;
  },
  categoriesAssignedToDatapoint(state) {
    return state.categoriesAssignedToDatapoint;
  },
};

const mutations = {
  setIsFetching(state, payload) {
    state.isFetching = payload;
  },
  setText(state, payload) {
    state.text = payload;
  },
  setCategoriesAssignedToDatapoint(state, payload) {
    state.categoriesAssignedToDatapoint = payload;
  },
};

const actions = {
  async fetchData({ commit, rootGetters, dispatch }, { dataId, dataType, datasetId }) {
    commit('setIsFetching', true);
    try {
      const url = `${endpoints.dataExplorationData + datasetId}/`;
      const auth = rootGetters['auth/headerAuthorization'];
      const config = {
        headers: auth.headers,
        params: { data_id: dataId, data_type: dataType },
      };
      const { data } = await axios.get(url, config);
      commit('setText', data.text);
      commit('setCategoriesAssignedToDatapoint', data.assigned_categories);
      commit('setIsFetching', false);
      return true;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'An error occured',
        text: error.message,
        variant: 'danger',
      }, { root: true });
      commit('setIsFetching', false);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state: labelState,
  getters,
  mutations,
  actions,
};
