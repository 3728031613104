<template>
  <b-modal
    id="basicSecretModal"
    :title="modalTitle"
    :ok-title="okTitle"
    size="lg"
    :ok-disabled="!okActionIsOKToInvoke"
    @ok="createOrUpdateSecret"
    @show="prepModalContent"
  >
    <b-form>
      <b-form-group
        label="Name"
        label-for="secretName"
      >
        <b-form-input
          id="secretName"
          v-model="modalContent.secretName"
          autocomplete="off"
          placeholder="Display name for secret (can be changed)"
          type="text"
          :state="$v.modalContent.secretName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretName.required">
            You must provide a name for the secret.
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretName.uniqueSecretName">
            The name is already in use for a secret for this bot.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Username and password"
      >
        <b-form-input
          v-model="modalContent.username"
          type="text"
          autocomplete="off"
          placeholder="Username"
          :state="$v.modalContent.username.$invalid ? false : null"
          @input="() => userUpdatedUsernameValue = true"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.username.required">
            You need to provide a username when configuring Basic authorization
          </div>
        </b-form-invalid-feedback>

        <b-form-input
          v-model="modalContent.password"
          class="mt-2"
          type="text"
          autocomplete="off"
          placeholder="Password"
          :state="$v.modalContent.password.$invalid ? false : null"
          @input="() => userUpdatedUsernameValue = true"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.password.required">
            You need to provide a password when configuring Basic authorization
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>

    <template
      v-if="mode === 'update'"
    >
      <b-button
        variant="outline"
        @click="() => ui.revealSecretCollapseIsOpen = !ui.revealSecretCollapseIsOpen"
      >
        <font-awesome-icon
          :icon="ui.revealSecretCollapseIsOpen ? 'angle-up' : 'angle-right'"
        />
        Inspect current secret
      </b-button>
      <b-collapse
        v-model="ui.revealSecretCollapseIsOpen"
      >
        <p>
          <code>
            {{ secretReveal.prefix }}
            {{ secretReveal.firstCharacter }}*****{{ secretReveal.lastCharacter }}
          </code>
        </p>
        <p>
          Note that the part following 'Basic ' is the base64-encoded version
          (as it should be when using the HTTP Basic authorization scheme) of the username and
          password you provided when creating this secret. <br> See "Techincal details" to
          understand why
        </p>
      </b-collapse>
    </template>

    <b-row>
      <b-col>
        <b-button
          variant="outline"
          @click="() => ui.revealTechnicalImplementation = !ui.revealTechnicalImplementation"
        >
          <font-awesome-icon
            :icon="ui.revealTechnicalImplementation ? 'angle-up' : 'angle-right'"
          />
          Technical details
        </b-button>

        <b-collapse
          v-model="ui.revealTechnicalImplementation"
        >
          The secret is constructed the following way, assume <code>aladdin</code> is used for
          username and <code>opensesame</code> is used for password
          <ol>
            <li>
              Produce the base64-encoding of <code>aladdin:opensesame</code>,
              revealing <code>YWxhZGRpbjpvcGVuc2VzYW1l</code>
            </li>
            <li>
              Prefix this base64-string with <code>Basic </code>, revealing
              <code>Basic YWxhZGRpbjpvcGVuc2VzYW1l</code>
            </li>
            <li>Encrypt and store <code>Basic YWxhZGRpbjpvcGVuc2VzYW1l</code> as the secret</li>
          </ol>
        </b-collapse>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'BasicAuthorizationSecretModal',
  mixins: [validationMixin],
  props: {
    // Mode is either: 'update' or 'create' and denotes whether to create a new secret or update an
    // existing secret. If mode is 'update', prop secretToUpdate must also be set.
    mode: {
      type: String,
      required: false,
      default: 'create',
    },
    secretToUpdate: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      ui: {
        revealSecretCollapseIsOpen: false,
      },
      modalContent: {
        secretName: null,
        username: null,
        password: null,
      },
      secretReveal: {
        prefix: null,
        firstCharacter: null,
        lastCharacter: null,
      },
      // Keep hold of the the initial name. Should only be set when modal is opened / prepared.
      secretNameUponShow: null,
      userUpdatedUsernameValue: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    okActionIsOKToInvoke() {
      if (this.mode === 'update') {
        // Verify that we're actually about to update anything
        if (this.$v.modalContent.$invalid) {
          // The validations take into account if user has updated the secret value, so something
          // in modalContent _is not_ valid
          return false;
        }
        if (this.userUpdatedUsernameValue
            || this.modalContent.secretName !== this.secretNameUponShow) {
          // User _did_ update the secret name
          return true;
        }
        // User did neither update secret value nor update the secret name -> do not allow user to
        // fire this update-request.
        return false;
      }
      return !this.$v.modalContent.$invalid;
    },
    modalTitle() {
      if (this.mode === 'update') {
        return `Update secret for basic authorization header: ${this.secretToUpdate.name}`;
      } if (this.mode === 'create') {
        return 'Create secret for basic authorization header';
      }
      return '';
    },
    okTitle() {
      if (this.mode === 'update') {
        return 'Update';
      }
      if (this.mode === 'create') {
        return 'Create';
      }
      return '';
    },
  },
  async mounted() {
    await this.fetchSecrets();
  },
  methods: {
    ...mapActions('botSecrets', [
      'fetchSecrets',
      'createBasicAuthorizationSecret',
      'updateBasicAuthorizationSecret',
      'fetchPartiallyRevealedSecret',
    ]),
    async createOrUpdateSecret() {
      if (this.mode === 'update') {
        await this.updateBasicAuthorizationSecret({
          botId: this.getBotId,
          secretId: this.secretToUpdate.id,
          newSecretName: this.modalContent.secretName,
          newSecretBasicUsername: this.modalContent.username,
          newSecretBasicPassword: this.modalContent.password,
        });
      } else if (this.mode === 'create') {
        await this.createBasicAuthorizationSecret({
          botId: this.getBotId,
          secretName: this.modalContent.secretName,
          username: this.modalContent.username,
          password: this.modalContent.password,
        });
      }
    },
    async prepModalContent() {
      await this.fetchSecrets();
      this.userUpdatedUsernameValue = false;

      if (this.mode === 'update') {
        this.modalContent.secretName = this.secretToUpdate.name;
        this.secretNameUponShow = this.secretToUpdate.name;

        // Fetch info for partial reveal
        const response = await this.fetchPartiallyRevealedSecret({
          botId: this.getBotId,
          secretId: this.secretToUpdate.id,
        });

        this.secretReveal.prefix = response.data.secret_prefix;
        this.secretReveal.firstCharacter = response.data.first_character;
        this.secretReveal.lastCharacter = response.data.last_character;
      } else {
        this.modalContent.secretName = null;

        this.secretReveal.prefix = null;
        this.secretReveal.firstCharacter = null;
        this.secretReveal.lastCharacter = null;
      }
      this.modalContent.username = null;
      this.modalContent.password = null;
    },
  },
  validations: {
    modalContent: {
      secretName: {
        required,
        uniqueSecretName(suggestedValue) {
          const botSecrets = this.getBotSecrets(this.activeBotId);
          if (botSecrets === undefined) {
            return true;
          }
          if (suggestedValue === this.secretNameUponShow) {
            // Name hasn't changed from its original value
            return true;
          }
          const secretNames = botSecrets.map((x) => x.name);
          return !secretNames.includes(suggestedValue);
        },
      },
      username: {
        required(newValue) {
          if (this.mode === 'update' && !this.userUpdatedUsernameValue) {
            // User is updating an existing secret but she/he has not changed the secret value
            // (yet), so we're still in a valid state.
            return true;
          }
          return required(newValue);
        },
      },
      password: {
        required(newValue) {
          if (this.mode === 'update' && !this.userUpdatedUsernameValue) {
            // User is updating an existing secret but she/he has not changed the secret value
            // (yet), so we're still in a valid state.
            return true;
          }
          return required(newValue);
        },
      },
    },
  },
};
</script>
