<template>
  <div
    class="info"
  >
    <div
      v-if="showName"
      class="messageContent name"
    >
      Info
    </div>
    <p
      class="messageContent mb-0"
      :class="lastMessage ? 'last-message' : ''"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'InfoMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      required: true,
    },
    lastMessage: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>

  .info {
    background: rgba(255,253,234, 0.65);
  }

</style>
