<template>
  <b-modal
    :id="modalId"
    :title="title"
    ok-only
    :ok-disabled="okDisabled"
    @shown="showNewNodeModal"
    @ok="addNodeProxy()"
  >
    <b-form-group
      description="Write a name and press enter."
    >
      <b-form-input
        ref="newNodeNameInput"
        v-model="newNodeName"
        type="text"
        :state="$v.newNodeName.$invalid ? false : null"
        placeholder="Some name..."
        aria-describedby="newNodeNameFeedback"
        @keyup.enter="addNodeProxy()"
      />
      <b-form-invalid-feedback id="newNodeNameFeedback">
        <div v-if="!$v.newNodeName.required">
          The node needs a name.
        </div>
        <div v-if="!$v.newNodeName.uniqueName">
          The name already exists.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-if="parentIsSubflow"
      label-for="outgoing-select"
      label="Outgoing node"
      invalid-feedback="Choose an outgoing node."
      description="The parent is a subflow."
      :state="Boolean(outgoingNode)"
    >
      <b-select
        id="outgoing-select"
        v-model="outgoingNode"
        :options="outgoingOptions"
      />
    </b-form-group>
    <b-form-checkbox
      v-if="isMainBot"
      v-model="addSubflow"
      switch
    >
      Add subflow
    </b-form-checkbox>
    <b-form-group
      v-if="addSubflow"
      class="mt-2"
      label-for="subflow-select"
      label="Subflow to add"
      invalid-feedback="Choose a subflow."
      :state="Boolean(subflowToAdd)"
    >
      <b-select
        id="subflow-select"
        v-model="subflowToAdd"
        :options="subflowOptions"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { guidGenerator } from '@/js/utils';
import { nodeTypes } from '@/js/constants';

export default {
  name: 'AddNodeModal',
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: 'Create new node',
    },
    parentNodeId: {
      required: false,
      type: String,
      default: null,
    },
    modalId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      newNodeName: '',
      outgoingNode: '',
      addSubflow: '',
      subflowToAdd: null,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'isNameUsed',
      'nodeById',
      'nameOfId',
    ]),
    ...mapGetters('botManipulation', [
      'customNode',
    ]),
    ...mapState('botManipulation', [
      'subFlows',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    parentNode() {
      return this.parentNodeId ? this.nodeById(this.parentNodeId) : null;
    },
    parentIsSubflow() {
      return this.parentNodeId ? this.parentNode.options.nodeType === 'subflow' : null;
    },
    subflow() {
      if (!this.parentNodeId) {
        return null;
      }
      const subFlows = this.$store.state.botManipulation.subFlows;
      const subFlowID = this.parentNode.subFlowMap.subFlowID;
      return subFlows.find((sf) => sf.id === subFlowID);
    },
    outgoingOptions() {
      if (!this.parentNodeId) {
        return null;
      }
      const outGoingNodes = Object.values(this.subflow.nodes).filter((n) => n.options.outgoing);
      return outGoingNodes.map((node) => ({ value: node.id, text: node.name }));
    },
    okDisabled() {
      if (this.$v.newNodeName.$invalid) {
        return true;
      }
      if (this.parentIsSubflow && !this.outgoingNode) {
        return true;
      }
      if (this.addSubflow && !this.subflowToAdd) {
        return true;
      }
      return false;
    },
    subflowOptions() {
      return this.subFlows.map((x) => ({ value: x.id, text: x.config.name }));
    },
  },
  methods: {
    ...mapActions('botManipulation', [
      'addNode',
    ]),
    ...mapActions('botManipulation/activeBot', [
      'updateSubFlowMap',
    ]),
    showNewNodeModal() {
      this.newNodeName = '';
      this.outgoingNode = '';
      this.$refs.newNodeNameInput.focus();
    },
    async addNodeProxy() {
      if (this.isNameUsed(this.newNodeName)) {
        return;
      }
      const newNode = JSON.parse(JSON.stringify({
        ...this.customNode,
        id: guidGenerator(),
        name: this.newNodeName,
        preds: this.parentNodeId ? [this.parentNodeId] : [],
      }));

      if (this.addSubflow) {
        newNode.subFlowMap = {
          input: {
            actions: {},
            ners: {},
            vars: {},
          },
          output: {
            ners: {},
            vars: {},
          },
          subFlowID: this.subflowToAdd,
          outgoing: {},
        };
        newNode.options.nodeType = nodeTypes.SUBFLOW;
      }

      // add to store
      this.addNode(newNode);

      // update outgoing if child of subflow
      if (this.parentIsSubflow) {
        await this.addToOutgoing(newNode);
      }

      this.$bvModal.hide(this.modalId);
      this.newNodeName = '';

      this.$emit('nodeAdded', newNode.id);
    },
    async addToOutgoing(newNode) {
      // the subFowMap.outgoing is only initialized when used on the edit page. It is therefore
      // possible that it may not contain the outgoing ID we want to use yet. In that case, we
      // should initialize it.
      let outgoingObj = {
        childrenIds: [],
      };
      if (this.parentNode.subFlowMap.outgoing[this.outgoingNode] !== undefined) {
        outgoingObj = JSON.parse(JSON.stringify(
          this.parentNode.subFlowMap.outgoing[this.outgoingNode],
        ));
      }
      outgoingObj.childrenIds.push(newNode.id);
      await this.updateSubFlowMap({
        id: this.parentNodeId,
        keys: ['outgoing', this.outgoingNode],
        value: outgoingObj,
      });
    },
  },
  validations: {
    newNodeName: {
      required,
      uniqueName(value) {
        return !this.isNameUsed(value);
      },
    },
  },
};
</script>
