<template>
  <b-container
    fluid
    class="p-0 h-100"
  >
    <router-view />
    <bot-studio-toaster />
  </b-container>
</template>

<script>
import BotStudioToaster from '@/components/BotStudioToaster.vue';

export default {
  name: 'Administrator',
  components: {
    BotStudioToaster,
  },
};
</script>
