<template>
  <div>
    <!-- The purpose of this component is to extract/hide a _huge_ (if, else-if, ...) of
      metric-specific configuration-components
      Below goes the mega (if, else-if, else-if) statement, one for each customizable metric
    -->
    <draft-responses-configuration
      v-if="metricIdentifier === 'NUMBER_OF_DRAFT_REPLIES'"
    />
    <examples-configuration
      v-if="metricIdentifier === 'HAS_ENOUGH_EXAMPLES_PER_NODE'"
    />
    <young-node-configuration
      v-if="metricIdentifier === 'CHECKUP_ON_YOUNG_NODE_RESPONSES'"
    />
    <link-check-configuration
      v-if="metricIdentifier === 'LINK_CHECKING'"
    />
  </div>
</template>

<script>
import DraftResponsesConfiguration from '@/pages/Health/Configurations/DraftResponsesConfiguration.vue';
import ExamplesConfiguration from '@/pages/Health/Configurations/ExamplesConfiguration.vue';
import YoungNodeConfiguration from '@/pages/Health/Configurations/YoungNodeConfiguration.vue';
import LinkCheckConfiguration from '@/pages/Health/Configurations/LinkCheckConfiguration.vue';

export default {
  name: 'HealthMetricConfig',
  components: {
    YoungNodeConfiguration,
    DraftResponsesConfiguration,
    ExamplesConfiguration,
    LinkCheckConfiguration,
  },
  props: {
    metricIdentifier: {
      type: String,
      required: true,
    },
  },
};
</script>
