<template>
  <main
    role="main"
  >
    <b-card
      title="Variables"
      class="r-75"
      body-class="p-3"
    >
      This page provides on overview of the variables in the bot and their usage.
    </b-card>
    <b-card
      class="r-75 mt-3 p-3"
      no-body
      style="border:none;"
    >
      <h6 class="font-weight-normal">
        Pick variable type:
      </h6>

      <b-form-select
        v-model="variableFilter"
        class="mb-3"
        :options="variableFilters"
      />
      Pick variable:
      <b-form-select
        v-model="variableName"
        :options="foundVariableNames"
      />
      <hr>
      <b-list-group>
        <div v-if="foundNodeActivities.size">
          <h6 class="font-weight-normal">
            Node Activities:
          </h6>
          <b-list-group-item
            v-for="nodeId in foundNodeActivities"
            :key="`node_activity_${nodeId}`"
            button
            variant="info"
            @click="event => nodeNav(nodeId, event)"
          >
            <p class="mb-0">
              {{ nameOfId(nodeId) }}
            </p>
          </b-list-group-item>
        </div>
        <div v-if="foundNodeRequirements.size">
          <h6 class="font-weight-normal mt-3">
            Node Requirements:
          </h6>
          <b-list-group-item
            v-for="nodeId in foundNodeRequirements"
            :key="`node_requirements_${nodeId}`"
            button
            variant="info"
            @click="event => nodeNav(nodeId, event)"
          >
            <p class="mb-0">
              {{ nameOfId(nodeId) }}
            </p>
          </b-list-group-item>
        </div>
        <div v-if="foundInactivities.size">
          <h6 class="font-weight-normal mt-3">
            Inactivity:
          </h6>
          <b-list-group-item
            v-for="inactivityTime in foundInactivities"
            :key="`inactivity_${inactivityTime}`"
            button
            variant="info"
            @click="event => inactivityNav(inactivityTime, event)"
          >
            <p class="mb-0">
              Inactivity at time {{ inactivityTime }}
            </p>
          </b-list-group-item>
        </div>
        <div v-if="foundIntegrations.size">
          <h6 class="font-weight-normal mt-3">
            Integrations:
          </h6>
          <b-list-group-item
            v-for="integrationName in foundIntegrations"
            :key="`integration_${integrationName}`"
            button
            variant="info"
            @click="event => integrationNav(integrationName, event)"
          >
            <p class="mb-0">
              Integration {{ integrationName }}
            </p>
          </b-list-group-item>
        </div>
        <div v-if="foundConfigurations.size">
          <h6 class="font-weight-normal mt-3">
            Configurations:
          </h6>
          <b-list-group-item
            v-for="configName in foundConfigurations"
            :key="`config_${configName}`"
            button
            variant="info"
          >
            <ConfigLink :category="configName">
              Found in {{ configName }} configuration
            </ConfigLink>
          </b-list-group-item>
        </div>
        <div v-if="foundNERs.size">
          <h6 class="font-weight-normal mt-3">
            Entities:
          </h6>
          <b-list-group-item
            v-for="nerName in foundNERs"
            :key="`ner_${nerName}`"
            button
            variant="info"
            @click="event => nerNav(nerName, event)"
          >
            <p class="mb-0">
              Entity {{ nerName }}
            </p>
          </b-list-group-item>
        </div>
        <div v-if="foundSubflows.size">
          <h6 class="font-weight-normal mt-3">
            Subflows:
          </h6>
          <b-list-group-item
            v-for="subflowId in foundSubflows"
            :key="`subflow_${subflowId}`"
            button
            variant="info"
            @click="event => subflowNav(subflowId, event)"
          >
            <p class="mb-0">
              Subflow {{ getSubFlowNameFromId(subflowId) }}'s input/output
            </p>
          </b-list-group-item>
        </div>
        <div v-if="foundSubflowNodes.size">
          <h6 class="font-weight-normal mt-3">
            Subflow Nodes:
          </h6>
          <b-list-group-item
            v-for="nodeId in foundSubflowNodes"
            :key="`subflownode_${nodeId}`"
            button
            variant="info"
            @click="event => nodeNav(nodeId, event)"
          >
            <p class="mb-0">
              {{ nameOfId(nodeId) }} options
            </p>
          </b-list-group-item>
        </div>
      </b-list-group>
    </b-card>
    <b-card
      v-if="getErrors.length > 0"
      class="r-75 mt-3 p-3"
      no-body
    >
      Warning, detected errors in {{ getErrors.length }} Botscript(s).

      <div
        v-for="(error, index) in getErrors"
        :key="index"
      >
        <b-list-group-item
          v-if="error.id.type === 'nodeActivity'"
          :key="`node_activity_${error.id.nodeId}`"
          button
          variant="danger"
          @click="event => nodeNav(error.id.nodeId, event)"
        >
          <p class="mb-0">
            {{ nameOfId(error.id.nodeId) }} (activity)
          </p>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="error.id.type === 'nodeRequirement'"
          :key="`node_requirements_${error.id.nodeId}`"
          button
          variant="danger"
          @click="event => nodeNav(error.id.nodeId, event)"
        >
          <p class="mb-0">
            {{ nameOfId(error.id.nodeId) }} (requirement)
          </p>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="error.id.type === 'config'"
          :key="`config_${error.id.name}`"
          button
          variant="danger"
        >
          <ConfigLink :category="error.id.name">
            Found in {{ error.id.name }} configuration
          </ConfigLink>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="error.id.type === 'subflow'"
          :key="`subflow_${error.id.subflowId}`"
          button
          variant="danger"
          @click="event => subflowNav(error.id.subflowId, event)"
        >
          <p class="mb-0">
            Subflow {{ getSubFlowNameFromId(error.id.subflowId) }}'s input/output
          </p>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="error.id.type === 'subflowNode'"
          :key="`subflownode_${error.id.nodeId}`"
          button
          variant="danger"
          @click="event => nodeNav(error.id.nodeId, event)"
        >
          <p class="mb-0">
            {{ nameOfId(error.id.nodeId) }} (options)
          </p>
        </b-list-group-item>
        Found {{ error.errors.length }} error(s):
        <pre><div
          v-for="(errorMsg, msgIndex) in error.errors"
          :key="msgIndex"
        >{{ errorMsg.msg }}</div></pre>
      </div>
    </b-card>
  </main>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ConfigLink from '@/pages/BotConfig/ConfigLink.vue';

export default {
  name: 'VariablesPage',
  components: { ConfigLink },
  data() {
    return {
      variableFilters: ['All', 'Read Only', 'Write Only'],
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getSubFlowNameFromId',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
    ...mapGetters('botscript', [
      'getVariables',
      'getErrors',
      'getVariableName',
      'getVariableFilter',
    ]),
    variableName: {
      get() {
        return this.getVariableName;
      },
      set(name) {
        this.setVariableName({ name });
      },
    },
    variableFilter: {
      get() {
        return this.getVariableFilter;
      },
      set(filter) {
        this.setVariableFilter({ filter });
      },
    },
    variables() {
      const vars = { ...this.getVariables };
      if (this.variableName !== '') {
        // Make sure what we current look for is still present
        if (!(this.variableName in vars)) {
          vars[this.variableName] = {
            written: [],
            read: [],
          };
        }
      }
      return vars;
    },
    variableCount() {
      return Object.keys(this.variables).length;
    },
    foundVariableNames() {
      if (this.variableFilter === 'All') {
        const allVariableNames = Object.keys(this.variables);
        allVariableNames.sort();
        return allVariableNames;
      }
      const filteredVariables = [];
      for (const [name, info] of Object.entries(this.variables)) {
        if (this.variableFilter === 'Read Only' && info.written.length === 0) {
          filteredVariables.push(name);
        }
        if (this.variableFilter === 'Write Only' && info.read.length === 0) {
          filteredVariables.push(name);
        }
      }
      filteredVariables.sort();
      return filteredVariables;
    },
    foundNodeActivities() {
      return this.filteredTypeSet('nodeActivity', 'nodeId');
    },
    foundNodeRequirements() {
      return this.filteredTypeSet('nodeRequirement', 'nodeId');
    },
    foundInactivities() {
      return this.filteredTypeSet('inactivity', 'time');
    },
    foundIntegrations() {
      return this.filteredTypeSet('integration', 'name');
    },
    foundConfigurations() {
      return this.filteredTypeSet('config', 'subtype');
    },
    foundNERs() {
      return this.filteredTypeSet('ner', 'name');
    },
    foundSubflows() {
      return this.filteredTypeSet('subflow', 'flowId');
    },
    foundSubflowNodes() {
      return this.filteredTypeSet('subflowNode', 'nodeId');
    },
  },
  mounted() {
    this.updateScripts();
  },
  methods: {
    ...mapMutations('botscript', [
      'setVariableFilter',
      'setVariableName',
    ]),
    ...mapActions('botscript', [
      'updateScripts',
    ]),
    filteredTypeSet(type, itemKey) {
      if (!(this.variableName in this.variables)) {
        return [];
      }
      const set = new Set();
      for (const item of this.variables[this.variableName].read) {
        if (item.type === type) {
          set.add(item[itemKey]);
        }
      }
      for (const item of this.variables[this.variableName].written) {
        if (item.type === type) {
          set.add(item[itemKey]);
        }
      }
      return set;
    },
    generalNav(event, routeTo) {
      this.openCtrlLink(routeTo, event);
    },
    nodeNav(id, event) {
      const botId = this.$route.params.botId;
      this.generalNav(event, { name: 'edit-node', params: { botId, nodeId: id } });
    },
    inactivityNav(time, event) {
      const botId = this.$route.params.botId;
      this.generalNav(event, { name: 'config', params: { botId }, hash: '#inactivity' });
    },
    integrationNav(name, event) {
      const botId = this.$route.params.botId;
      this.generalNav(event, { name: 'integrations', params: { botId }, hash: `#${name}` });
    },
    nerNav(name, event) {
      const botId = this.$route.params.botId;
      this.generalNav(event, { name: 'entities', params: { botId }, hash: `#${name}` });
    },
    subflowNav(subflowId, event) {
      const botId = this.$route.params.botId;
      this.generalNav(event, {
        name: 'config',
        params: { botId: `${botId}-${subflowId}` },
        hash: '#subflow-variables',
      });
    },
  },
};
</script>
