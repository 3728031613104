<template>
  <div>
    <DescriptionBox v-if="isMaster">
      This activity will automatically formulate a response to the user based on the chat
      history/transcript and the standard response written below.<br />

      The formulated response will automatically be sent if succesfully generated.
      Otherwise, the standard response will be sent.
    </DescriptionBox>
    <response-activity-text-input
      ref="textInputField"
      :uid="nodeId + activityId"
      :activity-id="activityId"
      :node-id="nodeId"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import ResponseActivityTextInput from '@/pages/EditNode/activity/ResponseActivityTextInput.vue';
import DescriptionBox from './DescriptionBox.vue';

export default {
  name: 'ReformulateReply',
  components: {
    ResponseActivityTextInput,
    DescriptionBox,
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: 'master',
    },
    data: {
      type: Object,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    isMaster() {
      return this.type === 'master';
    },
  },
  methods: {

  },
};

</script>
