import moment from 'moment';
import { truncateString } from '@/js/utils';
import { isProduction } from '@/js/featureFlags';

export function toNameFilter(object) {
  if (!object) {
    if (!isProduction()) {
      console.error('Object does not have attribute "name":', object);
    }
    return 'undefined';
  }
  return object.displayName ? object.displayName : object.name;
}

export function toDatetimeFilter(object) {
  return moment(object).format('YYYY-MM-DD HH:mm:ss');
}

export function toNameCapFilter(object, maxLength = 60) {
  const name = toNameFilter(object) || 'undefined';
  return truncateString(name, maxLength);
}

export function toEnabledFilter(enabled) {
  return enabled ? 'enabled' : 'disabled';
}

export function num2str(val) {
  if (val === undefined || val === null || Number.isNaN(val)) {
    return val;
  }
  // < 1e3: 0 digit precision
  // >= 1e3: 1 digit precision
  const num = Number(val);
  const limits = [
    { symbol: '', digits: 0 },
    { symbol: 'k', digits: 1 },
    { symbol: 'M', digits: 1 },
    { symbol: 'G', digits: 1 },
    { symbol: 'T', digits: 1 },
    { symbol: 'P', digits: 1 },
    { symbol: 'E', digits: 1 },
    { symbol: 'Z', digits: 1 },
    { symbol: 'Y', digits: 1 },
  ];
  // Special case for numbers below 1000
  let tier = Math.log10(num) / 3 | 0;
  if (tier === 0) {
    return num;
  }
  if (tier < limits.length) {
    // Check if rounding causes the number to change 1 tier up
    const digits = limits[tier].digits;
    if (Math.round(num / (10 ** ((tier * 3) - digits))) === 10 ** (3 + digits)) {
      tier += 1;
    }
  }
  const suffix = limits[tier].symbol;
  const scale = 10 ** (tier * 3);
  const scaled = num / scale;

  return scaled.toFixed(limits[tier].digits) + suffix;
}
