<template>
  <div>
    <b-row class="my-3">
      <b-col :class="textClass">
        <b-badge
          pill
          class="custom-badge"
          variant="primary"
        >
          {{ name }}
        </b-badge>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="border r-25 p-2" style="background-color: #EEEEEE; min-height:37px;" v-html="filteredText" />
      </b-col>
      <b-col v-if="!hideActions" cols="auto">
        <b-button
          v-b-tooltip.hover.noninteractive.viewport
          title="Edit phrase"
          size="sm"
          class="mr-1"
          :disabled="editDisabled"
          @click="$emit('edit', id)"
        >
          <font-awesome-icon icon="pencil" />
        </b-button>
        <b-button
          v-b-tooltip.hover.noninteractive.viewport
          size="sm"
          title="Remove phrase"
          @click="$emit('remove', id)"
        >
          <font-awesome-icon icon="minus" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { formatTextWithLinebreaks } from '@/js/utils';

export default {
  name: 'PhraseBuildingBlock',
  props: {
    id: {
      type: String,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['phraseText', 'phraseName']),
    ...mapGetters('botManipulation/activeBot/config', ['wysiwygEnabled']),
    text() {
      return this.phraseText(this.id);
    },
    name() {
      return this.phraseName(this.id);
    },
    filteredText() {
      const text = this.value ? this.value : this.text;
      return this.wysiwygEnabled ? formatTextWithLinebreaks(text) : text;
    },
    textClass() {
      return this.hideActions ? 'pr-3' : 'pr-1';
    },
  },
  methods: {
    ...mapMutations('botManipulation/', ['setActivePhraseId']),
  },
};
</script>
<style scoped>
.custom-badge{
  position: absolute;
  top: -10px;
  left: 4px;
  font-size:95%;
}
</style>
