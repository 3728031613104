<template>
  <div>
    <h3>Final Activities</h3>
    <b-card
      no-body
    >
      <p class="mb-2">
        Here you can configure final activities you always want to run after a
        conversation has ended.
      </p>
      <p class="mb-2">
        Ended here means ended in a broad sense:
        <ul class="mb-2">
          <li>
            The user ended the conversation
          </li>
          <li>
            The bot ended the conversation (by action "End conversation")
          </li>
          <li>
            The conversation was transferred
          </li>
          <li>
            The conversation experienced an error, and the bot hence came to the ErrorNode
          </li>
        </ul>
      </p>
      <p class="mb-2">
        You cannot end or transfer the conversation within final activities.
      </p>
      <p>
        You configure final activities just like you would configure activities of any node.<br>
      </p>
    </b-card>
    <hr>
    <p
      v-if="finalNodeActivityIds.length === 0"
      class="mb-1"
    >
      Pick an activity from the panel below to insert a final activity.
    </p>
    <node-edit-activities-content
      node-id="final"
      :allowed-activities="allowedActivities"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NodeEditActivitiesContent from '@/pages/EditNode/NodeEditActivitiesContent.vue';
import {
  ACTION,
  CALL_MODEL,
  CHAT_ACTION,
  CONTROL_FLOW,
  ENCRYPT,
  GENERATIVE_AI,
  METRIC_SIGNAL,
  SET_VARIABLE,
} from '@/js/activity';

export default {
  name: 'BotConfigFinalActivities',
  components: {
    NodeEditActivitiesContent,
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('userSettings', ['showAIFeatures']),
    allowedActivities() {
      const allowedActivities = new Set([
        ACTION,
        CALL_MODEL,
        CHAT_ACTION,
        CONTROL_FLOW,
        ENCRYPT,
        METRIC_SIGNAL,
        SET_VARIABLE,
      ]);
      if (this.showAIFeatures) {
        allowedActivities.add(GENERATIVE_AI);
      }
      return allowedActivities;
    },
    finalNodeActivityIds() {
      const finalNode = this.nodeById('final');
      // Not defined/should not be in subflows
      if (!finalNode) {
        return [];
      }
      return finalNode.activityIds;
    },
  },
};

</script>
