<template>
  <div>
    <!-- Add your "how-to-resolve" _description_ below for any new metric you add. The description
    should not allow the user to resolve the issue from here
    (i.e by providing settings-toggles here); instead you should give tangible advice to the user as
    to what he/she must do some place else in BotStudio.
    -->
    <span
      v-b-tooltip.hover.vieport.noninteractive
      title="Resolution"
    >
      <font-awesome-icon
        class="text-primary"
        icon="lightbulb"
      />
    </span>

    <template
      v-if="metricIdentifier === 'HAS_PLATFORM_BEEN_CONFIGURED'"
    >
      You can set or check the bot's platform in
      <ConfigLink category="general">
        Bot configuration
      </ConfigLink>
    </template>

    <template
      v-if="metricIdentifier === 'HAS_LANGUAGE_BEEN_CONFIGURED'"
    >
      You can set or check the bot's main language in
      <ConfigLink category="general">
        Bot configuration
      </ConfigLink>
    </template>

    <template
      v-else-if="metricIdentifier === 'HAS_ATLEAST_3_CONVERSATION_TESTS'"
    >
      You create a conversation-test by running a conversation in the testpanel and then clicking
      "Add to conversation test". <br>
      You can review existing conversation tests on the

      <span @click="$bvModal.hide(metricIdentifier)">
        <router-link
          :to="{ name: 'health', hash: '#tests' }"
        >
          Conversation tests page
        </router-link>
      </span>
    </template>

    <template
      v-else-if="metricIdentifier === 'FALLBACKNODE_CONFIGURED'"
    >
      You should either make FallBackNode
      <ul>
        <li>Go to a child that then handles the error state</li>
        <li>End conversation</li>
        <li>Transfer conversation</li>
      </ul>
      You can
      <router-link
        :to="editNodeLink('fallback')"
      >
        configure the FallBackNode here
      </router-link>
    </template>

    <template
      v-else-if="metricIdentifier === 'ERRORNODE_CONFIGURED'"
    >
      You should either make ErrorNode
      <ul>
        <li>Go to a child that then handles the error state</li>
        <li>End conversation</li>
        <li>Transfer conversation</li>
      </ul>
      You can
      <router-link
        :to="editNodeLink('error')"
      >
        configure the ErrorNode here
      </router-link>
    </template>

    <template
      v-else-if="metricIdentifier === 'HAS_NEUTRAL_MATCHING_EXAMPLES'"
    >
      The bot uses examples in matching, but there is not enough (50) neutral examples.
      To make the matching perform better you can go to the classifiers page and
      and upload a file containing lines of neutral examples.
    </template>

    <template
      v-else-if="metricIdentifier === 'HAS_ENOUGH_EXAMPLES_PER_NODE'"
    >
      For all the nodes using examples for matching make sure that at least the configured
      number examples are added.
    </template>

    <template
      v-else-if="metricIdentifier === 'HAS_SMARTNODE_CHILDREN_WO_MATCHING'"
    >
      For all the nodes that do not have matching configured and who appears as children of
      multiple-choice or smart nodes, make sure that matching is configured via examples or a
      classifier.
    </template>

    <template
      v-else-if="metricIdentifier === 'USES_FRESH_CLASSIFIERS'"
    >
      Training your classifiers in the NLU section and updating them on the bot could lead to
      better performance. This is likely to happen when more data has been accumulated. You can
      enable automatic training in the NLU section and automatic updates in the classifier
      registration on the bot.
    </template>

    <template
      v-else-if="metricIdentifier === 'NUMBER_OF_DRAFT_REPLIES'"
    >
      Review, correct if needed and approve any draft responses on the
      <router-link
        :to="{ name: 'responses' }"
      >
        Responses page
      </router-link>
    </template>

    <template
      v-else-if="metricIdentifier === 'TOO_MANY_DETACHED_NODES'"
    >
      Having detached nodes in the graph could be an indication of a broken link somewhere.<br>
      You can go to
      <router-link
        :to="{ name: 'flow' }"
      >
        Conversation flow
      </router-link>
      and click "Search" with the relevant filter to see an overview. <br>
    </template>

    <template
      v-else-if="metricIdentifier === 'HAS_LOOSE_ENDS'"
    >
      Make sure that all nodes have at least one child, "end conversation",
      "leave conversation" (Zendesk) or "transfer to department" action.
    </template>
    <template
      v-else-if="metricIdentifier === 'LINK_CHECKING'"
    >
      You can update broken links from
      <router-link
        :to="{ name: 'responses', query: linkQuery }"
      >
        Responses page
      </router-link>.
    </template>
    <template
      v-else-if="metricIdentifier === 'BUTTON_TEXT_LENGTH'"
    >
      Make sure the length of each button text does not exceed 40 characters.
    </template>
    <template
      v-else-if="metricIdentifier === 'ENSURE_CLASSIFIER_COMPATIBILITY'"
    >
      Update the classifier used for matching the affected nodes, or reintroduce the missing labels
      to the affected {{ pluralize(insightDetails.missing_labels, 'classifier') }}
    </template>
    <template
      v-else-if="metricIdentifier === 'CHECKUP_ON_YOUNG_NODE_RESPONSES'"
    >
      Check the responses are to the point and then press ignore.
    </template>
  </div>
</template>

<script>
import ConfigLink from '@/pages/BotConfig/ConfigLink.vue';

export default {
  name: 'HealthMetricHowToResolve',
  components: { ConfigLink },
  props: {
    metricIdentifier: {
      type: String,
      required: true,
    },
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    linkQuery() {
      if (this.metricIdentifier === 'LINK_CHECKING') {
        return Object.entries(this.insightDetails).filter((e) => !e[1]).map((e) => e[0]);
      }
      return {};
    },
  },
  methods: {
    pluralize(items, string) {
      if (items !== undefined && items.length > 1) {
        return `${string}s`;
      }
      return string;
    },
  },
  /* Please avoid putting additional logic or state into this component.
  This component is only intended to _display_ a text on how to resolve a given metric,
  isolating/extracting a _huge_ (if, else-if, ...) statement into a single componenent.
  */
};
</script>
