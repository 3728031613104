<template>
  <b-modal
    id="settings-modal"
    title="Health Settings"
    ok-title="Done"
    ok-only
  >
    <b-row class="mb-3">
      <b-col>
        <b-form
          inline
          class="mt-2"
        >
          Compute improvements based on data from
          <b-form-select
            v-model="improvementsPeriod"
            :disabled="loadingSuggestions"
            class="ml-2"
            size="sm"
            :options="timeOptions"
          />
        </b-form>
      </b-col>
    </b-row>
    <hr>
    <b-form-checkbox
      v-model="showCompleted"
      style="display: inline-block"
    >
      Show passed checks
    </b-form-checkbox>
    <div
      v-b-toggle.disabled-checks-collapse
      class="mt-2"
      style="cursor: pointer"
    >
      <b-button
        role="tablist"
        variant="link"
      >
        <font-awesome-icon
          icon="angle-down"
        />
      </b-button>
      Disabled checks
    </div>
    <b-collapse
      id="disabled-checks-collapse"
      accordion="metricConfig"
      role="tabpanel"
    >
      <div v-if="disabledCards.length === 0">
        No checks have been disabled.
      </div>
      <b-list-group v-else>
        <b-list-group-item
          v-for="card in disabledCards"
          :key="card.identifier"
          class="p-2"
        >
          <b-row>
            <b-col class="my-auto">
              {{ card.displayName }}
            </b-col>
            <b-col
              cols="auto"
              class="my-auto"
            >
              <b-button
                class="float-right"
                variant="success"
                size="sm"
                @click="enableMetric({ metricIdentifier: card.identifier })"
              >
                Enable
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-collapse>
    <template
      v-for="metricWithCustomizationOptions in metricsWithCustomizationOptions"
    >
      <div
        :key="`span${metricWithCustomizationOptions.identifier}`"
        v-b-toggle="`modal-config${metricWithCustomizationOptions.identifier}`"
        class="mt-2"
        style="cursor: pointer"
      >
        <b-button
          :key="`button${metricWithCustomizationOptions.identifier}`"
          role="tablist"
          variant="link"
        >
          <font-awesome-icon
            icon="angle-down"
          />
        </b-button>
        {{ displayNameForMetric(metricWithCustomizationOptions.identifier) }}
      </div>
      <b-collapse
        :id="`modal-config${metricWithCustomizationOptions.identifier}`"
        :key="metricWithCustomizationOptions.identifier"
        accordion="metricConfig"
        role="tabpanel"
      >
        <health-metric-config
          :key="`metricConfig${metricWithCustomizationOptions.identifier}`"
          :metric-identifier="metricWithCustomizationOptions.identifier"
          :current-config="computeConfigForMetric(metricWithCustomizationOptions.identifier)"
          @configForMetricUpdated="configForMetricUpdated"
        />
      </b-collapse>
    </template>
  </b-modal>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import SupportedMetrics from '@/js/healthConfig';
import HealthMetricConfig from '@/pages/Health/HealthMetricConfig.vue';

export default {
  name: 'HealthSettingsModal',
  components: {
    HealthMetricConfig,
  },
  data() {
    return {
      timeOptions: [{ text: 'last 7 days', value: 7 }, { text: 'last 30 days', value: 30 }],

    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', {
      metricComputeConfigs: 'getHealthConfigForBot',
      healthTaskConfigLoaded: 'getHealthTaskConfigLoaded',
      metricIsCurrentlyDisabled: 'metricIsCurrentlyDisabled',
    }),
    ...mapGetters('healthSuggestions', [
      'isFetchingFlowPotentials',
      'isFetchingSimpleNodeFallback',
    ]),
    ...mapState('healthSuggestions', ['improvementsTimeDelta']),
    loadingSuggestions() {
      return this.isFetchingFlowPotentials
       || this.isFetchingSimpleNodeFallback;
    },
    improvementsPeriod: {
      get() {
        return this.improvementsTimeDelta;
      },
      set(value) {
        this.needsRefresh = true;
        this.setImprovementsTimeDelta(value);
      },
    },
    metricsWithCustomizationOptions() {
      return Object.values(SupportedMetrics).filter((x) => x.allowsForComputeCustomization);
    },

    showCompleted: {
      get() {
        return this.$store.state.health.showCompleted;
      },
      set(value) {
        this.$store.commit('health/setShowCompleted', value);
      },
    },
    disabledCards() {
      if (!this.healthTaskConfigLoaded(this.getBotId)) {
        return [];
      }
      const unsorted = Object.values(SupportedMetrics)
        .filter((x) => this.metricIsCurrentlyDisabled(this.getBotId, x.identifier));
      return unsorted.sort(this.cardSortFunction).map((x) => x);
    },
  },
  methods: {
    ...mapActions('health', ['enableMetric']),
    ...mapMutations('healthSuggestions', ['setImprovementsTimeDelta']),
    cardSortFunction(a, b) {
      return a.displayOrder - b.displayOrder;
    },
    computeConfigForMetric(metricIdentifier) {
      const metricComputeConfig = this.metricComputeConfigs[metricIdentifier];
      if (metricComputeConfig === undefined) {
        return null;
      }
      return metricComputeConfig;
    },
    displayNameForMetric(metricIdentifier) {
      return SupportedMetrics[metricIdentifier].displayName;
    },
    configForMetricUpdated() {
      this.$emit('configForMetricUpdated');
    },
  },
};
</script>
