<template>
  <span
    v-if="tooltipsOn"
    v-b-tooltip.hover.noninteractive.viewport
    :style="spanStyle"
    :title="value"
  >
    <font-awesome-icon
      :class="iconClass"
      icon="question-circle"
      size="sm"
    />
  </span>
</template>

<script>
export default {
  name: 'TooltippedText',
  props: {
    value: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    novariant: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipsOn() {
      return this.$store.state.userSettings.botStudioSettings.show_tooltips;
    },
    spanStyle() {
      return this.right ? 'float: right' : '';
    },
    iconClass() {
      let c = '';
      if (this.right) {
        c += 'ml-2 ';
      }
      if (!this.novariant) {
        c += 'text-muted ';
      }
      return c;
    },
  },
};
</script>

<style scoped>
</style>
