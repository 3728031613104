<template>
  <b-form-group
    :id="`confirmQueryGroup${component_id}`"
    label-class="font-weight-bold d-flex"
    :label-for="`confirmQuery${component_id}`"
    description="Use {description} to get the description of the target node"
  >
    <template #label>
      Single choice message
      <voicebot-audio
        v-if="voicebotPlatformSelected"
        v-b-tooltip.noninteractive
        :text="confirmQuery || baseConfirmQuery"
        small
        title="Play this message"
        class="border-0 bg-transparent shadow-none ml-1"
      />
    </template>
    <b-form-textarea
      :id="`confirmQuery${component_id}`"
      :value="confirmQuery"
      type="text"
      :placeholder="baseConfirmQuery"
      @focus="autoFill"
      @update="confirmQuery => setConfirmQuery({ confirmQuery })"
    />
  </b-form-group>
</template>

<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';

import { applyThisArgs, addThisArgs } from '@/js/storeHelpers';
import VoicebotAudio from '@/components/VoicebotAudio.vue';

export default {
  name: 'EditSingleChoiceMessage',
  components: { VoicebotAudio },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      confirmQuery: 'getConfirmQuery',
    }), 'nodeId'),
    ...mapState('botManipulation/activeBot/config', ['multipleChoice']),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    component_id() {
      return this._uid;
    },
    baseConfirmQuery() {
      return this.multipleChoice.confirmQuery;
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setConfirmQuery',
    ]), { id: 'nodeId' }),
    autoFill() {
      if (this.confirmQuery === null) {
        this.setConfirmQuery({ confirmQuery: this.baseConfirmQuery });
      }
    },
  },
};
</script>
