<template>
  <main
    role="main"
  >
    <b-card
      title="Insights"
      class="r-75"
      body-class="p-3"
    >
      Here you investigate how the conversation flows to and from nodes.
      <hr class="mb-1">
      <date-time-lang-picker
        date
        time
        shortcuts
        :getter="insightsFilter"
        :setter="updateInsightsFilter"
      />
      <b-row
        class="mt-3"
      >
        <b-col
          cols="6"
        >
          <b-form-group>
            <label class="h6 font-weight-normal">Center-node
              <tooltipped-text
                value="Central point of the insights diagram"
              /></label>
            <completion-input
              :placeholder="chosenCenterNode"
              value=""
              :completions="nodeNamesToSuggest"
              @input="choseNodeName($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="mt-3"
      >
        <b-col>
          <b-overlay
            :show="loadingOrigins"
            :opacity="0.9"
          >
            <template #overlay>
              <div class="text-center">
                <p class="text-secondary">
                  <b-spinner
                    small
                    class="mr-2"
                    style="margin-bottom:2px;"
                  />Loading data sources...
                </p>
              </div>
            </template>
            <b-btn
              variant="primary"
              :disabled="chosenCenterNodeId === null"
              @click="fetchData"
            >
              Fetch Statistics
            </b-btn>
            <b-btn
              class="ml-2"
              variant="primary"
              :disabled="!diagramWasManuallyLayout"
              @click="layoutDiagram"
            >
              Reset diagram
            </b-btn>
            <div
              class="d-inline"
            >
              <b-dropdown
                id="dropdown-1"
                class="ml-2"
                text="Choose data sources"
              >
                <b-dropdown-form>
                  <b-form-checkbox
                    v-for="source in availableDataOrigins"
                    :key="source.rawValue"
                    v-model="selectedOrigins"
                    :value="source"
                    class="text-nowrap align-middle"
                  >
                    {{ source.displayName }}
                  </b-form-checkbox>
                </b-dropdown-form>
              </b-dropdown>
              <span
                v-if="selectedOrigins.length === 0"
                class="ml-2 text-warning"
              >
                <font-awesome-icon
                  icon="exclamation-circle"
                />
                Choose sources
              </span>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row
        v-if="noDataToBeShown"
      >
        <b-col
          cols="auto"
        >
          <p>
            There is no data to show for node {{ chosenCenterNode }}. <br>
            If this is unexpected, make sure the selected date-range filter is not too narrow.
          </p>
        </b-col>
      </b-row>
      <div
        v-if="fetching"
        class="text-center"
      >
        <b-spinner
          style="width: 3rem; height: 3rem;"
          class="m-3"
          variant="primary"
        />
      </div>
      <div
        id="sankeyDiagram"
      />
    </b-card>
  </main>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters, mapActions } from 'vuex';
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import * as d3 from 'd3';
import { nodeTypes, chartColors } from '@/js/constants';
import endpoints from '@/js/urls';
import { truncateString, filterDateBuilder } from '@/js/utils';
import DateTimeLangPicker from '@/components/DateTimeLangPicker.vue';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'InsightsPage',
  components: {
    CompletionInput,
    DateTimeLangPicker,
    TooltippedText,
  },
  data() {
    return {
      chosenCenterNode: null,
      chosenCenterNodeId: null,
      insightsFilter: {
        startDate: new Date(Date.now() - (7 * 60 * 60 * 24 * 1000)),
        endDate: new Date(Date.now() + (1 * 60 * 60 * 24 * 1000)),
        startTime: 0,
        endTime: 24,
      },
      chartData: null,
      diagramWasManuallyLayout: false,
      nameForUnaccountedChatsNode: '<unaccountedFor>',
      fetching: false,
      sankeyWidth: null,
      selectedOrigins: [],
      loadingOrigins: false,
    };
  },
  computed: {
    ...mapState('auth', ['jwt']),
    ...mapState('botManipulation/activeBot', {
      botId: 'id',
    }),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'allNodesAsList',
      'nodeByName',
    ]),
    ...mapGetters('chatlogs', [
      'availableDataOrigins',
    ]),
    nodeNamesToSuggest() {
      const nodes = this.allNodesAsList.filter(
        (element) => (element.options.nodeType === nodeTypes.SIMPLE
            || element.options.nodeType === nodeTypes.MULTIPLE_CHOICE),
      );
      return nodes.map((element) => element.name);
    },
    /**
     * Returns true if there is no data to be shown for selected centernode.
     */
    noDataToBeShown() {
      return this.chartData !== null
        && (this.chartData.links.length === 0 || this.chartData.nodes.length === 0);
    },
  },
  async mounted() {
    await this.fetchDataOrigins();
    await this.fetchLanguages();
    this.selectedOrigins = this.availableDataOrigins;
    this.loadingOrigins = false;
    this.sankeyWidth = document.getElementById('sankeyDiagram').offsetWidth - 20;
    // Set out using Greet as center-node
    this.chosenCenterNodeId = 'greet';
    this.chosenCenterNode = this.nodeById('greet').name;
    await this.fetchData();

    this.$root.$on('resizeDiagram', () => {
      this.$nextTick(() => this.resizeDiagram());
    });
  },
  created() {
    window.addEventListener('resize', this.resizeDiagram);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeDiagram);
  },
  methods: {
    ...mapActions('chatlogs', [
      'fetchDataOrigins',
      'fetchLanguages',
    ]),
    resizeDiagram() {
      this.clearDiagram();
      this.sankeyWidth = null;
      this.sankeyWidth = document.getElementById('sankeyDiagram').offsetWidth - 20;

      this.layoutDiagram();
    },
    choseNodeName(nodeName) {
      this.chosenCenterNode = nodeName;
      this.chosenCenterNodeId = this.nodeByName(nodeName).id;
    },
    /**
     * Fetches data using the specified date-range and the chosen center-node-id
     */
    async fetchData() {
      this.fetching = true;
      const filterStartDate = filterDateBuilder(
        this.insightsFilter.startDate,
        this.insightsFilter.startTime,
      );
      const filterEndDate = filterDateBuilder(
        this.insightsFilter.endDate,
        this.insightsFilter.endTime,
      );
      const selectedDataOrigins = this.selectedOrigins.map((x) => x.rawValue);
      const config = {
        params: {
          bot_id: this.botId,
          from_date: filterStartDate.toISOString(),
          end_date: filterEndDate.toISOString(),
          center_node_id: this.chosenCenterNodeId,
          selected_data_origins: selectedDataOrigins,
        },
        headers: { Authorization: `JWT ${this.jwt}` },
      };
      const result = await axios.get(endpoints.insights, config);

      this.fetching = false;
      this.chartData = result.data;

      this.updateDiagram();
    },
    preprocessChartData() {
      /**
       * Centernode looping on itself are handled as follows:
       * 1. Two artificial nodes are introduced, to make the centernode appear also on the left and
       right hand side of the diagram.
       * 2. Two links are inserted, one pointing to centernode and one leaving centernode, each with
       the same value.
       */
      const centerNodeIndex = this.chartData.index_for_center_node;
      const loopEntryIndex = this.chartData.links
        .findIndex((x) => x.source === centerNodeIndex && x.target === centerNodeIndex);

      let loopsCount = 0;
      if (loopEntryIndex > -1) {
        const loopEntry = this.chartData.links[loopEntryIndex];
        loopsCount = loopEntry.value;

        // Insert node that points to centernode
        const indexForFakeNodeIn = this.chartData.nodes.length;
        const fakeNodeIn = {
          node: indexForFakeNodeIn,
          name: this.chosenCenterNodeId,
        };
        this.chartData.nodes.push(fakeNodeIn);
        // Insert corresponding link
        this.chartData.links.push({
          source: indexForFakeNodeIn,
          target: centerNodeIndex,
          value: loopsCount,
        });

        // Insert node that points to centernode
        const indexForFakeNodeOut = indexForFakeNodeIn + 1;
        const fakeNodeOut = {
          node: indexForFakeNodeOut,
          name: this.chosenCenterNodeId,
        };
        this.chartData.nodes.push(fakeNodeOut);
        // Insert corresponding link
        this.chartData.links.push({
          source: centerNodeIndex,
          target: indexForFakeNodeOut,
          value: loopsCount,
        });

        this.chartData.links.splice(loopEntryIndex, 1);
      }

      // Handle loops among parents and children of centernode, since diagram cannot handle cycles
      // Example: InactivityNode -> centernode -> InactivityNode, in general: (A) -> (B) -> (A)
      // We handle this by keeping the (A -> B) entry unmodified, but then
      // (1) insert a new node, (A*) that proxies A, and then
      // (2) updates the (B -> A) link entry, so that it becomes (B->A*)
      // InactivityNode to appear on right hand side of diagram
      const intersection = this.chartData.distinct_nodes_flowing_into_center_node
        // eslint-disable-next-line max-len
        .filter((x) => x !== this.chosenCenterNodeId
          && this.chartData.distinct_target_nodes_from_center_node.includes(x));
      intersection.forEach((bothParentAndChildNodeId) => {
        const nodeIndex = this.chartData.nodes
          .findIndex((x) => x.name === bothParentAndChildNodeId);

        // Strategy, part I: Insert proxy node (A*)
        const nextIndex = this.chartData.nodes.length;
        const proxyNode = {
          node: nextIndex,
          name: bothParentAndChildNodeId,
        };
        this.chartData.nodes.push(proxyNode);
        // Strategy, part II: Update link (B->A): (B->A*)

        const entryToModifyIndex = this.chartData.links
        // eslint-disable-next-line max-len
          .findIndex((entry) => entry.source === centerNodeIndex && entry.target === nodeIndex);
        this.chartData.links[entryToModifyIndex].target = nextIndex;
      });

      // Naturally there will always be more chats leaving the init node than leaving it, since no
      // chats ever enter the init node. Hence we only deal with any discrepancies when not dealing
      // with init node as centernode
      if (this.chosenCenterNodeId !== 'init') {
        const inoutDiscrepancy = this.chartData.number_chats_entering_centernode
        - this.chartData.number_chats_leaving_centernode;
        if (inoutDiscrepancy !== 0) {
          const nextAvailableIndex = this.chartData.nodes.length;
          this.chartData.nodes.push({
            node: nextAvailableIndex,
            name: this.nameForUnaccountedChatsNode,
          });

          if (inoutDiscrepancy > 0) {
          // There are more chats flowing into centernode than leaving it
            this.chartData.links.push({
              source: this.chartData.index_for_center_node,
              target: nextAvailableIndex,
              value: inoutDiscrepancy,
            });
          } else if (inoutDiscrepancy < 0) {
          // There are more chats leaving centernode than entering it
            this.chartData.links.push({
              source: nextAvailableIndex,
              target: this.chartData.index_for_center_node,
              value: -inoutDiscrepancy,
            });
          }
        }
      }
    },
    nameForNodeId(nodeId, trunc = true) {
      if (nodeId === this.nameForUnaccountedChatsNode) {
        return 'Unaccounted for';
      }
      const pureNodeId = this.removeNodeSuffixes(nodeId);
      const node = this.nodeById(pureNodeId);
      if (node === undefined) {
        // Known cases where node is undefined: Node has been deleted after chat was recorded
        if (trunc) {
          return truncateString(`<unknown node: ${pureNodeId}>`, 23);
        }
        return `<unknown node: ${pureNodeId}>`;
      }

      let nodeName = node.name;
      // Developers note: Historical chats were recorded with -outer_match and -login_subflow suffix
      // In order to not break Insights for such chats we also match on such (now deprecated)
      // suffixes
      if (nodeId.endsWith('-auth_match') || nodeId.endsWith('-outer_match')) {
        // We're entering authentication subflow
        nodeName = `[AUTH] ${node.name}`;
      }

      if (nodeId.endsWith('-auth_sf') || nodeId.endsWith('-login_subflow')) {
        // We're exiting authentication subflow
        nodeName = `[AUTH] ${node.name}`;
      }

      if (trunc) {
        return truncateString(nodeName, 23);
      }
      return nodeName;
    },
    hoverTitleForNode(node) {
      const maxChatsLeavingOrEnteringCenter = Math.max(
        this.chartData.number_chats_entering_centernode,
        this.chartData.number_chats_leaving_centernode,
      );
      const percentage = Number(((node.value / maxChatsLeavingOrEnteringCenter) * 100).toFixed(1));
      const nodeName = this.nameForNodeId(node.name, false);
      return `${nodeName}: ${percentage}% (${node.value})`;
    },
    hoverTitleForLink(link) {
      // For each link, we'll compute the corresponding percentage, like so:
      // (link-weight)/(The total number of chats)
      // "The total number of chats" is the greatest number of: the number of chats flowing into
      // center-node or the number of chats leaving the centernode.
      const maxChatsLeavingOrEnteringCenter = Math.max(
        this.chartData.number_chats_entering_centernode,
        this.chartData.number_chats_leaving_centernode,
      );
      const percentage = Number(((link.value / maxChatsLeavingOrEnteringCenter) * 100).toFixed(1));
      return `${percentage}% (${link.value})`;
    },
    /**
     * Clears the SVG diagram, in the div id'ed sankeyDiagram, and returns (as a convenience)
     * the div-element.
     */
    clearDiagram() {
      return d3.select('#sankeyDiagram').html('');
    },
    updateDiagram() {
      if (this.noDataToBeShown) {
        // Clear diagram
        this.clearDiagram();
        return;
      }

      this.preprocessChartData();
      this.layoutDiagram();
    },
    removeNodeSuffixes(nodeId) {
      const subflowOuterMatchSuffixes = ['-auth_match', '-outer_match'];
      const subflowEntryMatch = subflowOuterMatchSuffixes.find((suffix) => nodeId.includes(suffix));
      if (subflowEntryMatch !== undefined) {
        // Handle subflows entry
        return nodeId.slice(0, -subflowEntryMatch.length);
      }
      const authSubflowExitSuffixes = ['-auth_sf', '-login_subflow'];
      const authSubflowExitMatch = authSubflowExitSuffixes
        .find((suffix) => nodeId.includes(suffix));
      if (authSubflowExitMatch !== undefined) {
        // Handle subflow exit node
        return nodeId.slice(0, -authSubflowExitMatch.length);
      }
      return nodeId;
    },
    /**
     * I created a separate layoutDiagram function, because if the logic in it was otherwise
     * included in the updateDiagram function, then preprocessChartData would be called too, and
     * preprocessChartData manipulates the chartdata, for instance adding unaccounted for nodes.
     * This had the unfortunate consequence that when each tiome a user reset the diagram, an
     * additional Unaccounted for node would be inserted into diagram
     */
    layoutDiagram() {
      const margins = {
        top: 10, right: 20, bottom: 10, left: 10,
      };

      const width = this.sankeyWidth - margins.left - margins.right;

      // Diagram height should be computed dynamically based on the number of nodes on the left
      // and/or right side of the diagram (1st and third "column")
      let baseHeight = 480;
      const maximumColumnNodesCount = Math
        .max(this.chartData.distinct_nodes_flowing_into_center_node.length,
          this.chartData.distinct_target_nodes_from_center_node.length);
      if (maximumColumnNodesCount > 4) {
        baseHeight += (maximumColumnNodesCount - 4) * 40;
      }

      const height = baseHeight - margins.top - margins.bottom;

      // Any subsequent drawing on SVG element should be offset by (margins.left, margint.top)
      // from upper-lefthand corner
      const translateFunction = `translate(${margins.left + 10},${margins.top})`;

      // Clear svg (needed for subsequent svg updates)
      let svg = this.clearDiagram();
      // Construct svg
      svg = svg.append('svg')
        .attr('width', width + margins.left + margins.right)
        .attr('height', height + margins.top + margins.bottom)
        .append('g')
        .attr('transform', translateFunction);

      const sankeyGenerator = sankey()
        .size([width, height]);

      sankeyGenerator
        .nodes(this.chartData.nodes)
        .links(this.chartData.links)
        .nodeSort((x, y) => y.value - x.value);
      const graph = sankeyGenerator.update(sankeyGenerator());

      // Bind this to self, since this will be overrridden inside callback functions
      const self = this;

      function customLinkWidthFunction(link) {
        return link.width;
      }

      /**
       * Intended shortcut: Allow the user to click the already chosen centernode, if he/she wishes
       * to either (or both): (A) refresh the data, (B) relayout the diagram
       */
      function nodeDoubleClicked(clickEvent) {
        if (clickEvent.defaultPrevented) {
          // The click was a drag-event, you cannot interpret this as a doubleclick: stop here!
          return;
        }
        const clickedNodeId = d3.select(this).datum().name;
        if (clickedNodeId === 'final') {
          /**
           * Disallow making Final Activities centernode (this was agreed on with Anders)
          */
          return;
        }

        // Choose the double-clicked node as new center node
        self.chosenCenterNodeId = clickedNodeId;
        self.chosenCenterNode = self.nameForNodeId(clickedNodeId);

        // Fetch data
        self.clearDiagram();
        self.fetchData();
      }

      function linkColor() {
        return chartColors.lightBlue;
      }

      function nodeColor(node) {
        if (node.name === self.chosenCenterNodeId) {
          return chartColors.deepDarkBlue;
        }
        return chartColors.blue;
      }

      // Add links
      const link = svg
        .append('g')
        .selectAll('link')
        .data(graph.links)
        .enter()
        .append('path')
        .attr('class', 'link')
        .attr('d', sankeyLinkHorizontal())
        .attr('stroke', linkColor)
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.5)
        .attr('stroke-width', customLinkWidthFunction);

      // Insert hovers onto links
      link
        .append('title')
        .text(this.hoverTitleForLink);

      // Add nodes
      function customTranslateFunction(input) {
        return `translate(${input.x0}, ${input.y0})`;
      }

      function dragHandler() {
        function dragmove(event, nodeBeingMoved) {
          // The if-else if-else is about preventing user in moving nodes outside view
          // The coordinate system is inverted, such that y grows towards the bottom of your screen.
          self.diagramWasManuallyLayout = true;
          const nodeHeight = nodeBeingMoved.y1 - nodeBeingMoved.y0;
          let newY0Value = 0;
          if (nodeBeingMoved.y0 + nodeHeight + event.dy >= height) {
          // Disallow user to move node outside svg (downwards)
            newY0Value = height - nodeHeight;
          } else if (nodeBeingMoved.y0 + event.dy < 0) {
          // Disallow user to move node outside svg (upwards)
            newY0Value = 0;
          } else {
            newY0Value = nodeBeingMoved.y0 + event.dy;
          }
          // eslint-disable-next-line no-param-reassign
          nodeBeingMoved.y0 = newY0Value;
          // eslint-disable-next-line no-param-reassign
          nodeBeingMoved.y1 = newY0Value + nodeHeight;

          const yTranslate = nodeBeingMoved.y0;
          const nodeTranslateFunction = `translate(${nodeBeingMoved.x0},${yTranslate})`;
          d3.select(this).attr('transform', nodeTranslateFunction);

          sankeyGenerator.update(graph);
          link.attr('d', sankeyLinkHorizontal());
        }

        return d3.drag().on('drag', dragmove);
      }
      const node = svg.append('g')
        .selectAll('.node') // Select all elements with class: node
        .data(graph.nodes)
        .enter()
        .append('g')
        .attr('class', 'node') // Set class: node
        .attr('transform', customTranslateFunction)
        .call(dragHandler())
        .on('dblclick', nodeDoubleClicked);

      function customNodeHeightFunction(element) {
        return element.y1 - element.y0;
      }

      node
        .append('rect')
        .attr('height', customNodeHeightFunction)
        .attr('width', sankeyGenerator.nodeWidth())
        .attr('fill', nodeColor)
        .attr('fill-opacity', 0.9)
        .attr('stroke', nodeColor)
        .attr('stroke-width', '1px')
      // Add hover text
        .append('title')
        .text(this.hoverTitleForNode);

      function hoverTextForNodeName(inputNode) {
        // A hover text is needed in particular for nodes that are visually so small that the node
        // itself cannot be hovered
        const maxChatsLeavingOrEnteringCenter = Math.max(
          self.chartData.number_chats_entering_centernode,
          self.chartData.number_chats_leaving_centernode,
        );
        const percentage = Number(
          ((inputNode.value / maxChatsLeavingOrEnteringCenter) * 100).toFixed(1),
        );
        const nodeName = self.nameForNodeId(inputNode.name, false);
        return `${nodeName}: ${percentage}% (${inputNode.value})`;
      }

      // add in the title for the nodes
      node
        .append('text')
        // x label is relative to the node's x0 coordinate
        .attr('x', (d) => (d.x0 < width / 2 ? d.x1 - d.x0 + 6 : -6))
        .attr('y', (d) => (d.y1 - d.y0) / 2)
        .attr('dy', '.35em')
        .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'end'))
        .attr('font-size', 12)
        // .attr('transform', null)
        // .attr('fill', 'black')
        .text((x) => this.nameForNodeId(x.name))
        .append('title')
        .text(hoverTextForNodeName);

      this.diagramWasManuallyLayout = false;
    },
    updateInsightsFilter({ key, newValue }) {
      this.insightsFilter[key] = newValue;
    },
  },
};
</script>
