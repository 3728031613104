import axios from 'axios';
import Vue from 'vue';
import { ComputationTaskStatus } from '@/js/constants';
import { metricSortFunction } from '@/js/utils';
import endpoints from '@/js/urls';
import SupportedMetrics from '@/js/healthConfig';

/**
 * This function returns the blueprint-object to be used _per_ bot
 */
function getInitialStateForBot() {
  return {
    visibility: {
      // 'USES_FRESH_CLASSIFIERS': 'enabled',
      // 'NUMBER_OF_DRAFT_REPLIES': 'disabled'
      // Absence of keys implies corresponding metric is enabled
    },
    custom_compute_config: {
      // <metric-identifier>: <custom, metric-specific config object>
    },
    metricResponses: {
      // <metric-identifier>: {
      //    computed_timestamp: <timestamp>
      //    progress: <SUCCEEDED|FAILED|IN_PROGRESS|QUEUED>
      //    'result': {
      //      'metric_applicable': <null|true|false>,
      //      'metric_satisfied': <null|true|false>,
      //      'details': <custom, metric-specific details object>
      //   }
      // }
    },
    // Used internally within store, you should not need to expose this state.
    ongoingPollTasks: {
      // <metric-identifier>: key absent altogether or null
      // (if no ongoing polling for metric-identifer), otherwise ID returned from setTimeout
    },

  };
}

const initialState = {
  showCompleted: false,
  aiThresholds: { },
  // <botId1>: {
  //   visibility: <see example above>,
  //   custom_compute_config: <see example above>,
  //   metricResponses: <see example above>,
  // },
  // botId2: {
  //   visibility: <see example above>,
  //   custom_compute_config: <see example above>,
  //   metricResponses: <see example above>,
  // },
};

const healthGetters = {
  getTaskProgress: (state) => (botId, metricIdentifier) => {
    const botState = state[botId];
    if (botState === undefined) {
      return null;
    }
    const metricState = state[botId];
    if (metricState === undefined) {
      return null;
    }
    const metricResponse = metricState.metricResponses[metricIdentifier];
    if (metricResponse === null) {
      return null;
    }
    if (metricResponse === undefined || metricResponse.progress === undefined) {
      return null;
    }

    return metricResponse.progress;
  },
  getHealthConfigForBot: (state) => (botId) => state[botId],
  getHealthTaskConfigLoaded: (state) => (botId) => {
    if (state[botId] === undefined || state[botId] === null) {
      return false;
    }
    return state[botId].visibility !== undefined && state[botId].visibility !== null;
  },
  /**
   * Determines if the metric is currently disabled, as determined by health config
   */
  metricIsCurrentlyDisabled: (state, getters) => (botId, metricIdentifier) => {
    const config = getters.getHealthConfigForBot(botId);
    return (config.visibility[metricIdentifier] !== undefined
        && config.visibility[metricIdentifier] === 'disabled');
  },
  /**
   * Returns the custom config object for the metric, if none is present returns null.
   */
  getConfigForMetric: (state) => (botId, metricIdentifier) => {
    if (state[botId].custom_compute_config[metricIdentifier] === undefined) {
      return null;
    }
    return state[botId].custom_compute_config[metricIdentifier];
  },
  /**
   * Returns the result (if any) for the specified metric. This may be null if either computation
   * has not been queued, has failed, or is in progress.
   * The metric-result is only a part of the full metric-response and hence does not contain
   * meta-information about the computation itself, for that you should inspect the full metric
   * response instead.
   */
  getMetricResult: (state, getters) => (botId, metricIdentifier) => {
    // Piggyback the getter we already have in place
    const metricResponse = getters.getMetricResponse(botId, metricIdentifier);
    if (metricResponse === null || metricResponse.state === ComputationTaskStatus.IN_PROGRESS) {
      return null;
    }
    return metricResponse.result;
  },
  getMetricResponse: (state) => (botId, metricIdentifier) => {
    const botState = state[botId];
    if (botState === undefined || botState === null) {
      return null;
    }
    if (botState.metricResponses === undefined) {
      return null;
    }
    if (botState.metricResponses[metricIdentifier] !== undefined
      && botState.metricResponses[metricIdentifier] !== null) {
      return botState.metricResponses[metricIdentifier];
    }
    return null;
  },
  getOngoingPollTaskId: (state) => (botId, metricIdentifier) => {
    const botState = state[botId];
    if (botState === undefined || botState === null) {
      return null;
    }
    if (botState.ongoingPollTasks === undefined) {
      return null;
    }
    if (botState.ongoingPollTasks[metricIdentifier] !== null
      || botState.ongoingPollTasks[metricIdentifier] !== undefined) {
      return botState.ongoingPollTasks[metricIdentifier];
    }
    return null;
  },
  metricsInProgress: (state, getters) => (botId) => {
    if (!getters.getHealthTaskConfigLoaded(botId)) {
      return 0;
    }
    const cardsUnsorted = Object.values(SupportedMetrics).filter((x) => {
      if (getters.metricIsCurrentlyDisabled(botId, x.identifier)) {
        return false;
      }
      const progressForMetric = getters.getTaskProgress(botId, x.identifier);
      if (progressForMetric === null) {
        return true;
      }
      if (progressForMetric === ComputationTaskStatus.IN_PROGRESS
      || progressForMetric === ComputationTaskStatus.QUEUED) {
        return true;
      }
      return false;
    });
    return cardsUnsorted.length;
  },
  inProgressFailedOrNotSatisfiedCards: (state, getters) => (botId, returnObjects = false) => {
    if (!getters.getHealthTaskConfigLoaded(botId)) {
      return [];
    }
    const cardsUnsorted = Object.values(SupportedMetrics).filter((x) => {
      if (getters.metricIsCurrentlyDisabled(botId, x.identifier)) {
        return false;
      }
      const progressForMetric = getters.getTaskProgress(botId, x.identifier);
      if (progressForMetric === null) {
        // Card has not even been queued yet.
        return true;
      }
      if (progressForMetric === ComputationTaskStatus.IN_PROGRESS
      || progressForMetric === ComputationTaskStatus.QUEUED
      || progressForMetric === ComputationTaskStatus.FAILED) {
        return true;
      }

      // Computation may have succeeded, although metric was not satisfied
      const metricResult = getters.getMetricResult(botId, x.identifier);
      if (metricResult === null) {
        return true;
      }
      // Include card if metric was satisfied
      return metricResult?.metric_satisfied === false;
    });
    if (returnObjects) {
      return cardsUnsorted.sort(metricSortFunction).map((y) => y);
    }
    return cardsUnsorted.sort(metricSortFunction).map((y) => y.identifier);
  },
};

const healthMutations = {
  ensureBaseState(state, botId) {
    if (state[botId] === undefined) {
      Vue.set(state, botId, getInitialStateForBot());
    }
  },
  setShowCompleted(state, value) {
    state.showCompleted = value;
  },
  resetMetricState(state, { botId, metricIdentifier }) {
    Vue.set(state[botId].metricResponses, metricIdentifier, null);
  },
  setHealthTaskConfig(state, { config, botId }) {
    Vue.set(state[botId], 'custom_compute_config', config.custom_compute_config);
    Vue.set(state[botId], 'visibility', config.visibility);
    if (config.ai_thresholds) {
      state.aiThresholds = config.ai_thresholds;
    }
  },
  updateComputation(state, { response }) {
    const botId = response.bot_id;
    const metricIdentifier = response.metric_identifier;
    Vue.set(state[botId].metricResponses, metricIdentifier, response);
  },
  setOngoingPollTaskId(state, { botId, metricIdentifier, taskId }) {
    const newStateOngoingPollTasks = { ...state[botId].ongoingPollTasks };
    newStateOngoingPollTasks[metricIdentifier] = taskId;
    Vue.set(state[botId], 'ongoingPollTasks', newStateOngoingPollTasks);
  },
  setMetricData(state, { botId, field, data }) {
    Vue.set(state[botId].metricResponses, field, data);
  },
  setAiThresholds(state, { key, value }) {
    Vue.set(state.aiThresholds, key, value);
  },
  copyActivityThresholds(state, { thresholds }) {
    state.aiThresholds = thresholds;
  },
  resetAiThresholds(state) {
    state.aiThresholds = {};
  },
};

const healthActions = {
  async fetchHealthConfig({ rootState, commit }) {
    commit('ensureBaseState', rootState.botManipulation.activeBotId);
    const response = await axios.get(endpoints.healthTaskConfig, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: {
        bot_id: rootState.botManipulation.activeBotId,
      },
    });
    commit('setHealthTaskConfig', {
      config: response.data.config,
      botId: rootState.botManipulation.activeBotId,
    });
  },
  async recomputeAllMetrics({
    rootState, commit, dispatch, getters,
  }, metrics) {
    const botId = rootState.botManipulation.activeBotId;
    let supportedMetrics = [];
    if (!metrics?.length) {
      Object.values(SupportedMetrics).forEach((metric) => {
        if (!getters.metricIsCurrentlyDisabled(botId,
          metric.identifier)) {
          supportedMetrics.push(metric.identifier);
        }
      });
    } else {
      supportedMetrics = metrics;
    }
    supportedMetrics.forEach((metric) => {
      commit('setMetricData', {
        botId,
        field: metric,
        data: {
          state: ComputationTaskStatus.IN_PROGRESS,
        },
      });
    });
    try {
      const { data } = await axios.post(
        endpoints.health,
        {
          bot_id: botId,
          metric_identifiers: supportedMetrics,
        },
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      );
      Object.entries(data.tasks).forEach(([k, v]) => {
        commit('task/addTask', {
          celeryId: v,
          callbackDone: (d) => {
            const result = {
              metric_identifier: k,
              bot_id: botId,
              progress: 'SUCCEEDED',
              result: d.result,
            };
            commit('updateComputation', { response: result });
          },
          callbackFailed: () => {
            dispatch('sidebar/showWarning', {
              title: 'An health metric error occurred',
              text: `Failed to compute health metric: ${k}`,
              variant: 'danger',
            }, { root: true });
          },
          callbackShouldAbort: () => botId !== rootState.botManipulation.activeBotId,
        }, { root: true });
      });
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to compute metric',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  /**
   * Utility method for disabling specified metric; it wraps and invokes updateMetricVisibility.
   */
  async disableMetric({ dispatch }, { metricIdentifier }) {
    await dispatch('updateMetricVisibility', {
      metricIdentifier,
      newVisibility: 'disabled',
    });
  },
  /**
   * Utility method for enabling specified metric; it wraps and invokes updateMetricVisibility.
   */
  async enableMetric({ dispatch }, { metricIdentifier }) {
    await dispatch('updateMetricVisibility', {
      metricIdentifier,
      newVisibility: 'enabled',
    });
  },
  async updateMetricVisibility({ rootState, dispatch }, { metricIdentifier, newVisibility }) {
    const updatePayload = {
      visibility_changes: {},
    };
    updatePayload.bot_id = rootState.botManipulation.activeBotId;
    updatePayload.visibility_changes[metricIdentifier] = newVisibility;

    // We intentionally omit the response payload (that contains the new config),
    // so that we need only maintain a single way for updating the config (done below)
    await axios.patch(endpoints.healthTaskConfig, updatePayload, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });

    // Fetch new config
    await dispatch('fetchHealthConfig');
  },
  /**
   * Update custom config for specified metric.
   * No validation of newConfig is done here - newConfig is uploaded as-is.
   * Callers should validate newConfig themselves before calling this method.
   */
  async updateComputeConfig({ rootState, dispatch }, { newConfig, metricIdentifier }) {
    // Upload new config
    const patchPayload = {
      bot_id: rootState.botManipulation.activeBotId,
      compute_config_changes: {},
    };
    patchPayload.compute_config_changes[metricIdentifier] = newConfig;
    await axios.patch(endpoints.healthTaskConfig, patchPayload, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    // Fetch new config
    await dispatch('fetchHealthConfig');

    // Schedule new computation since config has likely changed
    await dispatch('recomputeAllMetrics', [metricIdentifier]);
  },
  async saveAiThresholds({ state, rootState, dispatch }) {
    const patchPayload = {
      bot_id: rootState.botManipulation.activeBotId,
      ai_thresholds: state.aiThresholds,
    };
    await axios.patch(endpoints.healthTaskConfig, patchPayload, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    await dispatch('fetchHealthConfig');
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters: healthGetters,
  mutations: healthMutations,
  actions: healthActions,
};
