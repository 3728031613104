<template>
  <b-form-group :label="`${label}`" label-size="sm" class="mb-1">
    <b-input-group class="mb-2">
      <b-form-input v-model="sliderMin" :min="min" :max="max" size="sm" type="number" />
      <b-form-input v-model="sliderMax" :min="min" :max="max" size="sm" type="number" />
    </b-input-group>
  </b-form-group>
</template>
<script>
export default {
  name: 'MinMaxInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => ({
        max: 100,
        min: 0,
        step: 1,
      }),
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    max() {
      return this.config.max;
    },
    min() {
      return this.config.min;
    },
    step() {
      return this.config.step;
    },
    sliderMin: {
      get() {
        return parseInt(this.minValue, 10);
      },
      set(val) {
        const intVal = parseInt(val, 10);
        if (intVal > this.sliderMax) {
          this.$emit('input', { key: 'max', value: intVal });
        }
        this.$emit('input', { key: 'min', value: intVal });
      },
    },
    sliderMax: {
      get() {
        return parseInt(this.maxValue, 10);
      },
      set(val) {
        const intVal = parseInt(val, 10);
        if (intVal < this.sliderMin) {
          this.$emit('input', { key: 'min', value: intVal });
        }
        this.$emit('input', { key: 'max', value: intVal });
      },
    },
  },
};
</script>
<style scoped>

</style>
