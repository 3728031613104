<template>
  <main
    role="main"
  >
    <b-card
      title="Question & Answer"
      class="r-75"
      body-class="p-3"
    >
      <p>
        Each Q&A entry consists of 1 answer (the answer given by the bot) and several example
        questions. The bot generalizes these questions and provides the specified answer
        when the user asks similar questions.
      </p>
    </b-card>

    <b-card
      v-if="!hasNeutralExamples"
      class="r-75 mt-3"
      body-class="p-3"
      header="Warning"
      header-class="font-weight-bold r-75"
      header-bg-variant="warning"
      header-text-variant="white"
      border-variant="warning"
    >
      <p class="font-weight-bold">
        You are about to add Q&A entries without neutral examples. This may cause the
        bot to interpret questions wrongly.
      </p>
      <hr>
      <p>
        You can add neutral examples in the Classifiers section.
      </p>
    </b-card>

    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      no-body
    >
      <b-list-group class="r-75">
        <q-a-node
          v-for="id in qaNodeIds"
          :id="id"
          :key="id"
        />
      </b-list-group>

      <b-row
        align-h="center"
        class="my-2"
      >
        <b-btn
          variant="primary"
          @click="addQA()"
        >
          Add Q&A entry
        </b-btn>
      </b-row>
    </b-card>
  </main>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import QANode from '@/pages/QA/QANode.vue';
import { guidGenerator } from '@/js/utils';
import { nodeTypes } from '@/js/constants';

export default {
  name: 'QAPage',
  components: { QANode },
  mixins: [validationMixin],

  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'hasNeutralExamples',
      'nodeById',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapState('botManipulation/activeBot', [
      'nodeIds',
    ]),
    customNode() {
      return this.$store.state.botManipulation.customNode;
    },
    qaNodeIds() {
      return this.nodeIds.filter((id) => this.nodeById(id).options.nodeType === nodeTypes.QA);
    },
  },
  methods: {
    addQA() {
      const nodeId = guidGenerator();
      const newNode = JSON.parse(JSON.stringify(this.customNode));
      newNode.options.global = true;
      newNode.options.nodeType = nodeTypes.QA;
      // Hard-code this until some solution has been decided...
      newNode.options.smallTalkThreshold = 0.9;
      newNode.id = nodeId;
      newNode.name = '';
      this.$store.dispatch('botManipulation/addNode', newNode);
    },
  },
};

</script>

<style scoped>
</style>
