<template>
  <b-row class="justify-content-center pt-5 mx-0 r-75">
    <b-col cols="4">
      <b-card
        align="center"
        title="You are now signed out"
      >
        <router-link :to="{ name: 'login' }">
          Sign in again
        </router-link>
        <template #footer>
          <div>
            <PuzzelLogoDark class="w-25" />
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import PuzzelLogoDark from 'supwiz/components/template/header/PuzzelLogoDark.vue';

export default {
  name: 'LogoutPage',
  components: { PuzzelLogoDark },
  mounted() {
    this.$store.dispatch('auth/logout');
  },
};
</script>
