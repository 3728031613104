<template>
  <main>
    <b-card
      title="Bot management"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        On this page you can create and manage your bots.
      </p>
      <p>
        Select or create a bot below to get started.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      title="Bots"
    >
      <div v-if="botsList.length === 0">
        There are no bots in the database. Create one!
      </div>

      <b-table
        v-else
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :items="botsList"
        sort-by="name"
        :sort-compare="sortFavoriteCompare"
        :fields="fields"
        hover
        sort-icon-left
        @row-clicked="onRowClicked"
      >
        <template #cell(permitted)="row">
          <font-awesome-icon
            v-if="row.item.permitted"
            icon="check"
          />
          <font-awesome-icon
            v-else
            icon="lock"
          />
        </template>
        <template #cell(actions)="{ item }">
          <b-btn-group>
            <b-button
              size="sm"
              :variant="item.favorite ? 'primary' : 'outline-primary'"
              :title="item.favorite ? 'Remove Favorite' : 'Add Favorite'"
              :disabled="!item.permitted"
              @click="toggleFavoriteBot(item)"
            >
              <font-awesome-icon icon="star" />
            </b-button>

            <b-button
              size="sm"
              variant="outline-primary"
              title="Download"
              :disabled="!item.permitted"
              @click.stop="downloadCurrentBot(item.id, name = item.name)"
            >
              <font-awesome-icon icon="download" />
            </b-button>

            <b-button
              size="sm"
              variant="outline-danger"
              title="Archive bot"
              :disabled="!item.permitted"
              @click.stop="openArchiveBotModal(item)"
            >
              <font-awesome-icon icon="archive" />
            </b-button>
          </b-btn-group>
        </template>
      </b-table>
      <b-row
        class="mt-3 ml-1"
      >
        <b-button
          v-b-modal.new-bot-modal
          variant="primary"
        >
          <font-awesome-icon icon="plus" /> New bot
        </b-button>
        <b-button
          v-b-modal.upload-modal
          class="mx-2"
          variant="secondary"
        >
          <font-awesome-icon icon="upload" /> Upload bot
        </b-button>
      </b-row>
    </b-card>

    <new-bot-modal />

    <b-modal
      id="upload-modal"
      title="Upload Bot"
      :ok-disabled="!upload.botFile"
      @ok="uploadBot"
    >
      <b-form-group
        label="Name"
        label-for="botNameForm"
        description="Leave empty to use the same name as the original model."
      >
        <b-form-input
          id="botNameForm"
          v-model="upload.botName"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="File"
        label-for="botFileForm"
        description="These files are typically obtained by clicking 'Download bot'
          below the conversation panel in a BotStudio instance."
      >
        <b-form-file
          id="botFileForm"
          v-model="upload.botFile"
          placeholder="Choose zipfile..."
        />
      </b-form-group>
    </b-modal>

    <b-modal
      id="upload-progress-modal"
      title="Uploading Bot"
      :ok-disabled="uploadInProgress"
      ok-only
      no-close-on-backdrop
      @ok="resetProgressModal"
    >
      <div
        v-if="uploadInProgress"
        class="text-center"
      >
        <b-spinner
          variant="primary"
          class="d-block mx-auto mb-2"
        />
        Upload in progress
      </div>

      <template v-if="uploadFailed">
        <font-awesome-icon
          color="orange"
          icon="exclamation-circle"
        />
        An error occurred while uploading the bot.
        Please contact your BotStudio administrator if the problem persists.
      </template>

      <template v-if="uploadSucceeded">
        <font-awesome-icon
          icon="check"
          color="green"
        />
        Bot was uploaded.
      </template>
    </b-modal>

    <b-modal
      id="archive-bot-modal"
      title="Confirm archival"
      ok-title="Archive bot"
      :ok-disabled="$v.deleteBotName.$invalid"
      ok-variant="danger"
      @ok="archiveBot()"
    >
      Are you sure that you want to archive this bot?
      <div class="mt-2">
        To confirm, type <strong>"{{ botToDelete.name }}"</strong> in the box below.
        <b-form-input v-model="deleteBotName" class="mt-2" />
      </div>
    </b-modal>
  </main>
</template>

<script>

import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
import endpoints from '@/js/urls';
import { BotUploadStatus } from '@/js/constants';
import downloadBotMixin from '@/mixins/DownloadBotMixin';
import NewBotModal from '@/pages/BotsOverview/NewBotModal.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'BotsOverview',
  components: { NewBotModal },
  mixins: [downloadBotMixin, validationMixin],
  data() {
    return {
      upload: {
        botName: '',
        botFile: null,
        uploadStatus: null,
      },
      fields: [
        {
          key: 'permitted',
          label: 'Permission',
          sortable: false,
          class: 'text-center permission-col',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thStyle: { width: '50%' },
        },
        {
          key: 'created',
          label: 'Created',
          sortable: true,
          formatter: (date) => new Date(date).toLocaleString('en-GB'),
        },
        {
          key: 'modified',
          label: 'Modified',
          sortable: true,
          formatter: (date) => new Date(date).toLocaleString('en-GB'),
        },
        {
          key: 'chat_count',
          label: 'Conversations (7 days)',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          class: 'text-right action-col',
        },
      ],
      botToDelete: {},
      deleteBotName: '',
    };
  },
  computed: {
    ...mapState('botManipulation', ['botTemplates']),
    ...mapGetters('auth', [
      'isUserLimited',
      'userHasNoPermissions',
    ]),
    ...mapGetters('userSettings', ['getBotStudioSettings']),
    botsList() {
      const allBots = this.$store.state.botManipulation.botsList;
      const bots = allBots ? allBots.filter((bot) => !(bot.hidden)) : [];
      for (const bot of bots) {
        bot.favorite = this.getBotStudioSettings.favorite_bots.includes(bot.id);
      }
      return bots;
    },
    uploadInProgress() {
      return this.upload.uploadStatus === BotUploadStatus.IN_PROGRESS;
    },
    uploadFailed() {
      return this.upload.uploadStatus === BotUploadStatus.FAILED;
    },
    uploadSucceeded() {
      return this.upload.uploadStatus === BotUploadStatus.UPLOAD_SUCCESFULL;
    },
  },
  methods: {
    ...mapActions('botManipulation', ['updateBotList']),
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('userSettings', ['addFavoriteBot', 'removeFavoriteBot']),
    async toggleFavoriteBot(bot) {
      if (!bot.favorite) {
        await this.addFavoriteBot(bot.id);
      } else {
        await this.removeFavoriteBot(bot.id);
      }
    },
    sortFavoriteCompare(aRow, bRow, _, descending) {
      if (aRow.favorite !== bRow.favorite) {
        const comp = aRow.favorite ? 1 : -1;
        return descending ? comp : -comp;
      }
      return null;
    },
    onRowClicked(item) {
      if (item.permitted) {
        this.setBot(item.id);
      } else {
        this.showWarning({
          title: 'Permission denied',
          text: 'You do not have permission to open this bot.',
          variant: 'warning',
        });
      }
    },
    async setBot(botId) {
      if (this.isUserLimited) {
        this.$router.push({ name: 'flow', params: { botId } });
      } else {
        this.$router.push({ name: 'dashboard', params: { botId } });
      }
    },
    async uploadBot() {
      this.$bvModal.show('upload-progress-modal');
      this.upload.uploadStatus = BotUploadStatus.IN_PROGRESS;
      const name = this.upload.botName ? this.upload.botName : null;
      const formData = new FormData();
      if (name !== null) {
        formData.append('name', name);
      }
      formData.append('zipfile', this.upload.botFile);
      try {
        const { data } = await axios.post(endpoints.botsBase, formData, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        await this.setBot(data.id);
        this.upload.uploadStatus = BotUploadStatus.UPLOAD_SUCCESFULL;
      } catch (e) {
        this.upload.uploadStatus = BotUploadStatus.FAILED;
      }
    },
    resetProgressModal() {
      this.upload.uploadStatus = null;
    },
    openArchiveBotModal(bot) {
      this.botToDelete = bot;
      this.$bvModal.show('archive-bot-modal');
    },
    async archiveBot() {
      await axios.put(
        endpoints.botsBase + this.botToDelete.id,
        { hidden: true },
        {
          headers: {
            Authorization: `JWT ${this.$store.state.auth.jwt}`,
          },
        },
      );
      this.updateBotList();
    },
  },
  validations() {
    return {
      deleteBotName: {
        required,
        matchBotName(value) {
          return value === this.botToDelete?.name;
        },
      },
    };
  },
};
</script>
<style>
.action-col{
  width: 120px;
}
</style>
