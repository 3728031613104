<template>
  <div>
    <p class="mb-2">
      <strong>Additional GenAI Configuration</strong> is now available, offering more flexibility
      and control to fine-tune your generative AI interactions.
    </p>
    <p>
      Some of the new key features include:
      <ul>
        <li>
          Add custom text to the GenAI system prompt under
          <strong>Configuration &gt; Generative AI</strong>.
        </li>
        <li>
          New GenAI "hard" guard allowing you to specify restrictions such as
          “if user wrote X and the GenAI reply does not contain Y, then don’t send it”.
        </li>
        <li>
          Systematically exclude chunks of text from context when generating replies,
          useful for excluding generic texts like support contact information or disclaimers
          from knowledge articles.
        </li>
        <li>
          When manually selecting articles to use as context for generating replies,
          you can now select more than just one article.
        </li>
      </ul>
    </p>
    <div class="text-center my-2">
      <b-button
        href="https://www.supwiz.com/generative-ai-supwiz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to read more about our general approach to generative AI
      </b-button>
    </div>
    <hr>
    <b-alert
      show
      class="mt-3"
      variant="info"
    >
      These new GenAI configuration features are available now!
      Explore yourself in Configuration -> Generative AI
    </b-alert>
  </div>
</template>

<script>
export default { name: 'GenerativeAIFlexibility' };
</script>
