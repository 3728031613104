import { render, staticRenderFns } from "./PuzzelCompactLogo.vue?vue&type=template&id=32669d47"
var script = {}


/* normalize component */
import normalizer from "!../../../../../products/botstudio/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports