<template>
  <div>
    <p class="mb-2">
      Use <strong>rich responses</strong> to send good-looking replies to users with
      images, links, buttons, and more.
    </p>
    <p class="mb-2">
      Be aware that some limitations exist depending on the chat platform you are using.
    </p>
    <b-row>
      <b-col cols="6">
        <b-img
          fluid
          :src="require(`@/assets/featureImages/rich_message_puzzel.png`)"
          alt="Example of rich message in Puzzel chat"
        />
      </b-col>
      <b-col cols="6">
        <b-img
          fluid
          :src="require(`@/assets/featureImages/rich_message_zendesk.png`)"
          alt="Example of rich message in Zendesk chat"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default { name: 'RichResponsesDescription' };
</script>
