<template>
  <b-modal
    id="paste-activity-modal"
    title="Paste activity"
    :ok-disabled="$v.pastedContent.$invalid"
    @ok="addActivityProxy"
    @shown="$refs.pasteActivityInput.focus()"
  >
    <b-form-group
      label="Clipboard content"
      label-for="activity-id"
    >
      <b-form-input
        id="activity-id"
        ref="pasteActivityInput"
        v-model="pastedContent"
        type="text"
        placeholder="Paste clipboard content here"
        :state="$v.pastedContent.$invalid ? false : null"
        aria-describedby="pastedContentFeedback"
      />
      <b-form-invalid-feedback id="pastedContentFeedback">
        <div v-if="!$v.pastedContent.required">
          Copy an activity and paste content here
        </div>
        <div v-else-if="!$v.pastedContent.pastedContentIsValid">
          The pasted content could not be validated
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import { guidGenerator } from '@/js/utils';

export default {
  name: 'PasteActivityModal',
  mixins: [validationMixin],
  props: {
    // The id of the node where we're copying activities _to_
    destinationNodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Pasted content will be validated for adhering to one of the following formats
       * <node-id> (This is for the "copy-all-activities" mode)
       * <node-id>/<activity-id> (This is for the "copy-single activity" mode)
       */
      pastedContent: null,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
  },
  methods: {
    ...mapMutations('botManipulation/activeBot', [
      'addActivity',
    ]),
    addActivityProxy() {
      // We assume that this function is only run, when pasted content has been validated.
      const nodeId = this.extractNodeIdFromPastedContent();
      if (nodeId === null) {
        return;
      }
      const nodeFromPaste = this.nodeById(nodeId);
      if (nodeFromPaste === null) {
        return;
      }

      const activityId = this.extractActivityIdIdFromPastedContent();
      if (activityId === null) {
        // Copy-all-mode: We should copy all activities from pasted node to this node's activities
        nodeFromPaste.activityIds.forEach(
          (id) => this.copyActivity(nodeFromPaste.activities[id]),
        );
      } else {
        // Copy-single activity mode: Only copy the single activity to here
        const activity = nodeFromPaste.activities[activityId];
        if (activity === undefined) {
          return;
        }
        this.copyActivity(activity);
      }
    },
    copyActivity(activity) {
      /**
       * Copy activity to destination node while assigning the copied activity a new UUID.
       */
      const activityCopy = cloneDeep(activity);
      if (activityCopy.type === 'response') {
        // When copying response-activites, don't assume it is still an approved response
        // regardless of whether it was already approved or not.
        activityCopy.responseApproved = false;
      }
      this.addActivity({
        nodeId: this.destinationNodeId,
        activity: activityCopy,
        activityId: guidGenerator(),
      });
    },
    extractNodeIdFromPastedContent() {
      /**
       * This function attempts to extract a node-id from pasted content, but does not validate
       * if that node-id refers to an existing node.
       */
      const uuidRegex = /^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:$|\/)/;
      const namedNodeRegex = /^(greet|error|fallback|inactive|final)(?:$|\/)/;
      let pastedNodeId = null;
      if (uuidRegex.exec(this.pastedContent) !== null) {
        pastedNodeId = uuidRegex.exec(this.pastedContent)[1];
      } else if (namedNodeRegex.exec(this.pastedContent) !== null) {
        pastedNodeId = namedNodeRegex.exec(this.pastedContent)[1];
      }
      return pastedNodeId;
    },
    extractActivityIdIdFromPastedContent() {
      const pastedNodeId = this.extractNodeIdFromPastedContent();
      if (pastedNodeId === null) {
        // Pasted content does not adhere to expected format
        return null;
      }
      const activityId = this.pastedContent.substring(pastedNodeId.length + 1);
      if (activityId === null || activityId === '') {
        return null;
      }
      return activityId;
    },
  },
  validations: {
    /**
     * The validations are interdependent / build on top of each other and rely on their
     * corresponding warnings being shown only one warning at a time
     * (read: using "v-else-if"s to show warnings - as opposed to v-if)
     */
    pastedContent: {
      required,
      pastedContentIsValid() {
        // This validation only states whether a non-null pasted activity id is valid
        // the 'required' validation above will ensure non-null content has been pasted

        /* Check that the pasted content is valid:
        Pasted content is either,
        A. A node-id
        B. On the form <node-id>/<activity-id> and both <node-id> and <activity-id> exists
        in current bot
        */

        // Regex must also allow for named nodeIds such as greet, error, fallback, inactive
        if (this.pastedContent === null) {
          return true;
        }

        const pastedNodeId = this.extractNodeIdFromPastedContent();

        // Lookup node by node id
        const nodeFromPaste = this.nodeById(pastedNodeId);
        if (nodeFromPaste === undefined) {
          return false;
        }

        // If a slash is contained we must validate the activity-id too
        // This introduces/relies on an assumption that we will not in the future introduce
        // additional named nodes that has a slash in its name.
        if (this.pastedContent.includes('/')) {
          const allegedActivityId = this.extractActivityIdIdFromPastedContent();
          if (!nodeFromPaste.activityIds.includes(allegedActivityId)) {
            return false;
          }
        }
        return true;
      },
    },
  },
};
</script>
