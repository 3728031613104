<template>
  <b-card
    title="Secrets"
    class="r-75"
    body-class="p-3"
  >
    <p>
      Manage your bot's secrets here. <br> Secrets can be used for providing credentials when
      invoking integrations.<br>
      <b-button
        class="mt-2"
        variant="text"
        @click="() => ui.moreInfoCollapseIsOpen = !ui.moreInfoCollapseIsOpen"
      >
        <font-awesome-icon
          :icon="ui.moreInfoCollapseIsOpen ? 'angle-down' : 'angle-right'"
        />
        More info
      </b-button>
      <b-collapse
        v-model="ui.moreInfoCollapseIsOpen"
        class="mt-2"
      >
        <p class="mb-2">
          Secrets are stored in BotStudio backend using industry-standard encryption.
        </p>
        <p class="mb-2">
          If you download the bot, the secrets are not part of what is downloaded. Secrets' names
          can be updated without need for updating any use of it in the bot (or staged bots).
        </p>
        <p class="mb-2">
          When staging a bot, secrets are not part of what is staged, and secrets used by a staged
          bot are not "frozen in time".
        </p>
        <p>
          This means that if you stage a bot "Version 1" with a
          <em>reference</em> to a botsecret "AUTH_TOKEN" (for instance), then the value of
          AUTH_TOKEN used by "Version 1" will change if or when the value for AUTH_TOKEN is
          subsequently changed in BotStudio.
        </p>
      </b-collapse>
    </p>

    <b-table
      class="mt-3"
      :tbody-tr-attr="{ style: 'cursor:pointer' }"
      show-empty
      responsive
      hover
      small
      empty-text="No secrets defined for bot"
      :fields="secretsTableFields"
      :items="secretsTableItems"
      :busy="isFetchingActiveBotSecrets"
      @row-clicked="editSecret"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(buttons)="row">
        <b-btn
          title="Delete secret"
          variant="outline-danger"
          size="sm"
          @click="() => showDeleteModal(row.item)"
        >
          <font-awesome-icon icon="trash-alt" />
        </b-btn>
      </template>

      <template #cell(secretType)="row">
        <span
          v-if="row.item.secretType.toLowerCase() === 'simple'"
        >
          Simple secret
        </span>
        <span
          v-else-if="row.item.secretType.toLowerCase() === 'basicauth'"
        >
          Basic authorization header secret
        </span>
        <span
          v-else-if="row.item.secretType.toLowerCase() === 'customprefix'"
        >
          Secret with custom prefix
        </span>
        <span
          v-else-if="row.item.secretType.toLowerCase() === 'encryption-jwe'"
        >
          Secret with JWE encryption key
        </span>
        <span
          v-else
        >
          Unknown secret type
        </span>
      </template>
    </b-table>
    <b-dropdown
      split
      right
      text="Add a simple secret"
      variant="primary"
      @click="openCreateModal('createUpdateSecretModal')"
    >
      <b-dropdown-item-button
        @click="openCreateModal('basicSecretModal')"
      >
        Add a "Basic auth" secret
      </b-dropdown-item-button>
      <b-dropdown-item-button
        @click="openCreateModal('createPrefixedSecretModal')"
      >
        Add a prefixed secret
      </b-dropdown-item-button>
      <b-dropdown-item-button
        @click="openCreateModal('encryptionKeySecretModal')"
      >
        Add an encryption key secret
      </b-dropdown-item-button>
    </b-dropdown>
    <simple-secret-modal
      :secret-to-update="secretObjectForModal"
      :mode="createOrUpdateMode"
    />
    <basic-authorization-secret-modal
      :secret-to-update="secretObjectForModal"
      :mode="createOrUpdateMode"
    />
    <prefixed-secret-modal
      :secret-to-update="secretObjectForModal"
      :mode="createOrUpdateMode"
    />
    <encryption-key-secret-modal
      :secret-to-update="secretObjectForModal"
      :mode="createOrUpdateMode"
    />
    <delete-secrets-warning-modal
      :to-delete-id="secretToDeleteId"
    />
  </b-card>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import SimpleSecretModal from '@/pages/APIActions/BotSecrets/SimpleSecretModal.vue';
import DeleteSecretsWarningModal from '@/pages/APIActions/BotSecrets/DeleteSecretsWarningModal.vue';
import BasicAuthorizationSecretModal from '@/pages/APIActions/BotSecrets/BasicAuthorizationSecretModal.vue';
import PrefixedSecretModal from '@/pages/APIActions/BotSecrets/PrefixedSecretModal.vue';
import EncryptionKeySecretModal from '@/pages/APIActions/BotSecrets/EncryptionKeySecretModal.vue';

export default {
  name: 'ManageBotSecrets',
  components: {
    SimpleSecretModal,
    DeleteSecretsWarningModal,
    BasicAuthorizationSecretModal,
    PrefixedSecretModal,
    EncryptionKeySecretModal,
  },
  data() {
    return {
      ui: {
        moreInfoCollapseIsOpen: false,
      },
      // createOrUpdateMode prop for modal
      createOrUpdateMode: '',
      // secretObjectForModal should be set when the modal is to open in "edit" mode
      secretObjectForModal: null,
      secretsTableFields: [
        {
          key: 'buttons',
          label: '',
          thClass: 'text-left',
          tdClass: 'text-left align-middle',
          thStyle: 'width:30px',
        },
        {
          key: 'name',
          label: 'Name',
          tdClass: 'align-middle font-weight-bold',
        },
        {
          key: 'secretType',
          label: 'Type',
          tdClass: 'align-middle',
        },
      ],
      secretToDeleteId: null,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
      'isFetchingActiveBotSecrets',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    secretsTableItems() {
      const botSecrets = this.getBotSecrets(this.activeBotId);
      return botSecrets !== undefined ? botSecrets : [];
    },
  },
  async mounted() {
    await this.fetchSecrets();
  },
  methods: {
    ...mapActions('botSecrets', [
      'fetchSecrets',
    ]),
    openCreateModal(modalIdentifier) {
      this.createOrUpdateMode = 'create';
      this.$nextTick(() => {
        this.$bvModal.show(modalIdentifier);
      });
    },
    editSecret(rowItem) {
      // Specify edit-mode for modal, set object to edit, and then queue opening modal
      this.secretObjectForModal = rowItem;
      this.createOrUpdateMode = 'update';

      let editModalIdentifier = '';
      if (rowItem.secretType.toLowerCase() === 'encryption-jwe') {
        editModalIdentifier = 'encryptionKeySecretModal';
      } else if (rowItem.secretType.toLowerCase() === 'customprefix') {
        editModalIdentifier = 'createPrefixedSecretModal';
      } else if (rowItem.secretType.toLowerCase() === 'basicauth') {
        editModalIdentifier = 'basicSecretModal';
      } else if (rowItem.secretType.toLowerCase() === 'simple') {
        editModalIdentifier = 'createUpdateSecretModal';
      } else {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.show(editModalIdentifier);
      });
    },
    showDeleteModal(item) {
      this.secretToDeleteId = item.id;
      this.$nextTick(() => {
        this.$bvModal.show('deleteSecretsWarningModal');
      });
    },
  },
};
</script>
