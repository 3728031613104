<template>
  <b-list-group-item class="border-0 pl-3 pr-0 py-0">
    <b-row
      no-gutters
      align-v="center"
      @click="collapseOpen = !collapseOpen"
    >
      <b-col cols="auto">
        <font-awesome-icon
          class="mr-2"
          size="sm"
          :icon="collapseOpen ? 'angle-down' : 'angle-right'"
        />
      </b-col>
      <b-col>
        {{ item.name }}
      </b-col>
      <b-col cols="auto">
        <b-button
          v-if="!defaultBookmark"
          v-b-tooltip.hover.noninteractive.viewport
          size="sm"
          variant="text"
          title="Edit folder"
          @click="editFolder"
        >
          <font-awesome-icon icon="pencil" />
        </b-button>
      </b-col>
    </b-row>

    <b-collapse v-model="collapseOpen">
      <node-group
        v-for="(nestedItem, index) in filteredBookmarks"
        :key="index + nestedItem.name"
        :keyword="keyword"
        :item="nestedItem"
      />

      <b-row
        v-for="(node, i) in filteredNodes"
        :key="i"
        class="pl-3 cursor-pointer"
        no-gutters
        align-v="center"
      >
        <b-col
          v-b-tooltip.hover.noninteractive.viewport
          title="Zoom on node"
          @click="nodeClicked(node.node)"
        >
          {{ nameOfId(node.node) }}
        </b-col>
        <b-col
          v-b-tooltip.hover.noninteractive.viewport
          cols="auto"
          title="Remove node"
        >
          <b-button variant="text" @click="deleteBookmark(node.id)">
            <font-awesome-icon icon="minus" />
          </b-button>
        </b-col>
      </b-row>
    </b-collapse>
  </b-list-group-item>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

export default {
  name: 'NodeGroup',
  props: {
    item: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      collapseOpen: false,
    };
  },
  computed: {
    ...mapState('graphView', ['bookmarks']),
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
    defaultBookmark() {
      return this.item.isFolder && this.item.id === null;
    },
    filteredBookmarks() {
      return this.defaultBookmark ? []
        : this.bookmarks.filter((e) => e.parent === this.item.id && e.isFolder);
    },
    filteredNodes() {
      return this.bookmarks.filter((e) => e.parent === this.item.id && !e.isFolder);
    },
  },
  methods: {
    ...mapMutations('graphView', ['setTopNodeId', 'setActiveBookmark']),
    ...mapActions('graphView', ['deleteBookmark']),
    nodeClicked(item) {
      this.setTopNodeId(item);
      this.$emit('bookmarkClicked', item);
    },
    editFolder() {
      this.setActiveBookmark(this.item);
      this.$bvModal.show('bookmark-modal');
    },
  },
};
</script>
