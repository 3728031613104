export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isTranslationEnabled() {
  return !isProduction() ? process.env.ENABLE_TRANSLATION_FEATURE : window.translationEnabled.toLocaleLowerCase() === 'true';
}

export function isPhraseIntegrationEnabled() {
  return !isProduction() || window.phraseIntegrationEnabled.toLocaleLowerCase() === 'true';
}

export function isVoicebotEnabled() {
  return !isProduction() ? process.env.ENABLE_VOICEBOT_FEATURE : window.voicebotEnabled.toLocaleLowerCase() === 'true';
}

export function isGenerativeAiEnabled() {
  return !isProduction() ? process.env.ENABLE_GENERATIVE_AI_FEATURE : window.generativeAiEnabled.toLocaleLowerCase() === 'true';
}

export function isExternalStatsEnabled() {
  return !isProduction() ? process.env.ENABLE_EXTERNAL_STATS_FEATURE : window.externalStatsEnabled.toLocaleLowerCase() === 'true';
}

export function isSupSearchEnabled() {
  return !isProduction() ? process.env.ENABLE_SUPSEARCH_FEATURE : window.supSearchEnabled.toLocaleLowerCase() === 'true';
}
