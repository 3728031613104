<template>
  <div>
    <hr>
    <b-form-checkbox
      :id="`otherShowCheckbox${component_id}`"
      v-model="otherShow"
    >
      Show &quot;None of the above&quot; option
      <tooltipped-text
        value="Tick this to show an extra &quot;None of the above&quot; option when
               multiple options are showed"
      />
    </b-form-checkbox>

    <div v-if="otherShow">
      <b-form-group
        :id="`otherTextGroup${component_id}`"
        class="mt-1"
        label-class="d-flex"
        :label-for="`otherText${component_id}`"
      >
        <template #label>
          Text used for &quot;None of the above&quot; option
          <voicebot-audio
            v-if="voicebotPlatformSelected"
            v-b-tooltip.noninteractive
            :text="otherText || baseOtherText"
            small
            title="Play this message"
            class="border-0 bg-transparent shadow-none ml-1"
          />
        </template>
        <b-form-input
          :id="`otherText${component_id}`"
          v-model="otherText"
          type="text"
          :placeholder="baseOtherText"
          required
          @focus="autoFillText"
        />
        <div v-if="longNameWarning">
          <small class="text-warning mt-0">
            <font-awesome-icon icon="circle-exclamation" />
            We recommend using short and concise display names.
          </small>
        </div>
      </b-form-group>
      <b-form-group
        v-if="isSupchatPlatform"
        :id="`otherDescriptionGroup${component_id}`"
        class="mt-1"
        label-class="d-flex"
        :label-for="`otherDescription${component_id}`"
      >
        <template #label>
          Description used for &quot;None of the above&quot; option
        </template>
        <b-form-input
          :id="`otherDescription${component_id}`"
          v-model="otherDescription"
          type="text"
          :placeholder="baseOtherDescription"
          @focus="autoFillDescription"
        />
      </b-form-group>
    </div>
    <b-form-checkbox
      v-if="isMainBot"
      :checked="usesCustomFallbackNode"
      @input="usesCustomFallbackNode => setUsesCustomFallbackNode({ usesCustomFallbackNode })"
    >
      Custom fallback node
      <tooltipped-text
        value="When checked, you can specify a custom fallback node to replace the default behavior"
      />
    </b-form-checkbox>
    <div v-if="isMainBot && usesCustomFallbackNode">
      <template v-if="!customFallbackNodeId">
        <completion-input
          class="d-inline-block"
          placeholder="Choose fallback node"
          :completions="nodeCompletions"
          value=""
          @input="fallbackNodeId => setCustomFallbackNodeId({ fallbackNodeId })"
        />
        <div class="d-inline-block">
          <b-button
            v-b-modal.create-fallback-modal
            variant="success"
            style="margin-left:10px"
            pill
          >
            <font-awesome-icon
              icon="plus"
            />
            Create new fallback node
          </b-button>
        </div>
      </template>
      <div v-else>
        <b-button
          pill
          size="md"
          variant="primary"
          :to="editNodeLink(customFallbackNodeId)"
        >
          {{ nameOfId(customFallbackNodeId) }}
          <font-awesome-icon
            :icon="['far', 'times-circle']"
            size="lg"
            @click.prevent="() => setCustomFallbackNodeId({ fallbackNodeId: null })"
          />
        </b-button>
      </div>
    </div>
    <add-node-modal
      title="Create fallback node"
      modal-id="create-fallback-modal"
      @nodeAdded="setCustomFallbackNodeId({ fallbackNodeId: $event })"
    />
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import { applyThisArgs, addThisArgs } from '@/js/storeHelpers';
import TooltippedText from '@/components/TooltippedText.vue';
import AddNodeModal from '@/pages/TreeView/AddNodeModal.vue';
import VoicebotAudio from '@/components/VoicebotAudio.vue';

export default {
  name: 'EditOtherOptionText',
  components: {
    TooltippedText, CompletionInput, AddNodeModal, VoicebotAudio,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      arrow: 'angle-down',
    };
  },
  computed: {
    ...mapState('botManipulation/activeBot/config', ['multipleChoice']),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nameOfId',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
      'getPlatforms',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      usesCustomFallbackNode: 'getUsesCustomFallbackNode',
      customFallbackNodeId: 'getCustomFallbackNodeId',
      getOtherShow: 'getOtherShow',
      getOtherText: 'getOtherText',
      getOtherDescription: 'getOtherDescription',
    }), 'nodeId'),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    component_id() {
      return this._uid;
    },
    baseOtherText() {
      if (this.usesCustomFallbackNode && this.customFallbackNodeId) {
        // Return node name as placeholder
        return this.nameOfId(this.customFallbackNodeId);
      }
      return this.multipleChoice.otherText;
    },
    baseOtherDescription() {
      return this.multipleChoice.otherDescription;
    },
    otherShow: {
      get() {
        return this.getOtherShow;
      },
      set(otherShow) {
        this.setOtherShow({ otherShow });
      },
    },
    otherText: {
      get() {
        return this.getOtherText;
      },
      set(otherText) {
        this.setOtherText({ otherText });
      },
    },
    otherDescription: {
      get() {
        return this.getOtherDescription;
      },
      set(otherDescription) {
        this.setOtherDescription({ otherDescription });
      },
    },
    isSupchatPlatform() {
      return this.getPlatforms.includes('supchat');
    },
    nodeCompletions() {
      const { nodes } = this.$store.state.botManipulation.activeBot;
      const completions = [];
      for (const { id, name } of Object.values(nodes)) {
        if (id !== this.nodeId) {
          completions.push({ key: id, value: name });
        }
      }
      return completions;
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    longNameWarning() {
      return this.otherText?.length > 30;
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setOtherShow',
      'setOtherText',
      'setOtherDescription',
      'setUsesCustomFallbackNode',
      'setCustomFallbackNodeId',
    ]), { id: 'nodeId' }),
    autoFillText() {
      if (this.otherText === null) {
        this.setOtherText({ otherText: this.baseOtherText });
      }
    },
    autoFillDescription() {
      if (this.otherDescription === null) {
        this.setOtherDescription({ otherDescription: this.baseOtherDescription });
      }
    },
  },
};
</script>
