<template>
  <div class="d-flex flex-column align-items-center my-3">
    <span class="meta">Integration</span>
    <div
      class="bubble p-2"
      :class="action.data.type === 'chat_action'
        ? 'actionMessage bg-success'
        : 'actionMessage bg-dark'"
    >
      <b-row>
        <b-col class="text-right font-weight-bold">
          Name:
        </b-col>
        <b-col cols="8">
          {{ action.data.name }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Endpoint:
        </b-col>
        <b-col cols="8">
          {{ action.data.endpoint }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Request type:
        </b-col>
        <b-col cols="8">
          {{ action.data.request_type }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Params:
        </b-col>
        <b-col cols="8">
          <span v-if="params.length < maxCharacterCount">
            {{ params }}
          </span>
          <span
            v-else
            v-b-tooltip.noninteractive.hover.viewport="showParams ? 'Click to hide' : 'Click to show'"
            class="cursor-pointer"
            @click="showParams = !showParams"
          >
            {{ params }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Requires auth:
        </b-col>
        <b-col cols="8">
          {{ action.data.requires_auth }}
        </b-col>
      </b-row>
      <b-row v-if="action.data.error !== undefined">
        <b-col class="text-right font-weight-bold">
          Error:
        </b-col>
        <b-col cols="8">
          {{ action.data.error }}
          <font-awesome-icon
            v-if="!!action.data.error"
            icon="exclamation-circle"
            class="text-warning"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Result:
        </b-col>
        <b-col cols="8">
          <span v-if="result.length < maxCharacterCount">
            {{ result }}
          </span>
          <span
            v-else
            v-b-tooltip.noninteractive.hover.viewport="showResult ? 'Click to hide' : 'Click to show'"
            class="text-white cursor-pointer"
            @click="showResult = !showResult"
          >
            {{ result }}
          </span>
        </b-col>
      </b-row>
    </div>
    <span
      class="meta"
    >{{ dateFormatter(action.timestamp) }}</span>
  </div>
</template>

<script>
import { truncateString } from '@/js/utils';

export default {
  name: 'ChatAction',
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showParams: false,
      showResult: false,
      maxCharacterCount: 100,
    };
  },
  computed: {
    params() {
      return this.showParams ? JSON.stringify(this.action?.data?.params)
        : truncateString(JSON.stringify(this.action?.data?.params), this.maxCharacterCount);
    },
    result() {
      return this.showResult ? JSON.stringify(this.action?.data?.result)
        : truncateString(JSON.stringify(this.action?.data?.result), this.maxCharacterCount);
    },
  },
  methods: {
    dateFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleTimeString();
    },
  },
};
</script>

<style scoped>
.bubble.actionMessage {
  min-width: 80%;
  max-width: 80%;
}
</style>
