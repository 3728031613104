import Vue from 'vue';

const statsFilterState = {
  statsFilter: {
    selectedVariant: '',
    includeOngoing: false,
    selectedLanguage: 'any',
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
    startTime: 0,
    endTime: 24,
    selectedGroupBy: 'hoursAccum',
  },
  nodesFilter: {
    names: [],
    countMin: 0,
    countMax: 1,
    automationMin: 0,
    automationMax: 100,
    selfServiceMin: 0,
    selfServiceMax: 100,
    resolutionMin: 0,
    resolutionMax: 100,
    ratingMin: 0,
    ratingMax: 5,
  },
  activeSorting: { key: 'name', order: 'desc' },
  selectedOrigins: [],
  loadingOrigins: false,
  needsRefresh: {
    general: false,
    details: false,
  },
};
const getters = {
  statsFilter: (state) => state.statsFilter,
  nodesFilter: (state) => state.nodesFilter,
  getSelectedOrigins: (state) => state.selectedOrigins,
  loadingOrigins: (state) => state.loadingOrigins,
  needsRefresh: (state) => state.needsRefresh,
  activeSorting: (state) => state.activeSorting,
};
const mutations = {
  updateStatsFilter(state, { key, newValue }) {
    state.statsFilter[key] = newValue;
    state.needsRefresh = { general: true, details: true };
  },
  setLoadingOrigins(state, value) {
    state.loadingOrigins = value;
  },
  setOrigins(state, value) {
    state.selectedOrigins = value;
  },
  setNeedsRefresh(state, { key, value }) {
    state.needsRefresh[key] = value;
  },
  updateNodeFilter(state, { prop, value }) {
    Vue.set(state.nodesFilter, prop, value);
  },
  setActiveSorting(state, { key, order }) {
    state.activeSorting = { key, order };
  },
};
const actions = {};

export default {
  namespaced: true,
  state: statsFilterState,
  getters,
  mutations,
  actions,
};
