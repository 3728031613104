import Vue from 'vue';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { deepCopyJson } from 'supwiz/util/data';
import endpoints from '@/js/urls';

import {
  ACTION, CHAT_ACTION, CONTROL_FLOW, RESPONSE, SET_VARIABLE, CALL_MODEL, METRIC_SIGNAL,
  COMPOUND_RESPONSE, ENCRYPT, GENERATIVE_AI, SUP_WIZ_INTEGRATION,
} from '@/js/activity';
import { guidGenerator } from '@/js/utils';
import { nodesGetters } from './nodes';

// These are getters that relate to a single activity. They will be added to the set of all getters
// by transforming the getter into a function that takes two ids and returns the value for
// the given activity.
// In the code below the `state` argument will refer to a single activity, i.e. equals
// nodes[nodeId].activity[activityId] some ids.
const singleActivityGetters = {
  activityText(state) {
    if (![RESPONSE, COMPOUND_RESPONSE, GENERATIVE_AI].includes(state.type)) {
      return null;
    }
    return state.text;
  },
  activityResponseApproved(state) {
    if (![RESPONSE, COMPOUND_RESPONSE, GENERATIVE_AI].includes(state.type)) {
      return null;
    }
    return state.responseApproved;
  },
  activityId(state) {
    if (![CHAT_ACTION, GENERATIVE_AI, SUP_WIZ_INTEGRATION].includes(state.type)) {
      return null;
    }
    return state.id;
  },
  activityName(state) {
    if (![ACTION, CHAT_ACTION, GENERATIVE_AI,
      SUP_WIZ_INTEGRATION, CONTROL_FLOW].includes(state.type)) {
      return null;
    }
    return state.name;
  },
  activityParams(state) {
    if (![ACTION, CHAT_ACTION, GENERATIVE_AI, SUP_WIZ_INTEGRATION].includes(state.type)) {
      return null;
    }
    return state.params;
  },
  activityTarget(state) {
    if (![ACTION, CHAT_ACTION, GENERATIVE_AI, SUP_WIZ_INTEGRATION, ENCRYPT].includes(state.type)) {
      return null;
    }
    return state.target;
  },
  activityKey(state) {
    if (![SET_VARIABLE, ENCRYPT].includes(state.type)) {
      return null;
    }
    return state.key;
  },
  activityCompoundResponseTitle(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.title;
  },
  activityCompoundResponseImage(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.imageURL;
  },
  activityCompoundResponseImageAlt(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.imageAlt;
  },
  activityCompoundResponseLink(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.link;
  },
  activityCompoundResponseLinkText(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.linkText;
  },
  activityCompoundResponseType(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.compoundType;
  },
  activityCompoundResponseButtonIds(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.buttonIds;
  },
  activityCompoundResponseButtons(state) {
    if (state.type !== COMPOUND_RESPONSE) {
      return null;
    }
    return state.buttons;
  },
  activityActionHeaders(state) {
    if (state.type !== ACTION) {
      return null;
    }
    return state.headers;
  },
  activityActionModified(state) {
    if (state.type !== ACTION) {
      return null;
    }
    return state.modified;
  },
  activityActionAppendValue(state) {
    if (state.type !== ACTION) {
      return null;
    }
    return state.appendValue;
  },
  activityCode(state) {
    if (![SET_VARIABLE, ENCRYPT].includes(state.type)) {
      return null;
    }
    return state.code;
  },
  activityControlFlowLoopType(state) {
    if (state.type !== CONTROL_FLOW) {
      return null;
    }
    if (!state.loopType) {
      return 'ifLoop';
    }
    return state.loopType;
  },
  activityControlFlowStatement(state) {
    if (state.type !== CONTROL_FLOW) {
      return null;
    }
    return state.statement;
  },
  activityControlFlowIterable(state) {
    if (state.type !== CONTROL_FLOW) {
      return null;
    }
    return state.iterable;
  },
  activityControlFlowItem(state) {
    if (state.type !== CONTROL_FLOW) {
      return null;
    }
    return state.item;
  },
  activityCallModelName(state) {
    if (state.type !== CALL_MODEL) {
      return null;
    }
    return state.modelName;
  },
  activityCallModelInput(state) {
    if (state.type !== CALL_MODEL) {
      return null;
    }
    return state.modelInput;
  },
  activityCallModelTarget(state) {
    if (state.type !== CALL_MODEL) {
      return null;
    }
    return state.targetName;
  },
  activityCallModelReturnProbs(state) {
    if (state.type !== CALL_MODEL) {
      return null;
    }
    return state.returnProbs;
  },
  activityMetricSignalLabel(state) {
    if (state.type !== METRIC_SIGNAL) {
      return null;
    }
    return state.label;
  },
};

// These are mutations that relate to a single activity. They will be added to the set of all
// mutations by transforming the mutation into another mutation that first extracts the node
// using the nodeId and the activityId of the payload and then applies the original mutation.
// In the code below the `state` argument will refer to a single activity, i.e. equals
// nodes[nodeId].activity[activityId] some ids.
const singleActivityMutations = {
  setChatFormParams(state, { index, params }) {
    Vue.set(state.params[index], 'value', params);
  },

  setChatSummaryParams(state, { index, params }) {
    Vue.set(state.params[index], 'value', params);
  },

  setActivityResponseText(state, { text }) {
    if ([RESPONSE, COMPOUND_RESPONSE, GENERATIVE_AI].includes(state.type)) {
      if (state.text !== text) {
        state.text = text;
        state.responseApproved = false;
      }
    }
  },
  setApproved(state, { approved }) {
    if ([RESPONSE, COMPOUND_RESPONSE, GENERATIVE_AI].includes(state.type)) {
      state.responseApproved = !!approved;
    }
  },
  setActivityParams(state, { params }) {
    if ([ACTION, CHAT_ACTION, GENERATIVE_AI, SUP_WIZ_INTEGRATION].includes(state.type)) {
      state.params = params;
    }
  },
  setActivityTarget(state, { target }) {
    if ([ACTION, CHAT_ACTION, GENERATIVE_AI, SUP_WIZ_INTEGRATION, ENCRYPT].includes(state.type)) {
      state.target = target;
    }
  },
  setActivityKey(state, { key }) {
    if ([SET_VARIABLE, ENCRYPT].includes(state.type)) {
      state.key = key;
    }
  },
  setActivityCode(state, { code }) {
    if ([SET_VARIABLE, ENCRYPT].includes(state.type)) {
      state.code = code;
    }
  },
  setActivityCompoundResponseTitle(state, { title }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (state.title !== title) {
        state.title = title;
      }
    }
  },
  setActivityCompoundResponseImage(state, { imageURL }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (state.imageURL !== imageURL) {
        state.imageURL = imageURL;
      }
    }
  },
  setActivityCompoundResponseImageAlt(state, { imageAlt }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (state.imageAlt !== imageAlt) {
        state.imageAlt = imageAlt;
      }
    }
  },
  setActivityCompoundResponseLink(state, { link }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (state.link !== link) {
        state.link = link;
      }
    }
  },
  setActivityCompoundResponseLinkText(state, { linkText }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (state.linkText !== linkText) {
        state.linkText = linkText;
      }
    }
  },

  setActivityCompoundResponseType(state, { type }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (state.compoundType !== type) {
        state.compoundType = type;
      }
    }
  },
  setActivityCompoundResponseButtonText(state, { buttonId, text }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (buttonId in state.buttons) {
        Vue.set(state.buttons[buttonId], 'text', text);
      }
    }
  },
  setActivityCompoundResponseButtonType(state, { buttonId, type }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (buttonId in state.buttons) {
        Vue.set(state.buttons[buttonId], 'type', type);
      }
    }
  },
  setActivityCompoundResponseButtonLink(state, { buttonId, link }) {
    if (state.type === COMPOUND_RESPONSE) {
      if (buttonId in state.buttons) {
        Vue.set(state.buttons[buttonId], 'link', link);
      }
    }
  },
  addActivityCompoundResponseButton(state) {
    if (state.type === COMPOUND_RESPONSE) {
      const buttonId = guidGenerator();
      state.buttonIds.push(buttonId);
      Vue.set(state.buttons, buttonId, {
        text: '',
        type: 'Link',
        link: '',
      });
    }
  },
  deleteActivityCompoundResponseButton(state, { buttonId }) {
    if (state.type === COMPOUND_RESPONSE) {
      const buttonIndex = state.buttonIds.indexOf(buttonId);
      if (buttonIndex >= 0) {
        state.buttonIds.splice(buttonIndex, 1);
      }
      Vue.delete(state.buttons, buttonId);
    }
  },
  setActivityActionHeaders(state, { headers }) {
    if (state.type === ACTION) {
      state.headers = headers;
    }
  },
  setActivityActionModified(state, { modified }) {
    if (state.type === ACTION) {
      state.modified = modified;
    }
  },
  setActivityActionAppendValue(state, { value }) {
    if (state.type === ACTION) {
      state.appendValue = value;
    }
  },
  setActivityControlFlowStatement(state, { statement }) {
    if (state.type === CONTROL_FLOW) {
      state.statement = statement;
    }
  },
  setActivityControlFlowIterable(state, { iterable }) {
    if (state.type === CONTROL_FLOW) {
      state.iterable = iterable;
    }
  },
  setActivityControlFlowItem(state, { item }) {
    if (state.type === CONTROL_FLOW) {
      state.item = item;
    }
  },
  setActivityCallModelName(state, { modelName }) {
    if (state.type === CALL_MODEL) {
      state.modelName = modelName;
    }
  },
  setActivityCallModelInput(state, { modelInput }) {
    if (state.type === CALL_MODEL) {
      state.modelInput = modelInput;
    }
  },
  setActivityCallModelTarget(state, { targetName }) {
    if (state.type === CALL_MODEL) {
      state.targetName = targetName;
    }
  },
  setActivityCallModelReturnProbs(state, { returnProbs }) {
    if (state.type === CALL_MODEL) {
      state.returnProbs = returnProbs;
    }
  },
  setActivityMetricSignalLabel(state, { label }) {
    if (state.type === METRIC_SIGNAL) {
      state.label = label;
    }
  },
};

const activityGetters = {
  getActionsModified: (state, getters) => (nodeId) => {
    // Returns a list of the ids of the activites corresponding to actions that have been modified.
    const node = getters.nodeById(nodeId);
    const activityIds = [];
    for (const [activityId, activity] of Object.entries(node.activities)) {
      if (activity.type === ACTION && activity.modified) {
        activityIds.push(activityId);
      }
    }
    return activityIds;
  },
  getDraftResponsesForNode: (state, getters) => (nodeId) => {
    // Returns a list of the ids of the activites corresponding to actions that have been modified.
    const node = getters.nodeById(nodeId);
    const draftResponses = [];
    for (const activity of Object.values(node.activities)) {
      if (activity.type === RESPONSE && !activity.responseApproved) {
        draftResponses.push(activity);
      }
    }
    return draftResponses;
  },
  hasDraftResponsesInNode: (state, getters) => (nodeId) => {
    const node = getters.nodeById(nodeId);
    for (const activity of Object.values(node.activities)) {
      if (activity.type === RESPONSE && !activity.responseApproved) {
        return true;
      }
    }
    if (node.options.nodeType === 'multipleChoice' && !node.options.responseApproved) {
      return true;
    }

    return false;
  },
};

function convertGetter(getter) {
  return (state, getters) => (nodeId, activityId) => {
    const node = getters.nodeById(nodeId);
    const activity = node.activities[activityId] || {};
    return getter(activity);
  };
}

for (const [name, fn] of Object.entries(singleActivityGetters)) {
  activityGetters[name] = convertGetter(fn);
}

// TODO: Should we put these in the nodes.js file instead?
const activityMutations = {
  addActivity(state, { nodeId, activity, activityId }) {
    const node = nodesGetters.nodeById(state)(nodeId);
    node.activityIds.push(activityId);
    Vue.set(node.activities, activityId, activity);
  },
  cloneActivity(state, { nodeId, activityId }) {
    const node = nodesGetters.nodeById(state)(nodeId);
    const cloned = deepCopyJson(node.activities[activityId]);
    const newId = guidGenerator();
    if (cloned.type === 'response') {
      cloned.responseApproved = false;
    }
    Vue.set(node.activities, newId, cloned);
    const index = node.activityIds.indexOf(activityId);
    node.activityIds.splice(index + 1, 0, newId);
  },
  deleteActivity(state, { nodeId, activityId }) {
    const node = nodesGetters.nodeById(state)(nodeId);
    node.activityIds = node.activityIds.filter((id) => id !== activityId);
    Vue.delete(node.activities, activityId);
  },
  setActivitiesOrder(state, { nodeId, activityIds }) {
    const node = nodesGetters.nodeById(state)(nodeId);
    // TODO: Maybe make a check on the activity ids here?
    node.activityIds = activityIds;
  },
  clearAllModifiedActionWarnings(state) {
    for (const node of Object.values(state.nodes)) {
      for (const activity of Object.values(node.activities)) {
        if (activity.type === ACTION) {
          activity.modified = false;
        }
      }
    }
  },
};

function convertMutation(mutation) {
  return (state, payload) => {
    const node = nodesGetters.nodeById(state)(payload.nodeId);
    const activity = node.activities[payload.activityId];
    return mutation(activity, payload);
  };
}

for (const [name, fn] of Object.entries(singleActivityMutations)) {
  activityMutations[name] = convertMutation(fn);
}

const activityActions = {
  async addResponseActivity({ commit }, { nodeId }) {
    const activity = {
      type: RESPONSE,
      text: '',
      responseApproved: false,
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addCompoundResponseActivity({ commit }, { nodeId, compoundType }) {
    const buttonId = guidGenerator();
    const activity = {
      type: COMPOUND_RESPONSE,
      text: '',
      title: '',
      imageURL: '',
      imageAlt: '',
      link: '',
      linkText: '',
      compoundType: compoundType || '',
      buttonIds: [buttonId],
      buttons: {},
      responseApproved: false,
    };
    activity.buttons[buttonId] = {
      text: '',
      type: 'Link',
      link: '',
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addActionActivity({ commit, rootGetters }, { nodeId, name }) {
    const realAction = rootGetters['botManipulation/getBotActionByName'](name);
    if (!realAction) {
      throw new Error(`Could not find integration "${name}".`);
    }
    const headers = realAction.headers.map((header) => {
      const mappedHeader = {
        key: header.name,
        value: header.default,
      };
      if (header.secretReference !== undefined) {
        mappedHeader.secretReference = header.secretReference;
      }
      return mappedHeader;
    });
    const params = realAction.params.map((param) => {
      const mappedParameter = {
        key: param.name,
        value: param.default,
      };
      if (param.secretReference !== undefined) {
        mappedParameter.secretReference = param.secretReference;
      }
      return mappedParameter;
    });
    const activity = {
      type: ACTION,
      name,
      headers,
      params,
      modified: false,
      target: '',
      appendValue: realAction.appendDefault,
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addChatActionActivity({ commit }, { nodeId, chatAction }) {
    const params = chatAction.params.map((param) => ({
      key: param.name,
      value: cloneDeep(param.default),
    }));
    const activity = {
      type: CHAT_ACTION,
      name: chatAction.name,
      id: chatAction.id,
      params,
      target: chatAction.target,
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addGenerativeAIActionActivity({ commit }, { nodeId, aiAction }) {
    const params = aiAction.params.map((param) => ({
      key: param.name,
      value: cloneDeep(param.default),
    }));
    const activity = {
      type: GENERATIVE_AI,
      name: aiAction.name,
      id: aiAction.id,
      params,
      target: aiAction.target,
    };
    if (aiAction.text !== undefined) {
      activity.text = aiAction.text;
    }
    if (aiAction.responseApproved !== undefined) {
      activity.responseApproved = aiAction.responseApproved;
    }
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addSupWizActionActivity({ commit }, { nodeId, action }) {
    const params = action.params.map((param) => ({
      key: param.name,
      value: cloneDeep(param.default),
    }));
    const activity = {
      type: SUP_WIZ_INTEGRATION,
      name: action.name,
      id: action.id,
      params,
      target: action.target,
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addSetVariableActivity({ commit }, { nodeId }) {
    const activity = {
      type: SET_VARIABLE,
      key: '',
      code: '',
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addControlFlowActivity({ commit }, { nodeId, controlFlowObj }) {
    const activity = {
      type: CONTROL_FLOW,
      ...controlFlowObj,
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addCallModelActivity({ commit }, { nodeId }) {
    const activity = {
      type: CALL_MODEL,
      modelName: null,
      modelInput: '',
      targetName: '',
      returnProbs: false,
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addMetricSignalActivity({ commit }, { nodeId }) {
    const activity = {
      type: METRIC_SIGNAL,
      label: '',
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async addEncryptActivity({ commit }, { nodeId }) {
    const activity = {
      type: ENCRYPT,
      method: 'encryption-jwe',
      code: '',
      key: null,
      target: '',
    };
    const activityId = guidGenerator();
    commit('addActivity', { nodeId, activity, activityId });
    return activityId;
  },
  async synthesizeText({ rootState }, { botId, responseText }) {
    const config = {
      params: {
        bot_id: botId,
        text: responseText,
        supplier: 'azure',
      },
      headers: {
        Authorization: `JWT ${rootState.auth.jwt}`,
      },
      responseType: 'arraybuffer',
    };
    try {
      const result = await axios.get(endpoints.responseAudio, config);
      if (result.status === 200) {
        return {
          type: result.headers['content-type'],
          data: result.data,
        };
      }
      throw new Error('Failed to fetch Audio data from backend, ', result);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response.status === 400) {
        const enc = new TextDecoder('utf-8');
        const errorMsg = JSON.parse(enc.decode(error.response.data)).error;
        return {
          error: errorMsg,
        };
      }
      throw new Error('Failed to fetch Audio data from backend, ', error.response);
    }
  },
};

export { activityGetters, activityMutations, activityActions };
