<template>
  <div class="test">
    <h5
      v-for="(assignedLabel, index) in assignedLabels"
      :key="index"
    >
      <b-badge
        variant="primary"
        class="label-badge"
      >
        {{ assignedLabel }}
      </b-badge>
    </h5>
    <div />
  </div>
</template>

<script>
/**
 * Q: Why is this component needed?
 * A: I've created it such that I can pass in a fairly complex object that I can then manipulate
 * in script-section rather than try to work with it in template-section.
 *
 * Q: Is it likely to be re-used?
 * A: Most likely not, but hopefully others can find use of use it, too.
 */

import { mapGetters } from 'vuex';

export default {
  name: 'AssignedLabels',
  props: {
    assignedCategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('dataExploration/categories', [
      'getCategoryById',
    ]),
    assignedLabels() {
      // Two-step process:
      // Step 1:  Find all parent categories with at least one sub-category.
      const parentCategoriesWithSubcategory = new Set();
      for (const categoryId of this.assignedCategories) {
        const category = this.getCategoryById(categoryId);
        if (category && category.parentId !== null) {
          parentCategoriesWithSubcategory.add(category.parentId);
        }
      }

      // Step 2: Construct the actual badges
      const categoryBadges = [];
      for (const categoryId of this.assignedCategories) {
        if (parentCategoriesWithSubcategory.has(categoryId)) {
          // We don't add any badge since it is a parent category with a subcategory.
          continue;
        }
        const category = this.getCategoryById(categoryId);
        if (!category) {
          continue;
        }
        if (category.parentId == null) {
          // We're dealing with a parent-category
          categoryBadges.push(category.name);
        } else {
          // We're dealing with a sub-category
          const parentCategory = this.getCategoryById(category.parentId);
          categoryBadges.push(`${parentCategory.name}: ${category.name}`);
        }
      }
      return categoryBadges;
    },
  },
};
</script>
<style scoped>
.label-badge{
word-wrap: break-word;
word-break: break-all;
white-space: pre-line;
}
</style>
