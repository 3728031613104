<template>
  <div>
    <p class="mb-2">
      <strong>Response delays</strong> provides a way to make conversation flow more
      "naturally" by adding a short pause before and after sending messages to emulate the bot
      reading and writing text as well as to give users time to read.
    </p>
    <p>
      Delay can be set up as either a <em>fixed</em> delay between each message or as <em>auto</em>
      delay based on word-per-minute speed. This way delay is dynamic based on the length of the
      message sent by the bot.
    </p>
    <!-- TODO: Add an appropriate image -->
  </div>
</template>
<script>
export default { name: 'AutomaticMessageDelayDescription' };
</script>
