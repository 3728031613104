// import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'supwiz/styling/supwiz_bootstrap.scss';

// Import and configure icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueObserveVisibility from 'vue-observe-visibility';
import '@/js/icons';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store/index';
import { isProduction } from '@/js/featureFlags';

// Register font-awesome-icon globally, so that we don't need to register it within every component.
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.prototype.openCtrlLink = (route, event) => {
  if (event?.ctrlKey) {
    const url = router.resolve(route).href;
    window.open(url, '_blank');
  } else {
    router.push(route);
  }
};
Vue.prototype.editNodeLink = (nodeId, subflowId = null, botId = null) => {
  // eslint-disable-next-line no-underscore-dangle
  let formattedBotId = router.app._route.params.botId;
  if (botId) {
    formattedBotId = botId;
  }
  if (subflowId) {
    formattedBotId = `${formattedBotId}-${subflowId}`;
  }
  return { name: 'edit-node', params: { botId: formattedBotId, nodeId } };
};

Vue.use(VueObserveVisibility);
Vue.use(BootstrapVue);
/* Vue.use(VueCodemirror, {
  options: {
    tabSize: 2,
    mode: 'text/x-python',
    theme: 'darcula',
    lineNumbers: true,
    line: true,
  },
}); */

// this won't work for some reason
// if (process?.env?.NODE_ENV !== 'development') {
/* if (!document.location.href.includes('botstudio.local')) {
  Sentry.init({
    Vue,
    dsn: 'https://efa253e03acd4c76ab109ccbf9bfcbeb@o1307798.ingest.sentry.io/6552546',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
*/

// Vue is built in a way such that `new Vue` does give side effects, so we disable `no-new` here.
const vue = new Vue({ // eslint-disable-line no-new
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
// Alternative using $mount suggested by vue-cli 3:
/* const vue = new Vue({
   router,
   store,
   render: h => h(App),
 }).$mount('#app'); */

if (!isProduction()) {
  const consoleErrorOrg = console.error;
  // eslint-disable-next-line no-inner-declarations
  function consoleErrorMock() {
    // eslint-disable-next-line prefer-rest-params
    consoleErrorOrg(...arguments);
    if (vue) {
      vue.$bvToast.toast('Console has errors', {
        title: '[SupwizDEV]: An error occurred in console',
        appendToast: true,
        variant: 'danger',
      });
    }
  }
  console.error = consoleErrorMock;
}
