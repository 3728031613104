<template>
  <b-modal
    id="createVisualizationModal"
    title="Create new visualization"
    ok-title="Create"
    :ok-disabled="$v.$invalid"
    @ok="handleSubmit"
  >
    <span v-if="!dataSetHasSufficientDatapoints">
      <font-awesome-icon
        icon="exclamation-circle"
        style="color:red"
      />
      Your dataset does not have sufficient datapoints (10 or more) needed for creating a
      visualization. In order to create a visualization, add more datapoints to the dataset.
    </span>
    <span v-else-if="dataSetHasFewDatapoints">
      <font-awesome-icon
        icon="exclamation-circle"
        style="color:orange"
      />
      There is only {{ datapointCount }} datapoints in your dataset. We recommend having at
      least 500 datapoints to ensure reasonable performance of the algorithm.
    </span>
    <b-form-group
      label="Name"
      :disabled="!dataSetHasSufficientDatapoints"
      class="mt-3"
    >
      <b-form-input
        id="newVisualizationName"
        v-model="name"
        placeholder="Name"
        :state="$v.name.$invalid ? false : null"
        aria-describedby="newVisualizationNameFeedback"
      />
      <b-form-invalid-feedback id="newVisualizationNameFeedback">
        <div v-if="!$v.name.required">
          A name is needed.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Number of topics"
      :description="`${numTopics}`"
      :disabled="!dataSetHasSufficientDatapoints"
    >
      <b-form-input
        id="newVisualizationNumTopics"
        v-model="numTopics"
        type="range"
        min="10"
        max="50"
      />
    </b-form-group>
    <b-form-group
      label="Language"
      :disabled="!dataSetHasSufficientDatapoints"
    >
      <b-form-select
        id="newVisualizationLanguage"
        v-model="language"
        :options="languageOptions"
        :state="$v.language.$invalid ? false : null"
        aria-describedby="newVisualizationLanguageFeedback"
      />
      <b-form-invalid-feedback id="newVisualizationLanguageFeedback">
        <div v-if="!$v.name.required">
          Choose a language
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DataExplorationNewVisualization',
  mixins: [validationMixin],
  data() {
    return { // Note that this is overwritten by resetForm below.
      name: '',
      numTopics: 15,
      language: null,
    };
  },
  computed: {
    ...mapState('dataExploration', ['datasets']),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    languageOptions() {
      return [
        { text: '', value: null },
        { text: 'English', value: 'en' },
        { text: 'Danish', value: 'en+da' },
        { text: 'Dutch', value: 'en+nl' },
        { text: 'Finnish', value: 'en+fi' },
        { text: 'French', value: 'en+fr' },
        { text: 'German', value: 'en+de' },
        { text: 'Italian', value: 'en+it' },
        { text: 'Norwegian', value: 'en+no' },
        { text: 'Romanian', value: 'en+ro' },
        { text: 'Spanish', value: 'en+es' },
        { text: 'Swedish', value: 'en+sv' },
        { text: 'Turkish', value: 'en+tr' },
      ];
    },
    datapointCount() { return this.datasets[this.datasetId].data_count; },
    dataSetHasSufficientDatapoints() {
      return this.datapointCount > 10;
    },
    dataSetHasFewDatapoints() {
      return this.datapointCount < 500;
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...mapActions('dataExploration/visualizations', [
      'createVisualizationFromDataset',
    ]),
    resetForm() { // Note that this overwrites the data section above
      this.name = '';
      this.numTopics = 15;
      this.language = null;
      this.$v.$reset();
    },
    async handleSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const data = {
        datasetId: this.datasetId,
        name: this.name,
        numTopics: this.numTopics,
        language: this.language,
      };
      this.resetForm();
      const visualizationId = await this.createVisualizationFromDataset(data);
      const params = { visualizationId };

      this.$router.push({ name: 'dataExplorationTopicExploration', params });
    },
  },
  validations: {
    name: { required },
    language: { required },
  },
};
</script>
