<template>
  <div>
    <p>
      It is now possible to send <strong>hidden messages</strong> in SupChat for passing along
      information from chatbot to agent when transferring a conversation - or simply for adding
      notes to the agent during a conversation.
    </p>
    <p class="my-2">
      One common use-case is to combine this with the <em>Generate Summary</em> Gen-AI feature.
    </p>
    <!-- TODO: Add an appropriate image -->
  </div>
</template>
<script>
export default { name: 'SupchatHiddenMessageDescription' };
</script>
