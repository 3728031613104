<template>
  <b-table
    show-empty
    empty-text="No nodes have been added to overview yet"
    class="pb-0 mb-0"
    small
    hover
    :busy="isLoading('node_visits')"
    :tbody-tr-attr="{ style: 'cursor:pointer' }"
    :items="sortedItems"
    :fields="nodeStatsFields"
    @row-clicked="(row, index, event) => $emit('row-clicked', row, index, event)"
  >
    <template #cell(actions)="{ item: { id, favorite } }">
      <b-form-checkbox
        :key="id"
        button-variant="outline-primary"
        size="sm"
        button
        class="mr-1"
        :checked="favorite"
        @input="sel => proxyFavoriteNodeStatsNode(id, sel)"
      >
        <font-awesome-icon icon="star" />
      </b-form-checkbox>
      <b-btn
        variant="outline-danger"
        size="sm"
        @click.stop="proxyDeleteNodeStatsNode(id)"
      >
        <font-awesome-icon icon="trash-alt" />
      </b-btn>
    </template>
    <template #cell(count)="{ item: { count } }">
      <div v-if="count !== undefined">
        {{ count }}
      </div>
    </template>
    <template
      v-if="!showRatings"
      #cell(bar)="{ item: { fraction } }"
    >
      <div v-if="fraction !== undefined">
        <b-progress :max="100">
          <b-progress-bar
            :value="fraction * 100"
            :label="fraction >= 0.1 ? `${(fraction * 100).toFixed(2)}%` : ''"
          />
          <b-progress-bar
            :value="100 - fraction * 100"
            variant="secondary"
            :label="fraction < 0.1 ? `${(fraction * 100).toFixed(2)}%` : ''"
          />
        </b-progress>
      </div>
    </template>
    <template
      v-if="showRatings"
      #cell(ratingBar)="{ item: { rating } }"
    >
      <div v-if="rating !== undefined">
        <b-progress
          :class="rating2Class(rating)"
          :max="5"
        >
          <b-progress-bar
            :value="rating"
            :label="`${(rating).toFixed(1)} / 5.0`"
          />
        </b-progress>
      </div>
    </template>
  </b-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

function compareItems(a, b) {
  if (a.favorite && !b.favorite) {
    return -1;
  }
  if (!a.favorite && b.favorite) {
    return 1;
  }
  return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
}

export default {
  name: 'NodeStatsNodeTable',
  props: {
    nodeVisits: {
      type: Array,
      default: () => [],
    },
    showRatings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nodeStatsFields: [
        {
          key: 'actions',
          label: '',
          tdClass: 'align-left',
          thStyle: 'width:100px',
        },
        {
          key: 'name',
          label: 'Node',
          tdClass: 'align-middle font-weight-bold',
          thStyle: 'width:250px',
        },
        {
          key: 'count',
          label: 'Count',
          tdClass: 'align-middle text-left',
          thStyle: 'width:100px',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('statistics', ['isLoading']),
    sortedItems() {
      return [...this.nodeVisits].sort(compareItems);
    },
  },
  mounted() {
    this.showRatingsOrFractions();
  },
  methods: {
    ...mapActions('statistics', [
      'setNodeStatsNodeFavorite',
      'deleteNodeStatsNode',
    ]),
    proxyFavoriteNodeStatsNode(nodeId, favorite) {
      this.setNodeStatsNodeFavorite({ nodeId, favorite });
    },
    proxyDeleteNodeStatsNode(nodeId) {
      this.deleteNodeStatsNode(nodeId);
    },
    showRatingsOrFractions() {
      if (this.showRatings) {
        this.nodeStatsFields.push(
          {
            key: 'ratingBar',
            label: 'Average Rating',
            tdClass: 'align-middle',
          },
        );
      } else {
        this.nodeStatsFields.push(
          {
            key: 'bar',
            label: 'Fraction',
            tdClass: 'align-middle',
          },
        );
      }
    },
    rating2class(rating) {
      if (rating >= 4.0) return 'bar-green';
      if (rating >= 3.0) return 'bar-light-green';
      if (rating >= 2.0) return 'bar-yellow';
      if (rating >= 2.0) return 'bar-orange';
      return 'bar-red';
    },
  },
};
</script>
