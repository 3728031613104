<template>
  <b-card
    title="BotScript Playground"
    class="r-75"
    body-class="p-3"
  >
    <p>
      BotScript Playground provides you a quick and safe way to familiarize yourself with and to
      experiment with the BotScript language.<br> Read the full details on the BotScript language
      below.
    </p>
    <hr>
    <b-form @submit.prevent="() => evaluate()">
      <div class="mb-2">
        <b>Variables</b>
        <draggable
          v-model="variableIds"
        >
          <b-row
            v-for="i of variableIds"
            :key="`variable-${i}`"
          >
            <b-col cols="3">
              <div class="d-flex">
                <font-awesome-icon
                  class="mt-2 mr-1"
                  icon="up-down-left-right"
                />
                <VariableName
                  v-model="variables[i].name"
                  placeholder="Variable name"
                  class="mb-2 mr-sm-2 mb-sm-0 text-monospace"
                />
              </div>
            </b-col>
            <b-col cols="7">
              <botscript-validation
                :value="variables[i].code"
                :additional-types="getTypes(i)"
                :validations="['empty', 'typecheck-known']"
                @onChange="x => setCode(i, x)"
                @botscriptType="t => setType(i, t)"
              />
            </b-col>
            <b-col cols="2">
              <b-button
                variant="danger"
                @click="() => deleteVariable(i)"
              >
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </b-col>
          </b-row>
        </draggable>
        <div>
          <b-button
            variant="primary"
            @click="addVariable()"
          >
            <font-awesome-icon icon="plus" />
            Add variable
          </b-button>
        </div>
      </div>
      <div
        class="mt-3"
      >
        <b>Expression</b>
        <botscript-validation
          :validations="['typecheck-known']"
          :value="code"
          :additional-types="getTypes(-1)"
          expanded
          pretty-code
          @onChange="x => code = x"
        />
        <b-button
          variant="primary"
          class="mt-1"
          type="submit"
        >
          Evaluate
        </b-button>
      </div>
      <div class="p-3">
        <template v-if="loading">
          <b-spinner large />
        </template>
        <pre v-if="loaded && result !== null">{{ result }}</pre>
        <pre
          v-if="error !== null"
          class="text-danger"
        >
          {{ error }}
        </pre>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getVariables } from 'supwiz/botscript/parser';
import VariableName from '@/components/VariableName.vue';
import endpoints from '@/js/urls';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'ScriptEvaluator',
  components: {
    VariableName,
    BotscriptValidation,
    Draggable,
  },
  mixins: [validationMixin],
  data() {
    return {
      variableIds: [],
      variables: {},
      idCounter: 0,
      code: '',
      loading: false,
      loaded: false,
      result: null,
      error: null,
    };
  },
  beforeMount() {
    this.code = this.$route.query.code || '';
  },
  mounted() {
    const vars = getVariables(this.code);
    for (const name of vars) {
      this.addVariable(name);
    }
  },
  methods: {
    addVariable(name = '') {
      const id = this.idCounter++;
      this.variableIds.push(id);
      Vue.set(this.variables, id, { name, code: '', type: null });
    },
    deleteVariable(id) {
      Vue.delete(this.variables, id);
      this.variableIds = this.variableIds.filter((i) => i !== id);
    },
    setCode(id, code) {
      Vue.set(this.variables[id], 'code', code);
    },
    setType(id, type) {
      Vue.set(this.variables[id], 'type', type);
    },
    getTypes(id) {
      const types = {};
      for (const curId of this.variableIds) {
        if (curId === id) break;
        const variable = this.variables[curId];
        if (!variable || !variable.name) continue;
        if (variable.type) {
          types[variable.name] = variable.type;
        } else if (variable.name in types) {
          delete types[variable.name];
        }
      }
      return types;
    },
    setVarOrder(variableIds) {
      this.variableIds = variableIds;
    },
    async evaluate() {
      this.loading = true;
      this.loaded = false;
      this.error = null;
      this.result = null;
      const data = {
        vars: this.variableIds.map((id) => this.variables[id]),
        code: this.code,
      };
      try {
        const response = await axios.post(endpoints.evaluateScript, data, {
          headers: {
            Authorization: `JWT ${this.$store.state.auth.jwt}`,
          },
        });
        if (response.status !== 200) {
          throw new Error(`Unexpected ${response.status} status from backend`);
        }
        this.result = response.data.result;
        this.error = response.data.error;
      } catch (e) {
        this.error = `System error: ${e}`;
      }
      this.loading = false;
      this.loaded = true;
    },
  },
  validations: {
    variables: {
      $each: {
        code: {
          required,
        },
      },
    },
  },
};
</script>

<style scoped>

</style>
