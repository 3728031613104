<template>
  <b-modal
    id="new-bot-modal"
    title="Create a new bot"
    ok-title="Create"
    :ok-disabled="disabled"
    @show="reset"
    @ok="newBot"
  >
    <b-form-group
      label="Bot name"
    >
      <b-form-input
        id="botNameForm"
        v-model="newBotName"
        type="text"
        :state="!$v.newBotName.$invalid"
        placeholder="Leave blank and a bot name will be assigned automatically"
      />
      <b-form-invalid-feedback
        id="input-group-feedback"
      >
        <div v-if="!$v.newBotName.botNameNotTakenAlready">
          The name is already in use
        </div>
        <div v-else-if="!$v.newBotName.notAllWhiteSpace">
          Provide a name with characters other than whitespace.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox
        v-model="initFromDataset"
        switch
        @change="loadDatasets"
      >
        <label class="h6 font-weight-normal">Initialize bot from dataset</label>
        <tooltipped-text
          class="ml-1"
          value="Create nodes and classifiers based on the categories of the dataset."
        />
      </b-form-checkbox>
    </b-form-group>
    <template v-if="initFromDataset">
      <b-form-group
        :state="!$v.datasetId.$invalid"
        :invalid-feedback="'You must choose a dataset'"
      >
        <label class="h6 font-weight-normal">Dataset</label>
        <tooltipped-text
          class="ml-1"
          value="Intent nodes will be created based on the categories of the dataset"
        />
        <b-overlay
          :show="datasetsLoading"
          spinner-small
        >
          <b-form-select
            v-model="datasetId"
            :options="datasetOptions"
          />
        </b-overlay>
      </b-form-group>
      <b-form-group>
        <label class="h6 font-weight-normal">Other category</label>
        <tooltipped-text
          class="ml-1"
          value="The category used for all the datapoints that do not
          belong anywhere else. Only categories without subcategories are shown"
        />
        <b-form-select
          v-model="otherCategory"
          :options="otherCategories"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="createNodeClassifiers"
          switch
        >
          <label class="h6 font-weight-normal">Create intent classifiers</label>
          <tooltipped-text
            class="ml-1"
            value="Create intent classifiers based on the labels of the dataset (recommended)"
          />
        </b-form-checkbox>
      </b-form-group>
    </template>
    <b-form-group
      label="Templates"
      :state="!$v.templateIdx.$invalid"
      :invalid-feedback="'You must choose a template'"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(template, idx) in filteredBotTemplates"
          :key="template.name"
          :active="idx === templateIdx"
          button
          @click="chooseTemplate(idx)"
        >
          <b>{{ template.name }}</b><br>
          {{ template.description }}
        </b-list-group-item>
      </b-list-group>
    </b-form-group>
    <b-form-group
      label="Language"
    >
      <b-form-select
        v-model="language"
        :options="languages"
      />
    </b-form-group>
  </b-modal>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { DATASET_STATE_LOADING } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'NewBotModal',
  components: {
    TooltippedText,
  },
  mixins: [validationMixin],
  data() {
    return {
      initFromDataset: false,
      datasetId: null,
      otherCategory: null,
      createNodeClassifiers: true,
      templateIdx: null,
      newBotName: null,
      language: null,
    };
  },
  computed: {
    ...mapState('botManipulation', ['botTemplates']),
    ...mapGetters('dataExploration', [
      'datasetOptions',
    ]),
    ...mapGetters('botManipulation', [
      'botsList',
    ]),
    ...mapGetters('dataExploration/categories', [
      'getMainCategories',
      'getSubcategoriesForParent',
    ]),
    ...mapGetters('userSettings', ['showVoicebotFeatures']),
    existingBots() {
      const allBots = this.botsList;
      return allBots ? allBots.filter((bot) => !(bot.hidden)) : [];
    },
    filteredBotTemplates() {
      let templates = this.botTemplates;
      if (this.initFromDataset) {
        templates = templates.filter((x) => x.init_from_dataset);
      }
      if (!this.showVoicebotFeatures) {
        // Filter out voicebot-templates
        templates = templates.filter((x) => x.bot_type !== 'voicebot');
      }
      return templates;
    },
    disabled() {
      if (this.$v.templateIdx.$invalid) {
        return true;
      }
      if (this.initFromDataset && this.$v.datasetId.$invalid) {
        return true;
      }
      if (this.$v.newBotName.$invalid) {
        return true;
      }
      return false;
    },
    chosenTemplate() {
      return this.filteredBotTemplates[this.templateIdx];
    },
    languages() {
      if (this.chosenTemplate) {
        const result = [];
        for (const [languageId, language] of Object.entries(this.chosenTemplate.languages)) {
          result.push({
            text: language.display_name,
            value: languageId,
          });
        }
        return result;
      }
      return [];
    },
    /**
     * Bug-fix: Only allow for categories without subcategories to be chosen as "Other" category
     */
    otherCategories() {
      let displayableCategories;
      if (this.datasetId) {
        const allowedMainCategories = this.getMainCategories.filter((mainCategory) => {
          const mainCategoryId = mainCategory.id;
          // Only include those categories that does not have any subcategories
          return this.getSubcategoriesForParent(mainCategoryId).length === 0;
        });
        displayableCategories = allowedMainCategories.map((x) => ({ value: x.id, text: x.name }));
      } else {
        displayableCategories = [];
      }
      displayableCategories.unshift({ value: null, text: 'No category chosen' });
      return displayableCategories;
    },
    datasetsLoading() {
      return this.$store.state.dataExploration.datasetsLoadState === DATASET_STATE_LOADING;
    },
  },
  watch: {
    initFromDataset() {
      this.templateIdx = null;
    },
    datasetId(val) {
      this.otherCategory = null;
      if (val) {
        this.$store.dispatch('dataExploration/categories/fetchCategories', val);
      }
    },
    chosenTemplate(val) {
      if (val) {
        this.language = val.default_language;
      } else {
        this.language = null;
      }
    },
  },
  methods: {
    ...mapActions('dataExploration', ['fetchAllDatasets']),
    async setBot(botId) {
      this.$router.push({ name: 'flow', params: { botId } });
    },
    async newBot() {
      const data = {
        templateName: this.chosenTemplate.name,
        templateLanguage: this.language,
        datasetId: this.datasetId,
        otherCategory: this.otherCategory,
        createNodeClassifiers: this.createNodeClassifiers,
        botName: this.newBotName,
      };
      const res = await this.$store.dispatch('botManipulation/newBot', data);
      await this.setBot(res.data.id);
    },
    chooseTemplate(idx) {
      this.templateIdx = idx;
    },
    reset() {
      this.initFromDataset = false;
      this.datasetId = null;
      this.createNodeClassifiers = true;
      this.templateIdx = null;
    },
    loadDatasets() {
      if (!this.$store.state.dataExploration.isInitialized
          && this.$store.state.dataExploration.datasetsLoadState !== DATASET_STATE_LOADING) {
        this.fetchAllDatasets();
      }
    },
  },
  validations: {
    templateIdx: {
      required,
    },
    datasetId: {
      required,
    },
    newBotName: {
      botNameNotTakenAlready(suggestedName) {
        return !this.existingBots.map((x) => x.name).includes(suggestedName);
      },
      notAllWhiteSpace(suggestedName) {
        if (suggestedName === null || suggestedName.length === 0) {
          // The user hasn't entered anything yet / the modal has just shown itself
          return true;
        }
        return suggestedName.trim() !== '';
      },
    },
  },
};
</script>
