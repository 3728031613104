<template>
  <main
    role="main"
  >
    <b-card
      title="Entity Extraction"
      class="r-75"
      body-class="p-3"
    >
      <p class="mb-3">
        This page allows you to specify recognizers for different <em>entities</em> that the
        bot should pick up and save during conversations.
        Examples of <em>entities</em> are email-adresses, phone-numbers, web-adresses, etc.
        Each such <em>entity</em> is extracted using an <em>entity recognizer</em>.
      </p>
      <p class="mb-3">
        Each entity recognizer can be specified in different ways, using either a predefined
        entity-recognizer (for instance, email-adresses or URLs) or be configured for recognizing
        your organization's custom format(s), for instance invoice-numbers or customer-ids.
      </p>
      <p class="mb-3">
        Any entity used on the
        <router-link :to="{ name: 'config', hash: '#anonymization' }">
          anonymization page
        </router-link>
        should also be added here and
        the variable name should be equal to the replacement text used for anonymization. This
        ensures that the bot has a better understanding of the anonymized data that it is
        trained on.
      </p>
      <div class="small">
        Read the
        <router-link
          :to="{ name: 'scripthelp' }"
        >
          BotScript documentation
        </router-link> on how to access the results from
        entity-extraction in your bots nodes.
      </div>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      style="border:none;"
    >
      <b-table
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :items="getNERs"
        :fields="fields"
        show-empty
        empty-text="There are currently no entities defined for bot."
        responsive
        hover
        small
        @row-clicked="editIndex"
      >
        <template #cell(buttons)="row">
          <b-btn
            size="sm"
            variant="outline-danger"
            @click="deleteIndex(row.index)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-btn>
          <b-button
            :disabled="row.index === 0"
            size="sm"
            variant="outline-info"
            @click="swapIndex(row.index, row.index - 1)"
          >
            <font-awesome-icon icon="arrow-up" />
          </b-button>
          <b-button
            :disabled="row.index === getNERs.length - 1"
            size="sm"
            variant="outline-info"
            @click="swapIndex(row.index, row.index + 1)"
          >
            <font-awesome-icon icon="arrow-down" />
          </b-button>
        </template>

        <template #cell(type)="row">
          <span
            v-if="row.item.identifier.type === 'swml'"
          >
            Built-in recognizer
          </span>
          <span
            v-else-if="row.item.identifier.type === 'regex'"
          >
            Regular expression
          </span>
        </template>

        <template #cell(data)="row">
          {{ nerDetails(row.item) }}
        </template>
      </b-table>
      <div
        style="text-align: center"
      >
        <b-btn
          variant="primary"
          @click.stop="addNew"
        >
          Add new entity
        </b-btn>
      </div>
    </b-card>
    <AddEntityRecognizerModal
      :mode="isNew ? 'addNew' : 'editExisting'"
      :edit-index="activeIndex"
    />

    <!-- "Are you sure you want to delete this rule?" - modal -->
    <b-modal
      id="deleteNerModalId"
      title="Delete Entity"
      ok-title="Delete"
      @ok="deleteNer"
    >
      Are you sure you want to delete entity recognizer {{ curEntityName }}?
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddEntityRecognizerModal from '@/pages/AddEntityRecognizerModal.vue';
import { truncateString } from '@/js/utils';
import { NamedEntityRecognizerOptions } from '@/js/constants';

export default {
  name: 'EntityPage',
  components: {
    AddEntityRecognizerModal,
  },
  data() {
    return {
      isNew: null,
      activeIndex: -1,
      fields: [
        {
          key: 'buttons',
          label: '',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width:110px',
        },
        {
          key: 'name',
          tdClass: 'align-middle font-weight-bold',
        },
        {
          key: 'description',
          tdClass: 'align-middle',
        },
        {
          key: 'type',
          label: 'Type',
          tdClass: 'align-middle',
        },
        {
          key: 'data',
          label: 'Details',
          tdClass: 'align-middle',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'getNERs',
    ]),
    activeBotSet() {
      return this.$store.state.botManipulation.activeBotSet;
    },
    curEntityName() {
      if (this.activeIndex < 0 || this.activeIndex >= this.getNERs.length) {
        return '';
      }
      return this.getNERs[this.activeIndex].name;
    },
  },
  methods: {
    ...mapActions('botManipulation/activeBot', [
      'deleteNER',
      'addNewNER',
      'editNER',
      'swapNER',
    ]),
    deleteNer() {
      this.deleteNER({ index: this.activeIndex });
    },
    setEditIndex(idx) {
      this.activeIndex = idx;
      this.isNew = idx === -1;
    },
    editIndex(item, idx) {
      this.setEditIndex(idx);
      this.$nextTick(() => {
        // Without this, modal wont load correctly when editing an existing entry.
        this.$bvModal.show('editVarModal');
      });
    },
    deleteIndex(idx) {
      this.setEditIndex(idx);
      this.$bvModal.show('deleteNerModalId');
    },
    addNew() {
      this.setEditIndex(-1);
      this.$nextTick(() => {
        this.$bvModal.show('editVarModal');
      });
    },
    swapIndex(i, j) {
      this.swapNER({ i, j });
    },
    nerDetails(item) {
      if (item.identifier.type === 'swml') {
        const subtype = item.identifier.data;
        return NamedEntityRecognizerOptions[subtype].displayText;
      } if (item.identifier.type === 'regex') {
        return truncateString(item.identifier.data, 20);
      }
      return '';
    },
  },
};

</script>
