<template>
  <b-modal
    id="add-topic-examples"
    size="lg"
    ok-only
    title="Add topic examples to category"
    :ok-title="`${userWantsToCreateNewCategory ? 'Create' : 'Add to'} category`"
    :ok-disabled="disableSubmit"
    @show="onShow"
    @ok="submit"
    @hide="reset"
  >
    <b-form-group>
      <b-form-checkbox
        v-model="userWantsToCreateNewCategory"
        switch
      >
        Create new category
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      v-if="userWantsToCreateNewCategory"
      label="New category name"
    >
      <b-form-input
        v-model="newCategoryName"
        :state="$v.newCategoryName.$invalid ? false : null"
      />
      <b-form-invalid-feedback v-if="!$v.newCategoryName.required">
        Category is required.
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!$v.newCategoryName.isUnique">
        Category name already exists.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-else
      label="Choose existing category"
    >
      <b-form-select
        v-model="selectedCat"
        :options="catOptions"
        :state="$v.selectedCat.$invalid ? false : null"
      />
      <b-form-invalid-feedback v-if="!$v.selectedCat.required">
        Category is required.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Number of examples"
      description="The top examples will be included as data for the category."
    >
      <b-form-input
        v-model="numberOfExamplesForCategory"
        type="range"
        min="5"
        :max="maxExamples"
        step="1"
      />
      <div style="float:right">
        {{ numberOfExamplesForCategory }}
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'DataExplorationShowExamples',
  mixins: [validationMixin],
  props: {
    topicDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      numPages: 1,
      maxExamples: 50,
      visTopicName: null,
      missingData: false,
      userWantsToCreateNewCategory: false,
      newCategoryName: null,
      numberOfExamplesForCategory: 10,
      selectedCat: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('dataExploration/categories', ['categoryIds']),
    ...mapGetters('dataExploration/categories', [
      'isNameUnique',
      'nameOfId',
    ]),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    visualizationId() {
      return this.$route.params.visualizationId;
    },
    catOptions() {
      return this.categoryIds.map((id) => ({ text: this.nameOfId(id), value: id }));
    },
    disableSubmit() {
      if (this.userWantsToCreateNewCategory) {
        return this.$v.newCategoryName.$invalid;
      }
      return this.$v.selectedCat.$invalid;
    },
  },
  methods: {
    ...mapActions('dataExploration/categories', [
      'createCategory',
      'addTopicExamplesToCategory',
    ]),
    onShow() {
      this.maxExamples = Math.min(50, this.topicDetails.examples.length);
      this.visTopicName = this.topicDetails.id;
    },
    async createCategoryProxy() {
      this.isLoading = true;
      await this.createCategory({
        name: this.newCategoryName,
        visualizationId: this.visualizationId,
        topicId: this.visTopicName,
        numberOfTopicExamples: parseInt(this.numberOfExamplesForCategory, 10),
      });
      this.isLoading = false;
    },
    addToCategory() {
      this.isLoading = true;
      this.addTopicExamplesToCategory({
        categoryId: this.selectedCat,
        visualizationId: this.visualizationId,
        topicId: this.visTopicName,
        numberOfTopicExamples: parseInt(this.numberOfExamplesForCategory, 10),
      }).then(() => {
        this.isLoading = false;
      });
    },
    submit() {
      if (this.userWantsToCreateNewCategory) {
        this.createCategoryProxy();
      } else {
        this.addToCategory();
      }
    },
    reset() {
      this.userWantsToCreateNewCategory = false;
      this.newCategoryName = null;
      this.selectedCat = null;
    },
  },
  validations: {
    newCategoryName: {
      required,
      isUnique(name) {
        return this.isNameUnique(this.datasetId, null, name);
      },
    },
    selectedCat: {
      required,
    },
  },
};
</script>

<style scoped>

</style>
