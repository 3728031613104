import Vue from 'vue';
import update from 'immutability-helper';
import { nodeTypes, webhookPlatforms, wysiwygPlatforms } from '@/js/constants';
import { setState } from '@/js/utils';
import { isGenerativeAiEnabled, isVoicebotEnabled } from '@/js/featureFlags';
import auth from './auth';
import voice from './voice';
import generativeAI from './generativeAI';

export const makeInitialConfigState = () => ({
  type: null,
  name: null,
  responseDelays: {
    mode: 'fixed',
    fixedDelay: '0',
    readWpm: '200',
  },
  threshold: null,
  useInactiveNode: null,
  inactivityFromStart: false,
  inactivityPrompts: [],
  interruptMode: 'none',
  nluModels: [],
  platforms: [],
  /**
   * inputVariables for subflow.
   * Each entry (an object) in list contains a mapping from a mainflow-variable to a
   * subflow-input variable.
   */
  inputVariables: [],
  /**
   * outputVariables is simply a list of (strings of) the subflow variable names that user wish to
   * 'export' to mainflow. Relevant only for subflows
   */
  outputVariables: [],
  proactive: false,
  multipleChoice: {},
  routing: {},
  fallbackQuestionLimit: 0,
  signatureStarts: [],
  headerStarts: [],
  filteredLineStarts: [],
});

const initialState = makeInitialConfigState();

const configGetters = {
  getBotName(state) {
    return state.name;
  },
  getResponseDelayMode(state) {
    return state.responseDelays.mode;
  },
  getFixedDelay(state) {
    return state.responseDelays.fixedDelay;
  },
  getReadWpm(state) {
    return state.responseDelays.readWpm;
  },
  getBotThreshold(state) {
    return state.threshold;
  },
  isMainBot(state) {
    return state.type !== nodeTypes.SUBFLOW;
  },
  getUseInactiveNode(state) {
    return state.useInactiveNode;
  },
  getInactivityFromStart(state) {
    return state.inactivityFromStart;
  },
  getInactivity(state) {
    return state.inactivityPrompts;
  },
  getInterruptMode(state) {
    return state.interruptMode;
  },
  getNLUModels(state) {
    return state.nluModels;
  },
  getPlatforms(state) {
    return state.platforms;
  },
  isProactive(state) {
    return state.proactive;
  },
  getDescription(state) {
    return state.description;
  },
  getOutputVariables(state) {
    return state.outputVariables;
  },
  getInputVariables(state) {
    return state.inputVariables;
  },
  getMultipleChoiceApproved(state) {
    return state.multipleChoice.responseApproved;
  },
  getRoutingLanguage(state) {
    return state.routing.language;
  },
  getRoutingLabels(state) {
    return state.routing.labels;
  },
  getFallbackQuestionLimit(state) {
    return state.fallbackQuestionLimit;
  },
  getSignatureStarts(state) {
    return state.signatureStarts;
  },
  getHeaderStarts(state) {
    return state.headerStarts;
  },
  getFilteredLineStarts(state) {
    return state.filteredLineStarts;
  },
  wysiwygEnabled(state) {
    return state.platforms.some((e) => wysiwygPlatforms.includes(e));
  },
  includeOngoingByDefault(state) {
    // at the time of adding this, the wysiwygPlatforms represent 1-1 the platforms that would
    // have long-running conversations, but this could definitely change over time
    return state.platforms.some((e) => wysiwygPlatforms.includes(e));
  },
  usesWebhook(state) {
    return state.platforms.some((e) => webhookPlatforms.includes(e));
  },
};

const mutations = {
  setBotName(state, { name }) {
    state.name = name;
  },
  setResponseDelayMode(state, { mode }) {
    Vue.set(state.responseDelays, 'mode', mode);
  },
  setFixedDelay(state, { delay }) {
    Vue.set(state.responseDelays, 'fixedDelay', delay);
  },
  setReadWpm(state, { wpm }) {
    Vue.set(state.responseDelays, 'readWpm', wpm);
  },
  setBotThreshold(state, { threshold }) {
    state.threshold = threshold;
  },
  setUseInactiveNode(state, payload) {
    state.useInactiveNode = payload;
  },
  setNLUModels(state, nluModels) {
    state.nluModels = nluModels;
  },
  setMultipleChoice(state, val) {
    state.multipleChoice = val;
  },
  setInactivity(state, inactivityPrompts) {
    state.inactivityPrompts = inactivityPrompts;
  },
  setSingleInactivity(state, { index, inactivity }) {
    Vue.set(state.inactivityPrompts, index, inactivity);
  },
  setInactivityFromStart(state, inactivityFromStart) {
    state.inactivityFromStart = inactivityFromStart;
  },
  setConfig(state, config) {
    for (const [key, value] of Object.entries(config)) {
      Vue.set(state, key, value);
    }
    // TODO: In principle we should delete any values that were previously set, but are
    //  now removed. Maybe we should also do some validation.
  },
  resetConfigState(state) {
    setState(state, makeInitialConfigState());
  },
  setPlatforms(state, platforms) {
    state.platforms = platforms;
  },
  setProactive(state, { proactive }) {
    state.proactive = proactive;
  },
  setDescription(state, { description }) {
    state.description = description;
  },
  setInterruptMode(state, mode) {
    state.interruptMode = mode;
  },
  deleteInputVariable(state, variableName) {
    const indexToDelete = state.inputVariables.findIndex((x) => x.name === variableName);
    state.inputVariables.splice(indexToDelete, 1);
  },
  setSubflowInputVariable(state, { index, variable }) {
    if (index > state.inputVariables.length) {
      state.inputVariables.push(variable);
    } else {
      Vue.set(state.inputVariables, index, variable);
    }
  },
  setMultipleChoiceConfirmQuery(state, { query }) {
    state.multipleChoice.confirmQuery = query;
    state.multipleChoice.responseApproved = false;
  },
  setMultipleChoiceOtherText(state, { otherText }) {
    state.multipleChoice.otherText = otherText;
    state.multipleChoice.responseApproved = false;
  },
  setMultipleChoiceOtherDescription(state, { otherDescription }) {
    state.multipleChoice.otherDescription = otherDescription;
    state.multipleChoice.responseApproved = false;
  },
  setLimitOptions(state, { limitOptions }) {
    state.multipleChoice.limitOptions = limitOptions;
  },
  setMultipleChoiceOptionsQuery(state, { query }) {
    state.multipleChoice.optionsQuery = query;
    state.multipleChoice.responseApproved = false;
  },
  setMultipleChoiceApproved(state, { approved }) {
    state.multipleChoice.responseApproved = !!approved;
  },
  setMultipleChoiceYesNoModelName(state, { modelName }) {
    state.multipleChoice.yesNoModelName = modelName;
  },
  setRoutingLanguage(state, language) {
    state.routing.language = language;
  },
  setRoutingLabels(state, labels) {
    state.routing.labels = labels;
  },
  setFallbackQuestionLimit(state, limit) {
    state.fallbackQuestionLimit = limit;
  },
  setSignatureStartsWithHelper(state, startsUpdate) {
    state.signatureStarts = update(state.signatureStarts, startsUpdate);
  },
  setHeaderStartsWithHelper(state, startsUpdate) {
    state.headerStarts = update(state.headerStarts, startsUpdate);
  },
  setFilteredLineStartsWithHelper(state, startsUpdate) {
    state.filteredLineStarts = update(state.filteredLineStarts, startsUpdate);
  },

  /**
   * Updates the name of the output-variable at given index.
   * No validation is made here. Callers should ensure themselves that newName is a valid
   * variable name.
   */
  updateOutputVariable(state, { index, newName }) {
    state.outputVariables[index] = newName;
  },
  addOutputVariable(state, newOutputVariableName) {
    if (!state.outputVariables.includes(newOutputVariableName)) {
      state.outputVariables.push(newOutputVariableName);
    }
  },
  removeOutputVariable(state, toDeleteName) {
    state.outputVariables = state.outputVariables.filter((x) => x !== toDeleteName);
  },
};

const actions = {
  setNLUModels({ commit, rootGetters, state }, { models, idUpdated }) {
    commit('setNLUModels', models);
    // the idUpdated is used to specify which particular model was updated in the
    // array. If a node classifier was updated, then we want to update matching
    // on the relevant children
    if (!idUpdated) {
      return;
    }
    const globalModel = rootGetters['nlu/classifier/getGlobalNLUModels'][idUpdated];
    if (!(globalModel.botId && globalModel.nodeId)) {
      return;
    }
    const localModel = state.nluModels.find((x) => x.modelId === idUpdated);
    const modelVersion = globalModel.versions[localModel.modelVersion];
    const parent = rootGetters['botManipulation/activeBot/nodeById'](globalModel.nodeId);
    for (const label of modelVersion.labels) {
      if (!(parent.children.includes(label))) {
        continue;
      }
      commit(
        'botManipulation/activeBot/setNLUModelName',
        { id: label, nluModelName: localModel.name },
        { root: true },
      );
      commit(
        'botManipulation/activeBot/setNLULabel',
        { id: label, nluLabel: label },
        { root: true },
      );
    }
  },
};

const modules = {
  auth,
};
if (isVoicebotEnabled()) {
  modules.voice = voice;
}
if (isGenerativeAiEnabled()) {
  modules.generativeAI = generativeAI;
}

export default {
  namespaced: true,
  modules,
  state: initialState,
  getters: configGetters,
  mutations,
  actions,
};
