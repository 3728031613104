<template>
  <b-modal
    id="encryptionKeySecretModal"
    :title="modalTitle"
    :ok-title="okTitle"
    size="lg"
    :ok-disabled="!okActionIsOKToInvoke"
    @ok="createOrUpdateSecret"
    @show="prepModalContent"
  >
    <b-form>
      <b-form-group
        label="Name"
        label-for="secretName"
      >
        <b-form-input
          id="secretName"
          v-model="modalContent.secretName"
          autocomplete="off"
          placeholder="Display name for secret (can be changed)"
          type="text"
          :state="$v.modalContent.secretName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretName.required">
            You must provide a name for the secret
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.secretName.uniqueSecretName">
            The name is already in use for a secret for this bot.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Encryption Type"
        label-for="encryptionType"
      >
        <b-form-select
          id="encryptionType"
          v-model="modalContent.encryptionType"
          :options="supportedEncryptionTypes"
          :state="$v.modalContent.encryptionType.$invalid ? false : null"
          @input="() => userUpdatedEncryptionType = true"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.modalContent.encryptionType.required">
            You need to select an encryption type
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Secret (Encryption Key)"
        label-for="secretValueField"
      >
        <b-form-textarea
          id="secretValueField"
          v-model="modalContent.secretValue"
          autocomplete="off"
          style="font-family: monospace"
          rows="15"
          :placeholder="secretInputFieldPlaceholder"
          :state="$v.modalContent.secretValue.$invalid ? false : null"
          @input="() => userUpdatedSecretValue = true"
        />
        <b-form-invalid-feedback
          v-if="!$v.modalContent.secretValue.required"
        >
          You need to provide a secret in PEM format
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-else-if="!$v.modalContent.secretValue.pem"
        >
          The value is not a valid PEM format
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <template
      v-if="mode === 'update'"
    >
      <b-button
        variant="outline"
        @click="() => ui.revealSecretCollapseIsOpen = !ui.revealSecretCollapseIsOpen"
      >
        <font-awesome-icon
          v-if="ui.revealSecretCollapseIsOpen"
          icon="angle-up"
        />
        <font-awesome-icon
          v-else
          icon="angle-right"
        />
        Inspect current secret
      </b-button>
      <b-collapse
        v-model="ui.revealSecretCollapseIsOpen"
      >
        <code>
          {{ secretReveal.firstCharacter }}*****{{ secretReveal.lastCharacter }}
        </code>
      </b-collapse>
    </template>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'EncryptionKeySecretModal',
  mixins: [validationMixin],
  props: {
    // Mode is either: 'update' or 'create' and denotes whether to create a new secret or update an
    // existing secret. If mode is 'update', prop secretToUpdate must also be set.
    mode: {
      type: String,
      required: false,
      default: 'create',
    },
    secretToUpdate: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      ui: {
        revealSecretCollapseIsOpen: false,
      },
      modalContent: {
        secretName: null,
        encryptionType: null,
        secretValue: null,
      },
      secretReveal: {
        firstCharacter: null,
        lastCharacter: null,
      },
      // Keep hold of the initial name. Should only be set when modal is opened / prepared.
      secretNameUponShow: null,
      userUpdatedSecretValue: false,
      supportedEncryptionTypes: [
        { value: null, text: 'Please select an encryption type' },
        { value: 'jwe', text: 'JWE (JSON Web Encryption)' },
      ],
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    okActionIsOKToInvoke() {
      if (this.mode === 'update') {
        // Verify that we're actually about to update anything
        if (this.$v.modalContent.$invalid) {
          // The validations take into account if user has updated the secret value, so something
          // in modalContent _is not_ valid
          return false;
        }
        if (this.userUpdatedSecretValue
            || this.modalContent.secretName !== this.secretNameUponShow
            || this.modalContent.encryptionType !== this.encryptionTypeUponShow) {
          // User _did_ update the secret name
          return true;
        }
        // User did neither update secret value nor update the secret name -> do not allow user to
        // fire this update-request
        return false;
      }
      return !this.$v.modalContent.$invalid;
    },
    modalTitle() {
      if (this.mode === 'update') {
        return `Update encryption secret: ${this.secretToUpdate.name}`;
      }
      if (this.mode === 'create') {
        return 'Create encryption secret';
      }
      return '';
    },
    secretInputFieldPlaceholder() {
      if (this.mode === 'update' && !this.userUpdatedSecretValue) {
        return 'New secret value (leave as is to keep the secret value unchanged)';
      }
      if (this.mode === 'update') {
        return 'Supply new secret value (PEM format)';
      }
      return 'Secret value (PEM format)';
    },
    okTitle() {
      if (this.mode === 'update') {
        return 'Update';
      }
      if (this.mode === 'create') {
        return 'Create';
      }
      return '';
    },
  },
  async mounted() {
    await this.fetchSecrets();
  },
  methods: {
    ...mapActions('botSecrets', [
      'fetchSecrets',
      'createEncryptionKeySecret',
      'updateEncryptionKeySecret',
      'fetchPartiallyRevealedSecret',
    ]),
    async createOrUpdateSecret() {
      if (this.mode === 'update') {
        console.log(this.modalContent.secretValue);
        await this.updateEncryptionKeySecret({
          botId: this.getBotId,
          secretId: this.secretToUpdate.id,
          newSecretName: this.modalContent.secretName,
          newEncryptionType: this.modalContent.encryptionType,
          newSecretValue: this.modalContent.secretValue,
        });
      } else if (this.mode === 'create') {
        await this.createEncryptionKeySecret({
          botId: this.getBotId,
          secretName: this.modalContent.secretName,
          encryptionType: this.modalContent.encryptionType,
          secretValue: this.modalContent.secretValue,
        });
      }
    },
    async prepModalContent() {
      await this.fetchSecrets();
      this.userUpdatedSecretValue = false;

      if (this.mode === 'update') {
        this.modalContent.secretName = this.secretToUpdate.name;
        const prefix = 'encryption-';
        this.modalContent.encryptionType = this.secretToUpdate.secretType.slice(prefix.length);
        this.modalContent.secretValue = null;
        this.secretNameUponShow = this.secretToUpdate.name;
        this.encryptionTypeUponShow = this.modalContent.encryptionType;

        // Fetch info for partial reveal
        const response = await this.fetchPartiallyRevealedSecret({
          botId: this.getBotId,
          secretId: this.secretToUpdate.id,
        });

        this.secretReveal.firstCharacter = response.data.first_character;
        this.secretReveal.lastCharacter = response.data.last_character;
      } else {
        this.modalContent.secretName = null;
        this.modalContent.secretValue = null;
        this.modalContent.encryptionType = null;

        this.secretReveal.firstCharacter = null;
        this.secretReveal.lastCharacter = null;
      }
    },
  },
  validations: {
    modalContent: {
      secretName: {
        required,
        uniqueSecretName(suggestedValue) {
          const botSecrets = this.getBotSecrets(this.activeBotId);
          if (botSecrets === undefined) {
            return true;
          }
          if (suggestedValue === this.secretNameUponShow) {
            // Name hasn't changed from its original value
            return true;
          }
          const secretNames = botSecrets.map((x) => x.name);
          return !secretNames.includes(suggestedValue);
        },
      },
      encryptionType: {
        required,
      },
      secretValue: {
        required(newValue) {
          if (this.mode === 'update' && !this.userUpdatedSecretValue) {
            // User is updating an existing secret but she/he has not changed the secret value
            // (yet), so we're still in a valid state.
            return true;
          }
          return required(newValue);
        },
        pem(newValue) {
          if (this.$v.modalContent.secretValue.required) {
            return true;
          }
          if (!newValue) {
            return false;
          }
          const lines = newValue.split('\n');
          if (lines.length < 3) {
            return false;
          }
          // Check beginning and ending
          const first = lines.shift();
          if (!first.startsWith('-----BEGIN ') || !first.endsWith('-----')) {
            return false;
          }
          const label = first.substring(11, first.length - 5);
          let last = lines.pop();
          if (last === '') {
            last = lines.pop();
          }
          if (last !== `-----END ${label}-----`) {
            return false;
          }
          // Check payload
          if (!lines.map((x) => x.length).every((x) => x <= 64)) {
            return false;
          }
          const payload = lines.join('');
          return payload.length % 4 === 0 && /^[A-Za-z0-9+/]+={0,2}$/.test(payload);
        },
      },
    },
  },
};

</script>
