<template>
  <main
    role="main"
  >
    <b-card
      title="Global Nodes"
      class="r-75"
      body-class="p-3"
    >
      <p class="mb-2">
        <em>Global nodes</em> can be triggered from any point in the bot and are useful for
        handling cases like "I want to talk to a human", which can occur at any point during
        a conversation.
      </p>
      <p>
        You can also set a global node to be of type <em>small talk</em>,
        which will match and prompt a response, but without changing the flow of the conversation.
        This allow us to handle cases like
        "thanks" <font-awesome-icon icon="arrow-right" />
        "You're welcome" and then continue in the same flow as before.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <p class="mb-2">
        The following global nodes have special properties and are always part of the bot.
      </p>
      <hr>
      <b-row
        class="my-3"
        align-v="center"
      >
        <b-col cols="3">
          <b-btn
            block
            variant="warning"
            class="py-2"
            :to="editNodeLink('fallback')"
          >
            Fallback node
          </b-btn>
        </b-col>
        <b-col>
          The bot will go to the fallback node if there are no matches above the
          fallback threshold.
        </b-col>
      </b-row>
      <b-row
        class="my-3"
        align-v="center"
      >
        <b-col cols="3">
          <b-btn
            block
            variant="danger"
            class="py-2"
            :to="editNodeLink('error')"
          >
            Error node
          </b-btn>
        </b-col>
        <b-col>
          The bot will go to the error node if it experiences an internal error.
        </b-col>
      </b-row>
      <b-row
        class="my-3"
        align-v="center"
      >
        <b-col cols="3">
          <b-btn
            block
            variant="info"
            class="py-2"
            :disabled="disableInactivityBtn"
            :to="editNodeLink('inactive')"
          >
            Inactivity node
          </b-btn>
        </b-col>
        <b-col>
          The bot will go to the inactivity node after the last inactivity prompt if enabled
          in the
          <ConfigLink category="inactivity">
            Inactivity
          </ConfigLink>tab.
        </b-col>
      </b-row>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-list-group>
        <b-list-group-item
          v-for="node in globalNodes"
          :key="node.id"
          :to="editNodeLink(node.id)"
        >
          {{ node.name }}
        </b-list-group-item>
      </b-list-group>
      <b-btn
        block
        variant="primary"
        @click="addGlobalNode"
      >
        Add global node
      </b-btn>
    </b-card>
  </main>
</template>

<script>
import {
  mapGetters, mapActions,
} from 'vuex';
import { guidGenerator } from '@/js/utils';
import { nodeTypes } from '@/js/constants';
import ConfigLink from './BotConfig/ConfigLink.vue';

export default {
  name: 'GlobalNodes',
  components: { ConfigLink },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'customNode',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getUseInactiveNode',
    ]),
    globalNodes() {
      return Object.values(this.$store.state.botManipulation.activeBot.nodes).filter(
        (node) => node.options.global && node.options.nodeType !== nodeTypes.QA,
      );
    },
    disableInactivityBtn() {
      return !(this.getUseInactiveNode);
    },
  },
  methods: {
    ...mapActions('botManipulation', ['addNode']),
    addGlobalNode() {
      const newNode = JSON.parse(JSON.stringify(this.customNode));
      newNode.options.global = true;
      newNode.id = guidGenerator();
      newNode.name = 'Global node';
      this.addNode(newNode);
    },
  },
};
</script>
