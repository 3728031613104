import axios from 'axios';
import Vue from 'vue';
import levenshtein from 'js-levenshtein';
import endpoints from '@/js/urls';

const categoriesState = () => ({
  // A mapping from categoryId to a category object.
  // A category object is on the following form:
  // { id, datasetId, parentId, name }, where parentId might be null.
  categories: {},
  // A list of all category ids.
  categoryIds: [],
  autoFocus: false,
});

function compareCategories(a, b) {
  return a.name.localeCompare(b.name);
}

export const categoriesGetters = {
  getMainCategories(state) {
    const categoryIds = state.categoryIds.filter((id) => !state.categories[id].parentId);
    return categoryIds.map((id) => state.categories[id]);
  },
  getSubcategoriesFromParents: (state) => (parentIds) => {
    // parentIds should be a dictionary where the keys are the ids.
    const subcategoryChunks = {};
    for (const id of state.categoryIds) {
      const { parentId } = state.categories[id];
      if (Object.prototype.hasOwnProperty.call(parentIds, parentId)) {
        if (!Object.prototype.hasOwnProperty.call(subcategoryChunks, parentId)) {
          const parentCategory = state.categories[parentId];
          subcategoryChunks[parentId] = { ...parentCategory, children: [] };
        }
        subcategoryChunks[parentId].children.push(state.categories[id]);
      }
    }
    return Object.values(subcategoryChunks).sort(compareCategories);
  },
  // eslint-disable-next-line arrow-body-style
  getSubcategoriesForParent: (state) => (parentId) => {
    return Object.values(state.categories)
      .filter((x) => x.parentId !== null && x.parentId === parentId);
  },
  // depth = 0 is main category, and 1 is subcategory
  getCategoryDepth: (state) => (categoryId) => (state.categories[categoryId].parentId ? 1 : 0),
  // For a given parentId and a name finds all the categories with the same parent and names that
  // are similar to the given name. In this case similar means that the edit distance is at most 2.
  getSimilarNames: (state) => (datasetId, parentId, name) => {
    const matchingCategories = [];
    for (const categoryId of state.categoryIds) {
      const category = state.categories[categoryId];
      if (category.datasetId === datasetId && category.parentId === parentId
        && levenshtein(category.name, name) <= 2) {
        matchingCategories.push(category);
      }
    }
    return matchingCategories;
  },
  isNameUnique: (state) => (datasetId, parentId, name) => {
    const categories = Object.values(state.categories);
    const matchingCategory = categories.find(
      (category) => name === category.name && datasetId === category.datasetId
        && category.parentId === parentId,
    );
    return matchingCategory === undefined;
  },
  getCategoryById: (state) => (categoryId) => state.categories[categoryId],
  nameOfId: (state, getters) => (categoryId) => {
    const cat = getters.getCategoryById(categoryId);
    const parent = cat.parentId ? getters.getCategoryById(cat.parentId) : null;
    if (parent) {
      return `${parent.name} : ${cat.name}`;
    }
    return cat.name;
  },
};

/*
Conventions:
- The payload to any mutation or action must be a dict.
- The id of a category is always the key "categoryId".
- The id of a dataset is always the key "datasetId".
 */

const mutations = {
  setCategories(state, categories) {
    // By sorting the categories by name now all the getters, will return categories that
    // are sorted by name.
    const sortedCategories = [...categories].sort(compareCategories);
    const categoryDict = {};
    for (const category of sortedCategories) {
      categoryDict[category.id] = {
        id: category.id,
        datasetId: category.dataset_id,
        parentId: category.parent_id,
        name: category.name,
      };
    }
    state.categoryIds = sortedCategories.map(({ id }) => id);
    Vue.set(state, 'categories', categoryDict);
  },
  setAutoFocus(state, val) {
    state.autoFocus = val;
  },
};

const actions = {
  async fetchCategories({ rootState, commit, dispatch }, datasetId) {
    try {
      const resp = await axios.get(endpoints.dataExplorationCategories, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        params: { dataset_id: datasetId },
      });
      commit('setCategories', resp.data.categories);
    } catch (err) {
      if (err.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permissions to this dataset. You must ask a superuser to grant you access.',
          variant: 'warning',
        }, { root: true });
      }
      throw err;
    }
  },
  async deleteCategory({ rootState, dispatch }, { categoryId }) {
    try {
      await axios.delete(endpoints.dataExplorationCategories, {
        data: { id: categoryId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      await dispatch('fetchCategories', rootState.dataExploration.currentDatasetId);
    } catch (error) {
      if (error.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permission to change one or more classifiers pointing to this category.',
          variant: 'warning',
        }, { root: true });
      }
    }
  },
  async createCategory({ rootState, dispatch }, {
    parentId,
    name,
    visualizationId,
    topicId,
    numberOfTopicExamples,
  }) {
    const data = {
      dataset_id: rootState.dataExploration.currentDatasetId,
      parent_id: parentId,
      name,
      visualizationId,
      topicId,
      numberOfTopicExamples,
    };
    const resp = await axios.post(endpoints.dataExplorationCategories, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    await dispatch('fetchCategories', rootState.dataExploration.currentDatasetId);
    return resp.data.id;
  },
  async addTopicExamplesToCategory({ rootState, dispatch }, {
    categoryId,
    visualizationId,
    topicId,
    numberOfTopicExamples,
  }) {
    const data = {
      dataset_id: rootState.dataExploration.currentDatasetId,
      id: categoryId,
      visualizationId,
      topicId,
      numberOfTopicExamples,
    };
    try {
      await axios.put(endpoints.dataExplorationCategories, data, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'An error occured',
        text: 'Failed to add examples.',
        variant: 'danger',
      }, { root: true });
    }
  },
  async editCategory({ rootState, dispatch }, { categoryId, name }) {
    const data = { id: categoryId, name };
    await axios.put(endpoints.dataExplorationCategories, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    await dispatch('fetchCategories', rootState.dataExploration.currentDatasetId);
  },
};

export default {
  namespaced: true,
  state: categoriesState,
  getters: categoriesGetters,
  mutations,
  actions,
};
