<template>
  <div
    ref="collapseBtn"
    class="collapse-btn-wrapper"
    :style="compactView ? 'margin-top: -42px;' : 'margin-top: -18px;'"
    @keyup="onKeyUp"
  >
    <button
      type="button"
      class="collapse-btn"
      :class="[{
        'collapse-compact': compactView,
        'collapse-standard': !compactView,
      }]"
      @click="showList = !showList"
    >
      +{{ dropdownText }}
    </button>
    <div
      v-if="showList"
      class="graph-nodes-dropdown bg-white r-50 pt-1 mt-1"
      @mousedown="mouseDown = true"
      @mouseup="mouseUp"
      @mousemove="mouseMove"
    >
      <div class="px-2">
        <label class="text-secondary font-weight-bold mb-0">Visible nodes:</label>
        <b-form-group
          v-if="options.length > 10"
          class="mb-0"
        >
          <b-form-input
            v-model="keyword"
            size="sm"
            autofocus
            class="my-1"
            placeholder="Type to search"
          />
        </b-form-group>
      </div>
      <b-list-group
        class="options-wrapper"
        flush
      >
        <b-list-group-item
          class="py-1 px-2"
          @click="showAllClicked"
        >
          <font-awesome-icon
            class="mr-1"
            :icon="showAllSelected ? 'eye' : 'eye-slash'"
          />Show all
        </b-list-group-item>
        <b-list-group-item
          v-for="(option, index) in filteredOptions"
          :key="index"
          class="py-1 px-2"
          @click.stop="changeVisibleNodes(option.value)"
        >
          <font-awesome-icon
            :icon="selected.includes(option.value) ? 'eye' : 'eye-slash'"
            class="mr-1"
          />
          {{ option.text }}
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  name: 'CollapsedNodesButton',
  props: {
    config: {
      type: Object,
      required: true,
    },
    compactView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showList: false,
      keyword: '',
      // setting below values prevents accidental clicks when by moving graph
      mouseDown: false,
      dragging: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
      'nodeById',
    ]),
    dropdownText() {
      return this.options.filter((e) => !this.selected.includes(e.value)).length;
    },
    selected() {
      return this.config.selected.split(',');
    },
    filteredOptions() {
      return this.options.filter((e) => e.text.toLowerCase().includes(this.keyword.toLowerCase()));
    },
    options() {
      const childrenIds = this.nodeById(this.config.parentId).children;
      return childrenIds.map((e) => ({ text: this.nameOfId(e), value: e }));
    },
    showAllSelected() {
      return JSON.stringify(this.selected) === JSON.stringify(this.options.map((e) => e.value));
    },
  },
  mounted() {
    this.$root.$on('collapsedNodesButtonHide', () => {
      this.showList = false;
    });
  },
  methods: {
    showAllClicked() {
      if (!this.dragging) {
        this.$emit('toggleShowAll', !this.showAllSelected);
      }
    },
    changeVisibleNodes(value) {
      if (!this.dragging) {
        let copy = cloneDeep(this.selected);
        if (copy.includes(value)) {
          copy = copy.filter((v) => v !== value);
        } else {
          copy.push(value);
        }
        this.$emit('changeVisibleNodes', copy);
      }
    },
    mouseUp() {
      this.mouseDown = false;
      setTimeout(() => {
        this.dragging = false;
      }, 100);
    },
    mouseMove() {
      if (this.mouseDown) {
        this.dragging = true;
      }
    },
    onKeyUp(event) {
      if (event.keyCode === 27) {
        this.showList = false;
      }
      if (event.keyCode === 13) {
        this.$emit('toggleShowAll', !this.showAllSelected);
      }
    },
  },
};
</script>
<style scoped>
.collapse-btn-wrapper{
  z-index: 100 !important;
  width: 80px;
  height: 80px;
}
.collapse-btn{
  background-color: white;
  border: 2px solid rgb(161, 161, 161);
  border-radius: 3rem;
  font-weight: 500;
  margin: 0px;
  color: #111F2D;
}
.collapse-compact{
  height: 64px;
  font-size: 1.2rem;
  width: 64px;
}
.collapse-standard{
  font-size: 1.5rem;
  height: 80px;
  width: 80px;
}
.collapse-btn:hover{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.collapse-btn:hover{
  color: #111F2D;
}
.graph-nodes-dropdown{
  padding: 2px;
  text-overflow: ellipsis;
  position: absolute;
  top: -22px;
  left: 10px;
  display: flex;
  flex-flow: column;
  max-height: 200px;
  width: 250px !important;
  border: 2px solid rgb(161, 161, 161);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.options-wrapper{
  overflow-y: auto;
  overflow-x: hidden;
}
::v-deep .list-group-item {
  width: 250px !important;
  text-overflow: ellipsis;
}
::v-deep .list-group-item:hover{
  background-color: rgb(172, 223, 245) !important;
}

</style>
