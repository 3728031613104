<template>
  <!-- Modal for editing a rule -->
  <b-modal
    id="editActionModal"
    scrollable
    size="lg"
    ok-title="Save"
    :ok-disabled="$v.modalAction.$invalid"
    @ok="editActionOk"
    @show="prepareModal"
    @shown="focusOnNameInput"
  >
    <template slot="modal-title">
      <div v-if="isEdit">
        Edit integration
        <div class="small text-danger">
          Be aware that changing default values will not change existing integrations in the bot
        </div>
      </div>
      <div v-else>
        Add integration
      </div>
    </template>
    <form>
      <b-form-group
        label="Name"
        label-for="inputActionName"
      >
        <b-form-input
          id="inputActionName"
          ref="integrationNameInput"
          v-model="modalAction.name"
          :disabled="isEdit"
          type="text"
          class="form-control"
          placeholder="Name"
          :state="$v.modalAction.name.$invalid ? false : null"
          aria-describedby="actionNameFeedback"
        />
        <b-form-invalid-feedback id="actionNameFeedback">
          <div v-if="!$v.modalAction.name.required">
            Your integration must have a name. Integration names cannot be changed.
          </div>
          <div v-if="!$v.modalAction.name.uniqueName">
            Your integration name must be unique.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="REST endpoint"
        label-for="inputActionEndpoint"
        description="The REST API endpoint for the integration"
      >
        <b-form-input
          id="inputActionEndpoint"
          v-model="modalAction.endpoint"
          type="text"
          class="form-control"
          placeholder="End point"
          :state="$v.modalAction.endpoint.$invalid ? false : null"
          aria-describedby="actionEndpointFeedback"
        />
        <b-alert
          :show="endpointIsNotHttps"
          variant="warning"
        >
          Using non-https (non-encrypted) endpoints is discouraged in general. You're strongly
          advised against communicating confidential information to this non-https endpoint<br>
          Proceed only if you understand and accept this risk.
        </b-alert>
        <b-form-invalid-feedback id="actionEndpointFeedback">
          <div v-if="!$v.modalAction.endpoint.required">
            Supply the integration endpoint
          </div>
          <div v-else-if="!$v.modalAction.endpoint.isRequiredAndValid">
            The integration endpoint must start with http:// or https://
          </div>
          <div v-else-if="!$v.modalAction.endpoint.doesNotContainWhitespace">
            The integration endpoint is not allowed to contain spaces
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        variant="outline"
        @click="() => ui.basicConfigExpanded = !ui.basicConfigExpanded"
      >
        <font-awesome-icon
          :icon="ui.basicConfigExpanded ? 'angle-up' : 'angle-down'"
        />
        Basic integration configuration
      </b-button>
      <b-collapse
        v-model="ui.basicConfigExpanded"
      >
        <b-row
          class="mt-2"
        >
          <b-col
            cols="5"
            align-h="right"
          >
            <label>HTTP request type:</label>
          </b-col>
          <b-col cols="6">
            <b-form-select
              v-model="httpRequestType"
              :options="httpRequestTypes"
            />
          </b-col>
        </b-row>
        <b-row
          class="mt-2"
        >
          <b-col
            cols="5"
            align-h="right"
          >
            <label>Timeout (in seconds):</label>
          </b-col>
          <b-col cols="6">
            <b-form-input
              v-model="modalAction.timeoutValue"
              type="number"
              min="2"
              max="300"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="5"
            align-h="right"
          >
            <label>Allow appending value to endpoint path:</label>
          </b-col>
          <b-col cols="6">
            <b-form-checkbox
              v-model="modalAction.allowAppend"
              switch
            />
          </b-col>
        </b-row>
        <b-row
          v-if="modalAction.allowAppend"
          class="mt-1"
        >
          <b-col
            cols="5"
            align-h="right"
          >
            <label>Default append value:</label>
          </b-col>
          <b-col cols="6">
            <b-form-input
              v-model="modalAction.appendDefault"
              type="text"
              placeholder="Default value"
              class="text-monospace mb-2 mr-sm-2 mb-sm-0"
            />
          </b-col>
        </b-row>

        <b-form-group
          class="mt-2"
          label-for="inputExpectJson"
          label=""
          description="Check this box if the endpoint returns a JSON result"
        >
          <b-form-checkbox
            id="inputExpectJson"
            v-model="modalAction.expectJson"
          >
            Returns JSON object
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="modalAction.expectJson"
          class="mt-2"
          label-for="inputActionUseDataKey"
          label=""
          description="Check this box if the endpoint returns an object with a 'data' key and
                  if its related value should be bound to the response variable of the integration."
        >
          <b-form-checkbox
            id="inputActionUseDataKey"
            v-model="modalAction.useDataKey"
          >
            Use value of 'data' key
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="!modalAction.endpoint.startsWith('chatapi://')"
          class="mt-2"
          label-for="inputContinueOnError"
          label=""
          description="Check this box if the bot should continue its flow and return None if
            the endpoint does not answer with http success status (2XX).
            If disabled the bot will go to Error Node."
        >
          <b-form-checkbox
            id="inputContinueOnError"
            v-model="modalAction.continueOnError"
          >
            Continue if integration fails
          </b-form-checkbox>
        </b-form-group>
      </b-collapse>

      <!-- Headers -->
      <b-row>
        <b-col>
          <b-button
            variant="outline"
            @click="() => ui.headersTableExpanded = !ui.headersTableExpanded"
          >
            <font-awesome-icon
              :icon="ui.headersTableExpanded ? 'angle-up' : 'angle-down'"
            />
            Headers
          </b-button>
          <b-collapse
            v-model="ui.headersTableExpanded"
          >
            <b-table
              borderless
              outlined
              striped
              :fields="headersTableFields"
              :items="Object.entries($v.modalAction.headers.$each.$iter)"
              class="mb-0"
              show-empty
              empty-text="No headers defined for integration"
            >
              <template #cell(orgName)="row">
                <b-form-input
                  v-model="row.item[1].name.$model"
                  type="text"
                  placeholder="Header name"
                  :state="row.item[1].name.$invalid ? false : null"
                  :aria-describedby="`headerNameFeedback${row.item[0]}`"
                />
                <b-form-invalid-feedback
                  :id="`headerNameFeedback${row.item[0]}`"
                >
                  <div v-if="!row.item[1].name.required">
                    Your header must have a name.
                  </div>
                  <div v-if="!row.item[1].name.notDuplicate">
                    Header names should not be duplicated.
                  </div>
                </b-form-invalid-feedback>
              </template>

              <template #cell(defaultHeaderValue)="row">
                <template
                  v-if="row.item[1].secretReference.$model !== undefined"
                >
                  <b-form-select
                    :options="botSecretsForSelector"
                    :value="row.item[1].secretReference.$model.secretId"
                    :aria-describedby="`headerSecret${row.item[0]}`"
                    :state="row.item[1].secretReference.$invalid ? false : null"
                    @change="(secretId) => pickSecretToReference('headers', row.index, secretId)"
                  />
                  <b-form-invalid-feedback
                    :id="`headerSecret${row.item[0]}`"
                  >
                    <div v-if="!row.item[1].secretReference.hasNonNullSecretReferenceSet">
                      <div v-if="!botSecretsForSelector || !botSecretsForSelector.length">
                        Cannot pick a secret, as no secrets are defined.
                      </div>
                      <div v-else>
                        You must pick a secret
                      </div>
                    </div>
                  </b-form-invalid-feedback>
                </template>
                <b-form-input
                  v-else
                  :id="`headerDefault${row.item[0]}`"
                  v-model="row.item[1].default.$model"
                  type="text"
                  placeholder="Default value"
                  class="text-monospace"
                  :state="row.item[1].default.$invalid ? false : null"
                />
              </template>

              <template #cell(referenceSecret)="row">
                <b-form-checkbox
                  v-model="headersUsingSecrets"
                  :value="row.index"
                  switch
                  size="sm"
                  class="mt-2"
                  @change="(selectedIndices) => toggleSecretSelector('headers', selectedIndices)"
                />
              </template>

              <template #cell(actions)="row">
                <span
                  class="ml-2"
                >
                  <b-btn
                    class="ml-auto"
                    variant="outline-danger"
                    @click="removeActionHeader(row.index)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </b-btn>
                </span>
              </template>

              <template #custom-foot>
                <b-tr
                  style="text-align: center"
                >
                  <b-td
                    colspan="4"
                  >
                    <b-btn
                      variant="primary"
                      @click="addActionHeader()"
                    >
                      {{ modalAction.headers.length === 0 ? 'Add a header' : 'Add another header' }}
                    </b-btn>
                  </b-td>
                </b-tr>
              </template>
            </b-table>
          </b-collapse>
        </b-col>
      </b-row>

      <!-- Parameters -->
      <b-row>
        <b-col>
          <b-button
            variant="outline"
            @click="() => ui.parametersTableExpanded = !ui.parametersTableExpanded"
          >
            <font-awesome-icon
              :icon="ui.parametersTableExpanded ? 'angle-up' : 'angle-down'"
            />
            Parameters
          </b-button>
          <b-collapse
            id="parametersTable"
            v-model="ui.parametersTableExpanded"
          >
            <b-alert
              :show="modalAction.httpRequestType === 'GET'
                && !modalAction.useFormEncoding
                && paramsUsingSecrets.length > 0"
              variant="danger"
            >
              The use of secrets in query-parameters for GET-requests is discouraged. Proceed only
              if you understand and accept this risk.
            </b-alert>
            <b-table
              borderless
              outlined
              striped
              class="mb-0"
              :items="Object.entries($v.modalAction.params.$each.$iter)"
              :fields="paramsTableFields"
              show-empty
              empty-text="No parameters defined for integration"
            >
              <template #cell(orgName)="row">
                <b-form-input
                  v-model="row.item[1].name.$model"
                  type="text"
                  placeholder="Parameter name"
                  :state="row.item[1].name.$invalid ? false : null"
                  :aria-describedby="`paramNameFeedback${row.item[0]}`"
                />
                <b-form-invalid-feedback
                  :id="`paramNameFeedback${row.item[0]}`"
                >
                  <div v-if="!row.item[1].name.required">
                    Your parameter must have a name.
                  </div>
                  <div v-if="!row.item[1].name.notDuplicate">
                    Parameter names should not be duplicated.
                  </div>
                </b-form-invalid-feedback>
              </template>

              <template #cell(defaultParameterValue)="row">
                <template
                  v-if="row.item[1].secretReference.$model !== undefined"
                >
                  <b-form-select
                    :options="botSecretsForSelector"
                    :value="row.item[1].secretReference.$model.secretId"
                    :aria-describedby="`paramSecret${row.item[0]}`"
                    :state="row.item[1].secretReference.$invalid ? false : null"
                    @change="(secretId) => pickSecretToReference('params', row.index, secretId)"
                  />
                  <b-form-invalid-feedback
                    :id="`paramSecret${row.item[0]}`"
                  >
                    <div v-if="!row.item[1].secretReference.hasNonNullSecretReferenceSet">
                      You must pick a secret
                    </div>
                  </b-form-invalid-feedback>
                </template>
                <b-form-input
                  v-else
                  :id="`inputParamDefault${row.item[0]}`"
                  v-model="row.item[1].default.$model"
                  type="text"
                  placeholder="Default value"
                  class="text-monospace"
                  :state="row.item[1].default.$invalid ? false : null"
                />
              </template>

              <template
                #cell(referenceSecret)="row"
              >
                <b-form-checkbox
                  v-model="paramsUsingSecrets"
                  :value="row.index"
                  class="mt-2"
                  switch
                  size="sm"
                  @input="(selectedIndices) => toggleSecretSelector('params', selectedIndices)"
                />
              </template>

              <template #cell(actions)="row">
                <span
                  class="ml-2"
                >
                  <b-btn
                    class="ml-auto"
                    variant="outline-danger"
                    @click="removeActionParam(row.index)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </b-btn>
                </span>
              </template>

              <template #custom-foot>
                <b-tr
                  style="text-align: center"
                >
                  <b-td
                    colspan="4"
                  >
                    <b-btn
                      variant="primary"
                      @click="addActionParam()"
                    >
                      {{ modalAction.params.length === 0
                        ? 'Add a parameter' : 'Add another parameter' }}
                    </b-btn>
                  </b-td>
                </b-tr>
              </template>
            </b-table>
            <b-form-group
              class="mt-2"
              label-for="inputUseFormEncoding"
              label=""
              description="Check this box if the parameters should be encoded in the body"
            >
              <b-form-checkbox
                id="inputUseFormEncoding"
                v-model="modalAction.useFormEncoding"
              >
                Use content-type www-form-encoding.
              </b-form-checkbox>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>

      <hr>

      <!-- Mock response -->
      <b-row>
        <b-col>
          <b-button
            variant="outline"
            @click="() => ui.mockResponseExpanded = !ui.mockResponseExpanded"
          >
            <font-awesome-icon
              :icon="ui.mockResponseExpanded ? 'angle-up' : 'angle-down'"
            />
            Set or inspect mock response
          </b-button>
          <b-collapse
            v-model="ui.mockResponseExpanded"
          >
            <b-form-group
              label-for="inputActionMockResponse"
              label="Mock response"
            >
              <b-form-textarea
                id="inputActionMockResponse"
                v-model="modalAction.mockResponse"
                class="form-control text-monospace"
                :state="$v.modalAction.mockResponse.$invalid ? false : null"
                aria-describedby="mockResponseFeedback"
                placeholder="{ &quot;data&quot;: &quot;value&quot; }"
              />
              <b-form-invalid-feedback id="mockResponseFeedback">
                <div v-if="!$v.modalAction.mockResponse.required">
                  Your integration must have a mock response.
                </div>
              </b-form-invalid-feedback>
              <b-form-text>
                The REST API mock response is for testing in development.
                This must be valid JSON containing the data,
                or valid
                <b-link
                  target="_blank"
                  :to="{ name: 'scripthelp' }"
                >
                  BotStudio Script Language
                </b-link> evaluating to JSON.
                Note that this is the default mock response,
                and only used if the individual use case does not define one.
              </b-form-text>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>

      <!-- Test and preview -->
      <b-row>
        <b-col>
          <b-button
            variant="outline"
            class="mb-1"
            @click="() => ui.testIntegrationExpanded = !ui.testIntegrationExpanded"
          >
            <font-awesome-icon
              :icon="ui.testIntegrationExpanded ? 'angle-up' : 'angle-down'"
            />
            Test and preview integration
          </b-button>
          <b-collapse
            v-model="ui.testIntegrationExpanded"
          >
            <b-row
              v-for="(v, key) in modalAction.localVariables"
              :key="`variable${key}`"
              class="mb-1 px-2"
            >
              <b-col
                cols="2"
                class="my-auto text-right pr-0"
              >
                <label class="my-auto text-break">{{ key }}</label>
              </b-col>
              <b-col
                cols="10"
                class="my-auto"
              >
                <b-form-input
                  v-model="modalAction.localVariables[key]"
                  size="sm"
                />
              </b-col>
            </b-row>

            <div class="form-row mt-3 justify-content-center">
              <b-btn
                v-b-tooltip.hover
                :variant="modalAPITestCallVariant"
                title="Executes the API call using the default parameters and displays return value"
                @click="domodalAPITestCall()"
              >
                Test integration
              </b-btn>
              <b-btn
                v-b-tooltip.hover
                class="ml-2"
                variant="primary"
                :disabled="disallowUsingResponseAsMockResponse"
                title="Set this response as mock response"
                @click="useResponseAsMockResponse()"
              >
                Use as mock response
              </b-btn>
              <b-btn
                class="ml-2"
                :variant="previewRequestVariant"
                @click="showRequestPreview = !showRequestPreview"
              >
                {{ !showRequestPreview ? 'Preview request' : 'Hide preview' }}
              </b-btn>
            </div>
            <b-collapse
              v-model="showRequestPreview"
            >
              <b-card
                body-class="p-1"
                class="bg-light my-1"
              >
                <vue-json-pretty
                  :data="JSON.parse(dryrunAPICall)"
                />
              </b-card>
            </b-collapse>

            <b-card
              v-if="modalAPITestCall"
              body-class="p-1"
              class="bg-light my-1"
            >
              <vue-json-pretty :data="JSON.parse(modalAPITestCallAdjusted)" />
            </b-card>
            <!-- Intentionally duplicating below checkbox, because it here is very tangible
              what the effect is of toggling the useDataKey
             -->
            <b-form-group
              v-if="modalAPITestCall"
              description="Check this box if the endpoint returns an object with a 'data' key and
                      if its related value should be bound to the response variable of the
                      integration."
            >
              <b-form-checkbox
                v-model="modalAction.useDataKey"
              >
                Use value of 'data' key
              </b-form-checkbox>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>

      <div v-if="!isMainBot">
        <small>This is a stand-in integration, which can be replaced
          by a real one when using the SubFlow in the bot.</small>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import cloneDeep from 'lodash/cloneDeep';
import { required } from 'vuelidate/lib/validators';
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import axios from 'axios';
import { debounce } from 'lodash';
import Vue from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { splitString } from 'supwiz/util/text';
import { getVariables } from 'supwiz/botscript/parser';
import endpoints from '@/js/urls';

export default {
  name: 'ApiActionModal',
  components: { VueJsonPretty },
  mixins: [validationMixin],
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    editId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      ui: {
        headersTableExpanded: false,
        parametersTableExpanded: false,
        testIntegrationExpanded: false,
        mockResponseExpanded: false,
        requestPreviewExpanded: false,
        basicConfigExpanded: false,
      },
      modalAPITestCall: null,
      modalAPITestCallVariant: 'warning',
      // modalAPITestCallErrored tri-state: null (not executed yet), false (error), true (no error)
      modalAPITestCallErrored: null,
      latestVariableRequest: null,
      previewRequestVariant: 'success',
      showRequestPreview: false,
      dryrunAPICall: null,
      debouncer: null,
      modalAction: {
        name: '',
        endpoint: '',
        httpRequestType: 'GET',
        expectJson: true,
        headers: [],
        params: [],
        useFormEncoding: false,
        localVariables: {},
        timeoutValue: 10,
        allowAppend: false,
        appendDefault: '',
        useDataKey: false,
        continueOnError: false,
        mockResponse: '{ "data": null }',
      },
      httpRequestTypes: [
        'GET',
        'POST',
        'PUT',
        'PATCH',
        'DELETE',
      ],
      headersTableFields: [
        {
          key: 'orgName',
          label: 'Header name',
          tdClass: 'align-top',
        },
        {
          key: 'defaultHeaderValue',
          label: 'Default header value',
          tdClass: 'align-top',
        },
        {
          key: 'referenceSecret',
          label: 'Use secret',
          tdClass: 'align-top pr-0',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-top pl-0',
        },
      ],
      paramsTableFields: [
        {
          key: 'orgName',
          label: 'Parameter name',
          tdClass: 'align-top',
        },
        {
          key: 'defaultParameterValue',
          label: 'Default parameter value',
          tdClass: 'align-top',
        },
        {
          key: 'referenceSecret',
          label: 'Use secret',
          tdClass: 'align-top pr-0',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-top pl-0',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapGetters('botManipulation', [
      'getBotActions',
      'getBotId',
      'getBotActionByName',
    ]),
    ...mapGetters('botSecrets', [
      'getBotSecrets',
    ]),
    ...mapState('botManipulation', [
      'activeBotId',
    ]),
    disallowUsingResponseAsMockResponse() {
      return this.modalAPITestCall === null
        || this.modalAPITestCallErrored !== false;
    },
    /**
     * Returns a list of options for a dropdown picker
     * It is currently not decided if subflows should be able to declare their own secrets.
     * Hence for now, subflows should also use mainbot's secrets.
     */
    botSecretsForSelector() {
      // Passing this.activeBotId (id of mainbot) to getBotSecrets ensures we always get back
      // mainbot's secrets.
      const botSecrets = this.getBotSecrets(this.activeBotId);
      if (botSecrets === undefined) {
        return [];
      }
      const secretsMapped = botSecrets.map((botSecret) => ({
        value: botSecret.id,
        text: `🔑${botSecret.name}`,
      }));
      secretsMapped.unshift({ value: null, text: 'Choose a secret' });
      return secretsMapped;
    },
    endpointIsNotHttps() {
      return this.modalAction.endpoint.startsWith('http://');
    },
    modalAPITestCallAdjusted() {
      let parsedAsObject;
      try {
        parsedAsObject = JSON.parse(this.modalAPITestCall);
      } catch (error) {
        // Something is off, we can't do better than show what frontend received
        return JSON.stringify(this.modalAPITestCall, null, 2);
      }
      if (this.modalAction.useDataKey) {
        if (parsedAsObject.data === undefined) {
          return `<Error: no data key on response> \nRaw response: \n\n${this.modalAPITestCall}`;
        }
        return JSON.stringify(parsedAsObject.data, null, 2);
      }
      // To be consistent with the formatting that is used when useDataKey is true, we format the
      // same way herefor returned string
      return JSON.stringify(parsedAsObject, null, 2);
    },
    httpRequestType: {
      get() {
        return this.modalAction.httpRequestType;
      },
      set(newValue) {
        this.modalAction.httpRequestType = newValue;
      },
    },
    defaultHeaders() {
      if (!(this.modalAction && this.modalAction.headers)) {
        return {};
      }
      const headers = {};
      for (let i = 0; i < this.modalAction.headers.length; i += 1) {
        const header = this.modalAction.headers[i];
        if (header.secretReference !== undefined) {
          headers[header.name] = header.secretReference;
        } else {
          headers[header.name] = header.default;
        }
      }
      return headers;
    },
    defaultParameters() {
      if (!(this.modalAction && this.modalAction.params)) {
        return {};
      }
      const params = {};
      for (let i = 0; i < this.modalAction.params.length; i += 1) {
        const param = this.modalAction.params[i];
        if (param.secretReference !== undefined) {
          params[param.name] = param.secretReference;
        } else {
          params[param.name] = param.default;
        }
      }
      return params;
    },
    paramsUsingSecrets: {
      get() {
        const indices = [];
        this.modalAction.params.forEach((entry, index) => {
          if (entry.secretReference !== undefined) {
            indices.push(index);
          }
        });
        return indices;
      },
      set() {
        // Intentionally not implemented; when setting/unsetting a param to use secret or not, we
        // do this by reacting to input event
      },
    },
    headersUsingSecrets: {
      get() {
        const indices = [];
        this.modalAction.headers.forEach((entry, index) => {
          if (entry.secretReference !== undefined) {
            indices.push(index);
          }
        });
        return indices;
      },
      set() {
        // Intentionally not implemented; when setting/unsetting a param to use secret or not, we
        // do this by reacting to input event
      },
    },
    chosenSecretForParameter() {
      return (parameterIndex) => this.modalAction.params[parameterIndex].secretReference.secretId;
    },
    chosenSecretForHeader() {
      return (headerIndex) => {
        const secretId = this.modalAction.headers[headerIndex].secretReference.secretId;
        return secretId;
      };
    },
    usedVariables() {
      const variables = new Set();
      function addVars(script) {
        for (const variable of getVariables(script)) {
          variables.add(variable);
        }
      }
      for (const script of splitString(this.modalAction.endpoint, 'code', 'best-effort')) {
        addVars(script);
      }
      if (this.modalAction.allowAppend) {
        addVars(this.modalAction.appendDefault);
      }
      this.modalAction.headers.forEach((entry) => {
        if (entry.secretReference === undefined) {
          addVars(entry.default);
        }
      });
      this.modalAction.params.forEach((entry) => {
        if (entry.secretReference === undefined) {
          addVars(entry.default);
        }
      });
      return variables;
    },
  },
  watch: {
    modalAction: {
      handler() {
        // Whenever content change queue an invocation
        this.debouncer();
      },
      deep: true,
    },
    usedVariables: {
      handler() {
        const newVariables = {};
        for (const variable of this.usedVariables) {
          newVariables[variable] = this.modalAction.localVariables[variable] || '';
        }
        Vue.set(this.modalAction, 'localVariables', newVariables);
      },
    },
  },
  created() {
    this.debouncer = debounce(this.modalChanged, 200, { leading: false, trailing: true });
  },
  destroyed() {
    this.debouncer.cancel();
  },
  methods: {
    ...mapMutations('botManipulation', [
      'addBotAction',
      'editBotAction',
      'deleteBotAction',
    ]),
    pickSecretToReference(headersOrParams, index, secretId) {
      const currentDefinition = this.modalAction[headersOrParams][index];
      currentDefinition.secretReference.secretId = secretId;
      Vue.set(this.modalAction[headersOrParams], index, currentDefinition);
    },
    async modalChanged() {
      await this.getPreppedRequest();
    },
    async getPreppedRequest() {
      if (this.$v.modalAction.$invalid) {
        return;
      }
      const data = {
        endpoint: this.modalAction.endpoint,
        requestType: this.modalAction.httpRequestType,
        headers: this.defaultHeaders,
        parameters: this.defaultParameters,
        useFormEncoding: this.modalAction.useFormEncoding,
        timeoutValue: this.modalAction.timeoutValue,
        allowAppend: this.modalAction.allowAppend,
        appendDefault: this.modalAction.appendDefault,
        localDict: this.modalAction.localVariables,
      };
      try {
        const response = await axios.post(endpoints.previewAPICall, data, {
          params: {
            bot_id: this.activeBotId,
          },
          headers: {
            Authorization: `JWT ${this.$store.state.auth.jwt}`,
          },
        });
        const result = JSON.stringify(response.data, null, 2);
        if (response.data.error === undefined) {
          this.previewRequestVariant = 'success'; // Signal it went well
          this.dryrunAPICall = result.replace(/&/g, '&amp;');
        } else {
          this.previewRequestVariant = 'outline-warning'; // Signal it not went well
          this.dryrunAPICall = JSON.stringify({ message: response.data.error }, null, 2);
        }
      } catch {
        this.previewRequestVariant = 'danger'; // Signal it went bad
        this.dryrunAPICall = JSON.stringify({
          message: 'An unhandled error in BotStudio backend occurred.',
        }, null, 2);
      }
    },
    domodalAPITestCall() {
      this.modalAPITestCall = null;
      this.modalAPITestCallVariant = 'warning';
      this.modalAPITestCallErrored = false;
      const data = {
        endpoint: this.modalAction.endpoint,
        requestType: this.modalAction.httpRequestType,
        expectJson: this.modalAction.expectJson,
        headers: this.defaultHeaders,
        parameters: this.defaultParameters,
        useFormEncoding: this.modalAction.useFormEncoding,
        timeoutValue: this.modalAction.timeoutValue,
        allowAppend: this.modalAction.allowAppend,
        appendDefault: this.modalAction.appendDefault,
        localDict: this.modalAction.localVariables,
      };
      axios.post(endpoints.invokeIntegrationTestCall, data, {
        params: {
          bot_id: this.activeBotId,
        },
        headers: {
          Authorization: `JWT ${this.$store.state.auth.jwt}`,
        },
      }).then((response) => {
        this.modalAPITestCall = JSON.stringify(response.data.api_response, null, 2);
        this.modalAPITestCallVariant = 'success';
        this.modalAPITestCallErrored = false;
      }).catch((error) => {
        this.modalAPITestCall = JSON.stringify(error.response.data, null, 2);
        this.modalAPITestCallVariant = 'danger';
        this.modalAPITestCallErrored = true;
      });
    },
    editActionOk() {
      // As it is now the button that calls this function cannot be clicked
      // if the form is invalid, but for good measure we make sure that the
      // function cannot be called anyways.
      if (this.$v.modalAction.$invalid) {
        return;
      }
      // The modal is used for both editing and adding. Check which case we're in.
      if (this.isEdit) {
        this.editBotAction({ action: this.modalAction });
      } else {
        this.addBotAction({ action: this.modalAction });
      }
    },
    addActionParam() {
      this.modalAction.params.push({ name: '', default: '' });
    },
    removeActionParam(index) {
      this.modalAction.params.splice(index, 1);
    },
    /**
     * Toggle a header or parameter to reference a secret or not.
     * headersOrParams argument must match the name used in bot-definition
     * (currently 'headers' or 'params'). Doing so relieves us from code-duplication for toggling
     * referencing a secret on a header and on a parameter.
     */
    toggleSecretSelector(headersOrParams, checkedIndices) {
      let i = 0;
      for (i = 0; i < this.modalAction[headersOrParams].length; i++) {
        if (checkedIndices.includes(i)
          && this.modalAction[headersOrParams][i].secretReference === undefined) {
          // Header at index <i> should use a secret, but does not do so currently
          Vue.set(this.modalAction[headersOrParams][i], 'secretReference', { secretId: null });
        } else if (!checkedIndices.includes(i)
          && this.modalAction[headersOrParams][i].secretReference !== undefined) {
          // Header at index <i> should not use a secret, but does so currently
          Vue.delete(this.modalAction[headersOrParams][i], 'secretReference');
        }
      }
    },
    addActionHeader() {
      this.modalAction.headers.push({ name: '', default: '' });
    },
    removeActionHeader(idx) {
      this.modalAction.headers.splice(idx, 1);
    },
    prepareModal() {
      this.ui.headersTableExpanded = false;
      this.ui.parametersTableExpanded = false;
      this.ui.testIntegrationExpanded = false;
      this.ui.mockResponseExpanded = false;
      this.ui.requestPreviewExpanded = false;
      this.ui.basicConfigExpanded = false;
      this.modalAPITestCall = null;
      this.dryrunAPICall = null;
      this.showRequestPreview = false;

      if (this.isEdit) {
        // We must use a deep copy for the modal otherwise we end up editing even if we want to
        // cancel
        this.modalAction = cloneDeep(this.getBotActionByName(this.editId));
        this.modalAction.orgName = this.modalAction.name;
        for (let i = 0; i < this.modalAction.params.length; i += 1) {
          const param = this.modalAction.params[i];
          param.orgName = param.name;
          param.orgDefault = param.default;
        }
        for (let i = 0; i < this.modalAction.headers.length; i += 1) {
          const header = this.modalAction.headers[i];
          header.orgName = header.name;
          header.orgDefault = header.default;
        }
        this.modalAction.orgExpectJson = this.modalAction.expectJson;
        this.modalAction.orgAllowAppend = this.modalAction.allowAppend;
        this.modalAction.orgUseFormEncoding = this.modalAction.useFormEncoding;
        Vue.set(this.modalAction, 'localVariables', {});
      } else {
        // Set the action to an empty one that we can define.
        this.modalAction = {
          name: '',
          endpoint: '',
          httpRequestType: 'GET',
          expectJson: true,
          headers: [],
          params: [],
          useFormEncoding: false,
          timeoutValue: 10,
          allowAppend: false,
          appendDefault: '',
          useDataKey: false,
          mockResponse: '{ "data": null }',
          localVariables: {},
        };
      }
    },
    useResponseAsMockResponse() {
      this.modalAction.mockResponse = this.modalAPITestCall;
    },
    focusOnNameInput() {
      this.$refs.integrationNameInput.focus();
    },
  },
  validations: {
    modalAction: {
      name: {
        required,
        uniqueName(value) {
          if (this.isEdit && value === this.modalAction.orgName) {
            return true;
          }
          const foundAction = this.getBotActionByName(value);
          return foundAction === undefined;
        },
      },
      endpoint: {
        required,
        doesNotContainWhitespace: (value) => (value ? value.indexOf(' ') < 0 : true),
        isRequiredAndValid(value) {
          if (this.isMainBot) {
            const httpRegex = /^(?:http(?:s)?|chatapi):\/\/.+/i;
            return value ? value.length > 0 && value.match(httpRegex) !== null : false;
          }
          return true;
        },
      },
      mockResponse: {
        required,
      },
      headers: {
        $each: {
          name: {
            required,
            notDuplicate(value) {
              let numDuplicates = 0;
              for (let i = 0; i < this.modalAction.headers.length; i += 1) {
                const { name } = this.modalAction.headers[i];
                if (name === value) {
                  numDuplicates += 1;
                }
              }
              // We are always a duplicate of ourselves.
              return numDuplicates <= 1;
            },
          },
          secretReference: {
            hasNonNullSecretReferenceSet(secretReference) {
              if (secretReference === undefined) {
                // This check does not apply, as the user does not wish to reference a secret for
                // this parameter at all - just signal 'all ok'
                return true;
              }
              return secretReference.secretId !== null;
            },
          },
          orgName: {},
          default: {},
        },
      },
      params: {
        $each: {
          name: {
            required,
            notDuplicate(value) {
              let numDuplicates = 0;
              for (let i = 0; i < this.modalAction.params.length; i += 1) {
                const { name } = this.modalAction.params[i];
                if (name === value) {
                  numDuplicates += 1;
                }
              }
              // We are always a duplicate of ourselves.
              return numDuplicates <= 1;
            },
          },
          orgName: {},
          default: {},
          secretReference: {
            hasNonNullSecretReferenceSet(secretReference) {
              if (secretReference === undefined) {
                // This check does not apply, as the user does not wish to reference a secret for
                // this parameter at all - just signal 'all ok'
                return true;
              }
              return secretReference.secretId !== null;
            },
          },
        },
      },
    },
  },
};
</script>
