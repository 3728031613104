<template>
  <div>
    <p class="mb-2">
      The <strong>hygiene health checks</strong> have received a makeover to provide you
      with great suggestions on how to improve your bot. This includes a significant improvement
      of the link checker, which should now provide much fewer false positives.
    </p>
    <div class="text-center">
      <b-img
        class="w-75"
        fluid
        :src="require(`@/assets/featureImages/hygiene_checks.png`)"
        alt="Example of the new hygiene check look-and-feel"
      />
    </div>
  </div>
</template>
<script>
export default { name: 'HygieneChecksReviewedDescription' };
</script>
