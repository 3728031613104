<template>
  <div class="d-flex flex-column">
    <b-row
      v-if="variant"
      no-gutters
    >
      <b-col>
        <b-form-group
          label="Variant"
          label-class="pb-0"
          class="mb-1"
        >
          <b-overlay :show="variantOptions === null">
            <b-form-select
              v-model="selectedVariant"
              :options="variantOptions"
            />
          </b-overlay>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col
        v-if="groupBy"
        :class="lang ? 'pr-1' : ''"
      >
        <b-form-group
          label="Group by"
          label-class="pb-0"
          class="mb-0"
        >
          <b-form-select
            v-model="selectedGroupBy"
            :options="groupByOptions"
            :disabled="disabled"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="lang">
        <b-form-group
          label="Language"
          label-class="pb-0"
          class="mb-0"
        >
          <b-dropdown
            no-flip
            block
            no-caret
            toggle-class="custom-select bg-white text-secondary text-left"
            menu-class="bg-white"
            @hide="filter = ''"
          >
            <template #button-content>
              <span class="btn-content">
                {{ selectedLanguage }}
              </span>
            </template>
            <b-dropdown-item @click.native.capture.stop>
              <b-form-input
                v-model="filter"
                placeholder="Type to search"
                class="bg-white"
              />
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-group class="lang-selector">
              <b-dropdown-item
                v-for="(item, index) in filteredLanguageOptions"
                :key="index"
                class="text-dark"
                @click="selectedLanguage = item.value"
              >
                {{ item.text }}
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </b-form-group>
      </b-col>
    </b-row>
    <div
      v-if="date"
      class="d-flex flex-column"
      :class="lang ? 'mt-3' : ''"
    >
      <b-button-group
        v-if="shortcuts"
        class="d-flex flex-wrap"
      >
        <b-button
          v-for="{ text, onClick } in dateShortcuts"
          :key="text"
          :disabled="disabled"
          size="sm"
          variant="light"
          class="flex-grow-1"
          @click="onClick"
        >
          {{ text }}
        </b-button>
      </b-button-group>
      <div class="d-flex mt-2">
        <div class="flex-grow-1 mr-1">
          Start date:
          <datepicker
            v-model="startDate"
            :disabled="disabled"
            :bootstrap-styling="true"
            :monday-first="true"
            :disabled-dates="startDatesDisabled"
            :highlighted="startDatesHighlighted"
          />
        </div>
        <div class="flex-grow-1 ml-1">
          End date:
          <datepicker
            v-model="endDate"
            :disabled="disabled"
            :bootstrap-styling="true"
            :monday-first="true"
            :disabled-dates="endDatesDisabled"
            :highlighted="endDatesHighlighted"
          />
        </div>
      </div>
    </div>
    <div
      v-if="time"
      class="d-flex mt-3"
    >
      <div class="flex-grow-1 mr-1">
        Start-time
        <div>
          <b-form-input
            v-model="startTime"
            :disabled="disabled"
            type="range"
            min="0"
            :max="maxTime"
            step="0.5"
            number
          />
          {{ formattedTime(startTime) }}
        </div>
      </div>
      <div class="flex-grow-1 ml-1">
        End-time:
        <div>
          <b-form-input
            v-model="endTime"
            :disabled="disabled"
            type="range"
            min="0"
            :max="maxTime"
            step="0.5"
            number
          />
          {{ formattedTime(endTime) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import getLanguageName from '@/js/languageMap';

export default {
  name: 'DateTimeLangPicker',
  components: {
    Datepicker,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Boolean,
      default: false,
    },
    groupBy: {
      type: Boolean,
      default: false,
    },
    groupByOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    lang: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Boolean,
      default: false,
    },
    combinedDatetime: {
      type: Boolean,
      default: false,
    },
    shortcuts: {
      type: Boolean,
      default: false,
    },
    getter: {
      type: Object,
      required: true,
    },
    setter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      filter: '',
    };
  },
  computed: {
    ...mapGetters('chatlogs', [
      'availableLanguages',
      'availableVariants',
    ]),
    ...mapGetters('variants', [
      'getNameFromId',
    ]),
    variantOptions() {
      if (!this.variant || this.availableVariants === null) {
        return null;
      }
      const variants = this.availableVariants.map((x) => (
        { value: x, text: this.getNameFromId(x) || `Deleted variant (${x})` }
      ));
      variants.unshift({ value: null, text: 'No variant (main bot)' });
      variants.unshift({ value: '', text: 'Any variant' });
      return variants;
    },
    dateShortcuts() {
      return [
        {
          text: 'Last month',
          onClick: () => {
            this.startDate = new Date(moment().subtract(1, 'months').startOf('month'));
            this.endDate = new Date(moment().subtract(1, 'months').endOf('month'));
            this.resetTimePickers();
          },
        },
        {
          text: 'Last 30 days',
          onClick: () => {
            // We do 29 days since the time will be set 00:00 and 24:00, adding one extra day
            this.startDate = new Date(moment().subtract(29, 'days').startOf('day'));
            this.endDate = new Date(moment().endOf('day'));
            this.resetTimePickers();
          },
        },
        {
          text: 'Last week',
          onClick: () => {
            this.startDate = new Date(moment().subtract(1, 'weeks').startOf('isoWeek'));
            this.endDate = new Date(moment().subtract(1, 'weeks').endOf('isoWeek'));
            this.resetTimePickers();
          },
        },
        {
          text: 'Last 7 days',
          onClick: () => {
            // We do 6 days since the time will be set 00:00 and 24:00, adding one extra day
            this.startDate = new Date(moment().subtract(6, 'days').startOf('day'));
            this.endDate = new Date(moment().endOf('day'));
            this.resetTimePickers();
          },
        },
        {
          text: 'Yesterday',
          onClick: () => {
            this.startDate = new Date(moment().subtract(1, 'days').startOf('day'));
            this.endDate = new Date(moment().subtract(1, 'days').endOf('day'));
            this.resetTimePickers();
          },
        },
        {
          text: 'Today',
          onClick: () => {
            this.startDate = new Date(moment().startOf('day'));
            this.endDate = new Date(moment().endOf('day'));
            this.resetTimePickers();
          },
        },
      ];
    },
    languageOptions() {
      if (this.availableLanguages === null) {
        return null;
      }
      const languages = [...this.availableLanguages];
      languages.unshift({ value: 'any', text: 'Any language' });
      return languages.map((e) => ({ value: e.value, text: this.getLanguageName(e.text) }));
    },
    filteredLanguageOptions() {
      return this.languageOptions?.filter(
        (x) => x.text.toLowerCase().includes(this.filter.toLowerCase()));
    },
    selectedLanguage: {
      get() {
        return this.languageOptions?.find((e) => e.value === this.getter.selectedLanguage)?.text;
      },
      set(newValue) {
        this.setter({ key: 'selectedLanguage', newValue });
      },
    },
    selectedVariant: {
      get() {
        return this.getter.selectedVariant;
      },
      set(newValue) {
        this.setter({ key: 'selectedVariant', newValue });
      },
    },
    selectedGroupBy: {
      get() {
        return this.getter.selectedGroupBy;
      },
      set(newValue) {
        this.setter({ key: 'selectedGroupBy', newValue });
      },
    },
    startDate: {
      get() {
        if (!this.combinedDatetime) {
          return this.getter.startDate;
        }
        return this.combineDatetime(
          this.getter.startDatetime, this.hoursFromDate(this.getter.startDatetime),
        );
      },
      set(newValue) {
        if (!this.combinedDatetime) {
          this.setter({ key: 'startDate', newValue });
          return;
        }
        this.setter({ key: 'startDatetime', newValue: this.combineDatetime(newValue, this.startTime) });
      },
    },
    startTime: {
      get() {
        if (!this.combinedDatetime) {
          return this.getter.startTime;
        }
        return this.hoursFromDate(this.getter.startDatetime);
      },
      set(newValue) {
        if (!this.combinedDatetime) {
          this.setter({ key: 'startTime', newValue });
          return;
        }
        this.setter({ key: 'startDatetime', newValue: this.combineDatetime(this.startDate, newValue) });
      },
    },
    endDate: {
      get() {
        if (!this.combinedDatetime) {
          return this.getter.endDate;
        }
        return this.combineDatetime(
          this.getter.endDatetime, this.hoursFromDate(this.getter.endDatetime),
        );
      },
      set(newValue) {
        if (!this.combinedDatetime) {
          this.setter({ key: 'endDate', newValue });
          return;
        }
        this.setter({ key: 'endDatetime', newValue: this.combineDatetime(newValue, this.endTime) });
      },
    },
    endTime: {
      get() {
        if (!this.combinedDatetime) {
          return this.getter.endTime;
        }
        return this.hoursFromDate(
          this.combineDatetime(this.endDate, this.hoursFromDate(this.getter.endDatetime)),
        );
      },
      set(newValue) {
        if (!this.combinedDatetime) {
          this.setter({ key: 'endTime', newValue });
          return;
        }
        this.setter({ key: 'endDatetime', newValue: this.combineDatetime(this.endDate, newValue) });
      },
    },
    startDatesDisabled() {
      if (!this.endDate) {
        return {};
      }
      return { from: moment(this.endDate).endOf('day').toDate() };
    },
    startDatesHighlighted() {
      if (!this.endDate) {
        return {};
      }
      return { dates: [this.endDate] };
    },
    endDatesDisabled() {
      if (!this.startDate) {
        return {};
      }
      return { to: moment(this.startDate).startOf('day').toDate() };
    },
    endDatesHighlighted() {
      if (!this.startDate) {
        return {};
      }
      return { dates: [this.startDate] };
    },
    sameDaySelected() {
      if (!this.startDate && !this.endDate) {
        return true;
      }
      return moment(this.startDate).startOf('day') - moment(this.endDate).startOf('day') === 0;
    },
    maxTime() {
      return this.combinedDatetime ? 23.5 : 24;
    },
  },
  watch: {
    startDate: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        // Force startDate to be start of day if not combining date and time
        if (!this.combinedDatetime && value - moment(value).startOf('day') !== 0) {
          this.startDate = moment(value).startOf('day').toDate();
        }
        if (this.sameDaySelected && this.startTime > this.endTime) {
          this.$nextTick(() => { this.startTime = this.endTime; });
        }
      },
    },
    startTime: {
      immediate: true,
      handler(value) {
        if (this.startDate - moment(this.endDate).startOf('day') === 0 && value > this.endTime) {
          this.$nextTick(() => { this.startTime = this.endTime; });
        }
      },
    },
    endDate: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        // Force endDate to be end of day if not combining date and time
        if (!this.combinedDatetime && value - moment(value).endOf('day') !== 0) {
          this.endDate = moment(value).endOf('day').toDate();
        }
        if (this.sameDaySelected && this.endTime < this.startTime) {
          this.$nextTick(() => { this.endTime = this.startTime; });
        }
      },
    },
    endTime: {
      immediate: true,
      handler(value) {
        if (this.sameDaySelected && value < this.startTime) {
          this.$nextTick(() => { this.endTime = this.startTime; });
        }
      },
    },
  },
  async beforeMount() {
    // Do in beforeMount so it's fetched before pickerProps above is computed.
    // ^This doesn't actually seem to work, so availableLanguages returns [] as default.
    await this.fetchLanguages();
    if (this.variant) {
      await this.fetchVariants();
    }
  },
  mounted() {
    const parameters = [
      'selectedVariant',
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'selectedLanguage',
    ];
    parameters.forEach((param) => {
      const value = this.$route.query[param];
      if (value !== undefined) {
        let paramValue = value;
        if (param === 'startDate') {
          paramValue = new Date(moment(parseInt(value, 10)).startOf('day').toDate());
        } else if (param === 'endDate') {
          paramValue = new Date(moment(parseInt(value, 10)).endOf('day').toDate());
        }
        this[param] = paramValue;
      }
    });
    this.$forceUpdate();
  },
  methods: {
    getLanguageName,
    ...mapActions('chatlogs', ['fetchLanguages', 'fetchVariants']),
    formattedTime(timeObj) {
      const someDate = new Date();
      someDate.setHours(timeObj);
      someDate.setMinutes((timeObj % 1.0) * 60);
      someDate.setSeconds(0);
      let customTimeString = someDate.toTimeString();
      if (timeObj >= 24) {
        customTimeString = (someDate.getHours() + 24) + customTimeString.slice(2);
      }
      return customTimeString;
    },
    resetTimePickers() {
      this.startTime = 0;
      this.endTime = 24;
    },
    combineDatetime(date, time) {
      if (!date) {
        return null;
      }
      const minutes = (time * 10) % 10 !== 0 ? 30 : 0;
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        Math.floor(time),
        minutes);
    },
    hoursFromDate(date) {
      if (!date) {
        return null;
      }
      // Round minutes to nearest 0.5 hour
      return date.getHours() + (Math.round(date.getMinutes() / 60 / 0.5) * 0.5);
    },
  },
};

</script>
<style scoped>
::v-deep .dropdown-item{
  color: #111f2d !important;
  font-size: 0.9rem;
  font-weight: 400;
  min-width: 200px;
  line-height: 1.5;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
::v-deep .dropdown-button:hover{
  border-color: #b8bec1 !important;
}
::v-deep .dropdown-toggle::after{
  display: inline-block;
  margin-left: auto;
}
.btn-content{
  display: inline-block;
  width: calc(100% - 9px);
}
::v-deep .dropdown-toggle:focus{
  border-color: #0ab4ff !important;
  outline: 0 !important;
  box-shadow: inset 0 0 2px 2px #70d3ff !important;
}
::v-deep .lang-selector{
  max-height: 400px;
  overflow-y: auto;
}
</style>
