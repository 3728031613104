<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="secondary"
    @shown="focusInput"
  >
    <template #icon>
      <font-awesome-icon icon="code" />
    </template>
    <template #header>
      {{ cappedAssignment }}
    </template>
    <b-row>
      <b-col
        :cols="code.length > 24 ? 12 : 6"
        class="mt-1"
      >
        <label>
          Variable name:
        </label>
        <VariableName
          ref="varName"
          :value="keyName"
          placeholder="Variable name"
          class="mb-2 mb-sm-0"
          @input="value => setKey({ key: value })"
        />
      </b-col>
      <b-col
        :cols="code.length > 24 ? 12 : 6"
        class="mt-1"
      >
        <label>
          Variable code:
        </label>
        <botscript-validation
          :expanded="code.length > 24"
          :value="code"
          @onChange="code => setCode({ code })"
        />
      </b-col>
    </b-row>
  </base-activity>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import VariableName from '@/components/VariableName.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  truncateString,
} from '@/js/utils';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'SetVariableActivity',
  components: {
    BaseActivity,
    VariableName,
    BotscriptValidation,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      keyName: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      key: 'activityKey',
      code: 'activityCode',
    }), 'nodeId', 'activityId'),
    cappedAssignment() {
      return truncateString(`${this.key} = ${this.code}`, 60);
    },
    keyFeedbackId() {
      return `keyFeedback${this.nodeId}-${this.activityId}`;
    },
    codeFeedbackId() {
      return `codeFeedback${this.nodeId}-${this.activityId}`;
    },
  },
  watch: {
    /**
     * It is necessary to watch store as the value for key might be changed by
     * another, concurrent user. In this case we need to update keyName.
     */
    key(newValue) {
      this.keyName = newValue;
    },
  },
  mounted() {
    this.keyName = this.key;
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setKey: 'setActivityKey',
      setCode: 'setActivityCode',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    focusInput() {
      if (this.$refs.varName?.$children[0]) {
        this.$refs.varName.$children[0].focus();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
};
</script>
