<template>
  <b-card class="r-75 h-100 shadow" body-class="dashboard-card-content">
    <b-row class="mb-2">
      <b-col class="my-auto py-auto">
        <div class="dashboard-card-title text-truncate font-weight-bold">
          {{ title }}
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-tooltip.hover
          :disabled="!items.length"
          variant="text"
          class="chart-type-button text-primary p-1"
          :title="tooltip"
          @click.stop="switchView"
        >
          <font-awesome-icon :icon="icon" />
        </b-button>
      </b-col>
    </b-row>
    <b-table
      v-if="selectedChartType === 'table'"
      :fields="fields"
      hover
      show-empty
      responsive
      class="mt-2 mb-0 cursor-pointer d-color-dark-all"
      :items="items"
      @row-clicked="itemClicked"
    >
      <template #cell(name)="row">
        <span
          v-b-tooltip.hover.noninteractive.viewport
          :title="row.item.name"
        >
          {{ row.item.name.length > 12 ? `${row.item.name.substring(0, 12)}...` : row.item.name }}
        </span>
      </template>
      <template #cell(selfService)="row">
        {{ row.item.selfService }}%
      </template>
      <template #cell(transferred)="row">
        {{ row.item.transferred }}%
      </template>
    </b-table>
    <typed-chart
      v-else
      :key="selectedChartType"
      style="max-height: 300px;"
      :chart-data="chartData"
      :options="chartOptions"
      :chart-type="selectedChartType"
    />
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex';
import TypedChart from 'supwiz/components/TypedChart.vue';

export default {
  name: 'FlowsSummary',
  components: {
    TypedChart,
  },
  props: {
    title: {
      type: String,
      default: 'Flow summary',
    },
    metric: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      selectedChartType: 'table',
      chartColors: [
        '#546E7A', '#BFCCB5', '#0E8388', '#3795BD', '#CBE4DE',
      ],
    };
  },
  computed: {
    ...mapGetters('statistics', [
      'getKPIDataInfo',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
    items() {
      if (this.metric === 'most_visited_flows') {
        return [...this.formattedData].sort((a, b) => b.count - a.count).slice(0, 5);
      } if (this.metric === 'most_successful_flows') {
        return [...this.formattedData].sort((a, b) => b.selfServiceCount - a.selfServiceCount)
          .slice(0, 5);
      }
      return [...this.formattedData].sort((a, b) => b.transferredCount - a.transferredCount)
        .slice(0, 5);
    },
    formattedData() {
      return Object.entries(this.getKPIDataInfo('flow_stats') || {}).map(
        ([k, v]) => ({
          id: k,
          name: this.nameOfId(k),
          count: v.count,
          selfServiceCount: v.self_service_count,
          transferredCount: v.transfer_count,
          selfService: ((v.self_service_rate || 0) * 100).toFixed(2),
          transferred: ((v.transfer_rate || 0) * 100).toFixed(2),
        }),
      );
    },
    icon() {
      return this.selectedChartType === 'table' ? 'chart-pie' : 'table';
    },
    tooltip() {
      return this.selectedChartType === 'table' ? 'Switch to chart' : 'Switch to table';
    },
    chartData() {
      const labels = this.items.map((e) => e.name);
      const datasets = [];
      datasets.push({
        data: this.items.map((e) => ({ ...e, value: this.getChartValue(e) })),
        backgroundColor: this.chartColors,
      });
      return {
        labels,
        datasets,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        onClick: (event, activeElements) => {
          if (activeElements.length) {
            const { index } = activeElements[0];
            this.itemClicked(this.chartData.datasets[0].data[index], index, event.native);
          }
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (payload) => {
                const current = payload.dataset.data[payload.dataIndex];
                if (this.metric === 'flows_with_most_potential') {
                  return `Transfer count: ${current.transferredCount}, Transferred: ${current.transferred}%`;
                }
                if (this.metric === 'most_successful_flows') {
                  return `Self-service count: ${current.selfServiceCount}, Self-service: ${current.selfService}%`;
                }
                return `Count: ${current.count}, Self-service: ${current.selfService}%`;
              },
            },
          },
          legend: {
            position: 'bottom',
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        onHover: (event) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = 'pointer';
        },
      };
    },
  },
  methods: {
    getChartValue(item) {
      if (this.metric === 'most_visited_flows') {
        return item.count;
      } if (this.metric === 'most_successful_flows') {
        return item.selfServiceCount;
      }
      return item.transferredCount;
    },
    switchView() {
      this.selectedChartType = this.selectedChartType === 'table' ? 'doughnut' : 'table';
    },
    itemClicked(item, index, event) {
      const pastDate = new Date();
      pastDate.setDate(new Date().getDate() - 7);
      const query = {
        endDate: new Date().getTime(),
        startDate: pastDate.getTime(),
        includeNodes: [item.id],
      };

      this.openCtrlLink({ name: 'conversation-logs', query }, event);
    },
  },
};
</script>
<style scoped>
.chart-type-button{
  transform: scale(1.35);
}
</style>
