
const templateState = {
  pages: [
    /*
      {
        display: 'Config'
        linkName: 'ranker-config'
        icon: 'cog'
        tooltip: 'description of the page' *optional*
        tags: 'tags which can be used to improve search results' *optional*
        children: Array of nested pages. This will create a foldable element.
          These pages have the same key options except icon.
      },
    */
  ],
  userDetails: {
    /*
      displayName: 'Bob Ross' *optional*
      avatar: url or base64 *optional*
    */
  },
  userOptionPages: [
    /*
    {
      text: 'button text',
      to: router to,
    }
    */
  ],
  localizationObj: {},
  isLoading: false,
  isLoggedIn: false,
  noMainPage: false,
  sidebarVisible: false,
  sidebarCompact: false,
  toolDetails: {
    /*
      name: 'TicketAnalyzer',
      logo: require('./assets/supwiz-logo-flat.svg'),
      env: 'development' *optional*
      version: '1.0.0' *optional*
    */
  },
  notifications: [],
};

const getters = {
  breadcrumbs: () => (route) => {
    if (route.path.slice(-1) === '/') {
      const path = route.path.slice(1, -1).split('/');
      return path;
    }
    const path = route.path.substring(1).split('/');
    return path;
  },
  notificationsSorted: (state) => {
    const notifications = state.notifications.filter((n) => n.display);
    notifications.sort((a, b) => b.timestamp - a.timestamp);

    return notifications;
  },
  notificationToasts: (state) => state.notifications.filter((n) => n.toast),
}

const mutations = {
  templateSetPages(state, payload) {
    state.pages = payload;
  },
  templateSetUserDetails(state, payload) {
    state.userDetails = payload;
  },
  templateSetUserOptionPages(state, payload) {
    state.userOptionPages = payload;
  },
  templateSetIsLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  templateSetIsLoading(state, payload) {
    state.isLoading = payload;
  },
  templateSetLocalizationObj(state, payload) {
    state.localizationObj = payload;
  },
  templateSetNoMainPage(state, payload) {
    state.noMainPage = payload;
  },
  templateSetSidebarVisible(state, payload) {
    state.sidebarVisible = payload;
  },
  templateSetSidebarCompact(state, payload) {
    state.sidebarCompact = payload;
  },
  templateSetToolDetails(state, payload) {
    state.toolDetails = payload;
  },
  templateAddNotification(state, payload) {
    state.notifications.push(payload);
  },
  templateRemoveNotification(state, notificationTimestamp) {
    const notificationIndex = state.notifications
      .findIndex((notification) => notification.timestamp === notificationTimestamp);
    if (notificationIndex >= 0) {
      state.notifications.splice(notificationIndex, 1);
    }
  },
  templateRemoveAllNotifications(state) {
    state.notifications = [];
  },
};

const actions = {
  templateSendNotification({ commit }, {
    variant = '',
    title = 'Notification',
    text = '',
    timestamp = new Date().getTime(),
    toast = false,
    display = true,
  }) {
    commit('templateAddNotification', {
      variant, title, text, timestamp, toast, display,
    });
  },
};

export default {
  namespaced: true,
  state: templateState,
  getters,
  mutations,
  actions,
};
