<template>
  <div>
    <b-overlay :show="loading">
      <b-form-group label="User permission">
        <b-form-checkbox v-model="assignYourself" class="mb-2">
          Assign permission to yourself
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Assign permissions to all users and groups with access to one of the following bots:">
        <multi-select
          v-model="botPermissions"
          class="mb-2"
          :options="botOptions"
          :searchable="botOptions.length > 5"
          typename="bot"
        />
      </b-form-group>
      <b-form-group label="Assign permissions to the following groups:">
        <multi-select
          v-model="groupPermissions"
          :options="groupOptions"
          typename="group"
          :searchable="groupOptions.length > 5"
        />
      </b-form-group>
    </b-overlay>
  </div>
</template>
<script>
import MultiSelect from 'supwiz/components/MultiSelect.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'PermissionsSelector',
  components: {
    MultiSelect,
  },
  props: {
    permissions: {
      type: [Object, null],
      default: () => null,
    },
  },
  data() {
    return {
      loading: true,
      assignYourself: true,
      currentUserData: null,
      botPermissions: [],
      groupPermissions: [],
    };
  },
  computed: {
    ...mapState('botManipulation', ['isBotsLoading', 'botsList']),
    ...mapGetters('botManipulation', ['getBotId']),
    ...mapState('administration', ['groups']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isSuperUser']),
    botOptions() {
      return this.botsList.filter((bot) => this.isSuperUser || bot.permitted)
        .map((bot) => ({ text: bot.name, value: bot.id }));
    },
    groupOptions() {
      const member = (this.currentUserData?.groups?.member || []).map((x) => x.value);
      return Object.values(this.groups)
        .filter((group) => this.isSuperUser || member.includes(group.id))
        .map((group) => ({ text: group.name, value: group.id }));
    },
  },
  async created() {
    this.loading = true;
    this.botPermissions = [];
    this.groupPermissions = [];
    if (!this.botsList.length) {
      await this.updateBotList();
    }
    await this.fetchGroups();
    this.currentUserData = await this.fetchUser(this.user.id);
    this.assignYourself = this.permissions?.assignYourself !== undefined
      ? this.permissions.assignYourself : true;
    this.botPermissions = this.permissions?.bots !== undefined
      ? this.permissions.bots : this.botOptions.map((e) => e.value);
    this.groupPermissions = this.permissions?.groups !== undefined
      ? this.permissions.groups : this.groupOptions.map((e) => e.value);

    this.loading = false;
  },
  methods: {
    ...mapActions('botManipulation', ['updateBotList']),
    ...mapActions('administration', ['fetchGroups', 'fetchUser']),
  },
};
</script>
