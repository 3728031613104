<template>
  <div
    v-on="topLevel ? { mouseleave: leaveTree } : {}"
  >
    <app-node-unit
      :id="id"
      :parent-id="parentId"
      :depth="depth"
    />
    <draggable
      v-if="ownChildren !== null"
      :value="ownChildren"
      tag="ul"
      :disabled="!draggable"
      @input="reorderChildren"
    >
      <li
        v-for="childId in ownChildren"
        :key="childId"
      >
        <app-tree-node
          :id="childId"
          :parent-id="id"
          :depth="depth + 1"
        />
      </li>
    </draggable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import AppNodeUnit from '@/pages/TreeView/app-tree-node-unit.vue';

export default {
  name: 'AppTreeNode',
  components: { AppNodeUnit, Draggable },
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('treeView', [
      'children',
    ]),
    topLevel() {
      return this.depth === 0;
    },
    ownChildren() {
      return this.children(this.id);
    },
    // Returns true if all of the children of this node are leafs in the tree-view.
    allChildrenLeafs() {
      let foundNonLeaf = false;
      for (const childId of this.ownChildren) {
        if (this.children(childId)) {
          foundNonLeaf = true;
        }
      }
      return !foundNonLeaf;
    },
    draggable() {
      return this.ownChildren !== null && this.ownChildren.length > 1 && this.allChildrenLeafs;
    },
  },
  methods: {
    leaveTree() {
      this.$store.commit('treeView/setActiveBotNodeId', { botNodeId: null });
    },
    reorderChildren(newTreeChildren) {
      // Given the ids of the children in the tree the corresponding children in the bot are found.
      const newBotChildren = newTreeChildren
        .map((id) => this.$store.state.treeView.tree[id].botNodeId);
      const nodeId = this.$store.state.treeView.tree[this.id].botNodeId;
      this.$store.dispatch(
        'botManipulation/activeBot/updateActiveNode',
        { value: newBotChildren, attribute: 'children', id: nodeId },
      );
    },
  },
};
</script>

<style scoped>
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 0;
  }
</style>
