import { platforms, voicePlatforms } from '@/js/constants';

export default [
  {
    name: 'End conversation',
    description: 'End the conversation by closing down connection.',
    id: 'chat.end',
    params: [],
    supportedIn: [platforms.SUPCHAT, platforms.GENESYS, platforms.JSONWEBHOOK,
      platforms.PUZZEL, platforms.PUZZELTICKET, platforms.PUZZELHUB,
      platforms.BOMGAR, platforms.BOMGARAGENT, platforms.TOPDESKINCIDENT,
      voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES, platforms.FRESHDESKTICKET],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: false,
  },
  {
    name: 'Transfer to department',
    description: 'Transfers the conversation to a department. Use the format:\n"<tenant>.<department>".'
      + ' Also remember to leave after transferring.',
    id: 'chat.transferToDepartment',
    params: [{ name: 'departmentName', default: '' }],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESK, platforms.GENESYS,
      platforms.PUZZEL, platforms.PUZZELTICKET, platforms.PUZZELHUB,
      platforms.BOMGAR, platforms.BOMGARAGENT, platforms.ZENDESKSUPPORT,
      platforms.ZENDESKSUNCO, platforms.TOPDESKINCIDENT, platforms.FRESHDESKTICKET],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Leave conversation',
    description: 'Leave the conversation.',
    id: 'chat.leave',
    params: [],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESK, platforms.ZENDESKSUPPORT,
      platforms.GENESYS, platforms.PUZZEL, platforms.BOMGAR, platforms.BOMGARAGENT,
      platforms.TOPDESKINCIDENT],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: false,
  },
  {
    name: 'Run macro',
    description: 'Runs a macro.',
    id: 'chat.runMacro',
    params: [
      { name: 'macro_name', default: '' },
      { name: 'keep_assignment', default: false },
    ],
    extraParams: [
      { name: 'extraParams', default: '{}' },
    ],
    supportedIn: [platforms.ZENDESKSUPPORT, platforms.PUZZELHUB],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Callback visitor',
    description: 'Request a callback the visitor.',
    id: 'chat.callback',
    params: [
      { name: 'phonenumber', default: '' },
      { name: 'queue ID', default: '' },
      { name: 'scheduled time (optional)', default: 'None' },
    ],
    extraParams: [
      { name: 'details (genesys-only)', default: '"Transcript:\\n"+transcript' },
      { name: 'extraParams', default: '{}' },
    ],
    supportedIn: [platforms.PUZZEL, platforms.GENESYS],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Get schedule group',
    description: 'Returns information about a given schedule group.',
    id: 'get.schedule.group',
    params: [
      { name: 'scheduleGroupId' },
    ],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.GENESYS],
    excludeInNodes: ['init'],
    target: 'schedule_group',
    allowsExpansion: true,
  },
  {
    name: 'Get metadata',
    description: 'Get metadata from frontend.',
    id: 'chat.metadata.get',
    params: [],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESKSUPPORT, platforms.ZENDESKSUNCO,
      platforms.TOPDESKINCIDENT, platforms.PUZZELTICKET, platforms.PUZZELHUB,
      platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: 'metadata_info',
    allowsExpansion: true,
  },
  {
    name: 'Send metadata',
    description: 'Sends metadata to frontend.',
    id: 'chat.metadata',
    params: [
      { name: 'Name (not code)', default: '', type: 'label' },
      { name: 'value', default: '' }],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESK, platforms.ZENDESKSUNCO,
      platforms.GENESYS, platforms.JSONWEBHOOK,
      platforms.TOPDESKINCIDENT],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Send Frontend Event',
    description: 'Sends an event to frontend/widget/channel.',
    id: 'channel.event.send',
    params: [
      {
        name: 'name',
        default: '"navigateTo"',
        type: 'select',
        options: ['"navigateTo"', '"highlightDom"'],
      },
      { name: 'value', default: '' },
    ],
    supportedIn: [platforms.SUPCHAT, platforms.PUZZELHUB],
    excludeInNodes: [],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Save metadata in BotStudio log',
    description: 'Saves metadata in the BotStudio conversation log.',
    id: 'chat.internalMetadata',
    params: [
      { name: 'name', default: '' },
      { name: 'value', default: '' },
    ],
    supportedIn: [...Object.values(platforms), ...Object.values(voicePlatforms)],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Get queue information',
    description: 'Returns information about the current queue of the visitor. The '
      + '"queue_position" key gives the position in the queue.',
    id: 'chat.queuePosition',
    params: [],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.SUPCHAT, platforms.PUZZELHUB],
    excludeInNodes: ['init'],
    target: 'queue_info',
    allowsExpansion: true,
  },
  {
    name: 'Get visitor name',
    description: 'Returns the visitors name.',
    id: 'get.visitor.name',
    params: [],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESK, platforms.GENESYS,
      platforms.PUZZEL, platforms.PUZZELHUB,
      platforms.JSONWEBHOOK, platforms.BOMGAR, platforms.BOMGARAGENT,
      platforms.ZENDESKSUPPORT, platforms.ZENDESKSUNCO,
      platforms.TOPDESKINCIDENT, platforms.PUZZELTICKET,
      voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES, platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: 'visitor_name',
    allowsExpansion: true,
  },
  {
    name: 'Get visitor info',
    description: 'Return visitor info.',
    id: 'get.visitor.info',
    params: [],
    extraParams: [
      {
        name: 'mockResponse',
        default: '{"data":{"userDisplayName":"Amanda Hansen (TEST-person)", "email":"amanda.h@example.org"}}',
      },
    ],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESK, platforms.GENESYS,
      platforms.ZENDESKSUPPORT, platforms.ZENDESKSUNCO,
      platforms.PUZZEL, platforms.PUZZELTICKET, platforms.PUZZELHUB,
      platforms.JSONWEBHOOK, platforms.BOMGAR,
      platforms.BOMGARAGENT, platforms.TOPDESKINCIDENT,
      voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES, platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: 'visitor_info',
    allowsExpansion: true,
  },
  {
    name: 'Send SMS',
    description: 'Send sms-message to phone number',
    id: 'chat.sendSMS',
    params: [
      { name: 'phoneNumber', default: '' },
      { name: 'message', default: '', multiline: true },
      { name: 'from', default: '', placeholder: 'Type BotScript here. Max 11 characters.' },
    ],
    supportedIn: [...Object.values(platforms), ...Object.values(voicePlatforms)],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Send Email',
    description: 'Send email message to email adress',
    id: 'chat.sendEmail',
    params: [
      { name: 'recipients', default: '[]' },
      { name: 'cc', default: '[]' },
      { name: 'subject', default: '' },
      { name: 'message', default: '' },
    ],
    supportedIn: [...Object.values(platforms), ...Object.values(voicePlatforms)],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Change response recipient',
    description: 'Change email recipient of the response activity',
    id: 'chat.changeEmailRecipient',
    params: [
      { name: 'email', default: '' },
    ],
    supportedIn: [platforms.PUZZELTICKET],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Send DTMF tones',
    description: 'Send DTMF tones into the call',
    id: 'call.sendDTMF',
    params: [
      { name: 'digits', default: '' },
    ],
    supportedIn: [voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES, platforms.PUZZELHUB],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Await typed digits (Get DTMF tones)',
    description: 'Ask the client to press the digits and end them with the # character',
    id: 'call.getDTMF',
    params: [
      { name: 'max_digits', default: '10' },
      { name: 'timeout', default: '10' },
    ],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES, platforms.PUZZELHUB],
    excludeInNodes: ['init'],
    target: 'typedNumber',
    allowsExpansion: true,
  },
  {
    name: 'Transfer to phone',
    description: 'Forward call to a phone (endpoint)',
    id: 'call.transferToPhone',
    params: [
      { name: 'phoneNumber', default: '' },
    ],
    extraParams: [
      { name: 'dtmfAnswer', default: '' },
      { name: 'onAnswer', default: '{}' },
      { name: 'from', default: '' },
    ],
    supportedIn: [voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Transfer to SIP endpoint',
    description: 'Forward call to SIP endpoint',
    id: 'call.transferToSip',
    params: [
      { name: 'uri', default: '' },
      { name: 'headers', default: '{}' },
    ],
    supportedIn: [voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO,
      voicePlatforms.AUDIOCODES],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Transfer to websocket endpoint',
    description: 'Forward call to websocket endpoint',
    id: 'call.transferToWebsocket',
    params: [
      { name: 'uri', default: '' },
      { name: 'headers', default: '{}' },
    ],
    supportedIn: [voicePlatforms.VONAGE, voicePlatforms.VONAGEPUZZEL, voicePlatforms.VONAGETRIO],
    excludeInNodes: ['init', 'final'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Conversation sleep',
    description: 'Make the conversation idle for a specified number of seconds.',
    id: 'sleep',
    params: [{ name: 'duration', default: '1' }],
    supportedIn: [platforms.SUPCHAT,
      platforms.ZENDESK, platforms.ZENDESKSUPPORT, platforms.ZENDESKSUNCO,
      platforms.GENESYS,
      platforms.BOMGAR, platforms.BOMGARAGENT, platforms.PUZZELTICKET, platforms.PUZZELHUB,
      platforms.FRESHDESKTICKET,
      voicePlatforms.AUDIOCODES, // This is added for workaround for get dtmf timeout problem
    ],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Prompt Login',
    description: 'Prompt the visitor to login.',
    id: 'chat.promptLogin',
    params: [],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.SUPCHAT, platforms.PUZZELHUB],
    excludeInNodes: ['init'],
    target: 'is_authenticated',
    allowsExpansion: true,
  },
  {
    name: 'Get authentication token',
    description: 'Returns the authentication token if set. Otherwise returns "None".',
    id: 'chat.isAuthenticated',
    params: [],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.SUPCHAT],
    excludeInNodes: ['init'],
    target: 'auth_token',
    allowsExpansion: true,
  },
  {
    name: 'Prompt Overlay',
    description: 'Prompt visitor for overlay. If acceptButton is empty it is forced.',
    id: 'chat.promptOverlay',
    params: [
      { name: 'acceptButton', default: '' },
      { name: 'denyButton', default: '' },
      { name: 'functionName', default: '' },
      { name: 'url', default: '' },
    ],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.SUPCHAT],
    excludeInNodes: ['init'],
    target: 'overlay_response',
    allowsExpansion: true,
  },
  {
    name: 'Get Department Queue Size',
    description: 'Retrieves the queue size for a given department',
    id: 'chat.getQueueSize',
    params: [
      { name: 'departmentId', default: 'tenant_id.department_id' },
    ],
    extraParams: [
      { name: 'mockResponse', default: '{"data": 4 }' },
    ],
    supportedIn: [platforms.PUZZELHUB,
      platforms.SUPCHAT, platforms.ZENDESK, platforms.ZENDESKSUPPORT],
    excludeInNodes: ['init', 'final'],
    target: 'queue_size',
    allowsExpansion: true,
  },
  {
    name: 'Get Department Waiting Time',
    description: 'Retrieves the current average waiting time for a given department.',
    id: 'chat.getWaitingTime',
    params: [
      { name: 'departmentId', default: 'supchat_tenant_id.department_id' },
    ],
    extraParams: [
      { name: 'mockResponse', default: '{"data": 82.7348791 }' },
    ],
    supportedIn: [platforms.PUZZELHUB,
      platforms.SUPCHAT, platforms.BOMGAR, platforms.BOMGARAGENT, platforms.ZENDESK],
    excludeInNodes: ['init', 'final'],
    target: 'waiting_time',
    allowsExpansion: true,
  },
  {
    name: 'Get Department Online Agent Count',
    description: 'Retrieves the current number of online agents for a given department.',
    id: 'chat.getOnlineAgentCount',
    params: [
      { name: 'departmentId', default: 'supchat_tenant_id.department_id' },
    ],
    extraParams: [
      { name: 'mockResponse', default: '{"data": 1}' },
    ],
    supportedIn: [platforms.PUZZELHUB, platforms.SUPCHAT],
    excludeInNodes: ['init'],
    target: 'online_agent_count',
    allowsExpansion: true,
  },
  {
    name: 'Check if department is online',
    description: 'Returns true if the given department is online and otherwise false.',
    id: 'chat.isDepartmentOnline',
    params: [
      {
        name: 'departmentName',
        default: '',
      },
    ],
    extraParams: [
      {
        name: 'mockResponse',
        default: '{"data": True}',
      },
    ],
    supportedIn: [platforms.SUPCHAT, platforms.ZENDESK, platforms.GENESYS,
      platforms.PUZZEL, platforms.PUZZELHUB,
      platforms.BOMGAR, platforms.BOMGARAGENT],
    excludeInNodes: ['init'],
    target: 'department_is_online',
    allowsExpansion: true,
  },
  {
    name: 'Check if department is open',
    description: 'Returns an object with 2 fields; whether the department is open and '
      + 'the opening status (open/closed/emergency/holiday)',
    id: 'chat.isDepartmentOpen',
    params: [
      { name: 'departmentName', default: '' },
      { name: 'scheduleGroup', default: '' },
      { name: 'emergencyGroup', default: '' },
    ],
    extraParams: [{ name: 'mockResponse', default: '{"data": {"result": True, "status": "open"}}' }],
    supportedIn: [platforms.GENESYS, platforms.PUZZELHUB, platforms.ZENDESKSUNCO,
      platforms.SUPCHAT],
    excludeInNodes: ['init'],
    target: 'department_is_open',
    allowsExpansion: true,
  },
  {
    name: 'Add note',
    description: 'Adds a note for agents only',
    id: 'chat.addNote',
    params: [
      { name: 'richtext', default: false },
      { name: 'note', default: '' },
    ],
    supportedIn: [platforms.ZENDESKSUPPORT, platforms.TOPDESKINCIDENT,
      platforms.PUZZELHUB, platforms.PUZZELTICKET,
      platforms.SUPCHAT, platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Add tag',
    description: 'Adds the given tag to the conversation',
    id: 'set.tag',
    params: [{ name: 'tag', default: '' }],
    supportedIn: [platforms.ZENDESK, platforms.GENESYS,
      platforms.ZENDESKSUPPORT, platforms.ZENDESKSUNCO,
      platforms.PUZZELTICKET, platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Get tags',
    description: 'Retrieves the conversation, visitor and all tags that are set for the conversation.',
    id: 'chat.getTags',
    params: [],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.ZENDESK, platforms.GENESYS, platforms.BOMGAR, platforms.BOMGARAGENT,
      platforms.ZENDESKSUPPORT, platforms.ZENDESKSUNCO, platforms.PUZZELTICKET,
      platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: 'tag_dict',
    allowsExpansion: true,
  },
  {
    name: 'Set reported language',
    description: 'Set the language of this chat reported for statistics and filtering in BotStudio',
    id: 'chat.setReportedLanguage',
    params: [{ name: 'langcode', default: '"en"' }],
    extraParams: [{ name: 'mockResponse', default: '"en"' }],
    supportedIn: [...Object.values(platforms), ...Object.values(voicePlatforms)],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Prompt chat form',
    description: 'Prompt the visitor to fill in a form.',
    id: 'chat.promptForm',
    params: [{ name: 'fields', default: [] }],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: [platforms.PUZZELHUB, platforms.SUPCHAT, platforms.ZENDESKSUNCO],
    excludeInNodes: ['init'],
    target: 'form',
    allowsExpansion: true,
  },
  {
    name: 'Raise error',
    description: 'Forces the bot to go to the error node with a descriptive message',
    id: 'chat.raiseError',
    params: [{ name: 'errorMessage', default: '' }],
    supportedIn: [...Object.values(platforms), ...Object.values(voicePlatforms)],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Set ticket status',
    description: 'Sets the status of the ticket',
    id: 'chat.setTicketStatus',
    params: [
      { name: 'status', default: '' },
    ],
    supportedIn: [platforms.ZENDESKSUPPORT, platforms.TOPDESKINCIDENT, platforms.PUZZELTICKET,
      platforms.FRESHDESKTICKET],
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Move Chat',
    description: 'Move Chat to another bot or variant.',
    id: 'chat.move',
    params: [
      { name: 'language', default: 'None' },
      { name: 'message', default: 'thisMessageVerbatim' },
    ],
    extraParams: [
      { name: 'queue', default: 'None' },
      { name: 'labels', default: 'None' },
      { name: 'mockResponse', default: '{"data": False}' },
    ],
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: 'success',
    allowsExpansion: true,
  },

  // DEBUG stuff for devs only

  {
    name: 'Echo Message',
    description: 'Test interrupt modes. This is for debug only.',
    id: 'echo.message',
    params: [
      { name: 'message', default: '' },
      { name: 'locale', default: 'None' },
    ],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  {
    name: 'Echo payload',
    description: 'Test the reachability of backend. This is for debug only.',
    id: 'echo',
    params: [{ name: 'payload', default: '' }],
    extraParams: [{ name: 'mockResponse', default: '' }],
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: 'echo_payload',
    allowsExpansion: true,
  },

  // This is a special Action for when an action is removed or in another branch
  {
    name: 'MISSING CHAT ACTION',
    description: 'This chat action is not supported in current version or no longer supported.',
    id: 'MISSING_CHAT_ACTION',
    params: [],
    extraParams: [],
    supportedIn: [],
    excludeInNodes: [],
    target: null,
    allowsExpansion: false,
  },
];
