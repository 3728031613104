<template>
  <b-link
    :to="getTo"
  >
    <slot>
      Configuration
    </slot>
  </b-link>
</template>

<script>
// Mapping from category to config subpage. Only needed if category is not a subpage name
const categoryMap = {
  platforms: 'General',
};

// Set of categories, that only exists in main bot config
const mainBotOnlySet = [
  'platforms',
];

export default {
  name: 'ConfigLink',
  props: {
    category: {
      type: String,
      default: '',
    },
  },
  computed: {
    subpageRefName() {
      if (!this.category) {
        return '';
      }
      const key = this.category.toLowerCase();
      return categoryMap[key] || this.category;
    },
    mainBotOnly() {
      if (!this.category) {
        return false;
      }
      const key = this.category.toLowerCase();
      return mainBotOnlySet.includes(key);
    },
    getTo() {
      const to = {
        name: 'config',
      };
      if (this.subpageRefName) {
        to.hash = `#${this.subpageRefName}`;
      }
      if (this.mainBotOnly
        && this.$route.params
        && this.$route.params.botId
        && this.$route.params.botId.length > 36) {
        to.params = {};
        to.params.botId = this.$route.params.botId.substr(0, 36);
      }
      return to;
    },
  },
};
</script>

<style scoped>
</style>
