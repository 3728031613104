<template>
  <div class="w-100">
    <b-row class="w-100 text-center" no-gutters>
      <b-col style="width: calc(100% - 36px)">
        <div
          class="cursor-pointer node-name mx-auto bg-primary text-white text-truncate"
          style="max-width:90%"
          pill
          @click="goToNode(nodeId)"
        >
          {{ getName }}
        </div>
      </b-col>

      <b-col cols="auto" style="width: 36px;" />
    </b-row>
    <b-row v-if="showArrow" class="w-100 my-2" no-gutters>
      <b-col
        style="margin-right: 36px;"
      >
        <font-awesome-icon
          color="grey"
          class="d-block w-100 my-2"
          icon="arrow-down"
        />
        <div v-if="showPrediction" class="prediction-badge r-50 text-white">
          {{ getTopPrediction(showLabel, currentNode.probabilities) }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getTopPrediction } from '@/js/utils';

export default {
  name: 'NodeWithLabels',
  props: {
    currentNode: {
      required: true,
      type: Object,
    },
    showArrow: {
      required: true,
      type: Boolean,
    },
    showLabel: {
      required: true,
      type: Boolean,
    },
    showTopPrediction: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['nameOfId', 'nodeById']),
    nodeId() {
      return this.currentNode?.node?.node;
    },
    showPrediction() {
      return this.showTopPrediction && this.currentNode.probabilities?.some((e) => typeof e.score === 'number');
    },
    getName() {
      if (this.nodeById(this.nodeId)) {
        return this.nameOfId(this.nodeId);
      }
      return `${this.currentNode.node.display_name} (deleted)`;
    },
  },
  methods: {
    getTopPrediction,
    goToNode(nodeId) {
      const botId = this.$route.params.botId;
      const newPage = this.$router.resolve({ name: 'edit-node', params: { botId, nodeId } });
      window.open(newPage.href, '_blank');
    },
  },
};
</script>
<style scoped>
.node-name{
  width: fit-content;
  border-radius: 50rem;
  font-size: 15px;
  padding: 6px 8px;
  font-weight: 300;
}
</style>
