<template>
  <div
    class="customer"
    :class="selectedForReplay ? 'bg-info' : ''"
    @click="selectForReplay"
  >
    <div
      v-if="showName"
      class="messageContent name text-right"
    >
      You
    </div>
    <b-form-group>
      <template v-for="field in fields">
        <div
          v-if="field.type === 'text'"
          :key="`${field.id}+text`"
        >
          {{ field.text }}
          <b-form-input
            :ref="field.id"
            :disabled="submitted"
          />
        </div>
        <div
          v-else-if="field.type === 'select'"
          :key="`${field.id}+select`"
        >
          {{ field.text }}
          <b-form-select
            :ref="field.id"
            :options="toOptions(field.options)"
            :disabled="submitted"
          />
        </div>
      </template>
    </b-form-group>
    <b-button
      :disabled="submitted"
      @click.stop="submitForm"
    >
      Submit
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'FormMessage',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    replay: {
      type: [Object, null],
      default: null,
    },
    showName: {
      type: Boolean,
      required: true,
    },
    selectedForReplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitted: false,
    };
  },
  mounted() {
    if (this.replay === null) return;
    for (const [id, value] of Object.entries(this.replay)) {
      // Test bot did not change ids or remove fields
      if (this.$refs[id]) {
        this.$refs[id][0].localValue = value;
      }
    }
    this.submitForm();
  },
  methods: {
    submitForm() {
      this.submitted = true;
      const ret = {};
      for (const field of this.fields) {
        ret[field.id] = this.$refs[field.id][0].localValue;
      }
      this.$emit('submitForm', ret);
    },
    selectForReplay() {
      if (!this.submitted) return;
      this.$emit('pickMeForReplay');
    },
    toOptions(fields) {
      return fields.map((o) => Object.create({ value: o.id, text: o.text }));
    },
  },
};
</script>

<style scoped>
  .customer {
    background: rgba(244, 244, 244, 0.65);
    cursor: pointer;
    border-radius: 15px;
    width: max-content;
    margin-right: 4px;
    max-width: 90%;
    margin-left: auto;
  }

</style>
