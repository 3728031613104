export default [
  {
    name: 'if',
    loopType: 'ifLoop',
    description: 'Provide a condition that determines if the subsequent activities will be executed.',
    statement: null,
  },
  {
    name: 'else if',
    loopType: 'ifLoop',
    description: 'An alternative condition to the if statement.',
    statement: null,
  },
  {
    name: 'else',
    loopType: 'ifLoop',
    description: 'If none of the previous conditions are met.',
  },
  {
    name: 'end if',
    loopType: 'ifLoop',
    description: 'End of the if loop',
  },
  {
    name: 'for',
    loopType: 'forLoop',
    description: 'Loop over a list or an object.',
    iterable: null,
    item: null,
  },
  {
    name: 'end for',
    loopType: 'forLoop',
    description: 'End of the for loop',
  },
];
