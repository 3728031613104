<template>
  <div>
    <h3>Fallback Behavior</h3>
    <b-card
      no-body
    >
      <p class="mb-2">
        Before going to the fallback node here you can enable the bot to ask the user
        to elaborate on their message. Putting their message in a different way may enable better
        matching in the bot.
      </p>
      <p class="mb-2">
        The elaboration question is only asked when the matching is tried from a standard node,
        it won't be asked from smart or multiple choice nodes.
      </p>
    </b-card>
    <b-form-group>
      <label
        label-for="fallbackQuestionText"
      >
        Question for elaboration
      </label>
      <tooltipped-text
        class="ml-1"
        value="The message the bot should send asking for elaboration"
      />
      <b-input
        id="questionText"
        v-model="fallbackQuestionText"
        placeholder="e.g. could you put the message into different words?"
      />
    </b-form-group>
    <approve-button
      v-model="questionApproved"
      type="question"
    />
    <b-form-group>
      <label
        label-for="fallbackQuestionLimit"
      >
        Number of times bot is allowed to ask for elaboration (throughout a conversation)
      </label>
      <tooltipped-text
        class="ml-1"
        value="The number of times the bot will ask the user to elaborate when nothing is matched"
      />
      <b-form-input
        id="fallbackQuestionLimit"
        v-model="fallbackQuestionLimit"
        type="number"
        :state="$v.fallbackQuestionLimit.$invalid ? false : null"
        aria-describedby="fallbackQuestionLimitFeedback"
      />
      <b-form-invalid-feedback id="fallbackQuestionLimitFeedback">
        <div v-if="!$v.fallbackQuestionLimit.required">
          You must write a number.
        </div>
        <div v-if="!$v.fallbackQuestionLimit.integer">
          The limit must be an integer.
        </div>
        <div v-if="!$v.fallbackQuestionLimit.nonNegative">
          The limit cannot be negative.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, integer, minValue } from 'vuelidate/lib/validators';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import TooltippedText from '@/components/TooltippedText.vue';
import ApproveButton from '@/components/ApproveButton.vue';

export default {
  name: 'FallbackBehaviorConfig',
  components: {
    TooltippedText,
    ApproveButton,
  },
  mixins: [validationMixin],
  data() {
    return {
      nodeId: 'elaboration',
    };
  },
  computed: {
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        getQuestionText: 'activityText',
        getFallbackQuestionApproved: 'activityResponseApproved',
      }),
      'nodeId',
      'activityId',
    ),
    ...mapGetters('botManipulation/activeBot/config', [
      'getFallbackQuestionLimit',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    activityId() {
      return this.nodeById('elaboration').activityIds[0];
    },
    fallbackQuestionLimit: {
      get() {
        return this.getFallbackQuestionLimit || 0;
      },
      set(value) {
        this.setFallbackQuestionLimit(value);
      },
    },
    fallbackQuestionText: {
      get() {
        return this.getQuestionText;
      },
      set(value) {
        this.setQuestionText({ text: value });
      },
    },
    questionApproved: {
      get() {
        return this.getFallbackQuestionApproved;
      },
      set(approved) {
        this.setFallbackQuestionApproved({ approved });
      },
    },
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setFallbackQuestionLimit',
    ]),
    ...addThisArgs(
      mapMutations('botManipulation/activeBot', {
        setQuestionText: 'setActivityResponseText',
        setFallbackQuestionApproved: 'setApproved',
      }),
      { nodeId: 'nodeId', activityId: 'activityId' },
    ),
  },
  validations: {
    fallbackQuestionLimit: {
      required,
      integer,
      nonNegative: minValue(0),
    },
  },
};
</script>

<style scoped>

</style>
