<template>
  <div>
    <p class="mb-2">
      <strong>New and Improved Puzzel Chat Integration</strong> is here with exciting features
      to <strong>enhance your customer interactions</strong> and streamline your chatbot operations.
    </p>
    <p>
      Some of the key features in this new integration include:
      <ul>
        <li>
          Redirect users to relevant parts of your webpage and highlight items seamlessly
        </li>
        <li>
          Add notes upon transfer, enabling you to add AI summaries or looking up information
          in an external system for smooth handover to agents
        </li>
        <li>Support for rich messages to improve engagement</li>
        <li>Inactivity messages to re-engage users</li>
        <li>Authentication and form support for more secure and dynamic interactions</li>
        <li>... and much more!</li>
      </ul>
    </p>
    <div class="text-center my-2">
      <b-button
        href="https://www.puzzel.com/blog/puzzel-to-acquire-supwiz-to-revolutionise-ai-and-chatbot-capabilities"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to read more about the background for the new improved integration
      </b-button>
    </div>
    <hr>
    <b-alert
      show
      class="mt-3"
      variant="info"
    >
      The new Puzzel Chat integration is available now! Reach out to your Puzzel representative
      to learn more about enabling these features as it requires the new WebEngage chat
    </b-alert>
  </div>
</template>

<script>
export default { name: 'PuzzelChatIntegration' };
</script>
