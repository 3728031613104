<template>
  <div>
    <!-- <p>
      {{ longButtonTextCount }} button{{ plural }} with long text{{ plural }} found in the bot:
    </p> -->
    <!-- <b-list-group>
      <b-list-group-item
        v-for="(item, index) in longButtonTexts"
        :key="index"
      >
        {{ item }}
      </b-list-group-item>
    </b-list-group> -->
    <p>
      The following nodes have long button text(s):
    </p>
    <node-list :nodes="nodeList" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NodeList from '@/components/NodeList.vue';

export default {
  name: 'ButtonTextLength',
  components: {
    NodeList,
  },
  props: {
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['nodeById']),
    longButtonTexts() {
      return this.insightDetails.longButtonTexts;
    },
    longButtonTextCount() {
      return Object.keys(this.longButtonTexts).length;
    },
    plural() {
      return this.longButtonTexts.length > 1 ? 's' : '';
    },
    nodeList() {
      return Object.keys(this.insightDetails.longButtonTexts).map(
        (nodeId) => this.nodeById(nodeId),
      );
    },
  },
};
</script>
