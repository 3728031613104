<template>
  <b-modal
    id="version-upload-modal"
    title="Upload new version"
    hide-footer
  >
    <!-- Show user some useful info about the upload process. -->
    <b-alert
      variant="primary"
      :show="versionUploading"
      class="shadow-sm"
    >
      <font-awesome-icon
        icon="spinner"
        size="lg"
        spin
      />
      Model is being uploaded. Do NOT leave this page.
    </b-alert>
    <b-alert
      variant="success"
      :show="!versionUploading && versionUploadSuccess === true"
      class="shadow-sm"
    >
      Upload succeeded!
    </b-alert>
    <b-alert
      variant="danger"
      :show="!versionUploading && versionUploadSuccess === false"
      class="shadow-sm"
    >
      Upload failed: {{ versionUploadError }}
    </b-alert>

    <!-- Form for uploading a new model -->
    <div
      v-if="!versionUploading"
      class="mt-3"
    >
      <b-form-group
        label="Version description"
        label-for="versionDescriptionForm"
      >
        <b-form-input
          id="versionDescriptionForm"
          v-model="form.newVersionDescription"
          type="text"
          placeholder="Provide a description of the classifier version..."
        />
      </b-form-group>
      <b-form-group
        label="File"
        label-for="versionFileForm"
      >
        <b-form-file
          id="versionFileForm"
          v-model="form.newVersionFile"
          placeholder="Choose a zipped classifier folder..."
          :state="$v.form.newVersionFile.$invalid ? false : null"
        />
        <!-- Bug 3545: this invalid does not work so we added :state instead -->
        <b-form-invalid-feedback>
          <div v-if="$v.form.newVersionFile.$invalid">
            Missing file
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button
        class="p-2"
        variant="primary"
        :disabled="$v.form.$invalid"
        @click="() => uploadVersion()"
      >
        Upload version
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getConnectionErrorMsg } from '@/js/utils';

export default {
  name: 'VersionUpload',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        newVersionDescription: '',
        newVersionFile: null,
      },
      versionUploading: false,
      versionUploadSuccess: null,
      versionUploadError: '',
    };
  },
  computed: {
    ...mapGetters('nlu/classifier', ['activeModel']),
  },
  methods: {
    ...mapState('nlu/classifier', ['versionsPagination']),
    ...mapActions('nlu/classifier', [
      'fetchGlobalNLUModels',
      'fetchClassifierVersions',
    ]),
    uploadVersion() {
      this.versionUploading = true;
      this.versionUploadSuccess = false;
      this.versionUploadError = '';
      const formData = new FormData();
      formData.append('name', this.activeModel.name);
      formData.append('version_description', this.form.newVersionDescription);
      formData.append('file', this.form.newVersionFile);
      formData.append('newClf', false);
      formData.append('type', 'swml');
      axios.post('/api/classifier/', formData, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      }).then(() => {
        this.fetchGlobalNLUModels();
        this.fetchClassifierVersions({
          clfId: this.activeModel.id,
          page: this.versionsPagination.page,
        });
      }).catch((e) => {
        this.versionUploadError = getConnectionErrorMsg(e);
        this.versionUploadSuccess = false;
        this.versionUploading = false;
      });
    },
  },
  validations: {
    form: {
      newVersionFile: {
        required,
        notNull(value) {
          return !!value;
        },
      },
    },
  },
};
</script>
