<template>
  <div>
    <b-row>
      <b-col v-if="isFetchingMeta" class="text-center">
        <b-spinner
          class="m-4"
          style="width: 4rem; height: 4rem;"
        />
      </b-col>
      <b-col v-else>
        <b-card
          title="Topic Exploration"
          class="r-75"
          body-class="p-3"
        >
          <b-input-group class="py-1">
            <b-input-group-prepend>
              <b-button
                v-b-tooltip.hover
                class="px-3"
                title="Create new visualisation"
                @click="showCreateVisualizationModal"
              >
                <font-awesome-icon
                  icon="plus"
                />
              </b-button>
              <b-button
                v-if="selectedVisualizationId"
                v-b-tooltip.hover
                class="px-3"
                title="Delete visualisation"
                @click="promptDeleteVisualization"
              >
                <font-awesome-icon
                  icon="trash-alt"
                />
              </b-button>
            </b-input-group-prepend>
            <b-input-group-append>
              <b-dropdown
                :disabled="!visualizationMetaList.length"
                :text="selectedVisualizationTitle"
              >
                <b-dropdown-item
                  v-for="{ name, id } in visualizationMetaList"
                  :key="id"
                  :to="routeForVisualization(id)"
                >
                  {{ name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>

          <!-- Child route gets rendered in <router-view> or <nuxt-child> -->
          <router-view v-if="selectedVisualizationId" />
        </b-card>
      </b-col>
    </b-row>
    <NewVisualization />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { applyThisArgs } from '@/js/storeHelpers';
import NewVisualization from '@/pages/DataExploration/TopicExploration/NewVisualization.vue';

export default {
  name: 'TopicExplorationPage',
  components: { NewVisualization },
  data() {
    return {
      setIntervalId: null,
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('dataExploration/visualizations', {
      visualizationMetaList: 'visualizationMetasFromDatasetId',
    }), 'datasetId'),
    ...mapState('dataExploration/visualizations', ['isFetchingMeta']),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    selectedVisualizationId() {
      return this.$route.params.visualizationId;
    },
    selectedVisualizationTitle() {
      return this.visualizationMetaList.find((e) => `${e.id}` === `${this.selectedVisualizationId}`)?.name || 'Select visualization';
    },
  },
  created() {
    this.fetchAllVisualizationMetas({ datasetId: this.datasetId, refreshing: false });
    this.setIntervalId = setInterval(
      () => this.fetchAllVisualizationMetas({ datasetId: this.datasetId, refreshing: true }),
      3000,
    );
  },
  destroyed() {
    clearInterval(this.setIntervalId);
  },
  methods: {
    ...mapActions('dataExploration/visualizations', [
      'deleteVisualization',
      'fetchAllVisualizationMetas',
    ]),
    showCreateVisualizationModal() {
      this.$bvModal.show('createVisualizationModal');
    },
    routeForVisualization(visualizationId) {
      const params = { visualizationId };
      return { name: 'dataExplorationTopicExploration', params };
    },
    async promptDeleteVisualization() {
      const question = 'Are you sure you want to delete this visualization?';
      if (await this.$bvModal.msgBoxConfirm(question)) {
        await this.deleteVisualization({ visualizationId: this.selectedVisualizationId });
      }
      this.$router.push({ name: 'dataExplorationTopicExplorationEntry' });
    },
  },
};
</script>
<style scoped>
</style>
