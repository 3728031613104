import Vue from 'vue';

const phraseGetters = {
  phrasesDict: (state) => state.phrases,
  phrases: (state) => Object.values(state.phrases || {}),
  phraseText: (state) => (pId) => {
    if (pId in state.phrases) {
      return state.phrases[pId]?.text;
    }
    // This happens when a phrase is removed, but we still have references
    // What to do?
    // state.$_vm.$bvToast.toast(`Phrases ${pId} is missing`);
    return '';
  },
  phraseName: (state) => (pId) => {
    if (pId in state.phrases) {
      return state.phrases[pId]?.name;
    }
    return 'DELETED';
  },
};
const phraseMutations = {
  addPhrase(state, { id, name, text }) {
    Vue.set(state.phrases, id, {
      id,
      name,
      text,
      timestamp: Date.now(),
    });
  },
  updatePhrase(state, phrase) {
    Vue.set(state.phrases, phrase.id, { ...phrase, timestamp: state.phrases[phrase.id].timestamp });
  },
  deletePhrase(state, id) {
    Vue.delete(state.phrases, id);
  },
};
const phraseActions = {

};

export { phraseGetters, phraseMutations, phraseActions };
