<template>
  <div>
    <b-row>
      <b-col cols="6">
        <p class="mb-2">
          <strong>SupChat forms</strong> provide a structured and intuitive way to ask your users
          for information.
        </p>
        <p class="mb-2">
          This is especially useful in cases where you need to collect a list of information in
          order to e.g. create a ticket for later processing, add a request for an incoming order,
          or ask users for structured input in general.
        </p>
        <p>
          To use this feature you must be using SupChat as the conversation platform and then
          you can use the <em>Prompt chat form</em> conversation action.
        </p>
      </b-col>
      <b-col cols="6">
        <b-img
          fluid
          :src="require(`@/assets/featureImages/supchat_form.png`)"
          alt="SupChat form example"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default { name: 'SupchatFormsDescription' };
</script>
