var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col'),_c('b-col',{staticClass:"my-auto text-right pl-4 d-flex",attrs:{"cols":"auto"}},[(!_vm.isDetails)?_c('configuration-dropdown',_vm._b({staticClass:"mr-2",attrs:{"default-number-of-topics":_vm.topicCount},on:{"updateTopicConfig":_vm.updateTopicConfig}},'configuration-dropdown',{ data: _vm.topicsObj },false)):_vm._e(),_c('b-button-group',_vm._l((_vm.chartTypes),function({ type, icon }){return _c('b-button',{key:type,staticClass:"chart-type-button",attrs:{"variant":"primary","pressed":type === _vm.selectedChartType},on:{"click":function($event){$event.stopPropagation();_vm.selectedChartType = type}}},[_c('font-awesome-icon',{attrs:{"icon":icon}})],1)}),1)],1)],1),(!_vm.hasData)?_c('b-row',[_c('b-col',{staticClass:"py-4 text-center"},[_vm._v(" There is no data to show. ")])],1):_c('b-row',[_c('b-col',_vm._b({},'b-col',_vm.bColAttrs,false),[(_vm.selectedChartType === 'table' && _vm.tableFields.length)?_c('table-data',{staticClass:"w-100 pr-2",attrs:{"sticky-header":"450px","items":_vm.topics,"fields":_vm.tableFields,"hover":""},on:{"row-clicked":_vm.rowClicked}}):_c('typed-chart',{key:_vm.selectedChartType,staticClass:"h-100",attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions,"chart-type":"bar"}})],1),(_vm.showDetails)?_c('b-col',_vm._b({},'b-col',_vm.bColAttrs,false),[_c('details-section',_vm._b({},'details-section',{
          chartOptions: _vm.chartOptions,
          visualizationId: _vm.visualizationId,
          topicIndex: _vm.selectedTopicIndex,
          topicsObj: _vm.topicsObj,
          specificityValue: _vm.specificityValue,
        },false))],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }