import { library } from '@fortawesome/fontawesome-svg-core';

// Import the icons we use
// We do this to decrease the bundle-size
import {
  faAddressCard,
  faAlignJustify,
  faAlignLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsUpDownLeftRight,
  faArrowUp,
  faAsterisk,
  faAtom,
  faBackward,
  faBan,
  faBars,
  faBarsStaggered,
  faBell,
  faBezierCurve,
  faBookmark,
  faBrain,
  faBroadcastTower,
  faCalculator,
  faCaretDown,
  faChartArea,
  faChartColumn,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClipboard,
  faCopy,
  faClock,
  faClone,
  faCloud,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faComment,
  faCommentMedical,
  faComments,
  faCommentSms,
  faCompressAlt,
  faCrosshairs,
  faCubes,
  faDatabase,
  faDiagramNext,
  faDirections,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFaceLaugh,
  faFaceSmile,
  faFastForward,
  faFileAudio,
  faFileExport,
  faFileUpload,
  faFilter,
  faFolderPlus,
  faFont,
  faGlobeEurope,
  faGraduationCap,
  faGripLinesVertical,
  faHammer,
  faHeartbeat,
  faHighlighter,
  faHistory,
  faHome,
  faHourglassEnd,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faLanguage,
  faLayerGroup,
  faLevelUpAlt,
  faLightbulb,
  faLink,
  faLinkSlash,
  faList,
  faListOl,
  faLock,
  faLongArrowAltRight,
  faKey,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMaximize,
  faMicrophone,
  faMicroscope,
  faMinus,
  faNoteSticky,
  faPaperPlane,
  faParking,
  faPaste,
  faPauseCircle,
  faPencilAlt,
  faPlayCircle,
  faPlus,
  faPlusMinus,
  faProjectDiagram,
  faPumpSoap,
  faQuestionCircle,
  faRedo,
  faRightToBracket,
  faRobot,
  faRotateRight,
  faRunning,
  faSearch,
  faSearchMinus,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSitemap,
  faSliders,
  faSort,
  faSortDown,
  faSortUp,
  faSpellCheck,
  faSpinner,
  faSquare,
  faSquareXmark,
  faStar,
  faStopCircle,
  faStream,
  faSubscript,
  faSync,
  faTable,
  faTableList,
  faTag,
  faTags,
  faThermometerEmpty,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpDownLeftRight,
  faUnlock,
  faUpload,
  faUpLong,
  faUser,
  faUserClock,
  faUserLock,
  faUserPlus,
  faUserTie,
  faVial,
  faVials,
  faVolumeMute,
  faVolumeUp,
  faWandMagicSparkles,
  faWrench,
  faMinimize,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCheckCircle as farFaCheckCircle,
  faCircle as farCircle,
  faComment as farFaComment,
  faFolder as farFaFolder,
  faQuestionCircle as farFaQuestionCircle,
  faTimesCircle as farFaTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(faAddressCard);
library.add(faAlignJustify);
library.add(faAlignLeft);
library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);
library.add(faAngleDown);
library.add(faAngleRight);
library.add(faAngleUp);
library.add(faArchive);
library.add(faArrowCircleRight);
library.add(faArrowDown);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faArrowsUpDownLeftRight);
library.add(faArrowUp);
library.add(faAsterisk);
library.add(faAtom);
library.add(faBackward);
library.add(faBan);
library.add(faBars);
library.add(faBarsStaggered);
library.add(faBell);
library.add(faBezierCurve);
library.add(faBookmark);
library.add(faBrain);
library.add(faBroadcastTower);
library.add(faCalculator);
library.add(faCaretDown);
library.add(faChartArea);
library.add(faChartColumn);
library.add(faChartPie);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faCheckSquare);
library.add(faCircle);
library.add(faClipboard);
library.add(faCopy);
library.add(faClock);
library.add(faClone);
library.add(faCloud);
library.add(faCode);
library.add(faCodeBranch);
library.add(faCog);
library.add(faCogs);
library.add(faComment);
library.add(faCommentMedical);
library.add(faComments);
library.add(faCommentSms);
library.add(faCompressAlt);
library.add(faCrosshairs);
library.add(faCubes);
library.add(faDatabase);
library.add(faDiagramNext);
library.add(faDirections);
library.add(faDotCircle);
library.add(faDownload);
library.add(faEdit);
library.add(faEllipsisV);
library.add(faEnvelope);
library.add(faEraser);
library.add(faExclamationCircle);
library.add(faExternalLinkAlt);
library.add(faEye);
library.add(faEyeSlash);
library.add(faFaceLaugh);
library.add(faFaceSmile);
library.add(faFastForward);
library.add(faFileAudio);
library.add(faFileExport);
library.add(faFileUpload);
library.add(faFilter);
library.add(faFolderPlus);
library.add(faFont);
library.add(faGlobeEurope);
library.add(faGraduationCap);
library.add(faGripLinesVertical);
library.add(faHammer);
library.add(faHeartbeat);
library.add(faHighlighter);
library.add(faHistory);
library.add(faHome);
library.add(faHourglassEnd);
library.add(faHourglassStart);
library.add(faInfo);
library.add(faInfoCircle);
library.add(faKey);
library.add(faLanguage);
library.add(faLayerGroup);
library.add(faLevelUpAlt);
library.add(faLightbulb);
library.add(faLink);
library.add(faLinkSlash);
library.add(faList);
library.add(faListOl);
library.add(faLock);
library.add(faLongArrowAltRight);
library.add(faMagnifyingGlassMinus);
library.add(faMagnifyingGlassPlus);
library.add(faMaximize);
library.add(faMicrophone);
library.add(faMicroscope);
library.add(faMinimize);
library.add(faMinus);
library.add(faNoteSticky);
library.add(faPaperPlane);
library.add(faParking);
library.add(faPaste);
library.add(faPauseCircle);
library.add(faPencilAlt);
library.add(faPlayCircle);
library.add(faPlus);
library.add(faPlusMinus);
library.add(faProjectDiagram);
library.add(faPumpSoap);
library.add(faQuestionCircle);
library.add(faRedo);
library.add(faRightToBracket);
library.add(faRobot);
library.add(faRotateRight);
library.add(faRunning);
library.add(faSearch);
library.add(faSearchMinus);
library.add(faShare);
library.add(faSignInAlt);
library.add(faSignOutAlt);
library.add(faSitemap);
library.add(faSliders);
library.add(faSort);
library.add(faSortDown);
library.add(faSortUp);
library.add(faSpellCheck);
library.add(faSpinner);
library.add(faSquare);
library.add(faSquareXmark);
library.add(faStar);
library.add(faStopCircle);
library.add(faStream);
library.add(faSubscript);
library.add(faSync);
library.add(faTable);
library.add(faTableList);
library.add(faTag);
library.add(faTags);
library.add(faThermometerEmpty);
library.add(faThermometerEmpty);
library.add(faTimes);
library.add(faTimesCircle);
library.add(faTrashAlt);
library.add(faUpDownLeftRight);
library.add(faUnlock);
library.add(faUpload);
library.add(faUpLong);
library.add(faUser);
library.add(faUserClock);
library.add(faUserPlus);
library.add(faUserTie);
library.add(faUserLock);
library.add(faVial);
library.add(faVials);
library.add(faVolumeMute);
library.add(faVolumeUp);
library.add(faWandMagicSparkles);
library.add(faWrench);
library.add(farCircle);
library.add(farFaCheckCircle);
library.add(farFaComment);
library.add(farFaFolder);
library.add(farFaQuestionCircle);
library.add(farFaTimesCircle);
// NOTE please keep them SORTED

export default library;
