<template>
  <div class="d-flex flex-column align-items-center my-3">
    <span class="meta">Chat Moved</span>
    <div
      class="bubble p-2 chatMoved bg-info"
    >
      <b-row>
        <b-col class="text-right font-weight-bold">
          Type:
        </b-col>
        <b-col cols="8">
          {{ moved.data.type }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Bot:
        </b-col>
        <b-col cols="8">
          {{ getBotNameFromId(moved.data.botId) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          Worker:
        </b-col>
        <b-col cols="8">
          {{ moved.data.worker }}
        </b-col>
      </b-row>
      <!-- NOTE: We are now deleting moved chats, so the links won't work.
      <b-row>
        <b-col class="text-right font-weight-bold">
          Logging Id:
        </b-col>
        <b-col cols="8">
          {{ moved.data.loggingId }}
          <b-btn
            v-b-tooltip.hover
            title="View additional details and get direct link"
            size="sm"
            variant="primary"
            :disabled="!moved.data.botId || !moved.data.loggingId"
            @click="goToChatlogDetailsPage(moved.data.botId, moved.data.loggingId)"
          >
            <font-awesome-icon icon="external-link-alt" />
          </b-btn>
        </b-col>
      </b-row>
      -->
    </div>
    <span
      class="meta"
    >{{ dateFormatter(moved.timestamp) }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChatMove',
  props: {
    moved: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotNameFromId',
    ]),
  },
  methods: {
    dateFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleTimeString();
    },
    goToChatlogDetailsPage(botId, logId) {
      const newPage = this.$router.resolve({
        name: 'conversation-log-single',
        params: { botId, chatId: logId },
      });
      window.open(newPage.href, '_blank');
    },
  },
};
</script>

<style scoped>
.bubble.chatMoved {
  min-width: 80%;
  max-width: 80%;
}
</style>
