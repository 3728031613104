<template>
  <b-modal
    id="training-dataset"
    title="Create training dataset"
    :ok-disabled="$v.name.$invalid"
    @ok="okClicked"
    @hide="resetData"
  >
    <b-form-group label="Dataset name">
      <b-form-input v-model="name" :state="!$v.name.$invalid" />
      <b-form-invalid-feedback>
        Please enter a name for the dataset
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="hasData" label="Select topics">
      <b-form-checkbox-group
        v-model="selectedTopics"
        stacked
        :options="topicOptions"
      />
    </b-form-group>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  name: 'CreateDatasetModal',
  mixins: [validationMixin],
  props: {
    visualizationId: {
      type: [Number, Object],
      default: null,
    },
    origin: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      selectedTopics: [],
    };
  },
  computed: {
    ...mapState('dataExploration/visualizations', ['visualizationDatas']),
    ...mapGetters('dataExploration/visualizations', ['getVisualization']),
    visualizationData() {
      return this.getVisualization(this.visualizationId);
    },
    hasData() {
      return !!Object.values(this.visualizationData).length;
    },
    topicOptions() {
      return this.visualizationData?.encodedVisualization?.topics
        ?.map((e) => ({ text: e.name, value: e.id }));
    },
  },
  methods: {
    ...mapActions('nodeLabels', ['createDataset']),
    okClicked() {
      this.createDataset({
        name: this.name,
        origin: this.origin,
        visualizationId: this.visualizationId,
        includeTopics: this.selectedTopics,
      });
    },
    resetData() {
      this.name = '';
      this.selectedTopics = [];
    },
  },
  validations() {
    return {
      name: {
        required,
      },
    };
  },
};
</script>
