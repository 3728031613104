import axios from 'axios';
import endpoints from '@/js/urls';

const labelExternalState = {
  labelsExternal: {},
  isFetching: false,
};

const mutations = {
  setIsFetching(state, val) {
    state.isFetching = val;
  },
  setLabelsExternal(state, labelExternalList) {
    const labelExternalDict = {};
    for (const labelExternal of labelExternalList) {
      labelExternalDict[labelExternal.id] = labelExternal;
    }
    state.labelsExternal = labelExternalDict;
  },
};

const actions = {
  async fetchLabelsExternal({ rootState, commit }) {
    commit('setIsFetching', true);
    try {
      const resp = await axios.get(endpoints.labelExternal, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      if (resp.status === 200) {
        commit('setLabelsExternal', resp.data.result);
      }
    } finally {
      commit('setIsFetching', false);
    }
  },
};

export default {
  namespaced: true,
  state: labelExternalState,
  mutations,
  actions,
};
