<template>
  <div
    v-if="!onlyBreakMessage"
    class="d-flex flex-column"
    :class="{
      'align-items-end': isCustomer,
      'align-items-start': !isCustomer,
    }"
  >
    <div
      class="d-flex align-items-center"
      :class="isCustomer ? 'pr-2' : 'pl-2'"
    >
      <b-button
        v-if="isCustomer && isVoicebot && !simpleView"
        v-b-tooltip.noninteractive
        class="border-0 bg-transparent shadow-none p-0 mr-1 meta"
        size="sm"
        title="Fast forward the recording to this message"
        @click="playbackSkipTo({
          chatLogId,
          timestamp: chatMessage.timestamp - estimatedMessageLength,
        })"
      >
        <font-awesome-icon icon="fast-forward" />
      </b-button>
      <span class="meta">{{ isCustomer ? 'Customer' : 'Bot' }}</span>
      <voicebot-audio
        v-if="!isCustomer && isVoicebot"
        v-b-tooltip.noninteractive
        :text="chatMessage.data.query"
        small
        title="Play this message"
        class="border-0 bg-transparent shadow-none ml-1 meta"
      />
    </div>
    <div class="d-flex">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="bubble p-2"
        :class="isCustomer ? 'customer ml-5' : 'chatbot mr-5'"
        v-html="formatChatlogText(chatMessage.data.query, wasVoicebot, showSsml)"
      />
      <!-- eslint-enable -->
      <b-button
        v-if="isCustomer && !simpleView"
        v-b-tooltip.noninteractive
        class="border-0 bg-transparent shadow-none pl-1 mr-1 meta"
        title="Copy text to demo panel"
        @click="setCurrentMessage(chatMessage.data.query)"
      >
        <font-awesome-icon icon="share" />
      </b-button>
    </div>
    <span
      class="meta"
      :class="isCustomer ? 'pr-2' : 'pl-2'"
    >{{ dateFormatter(chatMessage.timestamp) }}</span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { formatChatlogText } from '@/js/utils';

import VoicebotAudio from '@/components/VoicebotAudio.vue';

export default {
  name: 'ChatMessage',
  components: { VoicebotAudio },
  props: {
    chatMessage: {
      type: Object,
      required: true,
    },
    chatLogId: {
      type: String,
      required: true,
    },
    chatLogPlatform: {
      type: String,
      required: true,
    },
    showSsml: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    simpleView() {
      return !this.$route.params?.botId;
    },
    wasVoicebot() {
      return this.isVoicebotPlatform(this.chatLogPlatform);
    },
    isVoicebot() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    isCustomer() {
      return this.chatMessage.data.author === 'customer';
    },
    dateFormatter() {
      return (timestamp) => (new Date(timestamp * 1000).toLocaleTimeString());
    },
    estimatedMessageLength() {
      const msgLength = this.chatMessage.data.query.length;
      /*
        This is used to offset the timestamp for playback when skipping
        forward to a voicebot chatlogs message. It's not perfect but better than nothing.
      */
      return (msgLength / 10) + 2;
    },
    onlyBreakMessage() {
      if (this.wasVoicebot && !this.showSsml
        && this.chatMessage.data.query.match(/^<break.*?\/>$/)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations('chatlogs', ['playbackSkipTo']),
    ...mapMutations('demopanel', ['setCurrentMessage']),
    formatChatlogText,
  },
};
</script>

<style scoped>
.chatbot {
  background-color: #2D3C5D;
}
.customer {
  background-color: #395D85;
}
::v-deep .ssmltag {
  display: inline-block;
  border-radius: 100px;
  font-weight: bold;
  padding: 0 0.5rem;
  margin-bottom: 0.05rem;
}
</style>
