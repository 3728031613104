<template>
  <b-modal
    ref="modal"
    title="New source"
    hide-footer
  >
    <b-form-group
      label="Source type"
      label-for="src-type-form"
    >
      <b-form-select
        id="src-type-form"
        v-model="newSrcType"
        :options="srcTypeOptions"
      />
    </b-form-group>
    <template v-if="newSrcType === 'file'">
      <b-form-group
        label="Name"
        label-for="new-src-file-name"
      >
        <b-form-input
          id="new-src-file-name"
          v-model="newSrcFileName"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="File"
        description="A text file with examples seperated by newlines."
        label-for="new-src-file"
      >
        <b-form-file
          id="new-src-file"
          v-model="newSrcFile"
          placeholder="Choose a file..."
          accept=".txt"
        />
      </b-form-group>
      <b-btn
        variant="primary"
        :disabled="sourceCreationInProgress"
        @click="uploadSrcFile()"
      >
        <font-awesome-icon
          v-if="sourceCreationInProgress"
          icon="spinner"
          spin
        />
        Upload
      </b-btn>
    </template>
    <template v-if="newSrcType === 'dataExploration'">
      <b-form-group
        label="Dataset"
        label-for="dataset-form"
      >
        <b-form-select
          id="dataset-form"
          v-model="dataset"
          :options="datasetOptions"
        />
      </b-form-group>
      <b-form-group
        v-if="dataset"
      >
        <label>Source datapoints must include:</label>
        <chip-list
          :completions="categoryOptions"
          :value="categoryIncludeFilter"
          placeholder="Choose categories"
          @input="value => categoryIncludeFilter = value"
        />
        <label class="mt-3">Source datapoints cannot include:</label>
        <chip-list
          :completions="categoryOptions"
          :value="categoryExcludeFilter"
          placeholder="Choose categories"
          @input="value => categoryExcludeFilter = value"
        />
      </b-form-group>
      <b-btn
        variant="primary"
        :disabled="sourceCreationInProgress
          || (categoryExcludeFilter.length === 0 && categoryIncludeFilter.length === 0)"
        @click="addSrcExternal()"
      >
        <font-awesome-icon
          v-if="sourceCreationInProgress"
          icon="spinner"
          spin
        />
        Add
      </b-btn>
    </template>
    <div v-show="newSrcType === 'node'">
      <bot-node-selector
        :value="botNode"
        :loading="sourceCreationInProgress"
        :language="selectedLanguage"
        @add="v => addSrcNode(v)"
        @input="v => updateBotNode(v)"
      >
        <template #extraInput>
          <b-form-group
            class="mt-3"
            label="Language"
            label-for="language-form"
          >
            <b-form-select
              id="language-form"
              v-model="selectedLanguage"
              :options="languageOptions"
            />
          </b-form-group>
          <b-form-checkbox v-model="includeAutolabels">
            Include autolabels
            <tooltipped-text
              value="Include labels that were created automatically based on the user behavior."
            />
          </b-form-checkbox>
        </template>
      </bot-node-selector>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState, mapActions } from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import endpoints from '@/js/urls';
import BotNodeSelector from '@/components/BotNodeSelector.vue';
import { LanguageOptions } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'NewSrcModal',
  components: { ChipList, TooltippedText, BotNodeSelector },
  data() {
    return {
      srcTypeOptions: [
        { value: null, text: 'No type selected' },
        { value: 'file', text: 'File' },
        { value: 'node', text: 'Node' },
        { value: 'dataExploration', text: 'Data Exploration' },
      ],

      newSrcType: null,
      newSrcFileName: '',
      newSrcFile: null,

      dataset: null,
      categoryIncludeFilter: [],
      categoryExcludeFilter: [],

      selectedLanguage: 'any',
      includeAutolabels: true,
      botNode: {
        botId: null,
        childNodeId: null,
        parentNodeId: null,
      },
      // Used for disabling buttons and signaling progress
      sourceCreationInProgress: false,
    };
  },
  computed: {
    ...mapState('dataExploration/categories', [
      'categories',
      'categoryIds',
    ]),
    ...mapGetters('nlu/classifier', [
      'labels',
      'activeLabel',
    ]),
    ...mapGetters('dataExploration', [
      'datasetOptions',
    ]),
    ...mapGetters('dataExploration/categories', [
      'getCategoryById',
    ]),
    categoryOptions() {
      const options = this.getOptions;
      const filteredOptions = options.filter((option) => !this.categoryIncludeFilter
        .includes(option.value) && !this.categoryExcludeFilter.includes(option.value));
      return filteredOptions.map((o) => o.value);
    },
    languageOptions() {
      const languages = [...LanguageOptions];
      languages.unshift({ value: 'any', text: 'Any language' });
      languages.unshift({ value: 'unknown', text: 'Unknown language' });
      return languages;
    },
    getOptions() {
      const options = [];
      Object.values(this.categories).forEach((x) => {
        const isMain = x.parentId === null;
        let name;
        if (isMain) {
          name = x.name;
        } else {
          const parent = this.getCategoryById(x.parentId);
          name = `${parent.name} : ${x.name}`;
        }
        options.push({ value: name, key: x.id });
        if (isMain) {
          // we also include the option to choose main category with no subcategory set
          options.push({ value: `${x.name} : No subcategory set`, key: `${x.id}-nosub` });
        }
      });
      return options;
    },
  },
  watch: {
    dataset(val) {
      if (val) {
        this.$store.dispatch('dataExploration/categories/fetchCategories', val);
      }
    },
  },
  created() {
    if (!this.$store.state.dataExploration.isInitialized) {
      this.$store.dispatch('dataExploration/fetchAllDatasets');
    }
  },
  methods: {
    valuesToKeys(values) {
      const options = this.getOptions;
      return options.filter((o) => values.includes(o.value)).map((o) => o.key);
    },
    ...mapActions('sidebar', ['showWarning']),
    show() {
      this.$refs.modal.show();
    },
    async uploadSrcFile() {
      this.sourceCreationInProgress = true;
      try {
        const formData = new FormData();
        formData.append('name', this.newSrcFileName);
        formData.append('label_id', this.activeLabel.id);
        formData.append('file', this.newSrcFile);
        await axios.post(endpoints.labelFile, formData, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
      } catch (error) {
        this.sourceCreationInProgress = false;
        throw error;
      }

      this.sourceCreationInProgress = false;
      this.afterSrcUpdate();
    },
    async addSrcExternal() {
      this.sourceCreationInProgress = true;
      const data = {
        label: this.activeLabel.id,
        dataset: this.dataset,
        includeFilter: this.valuesToKeys(this.categoryIncludeFilter),
        excludeFilter: this.valuesToKeys(this.categoryExcludeFilter),
      };
      try {
        await axios.post(endpoints.labelExternal, data, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        this.sourceCreationInProgress = false;
        this.afterSrcUpdate();
      } catch (err) {
        this.sourceCreationInProgress = false;
        if (err.response.status === 403) {
          this.showWarning({
            title: 'Permission denied',
            text: 'You do not have permissions to this dataset. You must ask a superuser to grant you access.',
            variant: 'warning',
          });
        }
        throw err;
      }
    },
    updateBotNode(payload) {
      this.$set(this.botNode, payload.key, payload.value);
    },
    async addSrcNode(payload) {
      this.sourceCreationInProgress = true;
      const data = {
        labelId: this.activeLabel.id,
        botId: this.botNode.botId,
        language: this.selectedLanguage,
        parentNodeId: this.botNode.parentNodeId,
        childNodeId: this.botNode.childNodeId,
        parentNodeName: payload.parentNodeName,
        childNodeName: payload.childNodeName,
        includeAutolabels: this.includeAutolabels,
      };
      try {
        await axios.post(endpoints.labelNode, data, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        this.sourceCreationInProgress = false;
        this.afterSrcUpdate();
      } catch (err) {
        this.sourceCreationInProgress = false;
        if (err.response.status === 403) {
          this.showWarning({
            title: 'Permission denied',
            text: 'You do not have permissions to this bot. You must ask a superuser to grant you access.',
            variant: 'warning',
          });
        }
        throw err;
      }
    },
    afterSrcUpdate() {
      this.$emit('src-updated');
      this.$refs.modal.hide();
      this.newSrcType = null;
      this.newSrcFileName = '';
      this.newSrcFile = null;
      this.dataset = null;
      this.botNode = {
        botId: null,
        childNodeId: null,
        parentNodeId: null,
      };
      this.categoryIncludeFilter = [];
      this.categoryExcludeFilter = [];
    },
  },
};
</script>

<style scoped>

</style>
