<template>
  <main role="main">
    <b-list-group>
      <b-list-group-item
        v-if="loading"
        variant="info"
        class="r-25 health-alert"
      >
        <b-row class="mx-0 py-2 h-100">
          <b-col class="my-auto">
            <h4 class="my-auto">
              Loading improvements...
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-else
        :variant="improvementsCount > 0 ? 'info' : 'success'"
      >
        <b-row
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Recompute improvements"
              class="text-secondary"
              variant="link"
              @click="fetchHealthSuggestionsLocal"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="py-2">
            <h4
              v-if="improvementsCount === 0"
              class="my-1"
            >
              No improvements detected
            </h4>
            <h4
              v-else
              class="my-1"
            >
              While checking the bot, {{ improvementsCount }}
              {{ improvementsCount > 1 ? 'improvements were' : 'improvement was' }} found.
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <date-time-lang-picker
      variant
      :getter="suggestionsFilter"
      :setter="updateSuggestionsFilter"
    />
    <b-overlay
      variant="white stat-overlay"
      :show="needsRefresh"
      opacity="1.0"
      no-center
      class="mt-3"
    >
      <template
        v-if="needsRefresh"
        #overlay
      >
        <h4
          style="padding-top: 20px"
          class="my-auto text-center pb-3"
        >
          You need to refresh improvements.
        </h4>
      </template>
      <div
        class="accordion mt-3"
        role="tablist"
      >
        <b-card
          no-body
          class="border"
        >
          <b-card-header
            v-b-toggle.accordion-1
            header-tag="header"
            class="cursor-pointer p-2 list-text health-header"
            role="tab"
          >
            <b-row
              role="tablist"
              class="mx-0"
            >
              <b-col
                cols="*"
                class="pl-2 my-auto"
              >
                <b-badge
                  v-b-tooltip.hover.noninteractive.viewport
                  :title="`${getFlowPotentialNodes.length} nodes found`"
                  variant="primary"
                  pill
                  class="py-1"
                >
                  {{ getFlowPotentialNodes.length + transferredBadgeCount }}
                </b-badge>
              </b-col>
              <b-col
                class="my-auto"
              >
                <h6 class="my-2">
                  Flows with many transfers
                </h6>
              </b-col>
            </b-row>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <p class="mb-2">
                Below is shown the number of transferred chats, as well as the proportion of covered
                chats to non-covered chats. The higher the number, the larger number of
                conversations were transferred.
              </p>
              <p class="mb-2">
                You should investigate whether it is possible to automate these conversations by
                using the automated exploration of the logs as well as reading through examples of
                conversations.
              </p>
              <p class="mb-3">
                These suggestions are based on the nodes specified in the statistics settings on
                the
                <router-link :to="{ name: 'statistics' }">
                  statistics page
                </router-link>
              </p>
              <b-list-group class="mb-2">
                <b-list-group-item v-if="isFetchingTransferredChats">
                  <span class="text-primary">
                    <font-awesome-icon
                      icon="spinner"
                      spin
                      class="mr-2"
                    />
                  </span>
                  Loading
                </b-list-group-item>
                <b-list-group-item v-else-if="getTransferredChats.transferred.count === 0">
                  0 transferred chats found.
                </b-list-group-item>
                <template v-else>
                  <b-list-group-item
                    v-for="filter in transferredListItems"
                    :id="`transferred${filter}`"
                    :key="`transferred${filter}`"
                    button
                    class="d-flex justify-content-between align-items-center list-text"
                    @click="transferredChatsClicked(filter)"
                  >
                    Transferred{{ filter ? ` ${filter}` : '' }} Chats
                    <b-badge
                      v-b-tooltip.hover.noninteractive.viewport
                      variant="primary"
                      :title="`Total number of transferred${filter ? ` ${filter.toLowerCase()}` : ''} chats`"
                      pill
                      class="py-1"
                    >
                      {{ getTransferredChatsCount(filter) }}
                      {{ getTransferredChatsFraction(filter) }}
                    </b-badge>
                  </b-list-group-item>
                </template>
              </b-list-group>
              <b-popover
                ref="transferredChatsPopover"
                :show.sync="transferredChatsPopover.show"
                placement="bottom"
                :target="`transferred${transferredChatsPopover.coverageFilter}`"
              >
                <b-button
                  block
                  size="sm"
                  variant="primary"
                  @click="viewTransferredChats(transferredChatsPopover.coverageFilter)"
                >
                  View conversations
                </b-button>
                <b-button
                  v-if="transferredChatsPopover.showExplore"
                  block
                  size="sm"
                  variant="primary"
                  @click="exploreTransferredCovered(transferredChatsPopover.coverageFilter)"
                >
                  Explore
                </b-button>
              </b-popover>
              <p class="mb-2">
                Below is a list of "flow nodes" that most commonly led to a transfer. The higher
                the number next a node, the larger number of conversations were transferred.
              </p>
              <b-list-group>
                <b-list-group-item v-if="isFetchingFlowPotentials">
                  <span class="text-primary">
                    <font-awesome-icon
                      icon="spinner"
                      spin
                      class="mr-2"
                    />
                  </span>
                  Loading
                </b-list-group-item>
                <b-list-group-item v-else-if="getFlowPotentialNodes.length === 0">
                  0 nodes found.
                </b-list-group-item>
                <template v-else>
                  <b-list-group-item
                    v-for="node in getFlowPotentialNodes"
                    :id="node.id"
                    :key="node.name"
                    button
                    :disabled="node.potential === 0"
                    class="d-flex justify-content-between align-items-center list-text"
                    @click="flowPotentialClicked(node)"
                  >
                    {{ node.name }}
                    <b-badge
                      v-b-tooltip.hover.noninteractive.viewport
                      variant="primary"
                      title="Total number of times chats went through a node and got transferred"
                      pill
                      class="py-1"
                    >
                      {{ node.potential }} ({{ (node.fraction * 100).toFixed(1) }}%)
                    </b-badge>
                  </b-list-group-item>
                </template>
              </b-list-group>
              <b-popover
                ref="flowPotentialsPopover"
                :show.sync="flowPotentialsPopover.show"
                placement="bottom"
                :target="flowPotentialsPopover.nodeId"
              >
                <b-button
                  block
                  size="sm"
                  variant="primary"
                  @click="viewFlowPotentialChats(flowPotentialsPopover.nodeId)"
                >
                  View conversations
                </b-button>
                <b-button
                  v-if="flowPotentialsPopover.showExplore"
                  block
                  :disabled="!flowPotentialsPopover.showExplore"
                  size="sm"
                  variant="primary"
                  @click="exploreFlow(flowPotentialsPopover.nodeId)"
                >
                  Explore
                </b-button>
              </b-popover>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card
          no-body
          class="border"
        >
          <b-card-header
            v-b-toggle.accordion-2
            header-tag="header"
            class="cursor-pointer p-2 list-text health-header"
            role="tab"
          >
            <b-row
              role="tablist"
              class="mx-0"
            >
              <b-col
                cols="*"
                class="pl-2 my-auto"
              >
                <b-badge
                  v-b-tooltip.hover.noninteractive.viewport
                  variant="primary"
                  pill
                  :title="`${getSimpleNodeFallbackNodes.length} nodes found`"
                  class="py-1"
                >
                  {{ getSimpleNodeFallbackNodes.length }}
                </b-badge>
              </b-col>
              <b-col
                class="my-auto"
              >
                <h6 class="my-2">
                  Nodes with many fallbacks
                </h6>
              </b-col>
            </b-row>
          </b-card-header>
          <b-collapse
            id="accordion-2"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <p class="mb-2">
                Below is a list of nodes that often are followed by a fallback.
              </p>
              <p class="mb-3">
                This is often caused by the bot not understanding what the user wrote, and a
                high number could indicate that more examples should be provided to the bot or
                that the matching property was not properly configured.
              </p>
              <b-list-group>
                <b-list-group-item v-if="isFetchingSimpleNodeFallback">
                  <span class="text-primary">
                    <font-awesome-icon
                      icon="spinner"
                      spin
                      class="mr-2"
                    />
                  </span>
                  Loading
                </b-list-group-item>
                <b-list-group-item v-else-if="getSimpleNodeFallbackNodes.length === 0">
                  0 nodes found.
                </b-list-group-item>
                <template v-else>
                  <b-list-group-item
                    v-for="node in getSimpleNodeFallbackNodes"
                    :key="node.name"
                    button
                    :disabled="node.fallbacks === 0"
                    class="d-flex justify-content-between align-items-center list-text"
                    @click="viewFallbackChatsWithNode(node.id)"
                  >
                    {{ node.name }}
                    <b-badge
                      v-b-tooltip.hover.noninteractive.viewport
                      variant="primary"
                      pill
                      title="Total number of chats where path went from the
                    node directly to fallback"
                      class="py-1"
                    >
                      {{ node.fallbacks }} ({{ (node.fraction * 100).toFixed(1) }}%)
                    </b-badge>
                  </b-list-group-item>
                </template>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-overlay>

    <b-modal
      id="visualizationModal"
      centered
      size="xl"
      title="Explore flow potential"
      @hide="latestVisualizationId = null"
    >
      <b-row v-if="isFetchingData || localIsFetching">
        <b-col class="text-center">
          <b-spinner />
        </b-col>
      </b-row>
      <DataDisplayTopics
        v-else-if="getVisualizationData !== undefined"
        :data="getVisualizationData"
        :visualization-id="latestVisualizationId"
        :table-fields="['name', 'count']"
      />
      <div v-else>
        The visualization failed to produce.
      </div>
      <template
        v-if="!isFetchingData && !localIsFetching && getVisualizationData !== undefined"
        #modal-footer
      >
        <b-row class="w-100">
          <b-col cols="auto">
            <b-button
              v-b-modal.training-dataset
              variant="primary"
            >
              Create training dataset
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <create-dataset-modal origin="health page" :visualization-id="latestVisualizationId" />
  </main>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import DateTimeLangPicker from '@/components/DateTimeLangPicker.vue';
import CreateDatasetModal from '@/pages/Training/CreateDatasetModal.vue';
import DataDisplayTopics from '@/components/StatisticsTopics/DataDisplayTopics.vue';

export default {
  name: 'HealthSuggestions',
  components: {
    CreateDatasetModal,
    DateTimeLangPicker,
    DataDisplayTopics,
  },
  data() {
    return {
      needsRefresh: false,
      flowPotentialsPopover: {
        show: false,
        nodeId: '',
        nodeName: '',
      },
      transferredChatsPopover: {
        show: false,
        coverageFilter: 'all',
        showExplore: false,
      },
      latestVisualizationId: null,
      localIsFetching: false,
      transferredListItems: ['', 'Covered', 'Non-Covered'],
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['getBotId']),
    ...mapGetters('botManipulation/activeBot', ['nameOfId', 'nodeById']),
    ...mapGetters('dataExploration/visualizations', ['getVisualization']),
    ...mapGetters('chatlogs', ['availableDataOrigins']),
    ...mapGetters('healthSuggestions', [
      'isFetchingFlowPotentials',
      'isFetchingTransferredChats',
      'isFetchingSimpleNodeFallback',
      'getFlowPotentials',
      'getTransferredChats',
      'getSimpleNodeFallbacks',
      'suggestionsFilter',
    ]),
    ...mapState('healthSuggestions', ['improvementsTimeDelta']),
    ...mapGetters('nodeInterpretations', ['transferNodes']),
    ...mapState('dataExploration/visualizations', ['isFetchingData']),
    loading() {
      return this.isFetchingFlowPotentials
       || this.isFetchingSimpleNodeFallback;
    },
    improvementsCount() {
      return this.getFlowPotentials(5).length + this.getSimpleNodeFallbacks(5).length;
    },
    getVisualizationData() {
      const vis = this.getVisualization(this.latestVisualizationId);
      const returnValue = vis.encodedVisualization;
      return returnValue;
    },
    getFlowPotentialNodes() {
      const flowPotentials = this.getFlowPotentials(5);
      const objects = [];
      for (const node of flowPotentials) {
        if (node[1] > 0) {
          objects.push({
            name: this.nameOfId(node[0]),
            id: node[0],
            potential: node[1],
            fraction: node[1] / node[2],
          });
        }
      }
      return objects;
    },
    getSimpleNodeFallbackNodes() {
      const simpleNodeFallbacks = this.getSimpleNodeFallbacks(5);
      const objects = [];
      for (const node of simpleNodeFallbacks) {
        objects.push({
          id: node[0],
          name: this.nameOfId(node[0]),
          fallbacks: node[1],
          fraction: node[1] / node[2],
        });
      }
      return objects;
    },
    transferredBadgeCount() {
      return (this.getTransferredChats.transferred.count > 0)
             + (this.getTransferredChats.transferredCovered.count > 0)
             + (this.getTransferredChats.transferredNonCovered.count > 0);
    },
  },
  mounted() {
    this.fetchDataOrigins().then(() => {
      this.fetchHealthSuggestions();
    });
  },
  methods: {
    ...mapActions('dataExploration/visualizations', [
      'createVisualizationFromFlowPotential',
      'createVisualizationFromTransferredCovered',
      'fetchVisualizationData',
    ]),
    ...mapActions('chatlogs', ['fetchDataOrigins']),
    ...mapActions('healthSuggestions', ['fetchHealthSuggestions']),
    ...mapMutations('task', ['addTask']),
    ...mapMutations('healthSuggestions', ['updateSuggestionsFilter']),
    fetchHealthSuggestionsLocal() {
      this.needsRefresh = false;
      this.fetchHealthSuggestions();
    },
    flowPotentialClicked(node) {
      this.flowPotentialsPopover.nodeId = node.id;
      this.flowPotentialsPopover.nodeName = node.name;
      this.flowPotentialsPopover.showExplore = node.potential >= 100;
      this.$nextTick(() => {
        this.flowPotentialsPopover.show = !this.flowPotentialsPopover.show;
      });
    },
    transferredChatsClicked(coverageFilter) {
      this.transferredChatsPopover.coverageFilter = coverageFilter;
      const transferCount = this.getTransferredChats[`transferred${coverageFilter.replace('-', '')}`].count;
      this.transferredChatsPopover.showExplore = transferCount > 100;
      this.$nextTick(() => {
        this.transferredChatsPopover.show = !this.transferredChatsPopover.show;
      });
    },
    viewFallbackChatsWithNode(nodeId) {
      const includeNodes = [nodeId, 'fallback'];
      const startDate = new Date();
      // the 7 in the line below marks that the filter should be put on the last week
      startDate.setDate(startDate.getDate() - 7);
      const endDate = new Date();
      const url = this.$router.resolve({
        name: 'conversation-logs',
        query: {
          endDate: endDate.getTime(),
          startDate: startDate.getTime(),
          includeNodes,
          requireImmediateOrder: true,
        },
      }).href;
      window.open(url, '_blank');
    },
    getTransferredChatsCount(payload) {
      return this.getTransferredChats[`transferred${payload.replace('-', '')}`]?.count;
    },
    getTransferredChatsFraction(payload) {
      const value = this.getTransferredChats[`transferred${payload.replace('-', '')}`]?.fraction;
      return `(${(value || 0).toFixed(1)}%)`;
    },
    async exploreFlow(nodeId) {
      this.flowPotentialsPopover.show = false;
      this.localIsFetching = true;
      this.$bvModal.show('visualizationModal');
      this.latestVisualizationId = null;
      const visualizationId = await this.createVisualizationFromFlowPotential({
        botId: this.getBotId,
        variantId: this.suggestionsFilter.selectedVariant,
        flowNodeId: nodeId,
        daysDelta: this.improvementsTimeDelta,
        slectedDataOrigins: this.availableDataOrigins.map((x) => x.rawValue),
        name: 'temp',
        numTopics: Math.min(20, this.nodeById(nodeId).children.length + 3),
        language: 'en', // TODO: Use bot language for stopwords
      });
      this.latestVisualizationId = visualizationId;
      await this.fetchVisualizationData({ visualizationId });
      this.localIsFetching = false;
    },
    async exploreTransferredCovered(coverageFilter) {
      this.transferredChatsPopover.show = false;
      this.localIsFetching = true;
      this.$bvModal.show('visualizationModal');
      this.latestVisualizationId = null;
      const numRelevantFlowNodes = this.getTransferredChats[`transferred${coverageFilter.replace('-', '')}`].flowNodes;
      const visualizationId = await this.createVisualizationFromTransferredCovered({
        botId: this.getBotId,
        variantId: this.suggestionsFilter.selectedVariant,
        coverageFilter: coverageFilter.toLowerCase(),
        daysDelta: this.improvementsTimeDelta,
        selectedDataOrigins: this.availableDataOrigins.map((x) => x.rawValue),
        name: 'temp',
        numTopics: Math.min(20, numRelevantFlowNodes + 4),
        language: 'en', // TODO: Use bot language for stopwords
      });
      this.latestVisualizationId = visualizationId;
      await this.fetchVisualizationData({ visualizationId });
      this.localIsFetching = false;
    },
    viewFlowPotentialChats(nodeId) {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      const endDate = new Date();
      const routeData = this.$router.resolve({
        name: 'conversation-logs',
        query: {
          endDate: endDate.getTime(),
          startDate: startDate.getTime(),
          includeNodes: [nodeId],
          selfServedFilter: 'not self-served',
        },
      });
      window.open(routeData.href, '_blank');
    },
    viewTransferredChats(coverageFilter) {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - this.improvementsTimeDelta);
      const endDate = new Date();
      const query = {
        endDate: endDate.getTime(),
        startDate: startDate.getTime(),
        requireOnlyOne: true,
        coverageFilter: coverageFilter.toLowerCase() || 'all',
        selfServedFilter: 'not self-served',
        selectedVariant: this.suggestionsFilter.selectedVariant,
      };
      const routeData = this.$router.resolve({
        name: 'conversation-logs',
        query,
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style scoped>
.list-text *{
  font-size:0.9375rem;
}</style>
