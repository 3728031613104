<template>
  <div>
    <!-- Metric specific result-details presentation for metric USES_FRESH_CLASSIFIERS -->
    <template
      v-if="tableItems.length === 0"
    >
      There are no classifiers in bot, so freshness cannot be reported.
    </template>
    <b-table-lite
      v-else
      borderless
      :items="tableItems"
    />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ClassifierFreshnessResult',
  props: {
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableItems() {
      const tableItems = [];
      const now = moment();
      for (const value of Object.values(this.insightDetails.classifier_details)) {
        const { outdated, name, created } = value;

        const timestamp = moment(new Date(created));
        const displayTextForCreation = moment.duration(timestamp.diff(now)).humanize(true);
        let rowVariant = '';
        if (outdated) {
          rowVariant = 'danger';
        }
        tableItems.push({
          ClassifierName: name,
          created: displayTextForCreation,
          _rowVariant: rowVariant,
        });
      }
      return tableItems;
    },
  },
};
</script>
