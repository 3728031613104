<template>
  <b-row no-gutters>
    <b-col cols="12">
      <div v-if="hasLabel" class="mb-0 label text-truncate">
        <span v-b-tooltip.hover.left.noninteractive="label">{{ label }}</span>
      </div>
      <div
        class="bar"
        :style="`background-color:${bgColor}; ${!hasLabel ? 'margin-top: 24px;' : ''}`"
        :class="`${getBorderRadius} ${getTextColor}`"
      >
        {{ value || 0 }}%
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'BarPiece',
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
    bgColor: {
      required: true,
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasLabel() {
      return !!this.label.length;
    },
    getIndex() {
      if (['Flow covered', 'Self-serviced', 'Partial resolution rate'].includes(this.label)) {
        return 0;
      }
      if (this.label === 'SupSearch covered') {
        return 1;
      }
      return 2;
    },
    getBorderRadius() {
      if (this.getIndex === 1) {
        return '';
      }
      return this.getIndex === 0 ? 'r-50-left' : 'r-50-right';
    },
    getTextColor() {
      return this.bgColor === 'bg-secondary' ? 'text-black' : 'text-white';
    },
  },
  methods: {

  },
};
</script>
<style scoped>
.bar{
  height: 32px;
  line-height: 32px;
}
.label{
  height: 24px;
  width: 100%;
  font-size: 1rem;
  white-space: nowrap;
}
</style>
