<template>
  <b-col v-show="!filterOut" cols="12">
    <b-row>
      <b-col
        :id="node.id"
        cols="auto"
        class="node-grid-col text-left text-truncate"
        :class="[{
          'cursor-pointer': hasChildren,
          'text-muted': hasRunningTasks || loadingData,
        }]"
        :style="getNameWidth"
        @click.ctrl="openEditNodePage"
        @click="nodeClicked"
      >
        <font-awesome-icon class="mx-1" :icon="showChildren ? 'angle-down' : 'angle-right'" />
        {{ getName }}
      </b-col>
      <b-col
        v-if="loadingData"
        cols="auto"
        :style="getLoadingWidth"
        class="node-grid-col text-center"
      >
        <b-spinner small />
      </b-col>
      <template v-else>
        <b-col :id="`count-popover-${path}`" cols="auto" style="width: 8%;" class="node-grid-col text-center">
          {{ getValue('num_chats', true) }}
          <node-details-popover v-if="!entirePeriod" :values="getValue('num_chats')" :target="`count-popover-${path}`" />
        </b-col>
        <b-col :id="`automation-popover-${path}`" cols="auto" style="width: 9%;" class="node-grid-col d-flex align-items-center">
          <b-progress show-progress :value="getValue('automation_rate', true)" :max="100" class="w-100" />
          <node-details-popover v-if="!entirePeriod" :values="getValue('automation_rate')" :target="`automation-popover-${path}`" />
        </b-col>
        <b-col :id="`self-service-popover-${path}`" cols="auto" style="width: 10%;" class="node-grid-col text-center">
          {{ typeof getValue('self_service', true) === 'number' ? `${getValue('self_service', true).toFixed(1)}%` : '-' }}
          <node-details-popover v-if="!entirePeriod" :values="getValue('self_service')" :target="`self-service-popover-${path}`" />
        </b-col>
        <b-col :id="`optput-popover-${path}`" cols="auto" style="width: 10%;" class="node-grid-col text-center">
          {{ typeof getValue('percent_optout', true) === 'number' ? `${getValue('percent_optout', true).toFixed(1)}%` : '-' }}
          <node-details-popover v-if="!entirePeriod" :values="getValue('percent_optout')" :target="`optput-popover-${path}`" />
        </b-col>
        <b-col :id="`resolution-popover-${path}`" cols="auto" style="width: 9%;" class="node-grid-col text-center">
          {{ typeof getValue('partial_resolution', true) === 'number' ? `${getValue('partial_resolution', true).toFixed(1)}%` : '-' }}
          <node-details-popover v-if="!entirePeriod" :values="getValue('partial_resolution')" :target="`resolution-popover-${path}`" />
        </b-col>

        <b-col v-if="isExternalStatsEnabled()" :id="`rating-popover-${path}`" cols="auto" style="width: 9%;" class="node-grid-col text-center">
          <font-awesome-icon icon="star" />
          {{ typeof getValue('rating', true) === 'number' ? `${getValue('rating', true).toFixed(1)}` : '-' }}
          <node-details-popover v-if="!entirePeriod" :values="getValue('rating')" :target="`rating-popover-${path}`" />
        </b-col>
        <b-col v-if="isExternalStatsEnabled()" :id="`rating-count-popover-${path}`" cols="auto" style="width: 9%;" class="node-grid-col text-center">
          {{ typeof getValue('rating_count', true) === 'number' ? `${getValue('rating_count', true)}` : '-' }}
          <node-details-popover v-if="!entirePeriod" :values="getValue('rating_count')" :target="`rating-count-popover-${path}`" />
        </b-col>
        <b-col cols="auto" style="width: 6%;" class="node-grid-col text-center">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport="'Edit group'"
            size="sm"
            :disabled="!node.isGroup || node.id === 'flow'"
            @click="$root.$emit('edit-node-group', node.id)"
          >
            <font-awesome-icon
              icon="pencil"
            />
          </b-button>
        </b-col>
        <b-col
          v-if="exportMode"
          cols="auto"
          class="py-2 px-1 text-center cursor-pointer"
          style="width: 5%; border: 1px solid #eee;"
          @click="selected = !selected"
        >
          <font-awesome-icon
            :color="selected ? '#004463' : '#D3D7D9'"
            :icon="selected ? 'check-square' : 'square-xmark'"
          />
        </b-col>
      </template>
    </b-row>
    <b-row v-if="showChildren">
      <node-grid-row
        v-for="(nodeChild, index) in
          sortedNodeDetailsChildren(nodeData.children, nameOfId, activeSorting)"
        :key="index"
        :indentation="indentation + 10"
        :path="getPath(nodeChild.id)"
        :node="nodeChild"
        :export-mode="exportMode"
      />
    </b-row>
    <b-tooltip
      :target="node.id"
      triggers="hover"
      boundary="viewport"
      :delay=" { show: 500, hide: 100 }"
    >
      {{ getName }}
    </b-tooltip>
  </b-col>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { sortedNodeDetailsChildren } from '@/js/utils';
import NodeDetailsPopover from '@/pages/Statistics/NodeDetailsPopover.vue';
import { isExternalStatsEnabled } from '@/js/featureFlags';

export default {
  name: 'NodeGridRow',
  components: { NodeDetailsPopover },
  props: {
    node: {
      type: Object,
      required: true,
    },
    indentation: {
      type: Number,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
    exportMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nameOfId',
    ]),
    ...mapGetters('statistics', [
      'getNodeDetailsData',
      'nodeDetailsGroupData',
      'filterOutNode',
      'getRunningNodeTaskIds',
      'nodeDetailsLabels',
      'getNodeDetailsGroups',
    ]),
    ...mapGetters('statistics/filters', [
      'statsFilter',
      'nodesFilter',
      'activeSorting',
    ]),
    ...mapGetters('nodeInterpretations', ['flowNodes']),
    isChild() {
      return this.path.length > 1;
    },
    entirePeriod() {
      return this.statsFilter.selectedGroupBy === null;
    },
    showChildren: {
      get() {
        return this.nodeData.showChildren;
      },
      set(value) {
        if (this.isGroup) {
          this.setNodeDetailsGroupProperty({ id: this.node.id, field: 'showChildren', data: value });
        } else if (!this.isChild) {
          this.setNodeDetailsData({ nodeId: this.node.id, field: 'showChildren', data: value });
        } else {
          this.setNodeChildDetailsData({ path: this.nodeData.path, field: 'showChildren', data: value });
        }
      },
    },
    hasRunningTasks() {
      return !!this.getRunningNodeTaskIds.length;
    },
    loadingData() {
      return !!Object.values(this.nodeData).some((e) => e?.state === 'PENDING' || e?.state === 'RUNNING');
    },
    filterOut() {
      if (this.loadingData) return false;
      if (this.isGroup) return this.filterOutNode(this.nodeData, this.node.name);
      return this.filterOutNode(this.nodeData, this.nameOfId(this.node.id));
    },
    disableDelete() {
      return this.flowNodes.includes(this.node.id) || this.isChild;
    },
    getName() {
      return this.isGroup ? this.getNodeDetailsGroups[this.node.id].name
        : this.nameOfId(this.node.id);
    },
    isGroup() {
      return !!this.node.isGroup;
    },
    nodeData() {
      let current;
      if (this.isGroup) {
        current = this.nodeDetailsGroupData[this.path[0]];
      } else {
        current = this.getNodeDetailsData[this.path[0]];
        this.path.slice(1).forEach((element) => {
          current = current.children[element];
        });
      }
      return current || {};
    },
    hasChildren() {
      return this.isGroup ? !!Object.values(this.nodeData.children).length
        : !!this.nodeById(this.node.id).children.length;
    },
    getNameWidth() {
      const baseWidth = this.isExternalStatsEnabled() ? '30%' : '48%';
      const exportWidth = this.isExternalStatsEnabled() ? '25%' : '43%';
      return `padding-left: ${this.indentation}px; width: ${this.exportMode ? exportWidth : baseWidth};`;
    },
    getLoadingWidth() {
      const baseWidth = this.isExternalStatsEnabled() ? 'width: 70%;' : 'width: 52%;';
      const exportWidth = this.isExternalStatsEnabled() ? 'width: 75%;' : 'width: 57%;';
      return this.exportMode ? exportWidth : baseWidth;
    },
    selected: {
      get() {
        return this.nodeData.selected;
      },
      set(value) {
        if (this.isGroup) {
          this.setNodeDetailsGroupProperty({ id: this.node.id, field: 'selected', data: value });
        } else {
          this.setNodeChildDetailsData({ path: this.nodeData.path, field: 'selected', data: value });
        }
        const children = Object.values(this.nodeData.children);
        if (children.length) {
          this.setChildrenSelected(value, children);
        }
      },
    },
  },
  methods: {
    sortedNodeDetailsChildren,
    isExternalStatsEnabled,
    ...mapMutations('statistics', ['setNodeDetailsData', 'setNodeChildDetailsData', 'setNodeDetailsGroupProperty']),
    ...mapActions('statistics', ['fetchNodeChildDetailsData']),
    setChildrenSelected(value, items) {
      items.forEach((child) => {
        this.setNodeChildDetailsData({ path: child.path, field: 'selected', data: value });
        const children = Object.values(child.children);
        if (children.length) {
          this.setChildrenSelected(value, children);
        }
      });
    },
    getPath(childId) {
      return this.isGroup ? [childId] : this.path.concat([childId]);
    },
    nodeClicked() {
      if (this.hasChildren && !this.loadingData && !this.hasRunningTasks) {
        if (!this.showChildren && !this.isGroup) {
          this.fetchNodeChildDetailsData({
            children: this.isGroup ? Object.keys(this.nodeData.children)
              : this.nodeById(this.node.id).children,
            path: this.nodeData.path,
          });
        }
        this.showChildren = !this.showChildren;
      }
    },
    getValue(key, sum = false) {
      if (sum) {
        const data = this.nodeData[key]?.info || [];

        const total = data.reduce((accumulator, currentValue) => accumulator + currentValue,
          0);
        if (!['num_chats', 'rating_count'].includes(key)) {
          const length = data.filter((e) => e > 0).length;
          return length > 0 ? total / length : null;
        }
        return total;
      }
      return this.nodeData[key]?.info;
    },
    openEditNodePage() {
      if (!this.node.isGroup) {
        const route = this.$router.resolve(this.editNodeLink(this.node.id));
        window.open(route.href, '_blank');
      }
    },
  },
};
</script>

<style scoped>
.popover{
  max-width: 500px !important;
}
::v-deep .popover-body{
 white-space: nowrap;
}
.popover-content{
  max-width: 500px;
  overflow-x: auto;
}
</style>
