import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';
import {
  DATASOURCE_CREATE_IN_PROGRESS,
  DATASOURCE_CREATE_FAILED,
  DATASOURCE_EDIT_IN_PROGRESS,
  DATASOURCE_EDIT_FAILED,
  DATASOURCE_DELETED_SUCCESSFULLY,
  DATASOURCE_DELETION_ERROR,
  DATASOURCE_DELETION_IN_PROGRESS,
} from '@/js/constants';

const dataSourceState = () => ({
  dataSourceEditingState: null,
  dataSourceCreatingState: null,
  dataSourceUploadProgress: undefined,
  dataSourceDeletingState: {},
  dataSourceDeletionTasks: {}, // Maps a dataSourceId to a taskId
});

export const dataSourcesGetters = {
  isCreating(state) {
    return state.dataSourceCreatingState === DATASOURCE_CREATE_IN_PROGRESS;
  },
  isDeleting: (state) => (dataSourceId) => {
    const result = DATASOURCE_DELETION_IN_PROGRESS === state.dataSourceDeletingState[dataSourceId];
    return result;
  },
};

/*
Conventions:
- The payload to any mutation or action must be a dict.
 */
const mutations = {
  setDataSourceEditingState(state, { editState }) {
    state.dataSourceEditingState = editState;
  },
  setDataSourceCreatingState(state, { createState }) {
    state.dataSourceCreatingState = createState;
  },
  setDataSourceUploadProgress(state, { uploadProgress }) {
    state.dataSourceUploadProgress = uploadProgress;
  },
  updateDeletionStateForDataSource(state, { dataSourceId, currentDeletionState }) {
    Vue.set(state.dataSourceDeletingState, dataSourceId, currentDeletionState);
  },
  addDeletionTask(state, { dataSourceId, taskId }) {
    Vue.set(state.dataSourceDeletionTasks, dataSourceId, taskId);
  },
  deleteDeletionTask(state, { dataSourceId }) {
    Vue.delete(state.dataSourceDeletionTasks, dataSourceId);
  },
};

function dataFromDataSource(dataSource) {
  if (dataSource.file !== null) {
    // Uploading file, so pack everything as file
    const data = new FormData();
    data.append('file', dataSource.file);
    const json = JSON.stringify(dataSource);
    const blob = new Blob([json], {
      type: 'application/json',
    });
    data.append('data_source', blob);
    return data;
  }
  return dataSource;
}

function computeUploadProgress(progressEvent) {
  return Math.round(progressEvent.loaded / progressEvent.total) * 100;
}

const actions = {
  async createDataSource({ rootState, dispatch, commit }, { dataSource }) {
    commit('setDataSourceCreatingState', { createState: DATASOURCE_CREATE_IN_PROGRESS });
    try {
      const data = dataFromDataSource(dataSource);
      commit('setDataSourceUploadProgress', { uploadProgress: 0 });
      const resp = await axios.post(endpoints.dataExplorationDataSources, data, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        onUploadProgress(progressEvent) {
          commit('setDataSourceUploadProgress', {
            uploadProgress: computeUploadProgress(progressEvent),
          });
        },
      });
      if (resp.data.celery_id) {
        commit(
          'task/addTask',
          {
            celeryId: resp.data.celery_id,
            callbackDone: () => {
              dispatch('dataExploration/loadCurrentDataset', {}, { root: true });
              commit('setDataSourceCreatingState', { createState: null });
            },
            callbackFailed: () => {
              dispatch('sidebar/showWarning', {
                title: 'An error occured',
                text: 'Backend ran into problems while extracting data source file.',
                variant: 'danger',
              }, { root: true });
              dispatch('dataExploration/loadCurrentDataset', {}, { root: true });
              commit('setDataSourceCreatingState', { createState: DATASOURCE_CREATE_FAILED });
            },
          },
          { root: true },
        );
        return resp.data.celery_id;
      }
      if (resp.data.celery_id === null) { // implies node source
        dispatch('dataExploration/loadCurrentDataset', {}, { root: true });
        commit('setDataSourceCreatingState', { createState: null });
      }
      return null;
    } catch (e) {
      commit('setDataSourceCreatingState', { createState: DATASOURCE_CREATE_FAILED });
      throw e;
    }
  },
  async editDataSource({ rootState, dispatch, commit }, { dataSource }) {
    commit('setDataSourceEditingState', { editState: DATASOURCE_EDIT_IN_PROGRESS });
    try {
      const data = dataFromDataSource(dataSource);
      commit('setDataSourceUploadProgress', { uploadProgress: 0 });
      await axios.put(endpoints.dataExplorationDataSources, data, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        onUploadProgress(progressEvent) {
          commit('setDataSourceUploadProgress', {
            uploadProgress: computeUploadProgress(progressEvent),
          });
        },
      });
      commit('setDataSourceEditingState', { editState: null });
      await dispatch('dataExploration/loadCurrentDataset', {}, { root: true });
    } catch (e) {
      commit('setDataSourceCreatingState', { editState: DATASOURCE_EDIT_FAILED });
    }
  },
  async deleteDataSource({ rootState, dispatch, commit }, { dataSourceId }) {
    try {
      const response = await axios.delete(endpoints.dataExplorationDataSources, {
        data: { id: dataSourceId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      const taskId = response.data.task_id;

      // Small hack to start showing spinner in UI immediately.
      commit('updateDeletionStateForDataSource', {
        dataSourceId,
        currentDeletionState: DATASOURCE_DELETION_IN_PROGRESS,
      });
      commit('addDeletionTask', { dataSourceId, taskId });
      dispatch('startPollingForDeletionUpdates');
    } catch (e) {
      commit('updateDeletionStateForDataSource', {
        dataSourceId,
        currentDeletionState: DATASOURCE_DELETION_ERROR,
      });
    }
  },
  async deleteDataPoint({ rootState }, { datasetId, dataPoint }) {
    const url = `${endpoints.dataExplorationDataPoint + datasetId}/`;
    await axios.delete(url, {
      data: { id: dataPoint.id },
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
  },
  async editDataPoint({ rootState }, { datasetId, dataPointId, dataPointText }) {
    const url = `${endpoints.dataExplorationDataPoint + datasetId}/`;
    await axios.put(url,
      {
        id: dataPointId,
        text: dataPointText,
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      });
  },
  async startPollingForDeletionUpdates({
    rootState, state, dispatch, commit,
  }) {
    const intervalMs = 1000; // Poll every second
    setTimeout(async () => {
      Object.entries(state.dataSourceDeletionTasks).forEach(async ([dataSourceId, taskId]) => {
        if (DATASOURCE_DELETED_SUCCESSFULLY === state.dataSourceDeletingState[dataSourceId]
          || DATASOURCE_DELETION_ERROR === state.dataSourceDeletingState[dataSourceId]) {
          return; // No need to do more.
        }
        try {
          const response = await axios.get(endpoints.dataExplorationDataSourceDeletionTaskStatus, {
            params: { task_id: taskId },
            headers: { Authorization: `JWT ${rootState.auth.jwt}` },
          });
          if (DATASOURCE_DELETED_SUCCESSFULLY === response.data.task_status) {
            // Update state: Stop polling for this task by removing it from list of ongoing tasks
            commit('deleteDeletionTask', { dataSourceId });
            commit('updateDeletionStateForDataSource', {
              dataSourceId,
              currentDeletionState: DATASOURCE_DELETED_SUCCESSFULLY,
            });
            // Remove datasource from list
            await dispatch('dataExploration/fetchAllDatasets', {}, { root: true });
          } else if (DATASOURCE_DELETION_IN_PROGRESS === response.data.task_status) {
            // Update state
            commit('updateDeletionStateForDataSource', {
              dataSourceId,
              currentDeletionState: DATASOURCE_DELETION_IN_PROGRESS,
            });

            // Schedule new poll
            dispatch('startPollingForDeletionUpdates');
          } else if (DATASOURCE_DELETION_ERROR === response.data.task_status) {
            // Update state accordingly
            commit('updateDeletionStateForDataSource', {
              dataSourceId,
              currentDeletionState: DATASOURCE_DELETION_ERROR,
            });
            // Currently frontend does not handle a deletion error, so we just piggyback the error-
            // handling already in place.
            throw new Error(`Unhandled status from backend ${response.data}`);
          } else {
            // Update state accordingly
            commit('updateDeletionStateForDataSource', {
              dataSourceId,
              currentDeletionState: DATASOURCE_DELETION_ERROR,
            });

            throw new Error(`Unhandled status from backend ${response.data}`);
          }
        } catch (e) {
          // TODO: Handle error somewhere
          console.log('Error occurred: ', e);
          throw e;
        }
      });
    }, intervalMs);
  },
};

export default {
  namespaced: true,
  state: dataSourceState,
  getters: dataSourcesGetters,
  mutations,
  actions,
};
