<template>
  <main
    role="main"
  >
    <b-card
      title="Bot staging"
      class="r-75"
      body-class="p-3"
    >
      <div>
        Here you can stage the current bot for production. Staging a bot creates a static image
        of the bot that can then be deployed at any time in the future.
      </div>
      <hr class="mb-2">
      <b-row
        align-h="center"
      >
        <b-col>
          <b-btn
            v-b-modal.addStagedBotModal
            class="mt-1 mr-2"
            variant="primary"
          >
            <font-awesome-icon icon="plus" /> Add current version to staged bots
          </b-btn>
          <b-button
            class="mt-1 mr-2"
            :to="route('diff')"
          >
            <font-awesome-icon icon="search" /> Compare bots
          </b-button>

          <b-btn
            class="mt-1 mr-2"
            @click="downloadCurrentBot(getBotId())"
          >
            <font-awesome-icon icon="download" /> Download current version
          </b-btn>
          <b-btn
            v-if="isSuperUser || isUserNormal"
            class="mt-1"
            :to="route('history')"
          >
            <font-awesome-icon icon="history" /> Bot history
          </b-btn>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table for displaying all staged bots -->

    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row
        v-if="numberOfRows > pagination.perPage"
        align-h="center"
      >
        <b-col cols="*">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="numberOfRows"
            :per-page="pagination.perPage"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        :items="sortedStagedBots"
        :fields="stagedBotsFields"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        show-empty
        empty-text="No bots have been staged yet."
        sort-by="timestamp"
        :sort-desc="true"
        responsive
        hover
        small
        :busy="loading"
        class="mb-0"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(timestamp)="data">
          <pretty-date-time :raw-time="data.item.timestamp" />
        </template>

        <template #cell(actions)="data">
          <b-button-group>
            <b-button
              v-b-tooltip.hover.noninteractive.viewport
              title="Copy ID to clipboard"
              class="table-button"
              @click="copyToClipboard(data.item.id)"
            >
              <font-awesome-icon icon="copy" />
            </b-button>
            <b-button
              v-b-tooltip.hover.noninteractive.viewport
              title="Download"
              class="table-button"
              @click="downloadStagedBot(data.item.id, data.item.name)"
            >
              <font-awesome-icon icon="download" />
            </b-button>

            <b-button
              v-b-tooltip.hover.noninteractive.viewport
              class="table-button"
              title="Delete"
              @click="() => promptDelete(data.item.id, data.item.name)"
            >
              <font-awesome-icon icon="trash-alt" />
            </b-button>
          </b-button-group>
        </template>

        <template
          #cell(description)="data"
        >
          <b-row no-gutters>
            <b-col class="my-auto">
              <span
                v-if="!data.item.description.length"
              >
                (empty)
              </span>
              <span>{{
                truncate(data.item.description, 55)
              }}</span>
            </b-col>
            <b-col cols="auto">
              <b-button
                v-b-tooltip.hover.bottomright
                class="px-2"
                :title="data.detailsShowing ? 'Hide description' : 'Expand description'"
                @click="openEditing(data)"
              >
                <font-awesome-icon :icon="data.detailsShowing ? 'angle-up' : 'angle-down'" />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #row-details="data">
          <div v-if="editedTexts[data.item.id] === undefined">
            <b-form-textarea
              rows="3"
              plaintext
              :value="data.item.description"
              @dblclick="() => startEditing(data.item.id, data.item.description)"
            />
          </div>
          <div v-else>
            <b-form-textarea
              v-model="editedTexts[data.item.id]"
              rows="3"
              size="sm"
            />
            <b-button
              :disabled="isSaveDisabled(data)"
              variant="primary"
              @click="() => saveChanges(data.item.id)"
            >
              Save changes
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="addStagedBotModal"
      title="Add bot for staging"
      ok-title="Save"
      :ok-disabled="$v.tmpStagedBotName.$invalid"
      @ok="proxyAddStagedBot"
      @shown="clearModal"
    >
      <b-form-group
        label="Name"
        label-for="addStagedBotName"
        description="Give a descriptive name to this version"
      >
        <b-form-input
          id="addStagedBotName"
          v-model="tmpStagedBotName"
          :state="$v.tmpStagedBotName.$invalid ? false : null"
          type="text"
          placeholder="StagedName"
          autofocus
          @keyup.enter="proxyAddStagedBot"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.tmpStagedBotName.uniqueName">
            The name already exists.
          </div>
          <div v-if="!$v.tmpStagedBotName.nonEmpty">
            Please provide a name for this particular version of the bot.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Description"
        description="Description of the version"
      >
        <b-form-textarea v-model="tmpStagedBotDescription" />
      </b-form-group>
      <hr class="separator">
      <b-row class="my-3">
        <b-col
          cols="6"
          align-h="center"
          class="text-center"
        >
          Please remember to check the health page for errors before staging the bot
          <b-button
            variant="primary"
            block
            class="mt-2"
            :to="{
              name: 'health',
              hash: '#errors',
            }"
          >
            Health page
          </b-button>
        </b-col>
        <b-col
          cols="6"
          align-h="center"
          class="text-center"
        >
          Please remember to check conversation tests for errors before staging the bot
          <b-button
            variant="primary"
            class="mt-2"
            block
            :to="{
              name: 'health',
              hash: '#tests',
            }"
          >
            Conversation tests page
          </b-button>
        </b-col>
      </b-row>
      <template v-if="currentBotContainsDraftResponses">
        <hr class="separator">
        <b-alert
          variant="warning"
          show
        >
          <font-awesome-icon icon="exclamation-circle" />
          The bot you're about to stage contains draft responses. For testing purposes this may be
          tolerable.

          <router-link
            class="text-white"
            :to="{ name: 'responses' }"
          >
            Go to Responses page
          </router-link>
        </b-alert>
      </template>
    </b-modal>
  </main>
</template>

<script>
import copy from 'clipboard-copy';
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';
import axios from 'axios';
import endpoints from '@/js/urls';
import PrettyDateTime from '@/components/PrettyDateTime.vue';
import downloadBotMixin from '@/mixins/DownloadBotMixin';
import { truncateString } from '@/js/utils';

export default {
  name: 'StagingPage',
  components: { PrettyDateTime },
  mixins: [validationMixin, downloadBotMixin],
  data() {
    return {
      pagination: {
        perPage: 20,
        currentPage: 1,
      },
      tmpStagedBotName: '',
      tmpStagedBotDescription: '',
      editedTexts: {},
      stagedBotsFields: [
        {
          key: 'name',
          label: 'Name',
          tdClass: 'align-middle font-weight-bold',
          sortable: true,
        },
        {
          key: 'timestamp',
          label: 'Created on',
          tdClass: 'align-middle table-nobreak',
          sortable: true,
          formatter: (value) => value.toISOString(),
        },
        {
          key: 'submitter',
          label: 'Created by',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'description',
          label: 'Description',
          tdClass: 'align-middle',
        },
        {
          key: 'id',
          label: 'Id',
          tdClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'Actions',
          tdClass: 'align-middle',
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['getStagedBots']),
    ...mapGetters('botManipulation/activeBot', ['nodesWithDraftResponses']),
    ...mapGetters('administration', ['getDisplayNameFromId']),
    ...mapGetters('auth', ['isSuperUser', 'isUserNormal']),
    sortedStagedBots() {
      const mapper = (entry) => {
        const replacingTimestamp = new Date(entry.timestamp);
        const replacingSubmitter = this.getDisplayNameFromId(entry.submitter);
        return {
          ...entry,
          submitter: replacingSubmitter,
          timestamp: replacingTimestamp,
        };
      };
      return this.getStagedBots.map(mapper);
    },
    numberOfRows() {
      return this.getStagedBots.length;
    },
    currentBotContainsDraftResponses() {
      return this.nodesWithDraftResponses.length > 0;
    },
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions('botManipulation', [
      'deleteStagedBot',
      'newStagedBot',
      'changeStagedBotDescription',
    ]),
    ...mapActions('administration', ['fetchUsers']),
    ...mapActions('sidebar', ['showWarning']),
    clearModal() {
      this.tmpStagedBotName = '';
      this.tmpStagedBotDescription = '';
    },
    async proxyAddStagedBot() {
      if (!this.$v.tmpStagedBotName.$invalid) {
        this.$bvModal.hide('addStagedBotModal');
        this.loading = true;
        await this.newStagedBot({
          name: this.tmpStagedBotName,
          description: this.tmpStagedBotDescription,
        });
        this.loading = false;
      }
    },
    copyToClipboard(idToCopy) {
      copy(idToCopy);
      this.showWarning({
        title: 'Bot ID copied',
        text: 'Bot ID copied to pasteboard.',
        variant: 'primary',
      });
    },
    downloadStagedBot(idToDownload, versionName) {
      this.showWarning({
        title: 'Dowloading a bot',
        text: 'Your download is being prepared. This may take a few seconds.',
        variant: 'primary',
      });

      const botName = this.$store.state.botManipulation.activeBot.config.name;
      const downloadFileName = `${botName}-${versionName}`;
      axios({
        responseType: 'arraybuffer',
        url: endpoints.downloadStagedBot,
        params: {
          bot_id: idToDownload,
        },
        headers: {
          Authorization: `JWT ${this.$store.state.auth.jwt}`,
        },
        method: 'GET',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${downloadFileName}.zip`);
        document.body.appendChild(fileLink);

        fileLink.click();

        document.body.removeChild(fileLink);
      });
    },
    async promptDelete(stagedBotId, stagedBotName) {
      const question = `Are you sure you want to delete the staged bot "${stagedBotName}"?`;
      if (await this.$bvModal.msgBoxConfirm(question)) {
        this.loading = true;
        await this.deleteStagedBot({ stagedBotId });
        this.loading = false;
      }
    },
    openEditing(data) {
      if (!data.detailsShowing) {
        data.toggleDetails();
        this.startEditing(data.item.id, data.item.description);
      } else {
        data.toggleDetails();
      }
    },
    isSaveDisabled(data) {
      return this.editedTexts[data.item.id] === data.item.description;
    },
    startEditing(stagedBotId, description) {
      Vue.set(this.editedTexts, stagedBotId, description);
    },
    async saveChanges(stagedBotId) {
      const description = this.editedTexts[stagedBotId];
      this.loading = true;
      await this.changeStagedBotDescription({ stagedBotId, description });
      this.loading = false;
      Vue.delete(this.editedTexts, stagedBotId);
    },
    getBotId() {
      return this.$route.params.botId;
    },
    route(name) {
      return { name, params: { botId: this.getBotId() } };
    },
    truncate(s, l) {
      return truncateString(s, l);
    },
  },
  validations: {
    tmpStagedBotName: {
      required,
      uniqueName(proposedValue) {
        const currentBots = this.getStagedBots.map((x) => x.name);
        return !currentBots.includes(proposedValue);
      },
      nonEmpty(proposedValue) {
        return proposedValue.trim() !== '';
      },
    },
  },
};
</script>

<style scoped>
.table-button{
width: 2.0rem;
}</style>
