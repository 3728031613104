<template>
  <div>
    <b-card class="r-75 h-100 shadow" body-class="dashboard-card-content feature-card">
      <div class="dashboard-card-title text-truncate mb-2 font-weight-bold">
        Discover new features
      </div>
      <b-carousel
        v-if="highlightedFeatures.length"
        id="carousel-1"
        v-model="slide"
        :interval="7000"
        controls
        indicators
        style="height: calc(100% - 36px);"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide v-for="(feature, index) in highlightedFeatures" :key="index" class="h-100">
          <template #img>
            <div class="r-25 bg-white p-1 text-center cursor-pointer" @click="itemClicked(feature)">
              <div class="r-25 bg-white p-1 pb-4 text-center h-100" style="overflow: visible !important;">
                <h2 class="display-2" style="overflow: visible;" :style="getColor(index)">
                  <font-awesome-icon class="feature-icon r-25" :icon="feature.icon" />
                </h2>
                <h4 class="mt-2 font-weight-bold">
                  {{ feature.title }}
                </h4>
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div v-else class="text-center my-3">
        There are no features to show.
      </div>
    </b-card>
    <new-features-modal :active-feature="activeFeature" @hide="activeFeature = {}">
      <template v-if="activeFeature" #description>
        <supchat-forms v-if="isMatch(discoverableFeatures.SUPCHAT_FORMS.key)" />
        <automatic-message-delay v-if="isMatch(discoverableFeatures.AUTOMATIC_MESSAGE_DELAY.key)" />
        <graph-view v-if="isMatch(discoverableFeatures.GRAPH_VIEW.key)" />
        <phrase-blocks v-if="isMatch(discoverableFeatures.PHRASE_BLOCKS.key)" />
        <generative-ai v-if="isMatch(discoverableFeatures.GENERATIVE_AI.key)" />
        <rich-responses v-if="isMatch(discoverableFeatures.RICH_RESPONSES.key)" />
        <send-sms v-if="isMatch(discoverableFeatures.SEND_SMS.key)" />
        <improved-auto-labels v-if="isMatch(discoverableFeatures.IMPROVED_AUTO_LABELS.key)" />
        <hygiene-checks-reviewed v-if="isMatch(discoverableFeatures.HYGIENE_CHECKS_REVAMPED.key)" />
        <excel-response-export v-if="isMatch(discoverableFeatures.EXCEL_RESPONSE_EXPORT.key)" />
        <supchat-hidden-message v-if="isMatch(discoverableFeatures.SUPCHAT_HIDDEN_MESSAGE.key)" />
        <integration-error-continue
          v-if="isMatch(discoverableFeatures.INTEGRATION_ERROR_CONTINUE.key)"
        />
        <TopicExploration v-if="isMatch(discoverableFeatures.TOPIC_EXPLORATION.key)" />
        <BotHistory v-if="isMatch(discoverableFeatures.BOT_HISTORY.key)" />
        <GenerativeAIImprovements
          v-if="isMatch(discoverableFeatures.GENERATIVE_AI_IMPROVEMENTS.key)"
        />
        <GenerativeAIEvaluation
          v-if="isMatch(discoverableFeatures.GENERATIVE_AI_EVALUATION_AND_FEATURES.key)"
        />
        <NewIntegrations v-if="isMatch(discoverableFeatures.NEW_INTEGRATIONS.key)" />
        <StatisticsEnchancements
          v-if="isMatch(discoverableFeatures.STATISTICS_ENCHANCEMENTS.key)"
        />
        <NewPuzzelChatIntegration
          v-if="isMatch(discoverableFeatures.NEW_PUZZEL_CHAT_INTEGRATION.key)"
        />
        <GenerativeAIFlexibility
          v-if="isMatch(discoverableFeatures.GENERATIVE_AI_FLEXIBILITY.key)"
        />
      </template>
    </new-features-modal>
  </div>
</template>
<script>
import { matchingColors } from 'supwiz/util/data';
import { mapActions, mapGetters } from 'vuex';
import { discoverableFeatures } from '@/js/discoverableFeatures';
import NewFeaturesModal from '@/pages/Dashboard/NewFeatureModal.vue';
// descriptions
import SupchatForms from '@/components/descriptions/discoverableFeatures/SupchatForms.vue';
import AutomaticMessageDelay from '@/components/descriptions/discoverableFeatures/AutomaticMessageDelay.vue';
import GraphView from '@/components/descriptions/discoverableFeatures/GraphView.vue';
import PhraseBlocks from '@/components/descriptions/discoverableFeatures/PhraseBlocks.vue';
import GenerativeAi from '@/components/descriptions/discoverableFeatures/GenerativeAi.vue';
import RichResponses from '@/components/descriptions/discoverableFeatures/RichResponses.vue';
import SendSms from '@/components/descriptions/discoverableFeatures/SendSms.vue';
import ImprovedAutoLabels from '@/components/descriptions/discoverableFeatures/ImprovedAutoLabels.vue';
import HygieneChecksReviewed from '@/components/descriptions/discoverableFeatures/HygieneChecksReviewed.vue';
import ExcelResponseExport from '@/components/descriptions/discoverableFeatures/ExcelResponseExport.vue';
import SupchatHiddenMessage from '@/components/descriptions/discoverableFeatures/SupchatHiddenMessage.vue';
import IntegrationErrorContinue from '@/components/descriptions/discoverableFeatures/IntegrationErrorContinue.vue';
import TopicExploration from '@/components/descriptions/discoverableFeatures/TopicExploration.vue';
import BotHistory from '@/components/descriptions/discoverableFeatures/BotHistory.vue';
import GenerativeAIImprovements from '@/components/descriptions/discoverableFeatures/GenerativeAIImprovements.vue';
import GenerativeAIEvaluation from '@/components/descriptions/discoverableFeatures/GenerativeAIEvaluation.vue';
import NewIntegrations from '@/components/descriptions/discoverableFeatures/NewIntegrations.vue';
import StatisticsEnchancements from '@/components/descriptions/discoverableFeatures/StatisticsEnchancements.vue';
import NewPuzzelChatIntegration from '@/components/descriptions/discoverableFeatures/NewPuzzelChatIntegration.vue';
import GenerativeAIFlexibility from '@/components/descriptions/discoverableFeatures/GenerativeAIFlexibility.vue';

export default {
  name: 'NewFeatures',
  components: {
    NewFeaturesModal,
    SupchatForms,
    AutomaticMessageDelay,
    GraphView,
    PhraseBlocks,
    GenerativeAi,
    RichResponses,
    SendSms,
    ImprovedAutoLabels,
    HygieneChecksReviewed,
    ExcelResponseExport,
    SupchatHiddenMessage,
    IntegrationErrorContinue,
    TopicExploration,
    BotHistory,
    GenerativeAIImprovements,
    GenerativeAIEvaluation,
    NewIntegrations,
    StatisticsEnchancements,
    NewPuzzelChatIntegration,
    GenerativeAIFlexibility,
  },
  data() {
    return {
      discoverableFeatures,
      slide: 0,
      sliding: null,
      activeFeature: {},
    };
  },
  computed: {
    ...mapGetters('userSettings', ['highlightedFeatures']),
  },
  methods: {
    ...mapActions('userSettings', ['calculateDiscoverableFeatures']),
    ...mapActions('sidebar', ['showWarning']),
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getColor(index) {
      return `color: ${matchingColors[index % matchingColors.length]};`;
    },
    itemClicked(feature) {
      this.activeFeature = feature;
      this.$bvModal.show('new-feature-modal');
    },
    isMatch(key) {
      return this.activeFeature?.key === key;
    },
  },
};
</script>
<style>
.carousel-control-next,
.carousel-control-prev {
  filter: invert(70%);
}
.carousel-inner{
  overflow:visible !important;
}
.carousel .carousel-indicators {
  bottom: -10px;
}
.carousel .carousel-indicators li {
  background-color: #344767;
}
</style>
<style>
.feature-icon {
  border: 1px solid #9fa3ac80;
  padding: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  overflow: visible !important;
  transition: transform 0.3s ease;
}
.feature-icon:hover {
  transform: scale(1.2);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 4px 6px 0px, rgba(14, 30, 37, 0.32) 0px 6px 20px 0px;
}
.feature-icon:not(:hover) {
  transform: scale(1);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.feature-card{
  overflow: hidden !important;
}
</style>
