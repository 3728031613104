// translated from evaluator.py split_string

// get parameter is "all" if you want a list of both code and ordinary text,
//                  "code" if you only want the {botscripts embedded in the mustaches}
//                  "text" if you only want the text{}outside{}the{}mustaches
// mode parameter is "best-effort" if you want to ignore errors and return the best-effort attempt at a result,
//                   "strict" if you want to throw SyntaxErrors upon failure
export function splitString(str, get, mode) {
  const exprStrip = true;
  const expressions = [];
  let i = 0;
  let isCurrentlyStr = true;
  let currentExpr = '';
  const getText = get === "all" || get === "text";
  const getCode = get === "all" || get === "code";
  while (i < str.length) {
    if (str.charAt(i) !== '{' && str.charAt(i) !== '}') {
      currentExpr += str.charAt(i);
    } else if (!isCurrentlyStr) {
      if (str.charAt(i) === '{' && mode === "strict") {
        throw SyntaxError();
      }
      if (exprStrip) {
        currentExpr = currentExpr.trim();
      }
      if (getCode) {
        expressions.push(currentExpr);
      }
      currentExpr = '';
      isCurrentlyStr = true;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (i + 1 < str.length && str.charAt(i) === str.charAt(i + 1)) {
        currentExpr += str.charAt(i);
        i += 1;
      } else if (str.charAt(i) === '{') {
        if (getText) {
          expressions.push(currentExpr);
        }
        currentExpr = '';
        isCurrentlyStr = false;
      } else if (mode === "strict") {
        throw new SyntaxError();
      }
    }
    i += 1;
  }
  if (!isCurrentlyStr && mode === "strict") {
    throw new SyntaxError();
  }
  if ((isCurrentlyStr && getText) || (!isCurrentlyStr && getCode)) {
    expressions.push(currentExpr);
  }
  return expressions;
}