<template>
  <div>
    <p class="mb-2">
      Use the <strong>Send SMS</strong> activity to have your bot send information directly via
      SMS to your users.
    </p>
    <b-alert
      show
      class="mt-3"
      variant="info"
    >
      Send SMS is an add-on feature that must be enabled for your SupWiz products before you
      can use it. Reach out to your SupWiz' rep to learn more.
    </b-alert>
  </div>
</template>
<script>
export default { name: 'SendSmsDescription' };
</script>
