import {
  lex, parse, ParsingError, VariablesVisitor,
} from 'supwiz/botscript/parser';
import { typecheck } from 'supwiz/botscript/typer';
import { splitString } from 'supwiz/util/text';
import {
  RESPONSE, ACTION, CHAT_ACTION, SET_VARIABLE, CONTROL_FLOW, CALL_MODEL, METRIC_SIGNAL, ENCRYPT,
} from '@/js/activity';
import { nodeTypes } from '@/js/constants';

const initialState = {
  variableName: '',
  variableFilter: 'All',
  foundBotscriptVariables: {},
  foundBotscriptErrors: [],
  botscriptVariablesDirty: true,
};

const botscriptGetters = {
  getVariables(state) {
    return state.foundBotscriptVariables;
  },
  getErrors(state) {
    return state.foundBotscriptErrors;
  },
  getVariableName(state) {
    return state.variableName;
  },
  getVariableFilter(state) {
    return state.variableFilter;
  },
  getNERs(state, getters, rootState, rootGetters) {
    const result = rootGetters['botManipulation/activeBot/getNERs'];
    if (result !== null) {
      return result.map((ner) => ner.name);
    }
    return [];
  },
  getSubflowNERs(state, getters, rootState, rootGetters) {
    const result = {};
    const subflows = rootGetters['botManipulation/getSubFlows'];
    for (const subflow of subflows) {
      if (subflow.ners !== null) {
        result[subflow.id] = subflow.ners.map((ner) => ner.name);
      }
    }
    return result;
  },
};

function parseScript(code, ners) {
  const script = code === null ? '' : code;
  try {
    const errors = [];
    const tokens = lex(script, errors);
    let vars = new Set();
    if (tokens.length > 1) {
      const ast = parse(tokens, errors, script);
      const visitor = new VariablesVisitor();
      ast.visit(visitor);
      vars = visitor.vars;
      const errs = [];
      typecheck(ast, errs, ners);
      for (const err of errs) {
        errors.push(new ParsingError(0, 0, err));
      }
    }
    return { variables: vars, errors };
  } catch (e) {
    return { variables: new Set(), errors: [new ParsingError(0, script.length, 'Unknown error')] };
  }
}

function initVariable(variables, name) {
  if (!(name in variables)) {
    // eslint-disable-next-line no-param-reassign
    variables[name] = {
      written: [],
      read: [],
    };
  }
}
function addReadVariable({ variables }, name, itemId) {
  initVariable(variables, name);
  variables[name].read.push(itemId);
}
function addWrittenNodeActivity({ variables }, name, nodeId, activityId) {
  // No need to lookup written variables, as they have the verbatim name
  initVariable(variables, name);
  variables[name].written.push({
    type: 'nodeActivity',
    nodeId,
    activityId,
  });
}
function handleScript(scriptInfo, id, script, ners) {
  const { variables: vars, errors } = parseScript(script, ners);
  for (const variable of vars) {
    addReadVariable(scriptInfo, variable, id);
  }
  if (errors.length > 0) {
    scriptInfo.errors.push({ id, errors });
  }
}
function handleString(scriptInfo, id, string, ners) {
  const vars = new Set();
  const errors = [];
  for (const script of splitString(string, 'code', 'best-effort')) {
    const { variables: scriptVars, errors: scriptErrors } = parseScript(script, ners);
    for (const variable of scriptVars) {
      vars.add(variable);
    }
    errors.push(...scriptErrors);
  }
  for (const variable of vars) {
    addReadVariable(scriptInfo, variable, id);
  }
  if (errors.length > 0) {
    scriptInfo.errors.push({ id, errors });
  }
}

const botscriptMutations = {
  // eslint-disable-next-line no-param-reassign
  setBotscriptInfo(state, { scriptInfo: { variables, errors } }) {
    state.foundBotscriptVariables = variables;
    state.foundBotscriptErrors = errors;
    state.botscriptVariablesDirty = false;
  },
  setVariableName(state, { name }) {
    state.variableName = name;
  },
  setVariableFilter(state, { filter }) {
    state.variableFilter = filter;
  },
};

function handleActivities(rootGetters, scriptInfo, ners) {
  const allNodesAsList = rootGetters['botManipulation/activeBot/allNodesAsList'];
  if (!allNodesAsList) {
    return;
  }

  for (const node of allNodesAsList) {
    const requirementRef = {
      type: 'nodeRequirement',
      nodeId: node.id,
      index: 0,
    };
    for (const requirement of node.requirements) {
      handleScript(scriptInfo, requirementRef, requirement, ners);
    }

    for (const [activityId, activity] of Object.entries(node.activities)) {
      const activityRef = {
        type: 'nodeActivity',
        nodeId: node.id,
        activityId,
      };

      if (activity.type === ACTION) {
        addWrittenNodeActivity(scriptInfo, activity.target, node.id, activityRef);
        for (const header of activity.headers) {
          handleScript(scriptInfo, { ...activityRef, subtype: 'header' }, header.value, ners);
        }
        for (const param of activity.params) {
          handleScript(scriptInfo, { ...activityRef, subtype: 'param' }, param.value, ners);
        }
      } else if (activity.type === CALL_MODEL) {
        addWrittenNodeActivity(scriptInfo, activity.targetName, node.id, activityRef);
        handleScript(scriptInfo, activityRef, activity.modelInput, ners);
      } else if (activity.type === CHAT_ACTION) {
        if (activity.target) {
          addWrittenNodeActivity(scriptInfo, activity.target, node.id, activityRef);
        }
      } else if (activity.type === CONTROL_FLOW) {
        if (activity.loopType === 'forLoop' && activity.name === 'for') {
          addWrittenNodeActivity(scriptInfo, activity.item, node.id, activityRef);
          handleScript(scriptInfo, activityRef, activity.iterable, ners);
        } else if (activity.name === 'if') {
          handleScript(scriptInfo, activityRef, activity.statement, ners);
        } else if (activity.name === 'else if') {
          handleScript(scriptInfo, activityRef, activity.statement, ners);
        }
      } else if (activity.type === METRIC_SIGNAL) {
        // Nothing, we only allow strings
      } else if (activity.type === SET_VARIABLE) {
        addWrittenNodeActivity(scriptInfo, activity.key, node.id, activityRef);
        handleScript(scriptInfo, activityRef, activity.code, ners);
      } else if (activity.type === RESPONSE) {
        handleString(scriptInfo, activityRef, activity.text, ners);
      } else if (activity.type === ENCRYPT) {
        addWrittenNodeActivity(scriptInfo, activity.target, node.id, activityRef);
        handleScript(scriptInfo, activityRef, activity.code, ners);
      } else {
        // Alert that we found an unknown activity
      }
    }
  }
}

function handleInactivities(rootGetters, scriptInfo, ners) {
  const inactivities = rootGetters['botManipulation/activeBot/config/getInactivity'];
  if (!inactivities) {
    return;
  }

  for (const item of inactivities) {
    handleString(scriptInfo, {
      type: 'inactivity',
      time: item.time,
    }, item.msg, ners);
  }
}

function handleEntities(rootGetters, { variables }) {
  const ners = rootGetters['botManipulation/activeBot/getNERs'];
  if (!ners) {
    return;
  }

  for (const ner of ners) {
    const name = ner.name;
    initVariable(variables, name);
    variables[name].written.push({
      type: 'ner',
      name,
    });
  }
}

function handleIntegrations(rootGetters, scriptInfo, ners) {
  const integrations = rootGetters['botManipulation/getBotActions'];
  if (!integrations) {
    return;
  }

  for (const integration of integrations) {
    handleString(scriptInfo, {
      type: 'integration',
      subtype: 'endpoint',
      name: integration.name,
    }, integration.endpoint, ners);

    if (integration.allowAppend) {
      handleScript(scriptInfo, {
        type: 'integration',
        subtype: 'appendDefault',
        name: integration.name,
      }, integration.appendDefault, ners);
    }

    integration.headers.forEach((entry) => {
      if (entry.secretReference === undefined) {
        handleScript(scriptInfo, {
          type: 'integration',
          name: integration.name,
          subtype: 'header',
          subname: entry.name,
        }, entry.default, ners);
      }
    });
  }
}

function handleConfiguration(rootState, scriptInfo, ners) {
  if (!rootState.botManipulation.activeBotSet) {
    return;
  }
  const config = rootState.botManipulation.activeBot.config;

  handleString(scriptInfo, {
    type: 'config',
    subtype: 'smart-nodes',
    name: 'optionQuery',
  }, config.multipleChoice.optionsQuery, ners);
  handleString(scriptInfo, {
    type: 'config',
    subtype: 'smart-nodes',
    name: 'confirmQuery',
  }, config.multipleChoice.confirmQuery, ners);
  handleString(scriptInfo, {
    type: 'config',
    subtype: 'smart-nodes',
    name: 'otherText',
  }, config.multipleChoice.otherText, ners);
}

function handleSubFlows(rootGetters, scriptInfo, subflowNers) {
  const subflows = rootGetters['botManipulation/getSubFlows'];
  for (const subflow of subflows) {
    for (const inputVar of subflow.config.inputVariables) {
      handleScript(scriptInfo, {
        type: 'subflow',
        flowId: subflow.id,
        subtype: 'input',
        subname: inputVar.name,
      }, inputVar.value, subflowNers[subflow.id]);
    }
    for (const outputVarName of subflow.config.outputVariables) {
      initVariable(scriptInfo.variables, outputVarName);
      scriptInfo.variables[outputVarName].written.push({
        type: 'subflow',
        flowId: subflow.id,
        subtype: 'output',
      });
    }
  }
}

function handleSubFlowNodes(rootGetters, scriptInfo, ners) {
  const allNodesAsList = rootGetters['botManipulation/activeBot/allNodesAsList'];
  if (!allNodesAsList) {
    return;
  }

  for (const node of allNodesAsList) {
    if (node.options.nodeType !== nodeTypes.SUBFLOW) {
      continue;
    }
    const inputVars = node.subFlowMap.input.vars;
    for (const [innerVar, outerVar] of Object.entries(inputVars)) {
      handleScript(scriptInfo, {
        type: 'subflowNode',
        nodeId: node.id,
        subtype: 'input',
        subname: innerVar,
      }, outerVar, ners);
    }
    const outputVars = node.subFlowMap.output.vars;
    for (const [innerVar, outerVar] of Object.entries(outputVars)) {
      initVariable(scriptInfo.variables, outerVar);
      scriptInfo.variables[outerVar].written.push({
        type: 'subflowNode',
        nodeId: node.id,
        subtype: 'output',
        subname: innerVar,
      });
    }
  }
}

const botscriptActions = {
  updateScripts({
    commit, getters, rootGetters, rootState,
  }) {
    const ners = getters.getNERs;
    const subflowNers = getters.getSubflowNERs;
    const scriptInfo = { variables: {}, errors: [] };

    handleActivities(rootGetters, scriptInfo, ners);
    handleInactivities(rootGetters, scriptInfo, ners);
    handleIntegrations(rootGetters, scriptInfo, ners);
    handleConfiguration(rootState, scriptInfo, ners);
    handleEntities(rootGetters, scriptInfo);
    handleSubFlows(rootGetters, scriptInfo, subflowNers);
    handleSubFlowNodes(rootGetters, scriptInfo, ners);

    commit('setBotscriptInfo', { scriptInfo });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters: botscriptGetters,
  mutations: botscriptMutations,
  actions: botscriptActions,
};
