<template>
  <div>
    <h5>SupSearch covered statistics</h5>
    <p>
      The statistics page now separates flows and SupSearch into two categories and provides
      distinct statistics for each.
    </p>
    <h5 class="mt-2">
      Intent-level statistics
    </h5>
    <p>
      Intent-level statistics for coverage and self-service are now available
      alongside full conversation-level statistics.
      This offers a more detailed view of bot performance
      when users ask multiple questions in a single conversation.
    </p>

    <div class="text-center">
      <b-img
        class="w-100"
        fluid
        :src="require(`@/assets/featureImages/statistics_enchancements.png`)"
        alt="Example of the new Excel response export"
      />
    </div>
  </div>
</template>
<script>
export default { name: 'StatisticsEnchancements' };
</script>
