<template>
  <b-modal
    id="bookmark-modal"
    :title="`${action} bookmark folder`"
    @ok="okClicked"
    @show="prepareData"
    @cancel="cancelClicked"
  >
    <b-form-group label="Name">
      <b-form-input
        v-model="localCopy.name"
        :state="!$v.localCopy.name.$invalid"
      />
      <b-form-feedback>
        {{ $v.localCopy.name.required ? 'Name must be unique.' : 'Name is required.' }}
      </b-form-feedback>
    </b-form-group>
    <b-form-group label="Folder">
      <b-form-select
        v-model="localCopy.parent"
        :options="bookmarkOptions"
      />
    </b-form-group>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        v-if="action === 'Edit'"
        variant="danger"
        @click="cancel()"
      >
        Delete folder
      </b-button>
      <b-button
        variant="primary"
        class="ml-auto"
        :disabled="$v.localCopy.$invalid"
        @click="ok()"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  name: 'BookmarkNodeModal',
  mixins: [validationMixin],
  data() {
    return {
      localCopy: {},
      action: null,
    };
  },
  computed: {
    ...mapState('graphView', ['bookmarks', 'isFetchingBookmarks', 'activeBookmark']),
    bookmarkOptions() {
      return this.bookmarks.filter((e) => e.isFolder && e.id !== this.activeBookmark?.id)
        .map((e) => ({ text: e.name, value: e.id }));
    },
  },
  methods: {
    ...mapActions('graphView', ['addBookmark', 'deleteBookmark', 'updateBookmark']),
    prepareData() {
      if (this.activeBookmark) {
        this.action = 'Edit';
        this.localCopy = cloneDeep(this.activeBookmark);
      } else {
        this.action = 'Add';
        this.localCopy = { name: '', parent: null };
      }
    },
    cancelClicked() {
      this.deleteBookmark(this.localCopy.id);
      this.clearData();
    },
    okClicked() {
      if (this.action === 'Edit') {
        this.updateBookmark(this.localCopy);
      } else {
        this.addBookmark(this.localCopy);
      }
      this.clearData();
    },
    clearData() {
      this.localCopy = {};
      this.action = null;
    },
  },
  validations() {
    return {
      localCopy: {
        name: {
          required,
          isUnique(value) {
            return !this.bookmarks.filter((e) => e.isFolder && e.id !== this.activeBookmark?.id)
              .map((e) => e.name).includes(value);
          },
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .action-col{
    width: 60px;
}
</style>
