<template>
  <div>
    <div
      v-if="activeModelId === null || isClassifierLoading"
      class="d-flex justify-content-center mt-3"
    >
      <b-spinner style="width: 5rem; height: 5rem;" />
    </div>
    <router-view v-else />
  </div>
</template>
<script>

import {
  mapActions, mapState, mapMutations,
} from 'vuex';

export default {
  name: 'NLUModelPage',
  beforeRouteEnter(to, from, next) {
    // setting this.intervalId beforeRouteEnter results in it
    // being overwritten by mounted. Maybe because the component is
    // mounted twice (seen experimentally), which might be due to
    // some v-if logic
    next((vm) => {
      vm.setAndFetch(to.params.modelId);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.modelId !== from.params.modelId) {
      this.setAndFetch(to.params.modelId);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.setActiveModelId(null);
    this.resetVersionsPagination();
    next();
  },
  computed: {
    ...mapState('nlu/classifier', ['isClassifierLoading', 'activeModelId']),
  },
  methods: {
    ...mapActions('nlu/classifier', ['fetchClassifier', 'fetchTrainingTask']),
    ...mapMutations('nlu/classifier', ['setActiveModelId', 'resetVersionsPagination']),
    setAndFetch(modelId) {
      this.setActiveModelId(modelId);
      this.fetchTrainingTask();
      this.fetchClassifier(modelId);
    },
  },
};
</script>
