<template>
  <div>
    <h5>Zendesk Sunshine integration</h5>
    <p class="mb-2">
      SupWiz chatbots can now be used in combination with Zendesk Messaging.
    </p>
    <h5>
      Puzzel Case Management Mailbot integration
    </h5>
    <p>
      SupWiz mailbots can now be used in combination with Puzzel Case Management.
    </p>
  </div>
</template>
<script>
export default { name: 'NewIntegrations' };
</script>
