<template>
  <div>
    <p>
      All statistics are calculated for the specified period. Furthermore all change-calculations
      are calculated using the previous period of the same length.
      <br>
      The previous period has the same length as the currently set period and it ends when
      the currently set period begins.
    </p>

    <h5>
      Automation rate
    </h5>
    <p>
      The overall percentage of work automated by the bot. This includes both fully automated
      conversations and partially automated work. The automation rate is further detailed in the
      "Automation details" section.
    </p>

    <h5>
      Estimated cost savings
    </h5>
    <p>
      The estimated cost savings provided by the bot based on the number of chats and the automation
      rate.
      <br>
      The specific value is calculated based on the average handling cost specified in the
      statistics settings (see screenshot below).
      <b-img
        fluid
        :src="require(`@/assets/helpImages/statistics_settings.png`)"
        alt="Statistics settings"
      />
    </p>

    <h5>
      Number of conversations
    </h5>
    <p>
      The total number of conversations that the bot has been involved in during the period.
    </p>

    <h5>
      Number of intents
    </h5>
    <p>
      The total number of intents that the bot has been involed in during the period. The number
      of intents for a conversation is calculated as the number of times the user has visited
      a master node. This corresponds to the number of overall questions handled by the bot.
    </p>

    <h5>
      Estimated visitor time saved
    </h5>
    <p>
      The estimated visitor time saved is calculated by taking the total number of messages users
      send in all chats and subtracting the number of chats that were transferred. The value is
      calculated based on the average agent response time and the average queue time for agent
      pickup, which can be configured in the statistics settings (see screenshot below):
      <b-img
        fluid
        alt="Automation details"
        :src="require(`@/assets/helpImages/statistics_settings_estimated_visitor_time_saved.png`)"
      />
    </p>

    <h5>
      Automation details
    </h5>
    <p>
      The automation details visualizes how the automation rate of the bot is calculated and
      provides key insights into the performance of the bot. The details can be calculated
      based on conversation- or intent-level information. The automation details are visualized
      as follows:
      <b-img
        fluid
        alt="Automation details"
        :src="require(`@/assets/helpImages/automation_details.png`)"
      />
      Each component is elaborated below:
    </p>
    <ul>
      <li>
        <strong>Flow covered:</strong> A conversation is (loosely speaking) flow covered if it
        passes through a flow node (configured in the
        <b-link v-b-modal.statistics-settings-modal>
          statistics settings
        </b-link>).
        This corresponds to questions the bot has been designed to handle
      </li>
      <li>
        <strong>SupSearch covered:</strong> A conversation is "SupSearch covered" if a SupSearch
        action was performed but it does not pass through a flow node.
        This corresponds to questions the bot has not been designed to handle explicitly using a
        flow but which is handled by looking up relevant articles using SupSearch.
      </li>
      <li>
        <strong>Not covered:</strong> A conversation is "not covered" if it is neither Flow covered
        nor SupSearch covered. "Not covered" conversations correspond to questions the bot is not
        (yet) designed to handle.
      </li>
      <li>
        <strong>Self-serviced:</strong> A conversation is said to be self-serviced if it does not
        visit a "transfer node". (configured in the
        <b-link v-b-modal.statistics-settings-modal>
          statistics settings
        </b-link>).
        <br>
        Note that even if a question is not covered by a flow, the bot can still sometimes fully
        automate it by e.g. providing knowledge articles using SupSearch, providing status
        messages, or other more generic types of assistance.
      </li>
      <li>
        <strong>Partial resolution rate:</strong> For conversations that are not fully automated
        by the bot, the partial resolution rate is calculated based on the method configured in the
        <em>statistics settings</em>.
        <br>
        Partial resolution describes all the work the bot can do such as ensuring the user has
        logged in, provided all the necessary details, tried the first few possible solutions,
        etc. to make the follow-up work for an agent as easy as possible.
      </li>
    </ul>
    <p>
      <strong>Statistics settings</strong> have three modes:
    </p>
    <ul>
      <li>
        <em>Fixed percentage:</em> You may specify that the bot always performs X% of the work
        in a conversation.
      </li>
      <li>
        <em>Fixed message count:</em> You may specify the average number of messages sent
        by a user in a conversation, and the calculation is then based on large a part
        of those messages were sent to the bot (subtracting the last message before a
        transfer).
      </li>
      <li>
        <em>Actual message count:</em> The calculation is based on how large a part of the messages
        sent by the user were sent to the bot.
        This mode differs from <i>Fixed message count</i>, by using the actual number
        of messages sent by the user, rather than a user-defined average.
      </li>
    </ul>
    <p>
      The total automation rate is then calculated by adding up all the contributions of the bot.
      In the example screenshot, the automation rate would be 69.8%
      <em>(calculated as 60% * (90% + 10% * 50%) + 40% * (15% + 85% * 20%))</em>
    </p>

    <h5>
      Distribution over time
    </h5>
    <p>
      Distribution of volume and flow coverage (see definition under Automation details) grouped
      by hour, weekday, day-of-moth, or month (as specified in the "Group by filter").
    </p>

    <!-- TODO: Include description once implemented
    <h5 class="font-weight-bold mt-4">
      Customer satisfaction
    </h5>
    <p>
      <b>
        Rating that users provide at the end of a conversation.
      </b>
    </p>
    -->

    <h5>
      Impacted conversations
    </h5>
    <p>
      The fraction of all conversations that were impacted by the bot.
      <br>
      A conversation is said to be "impacted" if the user sent more than one message to the bot
      or the conversation wasn't transferred.
    </p>

    <h5>
      Average user messages
    </h5>
    <p>
      The average number of messages sent by the user during conversation with the bot.
      <br>
      This number <em>does not include</em> messages sent by the user after being transferred to an
      agent.
    </p>

    <h5>
      Average bot messages
    </h5>
    <p>
      The average number of messages sent by the bot during conversation with users.
    </p>

    <h5>
      Average integrations used
    </h5>
    <p>
      The average number of integrations used by the bot during all conversations.
      <br>
      Integrations represent e.g. lookups, transactions, and more,
      such as "reset password", and are defined on the Integrations page.
    </p>

    <h5>
      Node visits
    </h5>
    <p>
      Shows the number of chats where a specified node was visited during a conversation as well
      as the fraction of conversations where the node was visited.
      Note that even if was visited more than once in a conversation, it will only count once (as
      opposed to the Conversation distribution below).
      <br>
      You can customize which nodes you want these statistics for as well as highlight given nodes
      as favorites.
    </p>

    <h5>
      Conversation distribution
    </h5>
    <p>
      Allows you to select a specific node and see the distribution of conversations going through
      that node to each of its children both as an absolute count and as a fraction of all the
      nodes visited through the selected node.
    </p>

    <h5>
      Conversation automation
    </h5>
    <p>
      Allows you to select a specific node and see the self-service rate of conversations
      going through that node to each of its children both.
    </p>
  </div>
</template>

<script>
export default { name: 'StatisticsDescription' };
</script>
