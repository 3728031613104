import Vue from 'vue';
import Vuex from 'vuex';
import anonymizationStore from 'supwiz/components/anonymization/anonymizationStore';
import templateStore from 'supwiz/components/template/templateStore';
import health from '@/store/health';
import { isProduction } from '@/js/featureFlags';
import auth from './auth';
import botManipulationModule from './botManipulation/index';
import treeView from './treeView';
import nodeLabels from './nodeLabels';
import statistics from './statistics';
import nodeInterpretations from './nodeInterpretations';
import nlu from './nlu';
import dataExploration from './dataExploration';
import unitTest from './unitTest';
import diagnostics from './diagnostics';
import chatlogs from './chatlogs';
import administration from './administration';
import botSecrets from './botSecrets';
import botSynchronization from './botSynchronization';
import botscript from './botscript';
import supsearch from './supsearch';
import audio from './audio';
import demopanel from './demopanel';
import graphView from './graphView';
import task from './task';
import sidebar from './sidebar';
import healthSuggestions from './healthSuggestions';
import variants from './variants';
import configureModerator from './store-moderator';
import userSettings from './userSettings';

Vue.use(Vuex);

const debug = !isProduction();

const incrementPlugin = (store) => {
  // synchronize bot each second (5 sec for dev environment)
  setInterval(async () => store.dispatch('botSynchronization/syncBot', false), isProduction() ? 1000 : 10000);

  // fetch status of running tasks each second
  setInterval(async () => {
    const celeryIds = store.getters['task/celeryIds'];
    if (!celeryIds.length) {
      return;
    }

    const tasks = await store.dispatch('task/fetchTasks', celeryIds);

    tasks.forEach((t) => {
      const curTask = store.getters['task/taskById'](t.celery_id);
      if (!curTask) {
        return;
      }

      const callbackShouldAbort = curTask.callbackShouldAbort;
      if (callbackShouldAbort && callbackShouldAbort()) {
        return;
      }

      if (t.status === 'pending') {
        const callback = curTask.callbackUpdate;
        if (callback) {
          callback(t);
        }
      } else if (t.status === 'done') {
        const callback = curTask.callbackDone;
        if (callback) {
          callback(t);
        }
        store.commit('task/removeTask', t.celery_id);
      } else if (t.status === 'failed') {
        const callback = curTask.callbackFailed;
        if (callback) {
          callback(t);
        }
        store.commit('task/removeTask', t.celery_id);
      }
    });
  }, 1000);

  // we actively refresh the token while the browser is open
  setInterval(() => {
    store.dispatch('auth/inspectToken', { shouldRefresh: true });
  }, 600000);
};

export default new Vuex.Store({
  modules: {
    anonymizationStore,
    auth,
    botManipulation: botManipulationModule,
    botSecrets,
    botSynchronization,
    botscript,
    treeView,
    statistics,
    nodeLabels,
    sidebar,
    nodeInterpretations,
    nlu,
    dataExploration,
    unitTest,
    templateStore,
    diagnostics,
    chatlogs,
    administration,
    health,
    audio,
    demopanel,
    task,
    healthSuggestions,
    variants,
    userSettings,
    graphView,
    supsearch,
  },
  strict: debug,
  state: {
    sidebarOpen: true, // flag for test whether sidebar is collapsed
  },
  getters: {
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },
  actions: {
    async authentication({
      state, commit, dispatch, rootState,
    }) {
      if (state.auth.jwt) {
        try {
          // If Promise does not reject, it is assumed that token was verified
          await dispatch('auth/verifyToken');
          await dispatch('auth/getPermissions');
          commit('auth/updateLoggedIn', true);
          commit('auth/updateOngoingSession', true);
          if (rootState.auth.user === null) {
            await dispatch('auth/getUser');
          }
          return 'success';
        } catch (e) {
          throw new Error(e);
        }
      }
      return 'no token';
    },
  },

  plugins: [incrementPlugin, configureModerator],
});
